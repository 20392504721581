import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { withRouter } from 'react-router';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../components/common/Box';
import Grid from '../components/common/Grid/ResponsiveGrid';
import TabContainer from '../components/common/Grid/TabContainer';
//import gridLayout from '../components/common/Grid/homepage';
import gridLayouts from '../components/common/Grid/responsiveHomepage';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import VendorsWidget from '../components/pages/home/VendorsWidget';
import TerritoriesWidget from '../components/pages/home/TerritoriesWidget';
import EntitiesList from '../components/pages/home/EntitiesList';
import SearchWidget from '../components/pages/home/SearchWidget';
import SharePage from '../components/pages/sharedPages/SharePage';
import { filterActions } from '../data/actions/filter';
import CSV from '../helpers/CSVExport';
import store from '../helpers/store'
import PDFDocument from '../components/pages/home/PDF';
import {Widget, WithWidgetManager} from '../components/widgets/manager';
import { milestonesActions } from '../data/actions/milestones';

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const lockedSvg = require('!svg-inline-loader!../../public/img/locked.svg');
const pdfSvg = require('!svg-inline-loader!../../public/img/pdf.svg');

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.searchResult = this.searchResult.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(milestonesActions.getEntityMilestones('mixed', null, {} ))
    }
    
    loadLayout() {
        const savedLayouts = store.get('store_homepage');
        if(savedLayouts) {
            if(Array.isArray(savedLayouts)) { // legacy single-dimensional array
                const multiLayout = {
                    ...gridLayouts,
                    lg: savedLayouts
                };
                return multiLayout;
            }
            else {
                return savedLayouts;
            }
        }
        else {
            return gridLayouts;    
        } 
    }
    

    searchResult(item) {
        /*
        if(item.entity == 'imprints') {
            this.props.setFilter(item.entity, [item]);
            this.props.history.push(`/catalog/${item.entity}`);
        }
        else {
        */
            this.props.history.push(`/${item.entity}/${item.id}`);
        //}

    }

    exportToCsv(){
      const {
        playlists,
        imprints,
        artists,
        projects,
        products,
        tracks,
        stats,
      } = this.props;

      const data = [
        { overview: stats.top },                    
        { top_imprints: imprints.top},
        { top_artists: artists.top },
        { top_projects: projects.top },
        { top_products: products.top },
        { top_tracks: tracks.top },
        { top_playlists: playlists.top},        
        { top_platforms: stats.vendorStatsCache },
        { territories: stats.topTerritoryCache }
      ];
      
      const filename = CSV.CSVHeader('overview', null, this.props.filter.global);
      CSV.CSVBulkExport(data, filename);

    }
    
    // renderElements(){
    //     return [
    //         <EntitiesList title="Top Imprints" entity="imprints" />,
    //         <EntitiesList title="Top Artists" entity="artists" />,
    //         <TerritoriesWidget />,
    //         <VendorsWidget /> 
              
    //     ];
    // }


    //TODO: replicate same function in OverviewShared
    getPdfData() {
        const reportTitle = 'Overview',
            { imprints, artists, products, projects, tracks, playlists, stats } = this.props;
            
        const imageIDs = [
              'home_vendors',
              'top_territories',
              'top_imprints',
              'top_projects',
              'top_products',
              'top_tracks',
              'top_playlists',
              'top_artists'
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        
        let vendorTableData = [];

        if (this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table

        const tables = {
            imprints: imprints.top,
            artists: artists.top,
            products: products.top,
            projects: projects.top,
            tracks: tracks.top,
            playlists: playlists.top,
            territories: stats.topTerritory.table,
            vendorsTable: vendorTableData
        };
        return { reportTitle, images, tables, overview: stats.top, grid: Object.values(this.loadLayout())[0], globalFilter: this.props.filter.global };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "overview.pdf";
            link.click();
        });
    }
    
    render() {
        // const entitiesTabProps = {
        //     tabs: [
        //            {title: 'Top Imprints', component: "EntitiesList", props: {title: "Top Imprints", entity: "imprints" }},
        //            {title: 'Top Artists', component: "EntitiesList", props: {title: "Top Artists", entity: "artists" }},
        //            {title: 'Top Products', component: "EntitiesList", props: {title: "Top Products", entity: "products" }},
        //            {title: 'Top Tracks', component: "EntitiesList", props: {title: "Top Tracks", entity: "tracks" }}
        //     ],
        //     components: {EntitiesList}
        // },
        // extraTabProps = {
        //     tabs: [
        //            {title: 'Top Territories', component: "TerritoriesWidget", props: {}},
        //            {title: 'Top Platforms', component: "VendorsWidget", props: {}}
        //     ],
        //     components: {TerritoriesWidget, VendorsWidget}
        // };
        const { sharedMode, widgetProps } = this.props;
        return (
            <div className="homepage overview-page">
                <div className="page-action-holder">
                    <div className="download-data-holder">
                        {sharedMode && <React.Fragment>
                            <span>Export</span>
                            <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                            <span>PDF</span>
                            <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                        </React.Fragment>}                        
                        {!sharedMode && <SharePage entity="" id="" module="home" exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} />}
                    </div>
                </div>
                <CatalogOverview view="home" />
                {/* <Grid layouts={gridLayouts} components={{EntitiesList, TerritoriesWidget, VendorsWidget, TabContainer}}>
                    {[
                      {i: 'top_imprints', component: "EntitiesList", props: {title: "Top Imprints", entity: "imprints" }, breakpoints: ['lg', 'md']},
                      {i: 'top_artists', component: "EntitiesList", props: {title: "Top Artists", entity: "artists" }, breakpoints: ['lg', 'md']},
                      {i: 'top_products', component: "EntitiesList", props: {title: "Top Products", entity: "products" }, breakpoints: ['lg', 'md']},
                      {i: 'top_tracks', component: "EntitiesList", props: {title: "Top Tracks", entity: "tracks" }, breakpoints: ['lg', 'md']},
                      {i: 'top_playlists', component: "EntitiesList", props: {title: "Top Playlists", entity: "playlists"}, breakpoints: ['lg', 'md']},
                      {i: 'top_territories', component: "TerritoriesWidget", props: {}, breakpoints: ['lg', 'md']} ,
                      {i: 'top_vendors', component: "VendorsWidget", props: {shadowChartProps: {id: 'home_vendors', shadowChartWidth: 800, shadowChartHeight: 400}}, breakpoints: ['lg', 'md']},
                      {i: 'tab_container_entities', component: "TabContainer", props: entitiesTabProps, breakpoints: ['sm', 'xs']},
                      {i: 'tab_container_extra', component: "TabContainer", props: extraTabProps, breakpoints: ['sm', 'xs']},
                  ]}
                </Grid> */}
                {/* <div className="container container-small transparent">
                    <SearchWidget searchResult={this.searchResult}/>
                </div> */}
                <Widget widgetID="top_imprints" widgetProps={widgetProps}>
                    <EntitiesList title="Top Imprints" entity="imprints" shadowChartProps={{id: 'top_imprints', shadowChartWidth: 800, shadowChartHeight: 400}} />
                </Widget>
                <Widget widgetID="top_artists" widgetProps={widgetProps}>
                    <EntitiesList title="Top Artists" entity="artists" shadowChartProps={{id: 'top_artists', shadowChartWidth: 800, shadowChartHeight: 400}} />
                </Widget>
                <Widget widgetID="top_projects" widgetProps={widgetProps}>
                    <EntitiesList title="Top Projects" entity="projects" shadowChartProps={{id: 'top_projects', shadowChartWidth: 800, shadowChartHeight: 400}} />
                </Widget>
                    
                <Widget widgetID="top_products" widgetProps={widgetProps}>
                    <EntitiesList title="Top Products" entity="products" shadowChartProps={{id: 'top_products', shadowChartWidth: 800, shadowChartHeight: 400}} />
                </Widget>
                <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                    <EntitiesList title="Top Tracks" entity="tracks" shadowChartProps={{id: 'top_tracks', shadowChartWidth: 800, shadowChartHeight: 400}} />
                </Widget>
                <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                    <EntitiesList title="Top Playlists" entity="playlists" shadowChartProps={{id: 'top_playlists', shadowChartWidth: 800, shadowChartHeight: 400}} />
                </Widget>
                <div className="widget-row widget-row--half-width">
                    <Widget widgetID="top_territories" widgetProps={widgetProps}>
                        <TerritoriesWidget shadowChartProps={{id: 'top_territories', shadowChartWidth: 800, shadowChartHeight: 400}} />
                    </Widget>
                    <Widget widgetID="vendors" widgetProps={widgetProps}>
                        <VendorsWidget shadowChartProps={{id: 'home_vendors', shadowChartWidth: 800, shadowChartHeight: 400}} />
                    </Widget>
                </div>
                <Helmet>
                    <title>Overview</title>
                </Helmet>

            </div>
        )
    }

}


function mapStateToProps(state) {
  const {
    playlists,
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  } = state;

  return {
    playlists,
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        setFilter: (entity, filter) => {
            return dispatch(filterActions.setEntityFilter(entity, filter));
        }
    }
}

export default connect(mapStateToProps)(WithWidgetManager(Home, 'home'))