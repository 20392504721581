export const gridsConstants = {
    GET_GRIDS_REQUEST: 'GET_GRIDS_REQUEST',
    GET_GRIDS_SUCCESS: 'GET_GRIDS_SUCCESS',
    GET_GRIDS_FAILURE: 'GET_GRIDS_FAILURE',
    ADD_GRID_REQUEST: 'ADD_GRID_REQUEST',
    ADD_GRID_SUCCESS: 'ADD_GRID_SUCCESS',
    ADD_GRID_FAILURE: 'ADD_GRID_FAILURE',
    SAVE_DEFAULT_GRID_REQUEST: 'SAVE_DEFAULT_CHANNEL_REQUEST',
    SAVE_DEFAULT_GRID_SUCCESS: 'SAVE_DEFAULT_GRID_CHANNEL_SUCCESS',
    SAVE_DEFAULT_GRID_FAILURE: 'SAVE_DEFAULT_GRID_CHANNEL_FAILURE',
}