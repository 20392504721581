import { notificationReportsService } from '../services/notification_reports';
import { productsActions } from '../actions/products';
import { notificationReportsConstants } from '../constants/notification_reports';
import { notificationReportsFormatter } from '../formatters/notification_reports';

export const notificationReportsActions = {
  getNotificationReports,
  getUserNotificationSettings,
  getFrequencies,
  createUserNotification,
  updateUserNotification,
  deleteUserNotification
};

function getNotificationReports(){
  return dispatch => {
    dispatch(request());

    return notificationReportsService.getNotificationReports()
    .then( response => {
      dispatch(success(response.data));
      return response.data;
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request() { return { type: notificationReportsConstants.GET_NOTIFICATION_REPORTS_REQUEST }};
  function success(payload) { return { type: notificationReportsConstants.GET_NOTIFICATION_REPORTS_SUCCESS, payload }};
  function failure(error) { return { type: notificationReportsConstants.GET_NOTIFICATION_REPORTS_FAILURE, payload: error }};
}



function getUserNotificationSettings(){
  return dispatch => {
    dispatch(request());

    return notificationReportsService.getUserNotificationSettings()
    .then( response => {
//      const notificationReportsProducts = response.data.find( report => report.settings.products !== undefined);
//      const notificationReportsProductIds = notificationReportsProducts ? notificationReportsProducts.settings.products : '';
//      
//      dispatch(productsActions.getProductsByBarcode(notificationReportsProductIds));
      const settings = notificationReportsFormatter.formatSettings(response.data);
      dispatch(success(settings));
      return settings;
    })
    .catch( error => {
        console.log(error);
      dispatch(failure(error.message));
    })
  }

  function request(){ return { type: notificationReportsConstants.GET_NOTIFICATION_SETTINGS_REQUEST }};
  function success(payload){ return { type: notificationReportsConstants.GET_NOTIFICATION_SETTINGS_SUCCESS, payload }};
  function failure(error){ return { type: notificationReportsConstants.GET_NOTIFICATION_SETTINGS_FAILURE, payload: error}};
}

function getFrequencies(){
  return dispatch => {
    dispatch(request());

    return notificationReportsService.getFrequencies()
    .then( response => {
        const frequencies = notificationReportsFormatter.formatFrequencies(response.data); 
      dispatch(success(frequencies));
      return frequencies;
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request(){ return { type: notificationReportsConstants.GET_FREQUENCIES_REQUEST }};
  function success(payload){ return { type: notificationReportsConstants.GET_FREQUENCIES_SUCCESS, payload }};
  function failure(error){ return { type: notificationReportsConstants.GET_FREQUENCIES_FAILURE, payload: error}};
}

function createUserNotification(notification, cb) {
    return dispatch => {
      dispatch(request());
      
      notification = notificationReportsFormatter.formatNewSettings(notification);

      return notificationReportsService.createUserNotification(notification)
      .then( response => {
        dispatch(success(response.data));
        if (cb && typeof cb === 'function') {
          cb();
        }
        else
            return true;
      })
      .catch( error => {
        dispatch(failure(error.message));
      })
    }

    function request(){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_REQUEST }};
    function success(payload){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS, payload }};
    function failure(error){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_FAILURE, payload: error}};
  }


function updateUserNotification(notification, cb) {
  return dispatch => {
    dispatch(request());
    notification = notificationReportsFormatter.formatNewSettings(notification);
    return notificationReportsService.updateUserNotification(notification)
    .then( response => {
      dispatch(success(response.data));
      if (cb && typeof cb === 'function') {
        cb();
      }
      else
          return true;
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request(){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_REQUEST }};
  function success(payload){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS, payload }};
  function failure(error){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_FAILURE, payload: error}};
}


function deleteUserNotification(id, cb) {
    return dispatch => {
      dispatch(request());

      return notificationReportsService.deleteUserNotification(id)
      .then( response => {
        dispatch(success(response.data));
        if (cb && typeof cb === 'function') {
          cb();
        }
        else
            return true;
      })
      .catch( error => {
        dispatch(failure(error.message));
      })
    }

    function request(){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_REQUEST }};
    function success(payload){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS, payload }};
    function failure(error){ return { type: notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_FAILURE, payload: error}};
  }
