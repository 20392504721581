import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { Prompt } from 'react-router-dom';
import { get, cloneDeep, isEqual } from 'lodash';
import NotificationReport from '../../components/pages/notifications/Report';
import { notificationReportsActions } from '../../data/actions/notification_reports';
import { errorActions } from '../../data/actions/error';
import Spinner from '../../components/common/Spinner';
import MailingListsByRecipient from '../../components/pages/mailingLists/MailingListsByRecipient';

export class NotificationSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            initialState: {
                settings: {},
                reports: [],
                frequencies: []
            }
        }
        this.saveSettings = this.saveSettings.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.onSettingsChanged = this.onSettingsChanged.bind(this);
        this.onFrequencyChanged = this.onFrequencyChanged.bind(this);
    }
    
    componentDidMount(){
        const { dispatch } = this.props;
        Promise.all([
             dispatch(notificationReportsActions.getUserNotificationSettings()),
             dispatch(notificationReportsActions.getNotificationReports()),
             dispatch(notificationReportsActions.getFrequencies())
        ]).then(([settings, reports, frequencies])=>{
            this.setState({
                initialized: true,
                settings, 
                reports,
                frequencies,
                initialState: {
                    settings: cloneDeep(settings),
                    reports: cloneDeep(reports),
                    frequencies: cloneDeep(frequencies)
                }
            })
        });

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }
    
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    updateSettings(report, key, value) {
        let settings = this.state.settings[report];
        if(!settings) {
            settings = {report_id: report, frequency: 3};
        }
        settings[key] = value;
        this.setState({
            ...this.state,
            settings: {
                ...this.state.settings,
                [report]: settings
            }
        }, ()=>{
        })
    }
    onSettingsChanged(report, settings) {
        this.updateSettings(report, 'settings', settings);
    }
    
    onFrequencyChanged(report, frequency) {
        this.updateSettings(report, 'frequency', frequency);
    }

    handleBeforeUnload = (event) => {
        const { initialState, settings, reports, frequencies } = this.state;
        
        if (!isEqual(initialState.settings, settings) ||
            !isEqual(initialState.reports, reports) ||
            !isEqual(initialState.frequencies, frequencies)) {
            event.preventDefault();
            event.returnValue = '';
            return null;
        }
    }
    
    saveSettings(){
        const { dispatch } = this.props,
            { settings: settingsDictionary } = this.state;
            
        let calls = [];
        for(let settings of Object.values(settingsDictionary)){
            if(!settings.settings)
                continue;
            
            if(!settings.id)
                calls.push(dispatch(notificationReportsActions.createUserNotification(settings)));
            else
                calls.push(dispatch(notificationReportsActions.updateUserNotification(settings)));    
        }
        Promise.all(calls).then(()=>{
            dispatch(notificationReportsActions.getUserNotificationSettings())
            .then(settings=>{
				dispatch(errorActions.setSuccessMessage('Notification Settings Saved'));
                this.setState({
                    settings,
                    initialState: {
                        ...this.state.initialState,
                        settings: cloneDeep(settings)
                    }
                });
            });
        });
    }
    
    
    render(){
      const { initialized, reports = [], settings: settingsList = {} } = this.state;
      const hasUnsavedChanges = !isEqual(this.state.initialState.settings, this.state.settings) ||
                                 !isEqual(this.state.initialState.reports, this.state.reports) ||
                                 !isEqual(this.state.initialState.frequencies, this.state.frequencies);
      return(
          <div className="homepage">
                <Prompt
                    when={hasUnsavedChanges}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
              <div className="ibox spinner-absolute">
                <div className="NotificationReportHeader">
                  <h3> Set up the emails you will receive for different reports </h3>
                  <button className="default-btn default-btn--small mt-20" onClick={this.saveSettings}>Save Notifications</button>
                  {/*<button type="submit" onClick={this.saveSettings} className="chat-submit"><i className="fas fa-save"></i></button>*/}
                </div>
                {reports.map(report=>{
                    let settings = settingsList[report.id];
                    return <NotificationReport key={report.id} report={report} settings={settings} onSettingsChanged={(value)=>this.onSettingsChanged(report.id, value)} onFrequencyChanged={(value)=>this.onFrequencyChanged(report.id, value)} />
                })}
                <Spinner enabled={!initialized} />
              </div>
              <div>
                  <MailingListsByRecipient />
              </div>
              <Helmet>
                  <title>Notifications - User Preferences</title>
              </Helmet>
              
          </div>
      )
  }
};


function mapStateToProps( state ) {
  return {
    products: state.products.data,
    reports: state.notification_reports
  };
}

export default connect(mapStateToProps)(NotificationSettings);