import React from 'react';

export default function Accordion(props) {
    const opened = props.accordion[props.id];
    const hidden = props.matchingIds && !props.matchingIds.includes(props.id);
    
    return <div className={`form-group ${props.className} ${opened ? 'opened' : ''} ${hidden ? 'hidden' : ''}`}>          
        <label className="font-normal filter-label" onClick={()=>props.toggleAccordion(props.id)}>{props.label}<span className={`caret ${opened && 'opened'}`}></span></label>
        {opened && <div className="form-group-inner">
            {props.children}
        </div>}
    </div>
    
}
