export const projectsConstants = {
    GET_PROJECTS_REQUEST: 'GET_PROJECTS_REQUEST',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
    GET_PROJECTS_AUTOCOMPLETE_REQUEST: 'GET_PROJECTS_AUTOCOMPLETE_REQUEST',
    GET_PROJECTS_AUTOCOMPLETE_SUCCESS: 'GET_PROJECTS_AUTOCOMPLETE_SUCCESS',
    GET_PROJECTS_AUTOCOMPLETE_FAILURE: 'GET_PROJECTS_AUTOCOMPLETE_FAILURE',
    GET_TOP_PROJECTS_REQUEST: 'GET_TOP_PROJECTS_REQUEST',
    GET_TOP_PROJECTS_SUCCESS: 'GET_TOP_PROJECTS_SUCCESS',
    GET_TOP_PROJECTS_FAILURE: 'GET_TOP_PROJECTS_FAILURE',
    GET_PROJECT_DETAILS_REQUEST: 'GET_PROJECT_DETAILS_REQUEST',
    GET_PROJECT_DETAILS_SUCCESS: 'GET_PROJECT_DETAILS_SUCCESS',
    GET_PROJECT_DETAILS_FAILURE: 'GET_PROJECT_DETAILS_FAILURE',
    GET_PROJECTS_CARD_REQUEST: 'GET_PROJECTS_CARD_REQUEST',
    GET_PROJECTS_CARD_SUCCESS: 'GET_PROJECTS_CARD_SUCCESS',
    GET_PROJECTS_CARD_FAILURE: 'GET_PROJECTS_CARD_FAILURE',
    GET_STREAMS_STATS_REQUEST: 'GET_PROJECTS_STREAMS_STATS_REQUEST',
    GET_STREAMS_STATS_SUCCESS: 'GET_PROJECTS_STREAMS_STATS_SUCCESS',
    GET_STREAMS_STATS_FAILURE: 'GET_STREAMS_STATS_FAILURE',
    GET_PROJECTS_BY_BARCODES_REQUEST: 'GET_PROJECTS_BY_BARCODES_REQUEST',
    GET_PROJECTS_BY_BARCODES_SUCCESS: 'GET_PROJECTS_BY_BARCODES_SUCCESS',
    GET_PROJECTS_BY_BARCODES_FAILURE: 'GET_PROJECTS_BY_BARCODES_FAILURE',
    GET_TRACKS_REQUEST: 'GET_TRACKS_REQUEST',
    GET_TRACKS_SUCCESS: 'GET_TRACKS_SUCCESS',
    GET_TRACKS_FAILURE: 'GET_TRACKS_FAILURE',
    GET_TIMESERIES_PROJECTS_REQUEST: 'GET_TIMESERIES_PROJECTS_REQUEST',
    GET_TIMESERIES_PROJECTS_SUCCESS: 'GET_TIMESERIES_PROJECTS_SUCCESS',
    GET_TIMESERIES_PROJECTS_FAILURE: 'GET_TIMESERIES_PROJECTS_FAILURE',
    COMPARE_PROJECTS_REQUEST: 'COMPARE_PROJECTS_REQUEST',
    COMPARE_PROJECTS_SUCCESS: 'COMPARE_PROJECTS_SUCCESS',
    COMPARE_PROJECTS_FAILURE: 'COMPARE_PROJECTS_FAILURE',
    COMPARE_PROJECT_DEMOGRAPHICS_REQUEST: 'COMPARE_PROJECT_DEMOGRAPHICS_REQUEST',
    COMPARE_PROJECT_DEMOGRAPHICS_SUCCESS: 'COMPARE_PROJECT_DEMOGRAPHICS_SUCCESS',
    COMPARE_PROJECT_DEMOGRAPHICS_FAILURE: 'COMPARE_PROJECT_DEMOGRAPHICS_FAILURE',
    COMPARE_PROJECT_TERRITORIES_REQUEST: 'COMPARE_PROJECT_TERRITORIES_REQUEST',
    COMPARE_PROJECT_TERRITORIES_SUCCESS: 'COMPARE_PROJECT_TERRITORIES_SUCCESS',
    COMPARE_PROJECT_TERRITORIES_FAILURE: 'COMPARE_PROJECT_TERRITORIES_FAILURE',
    COMPARE_PROJECT_VENDORS_REQUEST: 'COMPARE_PROJECT_VENDORS_REQUEST',
    COMPARE_PROJECT_VENDORS_SUCCESS: 'COMPARE_PROJECT_VENDORS_SUCCESS',
    COMPARE_PROJECT_VENDORS_FAILURE: 'COMPARE_PROJECT_VENDORS_FAILURE',    
    GET_PROJECTS_RELEASE_DATES_REQUEST: 'GET_PROJECTS_RELEASE_DATES_REQUEST',
    GET_PROJECTS_RELEASE_DATES_SUCCESS: 'GET_PROJECTS_RELEASE_DATES_SUCCESS',
    GET_PROJECTS_RELEASE_DATES_FAILURE: 'GET_PROJECTS_RELEASE_DATES_FAILURE',    
    COMPARE_PROJECT_ARTIST_REQUEST: 'COMPARE_PROJECT_ARTIST_REQUEST',
    COMPARE_PROJECT_ARTIST_SUCCESS: 'COMPARE_PROJECT_ARTIST_SUCCESS',
    COMPARE_PROJECT_ARTIST_FAILURE: 'COMPARE_PROJECT_ARTIST_FAILURE',

}
