import { find } from 'lodash';

import store from '../../helpers/store'
import crypto from '../../helpers/crypto'

import { filterActions } from './filter';
import { userConstants } from '../constants/user';
import { errorConstants } from '../constants/error';
import { userFormatter } from '../formatters/user';
import { globalFilterFormatter } from '../formatters/global_filter';
import { userEntityFiltersFormatter } from '../formatters/user_entity_filters'
import { userService } from '../services/user';
import { history } from '../../helpers/history';

export const userActions = {
    login,
    sso,
    logout,
    sendTicket,
    track,
    uploadAvatar,
    shareLogin,
    setHomePage
};

function login(params) {
    return dispatch => {
        dispatch(request());
        //store.set('email', params.email);
        userService.login(params)
            .then(user => {
                    user = userFormatter.formatUser(user);
                    store.set('user', user);
                    if(user.default_layout)
                        store.set('store_homepage_id', user.default_layout);
                    if(user.default_layout_content && (Array.isArray(user.default_layout_content) || user.default_layout_content.hasOwnProperty('lg')))
                        store.set('store_homepage', user.default_layout_content);
                    
                    return dispatch(filterActions.getFilters())
                    .then((filters)=>{
                        const { default_filter: defaultFilterID } = user;
                        const currentFilter = find(filters, filter=>filter.id == defaultFilterID);
                        // TODO: fix and remove

                        if(currentFilter && currentFilter.filter_body){
                            let { filter_body } = currentFilter;
                            if(filter_body.hasOwnProperty('filter_body'))
                                filter_body = filter_body.filter_body;
                            store.set('currentFilter', filter_body);
                            store.set('currentFilterID', defaultFilterID);
                        }
                        dispatch(success(user))
                    });
                    
                }
            )
            .catch(error => {
                console.log(error);
                dispatch(failure(errorMessage));
                let errorMessage = 'API error';
                switch(error.status){
                    case 400:
                        errorMessage = 'Please provide both email and password';
                        break;
                    case 401:
                        errorMessage = 'Email and password combination is invalid';
                        break;
                }
                dispatch(failureMessage(errorMessage));

            });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user} }
    function failureMessage(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: 'login error' } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function sso(token) {
    return dispatch => {
        dispatch(request());
        userService.sso(token)
            .then(user => {
                store.set('user', user);
                dispatch(success(user));
            })
            .catch(error => {
                error.json().then(err=>{console.log(err); dispatch(failure(err.err));});
            });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user} }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function logout() {
    return dispatch => {
        //store.clearAll();
        store.each((value, key)=>{
            //if(!key.startsWith('store_'))
                store.remove(key);
        });
        dispatch(success());
        history.push('/login');

    };
    function success() { return { type: userConstants.LOGOUT_SUCCESS } }
}

function sendTicket(params) {
    return dispatch => {
        return userService.sendTicket(params)
        .then(res => {
                dispatch(success('ticket was successfully submitted'));
                return true;
            }
        )
        .catch(error => {
            dispatch(failure('there was an error'));
        });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(message) { return { type: errorConstants.SET_SUCCESS_MESSAGE, message, title: '' } }
    function failure(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: 'send ticket error' } }
}

function track(path, page_title, params) {
    return dispatch => {
        userService.track({path, page_title, params});
    }
}


function uploadAvatar(params) {
    return dispatch => {
        userService.uploadAvatar(params)
        .then(res => {
                const { avatar_url } = res;
                dispatch(success('Avatar Uploaded'));
                dispatch(uploadSuccess(res.avatar_url));
                //
                let user = store.get('user');
                user.avatar_url = avatar_url;
                store.set('user', user);
            }
        )
        .catch(error => {
            dispatch(failure('Error Uploading Avatar'));
        });
    };

    function request() { return { type: userConstants.AVATAR_UPLOAD_REQUEST } }
    function success(message) { return { type: errorConstants.SET_SUCCESS_MESSAGE, message, title: '' } }
    function uploadSuccess(avatar) { return { type: userConstants.AVATAR_UPLOAD_SUCCESS, avatar } }
    function failure(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: '' } }
}

function shareLogin(slug, qs) {
    return dispatch => {
        dispatch(request());
        return userService.shareLogin(slug)
            .then(shareData => {
                let { user_data: userData, filter_data: filterData, entities } = shareData;
                userData = JSON.parse(crypto.decrypt(userData));
                store.set('user', userData);
                dispatch(success(userData));
                filterData = globalFilterFormatter.formatSharedGlobalFilter(filterData, qs);
                dispatch(filterActions.setGlobalFilter(filterData));
                //dispatch filter
                if(entities) {
                    const params = userEntityFiltersFormatter.stringifyFilter({entities}); 
                    shareData.params = params;
                }
                
                return shareData;
            })
            .catch(error => {
                let errorMessage = 'API error';
                if(typeof error == 'string')
                    errorMessage = error;
                else {
                    
                    // todo: move to service
                    switch(error.status){
                        case 404:
                            errorMessage = 'This link is expired';
                            break;
                        default:
                            errorMessage = error.message;
                    }
                }
                dispatch(failure(errorMessage));
                
                throw errorMessage;

            });
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user} }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function setHomePage(page) {
    return dispatch => {
        userService.setHomePage(page)
        .then(res => {
                let user = store.get('user');
                user.landing = page;
                store.set('user', user);
                dispatch(success('Landing Page Changed'));
                dispatch(updateHomepage(page));
            }
        )
        .catch(error => {
            dispatch(failure('Error Changing Landing Page'));
        });
    };

    function request() { return { type: userConstants.SET_HOME_PAGE_REQUEST } }
    function success(message) { return { type: errorConstants.SET_SUCCESS_MESSAGE, message, title: '' } }
    function updateHomepage(page) { return { type: userConstants.SET_HOME_PAGE_SUCCESS, page } }
    function failure(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title: '' } }
}
