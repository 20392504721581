import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../data/actions/user';

class AvatarUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null
        }
        this.selectAvatar = this.selectAvatar.bind(this);
        this.uploadAvatar = this.uploadAvatar.bind(this);
    }
    
    avatarSize = 48;
    
    selectAvatar(e) {
        const file = e.target.files[0];
        this.setState({file});
        const max_size = this.avatarSize;
        
        if(file.type.match(/image.*/)) {
            var reader = new FileReader();
            reader.onload = function (readerEvent) {
                var image = new Image();
                image.onload = function (imageEvent) {

                    // Resize the image
                    var canvas = document.getElementById('new-user-avatar'),
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    // resizedImage = canvas.toDataURL('image/jpeg');
                }
                image.src = readerEvent.target.result;
            }
            reader.readAsDataURL(file);
        }
    }
    
    uploadAvatar() {
        this.props.uploadAvatar(this.state.file);        
    }
    
    render() {
        return <div>
            <p className="bold">User Avatar: </p>
            <div>
                <input type="file" name="avatar" id="user_avatar" className="input-file" onChange={this.selectAvatar} />
                <label htmlFor="user_avatar" className="btn btn-w-m btn-default upload-label">Select File</label>
                {this.state.file && <button className="btn btn-w-m btn-default upload-label" onClick={this.uploadAvatar}>Upload</button> }
            </div>
            <canvas id="new-user-avatar" className="new-user-avatar" width={this.avatarSize} height={this.avatarSize} />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}

function mapDispatchToProps(dispatch) {
    return {
        uploadAvatar: (avatar) => dispatch(userActions.uploadAvatar(avatar))
    } 
}



export default connect(mapStateToProps, mapDispatchToProps)(AvatarUpload)
