import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, cloneDeep, get } from 'lodash';
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import DateGroupSelector from './DateGroupSelector';
import CSV from '../../../helpers/CSVExport';
import Tooltip from "../../common/Tooltip";

import { tracksActions } from '../../../data/actions/tracks';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class TiktokTimeseries extends React.Component {
	
	nullElement = {vend_id: 0, value: 'all', label: 'All'}
	
	constructor(props) {
		super(props);
		this.state = {
			vendor: this.nullElement,
			view: props.defaultMode || 'views',
			dateGroup: null
		}
		
		this.setVendor = this.setVendor.bind(this);
		this.setView = this.setView.bind(this);
		this.setDateGroup = this.setDateGroup.bind(this);
		this.exportToCsv = this.exportToCsv.bind(this);
	}

	setVendor(vendor) {
		this.setState({vendor});
	}

	setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>{
            this.props.getTiktokTimeseries(this.props.filtered, this.state.dateGroup);
        });
    }

	setView({value}) {
		this.setState({view: value});
		const { modeChange } = this.props;
		if(typeof modeChange == 'function')
			modeChange(value);
	}
	
	renderToolbar(data){
		const viewOptions = [{label: 'Views', value: 'views'}, {label: 'Creates', value: 'creates'}];
		const viewValue = find(viewOptions, option=>option.value == this.state.view);
		let toolbar = [];
		toolbar.push(<div key="wrapper" className="ibox-action-holder">
		<div className="ibox-actions">
			<ReactSelect value={viewValue} options={viewOptions} onChange={this.setView} className="single-select"/>
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		</div>
		</div>)

		return toolbar;
	}
	
    exportToCsv(){
        const filename = CSV.CSVHeader('social_timeseries', '', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.tracks.tiktokTimeseries.exportData[this.state.view], {filename}, 'social_timeseries');
    }

	
	
	componentDidMount() {
		this.props.getTiktokTimeseries(this.props.filtered, this.state.dateGroup);
	}
	
	componentWillReceiveProps(nextProps){
		if(nextProps.filter && nextProps.filter.global){
			if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
				this.props.getTiktokTimeseries(this.props.filtered, this.state.dateGroup);
			}                
		}
		if(nextProps.filtered && !isEqual(nextProps.filtered, this.props.filtered)) {
			this.props.getTiktokTimeseries(nextProps.filtered, this.state.dateGroup);
		} 
	}
	
    renderChartActions(data){
        const { mode } = this.state;
        let vendors = [this.nullElement];
        if(data) {
            for(let vendor of data) {
                vendors.push(vendor);
            }
        }
          
        return <React.Fragment>
			<div className="entity-selector-select">
			    <ReactSelect value={this.state.vendor} options={vendors} onChange={this.setVendor} className="single-select"/>    
			</div>
            <DateGroupSelector dateGroupChange={this.setDateGroup}/>        
        </React.Fragment>
    }

		renderTooltip() {
			let tooltip = [];
			tooltip.push(<React.Fragment key="tooltip">
					<div className="table-header-tooltip">
							<Tooltip 
									position="bottom" 
									message={`We are not able to display this currently at product level in order to protect against data duplications. Please view these at the Track level.`} 
									tooltipClass="toolbar-title-tooltip"
							/>
					</div>
			</React.Fragment>
			)

			return tooltip;
	}
	
	render (){  
		const {tiktokTimeseries, tiktokTimeseriesLoading} = this.props.tracks,
			widgetTitle = `Social Creates and Views`;
		const { view, vendor } = this.state;

		let chartData = cloneDeep(tiktokTimeseries);
		if(chartData) {
			chartData.datasets = chartData.datasets[view].filter(dataset => dataset.vendor == vendor.vend_id);
		}
		
		let { shadowChartProps } = this.props;
		if(shadowChartProps){
			shadowChartProps.shadowChart = true;
		}     
		const vendorsList = tiktokTimeseries ? tiktokTimeseries.vendors : [];
		let milestonesItems = get(this.props.milestones, 'entities.mixed.tableItems', [])
		return <Box title={widgetTitle} chartActions={this.renderChartActions(vendorsList)}  toolbar={this.renderToolbar()} spinnerEnabled={tiktokTimeseriesLoading} hasTooltip={true} loadingTooltip={this.renderTooltip()} >
			{(chartData && chartData.datasets) && 
				<div className="chart-block">
			        
					{chartData.datasets && <LineChartWithAnnotations milestones={milestonesItems} data={chartData} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} period={this.state.period} shadowChartProps={shadowChartProps} />}
				</div>
			}
		</Box>;
	}
}

function mapStateToProps(state) {
	return {
		stats: state.stats,
	} 
}

function mapStateToProps(state) {
	return {
		tracks: state.tracks,
		filter: state.filter,
		milestones: state.milestones
	} 
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		getTiktokTimeseries: (filtered, dateGroup) => {
			return dispatch(tracksActions.getTiktokTimeseries(filtered, dateGroup));
		} 
	} 
}



export default connect(mapStateToProps, mapDispatchToProps)(TiktokTimeseries);