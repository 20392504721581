import React from 'react';
import moment from 'moment';
import { filter, cloneDeep } from 'lodash';
import { colors } from '../components/widgets/charts/ChartDefaults';
import { milestonesFormatter } from '../data/formatters/milestones'

export {
    addMilestoneDataset,
    addComparisonMilestoneDataset
}

function labelDateMilestonesFormatter(period, label) {
    const dateLabel = moment(label);
    const year = dateLabel.format('YYYY');
    var week; 
    switch(period) {
        case 'Chart Week':
            week = dateLabel.day(5).week();
            return `Ch. Wk. #${week}, ${year}`;
        case 'Calendar Week':
            week = dateLabel.day(0).week();
            return `Cal. Wk. #${week}, ${year}`;
        case 'Month':
            return dateLabel.format('MMM YYYY');
        default:
            return dateLabel.format('DD MMM');
    }
}

function addMilestoneDataset(datasets, labels, sourceMilestones, onClick, period, show, leave, globalFilter){
    let milestones = cloneDeep(sourceMilestones);
    let annotations = {};

    if(Array.isArray(milestones) && milestones.length) {
        milestones = milestonesFormatter.setWithinRange(milestones, globalFilter);
        milestones = milestones.filter(milestone=>milestone.withinRange);
        for(let milestone of milestones) {
            milestone.occurs_at = labelDateMilestonesFormatter(period, milestone.occurs_at)
            if(milestone.ends_at)
                milestone.ends_at = labelDateMilestonesFormatter(period, milestone.ends_at)
        }
        for(let labelID in labels) {
            const label = labels[labelID];
            const milestonesForDate = filter(milestones, (milestone)=>(milestone.occurs_at == label || milestone.ends_at == label));
            if(milestonesForDate && milestonesForDate.length) {
                //dataset.push(0);
                //addDataset = true;
                //mappedMilestones[label] = milestonesForDate;
                annotations[label] = {
                    type: 'point',
                    backgroundColor: '#FFA500', //colors[labelID%colors.length],
                    borderColor: 'black',
                    borderWidth: 1,
                    pointStyle: 'circle',
                    radius: 5,
                    xValue: label,
                    xScaleID: 'x',
                    yAdjust: 7,
                    yValue: 0,
                    yScaleID: 'y',
                    milestones: milestonesForDate,
                    click: ()=>onClick(milestonesForDate, label),
                    enter: ()=>show(milestonesForDate, label),
                };
            }
            //else
                //dataset.push(null);
        }
    }
    
    return annotations;
}

function calculateWeekDifference(releaseDate, occursAt) {
    const releaseMoment = moment(releaseDate);
    const occursAtMoment = moment(occursAt);
    const differenceInWeeks = occursAtMoment.diff(releaseMoment, 'weeks');
    return differenceInWeeks;
}

function addComparisonMilestoneDataset(labels, metadata, sourceMilestones, onClick, show) {
    let milestones = cloneDeep(sourceMilestones);
    let annotations = {};
    let milestonesArray = [];
    let occursAt, weekDifference, milestonesModifiedArray;
    
    metadata.forEach(item => {
        const milestone = milestones && milestones.comparisonMilestones[item.id.toString()];
        if (milestone && milestone.milestones && milestone.milestones.length > 0) {
            milestone.milestones.forEach(function(el) {
                occursAt = el.occurs_at;
                weekDifference = calculateWeekDifference(item.release_date, occursAt);
                milestonesModifiedArray = {weekDifference, milestone: el};
                milestonesArray.push(milestonesModifiedArray)
            })
        }
    });

    for(let labelID in labels) {
        const label = labels[labelID];
        const milestonesForDate = filter(milestonesArray, (milestone)=>(milestone.weekDifference == label));
        const milestonesForAnnotation = milestonesForDate.map(item => item.milestone);

        if(milestonesForAnnotation && milestonesForAnnotation.length) {
            annotations[label] = {
                type: 'point',
                backgroundColor: '#FFA500', 
                borderColor: 'black',
                borderWidth: 1,
                pointStyle: 'circle',
                radius: 5,
                xValue: label,
                xScaleID: 'x',
                yAdjust: 7,
                yValue: 0,
                yScaleID: 'y',
                milestones: milestonesForAnnotation,
                click: ()=>onClick(milestonesForAnnotation, label),
                enter: ()=>show(milestonesForAnnotation, label),
            };
        }
    }

    return annotations;
}