import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, map, find } from 'lodash';
import ReactTable from 'react-table';
import { productsActions } from '../../../../data/actions/products';
import SearchWidget from '../../home/SearchWidget';
import Modal from '../../../common/Modal';
import { productsSettings } from './PlaylistAdditions/Settings';

class PlaylistAdditionsReport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
				productsTable: [],
				showManageProductsModal: false
        }
        this.setValue = this.setValue.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.submitProducts = this.submitProducts.bind(this);
		this.deleteProduct = this.deleteProduct.bind(this);
		this.searchResult = this.searchResult.bind(this);
    }
    
    componentDidMount() {
        const { settings } = this.props;
        this.setState({
            ...this.state,
            ...settings
        });
        if(settings.products){
            this.props.dispatch(productsActions.getProductsByBarcode(settings.products, true)).then(productsTable=>{
				this.setState({productsTable});		
			});
		}
    }
	
	toggleModal(show){
		this.setState({'showManageProductsModal': show});
	}
	
	searchResult(value) {
		if(find(this.state.productsTable, item=>item.barcode == value.code))
			return;
		this.setState({
			productsTable: [
				{barcode: value.code, title: value.name},
				...this.state.productsTable
			]
		})
	}
	
	deleteProduct(barcode) {
		let productsTable = this.state.productsTable.filter(item=>item.barcode != barcode);
		this.setState({productsTable});
	}
	
	submitProducts() {
		let products = map(this.state.productsTable, 'barcode').join(',');
		this.setValue({target: {value: products}}, 'products');
		this.toggleModal(false);
	}
    
    setValue(e, key) {
        const { value } = e.target;
        const { onValueChanged } = this.props;
        this.setState({
            ...this.state,
            [key]: value
        }, ()=>{
            onValueChanged(this.state)
        })
    }
    
    render (){
        const { avg_streams = 10, products, productsTable, showManageProductsModal } = this.state;
		const { products: {loading: productsLoading}} = this.props;
        return <div>
			<label htmlFor="">
				Streams
            	<input type="number" min={10} value={avg_streams} onChange={(e)=>this.setValue(e, 'avg_streams')} className="form-control" /> 
			</label>
			<Modal submitTitle="OK" handleSubmit={this.submitProducts} title="Products" show={showManageProductsModal} handleClose={()=>this.toggleModal(false)}>
				<SearchWidget searchResult={this.searchResult} placeholder="Add product" entities={['products']} mode="playlist" />
				<ReactTable data={productsTable} loading={productsLoading} columns={productsSettings.settingsForTable(this.deleteProduct)} pageSize={5} />
			</Modal>
			<button onClick={()=>this.toggleModal(true)}>Manage Products</button>
        </div>
    }
}

function mapStateToProps( state ) {
    return {
		products: state.products,
    };
}

export default connect(mapStateToProps)(PlaylistAdditionsReport);