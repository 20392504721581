import React, { Component } from 'react';
import Chart from '../components/widgets/charts/Chart';
const topXData = require('../data/topx.json');

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topX: topXData
        };
    }

    render() {
        return (
            <div className="">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <Chart data={this.state.topX.artists} mode="bar" labels={{header: 'Top Artists', titleLabel: 'Artist', valueLabel: 'plays'}} >
                        </Chart>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Chart data={this.state.topX.tracks} mode="pie" labels={{header: 'Top Tracks', titleLabel: 'Track', valueLabel: 'plays'}} >
                        </Chart>                        
                    </div>                    
                </div>    
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <Chart data={this.state.topX.vendors} mode="line" labels={{header: 'Top Platforms', titleLabel: 'Vendor', valueLabel: 'plays'}}>
                        </Chart>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Chart data={this.state.topX.territories} mode="world-map" labels={{header: 'Top Territories', titleLabel: 'Territory', valueLabel: 'plays'}} >
                        </Chart>
                    </div>                    
                </div>    
                
            </div>
        )
    }

}

export default Main