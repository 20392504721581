import React from 'react';
import _ from 'lodash';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import AbsoluteValue from '../AbsoluteValue';

class AccountSplitBox extends React.Component {
    accounts = [ 'free', 'discounted', 'premium' ]; 
    render() {
        let { item } = this.props,
            total = 0,
            percentages = [];
        
        for(let account of this.accounts) {
            total += item[account];
        }
        
        for(let account of this.accounts) {
            //percentages.push(Math.round(item[account] / total * 100));
            percentages.push(item[`${account}_ratio`])
        }
        
        let percentageOffset = _.sum(percentages) - 100;
        if(percentageOffset!==0) {
            let biggestPercentageIndex = _.indexOf(percentages, _.max(percentages));
            percentages[biggestPercentageIndex]-=percentageOffset;
        }
        
        return total ? <div className="accounts-box">
            {this.accounts.map((account, index)=>{
                const title = _.capitalize(account);
                return <span key={account} title={title} className={`account-percent account-percent-${account}`}>{percentages[index]}%</span>;    
            })}
            
            
        </div> : null;
    }

}

export default AccountSplitBox