export const sharedPagesConstants = {
  SHARE_REQUEST: 'SHARE_REQUEST',
  SHARE_SUCCESS: 'SHARE_SUCCESS',
  SHARE_FAILURE: 'SHARE_FAILURE',
  GET_SHARED_PAGES_REQUEST: 'GET_SHARED_PAGES_REQUEST', 
  GET_SHARED_PAGES_SUCCESS: 'GET_SHARED_PAGES_SUCCESS',
  GET_SHARED_PAGES_FAILURE: 'GET_SHARED_PAGES_FAILURE',
  DELETE_SHARED_PAGE_REQUEST: 'DELETE_SHARED_PAGE_REQUEST',
  DELETE_SHARED_PAGE_SUCCESS: 'DELETE_SHARED_PAGE_SUCCESS',
  DELETE_SHARED_PAGE_FAILURE: 'DELETE_SHARED_PAGE_FAILURE'
}