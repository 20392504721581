import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize } from 'lodash';
import Box from '../Box';
import ReactSelect from '../../common/ReactSelect';
import LineChart from '../../widgets/charts/types/LineChart';

import { imprintsActions } from '../../../data/actions/imprints';
import { artistsActions } from '../../../data/actions/artists';
import { playlistsActions } from '../../../data/actions/playlists';
import { projectsActions } from '../../../data/actions/projects';
import { productsActions } from '../../../data/actions/products';
import { tracksActions } from '../../../data/actions/tracks';


class StreamsStats extends React.Component {
    
    nullElement = {value: null, label: 'All'}
    
    constructor(props) {
        super(props);
        this.state = {
            entity: this.nullElement
        }
        
        this.setEntity = this.setEntity.bind(this);
    }

    setEntity(entity) {
        this.setState({entity});
    }
    
    renderToolbar(data){
        let entities = [this.nullElement];
        if(data) {
            for(let entity of data.datasets)
                entities.push({
                    value: entity.id,
                    label: entity.label
                });
        }
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <ReactSelect value={this.state.entity} options={entities} onChange={this.setEntity} className="single-select"/>
        </div>
        </div>)

        return toolbar;
    }
    
    
    componentDidMount() {
        this.props.getStreamsStats(this.props.ids);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats(this.props.ids);
            }                
        }
        if(nextProps.ids && !isEqual(nextProps.ids, this.props.ids)) {
            this.props.getStreamsStats(nextProps.ids);
        } 
    }
    
    render (){  
        const { entity } = this.props,
            {streams, streamsLoading} = this.props[entity],
            widgetTitle = `${capitalize(entity)} Units`;
            
        let chartData = Object.assign({}, streams);
        if(this.state.entity.value!==null) {
            const dataset = find(streams.datasets, {id: this.state.entity.value});
            chartData.datasets = [dataset];
        }
        
        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }        

        return <Box title={widgetTitle} toolbar={this.renderToolbar(streams)} spinnerEnabled={streamsLoading} >
            {chartData.datasets && 
                <div className="chart-block">
                    {chartData.datasets && <LineChart data={chartData} {...shadowChartProps} />}
                </div>
            }
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
    } 
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        playlists: state.playlists,
        tracks: state.tracks,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (ids) => {
            const { filtered = [] } = ownProps; 
            switch(ownProps.entity) {
                case 'imprints':
                    dispatch(imprintsActions.getStreamsStats(ids, filtered));
                    break;
                case 'artists':
                    dispatch(artistsActions.getStreamsStats(ids, filtered));
                    break;
                case 'playlists':
                    dispatch(playlistsActions.getStreamsStats(ids, filtered))
                    break;                    
                case 'products':
                    dispatch(productsActions.getStreamsStats(ids, filtered))
                    break;
                case 'projects':
                    dispatch(projectsActions.getStreamsStats(ids, filtered))
                    break;                    
                case 'tracks':
                    dispatch(tracksActions.getStreamsStats(ids, filtered))
                    break;
                    
            }
        } 
    } 
}



export default connect(mapStateToProps, mapDispatchToProps)(StreamsStats);