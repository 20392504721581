import React from 'react';
import _ from 'lodash';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import AbsoluteValue from '../AbsoluteValue';

class AudioFormatSplitBox extends React.Component {
    audioFormats = [ 'audio_compressed', 'audio_lossless', 'audio_immersive' ]; 
    render() {
        let { item } = this.props,
            total = 0,
            percentages = [];
        
        for(let audioFormat of this.audioFormats) {
            total += item[audioFormat];
        }
        
        for(let audioFormat of this.audioFormats) {
            //percentages.push(Math.round(item[audioFormat] / total * 100));
            percentages.push(item[`${audioFormat}_ratio`])
        }
        
        let percentageOffset = _.sum(percentages) - 100;
        if(percentageOffset!==0) {
            let biggestPercentageIndex = _.indexOf(percentages, _.max(percentages));
            percentages[biggestPercentageIndex]-=percentageOffset;
        }
        
        return total ? <div className="audio-formats-box">
            {this.audioFormats.map((audioFormat, index)=>{
                const title = _.capitalize(audioFormat.replace('audio_', ''));
                return <span key={audioFormat} title={title} className={`audioFormat-percent audioFormat-percent-${audioFormat}`}>{percentages[index]}%</span>;    
            })}
            
            
        </div> : null;
    }

}

export default AudioFormatSplitBox