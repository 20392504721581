import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableFooter from './TableFooter'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderColor: '#E8E8EA',
        fontSize: 5,
        width: '100%',
    }
});

const Table = ({items, columns}) => {
    return <View style={styles.tableContainer}>
        <TableHeader columns={columns} />
        <TableRow items={items} columns={columns} />
        {/*<TableFooter items={items} />*/}
    </View>
};
  
export default Table