import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import ReactTable from "react-table";
import cellHeaderHOC from '../../components/common/Datagrid/CellHeaderHOC';
import { sessionStore } from '../../helpers/SessionStore';
import { connect } from 'react-redux';
import { debounce, find, findIndex, isEqual, cloneDeep, map } from 'lodash';
import { withRouter } from 'react-router';
import { convertToThousands } from '../../helpers/NumberFormatter';
import Spinner from '../../components/common/Spinner';

import CSV from '../../helpers/CSVExport';
import LoadingMessage from '../../helpers/LoadingMessage';
import Box from '../../components/common/Box';
import FieldSelector from '../../components/common/Datagrid/FieldsSelector';
import PageFilter from '../../components/common/Datagrid/PageFilter';
import SectionHeader from '../../components/common/Datagrid/SectionHeader';
import PlaylistSearchForm from '../../components/forms/playlistSearch';
import EntityCard from '../../components/common/EntityCard';
import EntityFilters from '../../components/common/EntityFilters';
import TerritoryStats from '../../components/common/Stats/Territory';
import PlaylistDistribution from '../../components/pages/playlists/Distribution';
import PlaylistTracks from '../../components/pages/playlists/PlaylistTracks';
import PlaylistFilter from '../../components/pages/playlists/Filter';
import SearchWidget from '../../components/pages/home/SearchWidget';
import ScrollButtons from '../../components/common/ScrollButtons';
import DropdownMenu from '../../components/common/DropdownMenu'
import ModeSelect from '../../components/common/ModeSelect';
import EntitySelector from '../../components/pages/result/EntitySelector';
import PlaylistFilterButton from '../../components/widgets/playlistFilter/FilterButton';

import { playlistsActions } from '../../data/actions/playlists';
import { playlistsFormatter } from '../../data/formatters/playlists'
import { playlistsSettings } from '../../components/pages/playlists/Settings'
import { filterActions } from '../../data/actions/filter';

const CellHeaderTable = cellHeaderHOC(ReactTable);
var barSvg = require('!svg-inline-loader!../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../public/img/table.svg');
var worldSvg = require('!svg-inline-loader!../../../public/img/world.svg');
var manageSvg = require('!svg-inline-loader!../../../public/img/manage.svg');
var downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class PlaylistSearch extends Component {
    
    defaultPageSize = 10;

    defaultOptions = {
        'playlist_name': '',
//        'tags':'',
//        'atspd':false,
        'current_tracks':false,
        'playlist_source': false,
        'playlist_types': [],
        'ages':false,
        'genders':false
    }; 

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            pages: -1,
            total: 0,
            page: 0,
            //pageSize: 10,
            filtered: '',
            filter: { // will be set from FieldsSelector
                columns: this.getInitialColumns(),
                filtered: this.getInitialFilter()
            },
            defaultSorted: [{id: 'curr_units', desc: true}],
            view: props.defaultMode || 'table',
            currentFilter: null, 
            resizeObserver: undefined,
            tableContainer: undefined,
            hasHorizontalScroll: false,
            entityFilter: null
        }
        this.getInitialFilter = this.getInitialFilter.bind(this);
        this.getPageFilter = this.getPageFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setFilterSearch = this.setFilterSearch.bind(this);
        this.setFilterForm = this.setFilterForm.bind(this);
        this.setFilterSearchDeb = debounce(this.setFilterSearch, 1000);
        this.dispatchLoadData = this.dispatchLoadData.bind(this);
        this.addToPlaylistsFilter = this.addToPlaylistsFilter.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.searchResult = this.searchResult.bind(this);
        this.resetSearchResult = this.resetSearchResult.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.setView = this.setView.bind(this);
        this.resizeObserverFunction = this.resizeObserverFunction.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
		this.setPlaylists = this.setPlaylists.bind(this);
        // this.tableScrollTop = 0;
    }
    
    resizeObserverFunction() {
        const cellHeaderTable = ReactDOM.findDOMNode(this.refs.CellHeaderTable);
        if(!cellHeaderTable)
            return; 
        const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];
        
        this.setState({
            tableContainer
        });

        if (this.props.hasResizeAdjustment && window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                this.setState({
                    hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
                });
            });
            this.setState({
                resizeObserver
            });
        
            resizeObserver.observe(tableContainer);
        } else {
            // console.log("Resize observer not supported!");
            this.setState({
                hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
            });
        }
    }

    componentDidMount() {
        this.resizeObserverFunction();
        const { view } = this.state;
        if(view != 'table')
            this.dispatchLoadData(this.state);
    }
  
    loadData(state, instance) {
        this.setState({
            sorted: state.sorted
        }, ()=>this.dispatchLoadData(state));
    }
  
    getDefaultOptions() {
        const savedOptions = sessionStore.get('PlaylistSearchForm');
		if(savedOptions && savedOptions.hasOwnProperty('playlists'))
			delete savedOptions.playlists;
        return savedOptions || this.defaultOptions;
    }
    
    getInitialColumns() {
        return playlistsSettings.settingsForTable()
            .filter(item=>(item.show===undefined || item.show===true))
            .map(item=>item.id);
    }
    
    exportToCsv(){
        const sorted = this.state.sorted[0];
        const filename = CSV.CSVHeader('playlists', sorted.id, this.props.filter.global);
        let { data } = this.state;
        /*
        for(let item of data) {
            let tags = {};
            if(item.pl_tags) {
                for(let tag of item.pl_tags) {
                    const category = tag.tag_category;
                    if(!tags.hasOwnProperty(category)) {
                        tags[category] = [tag.tag]
                    }
                    else {
                        tags[category].push(tag.tag);
                    }
                }
            }
            item.tags = JSON.stringify(tags);
        }
        */
        data = CSV.filterColumns(data, this.state.filter.columns, this.getInitialColumns());

        return CSV.CSVExport(data, {filename}, 'playlists_search');
    }
    
    getPageSize() {
        return this.props.filter.global ? this.props.filter.global.limit : this.defaultPageSize;
    }
    
    getInitialFilter() {
        let filter = [],
            options = Object.assign({}, this.getDefaultOptions()),
            { filtered } = this.props,
            { params } = this.props.match;
        
        if(params.entity_type && params.entity_id){
            options[params.entity_type] = params.entity_id;
        }
        
        if(filtered) {
            for(let entityType of Object.keys(filtered)) {
                options[entityType] = filtered[entityType]
            }
        }
        
        for(let option in options) {
            filter.push({
                id: option,
                value: options[option]
            });
        }
        return filter;
    }

    dispatchLoadData(state){
        const { dispatch, mode = 'full' } = this.props;
        dispatch(playlistsActions.getPlaylists({
            page: state.page,
            //pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            mode
        }));      
    }
    
    getPageFilter(props, entity){
        let ids = [];
        if(props.filter[entity])
            ids = props.filter[entity].map(item=>item.id);
        return ids;
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.playlists && nextProps.playlists.items){
            this.setState({
                data: nextProps.playlists.items,
                pages: Math.ceil(nextProps.playlists.total / this.getPageSize()),
                total: nextProps.playlists.total
            })
        }
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    let state = Object.assign({}, this.state);
                    state.filtered = state.filter.filtered;
                    this.dispatchLoadData(state);
                }                
                /*
                this.setState({
                    pageSize: nextProps.filter.global.limit
                });
                */
            }
        }

    }
      
    setFilter(filter){
        this.setState({
            filter
        })
    }
    
    setEntityFilter(filtered) {
        
        let filter = [];

        
        if(!filtered)
            filtered = cloneDeep(this.props.filtered);
        
        for(let option in filtered) {
            filter.push({
                id: option,
                value: filtered[option]
            });
        }
        
        this.setState({
            ...this.state,
            entityFilter: filtered,
            filter: {
                ...this.state.filter,
                filtered: filter
            }
        }, ()=>this.dispatchLoadData(this.state.filter));
    }
    
    setFilterSearch(value, id){
        let filtered = this.state.filter.filtered.slice(0),
            valueIndex = findIndex(filtered, {id});
    
        if(valueIndex!==-1){
            filtered[valueIndex] = {id, value};
        }
        else {
            filtered.push({id, value})
        }
    
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        });      
    }
  
    setSearch(e){
        const value = e.target.value;
        this.setState({
            filtered: value
        })
        this.setFilterSearch(value, 'artist')      
    }

    renderHeader(mode){
        //if(mode != 'full')
            //return;
        
        let toolbar = [];
        const dropdownHeader = <span key="settings" className="manage-columns-title">
            Manage Columns
        </span>
        
        toolbar.push(
            <div className="ibox" key="ibox">
                <div className="ibox-title">
                    <h2 className="ibox-title__title">
                        <span className="title-text">
                            {this.state.view == 'table' && this.state.total !=0 && mode != 'full' && convertToThousands(this.state.total)} Playlist Streams
                        </span>
                        <DropdownMenu header={dropdownHeader}>
                            <div key="settings-box">
                                <FieldSelector settings={playlistsSettings.settingsForTable()} current={this.state.filter} onUpdate={this.setFilter} />
                            </div>
                        </DropdownMenu>
                            
                    </h2>
                </div>
            </div>
        );
        
        return toolbar;
    }
                
    renderToolbar(mode){
        let toolbar = [];
            
        const options = [
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: barSvg, value: 'chart', label: "Chart"},
            {icon: worldSvg, value: 'map', label: "World Map"},
        ];
        
        //const selectedOption = find(options, option=>option.value == this.state.view);
        
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper single-element">
        <div className="ibox-icon-holder mode-select">
            <ModeSelect options={options} selected={this.state.view} onChange={this.setView} isSearchable={ false } />
        </div>
        <div className="ibox-actions">
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" 
                dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)

        return toolbar;
    }


    setView(view){
        this.setState({
            view
        }, ()=>{
            const { modeChange } = this.props;
            if(typeof modeChange == 'function')
                modeChange(view);
        });
    }
    
    addToPlaylistsFilter(playlist){
    }
    
    setFilterForm(formData) {
        let filtered = [];
        
        for(let id of Object.keys(formData)) {
            let value = formData[id];
            filtered.push({id, value});
        }
        
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        }, ()=>this.dispatchLoadData(this.state.filter));
        
        sessionStore.set('PlaylistSearchForm', formData);
    }
    
    searchResult(item) {
        let filter = [],
            options = Object.assign({}, this.getDefaultOptions());
        if(item.entity == 'artists')
            item.entity = 'track_artists';
        
        options[item.entity] = item.id;

        for(let option in options) {
            filter.push({
                id: option,
                value: options[option]
            });
        }
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered: filter
            }
        }, ()=> {
            this.props.history.push(`/playlists/${item.entity}/${item.id}`);
        });
    }
	
	setPlaylists(filter) {
		let playlists = map(filter.value, 'entity_id');
		let current = this.state.filter.filtered.filter(option=>option.id != 'playlists')
		this.setState({
			...this.state,
			filter: {
				...this.state.filter,
				filtered: [
					...current,
					{id: 'playlists', value: playlists}
				]
			}
		}, ()=> {
            this.dispatchLoadData(this.state.filter);
            this.props.history.push(`/playlists/playlists/${playlists.join(',')}`);
		});
	}
    
    applyFilter(filter) {
        this.setState({currentFilter: filter}, ()=>this.searchResult(filter));
    }
    
    resetFilter() {
        this.setState({currentFilter: null}, ()=>this.props.history.push(`/playlists/`));
    }


    resetSearchResult(item) {
        let filter = [],
            options = Object.assign({}, this.getDefaultOptions());
        const permittedOptions = Object.keys(this.defaultOptions);
        
        for(let option in options) {
            if(permittedOptions.includes(option)){
                filter.push({
                    id: option,
                    value: options[option]
                });
            }
        }
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered: filter
            }
        }, ()=> {
            this.props.history.push(`/playlists`);
        });
    }
    
    applyNewFilter = (filter) => {
        let filtered = [];
        
        for(let key of Object.keys(filter)) {
            filtered.push({
                id: key,
                value: filter[key]
            });
        }
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            }
        }, ()=>this.dispatchLoadData(this.state.filter));

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.view !== prevState.view || this.state.filter !== prevState.filter) {
            if (this.state.view == "table") {
                this.resizeObserverFunction();
            }
        }
    }

    componentWillUnmount() {
        const cellHeaderTable = ReactDOM.findDOMNode(this.refs.CellHeaderTable);
        if(cellHeaderTable) {
            const tables = cellHeaderTable.getElementsByClassName('rt-table');
            if(Array.isArray(tables) && tables.length > 0) {
                const tableContainer = tables[0];
                this.state.resizeObserver && this.state.resizeObserver.unobserve(tableContainer);
            }
        }
    }

    render() {
        const { mode = 'full', shadowChartProps, externalLinks = true } = this.props;
        
        const tableData = this.state.data,
            total = this.state.total, 
            { params } = this.props.match,
            { currentFilter } = this.state,
            isFiltered = params.entity_type && params.entity_id && params.entity_type != 'playlists',
            dataReady = (Array.isArray(tableData) && tableData.length>0);
        let filtered = this.state.entityFilter ? this.state.entityFilter : this.props.filtered;
        
        let flatFilter = [];
        if(filtered) {
            for(let entity of Object.keys(filtered)) {
                flatFilter.push({id: entity, value: filtered[entity]})
            }
        }
            
        return (
            <div className="homepage">
                <Box title="Playlist Overview" toolbar={this.renderToolbar()}>
                    <div>
                        <PlaylistFilterButton filtered={this.state.filter.filtered} onApply={this.applyNewFilter} />
                    </div>
                    {/*mode == 'full'*/ false && <div>
                        {!currentFilter && <div className="playlist-search-container">
                            {isFiltered && <div>
                                <EntityCard entity={params.entity_type} id={params.entity_id} reset={this.resetSearchResult} />
                            </div>}   
                            {!isFiltered && <SearchWidget searchResult={this.searchResult} placeholder="Search playlists by imprint, artist, product or track" mode="playlist" /> }
                            
                        </div>}
                        {/*
                        <div className="column ibox-content section-header top-header">
                            <EntityFilters currentFilter={this.state.currentFilter} onSelect={this.applyFilter} onReset={this.resetFilter} />
                        </div>
                        */}
                    </div>}
                    {false && <div className="column ibox-content section-header">
                        <PlaylistSearchForm  onSubmit={this.setFilterForm} values={this.state.filter.filtered} />
                        <PlaylistFilter onChange={this.setPlaylists} filtered={params} />
                    </div>}
                        
                    {/*mode != 'full'*/ false && <div>
                        <EntitySelector entityChange={this.setEntityFilter} />
                    </div>}
                    {/*
                        <SectionHeader entity="playlists" label="Playlists" data={this.state.data} filtered={this.state.filtered} onChange={this.setSearch}>
                            <PageFilter mode="current" entity="playlists" label="Playlists" />
                            <PageFilter mode="parent" entity="imprints" label="Imprints" />
                        </SectionHeader>
                    */}
                    <div className="manage-columns-holder search">{this.renderHeader(mode)}</div>
                    <div>
                        {this.state.view == 'table' && this.state.hasHorizontalScroll && <ScrollButtons el={this.state.tableContainer} />}
                    </div>
                    {this.state.view == 'table' && <div className="resize-table-container" ref="CellHeaderTable">
                        <CellHeaderTable
                            showScrollBtn={false}
                            hasResizeAdjustment={false}
                            loading={this.props.playlists.loading}
                            loadingText={LoadingMessage('playlist')}
                            manual
                            className="playlist-table catalogue-table-holder playlist-table--search"  
                            resizable={false}
                            data={tableData}
                            pages={this.state.pages}          
                            showPageSizeOptions={false}
                            defaultPageSize={this.getPageSize()}
                            columns={playlistsSettings.settingsForTable(this.state.filter.columns, this.addToPlaylistsFilter, externalLinks)}
                            defaultSorted={this.state.defaultSorted}
                            filtered={this.state.filter.filtered}
                            onFetchData={this.loadData}
                            defaultSortDesc={true}
                            collapseOnDataChange={false}
                            noDataText='No results found. Please check your filters.'
                            SubComponent={row => <PlaylistTracks 
                                playlist={row.original} 
                                parentEntity={params.entity_type}
                                parentEntityID={params.entity_id}
                                filtered={filtered}
                                mode="expanded" 
                            />} 
                        /> 
                    </div>}
                    {this.state.view == 'chart' && <div className="">
                        <div className="default distribution-chart">
                            <Spinner enabled={!dataReady} />
                            {dataReady && <PlaylistDistribution data={tableData} filtered={filtered} shadowChartProps={shadowChartProps} />}
                        </div>
                    {/*<LineChart */}
                    </div>}
                    {this.state.view == 'map' && <div className="map-holder homepage territory-stats-holder">
                        <Spinner enabled={!dataReady} />
                        {dataReady && <TerritoryStats entity="playlists" expanded={true} filtered={flatFilter} ids={tableData.map(entry=>entry.playlist_id)}/> }
                    </div>}
                </Box>
                <Helmet>
                    <title>Playlist Library</title>
                </Helmet>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        playlists: state.playlists,
        filter: state.filter
    } 
}

export default withRouter(connect(mapStateToProps)(PlaylistSearch))