import React, { Component } from 'react';
import { CountryFlagByAbbreviation } from '../../components/common/CountryFlag';
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import { convertToThousands } from '../../helpers/NumberFormatter';

const manageSvg = require('!svg-inline-loader!../../../public/img/manage.svg');
const searchSvg = require('!svg-inline-loader!../../../public/img/search.svg');
const COMMA_REGEXP = /\B(?=(\d{3})+(?!\d))/g;

class ListOfCountries extends React.Component {
  render(){
    const { countries, sortData } = this.props;
    return (

      <ul className={`list-countries-search-panel${countries.length === 0 ? '-empty' : ''}`}>
        {
          <ReactTable
            resizable={false}
            columns={[
              {
                id: 'flag',
                className: 'flag',
                headerClassName: 'imprint-name',
                accessor: 'alpha_2_country_code',
                Cell: props => {
                  return (
                    <div className="search-territories">
                      <CountryFlagByAbbreviation className="flag-default" country={props.value} />
                      <div className="abbreviation-search-panel-territories">{props.value}</div>
                    </div>
                  )
                },
                sortable: false,
                Header: 'Top Territories'
              },
              {
                id: 'ctry_streams',
                className: 'ctry-streams',
                headerClassName: 'imprint-name',
                accessor: ({ctry_streams}) => { return convertToThousands(ctry_streams)},
                Header: 'Streams'
              }
            ]}
            showPagination={false}
            getTrProps={(state, rowInfo, column) => {
              return {
                onClick: (e) => {
                  this.props.onGetRegionData(e, { ...rowInfo.row._original, ...{ detail_level: 'region' }}, { country: false, region: true });
                },
                className: rowInfo.row._original.country === this.props.streamData.country ? "current-country-item" : "country-item"
              }
            }}
            pageSize={countries.length}
            data={sortData(countries, 'ctry_streams')}
            NoDataComponent={() => null}
          />
        }
      </ul>
    )
  }
}


class ListOfRegions extends React.Component {
  render(){
    const { regions, sortData } = this.props;
    return (

      <ul className={`list-countries-search-panel${regions.length === 0 ? '-empty' : ''}`}>
        {
          <ReactTable
            resizable={false}
            columns={[
              {
                id: 'state_code',
                className: 'state_code',
                headerClassName: 'imprint-name',
                accessor: 'state_code',
                sortable: false,
                Header: 'State code',
                width: 95
              },
              {
                id: 'state',
                className: 'state',
                headerClassName: 'imprint-name state',
                accessor: 'state',
                Header: 'State'
              },
              {
                id: 'streams_total',
                className: 'streams_total',
                headerClassName: 'imprint-name',
                accessor: ({streams_total}) => { return convertToThousands(streams_total) },
                Header: 'Streams',
                width: 85
              }
            ]}
            showPagination={false}
            pageSize={regions.length}
            data={sortData(regions, 'streams_total')}
            NoDataComponent={() => null}
          />
        }
      </ul>
    )
  }
}

ListOfRegions.propTypes = {
  regions: PropTypes.array
}

ListOfRegions.defaultProps = {
  regions: []
}


export default class TerritorySearchPanel extends React.Component {

  sortData = (data, key) => {
    return data.sort((a, b) => { return b[key] - a[key] } );
  }

  render(){
    const { rootClassName,
            searchStaticData,
            onShowHeaderContent,
            setSearch,
            searchValue,
            searchData,
            showSearchDropDown,
            setMap,
            resetSearch,
            onHandleShowClick,
            artist,
            artistStreamsByCountries,
            onHandleCountryClick,
            streamData,
            onGetRegionData
          } = this.props;

    const listOfArtists = () => {
      const emptyData = searchData.autocomplete_items.length === 0;
      return (
        <ul className={!emptyData ? "list-of-artists-root" : "list-of-artists-root-empty"}>
          { !emptyData ? searchData.autocomplete_items.map( artist => {
            return (
              <li className="list-of-artists-element" onClick={() => setMap( artist )}>{ artist.name }</li>
            );
          })
          :
          <span className="list-of-artists-no-results-modal"> Search returned no results </span>
        }
        </ul>
      )
    }

    const searchHolder = () => {
      return(
        <div>
          <div className="search-holder-territory-search-panel">
            <span className="search-icon" dangerouslySetInnerHTML={{__html: searchSvg}}></span>
            <input onChange={setSearch} value={ searchValue } className="pull-right search-field" type="text" name="search" placeholder={`Search by artists`} />
            <span className="pull-right clear-icon fa fa-backspace"  onClick={resetSearch}></span>
          </div>
          {showSearchDropDown && listOfArtists()}
          <div className="show-action-territory-search-panel">
            <button onClick={(e) => onHandleShowClick({ ...artist, ...{detail_level: 'country'} }, { country: true })} className="show-button-territory-search-panel">Show</button>
          </div>
        </div>
      )
    }

    const header = (data, index) => {
      return (
        <div className="territory-search-holder">
          <div onClick={() => onShowHeaderContent(data, index)} className="header-territory-search-panel">
            <span className="icon-territory-search-panel"><i className={`${data.iconClass} fa-3x`} aria-hidden="true"></i></span>
            <span className="message-territory-search-panel" >{data.title}</span>
          </div>
          {data.searchField && searchHolder()}
          {data.territoryField && <div> <ListOfCountries countries={this.props.artistStreamsByCountries} sortData={this.sortData} streamData={streamData} onGetRegionData={onGetRegionData} artistStreamsByCountries={artistStreamsByCountries} /> </div>}
          {data.regionField && this.props.artistStreamsByRegions.length !== 0 && <div> <ListOfRegions sortData={this.sortData} regions={this.props.artistStreamsByRegions} /> </div>}
        </div>
      )
    }

    return(
      <div className={rootClassName}>
        {
          searchStaticData.map( (headerData, index) => {
            return header(headerData, index);
          })
        }
      </div>
    )
  }
}
