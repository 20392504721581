import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AbsoluteValue from '../../../common/AbsoluteValue';
import PercentageValue from '../../../common/PercentageValue';
import Tooltip from '../../../common/Tooltip';
import { avgFooter, convertSumFooter } from '../../../common/Datagrid/Footers';
import CountryFlag from '../../../common/CountryFlag';

export const locationsSettings = {
    settingsForTable
};


function settingsForTable(mode, territory){
    let columns = [
         {
               id: 'logo',
               className: 'logo-sell',
               headerClassName: 'logo-sell no-sort',
               Header: '',
               accessor: 'territory',
               width: 40,
               sortable: false,
               Cell: props => {
                   return <CountryFlag className="flag-medium" country={props.value} />
            }, 
         },
         {
             id: 'territory_name',
             Header: 'Country',
             accessor: 'territory_name',
         },      
/*                   
        {
            id: 'region',
            className: '',
            headerClassName: '',
            Header: 'State/Region',
            accessor: 'region',
        },
        */                                 
        {
           id: 'city',
           className: '',
           headerClassName: '',
           Header: 'City',
           accessor: 'city'
       },
       {
           id: 'streams_total',
           headerClassName: '',
           Header: 'Share',
           accessor: 'share',
           //Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           Cell: props => props.value ? `${props.value}%` : 'N/A',
       },
       {
           id: 'listenersShare',
           headerClassName: '',
           Header: 'Listeners Share',
           accessor: 'listenersShare',
           //Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           Cell: props => props.value ? `${props.value}%` : 'N/A',
       }
       
    ];
    let hiddenColumns = [];
    if(mode !== 'expanded') {
        hiddenColumns.push('listeners');
        hiddenColumns.push('listenersShare');
    }
    if(territory !== 'world') {
        hiddenColumns.push('logo');
        hiddenColumns.push('territory_name');
    }
    return columns.filter(column => !hiddenColumns.includes(column.id));
}
