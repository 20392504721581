import { find } from 'lodash';
import store from '../../helpers/store'
import { searchConstants } from '../constants/search';
const recentSearches = store.get('recentSearches') || [];

export function search(state = {recentSearches, details: {}}, action) {
    switch (action.type) {
        case searchConstants.GET_SEARCH_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                loading: true
            };
        case searchConstants.GET_SEARCH_AUTOCOMPLETE_SUCCESS:
            return {
                
                ...state,
                loading: false,
                autocomplete_items: action.search
            };
        case searchConstants.GET_SEARCH_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case searchConstants.GET_SEARCH_IMPORT_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                importLoading: true
            };
        case searchConstants.GET_SEARCH_IMPORT_SUCCESS:
            return {
                
                ...state,
                importLoading: false,
                autocomplete_items: action.search
            };
        case searchConstants.GET_SEARCH_IMPORT_FAILURE:
            return {
                ...state,
                importLoading: false,
                error: action.error
            };

        case searchConstants.GET_SEARCH_RESULTS_REQUEST:
            return {
                ...state,
                searches: [],
                searchesLoading: true
            };
        case searchConstants.GET_SEARCH_RESULTS_SUCCESS:
            return {
                
                ...state,
                searchesLoading: false,
                searches: action.results
            };
        case searchConstants.GET_SEARCH_RESULTS_FAILURE:
            return {
                ...state,
                searchesLoading: false,
                error: action.error
            };
            
        case searchConstants.GET_SEARCH_RESULT_REQUEST:
            return {
                ...state,
                searchResultLoading: true
            };
        case searchConstants.GET_SEARCH_RESULT_SUCCESS:
            return {
                
                ...state,
                searchResultLoading: false,
                details: {
                    ...state.details,
                    [action.id]: action.result
                }
            };
        case searchConstants.GET_SEARCH_RESULT_FAILURE:
            return {
                ...state,
                searchResultLoading: false,
                error: action.error
            };

            
        case searchConstants.ADD_RECENT_ITEM:
            let { recentSearches } = state;
            if(!find(recentSearches, recentItem=>recentItem.id == action.item.id))
                recentSearches = [...state.recentSearches.slice(-9), action.item];
            return {
                ...state,
                recentSearches
            };
            break;

        case searchConstants.CLEAR_RECENT_ITEMS:
            return {
                ...state,
                recentSearches: []
            };
            break;
            
        default:
            return state
    }
} 