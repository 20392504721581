import { some, map, sumBy, uniq, find, cloneDeep } from 'lodash';
import moment from 'moment';
import ImageURL from '../../helpers/ImageURL';

export const spotifyMonetizationFormatter = {
	formatSpotifyMonetization,
	formatSpotifyMonetizationFlat,
	formatSpotifyMonetizationTotals,
	formatSpotifyMonetizationTimeseries,
	formatSpotifyMonetizationDates,
	formatMetadata
};



function formatSpotifyMonetization(data){
	const dates = uniq(map(data, 'current_end_date')).sort();
	const tracks = uniq(map(data, 'track_id'));
	let rows = [];
	let timeseries = {};
	let grandTotalStreams = 0, grandTotalMonetized = 0, grandTotalUnmonetized = 0;
	for(let date of dates) {
		timeseries[date] = {monetized: 0, unmonetized: 0};
	}
	
	for(let track of tracks) {
		let lastMonthStreams = 0, lastMonthListeners = 0, lastMonthStatus = '',
			totalStreams = 0, totalMonetized = 0, totalUnmonetized = 0,
			monthsUnmonetized = 0, unmonetizedSince = '';
		
		for(let date of dates) {
			let row = find(data, item=>(item.current_end_date == date && item.track_id == track));
			if(row) {
				lastMonthStreams = row.current_months_plays;
				lastMonthListeners = row.current_months_unique_users;
				lastMonthStatus = row.status;
				grandTotalStreams += row.current_months_plays;
				
				totalStreams += row.current_months_plays;
				if(row.status.endsWith('Unmonetized')) {
					totalUnmonetized += row.current_months_plays;
					grandTotalUnmonetized += row.current_months_plays;
					timeseries[date].unmonetized+=row.current_months_plays;
					monthsUnmonetized++;
					if(!unmonetizedSince)
						unmonetizedSince = row.current_end_date;
				}
				else {
					totalMonetized += row.current_months_plays;
					grandTotalMonetized += row.current_months_plays;
					timeseries[date].monetized+=row.current_months_plays;
					unmonetizedSince = '';
				}
			}
		}
		rows.push({id: track, lastMonthStreams, lastMonthListeners, lastMonthStatus, totalStreams, totalMonetized, totalUnmonetized, monthsUnmonetized, unmonetizedSince})
	};
	const monetizedTracks = rows.filter(row=>!row.lastMonthStatus.endsWith('Unmonetized')).length;
	const unmonetizedTracks = rows.filter(row=>row.lastMonthStatus.endsWith('Unmonetized')).length;
	const metrics = [
		{id: 'total_streams', title: 'Streams', value: grandTotalStreams},
		{id: 'monetized_streams', title: 'Monetized Streams', value: grandTotalMonetized, percentageValue: Math.round(grandTotalMonetized / grandTotalStreams * 100), valueClass: 'up'},
		{id: 'unmonetized_streams', title: 'Unmonetized Streams', value: grandTotalUnmonetized, percentageValue: Math.round(grandTotalUnmonetized / grandTotalStreams * 100), valueClass: 'down'},
		{id: 'monetized_tracks', title: 'Monetized Tracks', value: monetizedTracks, percentageValue: Math.round(monetizedTracks / rows.length * 100), valueClass: 'up'},
		{id: 'unmonetized_tracks', title: 'Unmonetized Tracks', value: unmonetizedTracks, percentageValue: Math.round(unmonetizedTracks / rows.length * 100), valueClass: 'down'}
	];
	
	let monetizedDataset = [], unmonetizedDataset = [];
	for(let date of dates) {
		let column = timeseries[date];
		monetizedDataset.push(column.monetized);
		unmonetizedDataset.push(column.unmonetized);
	};
	timeseries = {
		labels: dates,
		datasets: [
			{label: "Monetized", data: monetizedDataset},
			{label: "Unmonetized", data: unmonetizedDataset},
		]
	}

	return {data: rows, metrics, timeseries};
}

function formatSpotifyMonetizationFlat(data) {
	const newReleaseThreshold = 28;
	const today = moment();
	let total = 0;

	let result = data.map(row=>{
		total = row.total_tracks;
		const artist = ( Array.isArray(row.track_artists) && row.track_artists.length > 0 ) ? row.track_artists[0] : {};
		return {id: row.track_id, 
			lastMonthStreams: row.end_month_streams, 
			lastMonthListeners: row.end_month_listeners , 
			lastMonthStatus: row.end_month_status, 
			totalStreams: row.total_streams, 
			totalMonetized: row.monetized_streams, 
			totalUnmonetized: row.unmonetized_streams, 
			monthsUnmonetized: row.times_unmonetized, 
			unmonetizedSince: row.last_unmonetized_month,
			release_type: row.release,
			monthly_details: row.monthly_details,
			
			title: row.track_title,
			isrc: row.isrc,
			artist: artist.name || 'N/A',
			release_date: row.first_released,
			image: ImageURL(row.product_image, row.product_id, 'tracks'),
			//new_release: today.diff(moment(row.first_released), 'days') <= newReleaseThreshold,
			
		};
	});
	return {data: result, total};
}

function formatMetadata(data, metadata) {
	const newReleaseThreshold = 28;
	const today = moment();
	const tracks = cloneDeep(data);
	let rows = [];
	for(let track of tracks.data) {
		let trackMeta = find(metadata, meta=>meta.id == track.id);
		if(trackMeta) {
			track.title = trackMeta.title;
			track.isrc = trackMeta.isrc;
			track.release_date = trackMeta.release_date;
			track.artist = trackMeta.primary_artist.name;
			track.image = ImageURL(trackMeta.product_image, trackMeta.product_id, 'tracks');
			track.new_release = today.diff(moment(track.release_date), 'days') <= newReleaseThreshold;
		}
		rows.push(track);
	}
	tracks.data = rows;
	return tracks;
}

function formatSpotifyMonetizationTotals(data) {
	if(Array.isArray(data) && data.length > 0)
		data = data[0];
	else
		return null;
	
	const grandTotalMonetized = data['monetized_streams'];
	const grandTotalUnmonetized = data['unmonetized_streams'];
	const monetizedTracks = data['monetized_tracks'];
	const unmonetizedTracks = data['unmonetized_tracks'];
	
	const grandTotalStreams = grandTotalMonetized + grandTotalUnmonetized;
	const totalTracks = monetizedTracks + unmonetizedTracks;
	
	const metrics = [
		{id: 'total_streams', title: 'Streams', value: grandTotalStreams},
		{id: 'monetized_streams', title: 'Monetized Streams', value: grandTotalMonetized, percentageValue: grandTotalStreams ? Math.round(grandTotalMonetized / grandTotalStreams * 100) : 0, valueClass: 'up'},
		{id: 'unmonetized_streams', title: 'Unmonetized Streams', value: grandTotalUnmonetized, percentageValue: grandTotalStreams ? Math.round(grandTotalUnmonetized / grandTotalStreams * 100) : 0, valueClass: 'down'},
		{id: 'monetized_tracks', title: 'Monetized Tracks', value: monetizedTracks, percentageValue: totalTracks ? Math.round(monetizedTracks / totalTracks * 100) : 0, valueClass: 'up'},
		{id: 'unmonetized_tracks', title: 'Unmonetized Tracks', value: unmonetizedTracks, percentageValue: totalTracks ? Math.round(unmonetizedTracks / totalTracks * 100) : 0, valueClass: 'down'}
	];

	return metrics;
}

function formatSpotifyMonetizationTimeseries(data) {
	const dates = uniq(map(data, 'current_end_date')).sort();
	let monetizedDataset = [], unmonetizedDataset = [];
	for(let date of dates) {
		let column = find(data, row=>row.current_end_date == date);
		monetizedDataset.push(column.monetized_streams);
		unmonetizedDataset.push(column.unmonetized_streams);
	};
	const timeseries = {
		labels: dates,
		datasets: [
			{label: "Monetized", data: monetizedDataset},
			{label: "Unmonetized", data: unmonetizedDataset},
		]
	}
	
	return timeseries;
}

function formatSpotifyMonetizationDates(dates) {
	//eturn map(dates, 'current_end_date');
	return map(dates, ({current_end_date})=>(new Date(current_end_date).getTime()));
}