import React from 'react'
import ReactSelect from '../../common/ReactSelect'
import { find, map } from 'lodash'

class Select extends React.Component {
    valueKey = 'value';
    
    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
    }   
    
    onChange(val) {
        let result = '';
        if(val) {
            if(val instanceof Array)
                result = map(val, this.valueKey).join(',');
            else
                result = val[this.valueKey];
        }

        return this.props.input.onChange(result);
    }

    render() {
        const { input : {value, ...restInput}, options, ...rest } = this.props,
            valueObj = find(options, {[this.valueKey]: value});
        return <ReactSelect {...restInput} value={valueObj} options={options} onChange={this.onChange} {...rest} />
    }
}

export default Select;
