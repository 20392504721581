import moment from 'moment';
import { sortBy, uniq, map } from 'lodash';

export function dateRange(data) {
    let dates = [],
        period = null;
    for(let item of data) {
        for(let stream of item.stms_by_date) {
            dates.push(stream.stream_date);
            if(!period)
                period = stream.period;
        }
    }
    dates = uniq(dates).sort(sortDateRange);
    return { dates, period };    
}

export function formattedDateRange(data, format='DD MMM') {
    const { dates } = dateRange(data);
    return map(dates, (date)=>moment(date).format(format))
}

function compareValues(a, b) {
    return (a > b) 
        ? 1 
        : (a < b ? -1 : 0);
};

function sortDateRange(a, b) {
    if(typeof a === 'number') // day
        return a > b;
    else {
        const momentA = moment(a, "YYYY-MM-DD", true),
            momentB = moment(b, "YYYY-MM-DD", true);
        
        if(momentA.isValid()) {
            return compareValues(momentA.format('X'), momentB.format('X'));
        }
        else {
            const [yearA, weekA] = a.split('-'),
                [yearB, weekB] = b.split('-'),
                year = compareValues(Number(yearA), Number(yearB));
        
            return year === 0 ? compareValues(Number(weekA), Number(weekB)) : year;
        }
    }
}
    
export function dateRangeFlat(data, key = 'stream_date') {
    let dates = [],
        period = null;
    for(let stream of data) {
        dates.push(stream[key]);
        if(!period)
            period = stream.period;
    }
    dates = uniq(dates).sort(sortDateRange);
    return { dates, period };    
}

export function dateRangeSourceFlat(data) {
    let dates = [],
    period = null;
    for(let source of data) {
        dates.push(source.period_date);
        if(!period)
        period = source.period;
    }
    dates = uniq(dates).sort(sortDateRange);
    return { dates, period };    
}