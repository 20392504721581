import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import { filterActions } from '../../data/actions/filter';
import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';
import PlaylistSearch from '../Playlists/Search';

class ResultPlaylists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInitialized: false
        }
    }
    
    
    render() {
        const { filter, flatFilter, widgetProps, sharedMode } = this.props;
        
        return <div className="inner-page">
            {!sharedMode && <div className="widget-row">
                <HiddenElements widgetProps={widgetProps} />
            </div>}
            {/*    
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                    <EntitiesList title="Playlists" entity="playlists"  filter={filter} shadowChartProps={{id: 'top_playlists', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>     
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_territories" widgetProps={widgetProps}>
                    <TerritoryStats filtered={flatFilter} entity="playlists" shadowChartProps={{id: 'top_territories_playlists', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>                
            </div>
            */}
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                    <PlaylistSearch mode="inline" filtered={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_playlists', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>            
            
        </div>
            
    }
}

export default WithWidgetManager(ResultPlaylists, 'result_playlists');