import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, map } from 'lodash';
//import { Form, Field } from 'react-final-form'
import ReactSelect from '../common/ReactSelect';
import AsyncSelect from 'react-select/async';
import Modal from '../common/Modal';

import { artistsActions } from '../../data/actions/artists';
import { productsActions } from '../../data/actions/products';
import { campaignsActions } from '../../data/actions/campaigns';
import { milestonesActions } from '../../data/actions/milestones';

class AddChannelToCampaignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artist: null,
            product: null,
            track: null,
            channel: null,
            video: null
        };
        this.fetchArtists = debounce(this.fetchArtists.bind(this), 1000);
        this.selectArtist = this.selectArtist.bind(this);
        this.renderField = this.renderField.bind(this);
        this.addSalesChannel = this.addSalesChannel.bind(this);
        this.addSocialChannel = this.addSocialChannel.bind(this);
    }
    
    
    // TODO - remove dependency, inject base entity (camp or milestone) 
    // and add callbacks through props
    addSalesChannel(entity) {
        const params = this.state[entity];
        params.channel_type = entity;
        if(this.props.campaign!==undefined)
            this.props.addSales(params, this.props.campaign);
        else if(this.props.milestone!==undefined) 
            this.props.linkMilestone(params, this.props.milestone, 'sales');
        
    }
    
    addSocialChannel(entity) {
        if(this.props.campaign!==undefined)
            this.props.addSocial(this.state[entity], this.props.campaign);
        else if(this.props.milestone!==undefined) 
            this.props.linkMilestone(this.state[entity], this.props.milestone, 'social');
    }
    
    // CB instead of promise required for debounce
    fetchArtists(value, callback) {
        this.props.getArtists(value)
        .then(data=>data.map(item=>({
            value: item.id,
            label: item.name
        })))
        .then(callback);
    }
    
    selectArtist(artist) {
        const id = artist.value;
        this.setState({artist,
            product: null,
            track: null,
            channel: null,
            video: null
        }, ()=>{
            this.props.getSocials(id);
            this.props.getProducts(id)
            .then(products=>map(products, 'internal_id'))
            .then((ids)=>this.props.getTracks(ids));            
        })
    }
    
    selectEntity(type, value) {
        this.setState({
            [type]: value
        })
    }
    
    renderField(entity) {
        if(!this.state.artist)
            return <p>please select an artist</p>;
        if(entity=='products') {
            const { products, productsLoading } = this.props.artists; 
            if(productsLoading)
                return <p>loading products</p>;
            else if(products && products.length)
                return <div>
                    <ReactSelect options={products} onChange={(val)=>this.selectEntity('product', val)} /> 
                    {this.state.product && <a className="add-channel-btn" title="Add Products" onClick={()=>this.addSalesChannel('product')}>Add Products</a>}
                </div>
            else if(!productsLoading && !products) 
                return <p>no available products</p>;                
        }
        else if(entity=='tracks') {
            const { tracks, tracksLoading } = this.props.products; 
            if(tracksLoading)
                return <p>loading tracks</p>;
            else if(tracks && tracks.length) 
                return <div> 
                    <ReactSelect options={tracks} onChange={(val)=>this.selectEntity('track', val)} />
                    {this.state.track && <a className="add-channel-btn" title="Add Track" onClick={(e)=>this.addSalesChannel('track')}>Add Track</a>}
                </div>
            else if(!tracksLoading && !tracks) 
                return <p>no available tracks</p>;
        }
        else if(entity=='channels' || entity=='videos') {
            const { cardChannels, cardLoading } = this.props.artists; 
            if(cardLoading)
                return <p>loading {entity}</p>;
            else if(cardChannels) {
                
                const videos = cardChannels.filter(channel=>channel.channel_type=='youtube-video'),
                    channels = cardChannels.filter(channel=>channel.channel_type!='youtube-video');
                
                if(entity == 'videos') { 
                    return <div>
                        <ReactSelect options={videos} onChange={(val)=>this.selectEntity('video', val)} /> 
                        {this.state.video && <a className="add-channel-btn" title="Add Video" onClick={()=>this.addSocialChannel('video')}>Add Video</a>}
                    </div>;
                }
                else if(entity == 'channels') {
                    return <div>
                        <ReactSelect options={channels} onChange={(val)=>this.selectEntity('channel', val)} /> 
                        {this.state.channel && <a className="add-channel-btn" title="Add Social Channel" onClick={()=>this.addSocialChannel('channel')}>Add Social Channel</a>}
                    </div>
                }
            }
            else {
                return <p>no available channels</p>;
            }            
        }
            
    }
    render() {
        const {show, title, onClose, onSubmit} = this.props;
        return <Modal show={show} 
                title={title} 
                submitTitle="OK"
                handleSubmit={onSubmit}
                handleClose={onClose}
            >
            <form className="m-t">
                <div className="form-group">
                    <label className="font-normal control-label">Artist</label>
                    <AsyncSelect cacheOptions defaultOptions loadOptions={this.fetchArtists} onChange={this.selectArtist} />
                </div>
                <div className="form-group">
                    <label className="font-normal control-label">Social Channels</label>
                    {this.renderField('channels')}
                </div>
                <div className="form-group">
                    <label className="font-normal control-label">Videos</label>
                    {this.renderField('videos')}
                </div>                    
                <div className="form-group">
                    <label className="font-normal control-label">Products</label>
                    {this.renderField('products')}
                </div>
                <div className="form-group">
                    <label className="font-normal control-label">Tracks</label>
                    {this.renderField('tracks')}
                </div>            
            </form>
        </Modal>
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        products: state.products
    } 
}
function mapDispatchToProps(dispatch) {
    return {
        getArtists: (value) => dispatch(artistsActions.getArtistsAutocomplete(value)),
        getSocials: (id) => dispatch(artistsActions.getCard(id, true)),
        getProducts: (id) => dispatch(artistsActions.getProducts(id)),
        getTracks: (ids) => dispatch(productsActions.getTracks(ids)),
        addSales: (params, campaign) => dispatch(campaignsActions.addSalesChannel(params, campaign)),
        addSocial: (params, campaign) => dispatch(campaignsActions.addSocialChannel(params, campaign)),
        linkMilestone: (params, milestone, mode) => dispatch(milestonesActions.linkMilestone(params, milestone, mode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddChannelToCampaignForm)