export const searchConstants = {
    GET_SEARCH_REQUEST: 'GET_SEARCH_REQUEST',
    GET_SEARCH_SUCCESS: 'GET_SEARCH_SUCCESS',
    GET_SEARCH_FAILURE: 'GET_SEARCH_FAILURE',
    GET_SEARCH_AUTOCOMPLETE_REQUEST: 'GET_SEARCH_AUTOCOMPLETE_REQUEST',
    GET_SEARCH_AUTOCOMPLETE_SUCCESS: 'GET_SEARCH_AUTOCOMPLETE_SUCCESS',
    GET_SEARCH_AUTOCOMPLETE_FAILURE: 'GET_SEARCH_AUTOCOMPLETE_FAILURE',
    GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST: 'GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST',
    GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS: 'GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS',
    GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE: 'GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE',    
    ADD_RECENT_ITEM: 'ADD_RECENT_ITEM',
    CLEAR_RECENT_ITEMS: 'CLEAR_RECENT_ITEMS',
    GET_SEARCH_IMPORT_REQUEST: 'GET_SEARCH_IMPORT_REQUEST',
    GET_SEARCH_IMPORT_SUCCESS: 'GET_SEARCH_IMPORT_SUCCESS',
    GET_SEARCH_IMPORT_FAILURE: 'GET_SEARCH_IMPORT_FAILURE',
    GET_SEARCH_RESULT_REQUEST: 'GET_SEARCH_RESULT_REQUEST',
    GET_SEARCH_RESULT_SUCCESS: 'GET_SEARCH_RESULT_SUCCESS',
    GET_SEARCH_RESULT_FAILURE: 'GET_SEARCH_RESULT_FAILURE',
    GET_SEARCH_RESULTS_REQUEST: 'GET_SEARCH_RESULTS_REQUEST',
    GET_SEARCH_RESULTS_SUCCESS: 'GET_SEARCH_RESULTS_SUCCESS',
    GET_SEARCH_RESULTS_FAILURE: 'GET_SEARCH_RESULTS_FAILURE',
    SAVE_SEARCH_RESULTS_REQUEST: 'SAVE_SEARCH_RESULTS_REQUEST',
    SAVE_SEARCH_RESULTS_SUCCESS: 'SAVE_SEARCH_RESULTS_SUCCESS',
    SAVE_SEARCH_RESULTS_FAILURE: 'SAVE_SEARCH_RESULTS_FAILURE',
}