import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';

class RedirectToResults extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const { entity, id, tab="overview" } = this.props;
        const filter = JSON.stringify({[entity]: [id]});
        return <Redirect to={`/filter/${filter}/${tab}`} />    
    }
}

export default RedirectToResults