import { entityFiltersService } from '../services/entity_filters';
import { entityFiltersFormatter } from '../formatters/entity_filters';
import { entityFiltersConstants } from '../constants/entity_filters';

export const entityFiltersActions = {
  getEntityFilters,
  getAllEntityFilters,
  updateEntityFilter,
  createFilter,
  destroyFilter
}

function destroyFilter(filter, entity){
  return (dispatch, getState) => {
    dispatch(request());

    return entityFiltersService.destroyFilter(filter)
    .then( response => {
      dispatch(success(response.data, entity));
    })
    .catch( error => {
      dispatch(failure(error));
    });
  }

  function request() { return { type: entityFiltersConstants.DESTROY_ENTITY_FILTER_REQUEST }};
  function success(payload, entity) { return { type: entityFiltersConstants.DESTROY_ENTITY_FILTER_SUCCESS, payload, entity }};
  function failure(payload) { return { type: entityFiltersConstants.DESTROY_ENTITY_FILTER_FAILURE, payload }};
}

function createFilter(params){
  return dispatch => {
    dispatch(request());

    return entityFiltersService.createFilter(params)
    .then( response => {
      dispatch(success(response.data, params.entity));
      return response.data;
    })
    .catch( error => {
      dispatch(failure(error));
    })
  }

  function request() { return { type: entityFiltersConstants.CREATE_ENTITY_FILTER_REQUEST }};
  function success(payload, entity) { return { type: entityFiltersConstants.CREATE_ENTITY_FILTER_SUCCESS, payload, entity}};
  function failure(payload) { return { type: entityFiltersConstants.CREATE_ENTITY_FILTER_FAILURE, payload }};
}

function getEntityFilters(entity){
  return dispatch => {
    dispatch(request(entity));

    return entityFiltersService.getEntityFilters(entity)
    .then( response => {
      dispatch(success(response.data, entity));
    })
    .catch( error => {
      dispatch(failure(error, entity));
    })
  }
  function request(entity) { return { type: entityFiltersConstants.GET_ENTITY_FILTERS_REQUEST, entity }};
  function success( payload, entity) { return { type: entityFiltersConstants.GET_ENTITY_FILTERS_SUCCESS, payload, entity }};
  function failure( payload, entity ) { return { type: entityFiltersConstants.GET_ENTITY_FILTERS_FAILURE, error: payload, entity }};
}

function updateEntityFilter(filter, entity){
  return dispatch => {
    dispatch(request(entity));

    return entityFiltersService.updateEntityFilter(filter, entity)
    .then( response => {
      const filter = response.data;
      dispatch(success(filter, entity));
      return filter;
    })
    .catch( error => {
      dispatch(failure(error, entity));
    });
  }
  function request(entity) { return { type: entityFiltersConstants.UPDATE_ENTITY_FILTER_REQUEST, entity }};
  function success( payload, entity) { return { type: entityFiltersConstants.UPDATE_ENTITY_FILTER_SUCCESS, payload, entity }};
  function failure( payload, entity ) { return { type: entityFiltersConstants.UPDATE_ENTITY_FILTER_FAILURE, error: payload, entity }};
}

function getAllEntityFilters(){
    return dispatch => {
      dispatch(request());

      return entityFiltersService.getAllEntityFilters()
      .then( response => {
        const data = entityFiltersFormatter.formatAllEntityFilters(response.data);           
        dispatch(success(data));
      })
      .catch( error => {
        dispatch(failure(error));
      })
    }
    function request(entity) { return { type: entityFiltersConstants.GET_ALL_ENTITY_FILTERS_REQUEST, entity }};
    function success( payload ) { return { type: entityFiltersConstants.GET_ALL_ENTITY_FILTERS_SUCCESS, payload }};
    function failure( payload ) { return { type: entityFiltersConstants.GET_ALL_ENTITY_FILTERS_FAILURE, error: payload }};
  }
