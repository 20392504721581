import React, { Component } from 'react';
import { connect } from 'react-redux';
import { campaignsActions } from '../../data/actions/campaigns';
import Box from '../../components/common/Box';

import CampaignTypeBox from '../../components/pages/campaigns/CampaignTypeBox';
import FollowersStats from '../../components/pages/campaigns/Followers';
import CampaignItems from '../../components/pages/campaigns/CampaignItems';
import Milestones from '../../components/pages/campaigns/Milestones';
import CSV from '../../helpers/CSVExport';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class CampaignDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
        };

        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id}, ()=>{
            this.refresh();
        });

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.match && nextProps.match.params.id!==this.state.id) {
            this.setState({id: nextProps.match.params.id}, ()=>{
                this.refresh();
            })
        }
    }

    exportToCsv = () => {
      const {
        campaigns,
        milestones,
      } = this.props;

      const data = [
        { campaign_items: campaigns.campaign[this.state.id].campaign_sales },
        { campaign_followers: campaigns.campaign[this.state.id].campaign_socials },
        { milestones: milestones.tableItems }
      ];
      
      const filename = CSV.CSVHeader('campaign', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);
    }

    refresh() {
        this.props.dispatch(campaignsActions.getCampaignDetails(this.state.id));
    }

    render() {
        let campaign = this.props.campaigns.campaign[this.state.id],
            //campaignEntities = {'products': null, 'tracks': null};
        campaignEntities = ['products', 'tracks'];
//        if(campaign) {
//            for(let entity of Object.keys(campaignEntities)) {
//                campaignEntities[entity] = campaign[entity] ? campaign[entity] : null;
//            }
//        }

        return <Box title="Campaign Details"  className="" spinnerEnabled={this.props.campaigns.campaignLoading}>
            {campaign && <div className="row">
                <div className=" download-page">
                  <span>Export</span>
                  <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                </div>
                <div className="">
                    <CampaignItems campaign={campaign} refresh={this.refresh} />
                </div>
                <div className="">
                    {campaignEntities.map(entity=><CampaignTypeBox entity={entity} campaign={campaign} />)}

                    <FollowersStats channels={campaign.campaign_socials} />
                    <Milestones id={this.state.id} refreshCampaign={this.refresh} />
                </div>
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        campaigns: state.campaigns,
        filter: state.filter,
        milestones: state.milestones
    }
}

export default connect(mapStateToProps)(CampaignDetails)
