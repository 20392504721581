import _ from 'lodash'
import vendorsEnum from '../../components/widgets/filter/enums/vendors.json';

export const vendorsFormatter = {
    formatVendorStats,
    formatVendorStatuses
};

function formatVendorStats(data){
    const dates = _.sortBy(_.uniq(_.map(data, item=>item.gen_date)), (date)=>new Date(date)),
        silos = _.uniq(_.map(data, item=>item.silo)),
        vendors = _.map(vendorsEnum, item=>item.value);
        
    let result = [];

    
    for (let vendor of vendors) {
        for(let siloID in silos) {
            const silo = silos[siloID],
                vendorID = _.find(vendorsEnum, {value: vendor}).vend_id;
            
            let item = {silo, vendor, siloID, vendorID},
                foundDays = 0,
                foundTotal = 0;
                
            for(let gen_date of dates) {
                let record = _.find(data, {silo, vendor, gen_date});
                    
                if(record) {
                    const { rpt_rows: rows } = record;
                    foundDays++;
                    foundTotal += rows;
                    item[gen_date] = rows;
                }
                else
                    item[gen_date] = null; 
            }
            
            item.average = foundDays ? Math.round(foundTotal / foundDays) : 0;
            result.push(item);
        }
    }
    
    return { result, dates};
}


function formatVendorStatuses(stats, statuses) {
    for(let status of statuses) {
        let stat = _.find(stats, {vendorID: status.vendor_id, silo: status.silo_code});
        if(stat)
            stat.status = status.status;
    }
    return stats;
}