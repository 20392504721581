import React from 'react';
import moment from 'moment';
import AbsoluteValue from '../../../common/AbsoluteValue';

export const monthlyStatsSettings = {
	settingsForTable
};


function settingsForTable(){
	let columns = [
		{
		   id: 'month',
		   className: '',
		   headerClassName: '',
		   Header: 'Month',
		   accessor: 'current_end_date',
		   Cell: props => props.value ? <span>{moment(props.value).format('MMM YYYY')}</span> : '-',
	   },
	   {
		   id: 'status',
		   headerClassName: '',
		   Header: 'Status',
		   accessor: 'status',
	   },
	   {
		   id: 'streams',
		   headerClassName: '',
		   Header: 'Streams',
		   accessor: 'current_months_plays',
		   Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
	   },
	   {
		  id: 'listeners',
		  headerClassName: '',
		  Header: 'Listeners',
		  accessor: 'current_months_unique_users',
		  Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
  	    }
	   
	];

	return columns;
}
