import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { find, maxBy, isEqual, cloneDeep } from 'lodash';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import Box from '../../common/Box';
import TrendDirection from '../../common/TrendDirection';
import { audienceActions } from '../../../data/actions/audience';
import PieChart from '../../widgets/charts/types/PieChart';
import { sourcesSettings } from './Sources/Settings';
import VendorLogo from '../../common/VendorLogo';
import { dspLogos } from "../../common/Stats/utils";
import ModeSelect from '../../common/ModeSelect';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import Tooltip from '../../common/Tooltip';
import Spinner from "../../common/Spinner";
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import { FillLineChart } from '../../widgets/charts/types';
import EntitySelector from '../../pages/result/EntitySelector';

var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var playlistSvg = require('!svg-inline-loader!../../../../public/img/playlist.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');


class SourcesStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          mode: props.defaultMode || "pie",
          hiddenSources: {
              playlist: [],
              radio: []
          },
          entityFilter: null
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getDataKey = this.getDataKey.bind(this);
        this.hideSource = this.hideSource.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
    }

    setChartMode(mode){
        this.setState({mode}, ()=>{
            this.loadData();
        });
        
        /*
        if (mode == "playlist")
            this.getPlaylistStats();

        if (mode == "line" || mode == "bar")
            this.getTimeseries();
        */

        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(mode);

    }

    getDataKey() {
        const { discovery = true } = this.props;
        const { mode } = this.state;
        let dataKey = '';
        switch(mode) {
            case 'line':
            case 'bar':
                dataKey = 'Timeseries';
                break;
            case 'playlist':
                dataKey = 'Playlists';
                break;

            default:
                dataKey = 'Stats';
        }
        let discoveryKey = discovery ? 'Discovery' : '';
        return discoveryKey+dataKey;
    }
    
    loadData() {
        const { entityFilter } = this.state;
        const { dispatch, id, entity, filtered } = this.props;
        const dataKey = this.getDataKey();

        const dataCall =  audienceActions[`getSource${dataKey}`];
        dispatch(dataCall(id, entity, entityFilter ? entityFilter : filtered));    
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.loadData());
    }
    
    
    componentDidMount() {
        const { chartType } = this.props;
        this.loadData();
    }
    
    exportToCsv(){
        const { discovery = true } = this.props;
        const dataKey = discovery ? 'sourceDiscoveryStats' : 'sourceStats';
        const fileNameSuffix = discovery ? 'sources_discovery' : 'sources';
        const filename = CSV.CSVHeader(`audience_details_${fileNameSuffix}`, 'listeners', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.audience[dataKey].table, {filename});
    }
    
    componentWillReceiveProps(nextProps) {
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.loadData();
            }                
        }
    }
    
    getTimeseries() {
        const { entityFilter } = this.state;
        const { dispatch, id, entity, filtered, discovery = true } = this.props;
        const dataCall = discovery ? audienceActions.getSourceDiscoveryTimeseries : audienceActions.getSourceTimeseries;
        dispatch(dataCall(id, entity, entityFilter ? entityFilter : filtered));
    }
    
    getPlaylistStats() {
        const { entityFilter } = this.state;
        const { dispatch, id, entity, filtered, discovery = true } = this.props;
        const dataCall = discovery ? audienceActions.getSourceDiscoveryPlaylists : audienceActions.getSourcePlaylists;
        dispatch(dataCall(id, entity, entityFilter ? entityFilter : filtered));
    }
    
    hideSource(source, type) {
        let types = Object.assign([], this.state.hiddenSources[source]);
        if(types.includes(type))
            types.splice(types.indexOf(type), 1);
        else 
            types.push(type);    
        this.setState({
            ...this.state,
            hiddenSources: {
                ...this.state.hiddenSources,
                [source]: types
            }
        });
    }
    
    getSourceChartData(data, source, hiddenSources) {
        let chartData = cloneDeep(data[source]);
        let hiddenTypes = hiddenSources[source];
        let indexesToRemove = [];
        for(let labelId in chartData.labels) {
            if(hiddenTypes.includes(chartData.labels[labelId]))
                indexesToRemove.push(Number(labelId));
        }
        chartData.labels = chartData.labels.filter((item, index)=>!indexesToRemove.includes(index));
        for(let dataset of chartData.datasets) {
            dataset.data = dataset.data.filter((item, index)=>!indexesToRemove.includes(index));
            dataset.backgroundColor = dataset.backgroundColor.filter((item, index)=>!indexesToRemove.includes(index));
        }
        return chartData;
    }
    
    renderToolbar(modes){
        let toolbar = [];
        const options = [
            {icon: pieSvg, value: 'pie', label: "Donut Chart"},
            {icon: playlistSvg, value: 'playlist', label: "Playlist and Radio Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: barSvg, value: 'bar', label: "Bar Chart"}
        ];

        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
                <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
            </div>
        </div>)
        return toolbar;
    }    
    
    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = ['spotify'];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }

    renderLegend(datasets, source, hiddenSources) {
        const dataset = datasets[source];
        let items = [];
        for(let index in dataset.labels) {
            const color = dataset.datasets[0].backgroundColor[index],
                label = dataset.labels[index];
            items.push(<li className={`sources-legend-item ${hiddenSources[source].includes(label)?'legend-item-hidden':''}`} onClick={()=>this.hideSource(source, label)} key={label}><i className="fas fa-circle sources-color" style={{color}} />{label}</li>);
        }
        return items;
    }

    renderPlaylistStats(dataKey) {
        const { audience, discovery = true } = this.props;
        //const dataKey = discovery ? "sourceDiscoveryPlaylists" : "sourcePlaylists";
        const loading = discovery ? audience.sourceDiscoveryPlaylistsLoading : audience.sourcePlaylistsLoading;
        const data = audience[dataKey];
        let { hiddenSources } = this.state;

        if (loading)
            return <Spinner enabled={true} />

        const chartOptions = {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.label;
                        }
                    }
                }           
            }
        }
        
        return ( data ? <div className="row sources-list">
            <div className="col-xs-12 col-sm-6 sources-list-item">
                <h4 className="sources-item-title">Playlist</h4>
                <div className="chart-block">
                    <DoughnutChart data={this.getSourceChartData(audience[dataKey], 'playlist', hiddenSources)} options={chartOptions} />
                </div>
                <ul className="sources-legend">
                    {this.renderLegend(audience[dataKey], 'playlist', hiddenSources)}
                </ul>
            </div>
            <div className="col-xs-12 col-sm-6 sources-list-item">
                <h4 className="sources-item-title">Radio</h4>
                <div className="chart-block">
                    <DoughnutChart data={this.getSourceChartData(audience[dataKey], 'radio', hiddenSources)} options={chartOptions} />
                </div>
                <ul className="sources-legend">
                {this.renderLegend(audience[dataKey], 'radio', hiddenSources)}
                </ul>                    
            </div>                
        </div> : <p>No source data available</p> );
    }
    
    renderChartActions(){
        const { mode } = this.state;
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entityFilterMode="array" />        
        </React.Fragment>
    }

    
    render (){
        const { audience, discovery = true } = this.props;
        const dataKey = 'source' + this.getDataKey();

        const shadowChartID = discovery ? 'audience_sources_discovery_pie_chart' : 'audience_sources_pie_chart';
        const title = discovery ? 'Sources of Discovery' : 'Sources'; 
        
        const rows = (audience[dataKey] && audience[dataKey].table) ? audience[dataKey].table.length : 0;
        let { shadowChartProps } = this.props;
        if (shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        return <Box title={title} toolbar={this.renderToolbar()} chartActions={this.renderChartActions()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={audience[`${dataKey}Loading`]} exportToCsv={this.renderExportToCsv()} className="source-discovery-holder">
            {audience[dataKey] && <div className="row">
                <div className={``}>
                    {this.state.mode=='pie' && <div className="chart-block">
                        <DoughnutChart shadowChart={true} shadowChartWidth={400} shadowChartHeight={300} data={audience[dataKey].chart} id={shadowChartID} />                          
                    </div>}
                    {this.state.mode=='table' && <div className="sources-table territory-table--single custom-scroll">
                        <ReactTable
                            className="source-table simple-table"
                            data={audience[dataKey].table}
                            columns={sourcesSettings.settingsForTable('details')}
                            defaultPageSize={(audience[dataKey].table && audience[dataKey].table.length ) ? Math.min(audience[dataKey].table.length, 5) : 5}
                            showPagination={rows > 5}
                            getTrProps={sourcesSettings.settingsForRow}
                    /></div>}
                    
                    {this.state.mode=='line' && <div className="chart-block">
                        <FillLineChart data={audience[`${dataKey}`]} shadowChart={true} shadowChartWidth={400} shadowChartHeight={300} id={shadowChartID}  />                          
                    </div>}
                    {this.state.mode=='bar' && <div className="chart-block">
                        <StackedBarChart data={audience[`${dataKey}`]} shadowChart={true} shadowChartWidth={400} shadowChartHeight={300} id={shadowChartID} />                          
                    </div>}
                    {this.state.mode=='playlist' && <div>
                        <div className="table-header-tooltip sources">
                            <Tooltip 
                                position="right-bottom" 
                                message={`The granularity on Playlist and Radio sources is shown only when possible, provided the streaming platform gave us enough depth of data.`} 
                                tooltipClass="toolbar-title-tooltip sources-big-tooltip" 
                            />
                        </div>
                        {this.renderPlaylistStats(dataKey)}
                    </div>}                    
                    
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(SourcesStats);