import CryptoJS from "crypto-js";
import config from '../config/config';

module.exports = {
    encrypt,
    decrypt
}

function encrypt(val, origin = window.location.origin) {
    return CryptoJS.AES.encrypt(val, config.encryptionKey+origin).toString();
}

function decrypt(val, origin = window.location.origin) {
    return CryptoJS.AES.decrypt(val, config.encryptionKey+origin).toString(CryptoJS.enc.Utf8);
}