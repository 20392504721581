import React from 'react';
import { Link } from 'react-router-dom';
import VendorLogo from '../../../common/VendorLogo';

export const dataAdminSettings = {
    settingsForTable
};

function settingsForTable(dates, showApprove, showRevoke){
    const ratioThreshold = 0.3;
    
    let columns = [
        {
            id: 'vendor',
            className: 'logo-sell',
            headerClassName: 'logo-sell no-sort',
            Header: 'Logo',
            accessor: 'vendor',
            width: 86,
            sortable: false,
            Cell: props => {
               return <VendorLogo name={props.value} modificator="small" /> ;
            }
          },
          /*
       {
           id: 'silo',
           Header: 'Silo Code',
           accessor: 'silo',
        //    width: 60,
       }
       */
    ];
    
    for(let date of dates) {
        columns.push({
            id: date,
            Header: date,
            accessor: date,
            // width: 60,
            className: 'data-admin-cell',
            Cell: props => {
                const ratio = props.value / props.original.average;
                let cellClassName = 'average';
                
                if(ratio > (1 + ratioThreshold))
                    cellClassName = 'above-average';
                if(ratio < (1 - ratioThreshold))
                    cellClassName = 'below-average';
                if(ratio === 0 || !props.value)
                    cellClassName = 'empty';
                
                return <span className={cellClassName}>{props.value}</span> 
            }
        });
    }
    /*
    columns.push({
        id: 'links',
        className: 'lowercase grid-td action-td',
        headerClassName: 'no-sort action-td',
        Header: 'Actions',
        sortable: false,
        Cell: props => {
            const vendor = props.original,
                {silo, vendorID, status = 'default'} = vendor;
            return (
              <div className="action-icons">
                <button
                  onClick={() => showApprove(vendor)}
                  className={`chart-icon action-icon-btn status-${status}`}
                >
                  <i className="far fa-edit" title="approve"></i>
                </button>
                <button
                  onClick={() => showRevoke(vendor)}
                  className={`chart-icon action-icon-btn status-${status}`}
                >
                  <i className="fas fa-trash-alt" title="revoke"></i>
                </button>
              </div>
            );}
    },       
    )*/
    return columns;
}