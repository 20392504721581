import React, { Component } from "react";
import { find, cloneDeep, merge } from 'lodash';
import initialSettings from './InitialSettings.json';
import store from '../../../helpers/store';

export default function WithWidgetManager(WrappedComponent, type) {
  return class extends Component {
    constructor(props) {
        super(props);
        let widgetSettings = store.get('widgetSettings');
        if(!widgetSettings)
            widgetSettings = cloneDeep(initialSettings);
        // let settings = Object.assign({}, initialSettings, widgetSettings)
        let settings = merge({}, initialSettings, widgetSettings);
        this.state = {
            ...settings[type],
            showChangeEnabled: false
        };
        this.showChange = this.showChange.bind(this);
        this.modeChange = this.modeChange.bind(this);
        this.persistSettings = this.persistSettings.bind(this);
        this.toggleShowChange = this.toggleShowChange.bind(this);
        this.resetSettings = this.resetSettings.bind(this);
    }

    componentDidMount() {
    }
    
    modeChange(widget, mode) {
        this.setState({
            ...this.state,
            [widget]: {
                ...this.state[widget],
                mode
            }
        }, ()=>{
            this.persistSettings();
        })
    }
    
    showChange(widget, show) {
        this.setState({
            ...this.state,
            [widget]: {
                ...this.state[widget],
                show
            }
        }, ()=>{
            this.persistSettings();
        })
    }
    
    persistSettings() {
        let widgetSettings = store.get('widgetSettings');
        if(!widgetSettings)
            widgetSettings = cloneDeep(initialSettings);
        widgetSettings[type] = {
            ...this.state
        }
        // let settings = Object.assign({}, initialSettings, widgetSettings)
        let settings = merge({}, initialSettings, widgetSettings);
        store.set('widgetSettings', settings);
    }
    
    toggleShowChange() {
        this.setState({
            ...this.state,
            showChangeEnabled: !this.state.showChangeEnabled
        });
    }

    resetSettings() {
        let widgetSettings = cloneDeep(initialSettings);

        this.setState({
            ...widgetSettings[type],
            showChangeEnabled: false
        }, ()=>{
            this.persistSettings();
        })

    }
    
    render() {
        return <WrappedComponent widgetProps={{widgetState: this.state, modeChange: this.modeChange, showChange: this.showChange, toggleShowChange: this.toggleShowChange, resetSettings: this.resetSettings}} {...this.props} />;
    }
  };
}