import React from 'react';
import {Helmet} from 'react-helmet';
import Progress from '../common/Progress';
import NavigationMain from '../common/NavigationMain';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import Filter from '../widgets/filter/Filter';
import ChatWidget from '../widgets/chat/ChatWidget';
import AdminRoutes from '../../config/routes/admin';
import WithPermission from '../../helpers/WithPermission';
import withSiloCode from '../../helpers/WithSiloCode';
import { withRouter } from 'react-router';
import { correctHeight, detectBody } from './Helpers';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper'
import SearchWidget from '../pages/home/SearchWidget';

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalExpanded: false,
            isFilterOpened: false,
            filterDataCount: null,
        }
    } 
    
    toggleFilterOpened = () => {
        this.setState({
            isFilterOpened: !this.state.isFilterOpened
        });        
    }

    getFilterCount = (filterCount) => {
        this.setState({
            filterDataCount: filterCount
        })
    }

    render() {
        let wrapperClass = " " + this.props.location.pathname;
        const desktopNavigation = <NavigationMain location={this.props.location}/>
        const { filterDataCount } = this.state;
        const pathname = window.location.pathname;
        const pathnameRegExp = /admin/;
        const isFilderDisabled = pathnameRegExp.test(pathname);
        const isWhiteLabel = pathname == '/admin/white-label' ? true : false;
        const searchWidget = <div className="main-search-holder">
            <SearchWidget searchResult={this.searchResult} placeholder="Search all entities.." onExpand={this.onExpand} searchDropdownMode="single" />
            </div>
        const whiteLabelClass = isWhiteLabel ? 'white-label' : ''

        return (
            <div id="wrapper" className={this.props.silo}>
                <Progress />
                <ErrorBox />
                <WhiteLabelCss silo={this.props.silo} />
                <Helmet defaultTitle="Enterlytics" titleTemplate="%s - Enterlytics" />
                <div id="page-wrapper" className={`page-wrapper admin-page-wrapper ${whiteLabelClass} ${wrapperClass} `}>
                    <TopHeader 
                        // isFilterOpened={this.state.isFilterOpened}
                        // toggleFilterOpened={this.toggleFilterOpened}
                        // filterCount={filterDataCount}
                    >
                        { isWhiteLabel && 
                        <MatchMediaWrapper isMobile={false} breakpoint={639}>
                            {searchWidget}
                        </MatchMediaWrapper> }
                    </TopHeader>
                    <Filter 
                        filterClass={!isFilderDisabled ? "" : "is-main"}
                        isFilterOpened={this.state.isFilterOpened}
                        toggleFilterOpened={this.toggleFilterOpened}
                        callBackFromMain={this.getFilterCount}
                    />
                    <WithPermission permission="allow_admin">
                        <ChatWidget />
                    </WithPermission>
                    <WithPermission permission={["allow_admin", "can_grant_access"]}>
                        <div className="main-content-wrapper">
                            { isWhiteLabel && 
                                <MatchMediaWrapper isMobile={true} breakpoint={639}>
                                    {searchWidget}
                                </MatchMediaWrapper>
                            }
                            <AdminRoutes />
                        </div>
                    </WithPermission>
                    <Footer />
                </div>

            </div>

        )
    }

    componentDidMount() {

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function() {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }
}

export default withSiloCode(withRouter(Admin))
