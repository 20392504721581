import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class FilterSummaryLogoStatic extends React.Component {

	constructor(props){
		super(props);
		this.state = {
		}
	}
	
	render() {
		let { entity} = this.props;
		return <div className="logo" style={{backgroundImage: `url(${entity.entity_image})`}}></div>;
	}
}


export default FilterSummaryLogoStatic