export const campaignsConstants = {
    GET_CAMPAIGNS_REQUEST: 'GET_CAMPAIGNS_REQUEST',
    GET_CAMPAIGNS_SUCCESS: 'GET_CAMPAIGNS_SUCCESS',
    GET_CAMPAIGNS_FAILURE: 'GET_CAMPAIGNS_FAILURE',
    GET_CAMPAIGN_DETAILS_REQUEST: 'GET_CAMPAIGN_DETAILS_REQUEST',
    GET_CAMPAIGN_DETAILS_SUCCESS: 'GET_CAMPAIGN_DETAILS_SUCCESS',
    GET_CAMPAIGN_DETAILS_FAILURE: 'GET_CAMPAIGN_DETAILS_FAILURE',
    ADD_CAMPAIGN_REQUEST: 'ADD_CAMPAIGN_REQUEST',
    ADD_CAMPAIGN_SUCCESS: 'ADD_CAMPAIGN_SUCCESS',
    ADD_CAMPAIGN_FAILURE: 'ADD_CAMPAIGN_FAILURE',
    ADD_CHANNEL_REQUEST: 'ADD_CAMPAIGN_CHANNEL_REQUEST',
    ADD_CHANNEL_SUCCESS: 'ADD_CAMPAIGN_CHANNEL_SUCCESS',
    ADD_CHANNEL_FAILURE: 'ADD_CAMPAIGN_CHANNEL_FAILURE',
    DELETE_CHANNEL_REQUEST: 'DELETE_CAMPAIGN_CHANNEL_REQUEST',
    DELETE_CHANNEL_SUCCESS: 'DELETE_CAMPAIGN_CHANNEL_SUCCESS',
    DELETE_CHANNEL_FAILURE: 'DELETE_CAMPAIGN_CHANNEL_FAILURE'    

}