import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import config from '../../../config/config';
import ReactSelect from '../../common/ReactSelect';
import Box from '../../common/Box';
import TrendDirection from '../../common/TrendDirection';
import FollowersCard from './FollowersCard';
import { artistsActions } from '../../../data/actions/artists';
import { channelsActions } from '../../../data/actions/channels';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import LineChart from '../../widgets/charts/types/LineChart';
import CSV from '../../../helpers/CSVExport';
import ModeSelect from '../../common/ModeSelect';

var cardsSvg = require('!svg-inline-loader!../../../../public/img/card.svg');
var videosSvg = require('!svg-inline-loader!../../../../public/img/video.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var lineUpSvg = require('!svg-inline-loader!../../../../public/img/chart-up-arrow.svg');

class FollowersStats extends React.Component {

    nullElement = {value: null, label: 'All'};
    
    constructor(props) {
        super(props);
        const defaultMode = props.defaultMode || 'line';
        this.state = {
            mode: defaultMode,
            channel: this.nullElement
        }
        
        this.setChannel = this.setChannel.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setChartView = this.setChartView.bind(this);
        this.deleteChannel = this.deleteChannel.bind(this);
    }
    
    
    componentDidMount() {
        const { metric } = this.props;
        const channels = _.map(this.props.artist.artists_channels, 'channel_id');
        this.props.dispatch(artistsActions.getFollowers(channels, true, metric));
    }
    
    componentWillReceiveProps(nextProps){
        const { metric } = this.props;
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !_.isEqual(nextProps.filter.global, this.props.filter.global)) {
                const channels = _.map(this.props.artist.artists_channels, 'channel_id');
                this.props.dispatch(artistsActions.getFollowers(channels, true, metric));

            }                
        }
    }

    
    exportToCsv (){
        const filename = CSV.CSVHeader('followers', 'curr_units', this.props.filter.global); 
        return CSV.CSVExport(this.props.artists.followers.data, {filename}, 'followers');        
    }
    
    setChannel(channel) {
        this.setState({channel});
    }
    
    setChartView(mode){
        this.setState({mode});
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(mode);
        
    }
    
    deleteChannel(channel) {
        const {dispatch, artist } = this.props;
        dispatch(channelsActions.deleteChannel(artist.id, channel));        
    }
    
    renderToolbar(data, showChannels){
        
        let channels = [this.nullElement];
        if(data.followers) {
            for(let channel of data.followers.data) {
                channels.push({
                    value: channel.channel_id,
                    label: channel.label
                });
            }
        }
        //onChange={this.setPlaylist} 
        let toolbar = [];
        let options = [
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: lineUpSvg, value: 'abs', label: "Absolute Growth"},
            
        ];
        if(showChannels)
            options.push({icon: cardsSvg, value: 'cards', label: "Channels"});
        
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
          {/*<ReactSelect value={this.state.channel} options={channels} onChange={this.setChannel} className="single-select artist-channels-select"/>*/}
        </div>
        <div className="ibox-icon-holder mode-select">
            <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartView} isSearchable={ false } />
        </div>
        </div>)

        return toolbar;
    }
    
    renderTrend(data) {
        let total = 0,
            top = 0,
            topChannel = null;
        
        for(let channel of data) {
            const { growth, channel_type } = channel; 
            total += growth;
            if(growth >= top) {
                top = growth;
                topChannel = channel_type;
            }
        }
        const gain = total >= 0 ? 'gained' : 'lost';
        total = Math.abs(total);
        return <div>
            <h2 className="content-title capitalize">{convertToThousands(total)}</h2>
            <h4 className="content-subtitle"> {gain} followers across all social platforms</h4>
            {top > 0  && <TrendDirection direction="up">
                <p><strong>{topChannel}</strong> was your fastest growing audience with <span className="num">{convertToThousands(top)} new subscribers</span></p>
            </TrendDirection>}
        </div>;                        
    }
    
    getChannels(data){
        return data.filter(item=>item.channel_type!='YouTube Video');    
    }
    
    getVideos(data){
        return data.filter(item=>item.channel_type=='YouTube Video');    
    }
    
    render (){
        const artists = this.props.artists,
            trends = config.showTrends,
            { metric } = this.props,
            title = ( metric == 'metric' ) ? 'Followers' : 'Monthly Listeners';
        
        let timelineData;
        const key =( metric == 'metric' )? 'followers' : 'monthlyListeners';
        
        if(artists[key]){
            timelineData = Object.assign({}, artists[key].chart);
            if(this.state.channel.value!==null) {
                timelineData.datasets = [_.find(artists[key].chart.datasets, {id: this.state.channel.value})];
            }
            
            timelineData.datasets.map(dataset=>{
                dataset.data = this.state.mode == 'line' ? dataset.timeseries : dataset.absTimeseries;  
                return dataset;
            })
        }
        
        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        const noChannels = !artists.followersLoading && !artists[key];
        
        return <Box title={title} spinnerEnabled={artists.followersLoading} toolbar={this.renderToolbar(artists, (metric == 'metric'))}>
            {artists[key] && <div className="row">
                {trends && <div className="col-lg-4 col-md-4 col-xs-12">
                    {this.renderTrend(this.getChannels(artists[key].data))}
                </div>}
                <div className={`col-xs-12 col-sm-${trends?8:12}`}>
                    {this.state.mode=='cards' && <div className="entities-list ">
                        <div className="followers-entities-holder">
                            {this.getChannels(artists.followers.data).map(item=><FollowersCard item={item} key={item.channel_url} metric="followers" deleteChannel={()=>this.deleteChannel(item)} />) }
                        </div>
                    </div>}
                    {this.state.mode=='videos' && <div className="entities-list ">
                        <div className="followers-entities-holder">
                            {this.getVideos(artists.followers.data).map(item=><FollowersCard item={item} key={item.channel_url} metric="views"  deleteChannel={()=>this.deleteChannel(item)} />) }
                        </div>
                    </div>}
                    
                    {this.state.mode=='line' && <div className="chart-block"><LineChart mode={this.state.mode} data={timelineData} {...shadowChartProps} /></div> }
                    {this.state.mode=='abs' && <div className="chart-block"><LineChart mode={this.state.mode} data={timelineData} {...shadowChartProps} /></div> }
                </div>
            </div>}
            {noChannels && <p>There are no tracked channels for this artist</p>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(FollowersStats);