import { find } from 'lodash';
import vendors from '../../components/widgets/filter/enums/vendors';
import territories from '../../components/widgets/filter/enums/territories';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';
import sources from '../../components/widgets/filter/enums/sources';
import contentTypes from '../../components/widgets/filter/enums/content_types';


export const globalFilterFormatter = {
    formatGlobalFilter,
    formatSharedGlobalFilter,
    formatFilterSummary
};

function formatGlobalFilter(data) {
    return ( data && data.entities ) ? data.entities : [];
}

function formatSharedGlobalFilter(data, qs) {
    const filterMap = {
            'curr_start': 'dateStart',
            'curr_end': 'dateEnd',
            'prev_start': 'prevDateStart',
            'prev_end': 'prevDateEnd'
    };
    if(qs && qs.hasOwnProperty('query')) {
        for(let key of Object.keys(qs.query)) {
            if(filterMap[key]) {
                data[filterMap[key]] = qs.query[key];
            }
        }
    }
    return data;
}


function renderLimitedString (items, limit) {
    const overLimit = items.length - limit;
    let title = items.slice(0, limit).join(', ');
    
    if(overLimit > 0)
        title += ` + ${overLimit} more`;

    return title;
}

function formatFilterSummary (filter, limit = 3 ) {
    if(!filter || !filter.dateStart)
        return null;
    let extras = [];
    
    let totalTerritories = 0;
    for(let continent of Object.keys(territories)) {
        totalTerritories += territories[continent].length;
    }
        
    if(filter.territories && filter.territories.length && filter.territories.length != totalTerritories) {
        let territoriesTitles = [];
        for(let territoryCode of filter.territories) {
            const territory = find(countriesByAbbreviation, country=>country.abbreviation == territoryCode);
            if(territory)
                territoriesTitles.push(territory.country)
        }
        extras.push(`Territories: ${renderLimitedString(territoriesTitles, limit)}`);
    }

    if(filter.vendors && filter.vendors.length && filter.vendors.length != vendors.length)
        extras.push(`Platforms: ${renderLimitedString(filter.vendors, limit)}`);
    
    if(filter.sources && filter.sources.length && filter.sources.length != sources.length )
        extras.push(`Sources: ${renderLimitedString(filter.sources, limit)}`);
    
    if(filter.contentType && filter.contentType.length && filter.contentType.length != contentTypes.length) {
        let contentTypeTitles = [];
        for(let contentTypeID of filter.contentType) {
            const contentType = find(contentTypes, type=>type.value == contentTypeID);
            if(contentType)
                contentTypeTitles.push(contentType.label)
        }
        extras.push(`Content Types: ${renderLimitedString(contentTypeTitles, limit)}`);
    }    
    
    if(filter.genres && filter.genres.length)
        extras.push(`Genres: ${renderLimitedString(filter.genres.split(','), limit)}`);

    
    if(filter.releaseStart || filter.releaseEnd) {
        extras.push(`Release Dates: ${filter.releaseStart || 'All Available'} - ${filter.releaseEnd || 'Today'}`);
    }
    
    if(filter.active) {
        extras.push('Engagement: Active');
    }

    
    return extras;
}



