import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { withRouter } from 'react-router';
import { pdf as PDFRenderer }from '@react-pdf/renderer';
import PDFDocument from '../components/pages/home/PDF';

import Box from '../components/common/Box';
import Grid from '../components/common/Grid/ResponsiveGrid';
import TabContainer from '../components/common/Grid/TabContainer';
//import gridLayout from '../components/common/Grid/homepage';
import gridLayouts from '../components/common/Grid/responsiveHomepage';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import VendorsWidget from '../components/pages/home/VendorsWidget';
import TerritoriesWidget from '../components/pages/home/TerritoriesWidget';
import EntitiesList from '../components/pages/home/EntitiesList';
import SearchWidget from '../components/pages/home/SearchWidget';
import { filterActions } from '../data/actions/filter';
import CSV from '../helpers/CSVExport';

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const lockedSvg = require('!svg-inline-loader!../../public/img/locked.svg');
const pdfSvg = require('!svg-inline-loader!../../public/img/pdf.svg');

class OverviewShared extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    exportToCsv(){
      const {
        playlists,
        imprints,
        artists,
        products,
        tracks,
        stats
      } = this.props;

      const data = [
        { top_playlists: playlists.top},
        { top_imprints: imprints.top},
        { top_artists: artists.top },
        { top_products: products.top },
        { top_tracks: tracks.top },
        { top_platforms: stats.vendor.data },
        { territories: stats.topTerritoryCache }
      ];

      const filename = CSV.CSVHeader('overview', null, this.props.filter.global);
      CSV.CSVBulkExport(data, filename);

    }
    
    //TODO: replicate same function in OverviewShared
    getPdfData() {
        const reportTitle = 'Overview',
            { imprints, artists, products, tracks, playlists, stats } = this.props;
            
        const imageIDs = [
              'home_vendors',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }

        let vendorTableData = [];

        if(this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table

        const tables = {
            imprints: imprints.top,
            artists: artists.top,
            products: products.top,
            tracks: tracks.top,
            playlists: playlists.top,
            territories: stats.topTerritory.table,
            vendorsTable: vendorTableData
        };
        return { reportTitle, images, tables, overview: stats.top };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "overview.pdf";
            link.click();
        });
    }
    
    
    render() {
        const { sharedMode } = this.props;        
        return (
            <div className="homepage overview">
                <div className="page-action-holder">
                    <div className="download-data-holder">
                        <span>Export</span>
                        <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                        <span>PDF</span>
                        <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                    </div>
                </div>
                <CatalogOverview view="home" />
                <div className="">
                    <EntitiesList title="Top Imprints" entity="imprints" externalLinks={false} />
                </div>
                <div className="">
                    <EntitiesList title="Top Artists" entity="artists" externalLinks={false} />
                </div>
                <div className="">
                    <EntitiesList title="Top Products" entity="products" externalLinks={false} />
                </div>
                <div className="">
                    <EntitiesList title="Top Tracks" entity="tracks" externalLinks={false} />
                </div>
                <div className="">
                    <EntitiesList title="Top Playlists" entity="playlists" externalLinks={false} />
                </div>
                <div className="">
                    <TerritoriesWidget />
                </div>

                <div className="">
                    <VendorsWidget shadowChartProps={{id: 'home_vendors', shadowChartWidth: 400, shadowChartHeight: 300}}/>
                </div> 
                <Helmet>
                    <title>Overview</title>
                </Helmet>

            </div>
        )
    }

}


function mapStateToProps(state) {
  const {
    playlists,
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  } = state;

  return {
    playlists,
    imprints,
    artists,
    products,
    projects,
    tracks,
    stats,
    filter
  }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        setFilter: (entity, filter) => {
            return dispatch(filterActions.setEntityFilter(entity, filter));
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewShared))