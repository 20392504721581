export const userFormatter = {
    formatUser
};

function formatUser(data) {
    let canGrantAccess = false,
        clientAdmin = false;
    for (let silo of data.users_silos) {
        if(silo.can_grant_access) {
            canGrantAccess = true;
        }
        if(silo.client_admin) {
            clientAdmin = true;
        }
    }
    data.can_grant_access = canGrantAccess;
    data.client_admin = clientAdmin;
    
    let primarySilo = data.data_silo_codes;
    if(primarySilo.includes(','))
        primarySilo = primarySilo.split(',')[0];
    data.primary_silo_code = primarySilo;
    
    data.allow_api = Boolean(data.api_token);
    data.first_data_date = data.first_data_date || '2014-01-01';
    //
    return data;
}