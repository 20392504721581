import React, { Component, useState } from 'react';
import Navigation from './Navigation';

var userPreferencesSvg = require('!svg-inline-loader!../../../public/img/icons/user_preferences.svg');
var notificationsSvg = require('!svg-inline-loader!../../../public/img/icons/notifications.svg');
var profileSvg = require('!svg-inline-loader!../../../public/img/icons/imprints.svg');
var favoritesSvg = require('!svg-inline-loader!../../../public/img/icons/products.svg');
var adminSvg = require('!svg-inline-loader!../../../public/img/icons/admin.svg');
var statisticsSvg = require('!svg-inline-loader!../../../public/img/icons/statistics.svg');
var whiteLabelSvg = require('!svg-inline-loader!../../../public/img/icons/white_label.svg');
var dataAdminSvg = require('!svg-inline-loader!../../../public/img/icons/data_admin.svg');
var userManagementSvg = require('!svg-inline-loader!../../../public/img/icons/user_management.svg');
var tableSvg = require('!svg-inline-loader!../../../public/img/icons/artists.svg');
var shareSvg = require('!svg-inline-loader!../../../public/img/icons/share.svg');
var socialSvg = require('!svg-inline-loader!../../../public/img/icons/social.svg');
var milestonesSvg = require('!svg-inline-loader!../../../public/img/icons/milestones.svg');

class NavigationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [
        {
          label: "User Preferences",
          route: "/user",
          isSubmenuOpened: false,
          icon: userPreferencesSvg,
          submenu: [
            {
              label: "Profile",
              route: "/user/profile",
              icon: profileSvg,
              isSubmenuOpened: false
            },
            /*
            {
                label: "Templates",
                route: "/user/templates",
                icon: tableSvg,
                isSubmenuOpened: false
            },
            */   
              /*
            {
              label: "Favorites",
              route: "/user/favorites",
              icon: favoritesSvg,
              isSubmenuOpened: false
            },
            */
            {
              label: "Notifications",
              route: "/user/notification-settings",
              icon: notificationsSvg,
              isSubmenuOpened: false,
              //permission: "allow_preferences"
            },
            {
              label: "API",
              route: "/user/api",
              icon: notificationsSvg,
              isSubmenuOpened: false,
              permission: "allow_api"
            },
            {
                label: "Shared Pages",
                route: "/user/shared-pages",
                icon: shareSvg,
                isSubmenuOpened: false,
            },
            {
              label: "Saved Filters",
              route: "/user/entities-filter",
              icon: favoritesSvg,
              isSubmenuOpened: false
            },
			{
				label: "Milestones",
				route: "/milestones",
				icon: milestonesSvg,
				isSubmenuOpened: false
			},			            
          ]
        },
        {
          label: "Admin",
          route: "/admin",
          isSubmenuOpened: false,
          icon: adminSvg,
          permission: ["allow_admin", "can_grant_access"],
          submenu: [
            {
              label: "Statistics",
              route: "/admin/statistics",
              icon: statisticsSvg,
              isSubmenuOpened: false,
              permission: "allow_admin"
            },
            {
              label: "White Label",
              route: "/admin/white-label",
              icon: whiteLabelSvg,
              isSubmenuOpened: false,
              permission: "allow_admin"
            },
            {
              label: "Data Admin",
              route: "/admin/data",
              icon: dataAdminSvg,
              isSubmenuOpened: false,
              permission: "allow_admin"
            },
            {
              label: "User Management",
              route: "/admin/users",
              icon: userManagementSvg,
              isSubmenuOpened: false,
              permission: "can_grant_access"
            },
            /*
            {
                label: "Social Media",
                route: "/admin/social-management",
                icon: socialSvg,
                isSubmenuOpened: false,
                permission: "allow_admin"
              },
              */
            
          ]
        },
      ],
    };
  }

  render () {
    const { menu } = this.state;

    return (
      <Navigation menuItems={menu} />
    )
  }
}

export default NavigationUser;