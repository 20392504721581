import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { find, cloneDeep, get, capitalize, map } from 'lodash';
import store from '../../helpers/store'
import ReactSelect from '../../components/common/ReactSelect';
import SearchWidget from '../../components/pages/home/SearchWidget';
import FilterList from '../../components/widgets/entityFilter/FilterList';
import EntitiesList from '../../components/widgets/entityFilter/EntitiesList';
import SampleCSV from '../../components/widgets/entityFilter/SampleCSV';
import CSVImportModal from '../../components/common/CSVImportModal';
import CSVSampleDownload from '../../components/common/CSVSampleDownload';
import FieldMapper from '../../components/widgets/entityFilter/FieldMapper';
import Spinner from '../../components/common/Spinner';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';

import { searchActions } from '../../data/actions/search';
const allEntityTypes = ['imprints', 'artists', 'products', 'projects', 'tracks'];
const deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const editSvg = require('!svg-inline-loader!../../../public/img/icons/edit.svg');

class SearchDetails extends React.Component {

    constructor(props){
        super(props);
        // const currentFilter = store.get('userSearch') || null;
        this.state = {
            currentFilter: null,
            existingFilter: null,
            searchActive: false,
            entityType: null,
            entitySearchResults: null,
            showFieldMapper: false,
            importTarget: null
        }
        this.setFilterState = this.setFilterState.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);
        this.addToFilter = this.addToFilter.bind(this);
        this.addAllToFilter = this.addAllToFilter.bind(this);
        this.bulkAddToFilter = this.bulkAddToFilter.bind(this);
        this.dummyBulkAddToFilter = this.dummyBulkAddToFilter.bind(this);
        this.addMappedResultsToFilter = this.addMappedResultsToFilter.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.selectFilterByID = this.selectFilterByID.bind(this);
        this.updateFilterField = this.updateFilterField.bind(this);
        this.removeFromFilter = this.removeFromFilter.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.addRecentFilter = this.addRecentFilter.bind(this);
        this.setSearchActive = this.setSearchActive.bind(this);
        this.goToDetails = this.goToDetails.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
    }
    
    componentDidMount(){
        const { match, dispatch } = this.props; 
        const { id } = match.params;
        dispatch(searchActions.getSearchResult(id)).then(result=>{
            this.setState({id, result, showFieldMapper: true});
        })
        
    }
    
    componentWillReceiveProps(nextProps){
        const { match, dispatch } = nextProps; 
        const { id } = match.params;
        const { match: currentMatch } = this.props;
        const currentId = currentMatch.params.id;
        if(currentId && id != currentId) {
            dispatch(searchActions.getSearchResult(id)).then(result=>{
                this.setState({id, result, showFieldMapper: true});
            })                
        }
        
    }
    
    
    getNewFilter() {
        let entities = []
        if(this.props) {
            entities = get(this.props, 'location.state.defaultEntities', [])
        }
        return {caption: 'New Filter', entities};
    }
    
    addNewFilter() {
        const caption = prompt('New Filter Name')
        if(!caption)
            return;
        
        this.props.dispatch(userSearchsActions.createFilter({caption}))
        .then(newFilter => {
            this.setState({
                ...this.state,
                currentFilter: {
                    ...newFilter,
                    entities: [
                       ...this.state.currentFilter.entities
                    ]
                }
            }, ()=>this.saveFilter())
        });
    }
    
    renderRecentFilters() {
        const { currentFilter, recentFilters } = this.state;
        return <FilterList currentFilter={currentFilter} filters={recentFilters} onSelect={this.selectFilterByID} />
    }
    
    renderPinnedFilters() {
        const { currentFilter } = this.state,
            { filters = [] } = this.props.userSearchs;
        const pinnedFilters = filters.filter(filter=>filter.is_pinned);
        return <FilterList currentFilter={currentFilter} filters={pinnedFilters} onSelect={this.selectFilterByID} />        
    }
    
    addRecentFilter(filter) {
        let recentFilters = store.get('recentSearchs') || [];
        const newFilter = {id: filter.id, caption: filter.caption};
        if(!find(recentFilters, filter=>filter.id == newFilter.id)) {
            recentFilters.unshift(newFilter);
            store.set('recentSearchs', recentFilters);
            return new Promise((resolve) => this.setState({recentFilters}, resolve));
        }
        else 
            return Promise.resolve();
    }
    
    setFilterState(currentFilter, callback) {
        store.set('userSearch', currentFilter);
        this.setState({
            ...this.state,
            currentFilter,
            searchActive: false
        }, callback);
    }
    
    selectFilter(currentFilter) {
        this.addRecentFilter(currentFilter)
        .then(()=>{
            this.setFilterState(currentFilter);
        });
    }
    
    selectFilterByID(id) {
        const { filters = [] } = this.props.userSearchs;
        const currentFilter = find(filters, filter=>filter.id == id);
        if(currentFilter)
            this.selectFilter(currentFilter);
    }
    
    addToFilter(items, include) {
        const { id: filterID, entities } = this.state.currentFilter;
        if(!Array.isArray(items))
            items = [items];
        let newEntities = cloneDeep(entities);
        for(let item of items) {
            const newFilterEntity = {
                user_filter_id: filterID,
                entity_id: item.id,
                entity_type: item.entity,
                entity_title: item.name_raw,
                entity_image: item.image,
                fav_hide: include ? 'favorite' : 'hidden'
            };
            newEntities = newEntities.filter(entity=>(!(entity.entity_id==newFilterEntity.entity_id && entity.entity_type==newFilterEntity.entity_type)));
            if(include !== undefined)
                newEntities.push(newFilterEntity);
        }
        this.setFilterState({
            ...this.state.currentFilter,
            entities: newEntities 
        });
    }
    
    addAllToFilter(items, include) {
        let newItems = [];
        for(let key of Object.keys(items)) {
            if(Array.isArray(items[key])) {
                newItems = newItems.concat(items[key])
            }
        }
        this.addToFilter(newItems, include);
    }
    
    bulkAddToFilter(fields) {
        if(!fields.length)
            return;
        
        let { target, source } = fields[0];
        if(source == 'ISRC')
            source = 'tracks';        
        if(source == 'UPC')
            source = 'products';
        if(source == 'Project')
            source = 'projects';
        
        
        const searchString = map(fields, 'field').join('\n');
        this.props.dispatch(searchActions.getSearchImport(searchString, [source]))
        .then(results=>{
            this.setState({
                entitySearchResults: results,
                showFieldMapper: true,
                importTarget: target
            })
        });
    }
    
    dummyBulkAddToFilter() {
        const fields = [{
            'target': 'include',
            'source': 'artists',
            'field': 'Mitski'
        }, {
            'target': 'include',
            'source': 'artists',
            'field': 'Marco'            
        }];
        let { target, source } = fields[0];
        const searchString = map(fields, 'field').join('\n');
        this.props.dispatch(searchActions.getSearchImport(searchString, [source]))
        .then(results=>{
            this.setState({
                entitySearchResults: results,
                showFieldMapper: true,
                importTarget: target
            })
        });
    }
    
    addMappedResultsToFilter(results) {
        if( results.length ) { 
            const { importTarget } = this.state;
            const filteredResults = results.filter(result=>(result.id !== null));
            this.addToFilter(filteredResults, (importTarget == 'include'));
        }
        this.setState({
            entitySearchResults: null,
            showFieldMapper: false,
            importTarget: null
        })
    }
    
    removeFromFilter(id, type) {
        const { entities } = this.state.currentFilter;
        let newEntities = entities.filter(entity=>(!(entity.entity_id==id && entity.entity_type==type)));
        this.setFilterState({
            ...this.state.currentFilter,
            entities: newEntities 
        });
    }
    
    updateFilterField(field, value) {
        const { id } = this.state.currentFilter;
        this.setFilterState({
            ...this.state.currentFilter,
            [field]:value 
        }, ()=>{
            this.props.dispatch(userSearchsActions.updateUserSearch(id, {[field]:value}))    
        })
        
    }
    
    updateTitle() {
        const caption = prompt('Change Filter Name')
        if (!caption)
            return;

        this.updateFilterField('caption', caption)
    }
    
    saveFilter() {
        const { currentFilter, existingFilter } = this.state;
        const entitiesToAdd = currentFilter.entities.filter(entity=>!entity.id); 
        const entitiesToDelete = existingFilter.entities.filter(existingEntity => !find(currentFilter.entities, entity => entity.id == existingEntity.id));
        this.props.dispatch(userSearchsActions.updateFilterEntities(currentFilter.id, entitiesToAdd, entitiesToDelete))
        //.then(currentFilter=>this.setFilterState(currentFilter));
        .then(this.props.history.push('/user/entities-filter'));
    }
    
    deleteFilter() {
        const { id } = this.state.currentFilter;
        this.props.dispatch(userSearchsActions.destroyFilter(id))
        .then(this.setFilterState(null));
    }
    
    setSearchActive(searchActive) {
        this.setState({searchActive});
    }
    
    goToDetails() {
        const { entities } = this.state.currentFilter;
        let filter = {};
        for(let entity of entities){
            const { fav_hide, entity_type, entity_id } = entity;
            if(fav_hide == 'favorite') {
                if(!filter.hasOwnProperty(entity_type))
                    filter[entity_type] = [];
                filter[entity_type].push(entity_id);
            }
        }
        this.props.history.push(`/filter/${JSON.stringify(filter)}`);
    }
    
    resetFilter(){
        this.setFilterState(null, ()=>{
            this.props.applyFilter();
        })
    }
    
    renderFilterSelect() {
        const { currentFilter } = this.state,
            { loading, filters = [] } = this.props.userSearchs,
            filterOptions = filters.map(filter=>({label: filter.caption, value: filter})),
            selectedFilter = currentFilter ? find(filterOptions, option=>option.value.id == currentFilter.id) : null;
            
        return <ReactSelect
            loading
            value={selectedFilter}
            options={filterOptions}
            onChange={({value})=>this.selectFilter(value)}
            isLoading={loading}
            isSearchable={true}
            onFocus={()=>this.setSearchActive(true)}
        />
    }
    
    renderFilterEntities(entities, entityType) {
        if(!entities)
            return null;
        
        const entitiesInFilter = entities.length;
        const entityTypes = entityType ? [entityType] : allEntityTypes;
        return <div className="pinned-filters-holder filter-page">
            {entitiesInFilter == 0 && <p className="select-entity-text">Select entity from the search bar on the left to include or exclude it.</p>}
            {entitiesInFilter > 0 && ['favorite', 'hidden'].map(include=><div className="pinned-filters-item">
                <h4 className="pinned-filter-title inclusions">{include == "favorite" ? "Inclusions" : "Exclusions"}</h4>
                <div className="pinned-entity-list">
                    {entityTypes.map(type=><EntitiesList entities={entities} type={type} fav_hide={include} onDelete={this.removeFromFilter} />)}
                </div>
            </div>)}
        </div>
    }

    renderEntityTypes(selectedEntityType) {
        let options = [{value: null, label: 'All'}];
        for(let entityType of allEntityTypes) {
            options.push({value: entityType, label: capitalize(entityType)})
        }
        return <ul className="entity-filter-tabs">
            {options.map(option=><li key={option.value} className={`entity-filter-tab ${option.value == selectedEntityType ? 'active':''}`} onClick={()=>this.setEntityType(option.value)}>{option.label}</li>)}
        </ul>
    }            
            
    setEntityType(entityType) {
        this.setState({entityType});
    }
    
    render() {
        const { searchResultLoading }  = this.props.search;
        const { result, showFieldMapper } = this.state;
        return (
             <div className="user-entity-filter-holder create-filter">
                 <FieldMapper results={result} show={showFieldMapper} mapResults={this.addMappedResultsToFilter}/>}
            </div>   
        )
    }
}

function mapStateToProps(state) {
    return {
        search: state.search
    } 
}
export default connect(mapStateToProps)(withRouter(SearchDetails));