import React from 'react';
import { find, orderBy, capitalize } from 'lodash';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import AbsoluteValue from '../AbsoluteValue';

class GenderSplitBox extends React.Component {
    genders = [ 'curr_male', 'curr_female', 'curr_unknown' ]; 
    render() {
        let { item } = this.props,
            total = 0,
            percentages = [];
        for(let gender of this.genders) {
            total += item[gender];
        }
        
        for(let gender of this.genders) {
            percentages.push(<span key={gender} title={capitalize(gender)} className={`gender-percent gender-percent-${gender}`}>{Math.round(item[gender] / total * 100)}%</span>);
        }
        
        return total ? <div className="genders-box">
            {percentages}
        </div> : null;
    }

}

export default GenderSplitBox