import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import EntityWidget from './EntityWidget';
import BarChart from '../../widgets/charts/types/BarChart';
import LineChart from '../../widgets/charts/types/LineChart';
import { artistsActions } from '../../../data/actions/artists';
import icons from '../../../helpers/Icons';
import VendorLogo from '../../common/VendorLogo';

var chartSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');

class Entity extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            details: false,
            detailsMode: 'bar'
        };
        
        this.toggleExpand = this.toggleExpand.bind(this);
        this.goToDetails = this.goToDetails.bind(this);
        
    }
    toggleExpand() {
        if(!this.state.details)
            this.props.getDetails(this.props.item.id);
        this.setState({
            details: true
        });
        this.props.expand();
    }
    
    setChartMode(mode){
        this.setState({detailsMode:mode});
    }
    
    goToDetails(){
        const { history, entity, item } = this.props;
        let route = `/${entity}/${item.id}`;
        if(entity == 'playlists')
            route += '/details';
        history.push(route);
    }
    
    renderToolbar(modes){
        let toolbar = [];
        // for (let mode in modes) {
        //     toolbar.push(<a key={mode}><i className={`fa fa-${modes[mode]} ${this.state.detailsMode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode(mode)}></i></a>);                
        // }
        const { entity } = this.props, 
            title = entity.slice(0, 1).toUpperCase() + entity.slice(1, entity.length-1) + ' Details';
        
        //if(entity !== 'imprints') {
            toolbar.push(<a key='details' title={title} className={`chart-icon`} 
                onClick={this.goToDetails}
                dangerouslySetInnerHTML={{__html: icons.entities[entity]}}>
            </a>);
        //}
        toolbar.push(<a key='bar' title="Bar Chart" className={`chart-icon ${this.state.detailsMode == 'bar' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('bar')}
                dangerouslySetInnerHTML={{__html: chartSvg}}>
            </a>);
        toolbar.push(<a key='line' title="Line Chart" className={`chart-icon ${this.state.detailsMode == 'line' ? 'active' : ''}`} 
                onClick={()=>this.setChartMode('line')}
                dangerouslySetInnerHTML={{__html: lineSvg}}>
            </a>);
        return <div>{toolbar}</div>;
    }
    
    render (){
        const modes = {
            'bar': 'chart-bar',
            'line': 'chart-line'
        };    
        const { entity, item } = this.props; 
        return <div className="entity-item-container">
            <div className={`flex-block entity-item ${this.props.expanded?'opened':''}`}>
                    <EntityWidget entity={this.props.entity} item={this.props.item} toggleExpand={this.goToDetails} />
                    {entity == 'playlists' && item.vendor !== undefined && <div className="top-vendor-logo-holder">
                        <VendorLogo name={item.vendor} />
                    </div> }
                    {this.props.expanded && this.props.details && <div className="single entity-widget-bar">
                        <div className="title-block entity-widget-action">
                            <div className="pull-left ibox-tools">
                                {this.renderToolbar(modes)}
                            </div>
                            <div className="pull-right">
                                {/*<i className="fas fa-arrow-circle-left" onClick={this.toggleExpand} />*/}
                                <span className="widget-date">{this.props.dateRange}</span>
                            </div>
                        </div>
                        {this.state.detailsMode=='bar' && <BarChart data={this.props.details} />}
                        {this.state.detailsMode=='line' && <LineChart data={this.props.details} />}
                    </div>}
            </div>    
        </div>;
    }
}

export default withRouter(Entity)