import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import store from '../../../helpers/store'
import ReactSelect from '../../common/ReactSelect';
import ConfirmClick from '../../common/ConfirmClick';
import SearchWidget from '../../pages/home/SearchWidget';
import FilterList from './FilterList';

import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters'
import { userEntityFiltersFormatter } from '../../../data/formatters/user_entity_filters'

const imprintsSvg = require('!svg-inline-loader!../../../../public/img/icons/imprints.svg');
const artistsSvg = require('!svg-inline-loader!../../../../public/img/icons/artists.svg');
const productsSvg = require('!svg-inline-loader!../../../../public/img/icons/products.svg');
const tracksSvg = require('!svg-inline-loader!../../../../public/img/icons/tracks.svg');
const deleteSvg = require('!svg-inline-loader!../../../../public/img/icons/delete.svg');
const filterSvg = require('!svg-inline-loader!../../../../public/img/saved-filter-icon.svg');
const editSvg = require('!svg-inline-loader!../../../../public/img/edit-filter.svg');

class PinnedEntityFilters extends React.Component {
    pageFilterObjects = [
        { type: "imprints", icon: imprintsSvg },
        { type: "products", icon: productsSvg },    
        { type: "artists", icon: artistsSvg },
        { type: "tracks", icon: tracksSvg },
      ];

    constructor(props){
        super(props);
        const currentFilter = store.get('userEntityFilter') || null;

        this.state = {
            currentFilter,
        }

        this.goToDetails = this.goToDetails.bind(this);
        this.clearRecentFilters = this.clearRecentFilters.bind(this);
        this.removeRecentFilter = this.removeRecentFilter.bind(this); 
        this.editFilter = this.editFilter.bind(this);
    }
    
    componentDidMount(){
        const { dispatch, source } = this.props;
        if(source == 'pinned')
            dispatch(userEntityFiltersActions.getUserEntityFilters());
        if(source == 'recent')
            dispatch(userEntityFiltersActions.getRecentEntityFilters());
    }
    
    clearRecentFilters(){
        const { dispatch } = this.props;
        dispatch(userEntityFiltersActions.clearRecentEntityFilters());
    }
    
    removeRecentFilter(e, id) {
        e.stopPropagation();
        this.props.dispatch(userEntityFiltersActions.destroyFilter(id)).then(()=>this.props.dispatch(userEntityFiltersActions.getRecentEntityFilters()));        
    }
    
    editFilter(e, id) {
        e.stopPropagation();
        this.props.history.push(`/user/entities-filter/${id}`);        
    }

    
    renderPinnedFilters() {
        const { currentFilter } = this.state,
            { filters = [] } = this.props.userEntityFilters;
        const pinnedFilters = filters.filter(filter=>filter.is_pinned);
        return <FilterList actionButton={this.editFilter} actionButtonClass={"pinned"} currentFilter={currentFilter} filters={pinnedFilters} onSelect={this.goToDetails} />        
    }

    renderRecentFilters() {
        const { currentFilter } = this.state,
            { recentFilters = [] } = this.props.userEntityFilters;
        return <FilterList actionButton={this.removeRecentFilter} currentFilter={currentFilter} filters={recentFilters} onSelect={this.goToDetails} actionButtonClass={"recent"} />        
    }
    
    goToDetails(filter) {
        const filterString = userEntityFiltersFormatter.stringifyFilter(filter); 
        this.props.history.push(`/filter/${filterString}/overview`);
    }   

    render() {
        const { currentFilter: filter , searchActive }  = this.state;
        const { applyFilter, renderMode, source = 'pinned', userEntityFilters: { recentFilters = [] }  } = this.props;
        const title = source == 'recent' ? 'Recent' : 'Saved';
        return (
            <div className={`search-filter-item search-filter-${renderMode}`}>
                <h2 className="search-title">
                    <span className="search-title-text">{title}</span>
                    {source == 'pinned' && <Link to="/user/entities-filter" className="edit-saved">View Saved</Link>}
                    
                    {(source == 'recent' && recentFilters.length > 0) && <ConfirmClick confirmClass="edit-saved" confirmAction={this.clearRecentFilters} title="Clear Recent" confirmLabel="Clear Recent" />}
                </h2>
                {source == 'pinned' && this.renderPinnedFilters()}
                {source == 'recent' && this.renderRecentFilters()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters
    } 
}
export default connect(mapStateToProps)(withRouter(PinnedEntityFilters));