import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, debounce } from 'lodash';

import OverviewWidget from './OverviewWidget';
import TrendsWidget from './TrendsWidget';
import { statsActions } from '../../../data/actions/stats';
import { filterActions } from '../../../data/actions/filter';
import ScrollButtons from '../../common/ScrollButtons';
import Spinner from '../../common/Spinner';
import MatchMediaWrapper from '../../../helpers/MatchMediaWrapper';

const scrollRef = React.createRef();

class CatalogOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resizeObserver: undefined,
            tableContainer: undefined,
            hasHorizontalScroll: false,
            scrollLeft: 0,
            isMounted: false,
        };
        this.resizeObserverFunction = this.resizeObserverFunction.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    resizeObserverFunction() {
        const tableContainer = document.getElementsByClassName('overview-tiles-holder')[0];
        this.setState({
            tableContainer
        });

        if (window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                this.setState({
                    hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
                });
            });
            this.setState({
                resizeObserver
            });
        
            resizeObserver.observe(tableContainer);
        } else {
            // console.log("Resize observer not supported!");
            this.setState({
                hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
            });
        }
    }

    getTilesBoundary() {
        const tile = document.getElementsByClassName("overview-card");
        const tileWidth = tile && tile[0] && tile[0].offsetWidth;

        return tileWidth;
    }

    componentDidMount() {
        this.props.getStats();
        this.resizeObserverFunction();
        this.setState({
            isMounted: true
        })
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStats(false);
            }                
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.stats.top !== prevProps.stats.top) {
            this.resizeObserverFunction();
            this.getTilesBoundary();
        }
    }

    handleScroll() {
        if (this.state.isMounted) {
            this.setState({
                scrollLeft: scrollRef.current.scrollLeft
            })
        }
    }

    componentWillUnmount() {
        const tableContainer = document.getElementsByClassName('overview-tiles-holder')[0];

        this.state.resizeObserver && this.state.resizeObserver.unobserve(tableContainer)
    }

    render (){
        const { stats } = this.props;
        const statsKey = this.props.view == 'home' ? 'top' : 'catalog';
        const loadingKey = `${statsKey}Loading`; 
        return <div className={`catalog-overview ${this.props.view == 'home' ? 'home home-main' : ''} ${this.props.newClass}`}>
            {this.state && this.state.hasHorizontalScroll && <ScrollButtons scrollStepValue={this.getTilesBoundary()} el={this.state.tableContainer} scrollPosition={this.state.scrollLeft} />}
            <div className="overview-tiles-holder" ref={scrollRef} onScroll={this.handleScroll}>
                {/* <div className="title-container">
                    <h2 className="big-title">{this.props.view == 'home' ? '' : 'Catalogue Overview'}</h2>
                </div> */}
                <div className="overview-tiles-holder-inner">
                {stats[loadingKey] && <Spinner enabled={true} className="spinner spinner-inline" />}
                {/* <MatchMediaWrapper breakpoint={959} isMobile={true}>
                    <h2 className="ibox-title__title tiles-mobile-title">
                        Overview
                    </h2>
                </MatchMediaWrapper> */}
                    {stats[statsKey] && stats[statsKey].map(entity=>{
                        return <div key={entity.entity} className="overview-tiles-item">
                            { this.props.view == 'home' ? <TrendsWidget entity={entity} /> : <OverviewWidget entity={entity} /> }
                        </div>
                    })} 
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        getStats: (cache=true)=> {
            switch(ownProps.view) {
                case 'catalog':
                    dispatch(statsActions.getCatalogStats(cache))
                    break;
                case 'home':
                    dispatch(statsActions.getTopStats(cache, ownProps.filtered))
                    break;                    
            }
        },
        toggleFilter: ()=>dispatch(filterActions.toggleGlobalFilter()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CatalogOverview))