import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { find } from 'lodash';
import { Route, withRouter } from 'react-router';
import SearchWidget from '../../components/pages/home/SearchWidget';
import TrackSelector from '../../components/pages/trackComparison/TrackSelector';
import TrackComparisonDetails from './Details';
import Tooltip from '../../components/common/Tooltip';
import Filter from '../../components/pages/trackComparison/Filter';
import ReactSelect from '../../components/common/ReactSelect';
import { projectsActions } from '../../data/actions/projects';
import { productsActions } from '../../data/actions/products';
import { tracksActions } from '../../data/actions/tracks';

class TrackComparisonSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: '',
            tracks: [{}, {}],
            weeks: 1,
            territories: '',
            vendors: ''
        };
        this.compare = this.compare.bind(this);
        this.clear = this.clear.bind(this);
        this.comparisonReady = this.comparisonReady.bind(this); 
        this.setTrack = this.setTrack.bind(this);
        this.setWeek = this.setWeek.bind(this);
        this.filterChange = this.filterChange.bind(this);
    }
    
    componentDidMount() {
        const { match, dispatch } = this.props;
        if(match.params.ids) {
            this.props.getReleaseDates(match.params.ids)
            .then(tracks => {
                this.setState({
                    entity: match.params.entity,
                    tracks,
                    weeks: match.params.weeks
                });
            });
        }
        
    }
    
    componentWillReceiveProps(nextProps) {
        /*
        if(this.props.match.params.entity && this.props.match.params.entity!=nextProps.match.params.entity) {
            this.setState({
                tracks: [{}, {}]
            })
        }
        */
    }
    
    clear() {
        this.setState({
            entity: '',
            tracks: [{}, {}],
            weeks: 1
        }, ()=>{this.props.history.push(`/comparison/`)});
    }
    
    compare() {
        const { tracks, weeks, entity } = this.state;
        // const { entity = 'tracks' } = this.props.match.params;
        if(!this.comparisonReady())
            return;
        const codes = tracks.reduce((ids, track, index, tracks)=>ids += track.code + (index < tracks.length - 1 ? ',' : ''), '') 

        this.props.history.push(`/comparison/${entity}/${codes}/${weeks}`);
    }
    
    filterChange(filter) {
        let { territories, vendors } = filter;
        this.setState({
            territories: territories.join(','),
            vendors: vendors.join(',')
        });
    }
    
    setTrack(index, newTrack) {
        const { user } = this.props;
        const { entity } = this.state;
        //const { entity } = this.props.match.params;
        // const entityTitle = entity.replace(/s$/, '');
        
        let tracks = this.state.tracks.map(track => ({...track}));
        let limitedEntity = '';
        
        if(newTrack === undefined) {
            tracks[index] = {};
            for(let track of tracks) {
                if(track.entity !== undefined)
                    limitedEntity = track.entity;
            }
        }
        else {
            if(new Date(newTrack.first_released) < new Date(user.user.first_data_date)) {
                return alert(`No data available. This release pre-dates your earliest collection with Ei.`);
            }
            
            if(entity && entity != newTrack.entity) {
                return alert(`Please select two entities of the same type`);
            }
            
            for(let track of tracks) {
                if(track.code == newTrack.code)
                    return alert(`This entity is already selected`);
            }
            tracks[index] = newTrack;
            limitedEntity = newTrack.entity;
        }
        this.setState({
            tracks,
            entity: limitedEntity
        })
    }

    setWeek(week) {
        this.setState({
            weeks: week.value
        })
    }
    
    comparisonReady() {
        const { tracks } = this.state;
        return tracks.reduce((value, track)=> (value = track.code !== undefined), true);
    }
    
    render() {
        const { sharedMode = false } = this.props;
        
        const { entity = 'tracks' } = this.state;
        const entityTitle =  'Entity'; //entity == 'tracks' ? 'Track' : 'Product';
        
        const { tracks, weeks, territories, vendors} = this.state;
        const monthBreakpoint = 8;
        const weeksOptions = [...Array(24).keys()].map( i => {
            
            i=i+1; 
            let value = i;
            if(i > monthBreakpoint)
                value = monthBreakpoint + ( i - monthBreakpoint ) * 4;
                
            return {label: value, value: value}
            }),
            weeksValue = find(weeksOptions, week=>week.value == weeks),
            comparisonReady = this.comparisonReady(), 
            compareButtonClass = comparisonReady ? 'btn-active' : 'disabled'; 
        
        return <div className="inner-page track-comparison">
            <h2 className="audience">
                <span>{entityTitle} Comparison</span>
                {/* <div className="toolbar-title-tooltip">
                    <Tooltip position="bottom" message={`Todo`} tooltipClass="toolbar-title-tooltip top-sections-tooltip"/>
                </div> */}
            </h2>
            <div className="ibox-content section-header track-selector">
                <div className="comparison-search-holder">
                    <div className="comparison-tracks-holder">
                        <div className="comparison-tracks-item">
                            <span>{entityTitle} 1:</span>
                            <TrackSelector entity={entity} index={0} value={tracks[0]} readonly={sharedMode} setResult={(track)=>this.setTrack(0, track)} clearResult={()=>this.setTrack(0, undefined)} /> 
                        </div>
                        <div className="comparison-tracks-item">
                            <span>{entityTitle} 2:</span>
                            <TrackSelector entity={entity} index={1} value={tracks[1]} readonly={sharedMode} setResult={(track)=>this.setTrack(1, track)} clearResult={()=>this.setTrack(1, undefined)} />
                        </div>
                        <div className="weeks-item">
                            <div className="weeks-select">
                                <span className="weeks-select-title">Weeks:</span>
                                <ReactSelect options={weeksOptions} isDisabled={sharedMode} onChange={this.setWeek} value={weeksValue} />  
                            </div>
                            {!sharedMode && <div className="comparison-actions">
                                <button onClick={this.compare} className={`btn ${compareButtonClass} btn-compare default-btn default-btn--small`}>Compare</button>
                                <button onClick={this.clear} className={`default-btn default-btn--small default-btn--light`}>Reset</button>
                            </div>}
                        </div>
                    </div>
                    
                    
                </div>
                {!sharedMode && <Filter onChange={this.filterChange} />}
            </div>
            <Route path="/comparison/:entity/:ids/:weeks" render={(props)=><TrackComparisonDetails territories={territories} vendors={vendors}  {...props} />}></Route>
            <Route path="/share/comparison/:entity/:ids/:weeks" render={(props)=><TrackComparisonDetails territories={territories} vendors={vendors} sharedMode={true} {...props} />}></Route>
            <Helmet>
                <title>{`${entityTitle} Comparison`}</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        getReleaseDates: (ids) => {
            switch(ownProps.match.params.entity) {
                case 'projects':
                    return dispatch(projectsActions.getProjectsReleaseDates(ids))
                    break;
                
                case 'products':
                    return dispatch(productsActions.getProductsReleaseDates(ids))
                    break;
                default:
                    return dispatch(tracksActions.getTracksReleaseDates(ids))
                    break;

            }
        }
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackComparisonSearch))