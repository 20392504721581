import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import DateRange from '../../common/PDF/DateRange';
import ChartLegendPDF from '../../widgets/charts/ChartLegendPDF';
import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';

const renderMilestones=(data)=>{
    const columns=[
       {key: 'linked', title: 'Item', width: 40},
       {key: 'milestone_type', title: 'Type', width: 20},
       {key: 'reference', title: 'Reference', width: 20},
       {key: 'occurs_at', title: 'Occurs At', width: 15},
       {key: 'ends_at', title: 'Ends At', width: 15},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}

const renderPlaylists=(data)=>{
    const columns=[
       {key: 'rank', title: 'Rank', width: 10},
       {key: 'playlist_name', title: 'Playlist', width: 40},
       {key: 'listeners', title: 'Listeners', width: 25, formatFn: splitByThousands},
       {key: 'total_streams', title: 'Streams', width: 25, formatFn: splitByThousands},
       {key: 'ratio', title: 'Ratio', width: 12, formatFn: addPercent},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}

const renderSources=(data)=>{
  const columns=[
     {key: 'source', title: 'Source', width: 40},
     {key: 'listeners', title: 'Listeners', width: 40, formatFn: splitByThousands},
     {key: 'share', title: 'Share', width: 20, formatFn: addPercent},
 ];
     
  return <Table
      columns={columns}
      items={data}
  />
}

const renderDemographics=(data)=>{
  const columns=[
    {key: 'age', title: 'Age Group', width: 25, fontSize: 5},
    {key: 'male', title: 'Male', width: 25, fontSize: 5, formatFn: splitByThousands},
    {key: 'female', title: 'Female', width: 25, fontSize: 5, formatFn: splitByThousands},
    {key: 'unknown', title: 'Unknown', width: 25, fontSize: 5, formatFn: splitByThousands},
  ];
     
  return <Table
      columns={columns}
      items={data}
  />
}

const renderTerritories=(data)=>{
    const columns=[
       {key: 'rank', title: 'Rank', width: 20},
       {key: 'name', title: 'Country', width: 60},
       {key: 'share', title: 'Share', width: 20, formatFn: addPercent},
       {key: 'listeners', title: 'Listeners', width: 20, formatFn: splitByThousands},
       {key: 'total_streams', title: 'Streams', width: 20, formatFn: splitByThousands},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}

const renderLegend = (dataset)=>{
    let labels = [],
        colors = [];
    if(!dataset || !dataset.length)
        return null;
    
    for(let index = 3; index>=0; index--) {
        for(let datasetIndex = 0; datasetIndex<4; datasetIndex++) {
            const row = dataset[datasetIndex];
            if(row.data[index]) {
                labels.push(row.labels[index]);
                colors.push(row.backgroundColor[index]);
            }
        }
    }
    return <ChartLegendPDF labels={labels} colors={colors} />
}


export default (props) => {
    const { data: { images, tables, metadata, repeatListenersDataset, reportTitle, globalFilter, entity } } = props;
  return <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.columnReportTitle}>
        <Text style={styles.header}>{reportTitle}</Text>
        <Text style={styles.smallHeader}>{entity}</Text>
      </View>
      <DateRange filter={globalFilter} />
      <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Streams and Unique Listeners</Text>
          <Image source={images.audience_streams_line_chart} />
        </View>
      </View>
      <View style={styles.columnSection}>
        <View style={styles.columnWithMargin}>
          <Text style={styles.smallHeader}>Age and Gender</Text>
          <Image source={images.audience_demographics_bar_chart} />
        </View>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Age and Gender</Text>
          {renderDemographics(tables.demographics)}
        </View>
      </View>
    </Page>

    <Page size="A4"  wrap={false} style={styles.page}>
      <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Repeat Listeners</Text>
          <Image source={images.audience_repeat_listeners_line_chart} />
          {renderLegend(repeatListenersDataset)}
        </View>      
      </View>
      <View style={styles.section}>
        <View style={styles.half}>
          <View style={styles.columnSection}>
              <View style={styles.columnWithMargin}>
                <Text style={styles.smallHeader}>Sources</Text>
                <Image source={images.audience_sources_pie_chart} />
              </View>
              <View style={styles.column}>
                <Text style={styles.smallHeader}>Sources</Text>
                {renderSources(tables.sources)}
              </View>
          </View>
        </View>
        <View style={styles.half}>
            <Text style={styles.smallHeader}>Playlists</Text>
            {renderPlaylists(tables.playlists)}
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Territories</Text>
          {renderTerritories(tables.territories)}
        </View>
      </View>
    </Page>
    {/*

    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Milestones</Text>
          {renderMilestones(tables.milestones)}
        </View>
      </View>
    </Page>
    
    */}
  </Document>
};