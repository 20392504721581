import React from 'react';
import Tour from '../../common/Tour';

class SearchTour extends React.Component {
    render(){
        const steps = [
           {
               selector: '.search-result-list',
               content: () => <div ref={this.props.tourRef} >
                   Hover or tap any results to continue. Click the Name or Title to go to insights.
                   <br/>
                   Searching for multiple entities? Hover over your search result, then click the green + to add, or red - to exclude entities from your group. Alternatively, add many at once by copying and pasting a group of ISRCs or UPCs, separated by commas to the search bar.
               </div>
           }
        ];
        
        return <Tour steps={steps} tourID="search_tour" />
    }
}

export default SearchTour;