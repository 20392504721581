import { map, uniq, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';

export const mailingListsFormatter = {
    formatMailingLists,
    formatMailingList,
    formatMailingListRecipients,
    formatMailingListsByRecipient,
    formatCalendar
};

function formatMailingLists(data, filters){
    return data.data;
}

function formatMailingList(data){    
    return data.data;
}

function formatMailingListRecipients(data){
    if(!data)
        return [];
    let options = [];
    for(let item of data.data) {
        options.push({
            label: item.email, 
            value: item.email,
            ...item
        });
    }
    return options;
}

function formatMailingListsByRecipient(data) {
    return data.data;
}

function formatCalendar(calendar) {
    let value;
    switch(calendar.period) {
        case 'daily':
        case 'monthly_day':
            value = calendar.dayOfMonth;
            break;
        case 'weekly':
        case 'bi_weekly':
            value = calendar.dayOfWeek;
            break;
            
        case 'monthly_day_of_week':
            value = `${calendar.dayOfWeek}_${calendar.weekOfMonth}`;
            break;
    }
    return value;
}