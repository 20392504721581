//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';

export const catalogueExceptionsService = {
    getCatalogueExceptions,
    getCatalogueExceptionsCount,
    updateCatalogueExceptions
};

function getCatalogueExceptions(payload) {
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };
    
    let baseUrl = `${config.apis.miniCat}/v3/catalogue_exceptions?per_page=${payload.per_page}&page=${payload.page}`;  
    return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getCatalogueExceptionsCount() {
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };
    
    let baseUrl = `${config.apis.miniCat}/v3/catalogue_exceptions/count_tracks`;  
    return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function updateCatalogueExceptions(arr, cb) {
    const baseUrl = `${config.apis.miniCat}/v3/catalogue_exceptions/bulk_update`,

        requestOptions = {
            method: 'PATCH',
            headers: AuthHeader({'Content-Type': 'application/json'}),            
            body: JSON.stringify(arr)
        };        
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        if (res.ok) {
            res.json()
            if (cb && typeof cb === 'function') {
                cb()
            }
        } else {
          Promise.reject(res) 
        }
    })
    .catch(ex=>Promise.reject(ex))
}


