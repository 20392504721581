import React from 'react';
import { connect } from 'react-redux'; 
import { isEqual, cloneDeep, uniq, map } from 'lodash';
import { LineChart, BarChart, StackedBarChart } from '../../../widgets/charts/types'
import MilestoneLegendItem from '../../../pages/audience/MilestoneLegendItem';
import { addMilestoneDataset } from '../../../../helpers/MilestoneFormatter';
import { addMissedDataDataset } from '../../../../helpers/MissedDataFormatter';
import { filterActions } from '../../../../data/actions/filter';

class ChartWithAnnotations extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        period: this.props.period,
        dateGroup: this.props.dateGroup,
        xCoord: 0,
        direction: 'left',
    };

    this.ref = React.createRef();

    this.toggleMilestone = this.toggleMilestone.bind(this);
    this.showMilestone = this.showMilestone.bind(this);
    this.getDateGroup = this.getDateGroup.bind(this);
  }

  componentDidMount() {
    // this.props.getStats();
}

  componentWillReceiveProps(nextProps){
      if(nextProps.filter && nextProps.filter.global){
          if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
              this.props.getStats(false);
          }                
      }
  }
    
  toggleMilestone(currentMilestone, period, forceOff = false) {
    let previousMilestone = Object.assign([], this.state.currentMilestone);

    if (isEqual(currentMilestone, this.state.previousMilestone) || forceOff) {
        previousMilestone = null; //Object.assign([], this.state.previousMilestone);
        currentMilestone = null;
    }
    else if (isEqual(currentMilestone, this.state.currentMilestone)) {
      currentMilestone = null;
      previousMilestone = null;
    } 
    else if (isEqual(previousMilestone, this.state.currentMilestone)) {
      previousMilestone = null;
    }

    const length = this.props.data.labels.length;
    var i = 0;

    this.props.data.labels.forEach(function(el, index) {
      if (el == period) {
        i = index
      }
  })

  const chartWidth = this.ref && this.ref.current.clientWidth;
  const innerChartWidth = parseInt(chartWidth - 110);

  var coord = 0;
  var dir = 'left';

  if (i === 0) {
    coord = 0;
  } else if (i === (length - 1)) {
    coord = 0;
    return dir = 'right';
  } else {
    coord = parseInt(innerChartWidth/(length - 1)*i)
  }

  this.setState({
    currentMilestone, 
    previousMilestone, 
    period,
    xCoord: coord,
    direction: dir
  });

  }

  showMilestone(currentMilestone, period) {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      let previousMilestone = Object.assign([], this.state.currentMilestone);
  
      if (isEqual(previousMilestone, this.state.currentMilestone) && !isEqual(period, this.state.period)) {
        previousMilestone = null;
        currentMilestone
      }

      const length = this.props.data.labels.length;
      var i = 0;
  
      this.props.data.labels.forEach(function(el, index) {
          if (el == period) {
            i = index
          }
      })
  
      const chartWidth = this.ref && this.ref.current.clientWidth;
      const innerChartWidth = parseInt(chartWidth - 110);

      var coord = 0;
      var dir = 'left';

      if (i === 0) {
        coord = 0;
      } else if (i === (length - 1)) {
        coord = 0;
        dir = 'right'
      } else {
        coord = parseInt(innerChartWidth/(length - 1)*i)
      }
      
      this.setState({
        currentMilestone, 
        previousMilestone, 
        period,
        xCoord: coord,
        direction: dir
      });

    }
  }

  getDateGroup() {
    let { dateGroup } = this.state;
    switch(dateGroup) {
      case 'chwk':
        return 'Chart Week'
      case 'clwk':
        return 'Calendar Week'
      case 'mth':
        return 'Month';
      default: 
        return 'Day';
    }
  }

  render() {
    let { milestones, labels, datasets, annotations, data, shadowChartProps, chartType } = this.props,
    legendPosition = 'bottom';
    if(!data)
        return null;
    
    let ChartComponent;
    switch(chartType){
        case 'line':
            ChartComponent = LineChart;
            break;
        case 'bar': 
            ChartComponent = BarChart;
            break;
        case 'stacked':
            ChartComponent = StackedBarChart;
            break;
    }

    const { logs, logsLoading, sharedMode = false } = this.props.filter;
    
    var dataCopy = [];

    if (data && data !== undefined && data.labels && data.datasets) {
      dataCopy = cloneDeep(data)
      labels = dataCopy.labels;
      datasets = dataCopy.datasets;
      annotations = addMilestoneDataset(dataCopy.datasets, dataCopy.labels, milestones, this.toggleMilestone, this.getDateGroup(), this.showMilestone, this.leaveMilestone, this.props.filter.global);

      if (chartType == 'line') {
		  const logsDataReady = !logsLoading && Array.isArray(logs) && logs.length;
        if (logsDataReady && !sharedMode) {
          var missedDataArray = addMissedDataDataset(logs, dataCopy.labels, this.getDateGroup())
          var missingDataDataset = {
            "id": 123,
            "label": "MissedData",
            "data": missedDataArray.dataArray,
            "contentType": 123456,
            "borderColor": missedDataArray.borderColorsArray,
            "backgroundColor": missedDataArray.backgroundArray,
            "borderWidth": 1,
            "type": "bar", 
            "barPercentage": 1,
            "categoryPercentage": 1,
            "yAxisID": "missedData",
          }

          if (Array.isArray(missedDataArray.dataArray) && missedDataArray.dataArray.length) {
            datasets.push(missingDataDataset)
          }
        }
      }
    }
      
    return <React.Fragment><div className="chart-with-annotation-container" ref={this.ref}>
      <MilestoneLegendItem milestone={this.state.currentMilestone} period={this.state.period} clickOutside={()=>this.toggleMilestone(this.state.currentMilestone)} show={()=>this.showMilestone(this.state.currentMilestone)} xCoordinate={this.state.xCoord} direction={this.state.direction} />
      <ChartComponent data={{labels, datasets}} options={{
        plugins: {
          legend: {
            display: true, 
            position: legendPosition,
            labels: {
              filter: function(legendItem) {
                return legendItem.text !== 'MissedData';
              }
            }
          },
          tooltip: {
            callbacks: {
              title: function(context) {
                let title = context[0].dataset.label || '';

                if ( context[0].dataset.label == "MissedData") {
                  title = `Late data delivery from\n${context[0].raw.tooltipText}`;
                }
				else {
					title = context[0].label;
				}
                
                return title;
              },

              label: function(context) { 
				  
                let label = context.dataset.label || '';

                if ( context.dataset.label == "MissedData" ) {
                  label = ""
                }
				else {
					label = `${context.dataset.label}: ${context.formattedValue}`
				}

                return label;
              }
            }
          },
          
          annotation: {
            clip: false,
            annotations
          }
        },
        scales: {
          missedData: {
            type: 'linear',
            beginAtZero: true,
            display: false,
            position: 'right',
          },
        }    
      }} {...shadowChartProps} />
      </div>
    </React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
      filter: state.filter
  } 
}

function mapDispatchToProps(dispatch, ownProps){
  return {
      getStats: ()=>dispatch(filterActions.getDataLogs())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartWithAnnotations)