import store from './store';
import { find } from 'lodash';

export default function(prefix){

    function _get(){
        let existing = store.get(prefix);
        if(!existing)
            existing = [];
        return existing;
    }

    function save(key, value) {
        let existing = _get();
        existing.push({key, value});
        store.set(prefix, existing);
    }

    function load(key){
        let existing = _get();
        return find(existing, ['key', key]);
    }

    function loadAll(){
        return _get();
    }

    return {
        save,
        load,
        loadAll
    }
}
