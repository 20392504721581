import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ConfirmClick from '../../common/ConfirmClick';
import Spinner from '../../common/Spinner';
import Tooltip from '../../common/Tooltip';

export const usersSettings = {
    settingsForTable
};

function settingsForTable(onDelete, onResend, onResetPassword){
    let columns = [ 
       {
           id: 'invitee_email',
           className: 'text-align-left user-name-cell',
           Header: 'Email',
           accessor: 'invitee_email',
           width: 350,
       },
       {
           id: 'first_name',
           className: 'text-align-left',
           Header: 'First Name',
           accessor: 'first_name'
       },       
       {
           id: 'last_name',
           className: 'text-align-left',
           Header: 'Last Name',
           accessor: 'last_name'
       },       
       {
           id: 'invitation_sent_at',
           className: '',
           Header: 'Invited',
           Cell: props => moment(props.value).format('YYYY-MM-DD'),
           accessor: 'invitation_sent_at',
           filterable: false
       },
       {
           id: 'invitation_accepted_at',
           className: '',
           Header: 'Accepted',
           Cell: props => props.value ? moment(props.value).format('YYYY-MM-DD') : 'no',
           accessor: 'invitation_accepted_at',
           filterable: false
       },
       /*
       {
           id: 'imprint_count',
           className: '',
           headerClassName: 'no-sort',
           Header: 'Imprints',
           accessor: 'imprint_count',
           filterable: false,
           Cell: props => {
               const {acl_saving, imprint_count, client_admin } =  props.original;
               return acl_saving ? <Spinner enabled={true} /> : <span>{client_admin ? 'All' : imprint_count} </span>
           }
       },
       */       
       {
           id: 'links',
           className: '',
           headerClassName: 'milestone-actions no-sort',
           Header: 'Actions',
           accessor: 'acl_saving',
           sortable: false,
           filterable: false,
           Cell: props => (props.value ? null : <div className="buttons-holder users">
                 <Link to={`/admin/users/${props.original.invitee_id}`} className="chart-icon"><i className="far fa-edit"></i></Link> 
                 <ConfirmClick className="chart-icon" confirmAction={()=>{onDelete(props.original.invitee_email)}} 
                    title="Revoke Permissions" confirmLabel={<i className="fas fa-trash-alt"></i>} ></ConfirmClick>
                <Link to={`/admin/user-logs/${props.original.invitee_id}/details`} key={props.original.invitee_id} className="chart-icon"><Tooltip customIcon={true} position="left" message="See the logs" tooltipClass="toolbar-title-tooltip"><i className="fas fa-history"></i></Tooltip>
                </Link>
                {props.original.invitation_accepted_at ? <a onClick={()=>onResetPassword(props.original.invitee_id)}>Reset Password</a> : <a onClick={()=>onResend(props.original.invitee_id)}>Resend Invitation</a>}
           </div>)
       }
    ];
    
    return columns;
}