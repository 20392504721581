import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Helmet} from 'react-helmet';
import parser from 'url-parse';

import { userActions } from '../data/actions/user'
import Spinner from '../components/common/Spinner'
import Calendar from '../components/pages/mailingLists/Calendar';
import MailingListsByRecipient from '../components/pages/mailingLists/MailingListsByRecipient';

class MailingLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    checkLoggedIn(props) {


    }
    
    componentDidMount() {

    }
    
    componentWillReceiveProps(props) {
        
    }

    render() {
        const { user } = this.props;
        return (
            <div className="middle-box text-center">
                <MailingListsByRecipient />
                <Helmet>
                    <title>Shared</title>
                </Helmet>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}


export default connect(mapStateToProps)(withRouter(MailingLists));