import React from 'react';
import { connect } from 'react-redux';
import { playlistsActions } from  '../../../data/actions/playlists';
import PieChart from '../../widgets/charts/types/PieChart';
import Modal from '../../common/Modal';
import Spinner from '../../common/Spinner';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';

var chartArrowUpSvg = require('!svg-inline-loader!../../../../public/img/chart-up-arrow.svg');

class Benchmarks extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
                expanded: false,
                dispatched: false
        }
        this.toggleExpand = this.toggleExpand.bind(this);
    }
    
    toggleExpand() {
        let { expanded, dispatched } = this.state;
        if(!expanded && !dispatched) {
            dispatched = true;
            this.getBenchmarks();
        }
            
        this.setState({
            expanded: !expanded,
            dispatched
        });
    }
    
    
    getBenchmarks() {
        const {id, dispatch} = this.props;
        dispatch(playlistsActions.getPlaylistBenchmarks(id));
    }
    
    
    render (){
        const { expanded } = this.state,
            { id } = this.props,
            { benchmarks, benchmarksLoading } = this.props.playlists,
            data = benchmarks[id];
            
        return <div className="benchmark-holder">
            <Modal show={expanded}  
                size="lg"
                title="Benchmarks"
                showActionButtons={false}
                handleSubmit={()=>{this.toggleExpand()}}
                handleClose={()=>{this.toggleExpand()}}
                dialogClassName="benchmark-modal"
            >
                { benchmarksLoading && <Spinner enabled={true} /> }
                {data && <div className="modal-chart-holder">
                    <div className="modal-chart-item">
                        <PieChart data={data.devices} options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Devices',
                                    position: 'bottom'
                                },
                                legend: {
                                    position: 'top',
                                    maxHeight: 120
                                }
                            }
                        }}/>
                    </div>
                    <div  className="modal-chart-item">
                        <PieChart data={data.territories} options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Territories',
                                    position: 'bottom'
                                },
                                legend: {
                                    position: 'top',
                                    maxHeight: 120
                                }
                            }
                        }}/>
                    </div>        
                    <div  className="modal-chart-item">
                        <StackedBarChart data={data.demographics} options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Demographics',
                                    position: 'bottom'
                                },
                                legend: {
                                    position: 'top',
                                    maxHeight: 120  
                                }
                            }
                        }}/>
                    </div>                        
                </div>} 
                { (!benchmarksLoading && !data) && <p>No benchmarks available for this playlist</p> }
            </Modal>
            <button type="button" onClick={this.toggleExpand} className="default-btn button-icon">
                benchmarks
                <span className="chart-icon chart-icon--small" dangerouslySetInnerHTML={{__html: chartArrowUpSvg}}></span>
            </button>
            
        </div>
    }
}

function mapStateToProps(state) {
    return {
        playlists: state.playlists,
    }
}

    
export default connect(mapStateToProps)(Benchmarks);