import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import config from '../../config/config';
import cellHeaderHOC from '../../components/common/Datagrid/CellHeaderHOC';
import Modal from '../../components/common/Modal'
import VendorLogo from '../../components/common/VendorLogo'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { dataAdminSettings } from '../../components/pages/admin/data/Settings';
import { vendorsActions } from '../../data/actions/vendors';

const CellHeaderTable = cellHeaderHOC(ReactTable);

class DataAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showApproveModal: false,
            showRevokeModal: false,            
            currentVendor: null,
            
        }
        this.dispatchLoadData = this.dispatchLoadData.bind(this);
        this.changeVendorStatus = this.changeVendorStatus.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showVendorModal = this.showVendorModal.bind(this);
        this.approveCheckboxClick = this.approveCheckboxClick.bind(this);
    }

    componentDidMount() {
        this.dispatchLoadData(this.state);
    }
    dispatchLoadData(state){
        const { dispatch } = this.props;
        dispatch(vendorsActions.getVendorStats());
    }
    changeVendorStatus(vendor, silo, status, vendorName) {
        const { dispatch } = this.props;
        dispatch(vendorsActions.changeVendorStatus(vendor, silo, status, vendorName));
    }
    
    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }

    
    showVendorModal(currentVendor, modal) {
        this.setState({currentVendor}, ()=>{
            this.showModal(modal, true);
        });
    }
    
    approveCheckboxClick(currentVendor){
        if(currentVendor.status=='approved')
            return;
        this.changeVendorStatus(currentVendor.vendorID, currentVendor.silo, 'approved', currentVendor.vendor);
    }
    
    renderApproveModal(){
        const { currentVendor } = this.state;
        if(!currentVendor)
            return null;
        const vendorSlug = currentVendor.vendor == 'YouTube' ? 'YouTube' : 'Common', 
            vendorDataPolicyURL = `https://entertainment-intelligence.com/legal/${vendorSlug}`;
        
        return <Modal show={this.state.showApproveModal}  
                title="Approve Data Policy"
                showActionButtons={false}
                handleClose={()=>this.showModal('showApproveModal', false)}
                dialogClassName="modal-with-iframe"
            >
                <div className="vendor-title">
                    <VendorLogo name={currentVendor.vendor} /> {currentVendor.vendor}   
                </div>
                <iframe src={`${vendorDataPolicyURL}.html`} frameborder="0" className="message-iframe"></iframe>
                <div className="checkbox-holder">
                    <input type="checkbox" checked={currentVendor.status == 'approved'} readonly={currentVendor.status == 'approved'} onClick={(e)=>{this.approveCheckboxClick(currentVendor);currentVendor.status='approved'; }} id="approve" className="input" />
                    <label htmlFor="approve"  className="checkbox-label"><a href={`${vendorDataPolicyURL}.pdf`} target="_blank">Policy read and agreed</a></label>
                </div>
            </Modal>

    }
    
    renderRevokeModal(){
        const { currentVendor } = this.state;
        if(!currentVendor)
            return null;
        
        return <Modal show={this.state.showRevokeModal}  
                title="Revoke Access"
                submitTitle="Revoke"
                handleSubmit={()=>{this.changeVendorStatus(currentVendor.vendorID, currentVendor.silo, 'revoked', currentVendor.vendor);this.showModal('showRevokeModal', false)}}
                handleClose={()=>this.showModal('showRevokeModal', false)}
            >
                <div className="vendor-title">
                    <VendorLogo name={currentVendor.vendor} /> {currentVendor.vendor}   
                </div>
                <span className="popup-confirm-text">
                    Are you sure you want to revoke access to this API?
                </span>
            </Modal>

    }    

    render() {
        const { items, dates = [], loading } = this.props.vendors,
            tableRows = (items && items.length) ? items.length : 10;
        return <div className="statistics-main-holder">
            <h2 className="audience">
                <span>Data Admin</span>
            </h2>        
        
            <div className="">
                {this.renderApproveModal()}
                {this.renderRevokeModal()}
                
                <div className="data-table-legend">
                    <ul className="legend-list">
                        <li className="legend-item">
                            <div className="legend-mark average"></div>
                            <span className="text">average</span>
                        </li>
                        <li className="legend-item">
                            <div className="legend-mark above-average"></div>
                            <span className="text">30%+ above average</span>
                        </li>
                        <li className="legend-item">
                            <div className="legend-mark below-average"></div>
                            <span className="text">30%+ below average</span>
                        </li>
                        <li className="legend-item">
                            <div className="legend-mark empty"></div>
                            <span className="text">no data</span>
                        </li>
                        <li className="note">
                            These counts are for total rows of data collected, not the number of streams per day.
                            Overall totals will rely on data being mapped to catalogue and validated as acceptable to report.
                        </li>

                    </ul>
                </div>
                <ReactTable
                    loading={loading}
                    className='catalogue-table-holder data-admin-table simple-table'
                    data={items}
                    showPageSizeOptions={false}
                    defaultPageSize={tableRows}
                    columns={dataAdminSettings.settingsForTable(dates, (vendor)=>this.showVendorModal(vendor, 'showApproveModal'), (vendor)=>this.showVendorModal(vendor, 'showRevokeModal'))}
                />
            </div>
            <Helmet>
                <title>Data Admin - Admin</title>
            </Helmet>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        vendors: state.vendors
    }
}
export default withRouter(connect(mapStateToProps)(DataAdmin))
