import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, get } from 'lodash';
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import LineChart from '../../widgets/charts/types/LineChart';
import DateGroupSelector from './DateGroupSelector';
import ContentTypeSelector from '../../pages/result/ContentTypeSelector';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import EntitySelector from './EntitySelector';
import CSV from '../../../helpers/CSVExport';

import { statsActions } from '../../../data/actions/stats';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class MultiEntitiesStreams extends React.Component {
    
    nullElement = {value: null, label: 'All'}
    
    constructor(props) {
        super(props);
        this.state = {
            entity: this.nullElement,
            dateGroup: null,
            contentType: 0
        }
        
        this.setEntity = this.setEntity.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);
        this.setContentType = this.setContentType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setEntity(entity) {
        if(Array.isArray(entity) && entity.length){
            entity = entity[0];
        }
        else {
            entity = this.nullElement;
        }
        this.setState({entity}, ()=>this.props.getStreamsStats(this.state.dateGroup, this.state.entity));            
            
        
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>{
            this.props.getStreamsStats(dateGroup, this.state.entity);
        });
    }
    
    setContentType(contentType) {
        this.setState({contentType});
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('units_timeseries', '', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.stats.multiStreams.exportData, {filename}, 'units_timeseries');
    }
    
    renderChartActions(data, contentTypes = []){
        const { mode } = this.state;
        let entities = [this.nullElement];
        if(data) {
            for(let newEntity of data.datasets) {
                if(!find(entities, entity=>entity.value == newEntity.entity)) {
                    entities.push({
                        value: newEntity.entity,
                        label: newEntity.label
                    });
                }
            }
        }

        return <React.Fragment>
            
            <div className="entity-selector-select">
                <EntitySelector entityChange={this.setEntity} entityFilterMode="array" />
            </div>
            <ContentTypeSelector contentTypes={contentTypes} contentTypeChange={this.setContentType}/>
            <DateGroupSelector dateGroupChange={this.setDateGroup}/>        
        </React.Fragment>
    }
    
    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
            <div className="ibox-actions">
              <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            </div>
        </div>);
        return toolbar;

    }
    
    
    componentDidMount() {
        this.props.getStreamsStats(this.state.dateGroup, this.state.entity);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats(this.state.dateGroup, this.state.entity);
            }                
        }
        /*
        if(nextProps.ids && !isEqual(nextProps.ids, this.props.ids)) {
            this.props.getStreamsStats(nextProps.ids);
        } 
        */
    }
    
    render (){  
        const {multiStreams, multiStreamsLoading} = this.props.stats,
            widgetTitle = `Units`;
            
        let chartData = Object.assign({}, multiStreams);
        
        if(chartData.datasets) {
            let { entity, contentType } = this.state;
            
            if(contentType)
                chartData.datasets = chartData.datasets.filter(dataset=>dataset.contentType == contentType)
        }
        
        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        let milestonesItems = get(this.props.milestones, 'entities.mixed.tableItems', [])
        return <Box title={widgetTitle} toolbar={this.renderToolbar()} chartActions={this.renderChartActions(multiStreams, chartData.contentTypes)} spinnerEnabled={multiStreamsLoading} >
            {chartData.datasets && 
                <div className="chart-block">
                    {chartData.datasets && <LineChartWithAnnotations milestones={milestonesItems} data={chartData} period={this.state.dateGroup} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} shadowChartProps={shadowChartProps} />}
                </div>
            }
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        milestones: state.milestones,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (dateGroup, entityFilter) => {
            let { filtered = [] } = ownProps;
            if(entityFilter && entityFilter.value)
                filtered = [entityFilter];
            
            if(dateGroup) {
                filtered.push({id: 'date_grouping', value: dateGroup})
            }
            dispatch(statsActions.getMultiEntitiesStreams(filtered));
        } 
    } 
}



export default connect(mapStateToProps, mapDispatchToProps)(MultiEntitiesStreams);