import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, map } from 'lodash';
import store from '../../../helpers/store'

import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';

import Box from '../../common/Box';

import TerritoryStatsMap from './Territory/Map';
import TerritoryDemographics from './Territory/Demographics';
import { audienceActions } from '../../../data/actions/audience';
import { territorySettings } from './Territory/Settings'; 
import ModeSelect from '../../common/ModeSelect';
import EntitySelector from '../../pages/result/EntitySelector';
import Locations from '../../pages/result/Locations';

var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import VendorLogo from "../../common/VendorLogo";
import { dspLogos } from "../../common/Stats/utils";
const CellHeaderTable = cellHeaderHOC(ReactTable);



class TerritoryStats extends React.Component {
    
    constructor(props) {
        super(props);
        
        const territoryLimit =  10;
        
        this.state = {
          mode: props.defaultMode || 'map',
          territory: '',
          limit: territoryLimit,
          logoDataFiltered: [],
          entityFilter: null
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setTerritory = this.setTerritory.bind(this);
        this.setMapMode = this.setMapMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
    };    
    
    setChartMode(mode){
        this.setState({mode});
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(mode);

    }
    
    setTerritory(territory){
        this.setState({
            territory,
            mode: 'demographics'
        });
    }

    setMapMode() {
        this.setState({
            mode: 'map'
        });
    }

    renderToolbar(modes){
        let toolbar = [];
        const options = [
            {icon: worldSvg, value: 'map', label: "World Map"},
            {icon: tableSvg, value: 'table', label: "Table"},
        ];

        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
                <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
            </div>
        {/* <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div> */}
        </div>)
        return toolbar;
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.props.dispatch(audienceActions.getTerritoryStats(this.props.id, this.props.entity, entityFilter ? entityFilter : this.props.filtered)));
    }
    
    
    exportToCsv(){
        const { entityFilter } = this.state;
        this.props.dispatch(audienceActions.getTerritoryStats(this.props.id, this.props.entity, entityFilter ? entityFilter : this.props.filtered, 100000)).then(data=>{
            const filename = CSV.CSVHeader('details_territories', 'listeners', this.props.filter.global, this.props.parentEntityTitle); 
            return CSV.CSVExport(data.table, {filename}, 'audience_territories');
        })
        
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }

    componentDidMount() {
        const { entityFilter } = this.state;
        const { chartType } = this.props;
        this.props.dispatch(audienceActions.getTerritoryStats(this.props.id, this.props.entity, entityFilter ? entityFilter : this.props.filtered))
    }
    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        const { entityFilter } = this.state;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(audienceActions.getTerritoryStats(this.props.id, this.props.entity, entityFilter ? entityFilter : this.props.filtered))
            }                
        }
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    renderChartActions(){
        const { mode } = this.state;
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entityFilterMode="array" />        
        </React.Fragment>
    }

    render (){
        const { audience, expanded = false, locationFiltered, filtered} = this.props;
        
        return <Box title="Territories" toolbar={this.renderToolbar()} chartActions={this.renderChartActions()} spinnerEnabled={audience.territoryLoading} exportToCsv={this.renderExportToCsv()}>
            {audience.territory && <div>
                {this.state.mode=='map' && <TerritoryStatsMap id="audience_territories_widget" data={audience.territory} territoryClick={(territory)=>this.setTerritory(territory)} />}
                {this.state.mode=='table' && <div  id="audience_territories_widget" className="territory-table territory-table--single custom-scroll"><CellHeaderTable
                    className="simple-table"
                        data={audience.territory.table}
                        columns={territorySettings.settingsForTable(expanded?'expanded':'map', this.setTerritory)}
                        defaultPageSize={10}
                        showPagination={audience.territory.table.length > 10}
                        noDataText='No results found. Please check your filters.'
                        collapseOnDataChange={false}
                        SubComponent={row=><Locations territory={row.original.territory} filtered={filtered} mode="expanded" />}
                /></div>}
                {this.state.mode=='demographics' && <TerritoryDemographics filtered={filtered} territory={this.state.territory} setMap={this.setMapMode} setTerritory={this.setTerritory} />}
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(TerritoryStats);