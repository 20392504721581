import React from 'react';
//import $ from 'jquery';
import { connect } from 'react-redux';
import { debounce, pull, isEqual, get } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import Box from '../Box';
import FilterSaveLoad from '../../widgets/filter/FilterSaveLoad';
import Modal from '../Modal';

import store from '../../../helpers/store'

import { filterActions } from '../../../data/actions/filter';
import { imprintsActions } from '../../../data/actions/imprints';
import { artistsActions } from '../../../data/actions/artists';
import { productsActions } from '../../../data/actions/products';
import { tracksActions } from '../../../data/actions/tracks';
import icons from '../../../helpers/Icons';
import Spinner from '../Spinner';

import { Modal as RBModal } from 'react-bootstrap';

var addSvg = require('!svg-inline-loader!../../../../public/img/add.svg');
var imprintsSvg = require('!svg-inline-loader!../../../../public/img/icons/imprints.svg');
var artistsSvg = require('!svg-inline-loader!../../../../public/img/icons/artists.svg');
var productsSvg = require('!svg-inline-loader!../../../../public/img/icons/products.svg');
var tracksSvg = require('!svg-inline-loader!../../../../public/img/icons/tracks.svg');
var projectsSvg = require('!svg-inline-loader!../../../../public/img/icons/projects.svg');
var deleteSvg = require('!svg-inline-loader!../../../../public/img/icons/delete.svg');

class PageFilter extends React.Component {
    pageFilterObjects = [
      { type: "imprints", icon: imprintsSvg },
      { type: "products", icon: productsSvg },
      { type: "projects", icon: productsSvg },
      { type: "artists", icon: artistsSvg },
      { type: "tracks", icon: tracksSvg },
    ];
  
    constructor(props){
        super(props);
        this.state = {
            entity: this.props.entity,
            search: '',
            autocomplete: [],
            showAutocomplete: false,
//            filter: props.filter,
            expanded: true,
            modalId: `autocomplete${this.props.entity}`,
            showSeachModal: false
        }

        this.addToFilter = this.addToFilter.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.setFilterSearch = debounce(this.setFilterSearch.bind(this), 1000);
        this.collapseAutocomplete = this.collapseAutocomplete.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.useFilter = this.useFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.renderFilterIcon = this.renderFilterIcon.bind(this);
    }

    getFilter(props){
        const entity = this.props.filterKey;
        if(props.filter && props.filter[entity])
            return props.filter[entity];
        else
            return [];
    }

    componentWillReceiveProps(nextProps){
        const { entity } = this.props;
        if(nextProps[entity] && nextProps[entity].autocomplete_items){
            this.setState({
                autocomplete: nextProps[entity].autocomplete_items,
                autocompleteNotFound: (nextProps[entity].loading === false && nextProps[entity].autocomplete_items.length === 0)
            })
        }
    }

    setSearch(e){
        const value = e.target.value;
        this.setState({
            autocomplete: [],
            search: value,
            showAutocomplete: true
        })
        this.setFilterSearch(value)
    }

    setFilterSearch(value){
        this.props.getAutocomplete(value);
    }

    formatFilterItem = (item) => {
      const { logo, ...rest } = item;
      return rest;
    }

    addToFilter(e, item){
        e.preventDefault();
        const formattedItem = this.formatFilterItem(item);
        let filter = Object.assign({}, this.props.filter);
        filter.body = !!filter.body ? filter.body : {};
        filter.body.key = !!filter.body.key ? filter.body.key : filter.name;
        filter.body.value = filter.body.value ? filter.body.value : [];
        filter.body.value.push(formattedItem);
        store.set(this.props.filterKey, filter);
        this.props.loadFilter(filter);
        //this.props.setFilter(this.props.filterKey, filter.body.value);
        this.showModal('showSeachModal', false);
    }

    removeFromFilter(e, item){
        e.preventDefault();
        let filter = Object.assign({}, this.props.filter);
        pull(filter.body.value, item);
        store.set(this.props.filterKey, filter);
        this.props.loadFilter(filter);
        //this.props.setFilter(this.props.filterKey, filter.body.value);
    }

    removeFilter = () => {
      this.props.onRemoveFilter(this.props.filter, this.props.filterKey);
      const defaultFilter = this.props.onLoadUnsavedFilter();
      this.props.loadFilter(defaultFilter);
      this.applyFilter(defaultFilter);
    }

    updateEntityFilter = (e, paramName) => {
      e.preventDefault();
      this.props.onUpdateEntityFilter(this.props.filter, this.props.filterKey);
      this.applyFilter(this.props.filter);
    }

    applyFilter = filter => {
      store.set(this.props.filterKey, filter);
      this.props.setFilter(this.props.filterKey, filter.body.value);
    }
    
    useFilter = () => {
        const filter = store.get(this.props.filterKey);
        this.props.setFilter(this.props.filterKey, filter.body.value);
    }
    
    clearFilter(e, item){
        e.preventDefault();
        let filter = Object.assign({}, this.props.filter);
        filter.body.value = [];
        store.set(this.props.filterKey, filter);
        this.props.loadFilter(filter);
        this.useFilter();
    }

    renderDetailsButton(){
        const {entity, filter} = this.props;
        if(entity !== 'tracks')
            return null;
        
        if(!filter.body || !filter.body.value.length)
            return null;
        
        const ids = filter.body.value.reduce((str, track)=> {
            str += str ? ',' : '';
            str += track.id;
            return str;
        }, '');
        
        return <a className="btn" href={`/tracks/${ids}`}>Go to details page</a>
    }

    handleCreateFilter = e => {
      e.preventDefault();

      const key = prompt('Please enter an entity filter name');
      if(!key)
        return
      const currentEntityFilter = Object.assign({}, this.props.filter);
      currentEntityFilter.body.key = key;

      const newFilter = currentEntityFilter.body;

      const params = {
        entity: this.props.filterKey,
        name: key,
        body: newFilter,
        is_shared: false
      }

      const createdFilter = this.props.onCreateFilter(params);
      Promise.resolve(createdFilter).then( res => {
        const newFilter = { name: res.name, body: res.body, id: res.id };
        store.set(this.props.filterKey, newFilter);
        this.props.setFilter(this.props.filterKey, newFilter.body.value);
      })
    }

    collapseAutocomplete(){
        this.setState({
            showAutocomplete: false
        })
    }

    renderToolbar(){
        return <a className="collapse-link">
            <i className={"fa " + (this.state.expanded ? 'fa-chevron-down' : 'fa-chevron-up') } onClick={this.toggleExpand}></i>
        </a>;
    }

    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    showModal = (modal, show) => {
      this.setState({
          [modal]: show
      })
    }

    renderFilterIcon() {
      const { filterKey } = this.props;
      const searchCriteria = (f) =>
        f.type === filterKey || `exclude_${f.type}` === filterKey;
      const currentType = this.pageFilterObjects.find(searchCriteria);
      const icon = currentType ? currentType.icon : "";
      
      return <span className={`page-filter-icon ${currentType.type}`} dangerouslySetInnerHTML={{__html: icon}} />
    }

    render() {
        const existFilter = !!get(this.props.filter, 'body.value.length');
        const userFilter = this.props.user.id === this.props.filter.user_id;
        const autocompleteLoading = this.props[this.props.entity].autoCompleteLoading,
            autocompleteNotFound = !autocompleteLoading && this.state.autocompleteNotFound;

        return (
          <div className={this.props.rootClassName}>
            <Modal show={this.state.showSeachModal}  
                showActionButtons={false}
                onRequestClose={() => this.showModal("showSeachModal", false)}
                dialogClassName="page-filter-dialog"
            >
              <div>
                <form role="form" className="form-inline">
                  <div className="form-group page-filter-input">
                    <input
                      className="form-control"
                      type="text"
                      value={this.state.search}
                      onChange={this.setSearch}
                      placeholder={`Search for ${this.state.entity}`}
                    />
                    <Spinner
                      className="spinner filter-spinner"
                      enabled={autocompleteLoading}
                    />
                  </div>
                </form>
                <ul className="autocomplete">
                  {this.state.autocomplete.map((item) => {
                    return (
                      <li key={item.id} className="autocomplete-item">
                        <a
                          href="#"
                          onClick={(e) => this.addToFilter(e, item)}
                          className="logo-container"
                        >
                          <span
                            className="chart-icon logo"
                            dangerouslySetInnerHTML={{ __html: item.logo }}
                          ></span>
                          <span>{item.name}</span>
                        </a>
                      </li>
                    );
                  })}
                  {autocompleteNotFound === true && (
                    <li className="autocomplete-item">
                      <span>No {this.props.entity} found</span>
                    </li>
                  )}
                </ul>
              </div>
            </Modal>
            <div className="tag-list">
              {existFilter ? 
                <div className="page-filter-item">
                  {this.renderFilterIcon()}
                  <div className="page-filter-list">
                    {this.props.filter.body.value.map((item) => {
                      return (
                        <div key={item.id} className="tag">
                          <div className="tag-name-holder">
                            <div className="tag-name">
                              <span>{item.name}</span>
                            </div>
                            <a
                              className="close-link"
                              title="remove"
                              onClick={(e) => this.removeFromFilter(e, item)}
                            >
                              ×
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    </div>
                </div>
              : (
                <div className="empty-filter-tag">
                  <span>Filter is empty</span>
                </div>
              )}
              <div className="current-filter-actions">
                {this.props.filter.name && (
                  <button
                    type="button"
                    className="default-btn default-btn--small"
                    onClick={() => this.showModal("showSeachModal", true)}
                    title={`Add ${this.state.entity} to filter`}
                  >
                    Add {this.state.entity} to filter
                  </button>
                )}

              </div>
              <div>
                <button
                  type="button"
                  className="default-btn default-btn--small"
                  onClick={() => this.useFilter()}
                >
                  Apply
                </button>
                {existFilter && (
                  <button
                    type="button"
                    className="remove-filter-link filter-reset-btn button-no-style"
                    onClick={(e) => this.clearFilter(e)}
                  >
                    <span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>
                    Clear
                  </button>
                )}
              </div>
              
            </div>
            {this.renderDetailsButton()}

            <div className="entity-filter-action">
              <ul className="entity-filter-dropdown-list">
                {this.props.filter.name &&
                  !this.props.tempFilter &&
                  userFilter && (
                    <li>
                      <a className="default-btn default-btn--small" onClick={this.updateEntityFilter}>
                        Update current
                      </a>
                    </li>
                  )}
                {this.props.filter &&
                  this.props.filter.name &&
                  !this.props.tempFilter &&
                  userFilter && (
                    <li>
                      <a
                        title={`Remove ${this.props.entity} filter`}
                        onClick={this.removeFilter}
                        className="remove-filter-link filter-reset-btn button-no-style"
                      >
                        <span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>
                        Delete Filter
                      </a>
                    </li>
                  )}
              </ul>
              <a
                className="default-btn default-btn--small btn-with-icon btn-with-icon--left"
                onClick={this.handleCreateFilter}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: addSvg }}
                  className="btn-icon-holder no-fill"
                />
                Create New Filter
              </a>
            </div>
          </div>
          
        );
    }
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        tracks: state.tracks
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getAutocomplete: (value) => {
            switch(ownProps.entity) {
                case 'imprints':
                    dispatch(imprintsActions.getImprintsAutocomplete(value));
                    break;
                case 'artists':                 
                    dispatch(artistsActions.getArtistsAutocomplete(value));
                    break;
                case 'products':
                    dispatch(productsActions.getProductsAutocomplete(value))
                    break;
                case 'projects':
                    dispatch(projectsActions.getProjectsAutocomplete(value))
                    break;                    
                case 'tracks':
                    dispatch(tracksActions.getTracksAutocomplete(value))
                    break;

            }
        },
        setFilter: (entity, filter) => { dispatch(filterActions.setEntityFilter(entity, filter)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PageFilter)
