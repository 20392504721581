import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const mapsService = {
  getArtistStreamsByCountry,
  getArtistStreamsByRegion
};

function getArtistStreamsByCountry(params){
  const requestOptions = {
    method: 'GET',
    headers: AuthHeader()
  };

  const baseUrl = `${config.apis.ei_gis_api}/artist_streams/fetch_artist_streams/${params.id}`;
  let url = new URL(baseUrl);
  url.search = new URLSearchParams(params).toString();

  return fetch(url, requestOptions)
  .then( response => response.json())
  .then( response => response)
  .catch( error => error);
}

function getArtistStreamsByRegion(params){
  const requestOptions = {
    method: 'GET',
    headers: AuthHeader()
  };
  const baseUrl = `${config.apis.ei_gis_api}/artist_streams/fetch_artist_streams_by_region/${params.id}`;
  let url = new URL(baseUrl);
  url.search = new URLSearchParams(params).toString();

  return fetch(url, requestOptions)
  .then( response => response.json())
  .then( response => response)
  .catch( error => error);
}
