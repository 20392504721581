export const globalFilterConstants = {
    GET_GLOBAL_FILTER_REQUEST: 'GET_GLOBAL_FILTER_REQUEST',
    GET_GLOBAL_FILTER_SUCCESS: 'GET_GLOBAL_FILTER_SUCCESS',
    GET_GLOBAL_FILTER_FAILURE: 'GET_GLOBAL_FILTER_FAILURE',
    ADD_ENTITY_REQUEST: 'ADD_ENTITY_TO_GLOBAL_FILTER_REQUEST',
    ADD_ENTITY_SUCCESS: 'ADD_ENTITY_TO_GLOBAL_FILTER_SUCCESS',
    ADD_ENTITY_FAILURE: 'ADD_ENTITY_TO_GLOBAL_FILTER_FAILURE',
    DELETE_ENTITY_REQUEST: 'DELETE_ENTITY_TO_GLOBAL_FILTER_REQUEST',
    DELETE_ENTITY_SUCCESS: 'DELETE_ENTITY_TO_GLOBAL_FILTER_SUCCESS',
    DELETE_ENTITY_FAILURE: 'DELETE_ENTITY_TO_GLOBAL_FILTER_FAILURE',
    SET_DEFAULT_FILTER_REQUEST: 'SET_DEFAULT_FILTER_REQUEST',
    SET_DEFAULT_FILTER_SUCCESS: 'SET_DEFAULT_FILTER_SUCCESS',
    SET_DEFAULT_FILTER_FAILURE: 'SET_DEFAULT_FILTER_FAILURE'
}
