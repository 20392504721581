import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const userEntityFiltersService = {
  getUserEntityFilter,        
  getUserEntityFilters,
  getRecentEntityFilters,
  clearRecentEntityFilters,
  getPlaylistEntityFilters,
  updateUserEntityFilter,
  createFilter,
  destroyFilter,
  addToFilter,
  deleteFromFilter,
  clearRecentEntityFilters
}

function destroyFilter(id){
  const url = `${config.apis.orthus}/v1/user_entity_filters/${id}`;

  return fetch(url, {
    method: 'DELETE',
    headers: AuthHeader({ 'content-type': 'application/json' })
  }).then( res => res.json());
}

function createFilter(params){

  const url = `${config.apis.orthus}/v1/user_entity_filters`;

  return fetch( url, {
    method: 'POST',
    headers: AuthHeader({'content-type': 'application/json'}),
    body: JSON.stringify(params)
  }).then( res => res.json());
}

function getUserEntityFilters(){
  const url = `${config.apis.orthus}/v1/user_entity_filters`;

  return fetch( url, {
    method: 'GET',
    headers: AuthHeader()
  }).then( res => res.json());
}

function getPlaylistEntityFilters(){
    const url = `${config.apis.orthus}/v1/user_entity_filters`;

    return fetch( url, {
      method: 'GET',
      headers: AuthHeader()
    }).then( res => res.json());
  }


function updateUserEntityFilter(id, params){
  const url = `${config.apis.orthus}/v1/user_entity_filters/${id}`;

  return fetch( url, {
    method: 'PATCH',
    headers: AuthHeader({'content-type': 'application/json'}),
    body: JSON.stringify(params)
  }).then( res => res.json());
}


function addToFilter(id, entity){

  const url = `${config.apis.orthus}/v1/user_entity_filters/${id}/create_user_entity`;

  return fetch( url, {
    method: 'POST',
    headers: AuthHeader({'content-type': 'application/json'}),
    body: JSON.stringify(entity)
  }).then( res => res.json());
}


function deleteFromFilter(id, entityID){
    const url = `${config.apis.orthus}/v1/user_entity_filters/${entityID}/destroy_entity`;

    return fetch(url, {
      method: 'DELETE',
      headers: AuthHeader({ 'content-type': 'application/json' })
    }).then( res => res.json());
}

function getUserEntityFilter(id){
    const url = `${config.apis.orthus}/v1/user_entity_filters/${id}`;

    return fetch( url, {
      method: 'GET',
      headers: AuthHeader()
    }).then( res => res.json());
  }


function getRecentEntityFilters(){
    const url = `${config.apis.orthus}/v1/recent_entity_filters`;

    return fetch( url, {
      method: 'GET',
      headers: AuthHeader()
    }).then( res => res.json());
  }

function clearRecentEntityFilters(){
    const url = `${config.apis.orthus}/v1/recent_entity_filters`;

    return fetch( url, {
      method: 'DELETE',
      headers: AuthHeader()
    }).then( res => res.json());
  }

