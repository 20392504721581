import React from 'react';
import WorldMap  from '../../../common/WorldMap';
import { map, merge } from 'lodash';

class WorldMapChart extends React.Component {
    buildChartData(props){
        let mapValues = {};
        for (let region of props.data){
            mapValues[region.title] = region.value;
        }
        return mapValues
    }

    constructor(props){
        super(props);
        this.state = {
            data: this.buildChartData(props),
        };
    }

    componentWillReceiveProps(props){
        this.setState({
            data: this.buildChartData(props)
        })
    }
    render() {
        return (
            <WorldMap data={this.state.data} label={this.props.labels.valueLabel}></WorldMap>
        )
    }
}

export default WorldMapChart