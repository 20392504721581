import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Helmet} from 'react-helmet';
import parser from 'url-parse';

import LoginForm from '../components/forms/login'
import { userActions } from '../data/actions/user'
import Spinner from '../components/common/Spinner'

class SSO extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    checkLoggedIn(props) {


    }
    
    componentDidMount() {
        const { dispatch, user } = this.props;
        if(user.loggedIn)
            return this.props.history.push('/');
        
        const { query : {jwt = ''} } = parser(this.props.location.search, '', true);
        dispatch(userActions.sso(jwt));
    }
    
    componentWillReceiveProps(props) {
        if(props.user.loggedIn)
            return this.props.history.push('/');
        
    }

    render() {
        const { user } = this.props;
        return (
            <div className="middle-box text-center">
                <div>
                    <div className="logo row">
                        <div className="col-xs-3">
                            <div className="img" />
                        </div>
                    </div>
                    <div className="login-form">
                        <h2>Checking token</h2>
                        <Spinner enabled={this.props.user.loading} /> 
                        {user.error && <div className="alert alert-danger">
                            {user.error}
                        </div>}
                    </div>
                </div>
                <Helmet>
                    <title>SSO Login</title>
                </Helmet>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}


export default connect(mapStateToProps)(withRouter(SSO));