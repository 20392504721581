import React, {Component} from 'react';
import {connect} from 'react-redux';
import {commonActions} from '../../data/actions/common';
import { Dropdown } from 'react-bootstrap';
import { debounce } from 'lodash';
import ClickOutsideHolder from '../../helpers/ClickOutsideHolder';

export class EntityAutocomplete extends React.Component {
    
    constructor(props) {
        super(props);
        this.getAutocomplete = debounce(this.getAutocomplete.bind(this), 1000);
    }
    
    getAutocomplete(value, entity){
        return this.props.getAutocomplete(value, entity);    
    }

  handleChange = (value, entity) => {
    const { getAutocomplete, onChange } = this.props;

    onChange(value);
    this.getAutocomplete(value, entity);
  }

  render() {
    const { getAutocomplete, results, entities, entity, onClickItem, show, currentSetting, reportName, entityName, value, handleCloseSearch } = this.props;
    const existData = (Array.isArray(results) && results.length > 0);
    
    const placeholder = `Enter a ${entityName} name to add it to the notifications list`;
    const listOfUserEntities = entities.map(entity => entity.product.title);
    const searchWithoutSelectedData = existData && results.filter(hit => !listOfUserEntities.includes(hit.title) );

    return (
      <div className="notification-autocomplite-holder">
        <input value={value} placeholder={placeholder} onChange={e => this.handleChange(e.target.value, entity)} name={entity} type="text" className="form-control"/>
        {existData && 
        <ClickOutsideHolder show={show} onClickOutside={(e) => {handleCloseSearch(e)}}>
        <Dropdown show={show}>
          <Dropdown.Menu style={{overflowY: 'scroll', minWidth: 500, maxHeight: 200 }} as="ul">
            {
              searchWithoutSelectedData.map( item => {
                return (<Dropdown.Item as="li" key={item.id}>
                  <a onClick={e => onClickItem(e, item, entity, currentSetting, reportName)} className="logo-container">
                    <span> {item.title} ({item.barcode}) by {item.artist_name}</span>
                  </a>
                </Dropdown.Item>)
              })
            }
        </Dropdown.Menu>

      </Dropdown>

        </ClickOutsideHolder>
      }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { results: state.common.data }
}

function mapDispatchToProps(dispatch) {
  return {
    getAutocomplete: (value, entity) => {
      dispatch(commonActions.getAutocomplete(value, entity))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityAutocomplete);
