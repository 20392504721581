import React from 'react';
import { Link } from 'react-router-dom';

class TrackPosition extends React.Component {
    render (){
//        const { track: {position, history} } = this.props,
//            difference = history ? history.changed_from - position: '';
        const { track: {position, position_change} } = this.props;
        return <span>{position} ({position_change})</span>;
           
    }
}

export default TrackPosition;