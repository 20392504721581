import React from 'react';
import { ReactTableDefaults } from 'react-table';
import Spinner from '../Spinner';

const ReactTableSpinner = (props) => {
    const { loading, loadingText } = props;
    const message = loadingText ? <p className="loading-message">{loadingText}</p> : null;
    return loading ? <div className="-loading -active">
        <div className="-loading-inner">
            {message}
            <Spinner  enabled={true} />
    		</div>
        </div> : null;
}

Object.assign(ReactTableDefaults, {
    LoadingComponent: ReactTableSpinner
})