import React, { Component } from 'react';
import ReactTable from "react-table";
import {Helmet} from 'react-helmet';
import SearchWidget from '../../components/pages/home/SearchWidget';
import CSVImportModal from '../../components/common/CSVImportModal';
import CSVSampleDownload from '../../components/common/CSVSampleDownload';
import cellHeaderHOC from '../../components/common/Datagrid/CellHeaderHOC';
import CSV from '../../helpers/CSVExport';

import { connect } from 'react-redux';
import { debounce, find, findIndex, isEqual, cloneDeep } from 'lodash';
import { withRouter } from 'react-router';

import { artistsActions } from '../../data/actions/artists';
import { channelsActions } from '../../data/actions/channels';
import { socialManagementSettings } from '../../components/pages/socialManagement/Settings';
const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const CellHeaderTable = cellHeaderHOC(ReactTable);

class SocialManagementList extends Component {
  defaultPageSize = 10;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      page: 0,
      //            pageSize: 10,
      filtered: "",
      filter: {
        // will be set from FieldsSelector
        columns: [],
        filtered: [
           { id: "track_artists", value: this.getPageFilterInitial(props, "artists") }
        ],
      },
      defaultSorted: [{ id: "curr_units", desc: true }],
    };
    this.loadData = this.loadData.bind(this);
    this.csvImport = this.csvImport.bind(this);
    this.dispatchLoadData = this.dispatchLoadData.bind(this);
    this.searchResult = this.searchResult.bind(this);
    this.exportToCsv = this.exportToCsv.bind(this);
  }
  
  exportToCsv(){
      let exportData = cloneDeep(this.props.artists.socialItems);
      for(let row of exportData) {
          for(let key of Object.keys(row)) {
              if(key.startsWith('channel_')) {
                  let channelType = '', channelURLs = [];
                  for(let channel of row[key]) {
                      channelType = channel.channel_type_name;
                      channelURLs.push(channel.url);
                  }
                  row[channelType] = channelURLs.join('\\,');
              }
          }
      }
      const filename = CSV.CSVHeader('social_channels', '', this.props.filter.global); 
      return CSV.CSVExport(exportData, {filename}, 'social_channels');
  }

  getPageFilterInitial(){
      const { match, filtered } = this.props;
      if(match && match.params.artist)
          return match.params.artist;
      if(filtered) {
          if(filtered.artists && filtered.artists.length)
              return filtered.artists;
      }
      return null;
  }
  

    componentDidMount() {
        this.props.dispatch(channelsActions.getChannelTypes());
    }

    getPageSize() {
        return this.props.filter.global ? this.props.filter.global.limit : this.defaultPageSize;
    }

    loadData(state, instance) {
        this.setState({
            sorted: state.sorted
        }, ()=>this.dispatchLoadData(state));
    }

    dispatchLoadData(state, cached=false){
        const { dispatch, mode = "full" } = this.props;
        dispatch(artistsActions.getArtistsSocial({
            page: state.page,
//            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }, cached, mode));
    }

    /*
    getPageFilter(props, entity){
        let ids = [];
        if(props.filter[entity])
            ids = props.filter[entity].map(item=>item.id);
        return ids;
    }
     */

    componentWillReceiveProps(nextProps){
        if(nextProps.artists && nextProps.artists.socialItems){
            this.setState({
                data: nextProps.artists.socialItems,
                pages: Math.ceil(nextProps.artists.socialTotal / this.getPageSize()),
                loaded: true
            })
        }
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    let state = Object.assign({}, this.state);
                    state.filtered = state.filter.filtered;
                    this.dispatchLoadData(state);
                }
            }
        }
    }
    
    searchResult(item) {
        let filter = [];
        
        filter.push({
            id: 'track_artists',
            value: item.id
        });
    
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered: filter
            }
        }, ()=> {
           // this.dispatchLoadData(this.state);
            this.props.history.push(`/admin/social-management/${item.id}`);
        });
    }
    
    csvImport(rows) {
        let promises = [];
        for(let row of rows) {
            const { artist_id: artistID } = row;
            for(let channelID of Object.keys(row)) {
                if(channelID == 'artist_id')
                    continue;
                if(row[channelID]) {
                    promises.push(this.props.dispatch(channelsActions.addChannel(artistID, row[channelID], true)));
                }
            }
        }
        
        
        promises.reduce((curr, next)=>{return curr.then(next)}, Promise.resolve()).then(()=>this.dispatchLoadData(this.state, true));
    }

    renderCSVImport(channelTypes){
        let fields = [{key: 'artist_id', required: true, label: 'Artist ID'}],
            sampleFields = Object.assign([], fields);
        for(let channelID of Object.keys(channelTypes)) {
            fields.push({key: channelID, required: false, label: channelTypes[channelID]});
            sampleFields.push({key: channelTypes[channelID], required: false, label: channelTypes[channelID]});
        }
        return <div className="csv-import-holder"> 
            <div className="title-container">
                <h2 className="subheadline">Bulk Upload</h2>
            </div>
            <CSVImportModal fields={sampleFields} modalTitle="Import Channels" buttonClass="default-btn default-btn--light default-btn--small" buttonTitle="Choose File" onSubmit={this.csvImport} /> 
            <CSVSampleDownload fields={sampleFields} linkTitle="Download Template CSV" />
        </div>
    }

    render() {
        const { loaded } = this.state;
        let tableData = this.state.data,
            ids = [];
        const { items: channelTypes = {} } = this.props.channels;
        const { mode = 'full' } = this.props;
        
        const refresh = () => this.dispatchLoadData(this.state, true);

        return (
            <div>
                <div className="social-management">
                    <div className="socials-holder">
                        <div className="title-container">
                            <h2 className="subheadline social-channels-title">Social Channels</h2> <a onClick={() => this.exportToCsv()} title="Export CSV" className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                        </div>
                    
                        {mode == 'full' && <SearchWidget searchResult={this.searchResult} placeholder="Search Artists" mode="playlist" entities={['artists']} />}
                    </div>
                    <div className="bulk-upload-holder">
                        {this.renderCSVImport(channelTypes)}
                    </div>
                    <CellHeaderTable
                        manual
                        loading={this.props.artists.socialLoading}
                        loadingText={''} 
                        noDataText='There are no artists in current filter.'                        
                        className='simple-table'
                        data={tableData}
                        pages={this.state.pages}
                        defaultPageSize={this.getPageSize()}
                        showPageSizeOptions={false}
                        columns={socialManagementSettings.settingsForTable(channelTypes, refresh)}
                        defaultSorted={this.state.defaultSorted}
                        filtered={this.state.filter.filtered}
                        onFetchData={this.loadData}
                        defaultSortDesc={true}                        
                    />
                </div>
                <Helmet>
                    <title>Artists - Manage Social Media</title>
                    </Helmet>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        channels: state.channels,
        filter: state.filter
    }
}
export default withRouter(connect(mapStateToProps)(SocialManagementList))
