import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Helmet} from 'react-helmet';
import parser from 'url-parse';

import LoginForm from '../components/forms/login'
import { userActions } from '../data/actions/user'
import Spinner from '../components/common/Spinner'

class Login extends Component {
    constructor(props) {
        super(props);
        const { query : {back = '/'} } = parser(this.props.location.search, '', true);
        this.state = {
            redirect: back
        };
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    onSubmit(values){
        this.props.dispatch(userActions.login(values));
    }
    
    componentWillReceiveProps(props) {
        if(props.user.loggedIn && this.state.redirect)
            this.props.history.push(this.state.redirect);
    }

    render() {
        return (
            <div className="middle-box text-center">
                <div>
                    <div className="logo main-logo-container">
                        <div className="main-logo-img img" />
                        <h1 className="main-logo-text"></h1>
                    </div>
                    <div className="login-form">
                        <h3 className="login-form-title">Log in</h3>
                        <LoginForm onSubmit={this.onSubmit} />
                        <Spinner enabled={this.props.user.loading} />
                        <div className="link-container">
                            <a href="https://orthus.entertainment-intelligence.com/users/password/new" className="forgot-link" target="_blank">Forgot password?</a>
                            <a href="https://entertainment-intelligence.com/terms-and-privacy" className="terms-and-privacy" target="_blank">Terms and Privacy</a>
                        </div>
                    </div>
                </div>
                <Helmet>
                    <title>Login</title>
                </Helmet>
                
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}


export default connect(mapStateToProps)(withRouter(Login));