import React from 'react';
import { convertToThousands, round } from '../../../helpers/NumberFormatter';
import ConfirmClick from '../../common/ConfirmClick';

class FollowersCard extends React.Component {
    constructor(props){
        super(props);
    }
    
    
    render (){
        let { item, metric, deleteChannel } = this.props,
            value = item.channel_total ? convertToThousands(item.channel_total):'no tracked ';
        return  <div className="flex-block entity-item"><div className="widget-head-color-box entity-widget">
            <div className="delete-channel float-right">
                <ConfirmClick confirmClass="close-link" confirmAction={deleteChannel} title="remove channel" confirmLabel=" ×" />
            </div>
            <div className="title-block">
                <h2 className="font-bold no-margins entity-widget__title">
                    {item.channel_name}
                </h2>
            </div>
            <a className="social-widget-logo" href={item.channel_url} target="_blank"><i className={`social-icon social-icon--big ${item.channel_type.toLowerCase()}`} /></a>
            <div className="clearfix">
                <small className="entity-num-info followers-legend">{value} {metric}</small>
            </div>
        </div></div>;
    }
}


export default FollowersCard;