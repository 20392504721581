import { errorActions } from './error';
import { campaignsConstants } from '../constants/campaigns';
import { campaignsService } from '../services/campaigns';
import { campaignsFormatter } from '../formatters/campaigns';
import { find } from 'lodash';

export const campaignsActions = {
    getCampaigns,
    getCampaignDetails,
    addCampaign,
    editCampaign,
    deleteCampaign,
    addSalesChannel,
    addSocialChannel,
    deleteSalesChannel,
    deleteSocialChannel
};

function getCampaigns(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return campaignsService.getCampaigns(params, globalFilter)
            .then(
                campaigns => {
                    const data = campaignsFormatter.formatCampaigns(campaigns);
                    dispatch(success(data, campaigns.pages));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: campaignsConstants.GET_CAMPAIGNS_REQUEST } }
    function success(campaigns, pages) { return { type: campaignsConstants.GET_CAMPAIGNS_SUCCESS, campaigns, pages } }
    function failure(error) { return { type: campaignsConstants.GET_CAMPAIGNS_FAILURE, error } }
}

function getCampaignDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        campaignsService.getCampaignDetails(id, globalFilter)
            .then(
                campaign => {
                    campaign = campaignsFormatter.formatCampaign(campaign);
                    dispatch(success(id, campaign));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
 
    };

    function request() { return { type: campaignsConstants.GET_CAMPAIGN_DETAILS_REQUEST } }
    function success(id, campaign) { return { type: campaignsConstants.GET_CAMPAIGN_DETAILS_SUCCESS, id, campaign } }
    function failure(error) { return { type: campaignsConstants.GET_CAMPAIGN_DETAILS_FAILURE, error } }
}

function addCampaign(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        params.user_id = currentUser.id;
        
        campaignsService.addCampaign(params)
            .then(
                campaign => {
                    dispatch(success());
                    dispatch(campaignsActions.getCampaigns())
                    dispatch(errorActions.setSuccessMessage('campaign saved'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving campaign'))
                }
            );
 
    };
    function request() { return { type: campaignsConstants.ADD_CAMPAIGN_REQUEST } }
    function success() { return { type: campaignsConstants.ADD_CAMPAIGN_SUCCESS } }
    function failure() { return { type: campaignsConstants.ADD_CAMPAIGN_FAILURE } }

}

function editCampaign(params, id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        params.user_id = currentUser.id;
        
        campaignsService.editCampaign(params, id)
            .then(
                campaign => {
                    dispatch(success());
                    dispatch(campaignsActions.getCampaigns())
                    dispatch(errorActions.setSuccessMessage('campaign updated'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving campaign'))
                }
            );
 
    };
    function request() { return { type: campaignsConstants.ADD_CAMPAIGN_REQUEST } }
    function success() { return { type: campaignsConstants.ADD_CAMPAIGN_SUCCESS } }
    function failure() { return { type: campaignsConstants.ADD_CAMPAIGN_FAILURE } }

}

function deleteCampaign(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return campaignsService.deleteCampaign(id)
            .then(
                campaign => {
                    dispatch(success());
                    return dispatch(campaignsActions.getCampaigns())
                    dispatch(errorActions.setSuccessMessage('campaign removed'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving campaign'))
                }
            );
 
    };
    function request() { return { type: campaignsConstants.ADD_CAMPAIGN_REQUEST } }
    function success() { return { type: campaignsConstants.ADD_CAMPAIGN_SUCCESS } }
    function failure() { return { type: campaignsConstants.ADD_CAMPAIGN_FAILURE } }

}

function addSalesChannel(params, campaign) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        
        params = campaignsFormatter.formatNewChannel(params, campaign, currentUser);
        
        campaignsService.addSalesChannel(params, campaign)
            .then(
                result => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('Channel successfully saved'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error adding channel'))
                }
            );
    };

    function request() { return { type: campaignsConstants.ADD_CHANNEL_REQUEST } }
    function success(channel) { return { type: campaignsConstants.ADD_CHANNEL_SUCCESS, channel } }
    function failure() { return { type: campaignsConstants.ADD_CHANNEL_FAILURE} }
}

function addSocialChannel(params, campaign) {
    return dispatch => {
        dispatch(request());
        
        params = campaignsFormatter.formatNewSocialChannel(params);
        
        campaignsService.addSocialChannel(params, campaign)
            .then(
                result => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('Channel successfully saved'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error adding channel'))
                }
            );
    };

    function request() { return { type: campaignsConstants.ADD_CHANNEL_REQUEST } }
    function success(channel) { return { type: campaignsConstants.ADD_CHANNEL_SUCCESS, channel } }
    function failure() { return { type: campaignsConstants.ADD_CHANNEL_FAILURE} }
}


function deleteSalesChannel(id, campaignID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return campaignsService.deleteSalesChannel(id, campaignID)
            .then(
                result => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('Channel successfully deleted'))
                    return true;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error deleting channel'))
                }
            );
    };

    function request() { return { type: campaignsConstants.DELETE_CHANNEL_REQUEST } }
    function success() { return { type: campaignsConstants.DELETE_CHANNEL_SUCCESS } }
    function failure() { return { type: campaignsConstants.DELETE_CHANNEL_FAILURE} }
}

function deleteSocialChannel(id, campaignID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return campaignsService.deleteSocialChannel(id, campaignID)
            .then(
                result => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('Channel successfully deleted'))
                    return true;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error deleting channel'))
                }
            );
    };

    function request() { return { type: campaignsConstants.DELETE_CHANNEL_REQUEST } }
    function success() { return { type: campaignsConstants.DELETE_CHANNEL_SUCCESS } }
    function failure() { return { type: campaignsConstants.DELETE_CHANNEL_FAILURE} }
}
