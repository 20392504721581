import React from 'react';
import { withRouter } from 'react-router';
import config from '../../../config/config';

class LoginButton extends React.Component {
    render() {
        const path = this.props.history.location.pathname.replace('/share', '');
        const url = `${config.apis.base}${path}`;     
        return <div className="shared-login-button-holder"><a className="shared-login-button default-btn default-btn--small" href={url}>Log In</a></div>
    }
}

export default withRouter(LoginButton)