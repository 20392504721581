import React, { Component } from "react";
import { connect } from 'react-redux';

export default function(ComposedComponent) {
    class WithFilter extends Component {
        render() {
            const { global } = this.props.filter;
            if(!global){
                return null;
            }
            else {
                return <ComposedComponent {...this.props} />
            }
        }
    }

    function mapStateToProps(state) {
        return { 
            filter: state.filter
        };
    }

    return connect(mapStateToProps)(WithFilter);
}