import React, { Component } from 'react';
import { get } from 'lodash';
import EntityAutocomplete from '../../components/common/EntityAutocomplete';
import ReactTable from "react-table";
import { heartbeatsConstants } from '../../data/constants/heartbeats';
import Tooltip from '../../components/common/Tooltip';
import { convertToThousands } from '../../helpers/NumberFormatter';

import cellHeaderHOC from '../../components/common/Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);

export default class NotificationReport extends React.Component {
  state = {
    showInfoIcon: false,
    percentChangeOptions: [10, 20, 40, 60, 80, 100, 200, 300],
    minimumStreamsOptions: [1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000, 10000000],
    displayTooltip: false,
    selectedFrequency: 3
  }
  
  allowedFrequencies = {
      "threshold": [1,3],
      "minimum_streams": [1,3]
  }

  handleMouseEnter = () => {
    this.setState({
      showInfoIcon: false
    })
  }
  handleMouseLeave = () => {
    this.setState({
      showInfoIcon: false
    })
  }

  capitalize = s => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  hideTooltip = () => {
    this.setState({displayTooltip: false})
  }

  showTooltip = () => {
    this.setState({displayTooltip: true})
  }

  render(){
    const { onChangeSetting, onChangeEntity, onClickItem, onRemoveItem, inputValue, showDropdown, products, title, description, setting, frequencies, reportID, handleCloseSearch, onDeleteReport} = this.props;
    const { showInfoIcon } = this.state;
    const value = setting && Object.keys(setting.settings).reverse()[0];
    const productsColumns = [
      {
        id: 'artist',
        Header: 'Artist',
        accessor: 'artist',
        Cell: props => {
          return <div className="overflow-text">{props.original.artists[0].artist.name}</div>
        }
      },
      {
        id: 'product',
        Header: 'Product',
        Cell: props => {
          return <div className="overflow-text">{props.original.product.title}</div>
        }
      },
      {
        id: 'barcode',
        Header: 'Barcode',
        accessor: 'barcode',
        Cell: props => {
          return <div>{props.original.product.barcode}</div>
        }
      },
      {
        id: 'actions',
        accessor: 'id',
        className: 'remove-cell-btn',
        // width: 40,
        Cell: props => {
          return <a style={{color: '#ff1500'}} onClick={(e) => onRemoveItem(e, props.original, 'products', setting, value)} >X</a>
        }
      }
    ];

    const tooltipSetting = {
      1: { text: 'Release weeks to include in the report', placement: 'right'},
      2: { text: 'Percentage change in streams', placement: 'right'},
      4: { text: 'Minimum number of streams', placement: 'right'},
      3: { text: 'Average daily streams(per track)', placement: 'right'},
      5: { text: 'Percentage change in streams', placement: 'right'}
    };

    let filteredFrequencies = {};
    for(let frequencyCode in frequencies){
        const frequencyID = frequencies[frequencyCode];
        if(this.allowedFrequencies[value]!==undefined) {
            if(!this.allowedFrequencies[value].includes(frequencyID)) {
                continue;
            }
        }
        filteredFrequencies[frequencyCode] = frequencyID;
    }
    const frequencyValues = Object.values(filteredFrequencies);
    return(
      <div className="notificationReportRoot">
        <div className="notificationReportInfo">
          <h2>{title}</h2>
          <h4>{description}</h4>
          
        </div>
        <div className="notification-report-count">
          {
            ( reportID == 2 || reportID == 5 )
            ?
            <div className="notification-report-count-inner">
                {/*
                <select
                  className="percentSelect"
                  value={setting ? setting.settings.threshold : null}
                  onChange={(e) => onChangeSetting(e, setting, "threshold", "settings")}
                >
                {this.state.percentChangeOptions.map(option => {
                  return (
                    <option key={option} value={option} >
                      { option + heartbeatsConstants.PERCENT_SYMBOL }
                    </option>
                  );
                })}
                </select>
                */}
                <div className="notification-report-input-item">
                  <input type="number" defaultValue={(setting ? setting.settings.threshold : 50)}
                  onChange={(e) => onChangeSetting(e, setting, "threshold", "settings")} 
                  min={0} step={10} name={value}  
                  className="form-control percent-change-input"
                  /> %
                </div>
                <select
                    className="minimumStreamsSelect"
                    name={value} 
                    defaultValue={(setting ? setting.settings.minimum_streams : null)}
                    onChange={(e) => onChangeSetting(e, setting, "minimum_streams", "settings")}
                  >
                  {this.state.minimumStreamsOptions.map(option => {
                    return (
                      <option key={option} value={option} >
                        { convertToThousands(option) + '+' }
                      </option>
                    );
                  })}
                  </select>
              </div>
            :
            <input onChange={(e) => onChangeSetting(e, setting, value, "settings")} type="number" defaultValue={setting ? setting.settings[value] : 0 } min={1} name={value} className="form-control" />
          }
          { (reportID && (title !== 'Track Activity')) && <Tooltip position="bottom" message={tooltipSetting[reportID].text} tooltipClass="toolbar-title-tooltip"/>
          }
        </div>
        <div className="notification-report-set">
          <select onChange={(e) => onChangeSetting(e, setting, null, "frequency")} name={value} >
            {frequencyValues.map(value => {
              let frequency = Object.keys(frequencies)[parseInt(value)]
              return (
                <option selected={value === (setting && setting.frequency)} key={value} value={value} >
                  { this.capitalize(frequency) }
                </option>
              );
            })}
            <option selected={3 === (setting && setting.frequency)} key={frequencyValues.length} value={3}>
              Off - no email set
            </option>
          </select>
          {(setting && setting.id) && <a onClick={()=>onDeleteReport(setting.id)} className="default-btn default-btn--small delete-report-btn">Delete</a>}
        </div>

        { value === 'avg_streams' && (
          <div className="notification-report-table">
            <CellHeaderTable
              NoDataComponent={() => null}
              showPagination={false}
              resizable={false}
              pageSize={products.length}
              data={products}
              columns={productsColumns}
              className="report-table"
            />
            <EntityAutocomplete entities={products} value={inputValue} onChange={onChangeEntity} show={showDropdown} currentSetting={setting} reportName={value} onClickItem={onClickItem} entityName="product" entity={['products']} handleCloseSearch={handleCloseSearch} label="Products" />
          </div>
        )}
      </div>
    )
  }
}
