import React from 'react';
import { map } from 'lodash';

class CSVSampleDownload extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            linkRef: React.createRef()
        }
    }
    
    downloadCSV = (fields) => {
        const { linkRef } = this.state;
        const keys = map(fields, 'key');
        const placeholders = map(fields, 'placeholder');
        const csv = keys.join(',') + '\r\n' + placeholders.join(',');
        const fileData = new Blob([csv]);
        
        linkRef.current.href=URL.createObjectURL(fileData);
    }
    
        
    render() {
        const { linkTitle, linkClass, fields } = this.props,
            { linkRef } = this.state;
        
        return (
            <a ref={linkRef} onClick={()=>this.downloadCSV(fields)} className={linkClass} download='template.csv' type='text/csv'>{linkTitle}</a>
        )
    }
}

export default CSVSampleDownload