import React from 'react';
import ShortenedTitle from '../../pages/result/ShortenedTitle';

const labelSvg = require('!svg-inline-loader!../../../../public/img/icons/label.svg');
const filterSvg = require('!svg-inline-loader!../../../../public/img/saved-filter-icon.svg');
const editSvg = require('!svg-inline-loader!../../../../public/img/edit-filter.svg');
const deleteSvg = require('!svg-inline-loader!../../../../public/img/delete.svg');
const savedItemSvg = require('!svg-inline-loader!../../../../public/img/icons/products.svg');

export default function FilterList(props){
    const { currentFilter, filters = [], onSelect, actionButton, actionButtonClass } = props;
    if(!filters || !filters.length)
        return <p>No filters</p>;
        
    return <ul className="pinned-filter-list">{filters.map(filter=>{
        const filterClass = (currentFilter && filter.id == currentFilter.id) ? 'filter-selected':''; 
        const entitiesLength = filter.entities.length;
        const entitiesArr = filter && filter.entities && entitiesLength > 4 ? filter.entities.slice(0,4) : filter.entities;
        const filterImageClass = entitiesLength > 4 ? "image-4" : `image-${entitiesLength}`
        const filterActionIcon = actionButtonClass == 'recent' ? deleteSvg : editSvg; 

        return <li key={filter.id} className={`filter pinned-filter-list-item ${filterClass}`} onClick={()=>onSelect(filter)}>
            {/* <span className="small-icon" dangerouslySetInnerHTML={{__html: labelSvg}}></span> */}
            <div className={`saved-filter-item ${filterImageClass}`}>
                <div className="saved-filter-item-inner">
                    <span className="saved-filter-icon" dangerouslySetInnerHTML={{__html: savedItemSvg}}></span>
                    <div className="saved-filter-item-images">
                        { entitiesArr.map(entity => {
                            return entity.entity_image && entity.entity_image !== null && <div key={entity.id} className={`image`}><span className="image-inner"><img src={entity.entity_image} /></span></div>
                        })}
                    </div>
                    <span className="saved-filter-item-icon" dangerouslySetInnerHTML={{__html: filterSvg}}></span>
                </div>
                {actionButton && <span className={`saved-filter-item-action-button ${actionButtonClass}`} onClick={(e)=>actionButton(e, filter.id)}>
                    <span dangerouslySetInnerHTML={{__html: filterActionIcon}}/>
                    </span>}
                {/*<a className="edit-filter-holder" dangerouslySetInnerHTML={{__html: editSvg}}></a>*/}
            </div>
            <div className="saved-filter-item-text"><ShortenedTitle title={filter.caption} /></div>
            </li>
    })}</ul>
}
