import { splitByThousands, convertToThousands} from '../../../helpers/NumberFormatter';

export function formatAxesLabel(value, index, values){
    return convertToThousands(value, 1);
}

export function formatTooltipLabel(context){
    var valueLabel = context.dataset.label || '';
    return splitByThousands(context.raw) + (valueLabel ?  ` ${valueLabel}`: '') 
}

export function formatTooltipLabelWorldMap(label, value){
    return label + (value ? (': ' + splitByThousands(value)) : '');
}

export function formatTooltipLabelPie(context){
    var dataLabel = context.label,
        valueLabel = context.dataset.label,
        value = context.parsed;
    return `${dataLabel}: ${splitByThousands(value)} ${valueLabel}`;
}