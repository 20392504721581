import React from 'react';
import Filter from './Filter';
import { find, merge } from 'lodash';
import DropdownMenu from '../../common/DropdownMenu';
import vendorsArray from '../filter/enums/vendors';
import territoriesArray from '../filter/enums/territories.js';
import typesArray from './enums/types'
import { convertToThousands, addPercent } from '../../../helpers/NumberFormatter.js';

var tagSvg = require('!svg-inline-loader!../../../../public/img/tag.svg');
var arrowDownSvg = require('!svg-inline-loader!../../../../public/img/arrow-down.svg');

const headerSettings = [
    { id: "new_release", title: "Release Type: ", defaultValue: "false" },
    { id: "period_2_units", title: "Week Units: ", defaultValue: "1000" },
    { id: "tiktok_creations", title: "TikTok Creates: " },
    { id: "percent_change", title: "Percentage Change: ", defaultValue: "50" },
    { id: "vendors", title: "Vendors: " },
    { id: "territories", title: "Territories: " },
]

class FilterButton extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            filterComponentRef: React.createRef(),
            expanded: false,
            display: [],
        }
        this.getHeaderFilteredValue = this.getHeaderFilteredValue.bind(this)
        // this.showDeleteFilterBtn = this.showDeleteFilterBtn.bind(this)
    }

    
    toggleFilter = (event, force) => {
        this.setState({
            expanded: force !== undefined ? force : !this.state.expanded
        })
    }

    getFilteredValue = (filtered, key) => {
        let entity = find(filtered, item=>item.id == key);
        if (entity !== undefined) {
            let { value } = entity;
            if(Array.isArray(value))
                return value.length;
            else
                return value;
        }
    }

    getHeaderFilteredValue = (filtered, key) => {
        if (key == "period_2_units") {
            return convertToThousands(this.getFilteredValue(filtered, "period_2_units"))
        } else if (key == "percent_change") {
            return addPercent(this.getFilteredValue(filtered, key))
        } else if (key == "new_release") {
            const releaseTypesFilter = this.getFilteredValue(filtered, "new_release");
            const releaseLabel = releaseTypesFilter && find(typesArray, item => item.value == releaseTypesFilter);
            return releaseLabel ? releaseLabel.label : 'All'
        } else {
            return this.getFilteredValue(filtered, key)
        }
    }
    
    removeOption = (entity) => {
        this.state.filterComponentRef.current.removeOption(entity);
    }
        
    dropdownHeader = (filtered, entity, dropdown = true) => {
        const item = headerSettings.find(item => item.id === entity);
        const title = item.title;
        const count = item.count;
        
        let showDelete;
        const defaultValue = item.defaultValue;
        const value = this.getFilteredValue(filtered, entity)

        if (!defaultValue || defaultValue != value) {
            showDelete = true
        }

        return <span className="playlist-filter-text-item">
            <span className="playlist-filter-result-icon" dangerouslySetInnerHTML={{__html: tagSvg}}></span>
            {title} {this.getHeaderFilteredValue(filtered, entity)}
            {showDelete && <a className="close-link" onClick={() => this.removeOption(entity)}>&times;</a>}
            {dropdown && <span className="caret" dangerouslySetInnerHTML={{__html: arrowDownSvg}}></span>}
        </span>
    }

    showDropdownIcon(array) {
        return array.length ? true : false
    }

    renderSelected = (filtered) => {
        // vendors      
        const vendorFilter = find(filtered, item=>item.id == 'vendors');
        const vendorsArrayValues = vendorsArray.map(vendor=>({label: vendor.value, value: String(vendor.vend_id)}));

        const vendorValueToLabelMap = {};
        vendorsArrayValues.forEach(vendor => {
            vendorValueToLabelMap[vendor.value] = vendor.label;
        });

        const vendorFilterArray = vendorFilter && vendorFilter.value.map(value => vendorValueToLabelMap[value]);

        // territories
        const territoriesFilter = find(filtered, item => item.id == 'territories');

        const continentArray = Object.keys(territoriesArray);
        let continentRenderArray = [];

        if (territoriesFilter && territoriesFilter.value.length > 0) {
            for (const continent of continentArray) {
                let countriesLabelsArray = [];
                for (const country of territoriesArray[continent]) {
                    if (territoriesFilter && territoriesFilter.value.includes(country.value)) {
                        countriesLabelsArray.push(country.label);
                    }
                }
                
                if (countriesLabelsArray.length > 0) {
                    continentRenderArray.push({
                        continent: continent,
                        countries: countriesLabelsArray,
                    });
                }
            }
        }

        const showPercent = this.getFilteredValue(filtered, 'percent_change');
        const showUnits = this.getFilteredValue(filtered, 'period_2_units');
        const showTiktok = this.getFilteredValue(filtered, 'tiktok_creations');
        const showRelease = this.getFilteredValue(filtered, 'new_release');

        return <div>
        <div className="playlist-filter-text">
            
            {showUnits > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'period_2_units', false)} className="playlist-dropdown">
            </DropdownMenu>}
            {showTiktok > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'tiktok_creations', false)} className="playlist-dropdown">
            </DropdownMenu>}
            {showPercent > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'percent_change', false)} className="playlist-dropdown">
            </DropdownMenu>}
            <DropdownMenu header={this.dropdownHeader(filtered, 'new_release', false)} className="playlist-dropdown">
            </DropdownMenu>
            {vendorFilterArray && vendorFilterArray.length > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'vendors', this.showDropdownIcon(vendorFilterArray))} className="playlist-dropdown">
                {vendorFilterArray.map(item=><div className='playlist-filter-result-item'>{item}</div>)}
            </DropdownMenu>}
            {territoriesFilter && territoriesFilter.value.length > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'territories', this.showDropdownIcon(territoriesFilter.value))} className="playlist-dropdown territories">
                {continentRenderArray.map(item=>
                    <div className='playlist-filter-result-item'>
                        <DropdownMenu header={<div className="filtered-countries-header"><b>{item.continent}</b><span className="caret" dangerouslySetInnerHTML={{__html: arrowDownSvg}}></span></div>} className="filtered-countries-dropdown">
                            {item.countries.map(country => <div className="filtered-countries-item">{country}</div>)}
                        </DropdownMenu>
                    </div>
                )}
            </DropdownMenu>}
        </div></div>
    }

 
    onFilterChange = (filter) => {
        this.toggleFilter(null, false);
        this.props.onFilteredChangeCustom(filter);
    }
    
    render (){
        const { expanded, display, filterComponentRef } = this.state;
        const { filterState, filtered, disableReason, onFilteredChangeCustom, onApply } = this.props;   
        return <div className="heartbeats-filter-holder">
            <button className={`filter-toggle-btn btn default-btn`} onClick={this.toggleFilter}><span className="fa fa-plus"></span> Add Filter</button>
            {this.renderSelected(filtered, display)}
            <Filter filterState={filtered} disableReason={disableReason} expanded={expanded} onApply={onApply} ref={filterComponentRef} onFilteredChangeCustom={this.onFilterChange} />
        </div>
    }
}

export default FilterButton;