import React from 'react';
import { connect } from 'react-redux';

import AddMilestoneForm from '../../forms/addMilestone'
import { milestonesActions } from '../../../data/actions/milestones';

class AddMilestoneButton extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    
    componentDidMount() {
    }
    
    toggleModal = (showModal) =>{
        this.setState({showModal});
    }
    
    openForm = () => {
        const { milestones: {types = []} } = this.props;
        (types.length > 0 ? Promise.resolve() : this.props.dispatch(milestonesActions.getMilestoneTypes())).then(()=>this.toggleModal(true));
    }
    
    onSubmit = (formData) => {
        const { onSubmit, entity, id, dispatch } = this.props;
        dispatch(milestonesActions.addMilestone(null, formData))
            .then(({milestone}) => dispatch(milestonesActions.linkAudienceMilestone(entity, id, milestone.id)))
            .then(({milestone}) => {
                this.toggleModal(false);
                return onSubmit ? onSubmit(milestone) : ()=>{};
            })

    }
        
    render() {
        const { buttonTitle, buttonClass, milestones: {types = []} } = this.props;
        const { showModal } = this.state;
        return (
            <span className="add-milestone-btn">
                <AddMilestoneForm show={(this.state.showModal && types.length > 0)} title="Add Milestone" onSubmit={this.onSubmit} onClose={(e)=>this.toggleModal(false)} milestoneTypes={types} fileUpload={false} />
                <button title="Add Milestone" className={buttonClass} onClick={()=>this.openForm()}>{buttonTitle}</button>
            </span>
        )
    }
}
function mapStateToProps(state) {
    return {
        milestones: state.milestones,
    } 
}

export default connect(mapStateToProps)(AddMilestoneButton)