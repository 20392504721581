export const mailingListsConstants = {
    GET_MAILING_LISTS_REQUEST: 'GET_MAILING_LISTS_REQUEST',
    GET_MAILING_LISTS_SUCCESS: 'GET_MAILING_LISTS_SUCCESS',
    GET_MAILING_LISTS_FAILURE: 'GET_MAILING_LISTS_FAILURE',
    GET_MAILING_LIST_DETAILS_REQUEST: 'GET_MAILING_LIST_DETAILS_REQUEST',
    GET_MAILING_LIST_DETAILS_SUCCESS: 'GET_MAILING_LIST_DETAILS_SUCCESS',
    GET_MAILING_LIST_DETAILS_FAILURE: 'GET_MAILING_LIST_DETAILS_FAILURE',
    ADD_MAILING_LIST_REQUEST: 'ADD_MAILING_LIST_REQUEST',
    ADD_MAILING_LIST_SUCCESS: 'ADD_MAILING_LIST_SUCCESS',
    ADD_MAILING_LIST_FAILURE: 'ADD_MAILING_LIST_FAILURE',
    GET_MAILING_LIST_RECIPIENTS_REQUEST: 'GET_MAILING_LIST_RECIPIENTS_REQUEST',
    GET_MAILING_LIST_RECIPIENTS_SUCCESS: 'GET_MAILING_LIST_RECIPIENTS_SUCCESS',
    GET_MAILING_LIST_RECIPIENTS_FAILURE: 'GET_MAILING_LIST_RECIPIENTS_FAILURE',
    ADD_MAILING_LIST_RECIPIENT_REQUEST: 'ADD_MAILING_LIST_RECIPIENT_REQUEST',
    ADD_MAILING_LIST_RECIPIENT_SUCCESS: 'ADD_MAILING_LIST_RECIPIENT_SUCCESS',
    ADD_MAILING_LIST_RECIPIENT_FAILURE: 'ADD_MAILING_LIST_RECIPIENT_FAILURE',
    ADD_RECIPIENT_REQUEST: 'ADD_RECIPIENT_REQUEST',
    ADD_RECIPIENT_SUCCESS: 'ADD_RECIPIENT_SUCCESS',
    ADD_RECIPIENT_FAILURE: 'ADD_RECIPIENT_FAILURE',
    GET_MAILING_LISTS_BY_RECIPIENT_REQUEST: 'GET_MAILING_LISTS_BY_RECIPIENT_REQUEST',
    GET_MAILING_LISTS_BY_RECIPIENT_SUCCESS: 'GET_MAILING_LISTS_BY_RECIPIENT_SUCCESS',
    GET_MAILING_LISTS_BY_RECIPIENT_FAILURE: 'GET_MAILING_LISTS_BY_RECIPIENT_FAILURE',
    
}