import { gridsConstants } from '../constants/grids';

export function grids(state = {}, action) {
    switch (action.type) {
        case gridsConstants.GET_GRIDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case gridsConstants.GET_GRIDS_SUCCESS:
            return {
                
                ...state,
                loading: false,
                items: action.grids
            };
        case gridsConstants.GET_GRIDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case gridsConstants.ADD_GRID_REQUEST:
        case gridsConstants.SAVE_DEFAULT_GRID_REQUEST:
            return {
                ...state,
                gridLoading: true
            };
        case gridsConstants.ADD_GRID_SUCCESS:
        case gridsConstants.SAVE_DEFAULT_GRID_SUCCESS:            
            return {
                
                ...state,
                gridLoading: false,
            };
        case gridsConstants.ADD_GRID_FAILURE:
        case gridsConstants.SAVE_DEFAULT_GRID_FAILURE:            
            return {
                ...state,
                gridLoading: false,
            };

            
        default:
            return state
    }
} 