import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import Map from './Map';
import Switcher from './Switcher';
import PropTypes from 'prop-types';
import TerritorySearchPanel from './TerritorySearchPanel';
import { searchActions } from '../../data/actions/search';
import { mapsActions } from '../../data/actions/maps';
import OlView from 'ol/View';
import OlMap from 'ol/Map';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import LayerVector from 'ol/layer/Vector';
import Heatmap from 'ol/layer/Heatmap';
import Tile from 'ol/layer/Tile';
import Group from 'ol/layer/Group';
import OlLayerTile from 'ol/src/layer/Tile';
import Stamen from 'ol/source/Stamen';
import SourceVector from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Overlay from 'ol/Overlay';
import { createStringXY } from 'ol/coordinate';
import OlSourceOSM from 'ol/src/source/OSM';
import * as olProj from 'ol/proj';
import pin from '../../../public/img/pin.png';
import Spinner from '../../components/common/Spinner';
import store from '../../helpers/store'
import { pick, uniqBy } from 'lodash';
import { convertToThousands } from '../../helpers/NumberFormatter';
import countriesByGetCoordinates from 'country-json/src/country-by-geo-coordinates.json';


const DEFAULT_ENTITIES_LIMIT = 10;
const MAIN_ENTITIES = ['artists'];

const MIN_DEFAULT_ZOOM = 2.2;
const MAX_DEFAULT_ZOOM = 2.8;

const DEFAULT_ZOOM_RANGE = { min: MIN_DEFAULT_ZOOM, max: MAX_DEFAULT_ZOOM };

class TerritoryAnalysis extends React.Component {
  constructor(props){
    super(props);
    this.state = this._defaultState();
  }

  componentDidUpdate(prevProps){
    if(this.props.maps.artistStreamsByCountries.length !== prevProps.maps.artistStreamsByCountries.length){
      this.setState({ streams: this.props.maps.artistStreamsByCountries, showNoDataMessage: false });
      this.renderMap(this.props.maps.artistStreamsByCountries);
      this.initPointerMoveEvent(this.olmap, this.overlay, { region: true, country: false });
      this.initClickEvent(this.olmap, { region: true, country: false });
    }
    if(this.props.maps.artistStreamsByRegions.length !== prevProps.maps.artistStreamsByRegions.length){
      this.setState({ streams: this.props.maps.artistStreamsByRegions, showNoDataMessage: false });
      this.renderMap(this.props.maps.artistStreamsByRegions);
      this.initPointerMoveEvent(this.olmap, this.overlay, { region: false, country: true });
      this.removeClickEvent(this.olmap, this.overlay, { region: false, country: true });
    }
    if(this.props.maps.artistStreamsByRegions.length !== prevProps.maps.artistStreamsByRegions.length && this.props.maps.artistStreamsByRegions.length === 0){
      this.renderEmptyDataMessage();
    }
  }

  componentDidMount() {
    this.raster = new Tile({
      name: 'Tile',
      source: new Stamen({
        layer: 'toner'
      })
    });

    this.heatmapVector = new Heatmap({
      name: 'Heatmap',
      source: new SourceVector()
    });

    this.vectorSource = new LayerVector({
      name: 'LayerVector',
      source: new SourceVector()
    });

    this.overlay = new Overlay({
      element: document.getElementById('popup'),
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    this.defaultLayer = new OlLayerTile({
      name: 'OlLayerTile',
      source: new OlSourceOSM()
    });

    this.olmap = new OlMap({
      target: 'map',
      overlays: [this.overlay],
      layers: [ this.defaultLayer, this.vectorSource ],
      view: new OlView({
        center: this.state.center,
        zoom: this.state.zoom
      })
    });
  }

  renderEmptyDataMessage = () => {
    const countryWithCenter = countriesByGetCoordinates.find( data => data.country === this.state.streamData.country);

    const lat = (parseFloat(countryWithCenter.north) + parseFloat(countryWithCenter.south)) / 2;
    const long = (parseFloat(countryWithCenter.east) + parseFloat(countryWithCenter.west)) / 2;

    const element = document.getElementById('no-region-results-map');
    this.setState({ showNoDataMessage: true });
    element.innerHTML = 'There is no regional statistic.';
    const coordinates = [long, lat];
    const message = new Overlay({
      position: olProj.transform(coordinates, 'EPSG:4326', 'EPSG:3857'),
      element,
      stopEvent: false
    });
    this.olmap.addOverlay(message);
  }


  renderMap = streams => {
    if(this.state.showHeatMap){
      this.renderHeatMap(streams, [this.raster, this.heatmapVector]);
    } else {
      this.renderDefaultMap(streams, [this.defaultLayer, this.vectorSource]);
    }
  }

  clearAllPins = () => {
    let features = this.vectorSource.getSource().getFeatures();
    features.forEach((feature) => {
      this.vectorSource.getSource().removeFeature(feature);
    });
  }

  clearHeatMap = () => {
    let features = this.heatmapVector.getSource().getFeatures();
    features.forEach((feature) => {
      this.heatmapVector.getSource().removeFeature(feature);
    });
  }

  buildPins = (streams=[]) => {
    for( let i = 0; i < streams.length; i++) {
      if(streams[i].long && streams[i].lat){
        const coordinates = [parseFloat(streams[i].long.toFixed(4)), parseFloat(streams[i].lat.toFixed(4))];
        const marker = new Feature({
          geometry: new Point(olProj.transform(coordinates, 'EPSG:4326', 'EPSG:3857')),
          stream: streams[i],
          population: 4000,
          rainfall: 500
        });
        marker.setStyle(new Style({
          image: new Icon(({
            anchor: [0.5, 36],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            opacity: 1,
            src: pin,
            zIndex: 1
          })),
          zIndex: 1
        }));
        this.vectorSource.getSource().addFeature(marker);
      }
    }
  }

  buildHeats = (streams=[]) => {
    for( let i = 0; i < streams.length; i++) {
      if(streams[i].long && streams[i].lat){
        const coordinates = [parseFloat(streams[i].long.toFixed(4)), parseFloat(streams[i].lat.toFixed(4))];
        const heat = new Feature({
          stream: streams[i],
          geometry: new Point(olProj.transform(coordinates, 'EPSG:4326', 'EPSG:3857')),
          weight: streams.locs_streams
        });
        this.heatmapVector.getSource().addFeature(heat);
      }
    }
  }

  layerRemover = layers => {
    this.olmap.setLayerGroup(new Group());
  }

  layerSetter = layers => {
    this.olmap.getLayers().extend(layers);
  }

  componentWillUnmount() {
    this.olmap.setTarget(null);
  }

  _getTextContent = (stream, country, options) => {
    if(!options.country){
      return `<div>Country: ${country}</div> \n
              <div>Streams: ${convertToThousands(stream.locs_streams)}</div>`;
    } else {
      return `<div> State: ${stream.state} </div> \n
              <div> Streams: ${convertToThousands(stream.streams_total)}</div> \n
              <div> Unique users: ${convertToThousands(stream.unique_users)}</div> \n
              <div> Percent: ${stream.percent}</div> \n
              <div> District: ${stream.district} </div>`;
    }
  }

  onDisplayTooltip = (evt, map, overlay, options) => {
    const pixel = evt.pixel;
    const tooltip = document.getElementById('popup');
    const feature = map.forEachFeatureAtPixel(pixel, f => { return f });

    if (feature) {
      const stream = feature.get('stream');

      tooltip.style.display = '';
      overlay.setPosition(evt.coordinate);
      tooltip.innerHTML = this._getTextContent(stream, stream.country, options);
    } else {
      tooltip.style.display = 'none';
    }
  }

  getRegionData = (evt, stream, options) => {
    this.clearAllPins();
    this.handleCountryClick(evt, stream, options);
    this.onIncreaseResolutionOnCountry(this.olmap, stream);
  }

  onHandlePinClick = (evt, map, options) => {
    const feature = map.forEachFeatureAtPixel(evt.pixel, f => { return f });
    if(feature){
      const stream = feature.get('stream');
      this.clearAllPins();
      this.handleCountryClick(evt, stream, options);
      this.onIncreaseResolutionOnCountry(this.olmap, stream);
    }
  }

  initClickEvent = (map, options) => {
    map.on('click', evt => this.onHandlePinClick(evt, map, options));
  }

  initPointerMoveEvent = (map, overlay, options) => {
    map.on('pointermove', evt => this.onDisplayTooltip(evt, map, overlay, options));
  }

  initMoveEndEvent = map => {
    map.on('moveend', e => {
      const currZoom = map.getView().getZoom();

      if (currZoom > DEFAULT_ZOOM_RANGE.min && currZoom < DEFAULT_ZOOM_RANGE.max) {
        const options = { region: true, country: false };

        this.clearAllPins();
        this.buildPins(this.props.maps.artistStreamsByCountries);
        this.initClickEvent(this.olmap, options);
        this.initPointerMoveEvent(this.olmap, this.overlay, options);
      }
    });
  }

  removeClickEvent = (map, options) => {
    map.removeEventListener('click');
    delete map.listeners_.click;
  }

  onIncreaseResolutionOnCountry = (map, streamData) => {
    const coordinates = [streamData.long, streamData.lat];
    map.getView().setZoom(6);
    map.getView().setCenter(olProj.transform(coordinates, 'EPSG:4326', 'EPSG:3857'));
  }

  mapInitiator = layers => {
    this.layerRemover();
    this.layerSetter(layers);
    this.clearAllPins();
    this.clearHeatMap();
  }

  renderHeatMap = (streams=[], layers=[]) => {
    this.mapInitiator(layers);
    this.buildHeats(streams);
  }

  renderDefaultMap = (streams=[], layers=[]) => {
    this.mapInitiator(layers);
    this.buildPins(streams);
  }

  _defaultState = () => {
    return {
      searchPanelData: [
        { iconClass: "fas fa-headphones", show: false, title: "Select Artist", searchField: true},
        { iconClass: "fas fa-music", show: false, title: "Territories", territoryField: true },
        { iconClass: "fas fa-city", show: false, title: "State/Province", regionField: true },
      ],
      activeAccordionIndex: 0,
      searchValue: '',
      showSearchDropDown: false,
      artist: {},
      showNoDataMessage: false,
      streamData: {},
      countries: [],
      showSwitcherButtons: false,
      showHeatMap: false,
      showDefaultMap: true,
      center: [0, 0],
      zoom: 1
    };
  }

  onShowHeatMap = () => {
    this.renderHeatMap(this.state.streams, [this.raster, this.heatmapVector]);
    this.setState({ showHeatMap: true, showDefaultMap: false });
  }

  onShowDefaultMap = () => {
    this.renderDefaultMap(this.state.streams, [this.defaultLayer, this.vectorSource]);
    this.setState({ showDefaultMap: true, showHeatMap: false });
  }

  onShowSwitcherButtons = () => {
    this.setState({
      showSwitcherButtons: !this.state.showSwitcherButtons
    });
  }

  handleCountryClick = (event, streamData, options) => {
    const params = {id: streamData.artist_id, ...streamData};
    this.onHandleArtistStreamsRequest(params, options);
    this.setState({
      streamData
    });
  }

  _onGetObjectByCertainKeys = (object, keys) => {
    return pick(object, keys);
  }

  handleCountryRequest = (params, currentFilter) => {
    const requestParams = {
      id: params.id,
      ei_client_code: this.props.user.data_silo_codes,
      date_from: currentFilter.dateStart,
      date_to: currentFilter.dateEnd,
    };
    this.props.onGetArtistStreamsByCountry(requestParams);
  }

  handleRegionRequest = (params, currentFilter) => {
    const requestParams = {
      id: params.id,
      ei_client_code: this.props.user.data_silo_codes,
      date_from: currentFilter.dateStart,
      date_to: currentFilter.dateEnd,
      iso2: params.alpha_2_country_code,
      iso3: params.alpha_3_country_code,
    };
    this.props.onGetArtistStreamsByRegion(requestParams);
  }

  onHandleArtistStreamsRequest = (params, options) => {
    const currentFilter = store.get('currentFilter');

    if(options.country){
      this.handleCountryRequest(params, currentFilter);
    }
    if(options.region){
      this.handleRegionRequest(params, currentFilter);
    }
  }

  setMap = artist => {
    this.setState({ showSearchDropDown: false, searchValue: artist.name, artist });
  }

  setSearch = e => {
    const showSearchDropDown = e.target.value.length < 3;
    this.setState({ ...this.state, searchValue: e.target.value, showSearchDropDown: !showSearchDropDown });
    this.props.onGetAutocomplete(e.target.value, DEFAULT_ENTITIES_LIMIT, MAIN_ENTITIES);
  }

  resetSearch = () => {
    this.setState(this._defaultState())
  }

  showHeaderContent = (header, index) => {
    header.show = !header.show;
    this.setState({
      searchPanelData: [
        ...this.state.searchPanelData.slice(0, index),
        { ...this.state.searchPanelData[index], header },
        ...this.state.searchPanelData.slice(index + 1)
      ],
      activeAccordionIndex: index
    });
  }

  render(){
    const spinnerClassName = this.state.showDefaultMap ? "spinner" : "white-spinner";
    return(
      <div className="root-territories" >
        <TerritorySearchPanel
          onHandleShowClick={this.onHandleArtistStreamsRequest}
          onGetRegionData={this.getRegionData}
          rootClassName="root-territory-search-panel"
          searchStaticData={this.state.searchPanelData}
          onShowHeaderContent={this.showHeaderContent}
          setSearch={this.setSearch}
          searchValue={this.state.searchValue}
          searchData={this.props.search}
          showSearchDropDown={this.state.showSearchDropDown}
          setMap={this.setMap}
          resetSearch={this.resetSearch}
          artistStreamsByCountries={this.props.maps.artistStreamsByCountries}
          artistStreamsByRegions={this.props.maps.artistStreamsByRegions}
          artist={this.state.artist}
          onHandleCountryClick={this.handleCountryClick}
          streamData={this.state.streamData}
          activeAccordionIndex={this.state.activeAccordionIndex}
        />
        {
          <div className="map-switcher-container">
            {this.props.maps.artistStreamsByCountries.length === 0 && this.props.maps.success && <span className="no-results-map"> There are no results. Change the date or try the other artist.</span>}
            <span id="no-region-results-map" className={this.state.showNoDataMessage ? "no-results-map" : "no-results-map-hidden"} > </span>
            <Switcher showDefaultMap={this.state.showDefaultMap} onShowDefaultMap={this.onShowDefaultMap} showHeatMap={this.state.showHeatMap} onShowHeatMap={this.onShowHeatMap} show={this.state.showSwitcherButtons} showHandler={this.onShowSwitcherButtons} />
            <Map />
            <div className="map-spinner">
              <Spinner className={spinnerClassName} enabled={this.props.maps.loading} />
            </div>
          </div>
        }
        <Helmet>
            <title>Territories - Analytics</title>
        </Helmet>
        
      </div>
    )
  }
}

TerritoryAnalysis.defaultProps = {
  maps: {
    artistStreamsByRegions: [],
    artistStreamsByCountries: []
  }
}

TerritoryAnalysis.propTypes = {
  maps: PropTypes.shape({
    artistStreamsByCountries: PropTypes.array,
    artistStreamsByRegions: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool,
    regionLevel: PropTypes.bool,
    countryLevel: PropTypes.bool
  }),
};

function mapDispatchToProps(dispatch){
  return {
    onGetAutocomplete: (value, limit, entities) => dispatch(searchActions.getSearchAutocomplete(value, limit, entities)),
    onGetArtistStreamsByCountry: (params) => dispatch(mapsActions.getArtistStreamsByCountry(params)),
    onGetArtistStreamsByRegion: (params) => dispatch(mapsActions.getArtistStreamsByRegion(params))
  }
}

function mapStateToProps( state ) {
  return {
    search: state.search,
    maps: state.maps,
    user: state.user.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryAnalysis);
