import { mapsConstants } from '../constants/maps';

export const initialState = {
  artistStreamsByCountries: [],
  artistStreamsByRegions: [],
  loading: false,
  error: false,
  success: false,
  regionSuccess: false,
  regionLevel: false,
  countryLevel: false,
}

export function maps( state=initialState, action={} ){
  const { type, payload } = action;
  switch (type) {
    case mapsConstants.GET_ARTISTS_STREAMS_MAPS_REQUEST:
      return { ...state, loading: true, error: false, success: false, artistStreamsByRegions: [], artistStreamsByCountries: [] };
    case mapsConstants.GET_ARTISTS_STREAMS_MAPS_SUCCESS:
      return { ...state, loading: false, error: false, success: true, regionLevel: false, countryLevel: true, artistStreamsByRegions: [], artistStreamsByCountries: payload };
    case mapsConstants.GET_ARTISTS_STREAMS_MAPS_FAILURE:
      return { ...state, loading: false, error: true, success: false, error: payload };
    case mapsConstants.GET_ARTISTS_STREAMS_BY_REGION_MAPS_REQUEST:
      return { ...state, loading: true, error: false, regionSuccess: false };
    case mapsConstants.GET_ARTISTS_STREAMS_BY_REGION_MAPS_SUCCESS:
      return { ...state, loading: false, error: false, regionSuccess: true, regionLevel: true, countryLevel: false, artistStreamsByRegions: payload};
    case mapsConstants.GET_ARTISTS_STREAMS_BY_REGION_MAPS_FAILURE:
      return { ...state, loading: false, error: true, regionSuccess: false, error: payload };

    default:
      return state;
  }
}
