import React, { Component } from 'react';

const Widget = React.forwardRef(({children, widgetID, widgetProps, ...props}, ref) => {

    const { widgetState, modeChange: parentModeChange, showChange } = widgetProps;
    let show = true, mode = '';
    if(widgetState.hasOwnProperty(widgetID)) {
        //{ show, mode } = widgetState[widgetID];
        show = widgetState[widgetID].show;
        mode = widgetState[widgetID].mode;
    }
    if(!show)
        return null;
    
    const { showChangeEnabled } = widgetState;
    return <React.Fragment>
        <div className={`widget-item ${props.className}`}>
            {showChangeEnabled && <div className="hide-widget-button">
                <a className="" onClick={()=>showChange(widgetID, false)} title="Hide component"><span className="fas fa-times"></span></a>
            </div>}
            {React.cloneElement(children, { defaultMode: mode, modeChange: (mode)=>{parentModeChange(widgetID, mode)} })}
        </div>
    </React.Fragment>;
});

export default Widget;