import React, { Component } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {  isEqual, find, debounce } from 'lodash';
import {Helmet} from 'react-helmet';
import { usersSettings } from '../../../components/pages/users/Settings';

import { usersActions } from '../../../data/actions/users';


class UsersList extends Component {
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.onResend = this.onResend.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
        this.refreshList = debounce(this.refreshList, 30000);
        this.state = {
            defaultSorted: [{id: 'invitation_sent_at', desc: true}],
        }
    }
    
    componentDidMount() {
        this.props.dispatch(usersActions.getUsers());
    }
    
    refreshList() {
        this.props.dispatch(usersActions.getUsers());
    }
    
    onDelete(email) {
        this.props.dispatch(usersActions.deleteUser(email));
    }
    
    onResend(id) {
        this.props.dispatch(usersActions.resendInvitation(id));
    }
    
    onResetPassword(id) {
        this.props.dispatch(usersActions.resetPassword(id));
    }
    
    /*
    componentWillReceiveProps(nextProps) {
        const hasACLSaving = find(nextProps.users.items, {acl_saving: true});
        if(hasACLSaving){
            this.refreshList();
        }
    }
    */

    handleRefresh(hasPendingUsers){
        if(hasPendingUsers) {
            this.refreshList();
        }
    }
    
    
    render() {
        const { items, listLoading } = this.props.users;
        return (
            <div className="users-management">
                <Link className="btn default-btn add-user-btn" to="/admin/users/new"><i className="fas fa-plus"></i>Create User</Link>
                <ReactTable  
                    loading={listLoading}
                    className='catalogue-table-holder user-management-table simple-table'
                    data={items}          
                    defaultPageSize={10}
                    filterable
                    defaultFilterMethod={(filter, row) => {return String(row[filter.id]).match(new RegExp(filter.value, 'i'))}}
                    defaultSorted={this.state.defaultSorted}
                    columns={usersSettings.settingsForTable(this.onDelete, this.onResend, this.onResetPassword)}
                    showPagination={true}
                >
                {(state, makeTable, instance) => {
                    const hasPendingUsers = state.pageRows.reduce((prev, curr)=>prev?prev:curr.links, false);
                    this.handleRefresh(hasPendingUsers);
                    return makeTable();
                  }}
                </ReactTable>
                <Helmet>
                    <title>User Management - Admin</title>
                </Helmet>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        users: state.users
    } 
}

export default connect(mapStateToProps)(UsersList);
