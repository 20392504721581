import { catalogueExceptionsConstants } from '../constants/catalogue_exceptions';

export function catalogueExceptions(state = {}, action) {
    switch (action.type) {
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                exceptions: null
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                exceptions: action.exceptions,
                pages: action.pages
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
    
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_COUNT_REQUEST:
            return {
                ...state,
                loadingCount: true
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_COUNT_SUCCESS:
            return {
                ...state,
                loadingCount: false,
                count: action.exceptions.data
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_COUNT_FAILURE:
            return {
                ...state,
                loadingCount: false,
                error: action.error
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                exceptions: null
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                exceptions: action.exceptions,
            };
        case catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state
    }
} 