import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        // fontStyle: 'bold',
    }
});


const TableFooter = ({items, columns}) => {
    return null;
    return(    
        <View style={styles.row}>
        </View>
    )
};
  
export default TableFooter