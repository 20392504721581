import { map, orderBy, filter } from 'lodash';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';

export const userEntityFiltersFormatter = {
    formatUserEntityFilters,
    formatNewFilter,
    formatRecentFilter,
    formatTitle,
    stringifyFilter
};

function filterContainsPlaylists(filter) {
	for(let entity of filter.entities) {
		if(entity.entity_type == 'playlists') {
			return true;
		}
	}
	
	return false;
}

function formatUserEntityFilters({data}, currentUser){
    let allFilters =  data.filter(item => { 
		return item.filter_type == 'user';
	 }).map(item=>{
        let entityIDs = []; 
        let summary = [];
        
        for(let entity of item.entities) {
            entity.entity_image = ImageURL(entity.entity_image, entity.entity_id, entity.entity_type);
            entity.logo = icons.entities[entity.entity_type];
            entityIDs.push(entity.entity_id);
            summary.push(entity.entity_title)
        }
        item.owner = item.user_id == currentUser.id;
        item.summary = summary.join(', ');
        item.entityIDs = entityIDs.sort();
        return item;
    });
    
    let playlistFilters = [],
        filters = [];
        
    for(let filter of allFilters) {
        if(filterContainsPlaylists(filter)) {
            playlistFilters.push(filter);
        }
        else {
            filters.push(filter);
        }
    }
    
    return { filters, playlistFilters };
}

function formatNewFilter(params) {
    return {
        caption: params.caption,
        notes: params.notes || '',
        filter_type: 'user',
        is_shared: false,
        is_pinned: false,
        is_recent: params.is_recent || false
    }
}


function formatRecentFilter(metadata, title) {
    return {
        caption: title,
        is_recent: true,
        entities: metadata.map(item=>({
           entity_id: item.id,
           entity_type: item.entity,
           entity_title: item.name,
           entity_image: item.image,
           fav_hide: item.favHide
        }))
    }
}

function formatTitle(entities, nameKey) {
    const threshold = 2;
    const overThreshold = entities.length - threshold;
    
    let title = map(entities.slice(0, threshold), nameKey).join(', ');
    if(overThreshold > 0)
        title += ` + ${overThreshold}`;

    return title;
}

function stringifyFilter({entities}) {
    let filter = {};
    for(let entity of entities){
        const { fav_hide, entity_type, entity_id } = entity;
        const filterKey = ( fav_hide == 'favorite' ) ? entity_type : `exclude_${entity_type}`;
        //if(fav_hide == 'favorite') {
            if(!filter.hasOwnProperty(filterKey))
                filter[filterKey] = [];
            filter[filterKey].push(entity_id);
        //}
    }
    return JSON.stringify(filter);

}