import { errorConstants } from '../constants/error';

export const errorActions = {
    setSuccessMessage,
    setErrorMessage,
    clearErrorMessage
};

function setSuccessMessage(message, title='') {
    return dispatch => {
        dispatch(set(message));
    };

    function set(message) { return { type: errorConstants.SET_SUCCESS_MESSAGE, message, title} }
}

function setErrorMessage(message, title='') {
    return dispatch => {
        dispatch(set(message));
    };

    function set(message) { return { type: errorConstants.SET_ERROR_MESSAGE, message, title} }
}

function clearErrorMessage() {
    return dispatch => {
        dispatch(set());
    };

    function set() { return { type: errorConstants.CLEAR_ERROR_MESSAGE } }
}