import { map } from 'lodash'
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';
import countriesByGetCoordinates from 'country-json/src/country-by-geo-coordinates.json';
import { uniqBy } from 'lodash';
import countries from '../countries_data.json';

export const mapsFormatter = {
    formatMapParams,
    formatMapResponse,
    formatResponseForCountryLevel,
    formatResponseCoordinates
};

const SEPARATOR = ',';
const MIN_SILO_COUNT = 1;
const DEFAULT_SILO_INDEX = 0;

function calculateCoordinates(coordinates, res) {
  return coordinates.map(countryData => {
    const lat = (parseFloat(countryData.north) + parseFloat(countryData.south)) / 2;
    const long = (parseFloat(countryData.east) + parseFloat(countryData.west)) / 2;
    return { country: countryData.country, long, lat};
  });
}

function concatData(dataWithCoordinates, streamData) {
return streamData.map( stream => {
    const countryData = countriesByAbbreviation.find( country => stream.country === country.abbreviation);
    if(countryData){
      const dataWithCoordinatesIndex = dataWithCoordinates.findIndex(e => e.country === countryData.country);
      const alpha_3_country_code = countries.find( country => country['alpha-2'] === stream.country)['alpha-3'];
      return {...stream, alpha_3_country_code, alpha_2_country_code: stream.country, ...dataWithCoordinates[dataWithCoordinatesIndex] }
    }
  }).filter( stream => stream !== undefined);
}

function handleCoordinates(streamsData) {
  const listOfResponseCountries = streamsData.map( ({country}) => country);
  const listOfAbbreviationCountries = countriesByAbbreviation.filter(abbreviation => listOfResponseCountries.includes(abbreviation.abbreviation) );


  const listOfAbbreviationNames = listOfAbbreviationCountries.map( ({country}) => country);
  const dataWithCoordinates = countriesByGetCoordinates.filter( countryWithGeo => listOfAbbreviationNames.includes(countryWithGeo.country) );
  const calculatedCoordinates = calculateCoordinates(dataWithCoordinates);

  return concatData(calculatedCoordinates, streamsData);
}

function reduceLocaleStreams(data){
  return data.map( st => {
    const certainCountryData = data.filter( stream => stream.country === st.country);
    const reducer = (accumulator, currentValue) => { return { locs_streams: accumulator.locs_streams + currentValue.locs_streams } };
    const result = certainCountryData.reduce(reducer);
    return { ...st, locs_streams: result.locs_streams};
  })
}

function formatResponseForCountryLevel(response) {
  const data = reduceLocaleStreams(response);
  const uniqStreamsByCountry = uniqBy(data, e => e.country );
  const coordinates = handleCoordinates(uniqStreamsByCountry);
  return coordinates;
}

function formatMapParams(params, necessarySilo=null){
    const siloCodeCollection = params.ei_client_code.split(SEPARATOR);
    if(siloCodeCollection.length > MIN_SILO_COUNT){
      const indexOfNecessary = siloCodeCollection.indexOf(necessarySilo);
      const silo_code = indexOfNecessary !== -1 ? siloCodeCollection[indexOfNecessary] : siloCodeCollection[DEFAULT_SILO_INDEX];
      return { ...params, silo_code };
    } else {
      return params;
    }
}

function formatMapResponse(res, params) {
  return formatResponseForCountryLevel(res);
}

function _getPercentStreams(current, total){
  const percent = (current / total) * 100;
  const formattedPercent = percent.toFixed(2) + ' %';
  return formattedPercent;
}

function sumStreams(data){
  const totalStreamsReducer = (acc, currentValue) => { return { total: acc.total + currentValue.streams_total } };
  const initialValue = { total: 0 };
  const totalStreams = data.reduce(totalStreamsReducer, initialValue);
  return totalStreams.total;
}

function formatResponseCoordinates(response){
  return response.map( data => { return { ...data, long: data.lon, lat: data.lat, percent: _getPercentStreams(data.streams_total, sumStreams(response)) } });
}
