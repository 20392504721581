import React from 'react';
import { connect } from 'react-redux';
import { map, find, findIndex, isEqual, cloneDeep, get } from 'lodash';
import { Link } from "react-router-dom";
import { playlistsActions } from '../../../data/actions/playlists';
import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters';
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';

class StandaloneFilter extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			playlists: [],
			searchResults: []
		}
	}
	
	componentDidMount(){
		this.props.dispatch(userEntityFiltersActions.getUserEntityFilters());
	}
	
	render (){
		const { loading, filters = []} = this.props.userEntityFilters;
		const { filtered = {} } = this.props;
		let options = filters.map(filter=>({
			label: filter.caption,
			value: filter.entities,
			ids: map(filter.entities, 'entity_id').sort()
		}));
		
		return <div className='saved-filter-select'>
			<ReactSelect placeholder="Saved Filters" options={options}  onChange={this.props.onChange} />
			{/*<Link to="/playlists/filter">Manage Saved Filters</Link>*/}
		</div>
	}
	
	
}

function mapStateToProps(state) {
	return {
		userEntityFilters: state.userEntityFilters
	}
}


export default connect(mapStateToProps)(StandaloneFilter);