import React from 'react';
import { connect } from 'react-redux';
import ConfirmClick from '../../../common/ConfirmClick';
import { milestonesActions } from '../../../../data/actions/milestones';
import icons from '../../../../helpers/Icons';

class LinkedEntities extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        const entities = this.props.row.original.linked_entities,
            milestone = this.props.row.original.id;
        
        if(!entities || !entities.length)
            return <p>No linked entities</p>;
        else
            return <ul className="campaign-list milestones">
                {entities.map(entity=><li>
                    <span dangerouslySetInnerHTML={{__html: icons.entities[entity.entity_type+'s']}} className={`chart-icon social-icon ${entity.entity_type}`} />
                {entity.entity_details.label} ({entity.entity_type})
                <ConfirmClick confirmClass="close-link" confirmAction={()=>{this.props.unlinkMilestone(entity.id, milestone)}} title="remove channel" confirmLabel=" ×"></ConfirmClick>
                </li>)}
            </ul>;
    }
}

function mapStateToProps(state) {
    return {
    } 
}

function mapDispatchToProps(dispatch) {
    return {
        unlinkMilestone: (id, milestone) => dispatch(milestonesActions.unlinkMilestone(id, milestone))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(LinkedEntities)