import React from 'react';
import { map, find, isEqual } from 'lodash';
import moment from 'moment';
import Box from '../../common/Box';
import MilestoneLegend from '../../pages/audience/MilestoneLegend';
import MilestoneLegendItem from '../../pages/audience/MilestoneLegendItem';
import { LineChart, StackedBarChart } from '../../widgets/charts/types'
import { formatChartLabelItem, sortDateRange } from '../../../helpers/DateFormatter';
import { splitByThousands } from '../../../helpers/NumberFormatter';
import { addMilestoneDataset } from '../../../helpers/MilestoneFormatter';
import {dateRange} from '../../../helpers/DateRange';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import StackedBarChartWithAnnotations from '../../widgets/charts/types/StackedBarChartWithAnnotations';

class EntityTimeline extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            // currentMilestone: null,
            // previousMilestone: null,
            // period: null,
            dateGroup: null
        };
    }

    render() {
        let labels = [], 
            datasets = [], 
            { results, resultsLoading, mode = 'line', getTimeseries, forceLegendPosition, milestones, dateGroup } = this.props,
            legendThreshold = 10,
            widthThreshold = 640,
            legendPosition = 'bottom';
        
        /*
        if(results && results.length) {
    
            //const dates = results.length ? map(results[0].stms_by_date, (item)=>item.stream_date) : [];
            const {dates, period} = dateRange(results);
    
            datasets = results.map(entity=>{
                entity.stms_by_date = entity.stms_by_date.sort(sortDateRange);
                let data = [];
                for(let date of dates) {
                    let stms = find(entity.stms_by_date, {stream_date: date});
                    data.push(stms ? stms.curr_units : null);
                }    
                return {
                    label: entity.name,
                    data: data,
                    fill: false
                }
            });
            
            labels = map(dates, (date)=>formatChartLabelItem(date, period));
            legendPosition = (window.innerWidth < widthThreshold || datasets.length > legendThreshold ) ? 'bottom' : 'right';
        }
        */

        if(results && results.labels && results.datasets) {
            labels = results.labels;
            datasets = results.datasets;
        }
        else {
            getTimeseries();
        }
    
        // legendPosition = forceLegendPosition ? forceLegendPosition : datasets.length > 20 ? 'top' : 'right';

        let { shadowChartProps } = this.props;
        
        if (shadowChartProps){
            shadowChartProps.shadowChart = true;
        }
        
        return datasets.length ? <React.Fragment> {mode == 'line' ? <div className="chart-block">
            <LineChartWithAnnotations milestones={milestones} data={{labels, datasets}} period={this.state.period} dateGroup={this.props.dateGroup} shadowChartProps={shadowChartProps} />
            </div> : 
            <StackedBarChartWithAnnotations milestones={milestones} period={this.state.period} dateGroup={this.props.dateGroup} data={{labels, datasets}} options={{
                plugins: {
                    legend: {
                        display: true, 
                        position: legendPosition
                    }
                }
            }} {...shadowChartProps} /> }
            {/*<MilestoneLegend milestones={annotations} />*/}
            </React.Fragment> : null;
    }
}

export default EntityTimeline;