//import $ from 'jquery';
import Param from '../../helpers/Param';
import {merge} from 'lodash';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';

export const searchService = {
    autocomplete,
    getSearchResults,
    getSearchResult,
    updateSearchResult    
};

function _autocomplete(search_input, search_types) {
    if(typeof search_types === 'string')
        search_types = [search_types];
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.v2_sales}/search`,
        requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify({search_input, search_types: search_types.join(',')})
        };

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function autocomplete(search_input, search_types, batch, async=false) {
    return config.live ? (batch? autocompleteBatchLive(search_input, search_types, async) : autocompleteLive(search_input, search_types, batch)) : autocompleteStatic(batch);
}

function autocompleteLive(search_input, search_types, batch) {
    
//    if(typeof search_types === 'array')
    search_types = search_types.join(',');
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/search${batch?'/batch':''}?search_input=${encodeURIComponent(search_input)}&search_types=${encodeURIComponent(search_types)}`,
        requestOptions = {
            method: 'GET',
            headers,
//            body: JSON.stringify({search_input, search_types: search_types.join(',')})
        };

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function autocompleteBatchLive(search_input, search_types, async=false) {
    if(typeof search_types === 'array')
        search_types = search_types.join(',');
  let headers = AuthHeader({'Content-Type': 'application/json'}),
      baseUrl = `${config.apis.miniCat}/v2/search/batch${async?'?async=true':''}`,
      requestOptions = {
          method: 'POST',
          headers,
          body: JSON.stringify({search_input, search_types: search_types.join(',')})
      };

  return fetch(baseUrl, requestOptions)
      .then(res=>res.json());
}


function autocompleteStatic(batch) { 
    let baseUrl = `${config.apis.sales}/api/static/search_multi${batch?'_batch':''}.json`;
    return fetch(baseUrl)
    .then(res=>{   
        return res.json()
    });
}


function getSearchResults() {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/search/imports`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function getSearchResult(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/search/${id}/one_import`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function updateSearchResult(id, data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/search/${id}/update_import`;
    return fetch(baseUrl, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
}