import { heartbeatsConstants } from '../constants/heartbeats';

const MAX_QUANTITY_WEEKS = 52;

export function previousFriday(daysAgo = 7){
  const t = new Date().getDate() + (6 - new Date().getDay() - 1) - daysAgo;
  const lastFriday = new Date();
  lastFriday.setDate(t);
  return lastFriday.toISOString().slice(0, 10);
}

export function getWeekNumber(date) {
  let previousWeekNumber;
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7));
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1));
  const weekNumber = Math.ceil(( ( (date - yearStart) / 86400000) + 1)/7) - 1;
  return weekNumber !== 0 ? weekNumber : MAX_QUANTITY_WEEKS;
}

export function getDatesFromFridayToThursday(startDate = heartbeatsConstants.START_DATE){
  const lastFriday = previousFriday(14);
  const dates = [];
  let currentDate = new Date(startDate);
  let strDate = startDate;
  let rangeDates = {};
  while (strDate < lastFriday){
    strDate = currentDate.toJSON().slice(0, 10);
    const dateStr = currentDate.toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', year: 'numeric', day: '2-digit', weekday: 'short' });
    const dateFormat = `${currentDate.getUTCFullYear()}-${("0" + (currentDate.getUTCMonth() + 1)).slice(-2)}-${("0" + currentDate.getUTCDate()).slice(-2)}`;
    const currentDayOfWeek = dateStr.split(',')[0];
    if(currentDayOfWeek === 'Fri'){
      rangeDates.startDate = dateFormat;
    }
    if(currentDayOfWeek === 'Thu') {
      rangeDates.endDate = dateFormat;
      const weekNumber = getWeekNumber(new Date(dateFormat));
      rangeDates.weekNumber = weekNumber;
      let copiedRangeDates = Object.assign({}, rangeDates);
      dates.push(copiedRangeDates)
    }
    currentDate.setDate(currentDate.getDate()+1);
  }
  return dates.reverse();
};
