import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ShortenedTitle from '../../pages/result/ShortenedTitle';
import ConfirmClick from '../../common/ConfirmClick';
import { percentageValue } from '../../../helpers/NumberFormatter';
import { userEntityFiltersFormatter } from '../../../data/formatters/user_entity_filters';

var deleteSvg = require('!svg-inline-loader!../../../../public/img/icons/delete_new.svg');
var editSvg = require('!svg-inline-loader!../../../../public/img/icons/edit.svg');
var profileSvg = require('!svg-inline-loader!../../../../public/img/open_playlist.svg');
// var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

export const entityFilterSettings = {
    settingsForTable
};


const filterURL = (entities) => {
    let filter = {};
    for(let entity of entities){
        const { fav_hide, entity_type, entity_id } = entity;
        const filterKey = ( fav_hide == 'favorite' ) ? entity_type : `exclude_${entity_type}`; 
        //if(fav_hide == 'favorite') {
            if(!filter.hasOwnProperty(filterKey))
                filter[filterKey] = [];
            filter[filterKey].push(entity_id);
        //}
    }
    return `/filter/${JSON.stringify(filter)}/overview`;
}   



function settingsForTable(deleteFilter, pinFilter, shareFilter, hideFilter){
    let columns = [
       {
           id: 'caption',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'Title',
           accessor: 'caption',
           width: 228,
           Cell: props => <Link to={filterURL(props.original.entities)}><ShortenedTitle title={props.value} /></Link>,
       },
       {
           id: 'summary',
           className: 'imprint-name user-summary',
           headerClassName: 'imprint-name user-summary',
           Header: 'Summary',
           accessor: 'summary',
           Cell: props => <div>{userEntityFiltersFormatter.formatTitle(props.original.entities, 'entity_title')}</div>,
       },
       {
           id: 'created_at',
           className: '',
           headerClassName: '',
           Header: 'Created',
           accessor: 'created_at',
           width: 120,
           Cell: props => {
               return <p>{moment(props.value).format('YYYY-MM-DD')}</p>
           },
           filterable: false
       },
       {
           id: 'owner',
           className: '',
           headerClassName: '',
           Header: 'Author',
           accessor: 'owner',
           width: 100,
           Cell: props => {
               return <p>{props.value?'You':'Shared'}</p> 
           },
           filterable: false
       },
       {
           id: 'actions',
           className: 'milestone-actions',
           headerClassName: 'no-sort milestone-actions',
           Header: 'Actions',
           width: 140,
           sortable: false,
           // <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
           Cell: props => {
               const { id, owner, entities } = props.original;
               return <div className="buttons-holder buttons-holder--milestones">
                   <Link to={filterURL(entities)} className="btn add-to-filter" title="Go to Filter"><span className="small-icon" dangerouslySetInnerHTML={{__html: profileSvg}}></span></Link>
                   {owner && <React.Fragment>
                   <Link to={`/user/entities-filter/${id}`}className="btn add-to-filter" title="Edit"><span className="small-icon" dangerouslySetInnerHTML={{__html: editSvg}}></span></Link>
                   <ConfirmClick confirmAction={()=>deleteFilter([id])} confirmClass="btn add-to-filter" confirmLabel={<span className="small-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>} confirmPrompt="Delete Filter?" title="Delete"></ConfirmClick>
                   </React.Fragment>}
                   {/* <a key="download" title="Export CSV" onClick={() => exportToCsv(id)} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} /> */}
               </div>
           },
           filterable: false
       },
       
       {
           id: 'is_shared',
           className: '',
           headerClassName: 'rt-resizable-header-content',
           Header: 'Shared',
           accessor: 'is_shared',
           width: 80,
           Cell: props => {
               const { id, owner } = props.original;
               return owner ? <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
                   <input type="checkbox" id={`filter_is_shared_${id}`} checked={props.value} onChange={()=>shareFilter(id, !props.value)} className="input" />
                   <label htmlFor={`filter_is_shared_${id}`} className="checkbox-label checkbox-switcher"></label>
               </div> : <div>-</div>
           },
           filterable: false
       },       
       {
           id: 'is_pinned',
           className: '',
           headerClassName: '',
           Header: 'Pinned',
           accessor: 'is_pinned',
           width: 80,
           Cell: props => {
               const { id } = props.original;
               return <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
                   <input type="checkbox" id={`filter_is_pinned_${id}`} checked={props.value} onChange={()=>pinFilter(id, !props.value)} className="input" />
                   <label htmlFor={`filter_is_pinned_${id}`} className="checkbox-label checkbox-switcher"></label>
               </div>
           },
           filterable: false
       },
       {
          id: 'is_hidden',
          className: '',
          headerClassName: '',
          Header: 'Hidden',
          accessor: 'is_hidden',
          width: 80,
          Cell: props => {
              const { id } = props.original;
              return <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
                  <input type="checkbox" id={`filter_is_hidden_${id}`} checked={props.value} onChange={()=>hideFilter(id, !props.value)} className="input" />
                  <label htmlFor={`filter_is_hidden_${id}`} className="checkbox-label checkbox-switcher"></label>
              </div>
          },
          filterable: false
      },       
          {
           id: 'has_mailing_list',
           className: '',
           headerClassName: 'no-sort',
           Header: 'Alert',
           accessor: 'mailing_list_exists',
           width: 50,
           /*
           Cell: props => {
               const { id } = props.original;
               return <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
                   <input type="checkbox" disabled={true} id={`filter_has_mailing_list_${id}`} checked={props.value} onChange={()=>{}} class="input" />
                   <label htmlFor={`filter_has_mailing_list_${id}`} className="checkbox-label checkbox-switcher"></label>
               </div>
           },
           */
           Cell: props => {
              const { id, mailing_list, recipients_count = 0} = props.original;
              let markerClass = 'off';
			  let mailingListID = null;
              if(props.value) {
				  mailingListID = mailing_list.id;
                  if(mailing_list.enabled && moment(mailing_list.start_date).isBefore(moment()) && (!mailing_list.end_date || moment(mailing_list.end_date).isAfter(moment())) && recipients_count > 0 )
                    markerClass = 'on';
                  else
                    markerClass = 'paused';
              }
              return <span className="mailing-list-status"> 
			  	<i className={`fas fa-circle mailing-list-${markerClass}`} /> 
			  	{mailingListID && <Link to={`/user/mailing-lists/recipients/${mailingListID}`}><i title="Manage Recipients" className="fas fa-envelope" /></Link>}
			  </span>
           },
           filterable: false
       },       
    ];
    return columns;
}
