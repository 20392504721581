import React from 'react';
import Tour from '../../common/Tour';

class ResultTour extends React.Component {
    render(){
    const steps = [
   {
       selector: '.profile-navigation-holder',
       content: 'Navigate to insights for your search here.'
   },
   {
       selector: '.toggle-main-menu',
       content: 'Looking for tools from the previous version of the dashboard? Find them here.'
   },
   {
       selector: '.help-guide-link',
       content: () => 
            <div>
                You’re ready to start data digging!
                <br/>
                For more in-depth guides and walkthroughs&#160;
                <a href="https://found.ee/Enterlyticsv41UserGuide">click here</a>.
            </div>,
        mutationObservables: ['.main-content-wrapper']
   },                       
    ];
        
        return <Tour steps={steps} tourID="result_tour" />
    }
}

export default ResultTour;