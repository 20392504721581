import React from 'react';
import Filter from './Filter';
import { find } from 'lodash';
import DropdownMenu from '../../common/DropdownMenu';

var tagSvg = require('!svg-inline-loader!../../../../public/img/tag.svg');
var arrowDownSvg = require('!svg-inline-loader!../../../../public/img/arrow-down.svg');
var imprintsSvg = require('!svg-inline-loader!../../../../public/img/icons/imprints.svg');
var artistsSvg = require('!svg-inline-loader!../../../../public/img/icons/artists.svg');
var productsSvg = require('!svg-inline-loader!../../../../public/img/icons/products.svg');
var tracksSvg = require('!svg-inline-loader!../../../../public/img/icons/tracks.svg');
var projectsSvg = require('!svg-inline-loader!../../../../public/img/icons/projects.svg');

const headerSettings = [
    { id: "catalog", title: "Catalog: ", count: "" },
    { id: "playlists", title: "Playlist Title: ", count: "0" },
    { id: "current_tracks", title: "Number of Tracks: ", count: "Any" },
    { id: "playlist_source", title: "Playlist/Radio: ", count: "Any" },
    { id: "playlist_types", title: "Playlist Type: ", count: "Any" },
]

const entitiesObjects = [
    { type: "imprints", icon: imprintsSvg },
    { type: "artists", icon: artistsSvg },    
    { type: "projects", icon: projectsSvg },    
    { type: "products", icon: productsSvg },
    { type: "tracks", icon: tracksSvg },
  ];

const catalogKeys = ['imprints', 'artists', 'projects', 'products', 'tracks'];

class FilterButton extends React.Component {
    
    
    constructor(props){
        super(props);
        this.state = {
            filterComponentRef: React.createRef(),
            expanded: false,
            display: []
        }
    }
    
    toggleFilter = (event, force) => {
        this.setState({
            expanded: force !== undefined ? force : !this.state.expanded
        })
    }
    
    getFilteredValue = (filtered, key) => {
        if(key == 'catalog') {
            let totalEntities = 0;
            for(let entityType of catalogKeys) {
                let entity = find(filtered, item=>item.id == entityType);
                if(entity)
                    totalEntities += entity.value.length;
            }
            return totalEntities;
        }
        else {
            let entity = find(filtered, item=>item.id == key);
            if(entity) {
                let { value } = entity;
                if(Array.isArray(value))
                    return value.length;
                else
                    return value;
            }
        }
    }

    dropdownHeader = (filtered, entity, dropdown = true) => {
        const item = headerSettings.find(item => item.id === entity);
        const title = item.title;
        const count = item.count;

        return <span className="playlist-filter-text-item">
            <span className="playlist-filter-result-icon" dangerouslySetInnerHTML={{__html: tagSvg}}></span>
            {title} ({count == '' ? this.getFilteredValue(filtered, entity) : (this.getFilteredValue(filtered, entity) || count)})
            <a className="close-link" onClick={() => this.removeOption(entity)}>&times;</a>
            {dropdown && <span className="caret" dangerouslySetInnerHTML={{__html: arrowDownSvg}}></span>}
        </span>
    }

    mergeIcons(data, entitiesObjects) {
        return data.map(item => {
            const matchingEntity = entitiesObjects.find(entity => entity.type === item.type);
            if (matchingEntity) {
                return { ...item, icon: matchingEntity.icon };
            }
            return item;
        });
    }

    showDropdownIcon(array) {
        return array.length ? true : false
    }
    
    renderSelected = (filtered, display) => {
        const playlistTypesFilter = find(filtered, item=>item.id == 'playlist_types');
        const playlistTypes = playlistTypesFilter ? playlistTypesFilter.value : [];
        const displayWithIcons = this.mergeIcons(display, entitiesObjects);

        const mainEntities = displayWithIcons.filter(item=>item.type != 'playlists');
        const playlistEntities = display.filter(item=>item.type == 'playlists');
        
        const showTracks = this.getFilteredValue(filtered, 'current_tracks');
        const showSource = this.getFilteredValue(filtered, 'playlist_source');
        const showTypes = this.getFilteredValue(filtered, 'playlist_types');

        return <div>
        <div className="playlist-filter-text">
            {mainEntities.length > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'catalog', this.showDropdownIcon(mainEntities))} className="playlist-dropdown">
                {mainEntities.map(item=><div className={`playlist-filter-result-item ${item.type}`}>
                    <div className={`search-entity-logo ${item.type}`} dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                    <div className={`playlist-filter-result-item__tag`}>{item.title}</div>
                </div>)}
            </DropdownMenu>}
            {playlistEntities.length > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'playlists', this.showDropdownIcon(playlistEntities))} className="playlist-dropdown">
                {playlistEntities.map(item=><div className="playlist-filter-result-item">{item.title}</div>)}
            </DropdownMenu>}
            {showTracks > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'current_tracks', false)} className="playlist-dropdown">
                <div></div>
            </DropdownMenu>}
            {showSource && <DropdownMenu header={this.dropdownHeader(filtered, 'playlist_source', false)} className="playlist-dropdown">
                <div></div>
            </DropdownMenu>}
            {showTypes > 0 && <DropdownMenu header={this.dropdownHeader(filtered, 'playlist_types', this.showDropdownIcon(playlistTypes))} className="playlist-dropdown">
                {playlistTypes.map(item=><div className='playlist-filter-result-item'>{item}</div>)}
            </DropdownMenu>}
        </div></div>
        
    }
    
    applyFilter = (filter, display) => {
        this.setState({display});
        this.toggleFilter(null, false);
        this.props.onApply(filter);
    }
    
    removeOption = (option) => {
      const { filterComponentRef } = this.state;
      filterComponentRef.current.removeOption(option);
    }
    
    render (){
        const { expanded, display, filterComponentRef } = this.state;
        const { filtered, onApply } = this.props;        
        return <div className="playlist-filter-holder">
            <button className={`filter-toggle-btn btn default-btn`} onClick={this.toggleFilter}><span className="fa fa-plus"></span> Add Filter</button>
            {this.renderSelected(filtered, display)}
            <Filter filtered={filtered} expanded={expanded} onApply={this.applyFilter} ref={filterComponentRef} />
        </div>
    }
}

export default FilterButton;