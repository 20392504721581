import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { merge } from 'lodash';

import { filterActions } from '../../data/actions/filter'

class ResultRedirect extends Component {
	constructor(props) {
		super(props);
	}
	
	componentDidMount(){
		const currentFilter = this.props.filter.global;
		const newFilter = JSON.parse(this.props.match.params.global);
		const filter = merge({}, currentFilter, newFilter);
		this.props.dispatch(filterActions.setGlobalFilter(filter)).then(()=>{
			this.props.history.push(`/filter/${this.props.match.params.filter}`)
		});
	}
	


	render() {
		return <span>loading</span>
	}
}

function mapStateToProps(state) {
	return {
		filter: state.filter,
	} 
}

export default withRouter(connect(mapStateToProps)(ResultRedirect))