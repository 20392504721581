import React from 'react';
import ReactTooltip from 'react-tooltip';

var favHidSvg = require('!svg-inline-loader!../../../../public/img/fav-hid.svg');
var favoriteSvg = require('!svg-inline-loader!../../../../public/img/favorites.svg');
var hiddenSvg = require('!svg-inline-loader!../../../../public/img/hidden.svg');

class EntityStatusWidget extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            status: undefined,
        }
        this.setStatus = this.setStatus.bind(this);
    }
    
    componentDidMount(){
        const { status } = this.props;
        this.setState({status});
    }
    
    setStatus(status){
        this.setState({status});
    }
    
    render() {
        //const { status } = this.state,
        const { entity, id, status, setStatus } = this.props;
        if(!id)
            return null;

        const favoriteStatus = status == 'favorite' ? 'chosen-status' : '';
        const hiddenStatus = status == 'hidden' ? 'chosen-status' : '';

        return <div className="favorite-hidden-container" data-tip data-for={id.toString()} data-event="click">
            {status === undefined && <span className="favorite-hidden-icon" dangerouslySetInnerHTML={{__html: favHidSvg}}></span>}
            {status === 'favorite' && <span className={`favorite-hidden-icon ${status ==='favorite' ? 'chosen' : ''}`} dangerouslySetInnerHTML={{__html: favoriteSvg}}></span>}
            {status === 'hidden' && <span className={`favorite-hidden-icon ${status === 'hidden' ? 'chosen' : ''}`} dangerouslySetInnerHTML={{__html: hiddenSvg}}></span>}
            <ReactTooltip id={id.toString()} clickable={true} isCapture={true} effect="solid" className="favorite-tooltip" backgroundColor="#F9F9F9" place="right">
                <div className="favorite-hidden-container">
                <span className={`favorite-hidden-icon ${favoriteStatus}`} onClick={()=>setStatus('favorite')} title="Favorite" dangerouslySetInnerHTML={{__html: favoriteSvg}}></span>
                <span className={`favorite-hidden-icon ${hiddenStatus}`} onClick={()=>setStatus('hidden')} title="Hidden" dangerouslySetInnerHTML={{__html: hiddenSvg}}></span>
                </div>
            </ReactTooltip>
        </div>
    }
    
}

export default EntityStatusWidget