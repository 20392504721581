import React from 'react';
import ReactDOM from 'react-dom';
import Datepicker from '../../common/Datepicker';
import { format } from 'date-fns';

class DatePicker extends React.Component {

    

    onChange = (date) => {
        date = format(date, 'yyyy-MM-dd');
        return this.props.input.onChange(date);
    }

    render() {
        let {input: { name, value, label, ...restInput }, meta, children, className,datepickerPosition,placeholder, ...rest} = this.props;
        return <Datepicker placeholder={placeholder} datepickerPosition={datepickerPosition} name={name} date={value} className={className} dateChange={(date)=>this.onChange(date)} /> 
    }
}

 export default DatePicker;