import React from 'react';
import moment from 'moment';
import {View, Text, StyleSheet } from '@react-pdf/renderer';
import styles from './LayoutStylesheet';

const DateRange = ({filter}) => {
    const dateFormat = 'YYYY-MM-DD';
    const { dateStart, dateEnd } = filter;
    return <View style={styles.section}>
        <Text style={styles.dateRangeTitle}>Date Range: {moment(dateStart).format(dateFormat)} - {moment(dateEnd).format(dateFormat)}</Text>
    </View>
};
  
export default DateRange