import { mapsConstants } from '../constants/maps';
import { mapsService } from '../services/maps';
import { mapsFormatter } from '../formatters/maps';

export const mapsActions = {
  getArtistStreamsByCountry,
  getArtistStreamsByRegion
}

function getArtistStreamsByCountry(params){
  return ( dispatch, getState) => {

    dispatch(request());

    const formattedParams = mapsFormatter.formatMapParams(params);

    mapsService.getArtistStreamsByCountry(formattedParams)
    .then( response => {
      const formattedResponse = mapsFormatter.formatMapResponse(response, params);
      dispatch(success(formattedResponse));
    })
    .catch( error => {
      dispatch(failure(error));
    });
  }
  function request() { return { type: mapsConstants.GET_ARTISTS_STREAMS_MAPS_REQUEST }};
  function success(payload) { return { type: mapsConstants.GET_ARTISTS_STREAMS_MAPS_SUCCESS, payload }};
  function failure(error) { return { type: mapsConstants.GET_ARTISTS_STREAMS_MAPS_FAILURE, error }};
}

function getArtistStreamsByRegion(params){
  return dispatch => {
    dispatch(request());
    mapsService.getArtistStreamsByRegion(params)
    .then( response => {
      const formattedResponse = mapsFormatter.formatResponseCoordinates(response);
      dispatch(success(formattedResponse))
    })
    .catch( error => dispatch(failure(error.message)));
  }
  function request() { return { type: mapsConstants.GET_ARTISTS_STREAMS_BY_REGION_MAPS_REQUEST }};
  function success(payload) { return { type: mapsConstants.GET_ARTISTS_STREAMS_BY_REGION_MAPS_SUCCESS, payload }};
  function failure(error) { return { type: mapsConstants.GET_ARTISTS_STREAMS_BY_REGION_MAPS_FAILURE, error }};
}
