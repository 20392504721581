import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import { splitByThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import LineChart from '../../widgets/charts/types/LineChart';
import VendorLogo from "../../common/VendorLogo";
import SharedChartWithAnnotations from './SharedChartWithAnnotations';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { audienceActions } from '../../../data/actions/audience';


class SharedChart extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
        this.getMilestonesData = this.getMilestonesData.bind(this);        
    }
    
    exportToCsv(){
        let { data: { data }, metric, entity } = this.props,
            exportField;
        
        switch(metric) {
            case 'streams':
                exportField = 'total_streams';
                break;
            case 'ratio':
                exportField = 'listener_ratio';
                break;
            default:
                exportField = metric;
                
        }
        let fields = [];
        switch(entity) {
            case 'projects':
                fields = ['identifier', 'project_title']
                break;
            case 'products':
                fields = ['upc', 'product_title'];
                break;
                
            case 'tracks':
                fields = ['isrc', 'track_title'];    
        }
        fields = fields.concat(['first_released', 'weeks_since_release', exportField]);
        
        let exportData = data.map(item => {
            let resultItem = {};
            for(let field of fields) {
                resultItem[field] = item[field];
            }
            return resultItem;
        });

        const title = entity.replace(/s$/, '') + '-comparison'; 
        const filename = CSV.CSVHeader(title, metric);
        return CSV.CSVExport(exportData, {filename}, metric);
    }

    // renderToolbar(){
    //     let toolbar = [];
    //     toolbar.push(<div key="wrapper" className="ibox-action-holder">
    //     <div className="ibox-actions">
    //       <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
    //     </div>
    //     </div>)
    //     return toolbar;
    // }

    getMilestonesData() {
        let { data: { data }, metric, entity } = this.props;
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
        
    render (){  
        const { metric, data: {labels, datasets}, metadata, chartID = '', shadowChart = true, milestones, weeks} = this.props;
        const period = weeks <= 8 ? 'Week' : 'Month';
        let chartData = {
            labels,
            datasets: []
        }
        
        for(let isrc of Object.keys(datasets)) {
            const track = find(metadata, (track)=>track.code == isrc),
                label = track ? track.name_compare : isrc;
            chartData.datasets.push({
                label,
                data: datasets[isrc][metric],
                fill: false
            })
        }
        
        const percentageChart = ['active', 'skipped'].includes(metric);
        const formatterCallback = percentageChart ? (value) => value + '%' : splitByThousands;
        const chartOptions = {
            maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        callback: function(value, index, values) {
                            return formatterCallback(value);
                        }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: `${period}s Since Release`
                    },
                    ticks: {
                        callback: function(value, index, values) {
                            return value == 0 ? 'Release' : value;
                        }
                    }                    
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return formatterCallback(context.raw);
                        },
                        title: function(context) {
                            if(Array.isArray(context) && context.length) {
                                const {label} = context[0];
                                return label == 0 ? `Release ${period}` : `Release ${period} + ${label}`
                            }
                        }
                    }
                }
            }
        };
        
        let title = '';
        if (metric == 'ratio') {
            title = 'Streams Per Listener'
        } else if (metric == 'active' || metric == 'skipped') {
            title = `${capitalize(metric)} %`
        } else {
            title = `${capitalize(metric)} Per ${period}`
        };
        // const title = metric == 'ratio' ? 'Streams Per Listener' : `${capitalize(metric)} per Week`;
        
        return <Box title={`Compare: ${title}`} exportToCsv={this.renderExportToCsv()}>
            {chartData.datasets.length > 0 && <div className="streams-audience-chart">
                {this.getMilestonesData()}
                <div className="chart-block">
                    {/* <LineChart data={chartData} options={chartOptions} id={chartID} shadowChart={shadowChart} shadowChartWidth={794} shadowChartHeight={350} /> */}
                    <SharedChartWithAnnotations chartType="line" data={chartData} metadata={metadata} options={chartOptions} milestones={milestones} shadowChartProps={{id: chartID, shadowChart: true, shadowChartWidth: 800, shadowChartHeight: 300}} />
                </div>
                {/*<p className="stream-audience-chart-text">Weeks Since Release</p>*/}
            </div>}
            {!chartData.datasets.length && <p>No data available</p>}
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (id, entity) => {
            return dispatch(audienceActions.getStreamsStats(id, entity))
        } 
    } 
}

export default SharedChart;