//import $ from 'jquery';
import Param from '../../helpers/Param';
import {find} from 'lodash';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';
import {searchService} from './search';

export const imprintsService = {
    getImprints,
    getImprintsOld,
    getImprintsAutocomplete,
    getTopImprints,
    getCard,
    getStreamsStats,
    getMetadata,
    getTimeseriesImprints,
};

function getImprintsOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };
    
    // TODO: API dependent, change for prod
    let baseUrl = `${config.apis.sales}/api/v1/Imprint`, 
        qs = {};
    
    if(sorted !== undefined && sorted.length)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": ["${value.join('","')}"]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}", "$options": "i"}`);
            else
                filters.push(`"${filter.id}": ${value}`);    
            
        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`; 
    }
    //
    //baseUrl += '?' + Param(qs);

    let pages;
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');   
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}

function getImprints(params, globalFilter, basic){
    return config.live ? getImprintsNew(params, globalFilter, basic) : getImprintsStatic(basic);
}

function getImprintsNew(params, globalFilter, basic=true) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = `cat_nav_imprints${basic?'_basic':''}`;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;
    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search 
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    qs = BuildFilterQS(qs, 'imprints');
    //baseUrl += '?' + $.param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getImprintsStatic(basic) {
    let baseUrl = `${config.apis.sales}/api/static/imprints_simple${basic?'_basic':''}.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}

function getImprintsAutocomplete(input) {
    return searchService.autocomplete(input, ['imprints']);
}

function getTopImprints(params, globalFilter, fields){
    return config.live ? getTopImprintsLive(params, globalFilter, fields) : getTopImprintsStatic();
}

function getTopImprintsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = 'top_imprints';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    qs = BuildFilterQS(qs, 'imprints');
    //baseUrl += '?' + $.param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopImprintsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_imprints_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getCard(id) {
    let fields = 'id,name,logo_file_name', 
        baseUrl = `${config.apis.miniCat}/v2/imprints/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getStreamsStats(ids, filtered, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, filtered, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, extFiltered, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [...extFiltered, {id:'ids', value:ids}]
    }
    //
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'top_imprints';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    //baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_imprints_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}


function getMetadata(ids){
    return config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/imprints/by_id/`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'imprint_ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/imprints_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getTimeseriesImprints(params, globalFilter){
    return config.live ? getTimeseriesImprintsLive(params, globalFilter) : getTimeseriesImprintsStatic();
}

function getTimeseriesImprintsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = 'top_imprints';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesImprintsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_imprints_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}
