import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import Sources from '../../components/common/Stats/Sources';
import Devices from '../../components/common/Stats/Devices';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import MultiEntitiesStreams from '../../components/pages/result/MultiEntitiesStreams';
import { filterActions } from '../../data/actions/filter'
import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';
import Locations from '../../components/pages/result/Locations';

class ResultOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInitialized: false
        }
    }
    
    
    render() {
        const { filter, flatFilter, widgetProps, sharedMode } = this.props;
        let includeListeners = true;
        for(let entity of flatFilter){
            if(entity.id == 'imprints') {
                includeListeners = false;
                break;
            }
        }
        const StreamingDataElement = <MultiEntitiesStreams filtered={flatFilter} shadowChartProps={{id: 'streams_and_listeners', shadowChartWidth: 800, shadowChartHeight: 300}} />;  
        return <div className="inner-page">
            {!sharedMode && <div className="widget-row widget-row--full-width">
                <HiddenElements widgetProps={widgetProps} />
            </div>}                    
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="streams" widgetProps={widgetProps}>
                    {StreamingDataElement}

                        {/*<div>
                    {Object.keys(filter).map(entity=><StreamsStats entity={entity} ids={filter[entity]} /> )}
                    </div>
                    */}
                </Widget>
            </div>
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="vendors" widgetProps={widgetProps}>
                    <VendorsWidget filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'home_vendors', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
                <Widget widgetID="territories" widgetProps={widgetProps}>
                    <TerritoryStats filtered={flatFilter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_territories', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_artists" widgetProps={widgetProps}>
                    <EntitiesList title="Top Artists" entity="artists" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_artists', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_projects" widgetProps={widgetProps}>
                    <EntitiesList title="Top Projects" entity="projects" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_projects', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_products" widgetProps={widgetProps}>
                    <EntitiesList title="Top Products" entity="products" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_products', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                    <EntitiesList title="Top Tracks" entity="tracks" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_tracks', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>

            
            {/*
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="locations" widgetProps={widgetProps}>
                    <Locations filtered={flatFilter} territory="world" />
                </Widget>
            </div>
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="top_artists" widgetProps={widgetProps}>
                    <EntitiesList title="Top Artists" entity="artists" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_artists', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
                <Widget widgetID="top_products" widgetProps={widgetProps}>
                    <EntitiesList title="Top Products" entity="products" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_products', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="top_projects" widgetProps={widgetProps}>
                    <EntitiesList title="Top Projects" entity="projects" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_projects', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
                <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                    <EntitiesList title="Top Tracks" entity="tracks" filter={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_tracks', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            */}
            {/*
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="sources" widgetProps={widgetProps}>
                    <Sources filtered={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_sources', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
                <Widget widgetID="devices" widgetProps={widgetProps}>
                    <Devices filtered={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'top_devices', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            */}
        </div>
    }
}

export default WithWidgetManager(ResultOverview, 'result_overview')