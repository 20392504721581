import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
       {key: 'age', title: 'Age Group', width: 25, fontSize: 5},
       {key: 'male', title: 'Male', width: 25, fontSize: 5, formatFn: splitByThousands},
       {key: 'female', title: 'Female', width: 25, fontSize: 5, formatFn: splitByThousands},
       {key: 'unknown', title: 'Unknown', width: 25, fontSize: 5, formatFn: splitByThousands},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}