import React from 'react';

class MilestoneLegend extends React.Component {
    
    constructor(props) {
        super(props);
        
    }
        
    componentDidMount() {
    }

    
    render (){
        let { milestones } = this.props;
        milestones = Object.values(milestones);
        return <ul className="milestone-legend">
            {milestones.map(milestone=><li index={milestone.xValue} className="milestone-legend-item">
                <span className="milestone-legend-icon" style={{backgroundColor: milestone.backgroundColor}} />
                <span className="milestone-legend-date">{milestone.xValue}</span>
                <ul className="milestone-legend-events">
                {milestone.milestones.map(milestoneForDate=>{
                    if(!milestoneForDate.ends_at) {
                        return <li>{`${milestoneForDate.reference}`}</li>
                    }
                    else {
                        const startEndLabel = milestoneForDate.ends_at == milestone.xValue ? 'End' : 'Start';
                        return <li>{`${milestoneForDate.reference}: ${startEndLabel}`}</li>
                    }
                })}
                </ul>
            </li>)}
        </ul>
    }
}

export default (MilestoneLegend);