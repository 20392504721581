import React from 'react';
import {PieChart, BarChart, LineChart, WorldMapChart} from './types';

class GraphicChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }
    

    renderChart(mode, props){
        switch(mode) {
            case 'bar':
                return <BarChart {...props} />
            case 'pie':
                return <PieChart {...props} />
            case 'line':
                return <LineChart {...props} />
            case 'world-map':
                return <WorldMapChart {...props} />
                
        }
    }

    render() {
        return (
            <div className="graphic-chart">
                {this.renderChart(this.props.mode, {
                    data: this.state.data,
                    labels: this.props.labels
                })}
            </div>
        )
    }
}

export default GraphicChart