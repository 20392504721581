//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';
import {searchService} from './search';

export const mailingListsService = {
    getMailingLists,
    getMailingListDetails,
    getMailingListsByRecipient,
    getMailingListRecipients,
    addMailingList,
    editMailingList,
    deleteMailingList,
    addRecipient,
    deleteRecipient,
	resendInvitation
};

function getMailingLists(params, globalFilter){
    return true || config.live ? getMailingListsLive(params, globalFilter) : getMailingListsStatic();
}

function getMailingListsLive(params, globalFilter) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_lists`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getMailingListsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/mailing_lists.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}



function getMailingListDetails(id){
    return true || config.live ? getMailingListDetailsLive(id) : getMailingListDetailsStatic();
}

function getMailingListDetailsLive(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_lists/${id}`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getMailingListDetailsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/mailing_list.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getMailingListRecipients(id){
    return true || config.live ? getMailingListRecipientsLive(id) : getMailingListRecipientsStatic();
}

function getMailingListRecipientsLive(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_lists/${id}/recipients`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getMailingListRecipientsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/mailing_list_recipients.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function addMailingList(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_lists`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function editMailingList(data, id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_lists/${id}`;
    return fetch(baseUrl, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function deleteMailingList(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_lists/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function addRecipient(email, mailingListID) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_list_recipients`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({mailing_list_id: mailingListID, email})
    })
    .then(res=>{   
        return res.json()
    })
    
}

function deleteRecipient(id, mailingListID) {
    let baseUrl = `${config.apis.orthus}/v1/mailing_list_recipients/${id}?mailing_list_id=${mailingListID}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers: AuthHeader()
    })
    .then(res=>{   
        return res.json()
    });
}

function getMailingListsByRecipient(id){
    return true || config.live ? getMailingListsByRecipientLive(id) : getMailingListsByRecipientStatic();
}

function getMailingListsByRecipientLive(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/mailing_list_recipients/my_mailings`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getMailingListsByRecipientStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/mailing_lists_by_recipient.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function resendInvitation(id) {
	let baseUrl = `${config.apis.orthus}/v1/mailing_list_recipients/resent_email?recipient_id=${id}`;
	return fetch(baseUrl, {
		method: 'PATCH',
		headers: AuthHeader()
	})
	.then(res=>{   
		return res.json()
	});
} 