import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { find, capitalize } from 'lodash';
import store from '../../../helpers/store'
import { sessionStore } from '../../../helpers/SessionStore'
import ReactSelect from '../../common/ReactSelect';
import SearchWidget from '../../pages/home/SearchWidget';
import FilterList from './FilterList';
import EntitiesList from './EntitiesList';

import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters'

const deleteSvg = require('!svg-inline-loader!../../../../public/img/icons/delete.svg');
const filterSvg = require('!svg-inline-loader!../../../../public/img/saved-filter-icon.svg');
const editSvg = require('!svg-inline-loader!../../../../public/img/edit-filter.svg');
const allEntityTypes = ['imprints', 'artists', 'products', 'projects', 'tracks'];
 
class EntityFilter extends React.Component {

    constructor(props){
        super(props);
        const currentFilter = sessionStore.get('userEntityFilter') || null;
        const recentFilters = store.get('recentEntityFilters') || [];
        this.state = {
            currentFilter,
            recentFilters,
            entityType: null,
            searchActive: false
        }
        this.setFilterState = this.setFilterState.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);
        this.addToFilter = this.addToFilter.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.selectFilterByID = this.selectFilterByID.bind(this);
        this.updateFilterField = this.updateFilterField.bind(this);
        this.removeFromFilter = this.removeFromFilter.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.addRecentFilter = this.addRecentFilter.bind(this);
        this.setSearchActive = this.setSearchActive.bind(this);
        this.goToDetails = this.goToDetails.bind(this);
    }
    
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(userEntityFiltersActions.getUserEntityFilters());
    }
    
    addNewFilter() {
        const caption = prompt('New Filter Name')
        if(!caption)
            return;
        
        this.props.dispatch(userEntityFiltersActions.createFilter({caption}))
        .then(newFilter => this.selectFilter(newFilter));
    }
    
    renderRecentFilters() {
        const { currentFilter, recentFilters } = this.state;
        return <FilterList currentFilter={currentFilter} filters={recentFilters} onSelect={this.selectFilterByID} />
    }
    
    renderPinnedFilters() {
        const { currentFilter } = this.state,
            { filters = [] } = this.props.userEntityFilters;
        const pinnedFilters = filters.filter(filter=>filter.is_pinned);
        return <FilterList currentFilter={currentFilter} filters={pinnedFilters} onSelect={this.selectFilterByID} />        
    }
    
    addRecentFilter(filter) {
        let recentFilters = store.get('recentEntityFilters') || [];
        const newFilter = {id: filter.id, caption: filter.caption};
        if(!find(recentFilters, filter=>filter.id == newFilter.id)) {
            recentFilters.unshift(newFilter);
            store.set('recentEntityFilters', recentFilters);
            return new Promise((resolve) => this.setState({recentFilters}, resolve));
        }
        else 
            return Promise.resolve();
    }
    
    setFilterState(currentFilter, callback) {
        sessionStore.set('userEntityFilter', currentFilter);
        this.setState({
            ...this.state,
            currentFilter,
            searchActive: false
        }, callback);
    }
    
    selectFilter(currentFilter) {
        this.addRecentFilter(currentFilter)
        .then(()=>{
            this.setFilterState(currentFilter);
        });
    }
    
    selectFilterByID(id) {
        const { filters = [] } = this.props.userEntityFilters;
        const currentFilter = find(filters, filter=>filter.id == id);
        if(currentFilter)
            this.selectFilter(currentFilter);
    }
    
    addToFilter(item, include) {
        const { id: filterID, entities } = this.state.currentFilter;
        const newFilterEntity = {
            user_filter_id: filterID,
            entity_id: item.id,
            entity_type: item.entity,
            entity_title: item.name_raw,
            entity_image: item.image,
            fav_hide: include ? 'favorite' : 'hidden'
        };
        let newEntities = entities.filter(entity=>(!(entity.entity_id==newFilterEntity.entity_id && entity.entity_type==newFilterEntity.entity_type)));
        if(include !== undefined)
            newEntities.push(newFilterEntity);
        
        this.setFilterState({
            ...this.state.currentFilter,
            entities: newEntities 
        });
    }
    
    removeFromFilter(id, type) {
        const { entities } = this.state.currentFilter;
        let newEntities = entities.filter(entity=>(!(entity.entity_id==id && entity.entity_type==type)));
        this.setFilterState({
            ...this.state.currentFilter,
            entities: newEntities 
        });
    }
    
    updateFilterField(field, value) {
        const { id } = this.state.currentFilter;
        this.setFilterState({
            ...this.state.currentFilter,
            [field]:value 
        }, ()=>{
            this.props.dispatch(userEntityFiltersActions.updateUserEntityFilter(id, {[field]:value}))    
        })
        
    }
    
    saveFilter() {
        const { currentFilter } = this.state;
        const existingFilter = find(this.props.userEntityFilters.filters, filter=>filter.id == currentFilter.id);
        const entitiesToAdd = currentFilter.entities.filter(entity=>!entity.id); 
        const entitiesToDelete = existingFilter.entities.filter(existingEntity => !find(currentFilter.entities, entity => entity.id == existingEntity.id));
        this.props.dispatch(userEntityFiltersActions.updateFilterEntities(currentFilter.id, entitiesToAdd, entitiesToDelete))
        .then(currentFilter=>this.setFilterState(currentFilter));
    }
    
    deleteFilter() {
        const { id } = this.state.currentFilter;
        this.props.dispatch(userEntityFiltersActions.destroyFilter(id))
        .then(this.setFilterState(null));
    }
    
    setSearchActive(searchActive) {
        this.setState({searchActive});
    }
    
    goToDetails() {
        const { entities } = this.state.currentFilter;
        let filter = {};
        for(let entity of entities){
            const { fav_hide, entity_type, entity_id } = entity;
            if(fav_hide == 'favorite') {
                if(!filter.hasOwnProperty(entity_type))
                    filter[entity_type] = [];
                filter[entity_type].push(entity_id);
            }
        }
        this.props.history.push(`/filter/${JSON.stringify(filter)}`);
    }
    
    resetFilter(){
        const { applyFilter, resetFilter } = this.props;
        this.setFilterState(null, ()=>{
            if(typeof resetFilter == 'function')
                resetFilter();
            else
                applyFilter();
        })
    }
    
    renderFilterSelect() {
        const { currentFilter } = this.state,
            { loading, filters = [] } = this.props.userEntityFilters,
            filterOptions = filters.map(filter=>({label: filter.caption, value: filter})),
            selectedFilter = currentFilter ? find(filterOptions, option=>option.value.id == currentFilter.id) : null;
            
        return <ReactSelect
            loading
            value={selectedFilter}
            options={filterOptions}
            onChange={({value})=>this.selectFilter(value)}
            isLoading={loading}
            isSearchable={true}
            onFocus={()=>this.setSearchActive(true)}
        />
    }
    
    renderFilterEntities(entities, entityType) {
        const entitiesInFilter = entities.length;
        const entityTypes = entityType ? [entityType] : allEntityTypes; 
        return <div className="pinned-filters-holder filter-page">
            {entitiesInFilter == 0 && <p>Search for an entity to add it to the filter</p>}
            {entitiesInFilter > 0 && ['favorite', 'hidden'].map(include=><div className="pinned-filters-item">
                <h4 className="pinned-filter-title inclusions">{include == "favorite" ? "Inclusions" : "Exclusions"}</h4>
                <div className="pinned-entity-list">
                    {entityTypes.map(type=><EntitiesList entities={entities} type={type} fav_hide={include} onDelete={this.removeFromFilter} />)}
                </div>
            </div>)}
        </div>
    }
            
    renderEntityTypes(selectedEntityType) {
        let options = [{value: null, label: 'All'}];
        for(let entityType of allEntityTypes) {
            options.push({value: entityType, label: capitalize(entityType)})
        }
        return <ul className="entity-filter-tabs">
            {options.map(option=><li key={option.value} className={`entity-filter-tab ${option.value == selectedEntityType ? 'active':''}`} onClick={()=>this.setEntityType(option.value)}>{option.label}</li>)}
        </ul>
    }            
            
    setEntityType(entityType) {
        this.setState({entityType});
    }

    render() {
        const { currentFilter: filter , searchActive, entityType }  = this.state;
        const { applyFilter } = this.props;
        return (
             <div className="user-entity-filter-holder">
                 <div className="user-entity-filter">
                    <div className="entity-add-filter">
                        {/*<button onClick={this.addNewFilter} className="default-btn create-filter">+ Create New Filter</button>*/}
                        {this.renderFilterSelect()}
                    </div>
                    {filter && <div className="current-filter-holder"> 
                     <h3 className="current-filter-title">{filter.caption}</h3>
                     <div>
                         <SearchWidget searchResult={this.addToFilter} placeholder="Search imprint, artist, product or track" mode="playlist" searchItemMode="include_exclude" currentlySelectedItems={filter.entities} />
                     </div>
                     <div>
                         {this.renderEntityTypes(entityType)}
                         {this.renderFilterEntities(filter.entities, entityType)}
                     </div>
                     {/*                    
                     <div className="entity-filter-action"> 
                         <div className="entity-filter-dropdown-list">
                             <button onClick={this.saveFilter} className="default-btn default-btn--small">Save Filter</button>
                             <button onClick={this.deleteFilter} className="remove-filter-link filter-reset-btn button-no-style">
                             <span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>Delete Filter</button>
                         </div>
                         <div className="entity-filter-dropdown-list">
                             <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
                                 <input type="checkbox" id="filter_is_shared" checked={filter.is_shared} onChange={()=>this.updateFilterField('is_shared', !filter.is_shared)} class="input" />
                                 <label htmlFor="filter_is_shared" className="checkbox-label checkbox-switcher">Share</label>
                             </div>
                             <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
                                 <input type="checkbox" id="filter_is_pinned" checked={filter.is_pinned} onChange={()=>this.updateFilterField('is_pinned', !filter.is_pinned)} class="input" />
                                 <label htmlFor="filter_is_pinned" className="checkbox-label checkbox-switcher">Pin</label>
                             </div>
                         </div>
                     </div>
                     */}
                     <div className="entity-filter-apply-button-holder">
                        {/*<button onClick={()=>this.goToDetails(filter)} className="default-btn default-btn--small">Use Filter</button>*/}
                        <button onClick={()=>applyFilter(filter)} className="default-btn">Apply Filter</button>
                        <button onClick={()=>this.resetFilter()} className="button-no-style">Reset Filter</button>
                     </div>
                    
                 </div>}
                 </div>             
            </div>    
        )
    }
}

function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters
    } 
}
export default connect(mapStateToProps)(withRouter(EntityFilter));