import React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { entityFiltersActions } from '../../data/actions/entity_filters';

class EntityFilterName extends React.Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {
    this.props.onGetEntityFilters();
  }
  
  render(){
    const filters = this.props.entityFilters.allEntityFilters;
    const ids = this.props.ids.split(',');
    const entity = this.props.entity;
    let filterName = '';
    if(filters && filters.length) {
        for (let filter of filters) {
            if(filter.entity !== entity)
                continue;
            
            let filterIds = filter.id.split(',');
            if(isEqual(filterIds, ids))
                filterName = filter.name;
                
        }
    }
    if(!filterName)
        filterName = `${ids.length}  ${entity}`; 
    
    return <span>{filterName}</span>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetEntityFilters: entity => dispatch(entityFiltersActions.getAllEntityFilters()),
  }
}

const mapStateToProps = state => {
  return {
    entityFilters: state.entity_filters
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityFilterName);