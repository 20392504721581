import {
    globalFilterConstants
} from '../constants/global_filter';

const initialState = {
};

export function globalFilter(state = initialState, action) {
  switch (action.type) {
    case globalFilterConstants.ADD_ENTITY_REQUEST:
    case globalFilterConstants.GET_GLOBAL_FILTER_REQUEST:
    case globalFilterConstants.DELETE_ENTITY_REQUEST:
    case globalFilterConstants.SET_DEFAULT_FILTER_FAILURE:
      return {
        ...state,
        loading: true
      };
      
    case globalFilterConstants.ADD_ENTITY_SUCCESS:
    case globalFilterConstants.GET_GLOBAL_FILTER_SUCCESS:
        return {
            ...state,
            loading: false,
            globalFilter: action.data
        };
    case globalFilterConstants.SET_DEFAULT_FILTER_SUCCESS:
        return {
            ...state,
            loading: false,
        };        
    case globalFilterConstants.DELETE_ENTITY_SUCCESS:
        
        let { globalFilter } = state;
        globalFilter = globalFilter.filter(item => item.id != action.id);
        return {
            ...state,
            loading: false,
            globalFilter
        };
        
    case globalFilterConstants.ADD_ENTITY_FAILURE:
    case globalFilterConstants.GET_GLOBAL_FILTER_FAILURE:
    case globalFilterConstants.DELETE_ENTITY_FAILURE:
    case globalFilterConstants.SET_DEFAULT_FILTER_FAILURE:
        return {
            ...state,            
        };
    default:
      return state
  }
}
