import { find } from 'lodash';

export const releasesFormatter = {
    formatReleases
};

function formatReleases(releases, metadata) {
    for(let product of releases.releases) {
        
        for(let track of product.tracks) {
            const metaEntity = find(metadata, meta=>meta.id==track.track_id);
            if(metaEntity) {
                track.release_date = metaEntity.release_date;
            }
        }
    }
    return releases;

}
