import { find } from 'lodash';
import { heartbeatsService } from '../services/heartbeats';
import { heartbeatsFormatter } from '../formatters/heartbeats';
import { heartbeatsConstants } from '../constants/heartbeats';

export const heartbeatsActions = {
  getHeartbeats,
  getPagedHeartbeats,
  getHeartbeatsWeeks,
  getPagedHeartAttacks,
  getHeartAttacksWeeks  
};

function getHeartbeats(date){
  return ( dispatch, getState ) => {
    dispatch(request());
    heartbeatsService.getHeartbeats(date)
    .then( response => {
      dispatch(success(response));
    })
    .catch( error => {
      dispatch(failure(error));
    })
  };
  function request(){ return { type: heartbeatsConstants.GET_HEARTBEATS_REQUEST } };
  function success(payload){ return { type: heartbeatsConstants.GET_HEARTBEATS_SUCCESS, payload: payload } };
  function failure(error){ return { type: heartbeatsConstants.GET_HEARTBEATS_FAILURE, payload: error } };
}

function getPagedHeartbeats(params){
    return ( dispatch, getState ) => {
      dispatch(request());
      // exclude tiktok metrics filter for other vendors
      const vendor = find(params.filtered, param=>param.id == 'vend_id');
      if(vendor && vendor.value && vendor.value != 17) {
          const creations = find(params.filtered, param=>param.id == 'tiktok_creations');
          if(creations)
              creations.value = 0;
      }
      //
      heartbeatsService.getPagedHeartbeats(params)
      .then( response => {
          response = heartbeatsFormatter.formatPagedHeartbeats(response, params);
        dispatch(success(response));
      })
      .catch( error => {
        dispatch(failure(error));
      })
    };
    function request(){ return { type: heartbeatsConstants.GET_PAGED_HEARTBEATS_REQUEST } };
    function success(payload){ return { type: heartbeatsConstants.GET_PAGED_HEARTBEATS_SUCCESS, payload: payload } };
    function failure(error){ return { type: heartbeatsConstants.GET_PAGED_HEARTBEATS_FAILURE, payload: error } };
  }


function getHeartbeatsWeeks(){
    return ( dispatch, getState ) => {
      dispatch(request());
      const currentUser = getState().user.user;
      return heartbeatsService.getHeartbeatsWeeks(currentUser)
      .then( response => {
        const weeks = heartbeatsFormatter.formatWeeks(response);   
        dispatch(success(weeks));
        return weeks;
      })
      .catch( error => {
        dispatch(failure(error));
      })
    };
    function request(){ return { type: heartbeatsConstants.GET_HEARTBEATS_WEEKS_REQUEST } };
    function success(payload){ return { type: heartbeatsConstants.GET_HEARTBEATS_WEEKS_SUCCESS, payload: payload } };
    function failure(error){ return { type: heartbeatsConstants.GET_HEARTBEATS_WEEKS_FAILURE, payload: error } };
  }


function getPagedHeartAttacks(params){
    return ( dispatch, getState ) => {
      dispatch(request());
      // exclude tiktok metrics filter for other vendors
      const vendor = find(params.filtered, param=>param.id == 'vend_id');
      if(vendor && vendor.value && vendor.value != 17) {
          const creations = find(params.filtered, param=>param.id == 'tiktok_creations');
          if(creations)
              creations.value = 0;
      }
      //      
      heartbeatsService.getPagedHeartAttacks(params)
      .then( response => {
          response = heartbeatsFormatter.formatPagedHeartbeats(response, params, true);
        dispatch(success(response));
      })
      .catch( error => {
        dispatch(failure(error));
      })
    };
    function request(){ return { type: heartbeatsConstants.GET_PAGED_HEARTATTACKS_REQUEST } };
    function success(payload){ return { type: heartbeatsConstants.GET_PAGED_HEARTATTACKS_SUCCESS, payload: payload } };
    function failure(error){ return { type: heartbeatsConstants.GET_PAGED_HEARTATTACKS_FAILURE, payload: error } };
  }


function getHeartAttacksWeeks(){
    return ( dispatch, getState ) => {
      dispatch(request());
      const currentUser = getState().user.user;
      return heartbeatsService.getHeartAttacksWeeks(currentUser)
      .then( response => {
        const weeks = heartbeatsFormatter.formatWeeks(response);   
        dispatch(success(weeks));
        return weeks;
      })
      .catch( error => {
        dispatch(failure(error));
      })
    };
    function request(){ return { type: heartbeatsConstants.GET_HEARTATTACKS_WEEKS_REQUEST } };
    function success(payload){ return { type: heartbeatsConstants.GET_HEARTATTACKS_WEEKS_SUCCESS, payload: payload } };
    function failure(error){ return { type: heartbeatsConstants.GET_HEARTATTACKS_WEEKS_FAILURE, payload: error } };
  }
