import React from 'react';

class UnsupportedReport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    componentDidMount() {
    }
    
    render (){
        return <div>
            <p className="no-margins">this report type is not supported</p> 
        </div>
    }
}
export default UnsupportedReport;