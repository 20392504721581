import { projectsConstants } from '../constants/projects';

export function projects(state = {data: [], project: {}, card: {}, autoCompleteLoading: false, 
        demographicsComparison: {}, territoriesComparison: {}, vendorsComparison: {}, artistComparison: {} }, action) {
    switch (action.type) {
        case projectsConstants.GET_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case projectsConstants.GET_PROJECTS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.projects,
                metadata: action.metadata,
                total: action.total
            };
        case projectsConstants.GET_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case projectsConstants.GET_TOP_PROJECTS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                metadata: null,
                timeseries: null
            };
        case projectsConstants.GET_TOP_PROJECTS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.projects,
                metadata: action.metadata
            };
        case projectsConstants.GET_TOP_PROJECTS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };

        case projectsConstants.GET_PROJECTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case projectsConstants.GET_PROJECTS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.projects
            };
        case projectsConstants.GET_PROJECTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };
        case projectsConstants.GET_PROJECT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case projectsConstants.GET_PROJECT_DETAILS_SUCCESS:
            let project = state.project;
            project[action.id] = action.project;
            return {
                ...state,
                loading: false,
                project: project
            };
        case projectsConstants.GET_PROJECT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case projectsConstants.GET_PROJECTS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case projectsConstants.GET_PROJECTS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card
            };
        case projectsConstants.GET_PROJECTS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case projectsConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true,
                streams: undefined
            };
        case projectsConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.projects
            };
        case projectsConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error,
                streams: undefined
            };
        case projectsConstants.GET_PROJECTS_BY_BARCODES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case projectsConstants.GET_PROJECTS_BY_BARCODES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.projects
            };
        case projectsConstants.GET_PROJECTS_BY_BARCODES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case projectsConstants.GET_TRACKS_REQUEST:
            return {
                ...state,
                tracksLoading: true,
                tracks: null
            };
        case projectsConstants.GET_TRACKS_SUCCESS:
            return {

                ...state,
                tracksLoading: false,
                tracks: action.tracks,
            };
        case projectsConstants.GET_TRACKS_FAILURE:
            return {
                ...state,
                tracksLoading: false,
                error: action.error
            };


        case projectsConstants.GET_TIMESERIES_PROJECTS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case projectsConstants.GET_TIMESERIES_PROJECTS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.projects
            };
        case projectsConstants.GET_TIMESERIES_PROJECTS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };
            
        case projectsConstants.COMPARE_PROJECTS_REQUEST:
            return {
                ...state,
                comparisonLoading: true
            };
        case projectsConstants.COMPARE_PROJECTS_SUCCESS:
            return {

                ...state,
                comparisonLoading: false,
                comparison: action.projects,
            };
        case projectsConstants.COMPARE_PROJECTS_FAILURE:
            return {
                ...state,
                comparisonLoading: false,
                error: action.error
            };

        case projectsConstants.COMPARE_PROJECT_DEMOGRAPHICS_REQUEST:
            return {
                ...state,
                demographicsComparisonLoading: true
            };
        case projectsConstants.COMPARE_PROJECT_DEMOGRAPHICS_SUCCESS:
            return {

                ...state,
                demographicsComparisonLoading: false,
                demographicsComparison: {
                    ...state.demographicsComparison,
                    [action.id]: action.project
                }
                    
                    
            };
        case projectsConstants.COMPARE_PROJECT_DEMOGRAPHICS_FAILURE:
            return {
                ...state,
                demographicsComparisonLoading: false,
                error: action.error
            };

        case projectsConstants.COMPARE_PROJECT_TERRITORIES_REQUEST:
            return {
                ...state,
                territoriesComparisonLoading: true
            };
        case projectsConstants.COMPARE_PROJECT_TERRITORIES_SUCCESS:
            return {

                ...state,
                territoriesComparisonLoading: false,
                territoriesComparison: {
                    ...state.territoriesComparison,
                    [action.id]: action.project
                }
                    
                    
            };
        case projectsConstants.COMPARE_PROJECT_TERRITORIES_FAILURE:
            return {
                ...state,
                territoriesComparisonLoading: false,
                error: action.error
            };
            
        case projectsConstants.COMPARE_PROJECT_VENDORS_REQUEST:
            return {
                ...state,
                vendorsComparisonLoading: true
            };
        case projectsConstants.COMPARE_PROJECT_VENDORS_SUCCESS:
            return {

                ...state,
                vendorsComparisonLoading: false,
                vendorsComparison: {
                    ...state.vendorsComparison,
                    [action.id]: action.project
                }
                    
                    
            };
        case projectsConstants.COMPARE_PROJECT_VENDORS_FAILURE:
            return {
                ...state,
                vendorsComparisonLoading: false,
                error: action.error
            };

        case projectsConstants.COMPARE_PROJECT_ARTIST_REQUEST:
            return {
                ...state,
                artistComparisonLoading: true
            };
        case projectsConstants.COMPARE_PROJECT_ARTIST_SUCCESS:
            return {
        
                ...state,
                artistComparisonLoading: false,
                artistComparison: {
                    ...state.artistComparison,
                    [action.id]: action.product
                }
                    
                    
            };
        case projectsConstants.COMPARE_PROJECT_ARTIST_FAILURE:
            return {
                ...state,
                artistComparisonLoading: false,
                error: action.error
            };

            
        default:
            return state
    }
}
