export const imprintsConstants = {
    GET_IMPRINTS_REQUEST: 'GET_IMPRINTS_REQUEST',
    GET_IMPRINTS_SUCCESS: 'GET_IMPRINTS_SUCCESS',
    GET_IMPRINTS_FAILURE: 'GET_IMPRINTS_FAILURE',
    GET_IMPRINTS_AUTOCOMPLETE_REQUEST: 'GET_IMPRINTS_AUTOCOMPLETE_REQUEST',
    GET_IMPRINTS_AUTOCOMPLETE_SUCCESS: 'GET_IMPRINTS_AUTOCOMPLETE_SUCCESS',
    GET_IMPRINTS_AUTOCOMPLETE_FAILURE: 'GET_IMPRINTS_AUTOCOMPLETE_FAILURE',
    GET_TOP_IMPRINTS_REQUEST: 'GET_TOP_IMPRINTS_REQUEST',
    GET_TOP_IMPRINTS_SUCCESS: 'GET_TOP_IMPRINTS_SUCCESS',
    GET_TOP_IMPRINTS_FAILURE: 'GET_TOP_IMPRINTS_FAILURE',
    GET_IMPRINT_DETAILS_REQUEST: 'GET_IMPRINT_DETAILS_REQUEST',
    GET_IMPRINT_DETAILS_SUCCESS: 'GET_IMPRINT_DETAILS_SUCCESS',
    GET_IMPRINT_DETAILS_FAILURE: 'GET_IMPRINT_DETAILS_FAILURE',    
    GET_IMPRINTS_CARD_REQUEST: 'GET_IMPRINTS_CARD_REQUEST',
    GET_IMPRINTS_CARD_SUCCESS: 'GET_IMPRINTS_CARD_SUCCESS',
    GET_IMPRINTS_CARD_FAILURE: 'GET_IMPRINTS_CARD_FAILURE',
    GET_STREAMS_STATS_REQUEST: 'GET_STREAMS_STATS_REQUEST',
    GET_STREAMS_STATS_SUCCESS: 'GET_STREAMS_STATS_SUCCESS',
    GET_STREAMS_STATS_FAILURE: 'GET_STREAMS_STATS_FAILURE',
    GET_TIMESERIES_IMPRINTS_REQUEST: 'GET_TIMESERIES_IMPRINTS_REQUEST',
    GET_TIMESERIES_IMPRINTS_SUCCESS: 'GET_TIMESERIES_IMPRINTS_SUCCESS',
    GET_TIMESERIES_IMPRINTS_FAILURE: 'GET_TIMESERIES_IMPRINTS_FAILURE',   
    
}