import React from 'react';
import { find, orderBy } from 'lodash';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import PercentageValue from '../PercentageValue';
import VendorLogo from '../VendorLogo';

var userAltSvg = require('!svg-inline-loader!../../../../public/img/user-alt.svg');
var headphonesSvg = require('!svg-inline-loader!../../../../public/img/headphones.svg');

class TrendsBox extends React.Component {
    
    render() {
        let total = 0,
            prevTotal = 0,
            listenersTotal = 0,
            prevListenersTotal = 0,
            trend = find(this.props.trends, {'name': this.props.name}),
            vendors = [];
            if(trend) {
                trend.vendor = orderBy(trend.vendor, ['curr_units'], ['desc']);
                vendors = trend.vendor.map(vendor=>{
                    total += vendor.curr_units,
                    prevTotal += vendor.prev_units;
                    listenersTotal += vendor.curr_listeners,
                    prevListenersTotal += vendor.prev_listeners;
                    
                    return <div className="col trend-num" key={vendor.name}>
                        <VendorLogo name={vendor.name} />     
                        <PercentageValue field="units" item={vendor} />
                        {/*<PercentageValue field="listeners" item={vendor} />*/}
                    </div>
                });
            }   
        
        return total ? <div className="trends">
            <div className="total">
                <div className="bold total-item" title={total}>
                    <span className="chart-icon chart-icon--small" dangerouslySetInnerHTML={{__html: headphonesSvg}}></span><span>{convertToThousands(total)}</span></div>
                {/*                    
                    <div className="bold total-item" title={listenersTotal}><span className="chart-icon chart-icon--small" dangerouslySetInnerHTML={{__html: userAltSvg}}></span><span>{convertToThousands(listenersTotal)}</span></div>
                */}
            </div>
            <div className="expanded table-vendor-details">
                {vendors}
            </div>
        </div> : null;
    }

}

export default TrendsBox