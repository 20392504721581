import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {Helmet} from 'react-helmet';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../../components/common/Box';
import { projectsActions } from '../../data/actions/projects';
import EntityCard from '../../components/common/EntityCard';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import CSV from '../../helpers/CSVExport';
import SharePage from '../../components/pages/sharedPages/SharePage';
import SharedEntityCard from '../../components/common/SharedEntityCard';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import Milestones from '../../components/pages/audience/Milestones';
import { dspLogos } from "../../components/common/Stats/utils";
import PDFDocument from '../../components/pages/projects/PDF';
import {Widget, WithWidgetManager} from '../../components/widgets/manager';
import RedirectToResults from '../../components/pages/result/RedirectToResults';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
        };
        this.setParentEntity = this.setParentEntity.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id});
    }

    componentWillReceiveProps(nextProps){
        const { match } = nextProps;
        if(match.params.id != this.state.id) {
            this.setState({id: null}, ()=>{
                this.setState({id: match.params.id});
            });
        }        
    }

    setParentEntity(entity) {
        this.setState({
            title: entity.name_raw,
            titlePdf: `${entity.name_raw} (${entity.code}). Released on: ${entity.release_date}.`
        })
    }

    exportToCsv = () => {
      const {
        playlists,
        tracks,
        stats
      } = this.props;

      const data = [
        { top_platforms: stats.vendor.data },
        { top_playlists: playlists.top },
        { top_tracks: tracks.top },
        { sources: stats.sources.table },
        { demographics: stats.demographics.table },
        { territories: stats.territory.table },
        { devices: stats.devices.table }
      ];
      
      const filename = CSV.CSVHeader('project_details', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);

    }
    
    getPdfData() {
        const { id } = this.props.match.params,
            reportTitle = 'Project Details',
            { tracks, playlists, stats } = this.props;
            
        const imageIDs = [
              'project_details_vendors',
              'project_details_demographics',
              'project_details_sources',
              'project_details_devices',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }

        let vendorTableData = [];

        if (this.props.stats.vendor)
            vendorTableData = this.props.stats.vendor.table

        const tables = {
            tracks: tracks.top,
            playlists: playlists.top,
            territories: stats.territory.table,
            vendorsTable: vendorTableData
        }
        return { reportTitle, images, tables , globalFilter: this.props.filter.global, entity: this.state.titlePdf };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "project_details.pdf";
            link.click();
        });
    }
    

    render() {
        if(!this.state.id)
            return null;
        let metaTitle = this.state.title ? `${this.state.title} - ` : '';
        metaTitle += 'Details';
        const { sharedMode, widgetProps } = this.props;
        return <RedirectToResults entity="projects" id={this.state.id} />
        return <div className="project-details">
                <div className="">
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <div className="share-page-title-holder">
                                <MatchMediaWrapper breakpoint={768} isMobile={false}>
                                    <h2>Project Details</h2>
                                    {!sharedMode && <SharePage entity="projects" id={this.state.id} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} />}
                                </MatchMediaWrapper>
                            </div>
                            {!sharedMode && <EntityCard entity="projects" id={this.state.id} onResult={this.setParentEntity} showLink={true}/>}
                            {sharedMode && <SharedEntityCard entity="projects" id={this.state.id} onResult={this.setParentEntity} />}
                        </div>
                    </div>
                    {sharedMode && <div className="row download-page">
                        <span>Export</span>
                        <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                        <span>PDF</span>
                        <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                    
                    </div>}

                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <Widget widgetID="vendors" widgetProps={widgetProps}>
                                <VendorsWidget entity="projects" id={this.state.id} parentEntityTitle={this.state.title}  shadowChartProps={{id: 'project_details_vendors', shadowChartWidth: 800, shadowChartHeight: 400}} />
                            </Widget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                                <EntitiesList trends={true} title="Top Tracks" entity="tracks" parentEntity="projects" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} externalLinks={!sharedMode} />
                            </Widget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                                <EntitiesList trends={true} title="Top Playlists" entity="playlists" parentEntity="projects" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} showPlaylistAnalisysLink={true} externalLinks={!sharedMode} />
                            </Widget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xs-12 pd-0 default territory-details">
                            <Widget widgetID="sources" widgetProps={widgetProps}>
                                <SourcesStats entity="projects" ids={this.state.id}  parentEntityTitle={this.state.title} chartType={dspLogos.sources.id}  shadowChartProps={{id: 'project_details_sources', shadowChartWidth: 400, shadowChartHeight: 300}} />
                            </Widget>
                        </div>

                        <div className="col-lg-6 col-xs-12 pd-0 default">
                            <Widget widgetID="demographics" widgetProps={widgetProps}>
                                <DemographicsStats entity="projects" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.age.id} shadowChartProps={{id: 'project_details_demographics', shadowChartWidth: 800, shadowChartHeight: 400}} />
                            </Widget>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-xs-12 pd-0 default territory-details">
                            <Widget widgetID="territories" widgetProps={widgetProps}>
                                <TerritoryStats entity="projects" ids={this.state.id}  parentEntityTitle={this.state.title} chartType={dspLogos.territories.id} />
                            </Widget>
                        </div>
                        <div className="col-lg-6 col-xs-12 pd-0 default">
                            <Widget widgetID="devices" widgetProps={widgetProps}>
                                <DevicesStats entity="projects" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.devices.id} shadowChartProps={{id: 'project_details_devices', shadowChartWidth: 400, shadowChartHeight: 300}} />
                            </Widget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <Milestones entity="projects" id={this.state.id} sharedMode={sharedMode} />
                        </div>
                    </div>
                    
                    <Helmet>
                        <title>{metaTitle}</title>
                    </Helmet>
                    
                </div>
            </div>;
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects,
        filter: state.filter,
        stats: state.stats,
        playlists: state.playlists,
        tracks: state.tracks
    }
}

export default connect(mapStateToProps)(WithWidgetManager(ProjectDetails, 'project_details'))