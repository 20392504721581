import React from 'react';
import { map } from 'lodash';
import PercentageValue from '../../../common/PercentageValue';
import CountryFlag from '../../../common/CountryFlag';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import { avgFooter, convertSumFooter } from '../../../common/Datagrid/Footers';

export const territorySettings = {
    settingsForTable
};


function settingsForTable(mode){
    let columns = [
      {
        id: "logo",
        className: "logo-sell frozen",
        headerClassName: "logo-sell frozen no-sort",
        Header: "",
        accessor: "name",
        sortable: false,
        width: 123,
        Cell: (props) => {
          return (
            <CountryFlag
              className="flag-default"
              country={props.original.code}
            />
          );
        },
      },
      {
        id: "share",
        headerClassName: "",
        Header: "Share",
        // width: 70,
        accessor: "share",
        Cell: (props) => <span>{props.value}%</span>,
      },

      {
        id: "name",
        className: "imprint-name",
        headerClassName: "imprint-name",
        Header: "Country",
        accessor: "name",
        Footer: 'Total: '
        // width: 190
      },
      {
        id: "total",
        headerClassName: "total",
        className: "total",
        Header: "Streams",
        Cell: (props) => (
          <PercentageValue
            field="units"
            item={props.original}
          ></PercentageValue>
        ),
        // width: 70,
        accessor: "curr_units",
        Footer: convertSumFooter
      },
      {
        id: "playlists",
        headerClassName: "total",
        className: "total",
        Header: "Playlists",
        Cell: (props) => (
          <PercentageValue
            field="playlists"
            item={props.original}
          ></PercentageValue>
        ),
        // width: 70,
        accessor: "curr_playlists",
        Footer: convertSumFooter
      },
    ];
    return columns;
}
