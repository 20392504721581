import React from 'react';
import { find } from 'lodash';
import WorldMap from '../../../common/WorldMap';
import AbsoluteValue from '../../../common/AbsoluteValue';
import PercentageValue from '../../../common/PercentageValue';
import ReactTable from "react-table";
import cellHeaderHOC from '../../../common/Datagrid/CellHeaderHOC';
import { territorySettings } from './Settings';
import {convertToThousands} from '../../../../helpers/NumberFormatter';
const CellHeaderTable = cellHeaderHOC(ReactTable);

class TerritoryStatsMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCountry: null,

        };
        this.onTerritoryClick = this.onTerritoryClick.bind(this);         
    };    
    


    onTerritoryClick(code) {
        if(this.props.territoryClick)
            return this.props.territoryClick(code);
    }
    
    renderSelectedRow (data, code) {
        const selectedRow = find(data, {territory: code});
        if(selectedRow) {
            return <div className="territory-details-description">
            <h2 className="content-title lowercase">{selectedRow.share}% of your streams </h2>
            <h4 className="content-subtitle">are from {selectedRow.territory_name}</h4>
            <div className={`description ${selectedRow.units_diff > 0 ? 'up' : 'down'}`}>
                <span className="icon fa"></span>
                <p>You have <strong><AbsoluteValue value={selectedRow.curr_units} /></strong> streams, with a <span className="num lowercase"><PercentageValue field="units" item={selectedRow} onlyPercentage={true} /></span> <span className="growth-text">{selectedRow.units_diff > 0 ? 'growth' : 'shrinkage'}</span></p> 
            </div>
            </div>    
        }
    }
        

    render () {
        const { expanded, data, id, topCountryThreshold, averageCountryThreshold } = this.props;
        let topQuantity = 10,
            threshold = 100,
            topCount = 0,
            topSum = 0,
            countriesOverThreshold = 0,
            countries = [];        
        if(data && data.table){
            countries = data.table;
            
            for(let country of countries){
                let streams = Number(country.listeners);
                if(topCount<topQuantity) {
                    topCount++;
                    topSum += streams;
                } 
                if(streams<threshold)
                    continue;
                countriesOverThreshold++;
            }
        }

        return <div className="territory-map-container-row">
            <div className="territory-map-item" id={id}>
                <div className="tooltip-holder">
                <h3 className="content-title lowercase">{countriesOverThreshold} countries</h3>
                {/*<i className="fa fa-question entity-question-sign entity-question-sign--tooltip"></i>*/}
              </div>
              <h4 className="content-subtitle lowercase">With over {convertToThousands(threshold)} listeners</h4>
              {/*
              <div className="description up">
                  <span className="icon fa"></span>    
                  <p>You have <span className="num">+11%</span> in comparison with the previous month.</p>
              </div>
              */}
              <div className="data-title">
                  <div>
                      Listeners by Top {topQuantity} Countries
                  </div>
                  <div className="lowercase">
                      {/*actual count*/}
                      {convertToThousands(topSum)}                                
                  </div>                                
              </div>
              <div className="data-block"> 
                  {countries.slice(0, topQuantity).map(country=>{return <div key={country.code} className="data-block__item">
                      <div className="name">
                          {country.name}
                      </div>
                      <div className="value">
                          {convertToThousands(country.value)}                                
                      </div>                                
                  </div>})}
              </div>

            </div>
            <div className={'territory-map-item territory-map-holder'}>
                <WorldMap data={data.world} label="Listeners" onRegionClick={this.onTerritoryClick} /> 
                <p className="territory-action-text">Click on the territory for detailed demographic data</p>
            </div>
        </div>
    }
}
export default TerritoryStatsMap;