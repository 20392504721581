import { errorActions } from './error';
import { sharedPagesActions } from './shared_pages';
import { mailingListsConstants } from '../constants/mailing_lists';
import { mailingListsService } from '../services/mailing_lists';
import { mailingListsFormatter } from '../formatters/mailing_lists';
import { find } from 'lodash';

export const mailingListsActions = {
    getMailingLists,
    getMailingListsByRecipient,
    getMailingListByEntityFilter,
    getMailingListDetails,
    getMailingListRecipients,
    addMailingList,
    editMailingList,
    deleteMailingList,
    addRecipient,
    deleteRecipient,
	resendInvitation
};

function getMailingListByEntityFilter(entityFilter, useCache = true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return dispatch(getMailingLists(useCache))
            .then(
                mailingLists => {
                    const mailingList = find(mailingLists, list=>list.user_filter_id == entityFilter);
                    if(mailingList) {
                        dispatch(success(mailingList.id, mailingList));
                    }
                    else {
                        dispatch(success(null));
                    }
                    return mailingList;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
 
    };

    function request() { return { type: mailingListsConstants.GET_MAILING_LIST_DETAILS_REQUEST } }
    function success(id, mailingList) { return { type: mailingListsConstants.GET_MAILING_LIST_DETAILS_SUCCESS, id, mailingList } }
    function failure(error) { return { type: mailingListsConstants.GET_MAILING_LIST_DETAILS_FAILURE, error } }
}

function getMailingLists(useCache = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const { mailingLists } = getState().mailingLists;
        const { filters } = getState().userEntityFilters;
        if(useCache && mailingLists) {
            dispatch(success(mailingLists));
            return Promise.resolve(mailingLists);
        }
        return mailingListsService.getMailingLists()
            .then(
                mailingLists => {
                    const data = mailingListsFormatter.formatMailingLists(mailingLists, filters);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: mailingListsConstants.GET_MAILING_LISTS_REQUEST } }
    function success(mailingLists) { return { type: mailingListsConstants.GET_MAILING_LISTS_SUCCESS, mailingLists } }
    function failure(error) { return { type: mailingListsConstants.GET_MAILING_LISTS_FAILURE, error } }
}

function getMailingListDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        mailingListsService.getMailingListDetails(id, globalFilter)
            .then(
                mailingList => {
                    mailingList = mailingListsFormatter.formatMailingList(mailingList);
                    dispatch(success(id, mailingList));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
 
    };

    function request() { return { type: mailingListsConstants.GET_MAILING_LIST_DETAILS_REQUEST } }
    function success(id, mailingList) { return { type: mailingListsConstants.GET_MAILING_LIST_DETAILS_SUCCESS, id, mailingList } }
    function failure(error) { return { type: mailingListsConstants.GET_MAILING_LIST_DETAILS_FAILURE, error } }
}

function getMailingListRecipients(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return mailingListsService.getMailingListRecipients(id)
            .then(
                recipients => {
                    recipients = mailingListsFormatter.formatMailingListRecipients(recipients);
                    dispatch(success(id, recipients));
                    return recipients;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
 
    };

    function request() { return { type: mailingListsConstants.GET_MAILING_LIST_RECIPIENTS_REQUEST } }
    function success(id, recipients) { return { type: mailingListsConstants.GET_MAILING_LIST_RECIPIENTS_SUCCESS, id, recipients } }
    function failure(error) { return { type: mailingListsConstants.GET_MAILING_LIST_RECIPIENTS_FAILURE, error } }
}


function addMailingList(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const { user_filter_id: entityFilterID, filter_id: globalFilterID } = params;
        const entityFilters = getState().userEntityFilters.filters,
            globalFilters = getState().filter.data;
        const entityFilter = find(entityFilters, filter=>filter.id == entityFilterID),
            globalFilter = find(globalFilters, filter=>filter.id == globalFilterID);
        const currentFilter = getState().filter.global;
        
        return dispatch(sharedPagesActions.shareFilter(entityFilter, globalFilter, currentFilter))
                .then(sharedPage => {
                    params.shared_page_slug = sharedPage.slug;
                    return mailingListsService.addMailingList(params)
                    .then(
                        mailingList => {
                            mailingList = mailingListsFormatter.formatMailingList(mailingList);
                            dispatch(success());
                            //dispatch(mailingListsActions.getMailingLists())
                            dispatch(errorActions.setSuccessMessage('Notification Saved'));
                            return mailingList;
                        },
                        error => {
                            dispatch(failure())
                            dispatch(errorActions.setErrorMessage('Error Saving Notification'))
                        }
                    );
                })
         
    };
    function request() { return { type: mailingListsConstants.ADD_MAILING_LIST_REQUEST } }
    function success() { return { type: mailingListsConstants.ADD_MAILING_LIST_SUCCESS } }
    function failure() { return { type: mailingListsConstants.ADD_MAILING_LIST_FAILURE } }

}

function editMailingList(params, id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return mailingListsService.editMailingList(params, id)
            .then(
                mailingList => {
                    mailingList = mailingListsFormatter.formatMailingList(mailingList);
                    dispatch(success(mailingList, id));
                    //dispatch(mailingListsActions.getMailingLists())
                    dispatch(errorActions.setSuccessMessage('Notification Saved'))
                    return mailingList;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error Saving Notification'))
                }
            );
 
    };
    function request() { return { type: mailingListsConstants.ADD_MAILING_LIST_REQUEST } }
    function success() { return { type: mailingListsConstants.ADD_MAILING_LIST_SUCCESS } }
    function failure() { return { type: mailingListsConstants.ADD_MAILING_LIST_FAILURE } }

}

function deleteMailingList(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return mailingListsService.deleteMailingList(id)
            .then(
                mailingList => {
                    dispatch(success());
                    return dispatch(mailingListsActions.getMailingLists())
                    dispatch(errorActions.setSuccessMessage('Notification Removed'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error Saving Notification'))
                }
            );
 
    };
    function request() { return { type: mailingListsConstants.ADD_MAILING_LIST_REQUEST } }
    function success() { return { type: mailingListsConstants.ADD_MAILING_LIST_SUCCESS } }
    function failure() { return { type: mailingListsConstants.ADD_MAILING_LIST_FAILURE } }

}

function addRecipient(email, mailingListID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return mailingListsService.addRecipient(email, mailingListID)
            .then(
                result => {
                    dispatch(success());
                    return true;
                },
                error => {
                    dispatch(failure())
                }
            );
    };

    function request() { return { type: mailingListsConstants.ADD_RECIPIENT_REQUEST } }
    function success(channel) { return { type: mailingListsConstants.ADD_RECIPIENT_SUCCESS, channel } }
    function failure() { return { type: mailingListsConstants.ADD_RECIPIENT_FAILURE} }
}


function deleteRecipient(id, mailingListID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return mailingListsService.deleteRecipient(id, mailingListID)
            .then(
                result => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('Recipient Deleted'))
                    return true;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error deleting channel'))
                }
            );
    };

    function request() { return { type: mailingListsConstants.ADD_RECIPIENT_REQUEST } }
    function success() { return { type: mailingListsConstants.ADD_RECIPIENT_SUCCESS } }
    function failure() { return { type: mailingListsConstants.ADD_RECIPIENT_FAILURE} }
}

function getMailingListsByRecipient() {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;        
        return mailingListsService.getMailingListsByRecipient(currentUser.id)
            .then(
                mailingLists => {
                    const data = mailingListsFormatter.formatMailingListsByRecipient(mailingLists);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: mailingListsConstants.GET_MAILING_LISTS_BY_RECIPIENT_REQUEST } }
    function success(mailingLists) { return { type: mailingListsConstants.GET_MAILING_LISTS_BY_RECIPIENT_SUCCESS, mailingLists } }
    function failure(error) { return { type: mailingListsConstants.GET_MAILING_LISTS_BY_RECIPIENT_FAILURE, error } }
}

function resendInvitation(id) {
	return ( dispatch, getState ) => {
		dispatch(request());
		return mailingListsService.resendInvitation(id)
			.then(
				mailingList => {
					dispatch(success());
					dispatch(errorActions.setSuccessMessage('Invitation Resent'))
				},
				error => {
					dispatch(failure())
					dispatch(errorActions.setErrorMessage('Error Resending'))
				}
			);
 
	};
	function request() { return { type: mailingListsConstants.ADD_RECIPIENT_REQUEST } }
	function success() { return { type: mailingListsConstants.ADD_RECIPIENT_SUCCESS } }
	function failure() { return { type: mailingListsConstants.ADD_RECIPIENT_FAILURE } }

}
