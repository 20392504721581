import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CopyToClipboard from '../../common/CopyToClipboard';
import EntityCard from '../../common/EntityCard';
import AbsoluteValue from '../../common/AbsoluteValue';

export const spotifyMonetizationSettings = {
	settingsForTable
};

function settingsForTable(){

	let columns = [
		{
			id: 'expander',
	   		expander: true,
	   		width: 35,
	   		Expander: ({ isExpanded, ...rest }) => (
		 		<div>
		   		{isExpanded ? (
			 		<span>&#x25BC;</span>
		   		) : (
			 		<span>&#x25B6;</span>
		   		)}
		 		</div>
	   		),
	   		style: {
		 		cursor: "pointer",
		 		fontSize: 10,
		 		padding: "0",
		 		textAlign: "center",
		 		userSelect: "none"
	   		}
		},		
	   	{
			id: 'logo',
			className: 'logo-sell ',
			headerClassName: 'logo-sell  no-sort',
			Header: 'Logo',
			accessor: 'image',
			width: 60,
			sortable: false,
			required: true,
			Cell: props => {
				return <div className="logo-container">
					<Link to={`/tracks/${props.original.id}`}>
						<div className="logo" style={{backgroundImage: `url(${props.original.image})`}} />
					</Link>
				</div>
			}
		},
		{
			id: "artist_name",
			className: "imprint-name ",
			headerClassName: "imprint-name  no-sort",
			Header: "Track",
			accessor: "artist",
			sortable: false,
			required: true,
			width: 260,
			Cell: props => {
				const { id, artist, title, isrc } = props.original;
				return <React.Fragment>
					<span>{props.original.id ? <Link to={`/tracks/${props.original.id}`}>{title}</Link> : title}</span>                
					<span>{artist}</span>
					<span className="isrc-holder with-copy">
					{id ? <CopyToClipboard value={isrc}>{isrc}</CopyToClipboard> : <span>{isrc}</span>}</span>
				</React.Fragment>
			}
	    },  
		{
		   id: 'end_month_status',
		   Header: 'Status',
		   accessor: 'lastMonthStatus'
		},                   		
		{
		   id: 'release_type',
		   Header: 'New Release',
		   accessor: 'release_type',
		   Cell: props => <span>{props.value == 'new_release' ? '✓' : '✗'}</span>
		},                   		

		{
			id: 'end_month_streams',
			Header: 'Latest Month Streams',
			accessor: 'lastMonthStreams',
			Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
		},
		{
			id: 'end_month_listeners',
			Header: 'Latest Month Listeners',
			accessor: 'lastMonthListeners',
			Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
		},
		{
			id: 'total_streams',
			Header: 'Date Range Streams',
			accessor: 'totalStreams',
			Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
		},
		{
			id: 'monetized_streams',
			Header: 'Date Range Monetized Streams',
			accessor: 'totalMonetized',
			Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
		},
		{
			id: 'unmonetized_streams',
			Header: 'Date Range Unmonetized Streams',
			accessor: 'totalUnmonetized',
			Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
		},

		{
			id: 'last_unmonetized_month',
			Header: 'Last Unmonetized Since',
			accessor: 'unmonetizedSince',
		},
		{
			id: 'times_unmonetized',
			Header: '# of Months Unmonetized',
			accessor: 'monthsUnmonetized',
		}
	];
					
	return columns; 
}
