import { notificationsConstants } from '../constants/notifications';
import { notificationsService } from '../services/notifications';
import { notificationsFormatter } from '../formatters/notifications';
import { catalogueExceptionsActions } from '../../data/actions/catalogue_exceptions';
import { previousFriday } from '../helpers/heartbeats.js';

export const notificationsActions = {
    getNotificationsCount,
    getStorePlaylistNotifications,
    getHeartbeatsNotifications,
    getReleaseNotifications,
    getCountOfNotifications,
    updateStorePlaylistNotifications,
    updateReleaseNotifications,
    updateHeartbeatsNotifications
};

function getNotificationsCount(mode) {
    return dispatch => {
        dispatch(request());

        return notificationsService.getNotificationsCount(mode)
            .then(
                notifications => {
                    const data = notificationsFormatter.formatNotifications(notifications);
                    dispatch(success(data));
                    notificationsFormatter.notificationsCountFormat(data);
                    if (notificationsFormatter.notificationsCountFormat(data) === true) {
                        return data;
                    } else {
                        return false
                    }
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: notificationsConstants.GET_USER_NOTIFICATIONS_REQUEST } }
    function success(notifications) { return { type: notificationsConstants.GET_USER_NOTIFICATIONS_SUCCESS, notifications } }
    function failure(error) { return { type: notificationsConstants.GET_USER_NOTIFICATIONS_FAILURE, error } }
}

function getStorePlaylistNotifications(date) {
    return dispatch => {
        dispatch(request());

        return notificationsService.getStorePlaylistNotifications(date)
            .then( response => {
               const data = notificationsFormatter.notificationsCountFormat(response);
               data && dispatch(success(response));
               return data && response;
            });
    };

    function request() { return { type: notificationsConstants.GET_STORE_PLAYLIST_NOTIFICATIONS_REQUEST } }
    function success(notifications) { return { type: notificationsConstants.GET_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS, notifications } }
    function failure(error) { return { type: notificationsConstants.GET_STORE_PLAYLIST_NOTIFICATIONS_FAILURE, error } }
}

function getHeartbeatsNotifications(date) {
    return dispatch => {
        dispatch(request());

        return notificationsService.getHeartbeatsNotifications(date)
            .then( response => {

               const data = notificationsFormatter.notificationsCountFormat(response);
               data && dispatch(success(response));
               return data && response;
            })
            .catch( error => {
              failure(error);
            })
    };

    function request() { return { type: notificationsConstants.GET_HEARTBEATS_NOTIFICATIONS_REQUEST } }
    function success(payload) { return { type: notificationsConstants.GET_HEARTBEATS_NOTIFICATIONS_SUCCESS, payload } }
    function failure(error) { return { type: notificationsConstants.GET_HEARTBEATS_NOTIFICATIONS_FAILURE, error } }
}

function getReleaseNotifications(date = previousFriday()){
  return dispatch => {
    dispatch(request());
    return notificationsService.getReleaseNotifications(date)
    .then( response => {
       const data = notificationsFormatter.notificationsCountFormat(response);
       data && dispatch(success(response));
       return data && response;
    })
    .catch( error => {
      dispatch(failure(error));
    });
  }

  function request() { return { type: notificationsConstants.GET_RELEASES_NOTIFICATIONS_REQUEST } };
  function success(payload) { return { type: notificationsConstants.GET_RELEASES_NOTIFICATIONS_SUCCESS, payload } };
  function failure(error) { return { type: notificationsConstants.GET_RELEASES_NOTIFICATIONS_FAILURE, payload: error } };
}


function updateStorePlaylistNotifications(id, cb, date) {
    return dispatch => {
        dispatch(request());

        notificationsService.updateStorePlaylistNotifications(id)
        .then(res => {
                if (cb && typeof cb === 'function') {
                    cb()
                }
                dispatch(getCountOfNotifications(date));
                dispatch(success('ticket was successfully submitted'));
            }
        )
        .catch(error => {
            dispatch(failure('there was an error'));
        });;
    };

    function request() { return { type: notificationsConstants.UPDATE_STORE_PLAYLIST_NOTIFICATIONS_REQUEST } }
    function success(message) { return { type: notificationsConstants.UPDATE_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS, title: '' } }
    function failure(message) { return { type: notificationsConstants.UPDATE_STORE_PLAYLIST_NOTIFICATIONS_FAILURE, title: 'update exceptions error' } }
}

function updateReleaseNotifications(id, date){
  return dispatch => {
    dispatch(request());

    notificationsService.updateReleaseNotifications(id)
    .then(response => {
      dispatch(success());
      dispatch(getCountOfNotifications(date));
    })
    .catch( error => {
      dispatch(success());
      dispatch(getCountOfNotifications(date));
    })
  }

  function request(){ return { type: notificationsConstants.UPDATE_RELEASE_NOTIFICATIONS_REQUEST }}
  function success(){ return { type: notificationsConstants.UPDATE_RELEASE_NOTIFICATIONS_SUCCESS }}
  function failure(){ return { type: notificationsConstants.UPDATE_RELEASE_NOTIFICATIONS_FAILURE }}
}

function updateHeartbeatsNotifications(id, date){
  return dispatch => {
    dispatch(request());

    notificationsService.updateHeartbeatsNotifications(id)
    .then(response => {
      dispatch(getCountOfNotifications(date));
      dispatch(success());
    })
    .catch( error => {
      dispatch(failure());
    })
  }

  function request(){ return { type: notificationsConstants.UPDATE_HEARTBEATS_NOTIFICATIONS_REQUEST }}
  function success(){ return { type: notificationsConstants.UPDATE_HEARTBEATS_NOTIFICATIONS_SUCCESS }}
  function failure(){ return { type: notificationsConstants.UPDATE_HEARTBEATS_NOTIFICATIONS_FAILURE }}
}

function getCountOfNotifications(date) {
  return (dispatch, getState) => {
    dispatch(request());

    let notificationCount = 0;

    const adminRequiredRequests = [dispatch(catalogueExceptionsActions.getCatalogueExceptionsCount())];
    const commonRequiredRequests = [
      dispatch(getHeartbeatsNotifications(date)),
      dispatch(getReleaseNotifications()),
      dispatch(getStorePlaylistNotifications(date)),
    ];

    const requests = getState().user.user.client_admin ? commonRequiredRequests.concat(adminRequiredRequests) : commonRequiredRequests;

    Promise.all(requests)
    .then(values => {
      values.forEach(value => !!value && notificationCount++ );
      dispatch(success(notificationCount));
    });
  };

  function request() { return { type: notificationsConstants.GET_COUNT_OF_NOTIFICATIONS_REQUEST } }
  function success(payload) { return { type: notificationsConstants.GET_COUNT_OF_NOTIFICATIONS_SUCCESS, payload } }
  function failure(error) { return { type: notificationsConstants.GET_COUNT_OF_NOTIFICATIONS_FAILURE, error: error } }
};
