import { audienceConstants } from '../constants/audience';

export function audience(state = {demographicsTerritory: {}}, action) {
    switch (action.type) {
        case audienceConstants.GET_AUDIENCE_STREAMS_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                timeline: null,
                streams_listeners: null
            };
        case audienceConstants.GET_AUDIENCE_STREAMS_STATS_SUCCESS:
            return {
                
                ...state,
                loading: false,
                timeline: action.data,
                streams_listeners: action.audience
            };
        case audienceConstants.GET_AUDIENCE_STREAMS_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST:
            return {
                ...state,
                demographicsLoading: true
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS:
            return {
                
                ...state,
                demographicsLoading: false,
                demographics: action.data
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE:
            return {
                ...state,
                demographicsLoading: false,
                error: action.error
            };
        case audienceConstants.GET_AUDIENCE_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                territoryLoading: true
            };
        case audienceConstants.GET_AUDIENCE_TERRITORY_STATS_SUCCESS:
            return {
                
                ...state,
                territoryLoading: false,
                territory: action.data
            };
        case audienceConstants.GET_AUDIENCE_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                territoryLoading: false,
                error: action.error
            };          

        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                demographicsTerritoryLoading: true
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS:
            const {data, territory} = action;
            return {
                
                ...state,
                demographicsTerritoryLoading: false,
                demographicsTerritory: {
                    ...state.demograhicsTerritory,
                    [territory]: data
                }
            };
        case audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                demographicsTerritoryLoading: false,
                error: action.error
            };
            
        case audienceConstants.GET_AUDIENCE_SOURCE_STATS_REQUEST:
            return {
                ...state,
                sourceStatsLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_STATS_SUCCESS:
            return {
                
                ...state,
                sourceStatsLoading: false,
                sourceStats: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_STATS_FAILURE:
            return {
                ...state,
                sourceStatsLoading: false,
                error: action.error
            };          

            
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_STATS_REQUEST:
            return {
                ...state,
                sourceDiscoveryStatsLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_STATS_SUCCESS:
            return {
                
                ...state,
                sourceDiscoveryStatsLoading: false,
                sourceDiscoveryStats: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_STATS_FAILURE:
            return {
                ...state,
                sourceDiscoveryStatsLoading: false,
                error: action.error
            };          


        case audienceConstants.GET_AUDIENCE_SOURCE_TIMESERIES_REQUEST:
            return {
                ...state,
                sourceTimeseriesLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                sourceTimeseriesLoading: false,
                sourceTimeseries: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_TIMESERIES_FAILURE:
            return {
                ...state,
                sourceTimeseriesLoading: false,
                error: action.error
            };          

            
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_REQUEST:
            return {
                ...state,
                sourceDiscoveryTimeseriesLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                sourceDiscoveryTimeseriesLoading: false,
                sourceDiscoveryTimeseries: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_FAILURE:
            return {
                ...state,
                sourceDiscoveryTimeseriesLoading: false,
                error: action.error
            };          

        case audienceConstants.GET_AUDIENCE_SOURCE_PLAYLISTS_REQUEST:
            return {
                ...state,
                sourcePlaylistsLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_PLAYLISTS_SUCCESS:
            return {
                
                ...state,
                sourcePlaylistsLoading: false,
                sourcePlaylists: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_PLAYLISTS_FAILURE:
            return {
                ...state,
                sourcePlaylistsLoading: false,
                error: action.error
            };          

            
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_REQUEST:
            return {
                ...state,
                sourceDiscoveryPlaylistsLoading: true
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_SUCCESS:
            return {
                
                ...state,
                sourceDiscoveryPlaylistsLoading: false,
                sourceDiscoveryPlaylists: action.data
            };
        case audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_FAILURE:
            return {
                ...state,
                sourceDiscoveryPlaylistsLoading: false,
                error: action.error
            };          

            
        case audienceConstants.GET_AUDIENCE_DEVICE_STATS_REQUEST:
            return {
                ...state,
                deviceLoading: true
            };
        case audienceConstants.GET_AUDIENCE_DEVICE_STATS_SUCCESS:
            return {
                
                ...state,
                deviceLoading: false,
                device: action.data
            };
        case audienceConstants.GET_AUDIENCE_DEVICE_STATS_FAILURE:
            return {
                ...state,
                deviceLoading: false,
                error: action.error
            };          

            
            
        case audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_REQUEST:
        case audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST:            
            return {
                ...state,
                playlistLoading: true
            };
        case audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_SUCCESS:
        case audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS:
            return {
                
                ...state,
                playlistLoading: false,
                playlist: action.data
            };
        case audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_FAILURE:
        case audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE:
            return {
                ...state,
                playlistLoading: false,
                error: action.error
            };          
            
        case audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_REQUEST:
            return {
                ...state,
                repeatListenersLoading: true
            };
        case audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS:
            return {
                
                ...state,
                repeatListenersLoading: false,
                repeatListeners: action.data
            };
        case audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_FAILURE:
            return {
                ...state,
                repeatListenersLoading: false,
                error: action.error
            };          
            
        default:
            return state
    }
} 