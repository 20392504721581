import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import EntityTablePDF from '../home/EntityTable/PDF';
import TerritoryTablePDF from '../home/Territories/PDF';
import DateRange from '../../common/PDF/DateRange';

export default (props) => {
    const { data: { images, tables, reportTitle, globalFilter, entity } } = props;
  return <Document>
    <Page size="A4" style={styles.page}>
    <View style={styles.columnReportTitle}>
        <Text style={styles.header}>{reportTitle}</Text>
        <Text style={styles.smallHeader}>{entity}</Text>
    </View>
    <DateRange filter={globalFilter} />
    <View style={styles.columnSection}>
    <View style={styles.column}>
      <Text style={styles.smallHeader}>Platforms</Text>
      <Image source={images.track_details_vendors} />
      </View>
    </View>
    <View style={styles.columnSection}>
    <View style={styles.column}>
        <Text style={styles.smallHeader}>Top Products</Text>
        <EntityTablePDF entity="products" data={tables.products} />
    </View>
  </View>
    
    <View style={styles.columnSection}>
    <View style={styles.column}>
        <Text style={styles.smallHeader}>Top Playlists</Text>
        <EntityTablePDF entity="playlists" data={tables.playlists} />
    </View>
  </View>
  <View style={styles.columnSection}>
  <View style={styles.column}>
      <Text style={styles.smallHeader}>Age and Gender</Text>
      <Image source={images.track_details_demographics} />
  </View>
  </View>
  <View style={styles.columnSection}>
  <View style={styles.column}>
      <Text style={styles.smallHeader}>Territories</Text>
      <TerritoryTablePDF data={tables.territories} />
  </View>
  </View>
  <View style={styles.section}>
      <View style={styles.half}>
          <Text style={styles.smallHeader}>Sources</Text>
          <Image source={images.track_details_sources} />
      </View>
          <View style={styles.half}>
          <Text style={styles.smallHeader}>Devices</Text>
          <Image source={images.track_details_devices} />
      </View>
  </View>

  </Page>
  </Document>
};