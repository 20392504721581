import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const entityFiltersService = {
  getEntityFilters,
  getAllEntityFilters,
  updateEntityFilter,
  createFilter,
  destroyFilter
}

function destroyFilter(filter){
  const url = `${config.apis.orthus}/v1/entity_filters/${filter.id}`;

  return fetch(url, {
    method: 'DELETE',
    headers: AuthHeader({ 'content-type': 'application/json' })
  }).then( res => res.json());
}

function createFilter(params){

  const url = `${config.apis.orthus}/v1/entity_filters`;

  return fetch( url, {
    method: 'POST',
    headers: AuthHeader({'content-type': 'application/json'}),
    body: JSON.stringify(params)
  }).then( res => res.json());
}

function getEntityFilters(entity){
  const url = `${config.apis.orthus}/v1/entity_filters/by_entity/${entity}`;

  return fetch( url, {
    method: 'GET',
    headers: AuthHeader()
  }).then( res => res.json());
}

function getAllEntityFilters(){
    const url = `${config.apis.orthus}/v1/entity_filters`;

    return fetch( url, {
      method: 'GET',
      headers: AuthHeader()
    }).then( res => res.json());
  }


function updateEntityFilter(filter, entity){
  const url = `${config.apis.orthus}/v1/entity_filters/${filter.id}`;

  return fetch( url, {
    method: 'PUT',
    headers: AuthHeader({'content-type': 'application/json'}),
    body: JSON.stringify({
      body: filter.body,
      is_shared: filter.is_shared
    })
  }).then( res => res.json());
}
