import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AbsoluteValue from '../../common/AbsoluteValue';
import PercentageValue from '../../common/PercentageValue';
import Tooltip from '../../common/Tooltip';
import { avgFooter, convertSumFooter } from '../../common/Datagrid/Footers';
import CountryFlag from '../../common/CountryFlag';
import ConfirmClick from '../../common/ConfirmClick';
import Calendar from './Calendar';
import FilterSummary from './FilterSummary';
import FilterContents from './FilterContents';

export const mailingListsSettings = {
    settingsForTable
};


function settingsForTable(deleteCall, updateScheduleCall){
    let columns = [
       {
           id: 'user_filter',
           Header: 'Filter',
           accessor: 'user_filter',
           Cell: props => <FilterSummary filter={props.value} />
       },      
	   {
			  id: 'user_filter_contents',
			  Header: '',
			  accessor: 'user_filter',
			  Cell: props => <FilterContents filter={props.value} />
		  },      

        {
             id: 'schedule',
             Header: props => <span className="repeats-on-holder">Start Date: <span className="repeats-on">Repeats On:</span></span>,
             className: 'no-overflow',
             headerClassName: 'no-overflow',
             width: 350,
             Cell: props => <Calendar mailingList={props.original} onChange={(schedule)=>updateScheduleCall(schedule, props.original.id)}  /> 
       },
       {
           id: 'actions',
           className: 'milestone-actions',
           headerClassName: 'no-sort milestone-actions',
           Header: 'Actions',
           sortable: false,
           width: 150,
           // <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
           Cell: props => <div className="">
            <Link to={`/user/entities-filter/${props.original.user_filter_id}#manage-notification`}>Edit</Link>
            <ConfirmClick confirmAction={()=>deleteCall(props.original.id)} confirmClass="btn add-to-filter" confirmLabel={<i className="fas fa-trash-alt"></i>} confirmPrompt="Delete Mailing List?" title="Delete" extraClass="dialog-modal"></ConfirmClick>
           </div>
       }
       
    ];
    return columns;
}
