import { userLogsConstants } from '../constants/user_logs';
import { userLogsService } from '../services/user_logs';

export const userLogsActions = {
    getUsers,
    getUserLogs
};

function getUsers() {
    return dispatch => {
        dispatch(request());

        userLogsService.getUsers()
            .then(
                users => {
                    dispatch(success(users.data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: userLogsConstants.GET_USERS_REQUEST } }
    function success(users) { return { type: userLogsConstants.GET_USERS_SUCCESS, users } }
    function failure(error) { return { type: userLogsConstants.GET_USERS_FAILURE, error } }
}

function getUserLogs(userId, page) {
    return dispatch => {
        dispatch(request());

        userLogsService.getUserLogs(userId, page)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: userLogsConstants.GET_USER_LOGS_REQUEST } }
    function success(user) { return { type: userLogsConstants.GET_USER_LOGS_SUCCESS, user } }
    function failure(error) { return { type: userLogsConstants.GET_USER_LOGS_FAILURE, error } }
}