import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';
import { isEqual, maxBy, minBy } from 'lodash';

import CSV from '../../../helpers/CSVExport';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box'
import PlaylistsPie from './Playlists/Pie';
import PlaylistsTable from './Playlists/Table';
import PlaylistsTimeline from './Playlists/Timeline';
import PercentageValue from '../../common/PercentageValue';

import { playlistsActions } from '../../../data/actions/playlists';

var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class TopPlaylists extends React.Component{

    options = [
       {label: 'Most Streams', field: 'curr_units', dir: 'DESC'},
       {label: 'Least Streams', field: 'curr_units', dir: 'ASC'},
       {label: 'Streams Spike', field: 'units_diff', dir: 'DESC'},
    ];
    constructor(props) {
        super(props);
        this.state = {
            sort: this.options[0],
            view: 'table',
            filter: this.getInitialFilter(props)
        }
        this.setChartView = this.setChartView.bind(this);
        this.setSort = this.setSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    getInitialFilter(props) { 
        if(props.parentEntity && props.parentEntityIDs) {
            return [{id: props.parentEntity, value: props.parentEntityIDs}];
        }
        return [];

    }

    componentDidMount(){
        this.props.getPlaylists({sorted: this.state.sort, filtered: this.state.filter});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getPlaylists({sorted: this.state.sort, filtered: this.state.filter}, false);
            }
        }
    }

    exportToCsv(){
        const filename = CSV.CSVHeader(`top_playlists`, this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.getResults(), {filename}, `top_${this.props.entity}`);
    }

    getTopKey(){
        return `top${this.props.parentEntity}`   
    }
    
    getResults(){
        const topKey = this.getTopKey();
        return this.props.playlists[topKey] || [];
    }

    getLoading(){
        const topKey = this.getTopKey();
        return this.props.playlists[`${topKey}Loading`] || false;
    }


    setChartView(view){
        this.setState({view});
    }

    setSort(sort){
        this.setState({sort}, ()=>{
            this.props.getPlaylists({sorted: this.state.sort, filtered: this.state.filter}, false);
        })
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }


    renderToolbar(){

        let toolbar = [];
        //for (let view in views) {
          //  toolbar.push(<a key={view}><i className={`fa fa-${views[view]} ${this.state.view == view ? 'active' : ''}`} onClick={()=>this.setChartView(view)}></i></a>);
        //}
        toolbar.push(<div>
            <div key="sort" className="select-holder"><span className="select-label">Sort by</span>
                <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select sort-select" isSearchable={ false }/>
            </div>
            <div className="ibox-icon-holder">
                <a key='table' title="Table" className={`chart-icon ${this.state.view == 'table' ? 'active' : ''}`}
                    onClick={()=>this.setChartView('table')}
                    dangerouslySetInnerHTML={{__html: tableSvg}}>
                </a>
                <a key='line' title="Line Chart" className={`chart-icon ${this.state.view == 'line' ? 'active' : ''}`}
                    onClick={()=>this.setChartView('line')}
                    dangerouslySetInnerHTML={{__html: lineSvg}}>
                </a>
                <a key='pie' title="Pie Chart" className={`chart-icon ${this.state.view == 'pie' ? 'active' : ''}`}
                    onClick={()=>this.setChartView('pie')}
                    dangerouslySetInnerHTML={{__html: pieSvg}}>
                </a>

                <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
            </div>
        </div>);
        return toolbar;
    }

    getDateRange(){
        if(this.props.filter.global) {
            let { dateStart, dateEnd } = this.props.filter.global;
            return `${moment(dateStart).format('D MMM')} - ${moment(dateEnd).format('D MMM')}`;

        }
        else
            return '';

    }

    render(){
        let topChartClass = '';
        const datasetResults = this.getResults().datasets;

        if (datasetResults.length > 20 && datasetResults.length <= 100) {
            topChartClass = 'top-chart-md'    
        } else if (datasetResults.length > 100 ) {
            topChartClass = 'top-chart-lg'    
        }

        return <Box title={this.props.title} toolbar={this.renderToolbar()} className="home-box" spinnerEnabled={this.getLoading()}>
                <div className="row">
                    <div className={`col-xs-12`}>
                        {!this.getLoading() && <div>
                            {this.state.view == 'table' && <PlaylistsTable entity={this.props.entity} results={this.getResults()} />}
                            {this.state.view == 'line' && <div className={`timeline-holder ${topChartClass}`}><PlaylistsTimeline mode="line" entity={this.props.entity} results={this.getResults()} /></div>}
                            {this.state.view == 'pie' && <div className="chart-holder"><PlaylistsPie entity={this.props.entity} results={this.getResults()} /></div>}
                        </div>}
                    </div>
                </div>
            </Box>
    }
}

function mapStateToProps(state) {
    return {
        playlists: state.playlists,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getPlaylists: (params) => dispatch(playlistsActions.getTopPlaylists(params, ownProps.parentEntity))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TopPlaylists)
