import React from 'react';
import { Bar } from 'react-chartjs-2';
import { map, merge, cloneDeep } from 'lodash';
import alpha from 'color-alpha';
import { colors, stackedBar } from '../ChartDefaults';

class StackedBarChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          //  data: this.buildChartData(props.data),
            options: merge({}, stackedBar, props.options)
        };
        
        this.getData = this.getData.bind(this);
    }

    componentWillReceiveProps(props){
        /*
        this.setState({
            data: this.buildChartData(props.data)
        })
        */
    }
    
    getData(canvas) {
        return this.props.data;
        const ctx = canvas.getContext("2d"),
            data = this.props.data;
        
        
        if(!data)
            return [];
        
        data.dataset = data.dataset.map((entry, index)=>{
//            let gradient = ctx.createLinearGradient(0, 0, 0, canvas.height),
//                color = colors[index%colors.length],
//                alphaColor = alpha(color, 0,9);
            let color = colors[index%colors.length];
            //gradient.addColorStop(0, color);
            //gradient.addColorStop(1, alphaColor);            
            entry.backgroundColor = color;
            //entry.hoverBackgroundColor = color;
            entry.fill = true;
            return entry;
        });
        
        
        
        return data;

    }
    
    addBorders(data) {
        let chartData = cloneDeep(data);
        if(chartData && chartData.datasets && Array.isArray(chartData.datasets)) {
            for(let item of chartData.datasets) {
                item.borderWidth = 2;
                item.borderColor = '#FFF';
            }
        }
        return chartData;
    }
    
    render() {
        let topChartClass = '';

        if (this.props.data && this.props.data.datasets) {
            const { datasets } = this.props.data;

            if (datasets.length > 20 && datasets.length <= 100) {
                topChartClass = 'top-chart-md'    
            } else if (datasets.length > 100 ) {
                topChartClass = 'top-chart-lg'    
            }
        };

        const { data, options, id, shadowChart = false, shadowChartClass = 'chart-export-shadow', shadowChartWidth = 400, shadowChartHeight = 300, ...rest } = this.props;
        return (
            (this.props.data ?
                <React.Fragment>
                    <div className={`chart-block ${topChartClass}`}><div className="chart-block-inner">
                        <Bar data={this.addBorders(this.props.data)} options={this.state.options} ></Bar>
                        {shadowChart && <div className={shadowChartClass}><Bar width={shadowChartWidth} height={shadowChartHeight} id={id} data={this.addBorders(this.props.data)} options={this.state.options} {...rest}></Bar></div>}
                    </div></div>
                </React.Fragment> : null )
        )
    }
}

export default StackedBarChart