import React from 'react';
import moment from 'moment';
import { concat, uniq } from 'lodash';
import {round, splitByThousands } from '../../../helpers/NumberFormatter';
import Tooltip from "../../common/Tooltip";
import VendorBarChart from "./Vendor/BarChart";

class EntityVendors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenVendors: []
        }
        this.getVendors = this.getVendors.bind(this);
        this.excludeVendor = this.excludeVendor.bind(this);
    }

    getName(item) {
        return <span>{item.name} {item.code && <span className="newline">{item.code}</span>} </span>  
    }
    getVendors(item) {
        const {hiddenVendors} = this.state;
        return <VendorBarChart vendors={item.vendors} hiddenVendors={hiddenVendors} />
    }
    excludeVendor(vendor, event, allVendors){
        let hiddenVendors = Object.assign([], this.state.hiddenVendors);

        if (event.shiftKey) {
            if (hiddenVendors.length === 0 || hiddenVendors.includes(vendor)) {
                hiddenVendors = Object.keys(allVendors).filter(v => v !== vendor);
            } else {
                hiddenVendors = [];
            }
        } else {
            if (hiddenVendors.includes(vendor)) {
                hiddenVendors.splice(hiddenVendors.indexOf(vendor), 1);
            } else {
                hiddenVendors.push(vendor);
            }
        }

        this.setState({ hiddenVendors });
    }

    render(){
        const {props} = this;
        const { hiddenVendors } = this.state;
        let headers = [
            {text: 'Name'},  
            {text: 'Streams'}, 
            {text: 'Streams growth'}, 
            {text: 'Platforms'}, 
        ],
        cells = (item)=>[this.getName(item),
            splitByThousands(item.curr_units),
            round(item.units_diff)+'%',
            this.getVendors(item)
        ],
        preExtraHeaders = [], 
        preExtraCells = (item)=> [];          
    
        if (props.entity == 'products' || props.entity == 'projects' || props.entity == 'tracks') {
            preExtraHeaders = [
                {text: 'Artist'}
            ];
            preExtraCells = (item)=> [
                item.artist_name
            ];
            
        };
        
        
        let allHeaders = concat(preExtraHeaders, headers);
        let allVendorsHash = {},
            allVendors = [];
        for(let item of props.results) {
            if(item.vendors) {
                for (let vendor of item.vendors) {
                    if(!allVendorsHash[vendor.vendor_value])
                        allVendorsHash[vendor.vendor_value] = vendor.color; 
                }
            }
        }
        
        for(let vendor of Object.keys(allVendorsHash))
            allVendors.push(<li className={hiddenVendors.includes(vendor)?'legend-item-hidden':''} key={vendor} onClick={(e)=>this.excludeVendor(vendor, e, allVendorsHash)}><span className="vendor-legend-box" style={{backgroundColor: allVendorsHash[vendor]}} /> {vendor}</li>)
            
        return <div className="top-vendor-holder">
            <div className="top-vendor-holder-table">
                <div className="top-table-holder">
                    <table className="top-vendor-table">
                        <thead>
                            <tr>
                                {allHeaders.map(header=><th key={header.text} className="vendor-table-th">
                                    <div className="top-table-header-inner">
                                        {header.text}
                                        {header.tooltipText ? (
                                            <Tooltip position="bottom" message={header.tooltipText} tooltipClass="toolbar-title-tooltip"/>
                                        ) : null}
                                    </div>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {props.results.map((item, index)=>{
                                let allCells = props.entity !== 'playlists' ? concat(preExtraCells(item), cells(item)) : cells(item);
                                return <tr key={item.id} className={index % 2 == 0 ? "gradeX" : "gradeC"}>
                                    {allCells.map((tdItem, tdIndex)=><td key={`${index}_${tdIndex}`} className="vendor-table-td"><div className="top-table-body-inner">{tdItem}</div></td>)}
                            </tr>})}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="top-vendor-holder-legend">
                <ul>
                    {allVendors}
                </ul>
            </div>
        </div>    
    }
}
export default EntityVendors;