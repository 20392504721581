import { commonConstants } from '../constants/common';

const initialState = {
  loading: false,
  data: [],
  error: null
};

export function common(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case commonConstants.GET_AUTOCOMPLETE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case commonConstants.GET_AUTOCOMPLETE_SUCCESS:
        return {
          ...state,
          data: payload,
          loading: false,
          error: null
        };
      case commonConstants.GET_AUTOCOMPLETE_FAILURE:
        return {
          ...state,
          data: [],
          loading: false,
          error: payload
        };
      default:
        return state
    }
}
