import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ShortenedTitle from '../../result/ShortenedTitle';
import ConfirmClick from '../../../common/ConfirmClick';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import { userEntityFiltersFormatter } from '../../../../data/formatters/user_entity_filters';

var deleteSvg = require('!svg-inline-loader!../../../../../public/img/icons/delete_new.svg');
var editSvg = require('!svg-inline-loader!../../../../../public/img/icons/edit.svg');
var profileSvg = require('!svg-inline-loader!../../../../../public/img/open_playlist.svg');
var downloadSvg = require('!svg-inline-loader!../../../../../public/img/download.svg');

export const playlistFilterSettings = {
	settingsForTable
};


const filterURL = (entities) => {
	let filter = {};
	for(let entity of entities){
		const { fav_hide, entity_type, entity_id } = entity;
		const filterKey = ( fav_hide == 'favorite' ) ? entity_type : `exclude_${entity_type}`; 
		//if(fav_hide == 'favorite') {
			if(!filter.hasOwnProperty(filterKey))
				filter[filterKey] = [];
			filter[filterKey].push(entity_id);
		//}
	}
	return `/filter/${JSON.stringify(filter)}/overview`;
}   



function settingsForTable(deleteFilter, pinFilter, shareFilter, exportToCsv){
	let columns = [
	   {
		   id: 'caption',
		   className: 'imprint-name',
		   headerClassName: 'imprint-name',
		   Header: 'Title',
		   accessor: 'caption',
		   width: 258,
		   Cell: props => {
			   const ids = map(props.original.entities, 'entity_id').join(',');
			   return <Link to={`/playlists/playlists/${ids}`}><ShortenedTitle title={props.value} /></Link>
		   },
	   },
	   {
		   id: 'summary',
		   className: 'imprint-name user-summary',
		   headerClassName: 'imprint-name user-summary',
		   Header: 'Summary',
		   Cell: props => <div>{userEntityFiltersFormatter.formatTitle(props.original.entities, 'entity_title')}</div>,
	   },
	   {
		   id: 'created_at',
		   className: '',
		   headerClassName: '',
		   Header: 'Created',
		   accessor: 'created_at',
		   width: 120,
		   Cell: props => {
			   return <p>{moment(props.value).format('YYYY-MM-DD')}</p>
		   }
	   },
	   {
		   id: 'owner',
		   className: '',
		   headerClassName: '',
		   Header: 'Created By',
		   accessor: 'owner',
		   width: 200,
		   Cell: props => {
			   return <p>{props.value?'You':'Shared'}</p> 
		   }
	   },
	   {
		   id: 'actions',
		   className: 'milestone-actions',
		   headerClassName: 'no-sort milestone-actions',
		   Header: 'Actions',
		   width: 150,
		   sortable: false,
		   // <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
		   Cell: props => {
			   const { id, owner, entities } = props.original;
			   return <div className="buttons-holder buttons-holder--milestones buttons-holder--gap">
				   {owner && <React.Fragment>
				   <Link to={`/playlists/filter/${id}`}className="btn add-to-filter" title="Edit"><span className="small-icon" dangerouslySetInnerHTML={{__html: editSvg}}></span></Link>
				   <ConfirmClick confirmAction={()=>deleteFilter([id])} confirmClass="btn add-to-filter" confirmLabel={<span className="small-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>} confirmPrompt="Delete Filter?" title="Delete"></ConfirmClick>
				   </React.Fragment>}
					 {/* <button type="button" className="default-btn default-btn--small" onClick={() => exportToCsv()}>Export to CSV</button> */}
					 <a key="download" title="Export CSV" onClick={() => exportToCsv(id)} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
			   </div>
		   }
	   },
	   
	   {
		   id: 'is_shared',
		   className: '',
		   headerClassName: 'no-sort',
		   Header: 'Shared',
		   accessor: 'is_shared',
		   width: 100,
		   Cell: props => {
			   const { id, owner } = props.original;
			   return owner ? <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
				   <input type="checkbox" id={`filter_is_shared_${id}`} checked={props.value} onChange={()=>shareFilter(id, !props.value)} className="input" />
				   <label htmlFor={`filter_is_shared_${id}`} className="checkbox-label checkbox-switcher"></label>
			   </div> : <div>-</div>
		   }
	   },       
	   {
		   id: 'is_pinned',
		   className: '',
		   headerClassName: 'no-sort',
		   Header: 'Pinned',
		   accessor: 'is_pinned',
		   width: 100,
		   Cell: props => {
			   const { id } = props.original;
			   return <div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder user-save-filter">
				   <input type="checkbox" id={`filter_is_pinned_${id}`} checked={props.value} onChange={()=>pinFilter(id, !props.value)} className="input" />
				   <label htmlFor={`filter_is_pinned_${id}`} className="checkbox-label checkbox-switcher"></label>
			   </div>
		   }
	   },
	   
	];
	return columns;
}
