import React from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import Box from '../../common/Box';
import ConfirmClick from '../../common/ConfirmClick';
import AddChannelToCampaignForm from '../../forms/addChannelToCampaign';
import { campaignsActions } from '../../../data/actions/campaigns';
import icons from '../../../helpers/Icons';

class CampaignItems extends React.Component {

    
    constructor(props) {
        super(props);
        this.state = {
            showNewChannelModal: false,
            expanded: false
        }
        this.toggleExpand = this.toggleExpand.bind(this);
        this.showModal = this.showModal.bind(this);
        this.refresh = this.refresh.bind(this);
        this.deleteSalesChannel = this.deleteSalesChannel.bind(this);
        this.deleteSocialChannel = this.deleteSocialChannel.bind(this);
        this.renderStreamItem = this.renderStreamItem.bind(this);
        this.renderSocialItem = this.renderSocialItem.bind(this);
    }
        
    showModal(show){
        this.setState({
            showNewChannelModal: show
        })
    }
    
    toggleExpand(){
        this.setState({
            expanded: !this.state.expanded
        })
    }
    
    deleteSalesChannel(id) {
        this.props.dispatch(campaignsActions.deleteSalesChannel(id, this.props.campaign.id)).then(this.refresh);
    }
    
    deleteSocialChannel(id) {
        this.props.dispatch(campaignsActions.deleteSocialChannel(id, this.props.campaign.id)).then(this.refresh);
    }
    
    refresh() {
        this.props.refresh();
        this.showModal(false);
    }
    
    renderStreamItem(stream) {
        return <li className="campaign-item">
            <span dangerouslySetInnerHTML={{__html: icons.entities[stream.channel_type+'s']}} className="chart-icon" />
            {stream.caption} {!stream.internal_id && <span className="text-warning">(no int ID)</span>}
            <ConfirmClick confirmClass="close-link" confirmAction={()=>{this.deleteSalesChannel(stream.id)}} title="remove channel" confirmLabel=" ×"></ConfirmClick>
        </li>
    }
    
    renderSocialItem(social) {
        return <li className="campaign-item">
            <span className={`chart-icon social-icon ${social.channel_type}`}></span>
            {social.caption}
            <ConfirmClick confirmClass="close-link" confirmAction={()=>{this.deleteSocialChannel(social.id)}} title="remove channel" confirmLabel=" ×"></ConfirmClick>
        </li>
    }
    
    renderStreamItems(campaign, entity) {
        const entities = campaign.campaign_sales.filter(stream=>stream.channel_type==entity);
        const caption = entity + 's';
        return <div className="items-list">
            <h3>{capitalize(caption)} ({entities.length})</h3>
            <ul className="campaign-list">
                {entities.map(this.renderStreamItem)}
                {!entities.length && <li>no channels</li>} 
            </ul>
        </div>
    }
    
    renderSocialItems(campaign, entity) {
        const entities = campaign.campaign_socials.filter(stream=>{
            if(entity=='videos')
                return stream.channel_type == 'youtube-video';
            else
                return stream.channel_type !== 'youtube-video';
        });
        const caption = entity == 'channels' ? 'socials' : 'videos';
        return <div className="items-list">
            <h3>{capitalize(caption)} ({entities.length})</h3>
            <ul className="campaign-list">
                {entities.map(this.renderSocialItem)}
                {!entities.length && <li>no channels</li>} 
            </ul>
        </div>
    }    
    
    renderToolbar(){
        const caret = this.state.expanded ? 'fa-caret-down' : 'fa-caret-up';
        return <div className="">
            <div className="ibox-icon-holder">
                <a key="toggle" onClick={this.toggleExpand} className={`fa ${caret}`} />
            </div>
        </div>
    }

    render (){
        const { campaign } = this.props;
        return <Box title={campaign.title} className={`campaign-items ${(this.state.expanded ? 'items-expanded' : 'items-collapsed')}`} toolbar={this.renderToolbar()}>
            <div className="small-title-holder">
                <h3 className="title">Add Channel</h3>
                <button title="Add Channel" onClick={()=>this.showModal(true)} className="btn add-to-filter"><i className="fas fa-plus"></i></button>
            </div>
            <AddChannelToCampaignForm show={this.state.showNewChannelModal} title="Add Channels to Campaign" onSubmit={this.refresh} onClose={(e)=>this.showModal(false)} campaign={campaign} />
            {this.renderSocialItems(campaign, 'channels')}
            {this.renderSocialItems(campaign, 'videos')}
            {this.renderStreamItems(campaign, 'product')}
            {this.renderStreamItems(campaign, 'track')}
            
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        campaigns: state.campaigns
    } 
}

export default connect(mapStateToProps)(CampaignItems);