import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import Spinner from '../../components/common/Spinner';
import AddRecipientForm from '../../components/forms/addRecipient';
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { mailingListsActions } from '../../data/actions/mailing_lists';
import { mailingListsSettings } from '../../components/pages/mailingLists/Recipients/Settings';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const SelectTable = selectTableHOC(ReactTable);

class MailingListsRecipients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            selectedRows: [],
            showNewRecipientForm: false
        }
        this.addRecipient = this.addRecipient.bind(this);
        this.deleteRecipient = this.deleteRecipient.bind(this);
        this.deleteSelectedRecipients = this.deleteSelectedRecipients.bind(this);
        this.toggleSelection = this.toggleSelection.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.showModal = this.showModal.bind(this);
        this.allSelected = this.allSelected.bind(this);
		this.resendInvitation = this.resendInvitation.bind(this);
    }
    
    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    addRecipient({email}) {
        const { id: mailingListID } = this.state;
        const { dispatch } = this.props;
        dispatch(mailingListsActions.addRecipient(email, mailingListID))
        .then(()=>dispatch(mailingListsActions.getMailingListRecipients(mailingListID)))
        .then(()=>this.showModal('showNewRecipientForm', false));
    }
    
    deleteRecipient(id) {
        const { id: mailingListID } = this.state;
        const { dispatch } = this.props;
        dispatch(mailingListsActions.deleteRecipient(id, mailingListID))
        .then(()=>dispatch(mailingListsActions.getMailingListRecipients(mailingListID)))
    }
    
    deleteSelectedRecipients() {
        const { id: mailingListID } = this.state;
        const { dispatch } = this.props;        
        Promise.all(map(this.state.selectedRows, id=>dispatch(mailingListsActions.deleteRecipient(id, mailingListID))))
        .then(()=>dispatch(mailingListsActions.getMailingListRecipients(mailingListID)))
    }
	
	resendInvitation(id) {
		const { dispatch } = this.props;
		dispatch(mailingListsActions.resendInvitation(id));
	}
    
    componentDidMount() {
        const { match, dispatch } = this.props;
        const { id } = match.params;
        dispatch(mailingListsActions.getMailingListRecipients(id))
        .then(()=>this.setState({id}));
    }
        
    toggleSelection(id){
        let selectedRows = Object.assign([], this.state.selectedRows);
        if(selectedRows.includes(id))
            selectedRows = selectedRows.filter(item=>item!=id);
        else
            selectedRows.push(id);
        this.setState({selectedRows});
    }
    
    toggleAll(rows){
        let selectedRows = map(rows, row=>row.id);
        if(this.allSelected(rows))
            selectedRows = [];
        this.setState({selectedRows});
    }
    
    allSelected(rows){
        const currentlySelectedRows = this.state.selectedRows;
        return (currentlySelectedRows.length > 0 && currentlySelectedRows.length == rows.length);
    }
    
    render() {
        const { recipientsLoading, mailingListRecipients = {} } = this.props.mailingLists,
            { id, selectedRows } = this.state,
            tableData = mailingListRecipients[id] || [],
            rows = tableData.length;
        

        return <div className="inner-page shared-pages">
            <div className="ibox">
                <h2>Saved Filter Recipients</h2>
                {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedRecipients}>Delete Selected</button>}
                <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={()=>this.showModal('showNewRecipientForm', true)}>New Recipient</button>
                <AddRecipientForm show={this.state.showNewRecipientForm} onSubmit={this.addRecipient} title="Add Recipient" onClose={()=>this.showModal('showNewRecipientForm', false)} />
                <SelectTable
                    data={tableData}
                    loading={recipientsLoading}
                    columns={mailingListsSettings.settingsForTable(this.resendInvitation, this.deleteRecipient)}
                    pageSize={rows ? Math.min(rows, 10): 10}
                    showPagination={rows > 10}       
                    selectType="checkbox"
                    keyField="id"
                    toggleSelection={(key, shift, row)=>{this.toggleSelection(row.id)}}
                    toggleAll={()=>this.toggleAll(tableData)}
                    isSelected={(key)=>{return selectedRows.includes(key)}}
                    className="simple-table"
                    selectAll={this.allSelected(tableData)}
                />
                
                {/* {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={this.deleteSelectedPages}>Delete Selected</button>} */}

            </div>
            <Helmet>
                <title>Saved Filter Recipients</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        mailingLists: state.mailingLists
    } 
}

export default connect(mapStateToProps)(MailingListsRecipients)