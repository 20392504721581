import React from 'react';

export const productsSettings = {
	settingsForTable,
};


function settingsForTable(deleteCall){
	
	
	let columns = [
		{
			id: 'code',
			Header: 'Barcode',
			width: 170,
			accessor: 'barcode'
		},
		{
			id: 'title',
			Header: 'Title',
			accessor: 'title'
		},
		{
			id: 'actions',
			Header: 'Actions',
			width: 70,
			accessor: 'barcode',
			Cell: (props)=><i className="fas fa-trash" onClick={()=>deleteCall(props.value)} />
		},
		
	];
	return columns;
}

function settingsForRow(state, rowInfo, column) {
	if(rowInfo===undefined)
		return {};

	return {
		className: `tr-device tr-device-${rowInfo.row.device.toLowerCase().replace(' ', '-')}`
	}
}