import React from 'react';
import { connect } from 'react-redux';
import { find, capitalize, cloneDeep } from 'lodash';
import ReactTable from 'react-table';
import { mailingListsActions } from '../../../data/actions/mailing_lists';
import { mailingListsFormatter } from '../../../data/formatters/mailing_lists';
import Box from '../../common/Box';
import Spinner from '../../common/Spinner';
import Calendar from './Calendar';
import RecipientsInput from './RecipientsInput';
import { Link } from 'react-router-dom';
import { mailingListsSettings } from './Settings';
import { mailingListsSubscriptionsSettings } from './Subscriptions/Settings';

class MailingListsByRecipient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                initilized: false
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props;
        Promise.all([dispatch(mailingListsActions.getMailingLists()), dispatch(mailingListsActions.getMailingListsByRecipient())])
        .then(([ownMailingLists, subscribedMailingLists])=>{
            this.setState({initialized: true})
        })
    }
    
    updateMailingListSchedule = (calendar, listID) => {
        let value = mailingListsFormatter.formatCalendar(calendar);
        const { dispatch, mailingLists } = this.props;
        let list = find(mailingLists.mailingLists, list=>list.id == listID);
        if(list) {
            if(!(list.schedule_type == calendar.period && list.schedule_value == value && list.start_date == calendar.date)) {
                list.schedule_type = calendar.period;
                list.schedule_value = value;
                list.start_date = calendar.date;
                
                dispatch(mailingListsActions.editMailingList(list, listID))
                .then(()=>dispatch(mailingListsActions.getMailingListsByRecipient()));
            }
        }
    }
    
    deleteRecipient = (recipientID, listID) => {
        const { dispatch } = this.props;
        dispatch(mailingListsActions.deleteRecipient(recipientID, listID))
        .then(()=>dispatch(mailingListsActions.getMailingListsByRecipient()));
    } 
    
    deleteMailingList = (listID) => {
        const { dispatch } = this.props;
        dispatch(mailingListsActions.deleteMailingList(listID))
        .then(()=>dispatch(mailingListsActions.getMailingLists()));        
    }
    
    render() {
        const { initialized } = this.state,
        {mailingLists, listsByRecipient} = this.props.mailingLists;
        if(!initialized)
            return <Spinner enabled={true} />
        return <Box title="Subscriptions">
            <h4>My Mailing Lists</h4>
            <ReactTable
                data={mailingLists}
                loading={false}
                columns={mailingListsSettings.settingsForTable(this.deleteMailingList, this.updateMailingListSchedule)}
                defaultPageSize={mailingLists?Math.min(mailingLists.length, 10):10}
                className="simple-table static-dropdowns"
            />

            <h4>My Subscriptions</h4>
            <ReactTable
                data={listsByRecipient}
                loading={false}
                columns={mailingListsSubscriptionsSettings.settingsForTable(this.deleteRecipient)}
                defaultPageSize={listsByRecipient?Math.min(listsByRecipient.length, 10):10}
                className="simple-table"
            />                
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        mailingLists: state.mailingLists,
        globalFilter: state.filter
    } 
}


export default connect(mapStateToProps)(MailingListsByRecipient);