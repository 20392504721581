import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const notificationReportsService = {
  getNotificationReports,
  getUserNotificationSettings,
  getFrequencies,
  createUserNotification,
  updateUserNotification,
  deleteUserNotification
}

function getNotificationReports(){
  const options = {
    method: 'GET',
    headers: AuthHeader({'Content-Type': 'application/json'})
  };
  const url = `${config.apis.orthus}/v1/reports`;

  return fetch(url, options)
  .then( response => {
    return response.json();
  })
  .catch( error => {
    return Promise.reject(error.message);
  })
}

function getUserNotificationSettings(){
  const options = {
    method: 'GET',
    headers: AuthHeader({ 'Content-Type': 'application/json'})
  };

  const url = `${config.apis.orthus}/v1/users_notifications`;
  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error));
}

function getFrequencies(){
  const options = {
    method: 'GET',
    headers: AuthHeader({ 'Content-Type': 'application/json'})
  };

  const url = `${config.apis.orthus}/v1/reports/frequencies`;
  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error));
}

function createUserNotification(notification) {
    const options = {
      method: 'POST',
      headers: AuthHeader({ 'Content-Type': 'application/json'}),
      body: JSON.stringify({'users_notification': notification })
    };
    const url = `${config.apis.orthus}/v1/users_notifications/`;
    return fetch(url, options)
    .then( response => response.json())
    .catch( error => Promise.reject(error.message));
  }


function updateUserNotification(notification) {
  const options = {
    method: 'PUT',
    headers: AuthHeader({ 'Content-Type': 'application/json'}),
    body: JSON.stringify({'users_notification': notification })
  };
  const url = `${config.apis.orthus}/v1/users_notifications/${notification.id}`;
  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error.message));
}

function deleteUserNotification(id) {
    const options = {
      method: 'DELETE',
      headers: AuthHeader()
    };
    const url = `${config.apis.orthus}/v1/users_notifications/${id}`;
    return fetch(url, options)
    .then( response => response.json())
    .catch( error => Promise.reject(error.message));
  }
