import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {Helmet} from 'react-helmet';
import { pdf as PDFRenderer }from '@react-pdf/renderer';

import Box from '../../components/common/Box';
import { imprintsActions } from '../../data/actions/imprints';
import EntityCard from '../../components/common/EntityCard';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import CSV from '../../helpers/CSVExport';
import PDFDocument from '../../components/pages/imprints/PDF';
import { dspLogos } from "../../components/common/Stats/utils";
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import SharePage from '../../components/pages/sharedPages/SharePage';
import {Widget, WithWidgetManager} from '../../components/widgets/manager';
import RedirectToResults from '../../components/pages/result/RedirectToResults';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class ImprintDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            currentTabIndex: {
                top: 0,
                sources: 3,
                territories: 5
            }
        };
        this.setParentEntity = this.setParentEntity.bind(this);
        this.setTabIndex = this.setTabIndex.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id});
    }

    componentWillReceiveProps(nextProps){
        const { match } = nextProps;
        if(match.params.id != this.state.id) {
            this.setState({id: null}, ()=>{
                this.setState({id: match.params.id});
            });
        }
    }

    setParentEntity(entity) {
        this.setState({
            title: entity.name_raw
        })
    }

    exportToCsv = () => {
      const {
        playlists,
        products,
        tracks,
        stats,
        artists
      } = this.props;

      const data = [
        { top_platforms: stats.vendor.data },
        { top_artists: artists.top },
        { top_products: products.top },
        { top_playlists: playlists.top },
        { top_tracks: tracks.top },
        { sources: stats.sources.table },
        { demographics: stats.demographics.table },
        { territories: stats.territory.table },
        { devices: stats.devices.table }
      ];
      
      const filename = CSV.CSVHeader('imprint_details', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);
    }

    setTabIndex(currentTabIndex, group) {
        this.setState({
            ...this.state, 
            currentTabIndex: {
                ...this.state.currentTabIndex,
                [group]: currentTabIndex
            }
        });
    }

    renderTabsHeader(tabsHeadingConfig, group) {
        const { currentTabIndex } = this.state; 
        
        let tabs = [];
        for(let tab of tabsHeadingConfig) {
            //const tab = tabsHeadingConfig[tabIndex]
            const tabIndex = tab.id;
            tabs.push(<span className={`tab${tabIndex == currentTabIndex[group] ? ' active' : ''}`} onClick={()=>this.setTabIndex(tabIndex, group)}>{tab.title}</span>);
        }
        return tabs;
    }
    
    getPdfData() {
        const { id } = this.props.match.params,
            reportTitle = 'Imprint Details',
            { artists, products, tracks, playlists, stats } = this.props;
            
        const imageIDs = [
            'imprint_details_vendors',
            'imprint_details_demographics',
            'imprint_details_sources',
            'imprint_details_artists',
            'imprint_details_products',
            'imprint_details_tracks',
            'imprint_details_playlists',
            'imprint_details_territories',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }

        let vendorTableData = [];

        if (stats.vendor) {
            vendorTableData = stats.vendor.table;
        }

        const tables = {
            artists: artists.top,
            products: products.top,
            tracks: tracks.top,
            playlists: playlists.top,
            territories: stats.territory.table,
            vendorsTable: vendorTableData,
            demograpics: stats.demographics.table,
            sources: stats.sources.table,
            devices: stats.devices.table,
        }
        return { reportTitle, images, tables, globalFilter: this.props.filter.global, entity: this.state.title };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "imprint_details.pdf";
            link.click();
        });
    }
    

    render() {
        if(!this.state.id)
            return null;
        let metaTitle = this.state.title ? `${this.state.title} - ` : '';
        metaTitle += 'Details';

        const { currentTabIndex } = this.state
        const topTabs = [
            {
                id: 0,
                title: "Artists"
            },
            {
                id: 1,
                title: "Products"
            },
            {
                id: 2,
                title: "Tracks"
            },
            {
                id: 7,
                title: "Playlists"
            }
        ];

        const sourcesTabs = [
            {
                id: 3,
                title: "Sources"
            },
            {
                id: 4,
                title: "Age and Gender"
            }
        ]; 

        const territoryTabs = [
            {
                id: 5,
                title: "Territories"
            },
            {
                id: 6,
                title: "Devices"
            }
        ]
        
        const { sharedMode, widgetProps } = this.props;
        return <RedirectToResults entity="imprints" id={this.state.id} />
        return <div className="imprint-details">
                <div className="">
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <MatchMediaWrapper breakpoint={768} isMobile={false}>
                                <h2>Imprint Details</h2>
                            </MatchMediaWrapper>
                            <EntityCard entity="imprints" id={this.state.id} onResult={this.setParentEntity} showLink={true} />
                        </div>
                    </div>
                    <div className="row download-page">
                        {sharedMode && <React.Fragment>
                            <span>Export</span>
                            <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                            <span>PDF</span>
                            <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                        </React.Fragment>}
                        {!sharedMode && <SharePage module="details" entity="imprints" id={this.state.id} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf}  />}
                    </div>
                        
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <Widget widgetID="vendors" widgetProps={widgetProps}>
                                <VendorsWidget entity="imprints" id={this.state.id} parentEntityTitle={this.state.title} shadowChartProps={{id: 'imprint_details_vendors', shadowChartWidth: 800, shadowChartHeight: 400}} />
                            </Widget>
                        </div>
                    </div>

                    <MatchMediaWrapper breakpoint={640} isMobile={true}>
                        <div className="tabs-content">
                            <div className="tab-container">
                                {this.renderTabsHeader(topTabs, 'top')}
                            </div>
                            <div className="tab-content mobile-content-tables">
                                {currentTabIndex.top == 0 && <Widget widgetID="top_artists" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Artists" entity="artists" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} showPlaylistAnalisysLink={true} id={0} shadowChartProps={{id: 'imprint_details_artists', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget>}
                                {currentTabIndex.top == 1 && <Widget widgetID="top_products" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Products" entity="products" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} id={1} shadowChartProps={{id: 'imprint_details_products', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget> }
                                {currentTabIndex.top == 2 && <Widget widgetID="top_tracks" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Tracks" entity="tracks" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} id={2} shadowChartProps={{id: 'imprint_details_tracks', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget>}
                                {currentTabIndex.top == 7 && <Widget widgetID="top_playlists" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Playlists" entity="playlists" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} id={7} shadowChartProps={{id: 'imprint_details_playlists', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget>}
                            </div>
                        </div>

                        <div className="tabs-content">
                            <div className="tab-container">
                                {this.renderTabsHeader(sourcesTabs, 'sources')}
                            </div>
                            <div className="tab-content">
                                {currentTabIndex.sources == 3 && <div className="pd-0 default" id={3}><Widget widgetID="sources" widgetProps={widgetProps}><SourcesStats entity="imprints" ids={this.state.id}  parentEntityTitle={this.state.title} chartType={dspLogos.sources.id} shadowChartProps={{id: 'imprint_details_sources', shadowChartWidth: 400, shadowChartHeight: 300}} /></Widget></div>}
                                {currentTabIndex.sources == 4 && <div className="pd-0 default" id={4}><Widget widgetID="demographics" widgetProps={widgetProps}><DemographicsStats entity="imprints" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.age.id}  shadowChartProps={{id: 'imprint_details_demographics', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget></div>}
                            </div>
                        </div>

                        <div className="tabs-content">
                            <div className="tab-container">
                                {this.renderTabsHeader(territoryTabs, 'territories')}
                            </div>
                            <div className="tab-content">
                                <div className="pd-0 default territory-details" id={5}>
                                    {currentTabIndex.territories == 5 && <Widget widgetID="territories" widgetProps={widgetProps}><TerritoryStats entity="imprints" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.territories.id} shadowChartProps={{id: 'imprint_details_territories', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget>}
                                </div>
                                <div className="pd-0 default" id={6}>
                                    {currentTabIndex.territories == 6 && <Widget widgetID="devices" widgetProps={widgetProps}><DevicesStats entity="imprints" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.devices.id} shadowChartProps={{id: 'imprint_details_devices', shadowChartWidth: 400, shadowChartHeight: 300}} /></Widget> }
                                </div>
                            </div>
                        </div>
                    </MatchMediaWrapper>

                    <MatchMediaWrapper breakpoint={641} isMobile={false}>
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_artists" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Artists" entity="artists" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} showPlaylistAnalisysLink={true} shadowChartProps={{id: 'imprint_details_artists', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>
                        </div>    
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_products" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Products" entity="products" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} shadowChartProps={{id: 'imprint_details_products', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>
                        </div>                    
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Tracks" entity="tracks" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} shadowChartProps={{id: 'imprint_details_tracks', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Playlists" entity="playlists" parentEntity="imprints" parentEntityIDs={this.state.id}  parentEntityTitle={this.state.title} shadowChartProps={{id: 'imprint_details_playlists', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 pd-0 default territory-details">
                                <Widget widgetID="sources" widgetProps={widgetProps}>
                                    <SourcesStats entity="imprints" ids={this.state.id}  parentEntityTitle={this.state.title} chartType={dspLogos.sources.id} shadowChartProps={{id: 'imprint_details_sources', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>

                            <div className="col-lg-6 col-xs-12 pd-0 default">
                                <Widget widgetID="demographics" widgetProps={widgetProps}>
                                    <DemographicsStats entity="imprints" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.age.id} shadowChartProps={{id: 'imprint_details_demographics', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 pd-0 default territory-details">
                                <Widget widgetID="territories" widgetProps={widgetProps}>
                                    <TerritoryStats entity="imprints" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.territories.id} shadowChartProps={{id: 'imprint_details_territories', shadowChartWidth: 800, shadowChartHeight: 400}} />
                                </Widget>
                            </div>
                            <div className="col-lg-6 col-xs-12 pd-0 default">
                                <Widget widgetID="devices" widgetProps={widgetProps}>
                                    <DevicesStats entity="imprints" ids={this.state.id} parentEntityTitle={this.state.title} chartType={dspLogos.devices.id} shadowChartProps={{id: 'imprint_details_devices', shadowChartWidth: 400, shadowChartHeight: 300}} />
                                </Widget>
                            </div>
                        </div>
                    </MatchMediaWrapper>

                    <Helmet>
                        <title>{metaTitle}</title>
                    </Helmet>
                    
                </div>
            </div>;
    }
}

function mapStateToProps(state) {
    return {
        artists: state.artists,
        imprints: state.imprints,
        filter: state.filter,
        stats: state.stats,
        products: state.products,
        playlists: state.playlists,
        tracks: state.tracks
    }
}

export default connect(mapStateToProps)(WithWidgetManager(ImprintDetails, 'imprint_details'))
