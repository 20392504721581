import React, { Component } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import moment from 'moment';

import Box from '../../../components/common/Box';
import FieldSelector from '../../../components/common/Datagrid/FieldsSelector';
import PageFilter from '../../../components/common/Datagrid/PageFilter';
import AuthHeader from '../../../helpers/AuthHeader';

import { userLogsActions } from '../../../data/actions/user_logs';

class UserLogsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 0,
            currentUserId: null
        }
        this.loadData = this.loadData.bind(this);
        this.dispatchLoadData = this.dispatchLoadData.bind(this);
        this.dispatchUserLogs = this.dispatchUserLogs.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.dispatchLoadData(this.state);

        this.setState({id: match.params.id});
        dispatch(userLogsActions.getUserLogs(match.params.id));
    }

    loadData(page) {
        this.setState({
            page: page
        }, () => this.dispatchUserLogs(this.state.currentUserId, page))
    }

    dispatchLoadData(state){
        const { dispatch } = this.props;

        dispatch(userLogsActions.getUsers());
    }

    dispatchUserLogs(id, page) {
        const { match, dispatch } = this.props;
        let currentPage = page + 1 || this.state.page + 1;

        this.setState({
            id: match.params.id
        });

        dispatch(userLogsActions.getUserLogs(match.params.id, currentPage));
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.logs && nextProps.logs.user){
            const { pagination, logs } = nextProps.logs.user

            if (pagination && logs) {
                const { page, per_page, total_page } = pagination

                this.setState({
                    data: logs,
                    page,
                    per_page,
                    total_page
                })
            }

        }
    }

    render() {
        const columns = [
            {
                id: 'page_title',
                Header: 'Page Title',
                accessor: 'page_title',
                Cell: props => {
                    return <div>{props.value}</div>
                }
            },
            {
                id: 'path',
                Header: 'Path',
                accessor: 'path',
                Cell: props => {
                    return <div>{props.value}</div>
                }
            },
            {
                id: 'date',
                Header: 'Date/Time',
                accessor: 'created_at',
                Cell: props => {
                    return <div>{moment(props.value).format('lll')}</div>
                }
            },
            
            {
                id: 'params',
                Header: 'Parameters',
                accessor: 'params',
                Cell: props => {
                    return <div>{props.value}</div>
                }
            }
        ]
        const tableData = this.state.data
        const { logs } = this.props;
        const { params } = this.props.match;
        let userData = {};

        if(this.props.logs && this.props.logs.users) {
            userData = this.props.logs.users.find(user => user.invitee_id === parseInt(params.id))
        } else null

        return (<div>
            <div className="">
                <h2 className="bid-title">{userData.first_name} {userData.last_name}</h2>
            </div>
            <div className="">
                {logs.users ? <div>
                    <ReactTable
                        manual
                        className='logs-table'
                        data={tableData}
                        loading={logs.loading}
                        page={this.state.page - 1}
                        pages={this.state.total_page}
                        pageSize={this.state.per_page}
                        columns={columns}
                        onPageChange={pageIndex => this.loadData(pageIndex)}
                    />
                    </div> : null}
            </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        users: state.users,
        user: state.user,
        logs: state.userLogs
    }
}

export default connect(mapStateToProps)(UserLogsDetails);
