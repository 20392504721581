import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { maxBy, isEqual, map, find } from 'lodash';
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import Modal from '../../common/Modal';
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import { milestonesActions } from '../../../data/actions/milestones';
import { milestonesSettings } from './Milestones/Settings'
import AddMilestoneForm from '../../forms/addMilestone'
import AddChannelToCampaignForm from '../../forms/addChannelToCampaign'
import MilestoneChart from './Milestones/Chart';
import LinkedEntities from './Milestones/LinkedEntities';
import ScrollButtons from '../../common/ScrollButtons';

import Box from '../../common/Box';
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

const SelectTable = selectTableHOC(ReactTable);

class Milestones extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showNewMilestoneModal: false,
            showEditMilestoneModal: false,
            showBulkResultsModal: false,
            showAddChannelModal: false,
            mode: 'table',
            milestoneType: undefined,
            currentMilestone: undefined,
            resizeObserver: undefined,
            tableContainer: undefined,
            hasHorizontalScroll: false,
            selectedRows: [],
            selectedEntity: null,
            selectedEntityID: null
        }
        
        this.showModal = this.showModal.bind(this);
        this.addMilestone = this.addMilestone.bind(this);
        this.bulkAddMilestones = this.bulkAddMilestones.bind(this);
        this.copyMilestone = this.copyMilestone.bind(this);
        this.editMilestone = this.editMilestone.bind(this);
        this.deleteMilestone = this.deleteMilestone.bind(this);
        this.bulkDeleteMilestones = this.bulkDeleteMilestones.bind(this);
        this.shareMilestone = this.shareMilestone.bind(this);
        this.shareAllMilestones = this.shareAllMilestones.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setCurrentMilestone = this.setCurrentMilestone.bind(this);
        this.setChartMode = this.setChartMode.bind(this);
        this.setMilestoneType = this.setMilestoneType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.resizeObserverFunction = this.resizeObserverFunction.bind(this);
        this.allSelected = this.allSelected.bind(this);
    }
      
    resizeObserverFunction() {
        const cellHeaderTable = ReactDOM.findDOMNode(this.refs.CellHeaderTable);
        const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];
        
        this.setState({
            tableContainer
        });

        if (this.props.hasResizeAdjustment && window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                this.setState({
                    hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
                });
            });
            this.setState({
                resizeObserver
            });
        
            resizeObserver.observe(tableContainer);
        } else {
            // console.log("Resize observer not supported!");
            this.setState({
                hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
            });
        }
    }

    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    setChartMode(mode){
        this.setState({mode});
    }
    
    setMilestoneType(milestoneType){
        this.setState({milestoneType});
    }
    
    _bulkAddMilestones(milestones) {
        this.showModal('showNewMilestoneModal', false);
        const { dispatch, milestones: {types} } = this.props;
//        for(let milestone of milestones) {
//            const milestoneType = find(types, type=>type.label == milestone['milestone_type']);
//            milestone['milestone_type_id'] = milestoneType ? milestoneType.value : 0;
//        }
       
        if(!(Array.isArray(milestones) && milestones.length))
            return;
        
        const { entity, id } = milestones[0].linkedEntity;

        const calls = milestones.map(milestone => { return dispatch(milestonesActions.addMilestone(null, milestone, true))
            .then(({milestone}) => dispatch(milestonesActions.linkAudienceMilestone(entity, id, milestone.id)));
        });
            
        return calls.reduce((promiseChain, currentTask) => {
            return promiseChain.then(chainResults =>
                currentTask.then(currentResult =>
                    [ ...chainResults, currentResult ]
                )
            );
        }, Promise.resolve([])).then(arrayOfResults => {
            // this.setState({bulkResult: arrayOfResults, showBulkResultsModal: true});
            dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered));
        });
    }
    
    bulkAddMilestones(milestones) {
        this.showModal('showNewMilestoneModal', false);
        const { dispatch, milestones: {types} } = this.props;
        for(let milestone of milestones) {
            let { id, entity } = milestone.linkedEntity;
            milestone.entity_id = id;
            milestone.entity_type = entity.replace(/s$/, '');
            delete milestone.linkedEntity;
        }
        dispatch(milestonesActions.bulkAddMilestones(milestones))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
    }

    addMilestone(formData) {
        let { entity, id } = this.props;
        if(formData.entity) {
            entity = formData.entity.entity;
            id = formData.entity.id;
        }
        this.props.dispatch(milestonesActions.addMilestone(null, formData))
        .then(({milestone}) => this.props.dispatch(milestonesActions.linkAudienceMilestone(entity, id, milestone.id)))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
        this.showModal('showNewMilestoneModal', false);
    }
    
    copyMilestone(milestone) {
        const formData = {
            milestone_type_id: milestone.milestone_type_id,
            reference: `${milestone.reference}(copy)`,
            notes: milestone.notes,
            occurs_at: milestone.occurs_at,
            ends_at: milestone.ends_at,
        };
        return this.addMilestone(formData);
    }
    
    editMilestone(formData) {
        this.props.dispatch(milestonesActions.editMilestone(null, formData, this.state.currentMilestone.id))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
        this.showModal('showEditMilestoneModal', false);
    }
    
    shareMilestone(shared, id) {
        const formData = {'public': !Boolean(shared)};
        this.props.dispatch(milestonesActions.editMilestone(null, formData, id, false))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
    }
    
    shareAllMilestones(allShared) {
        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones || !milestones.tableItems)
            return;
        //.filter(item => !item.public)
        const ids = map(milestones.tableItems, 'id');
        const sharePromises = map(ids, (id)=>this.props.dispatch(milestonesActions.editMilestone(null, {'public': allShared}, id, false)));
        Promise.all(sharePromises)
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
        this.showModal('showEditMilestoneModal', false);
    }


    deleteMilestone(milestoneID) {
        this.props.dispatch(milestonesActions.deleteMilestone(milestoneID))
        .then(() => this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)));
    }
    
    bulkDeleteMilestones() {
        Promise.all(map(this.state.selectedRows, id=>this.props.dispatch(milestonesActions.deleteMilestone(id, true))))
        .then(()=>this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered)))
        .then(()=>this.setState({selectedRows: []}));
    }
    
    
    refresh(){
        this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered))
        this.showModal('showAddChannelModal', false);
    }
    
    exportToCsv(){
        const milestones = this.props.milestones.entities[this.props.entity],
            { tableItems } = milestones,
            typeLabel = this.state.milestoneType ? this.state.milestoneType.label : 'all',
            filename = CSV.CSVHeader('entity milestones', typeLabel, this.props.filter.global, this.props.parentEntityTitle);

        return CSV.CSVExport(this.filterMilestoneTypes(tableItems), {filename}, 'entity_milestones');
    }

    setCurrentMilestone(currentMilestone, showModal) {
        this.setState({currentMilestone}, ()=>this.showModal(showModal, true));
    }
    
    componentDidMount() {
        this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered))
        this.props.dispatch(milestonesActions.getMilestoneTypes())
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.id, this.props.filtered))
            }                
        }
        
        if(nextProps.id && nextProps.id !== this.props.id){
            this.props.dispatch(milestonesActions.getEntityMilestones(nextProps.entity, nextProps.id, this.props.filtered))
        }
        
        if(nextProps.filtered && !isEqual(this.props.filtered, nextProps.filtered)){
            this.props.dispatch(milestonesActions.getEntityMilestones(nextProps.entity, nextProps.id, nextProps.filtered))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.milestones !== prevProps.milestones) {
            this.resizeObserverFunction();
        }
    }

    componentWillUnmount() {
        const cellHeaderTable = ReactDOM.findDOMNode(this.refs.CellHeaderTable);
        const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];

        this.state.resizeObserver && this.state.resizeObserver.unobserve(tableContainer)
    }
    
    filterMilestoneTypes(items){
        const { milestoneType } = this.state;
        if(milestoneType && milestoneType.value) {
            items = items.filter(item=>item.milestone_type_id == milestoneType.value)
        }
        return items;
    }
    
    toggleSelection(id){
        let selectedRows = Object.assign([], this.state.selectedRows);
        if(selectedRows.includes(id))
            selectedRows = selectedRows.filter(item=>item!=id);
        else
            selectedRows.push(id);
        this.setState({selectedRows});
    }
    
    toggleAll(rows){
        let selectedRows = map(rows, row=>row.id);
        if(this.allSelected(rows))
            selectedRows = []; 
        this.setState({selectedRows});
    }
    
    allSelected(rows){
        if(!rows)
            return false;
        const currentlySelectedRows = this.state.selectedRows;
        return (currentlySelectedRows.length > 0 && currentlySelectedRows.length == rows.length)
    }
    
    
    
    renderShareAll() {
        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones || !milestones.tableItems) 
            return null;
        
        
        let allShared = true;
        for(let milestone of milestones.tableItems) {
            if(!milestone.public) {
                allShared = false;
                break;
            }        
        }
        
        let buttonLabel,
            buttonClass;
        
        if(allShared) {
            buttonLabel = 'Stop Sharing All Milestones';
            buttonClass = 'shared';
        }
        else {
            buttonLabel = 'Share All Milestones';
            buttonClass = '';
        }
            
        
        return <div className="small-title-holder small-title-holder--right">
            <h3 className="title share-label">{buttonLabel}</h3>
            <button title={buttonLabel} onClick={()=>this.shareAllMilestones(!allShared)} className={`btn share-all-milestones ${buttonClass}`}></button>
        </div>
    }

    renderToolbar(types){
        let toolbar = [];
    
        types = [{
            label: 'All',
            value: null
        }].concat(types);
   
        /*
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
            <div className="ibox-actions">
                <div key="sort" className="select-holder"><span className="select-label">Milestone Type </span>
                    <ReactSelect value={this.state.milestoneType || types[0]} options={types} onChange={this.setMilestoneType} className="single-select"/>
                </div>
            </div>
        </div>)
        */
        return toolbar;
    }    

    renderExportToCsv() {
        return (
            <a key="download" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    getEntities() {
        const { mode = 'single', stats } = this.props;
        
        if(mode == 'mixed') {
            const { multiMetadata } = stats;
            if(Array.isArray(multiMetadata)) {
                return multiMetadata.map(item=>({
                    label: item.name,
                    value: { id: item.id, entity: item.entity } 
                }));
            }
        }
        else {
            return [];
        }
            
        
    }

    render (){
        
        const { sharedMode = false } = this.props;

        const { selectedRows } = this.state;
        
        const renderTable = (items, loading) => {
            return <div>
                <SelectTable
                    ref="CellHeaderTable"
                    data={items}
                    loading={loading}
                    className='milestone-table simple-table'
                    columns={milestonesSettings.settingsForTable(this.setCurrentMilestone, this.deleteMilestone, this.shareMilestone, this.copyMilestone, sharedMode)}
                    showPagination={false}
                    pageSize={(items && items.length ) ? items.length : 10}
                    selectType="checkbox"
                    keyField="id"
                    toggleSelection={(key, shift, row)=>{this.toggleSelection(row.id)}}
                    toggleAll={()=>this.toggleAll(items)}
                    isSelected={(key)=>{return selectedRows.includes(key)}}
                    selectAll={this.allSelected(items)}
                    defaultSorted={[{id:'occurs_at', desc: true}]}
                    // SubComponent={row => <LinkedEntities row={row} /> }
                />
            </div>
        }

        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones)
            return null;
        
        const { items, tableItems, loading } = milestones,
            {types} = this.props.milestones; 
            
        return <Box title="Milestones" toolbar={this.renderToolbar(types)} exportToCsv={this.renderExportToCsv()} className="milestone-holder">
            {types && <div>
                {!sharedMode && <div className="milestone-action-btns">
                    <div className="buttons-holder">
                        <button title="Add Milestone" onClick={()=>this.showModal('showNewMilestoneModal', true)} className="default-btn default-btn--small delete-selected">Add Milestone</button>
                        {selectedRows.length > 0 && <button className="default-btn default-btn--light default-btn--small delete-selected" onClick={this.bulkDeleteMilestones}>Delete Selected</button>}
                    </div>
                    {this.renderShareAll()}
                </div>}
                
                <AddMilestoneForm show={this.state.showNewMilestoneModal} entities={this.getEntities()} title="Add Milestone" onSubmit={this.addMilestone} onBulkAdd={this.bulkAddMilestones} onClose={(e)=>this.showModal('showNewMilestoneModal',false)} milestoneTypes={types} mode="new" />
                <AddMilestoneForm show={this.state.showEditMilestoneModal} title="Edit Milestone" onSubmit={this.editMilestone} onClose={(e)=>this.showModal('showEditMilestoneModal',false)} milestoneTypes={types} values={this.state.currentMilestone} mode="edit" />
                <Modal show={this.state.showBulkResultsModal} />
                <div>
                    {this.state.hasHorizontalScroll && <ScrollButtons el={this.state.tableContainer} />}
                </div>
            </div>}
            {this.state.mode=='table' && renderTable(this.filterMilestoneTypes(tableItems), loading)}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones,
        filter: state.filter,
        stats: state.stats
    } 
}

export default connect(mapStateToProps)(Milestones);