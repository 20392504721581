import React from 'react';
//import $ from 'jquery';
import { connect } from 'react-redux';
import { debounce, pull, isEqual, get, capitalize } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import Box from '../../common/Box';
import FilterSaveLoad from '../../widgets/filter/FilterSaveLoad';

import store from '../../../helpers/store'

import { filterActions } from '../../../data/actions/filter';
import { imprintsActions } from '../../../data/actions/imprints';
import { artistsActions } from '../../../data/actions/artists';
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { tracksActions } from '../../../data/actions/tracks';
import { searchActions } from '../../../data/actions/search';
import icons from '../../../helpers/Icons';
import Spinner from '../../common/Spinner';

import { Modal as RBModal } from 'react-bootstrap';
var crossSvg = require('!svg-inline-loader!../../../../public/img/cross.svg');

class TrackSelector extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            search: '',
            autocomplete: [],
            showAutocomplete: false,
            expanded: true,
            modalId: `autocomplete${this.props.entityIndex}`,
            showSeachModal: false
        }

        this.setResult = this.setResult.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.setFilterSearch = debounce(this.setFilterSearch.bind(this), 1000);
        this.collapseAutocomplete = this.collapseAutocomplete.bind(this);
    }

    componentWillReceiveProps(nextProps){
        //const { entity }= this.props;
        if(nextProps.search && nextProps.search.autocomplete_items){
            let autocomplete = [];
            for(let key of Object.keys(nextProps.search.autocomplete_items)) {
                if(Array.isArray(nextProps.search.autocomplete_items[key]))
                    autocomplete = autocomplete.concat(nextProps.search.autocomplete_items[key]);
            }
            
            this.setState({
                autocomplete,
                autocompleteNotFound: (nextProps.search.loading === false && nextProps.search.autocomplete_items.length === 0)
            })
        }
    }

    setSearch(e){
        const value = e.target.value;
        this.setState({
            autocomplete: [],
            search: value,
            showAutocomplete: true
        })
        this.setFilterSearch(value)
    }

    setFilterSearch(value){
        this.props.getAutocomplete(value);
    }

    formatFilterItem = (item) => {
      const { logo, ...rest } = item;
      return rest;
    }

    setResult(e, item){
        e.preventDefault();
        this.props.setResult(item);
        this.showModal('showSeachModal', false);
    }
    
    collapseAutocomplete(){
        this.setState({
            showAutocomplete: false
        })
    }

    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    showModal = (modal, show) => {
      this.setState({
          [modal]: show
      })
    }

    render() {
        const {value: currentValue, entity, readonly = false, clearResult } = this.props,
            entityValue = entity.replace(/s$/, ''),
            valueSet = (currentValue && currentValue.name_result),
            valueText =  valueSet ? currentValue.name_result : `No Entity Selected`,
            valueName = valueText.split('released on'),
            weekDayWarning = currentValue ? currentValue.week_day_warning : '';
        
        const autocompleteLoading = this.props.search.loading,
            autocompleteNotFound = !autocompleteLoading && this.state.autocompleteNotFound;
        return (
                <div className={this.props.rootClassName} >
                      <RBModal show={this.state.showSeachModal} style={{opacity:1}} onHide={()=>this.showModal('showSeachModal', false)} className="page-filter-dialog">
                        <RBModal.Body>
                        <form role="form" className="form-inline">
                          <div className="form-group page-filter-input">
                            <input className="form-control" type="text" value={this.state.search} onChange={this.setSearch} placeholder={`Search for projects, products or tracks`} />
                            <Spinner className="spinner filter-spinner" enabled={autocompleteLoading} />
                          </div>
                        </form>
                          <ul className="autocomplete track-selector-autocomplete">
                              {this.state.autocomplete.map(item => {
                                  return (<li key={item.id} className="autocomplete-item">
                                      <a href="#" onClick={(e)=>this.setResult(e, item)} className="logo-container track">
                                          <span className="chart-icon logo" dangerouslySetInnerHTML={{__html: item.logo}}></span>
                                          <span>{item.name}</span>
                                      </a>
                                  </li>)
                              })}
                              {autocompleteNotFound === true &&  <li className="autocomplete-item">
                                  <span>Nothing found</span>
                              </li>}
                          </ul>
                        </RBModal.Body>
                      </RBModal>

                      <div className="tag-list track-tag-list">
                        <div className="track-tag-item">
                            <div>
                                <span className="no-track">{valueName[0]}</span>
                                
                                {valueName[1] && <span className="newline">Released on{valueName[1]}</span>} 
                                {weekDayWarning && <p className="week-day-warning"><i className="fa fa-exclamation-triangle" /> {weekDayWarning}</p>}
                            </div>
                            {(!readonly && valueSet) && <button type="button" className="btn remove-from-comparison" onClick={clearResult} title={`Clear`}><span dangerouslySetInnerHTML={{__html: crossSvg}}/></button>}
                            {!readonly && <button type="button" className="default-btn default-btn--small add-to-comparison" onClick={()=>this.showModal('showSeachModal', true)} title={`Add to filter`}><i className="fas fa-search"></i></button>}
                        </div>
                      </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        projects: state.projects,
        products: state.products,
        search: state.search
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getAutocomplete: (value) => {
            return dispatch(searchActions.getSearchAutocomplete(value, 10, ['projects', 'products', 'tracks']));
            
            switch(ownProps.entity) {
                case 'products':
                    dispatch(productsActions.getProductsAutocomplete(value));
                    break;
                case 'projects':
                    dispatch(productsActions.getProjectsAutocomplete(value));
                    break;                    
                default:
                    dispatch(tracksActions.getTracksAutocomplete(value));
                    break;

            
            }
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TrackSelector)
