import React from 'react';
import ReactDOM from 'react-dom';
import { merge, map, max } from 'lodash';
import { VectorMap } from '@south-paw/react-vector-maps';
import WorldMapData from './WorldMap.json';
import {convertToThousands} from '../../helpers/NumberFormatter';

// import { formatTooltipLabelWorldMap } from '../widgets/charts/Formatter';


class WorldMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentLayer: null
        }
    }

    normalizeFunction = (val = 0, topCountryThreshold, averageCountryThreshold)=>{
        if(val > topCountryThreshold)
            return 'high';
        else if(val>averageCountryThreshold)
            return 'low';
        else
            return 'none' ;
    };
    
    onHover(currentLayer) {
        this.setState({currentLayer})
    }

    onClick(target) {
        if(this.props.onRegionClick)
            this.props.onRegionClick(target.attributes.id.value.toUpperCase());
    }
    
    renderTooltip() {
        const { currentLayer } = this.state,
            { label } = this.props;
        if(currentLayer == null)
            return '';
        else {
            const { name, value } = currentLayer.attributes,
                valueText = ( value && value.value ) ? `${value.value} ${label}` : 'No Data'; 
            return `${name.value}: ${valueText}`;
        }
    }
    
    render() { 
        const { currentLayer } = this.state;
        const { data } = this.props;
        const newDataArr = [];
        const layers = map(WorldMapData.layers, item=>{
            const dataValue = data[item.id.toUpperCase()];
            let topCountryThreshold;
            let averageCountryThreshold;

            item.value = dataValue ? convertToThousands(dataValue) : undefined; 
            if (dataValue !== undefined)
                newDataArr.push(dataValue);
            const maxValue = Math.max(...newDataArr)

            if (maxValue > 1000000) {
                topCountryThreshold = 1000000; 
                averageCountryThreshold = 100000;
            } else if (maxValue < 1000000 && maxValue > 100000) {
                topCountryThreshold = 100000; 
                averageCountryThreshold = 10000;
            } else if (maxValue < 100000 && maxValue > 10000) {
                topCountryThreshold = 10000; 
                averageCountryThreshold = 1000;
            } else {
                topCountryThreshold = 1000; 
                averageCountryThreshold = 100;
            }

            item.className = this.normalizeFunction(dataValue, topCountryThreshold, averageCountryThreshold);
            return item;
        });

        const layerProps = {
            onClick: ({target})=>this.onClick(target),
            onMouseEnter: ({target})=>this.onHover(target),
            onMouseLeave: ({target})=>this.onHover(null) 
        }
        return <div className="vector-map-container"> 
            <VectorMap 
                className="vector-map" 
                {...WorldMapData} 
                layers={layers}
                layerProps={layerProps}
            />
            {currentLayer && <span className="map-tooltip">{this.renderTooltip()}</span>}
        </div>
    }
  }

  export default WorldMap;