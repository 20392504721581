import { mailingListsConstants } from '../constants/mailing_lists';

export function mailingLists(state = {mailingList: {}, mailingListRecipients: {}}, action) {
    switch (action.type) {
        case mailingListsConstants.GET_MAILING_LISTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mailingListsConstants.GET_MAILING_LISTS_SUCCESS:
            return {
                
                ...state,
                loading: false,
                mailingLists: action.mailingLists
            };
        case mailingListsConstants.GET_MAILING_LISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case mailingListsConstants.GET_MAILING_LIST_DETAILS_REQUEST:
            return {
                ...state,
                detailsLoading: true
            };
        case mailingListsConstants.GET_MAILING_LIST_DETAILS_SUCCESS:
            if(!action.id)
                return {...state};
                
            let mailingList = state.mailingList;
            mailingList[action.id] = action.mailingList;
            return {
                ...state,
                detailsLoading: false,
                mailingList
            };
        case mailingListsConstants.GET_MAILING_LIST_DETAILS_FAILURE:
            return {
                ...state,
                detailsLoading: false,
                error: action.error
            };
        case mailingListsConstants.GET_MAILING_LIST_RECIPIENTS_REQUEST:
            return {
                ...state,
                recipientsLoading: true
            };
        case mailingListsConstants.GET_MAILING_LIST_RECIPIENTS_SUCCESS:
            let mailingListRecipients = state.mailingListRecipients;
            mailingListRecipients[action.id] = action.recipients;
            return {
                ...state,
                recipientsLoading: false,
                mailingListRecipients
            };
        case mailingListsConstants.GET_MAILING_LIST_RECIPIENTS_FAILURE:
            return {
                ...state,
                recipientsLoading: false,
                error: action.error
            };
            
        case mailingListsConstants.ADD_MAILING_LIST_REQUEST:
            return {
                ...state,
                mailingListLoading: true
            };
        case mailingListsConstants.ADD_MAILING_LIST_SUCCESS:
            return {
                
                ...state,
                mailingListLoading: false,
            };
        case mailingListsConstants.ADD_MAILING_LIST_FAILURE:
            return {
                ...state,
                mailingListLoading: false,
            };

        case mailingListsConstants.ADD_RECIPIENT_REQUEST:
            return {
                ...state,
                recipientLoading: true,
            };
        case mailingListsConstants.ADD_RECIPIENT_SUCCESS:
            return {
                
                ...state,
                recipientLoading: false,
            };
        case mailingListsConstants.ADD_RECIPIENT_FAILURE:
            return {
                ...state,
                recipientLoading: false,
            };
            
        case mailingListsConstants.GET_MAILING_LISTS_BY_RECIPIENT_REQUEST:
            return {
                ...state,
                listsByRecipientLoading: true
            };
        case mailingListsConstants.GET_MAILING_LISTS_BY_RECIPIENT_SUCCESS:
            return {
                
                ...state,
                listsByRecipientLoading: false,
                listsByRecipient: action.mailingLists
            };
        case mailingListsConstants.GET_MAILING_LISTS_BY_RECIPIENT_FAILURE:
            return {
                ...state,
                listsByRecipientLoading: false,
                error: action.error
            };            
            
        default:
            return state
    }
} 

