import React from 'react';

class Optgroup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: false
        }
        
        this.toggleExpand = this.toggleExpand.bind(this);
    }
    
    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded
        });        
    }
    
    getCheckedClass(){
        switch(this.props.selected){
            case 'yes':
                return 'checkbox-square checked all';
            case 'no':
                return 'checkbox-square';
            default:
                return 'checkbox-square checked';
        }
    }

    
    render (){
        const { label, children, selected } = this.props; 
        return  <div className="optgroup">
            <div className="option-item">
                <span onClick={this.props.selectAll} className={this.getCheckedClass()}></span>
                <span onClick={this.toggleExpand} className="optgroup-label">{label}</span>
                <span onClick={this.toggleExpand} className={`fa fa-caret-${this.state.expanded?'up':'down'}`}></span>
            </div>
            {this.state.expanded && <div className="options-second-level">
                <div className="options-second-level-container">
                    {children}
                </div>
            </div>}
        </div>;
    }
}


export default Optgroup;