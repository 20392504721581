import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import HomeView from '../../views/Home';
import MainView from '../../views/Main';
import CatalogView from '../../views/Catalog';
import StatisticsView from '../../views/admin/Statistics';
import UserLogsDetails from '../../views/admin/UserLogs/UserLogsDetails';
import WhiteLabelView from '../../views/admin/WhiteLabel';
import UsersListView from '../../views/admin/Users/List';
import UsersDetailsView from '../../views/admin/Users/Details';
import ReportsView from '../../views/admin/Reports';
import DataAdminView from '../../views/admin/DataAdmin';
import SocialManagementList from '../../views/SocialManagement/List';

const admin=(props)=>{
    return <Switch>
        <Route path="/admin/statistics" component={(StatisticsView)}></Route>
        <Route path="/admin/user-logs/:id/details" component={(UserLogsDetails)}></Route>
        <Route path="/admin/white-label" component={(WhiteLabelView)}></Route>
        <Route path="/admin/users/:id" component={(UsersDetailsView)}></Route>
        <Route path="/admin/users" component={(UsersListView)}></Route>        
        {/*<Route path="/admin/reporting/reports" component={(ReportsView)}></Route> */}
        <Route path="/admin/data" component={(DataAdminView)}></Route>
        <Route path="/admin/social-management/:artist?" component={SocialManagementList}></Route>
    </Switch>

    }

export default admin;
