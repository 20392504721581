import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../Datagrid/Footers';

export const demographicsSettings = {
    settingsForTable,
    settingsForRow
};


function settingsForTable(mode){
    
    
    let columns = [
       /*
      {
              id: 'gender',
              headerClassName: '',
              Header: 'Gender',
              // width: 70,
              accessor: 'gender',
          },
       */                         
       {
           id: 'age',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'Age Group',
           accessor: 'age',
           // width: 190,
           Footer: textFooter
       },
       
       {
           id: 'male',
           headerClassName: '',
           Header: 'Male',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'male',
           Footer: convertSumFooter
       },
       {
           id: 'female',
           headerClassName: '',
           Header: 'Female',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'female',
           Footer: convertSumFooter
       },              
       {
           id: 'unknown',
           headerClassName: '',
           Header: 'Unknown',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'unknown',
           Footer: convertSumFooter
       },              
       /*
       {
           id: 'streams',
           headerClassName: '',
           Header: 'Streams',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'curr_units'
       },
       /*
       {
           id: 'curr_listeners',
           headerClassName: '',
           Header: 'Listeners',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 100,
           accessor: 'curr_listeners'
       },
                     
       {
           id: 'share',
           headerClassName: ' no-sort',
           Header: 'Ratio',
           sortable: false,
           accessor: 'share',
           Cell: props => <span><AbsoluteValue value={props.value}></AbsoluteValue>%</span>,
           // width: 110
       }
       */


    ];
    return columns.map(column=>{
        //column.show = modes[mode].includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};
        
    return {
        className: `tr-gender tr-gender-${rowInfo.row.gender.toLowerCase()}`
    }
}