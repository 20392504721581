import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { userActions } from  '../data/actions/user';

class RouteLogger extends React.Component {

    track(location) {
        const title = document.title;
        return this.props.track(location.pathname, title, location.search || '');
    }
  componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
        this.track(location);
    });
    this.track(this.props.history.location);
  }
  componentWillUnmount() {
      this.unlisten();
  }
  render() {
     return <div>{this.props.children}</div>;
  }
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        track: (route, title, params)=>dispatch(userActions.track(route, title, params))
    }
} 

export default withRouter(connect(null, mapDispatchToProps)(RouteLogger));