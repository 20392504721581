export const productsConstants = {
    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
    GET_PRODUCTS_AUTOCOMPLETE_REQUEST: 'GET_PRODUCTS_AUTOCOMPLETE_REQUEST',
    GET_PRODUCTS_AUTOCOMPLETE_SUCCESS: 'GET_PRODUCTS_AUTOCOMPLETE_SUCCESS',
    GET_PRODUCTS_AUTOCOMPLETE_FAILURE: 'GET_PRODUCTS_AUTOCOMPLETE_FAILURE',
    GET_TOP_PRODUCTS_REQUEST: 'GET_TOP_PRODUCTS_REQUEST',
    GET_TOP_PRODUCTS_SUCCESS: 'GET_TOP_PRODUCTS_SUCCESS',
    GET_TOP_PRODUCTS_FAILURE: 'GET_TOP_PRODUCTS_FAILURE',
    GET_PRODUCT_DETAILS_REQUEST: 'GET_PRODUCT_DETAILS_REQUEST',
    GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
    GET_PRODUCT_DETAILS_FAILURE: 'GET_PRODUCT_DETAILS_FAILURE',
    GET_PRODUCTS_CARD_REQUEST: 'GET_PRODUCTS_CARD_REQUEST',
    GET_PRODUCTS_CARD_SUCCESS: 'GET_PRODUCTS_CARD_SUCCESS',
    GET_PRODUCTS_CARD_FAILURE: 'GET_PRODUCTS_CARD_FAILURE',
    GET_STREAMS_STATS_REQUEST: 'GET_PRODUCTS_STREAMS_STATS_REQUEST',
    GET_STREAMS_STATS_SUCCESS: 'GET_PRODUCTS_STREAMS_STATS_SUCCESS',
    GET_STREAMS_STATS_FAILURE: 'GET_STREAMS_STATS_FAILURE',
    GET_PRODUCTS_BY_BARCODES_REQUEST: 'GET_PRODUCTS_BY_BARCODES_REQUEST',
    GET_PRODUCTS_BY_BARCODES_SUCCESS: 'GET_PRODUCTS_BY_BARCODES_SUCCESS',
    GET_PRODUCTS_BY_BARCODES_FAILURE: 'GET_PRODUCTS_BY_BARCODES_FAILURE',
    GET_TRACKS_REQUEST: 'GET_TRACKS_REQUEST',
    GET_TRACKS_SUCCESS: 'GET_TRACKS_SUCCESS',
    GET_TRACKS_FAILURE: 'GET_TRACKS_FAILURE',
    GET_TIMESERIES_PRODUCTS_REQUEST: 'GET_TIMESERIES_PRODUCTS_REQUEST',
    GET_TIMESERIES_PRODUCTS_SUCCESS: 'GET_TIMESERIES_PRODUCTS_SUCCESS',
    GET_TIMESERIES_PRODUCTS_FAILURE: 'GET_TIMESERIES_PRODUCTS_FAILURE',
    COMPARE_PRODUCTS_REQUEST: 'COMPARE_PRODUCTS_REQUEST',
    COMPARE_PRODUCTS_SUCCESS: 'COMPARE_PRODUCTS_SUCCESS',
    COMPARE_PRODUCTS_FAILURE: 'COMPARE_PRODUCTS_FAILURE',
    COMPARE_PRODUCT_DEMOGRAPHICS_REQUEST: 'COMPARE_PRODUCT_DEMOGRAPHICS_REQUEST',
    COMPARE_PRODUCT_DEMOGRAPHICS_SUCCESS: 'COMPARE_PRODUCT_DEMOGRAPHICS_SUCCESS',
    COMPARE_PRODUCT_DEMOGRAPHICS_FAILURE: 'COMPARE_PRODUCT_DEMOGRAPHICS_FAILURE',
    COMPARE_PRODUCT_TERRITORIES_REQUEST: 'COMPARE_PRODUCT_TERRITORIES_REQUEST',
    COMPARE_PRODUCT_TERRITORIES_SUCCESS: 'COMPARE_PRODUCT_TERRITORIES_SUCCESS',
    COMPARE_PRODUCT_TERRITORIES_FAILURE: 'COMPARE_PRODUCT_TERRITORIES_FAILURE',
    COMPARE_PRODUCT_ARTIST_REQUEST: 'COMPARE_PRODUCT_ARTIST_REQUEST',
    COMPARE_PRODUCT_ARTIST_SUCCESS: 'COMPARE_PRODUCT_ARTIST_SUCCESS',
    COMPARE_PRODUCT_ARTIST_FAILURE: 'COMPARE_PRODUCT_ARTIST_FAILURE',
    COMPARE_PRODUCT_DATETIME_REQUEST: 'COMPARE_PRODUCT_DATETIME_REQUEST',
    COMPARE_PRODUCT_DATETIME_SUCCESS: 'COMPARE_PRODUCT_DATETIME_SUCCESS',
    COMPARE_PRODUCT_DATETIME_FAILURE: 'COMPARE_PRODUCT_DATETIME_FAILURE',    
    COMPARE_PRODUCT_VENDORS_REQUEST: 'COMPARE_PRODUCT_VENDORS_REQUEST',
    COMPARE_PRODUCT_VENDORS_SUCCESS: 'COMPARE_PRODUCT_VENDORS_SUCCESS',
    COMPARE_PRODUCT_VENDORS_FAILURE: 'COMPARE_PRODUCT_VENDORS_FAILURE',    
    GET_PRODUCTS_RELEASE_DATES_REQUEST: 'GET_PRODUCTS_RELEASE_DATES_REQUEST',
    GET_PRODUCTS_RELEASE_DATES_SUCCESS: 'GET_PRODUCTS_RELEASE_DATES_SUCCESS',
    GET_PRODUCTS_RELEASE_DATES_FAILURE: 'GET_PRODUCTS_RELEASE_DATES_FAILURE',  
      
    GET_ORDERED_TRACKS_REQUEST: 'GET_ORDERED_TRACKS_REQUEST',
    GET_ORDERED_TRACKS_SUCCESS: 'GET_ORDERED_TRACKS_SUCCESS',
    GET_ORDERED_TRACKS_FAILURE: 'GET_ORDERED_TRACKS_FAILURE',    
    
    
    
}
