import React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import VendorLogo from '../../../common/VendorLogo';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../../common/Tooltip';

export const playlistSettings = {
    settingsForTable
};


function settingsForTable(mode){    
    let columns = [
       {
           id: 'rank',
           className: 'rank',
           headerClassName: ' rank',
           Header: 'Rank',
           width: 80,
           accessor: 'rank'
       },
                   
      {
          id: 'playlist_name',
           className: 'playlist-name-td',
           headerClassName: 'imprint-name',
          Header: 'Playlist',
          accessor: 'playlist_name',
          width: 190,
          Cell: props => <div className="logo-container">
              <div className="playlist-description">
                    <p className="playlist-name" title={props.value}><VendorLogo name={props.original.vendor} modificator="small" /><span className={`activity activity-${props.original.pl_active?'true':'false'}`}/><Link to={`/playlists/${props.original.playlist_id}/details`}>{props.value}</Link></p>
              </div>                
          </div>
      },
      {
          id: 'listeners',
          headerClassName: '',
          className: 'listeners',
          Header: 'Listeners',
          Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
          width: 90,
          accessor: 'listeners'
      },
      {
          id: 'total_streams',
          headerClassName: '',
          className: 'listeners',
          Header: 'Streams',
          Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
          width: 90,
          accessor: 'total_streams'
      },            
      {
              id: 'ratio',
              headerClassName: '',
              Header: 'Ratio',
              width: 70,
              accessor: 'ratio',
              Cell: props => <span>{props.value}</span>,
      },


    ];
    return columns;
}