import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { find, cloneDeep, get, capitalize, map } from 'lodash';
import config from '../../config/config'
import store from '../../helpers/store'
import ReactSelect from '../../components/common/ReactSelect';
import SearchWidget from '../../components/pages/home/SearchWidget';
import FilterList from '../../components/widgets/entityFilter/FilterList';
import SampleCSV from '../../components/widgets/entityFilter/SampleCSV';
import CSVImportModal from '../../components/common/CSVImportModal';
import CSVSampleDownload from '../../components/common/CSVSampleDownload';
import FieldMapper from '../../components/widgets/entityFilter/FieldMapper';
import Spinner from '../../components/common/Spinner';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import Sources from '../../components/common/Stats/Sources';
import Devices from '../../components/common/Stats/Devices';
import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';

import { milestonesActions } from '../../data/actions/milestones';
const allEntityTypes = ['imprints', 'artists', 'products', 'tracks'];
const deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const editSvg = require('!svg-inline-loader!../../../public/img/icons/edit.svg');

class SpotifyDiscoveryReport extends React.Component {

    constructor(props){
        super(props);
        // const currentFilter = store.get('userSearch') || null;
        this.state = {
                filter: null,
                filterEmpty: false
        }
    }    
    
    componentDidMount(){
        const { dispatch } = this.props; 
        dispatch(milestonesActions.getMilestonesByType(config.settings.spotifyMilestoneType, true)).then(milestones=>{
            if(!milestones || !milestones.length) {
                this.setState({
                    filter: null,
                    filterEmpty: true
                });
                return;
            }
                
            let filter = {};
            for(let milestone of milestones) {
                let { entity_id: entityId, entity_type: entityType } = milestone;
                if(!entityType.endsWith('s'))
                    entityType = entityType+'s';
                
                if(!filter.hasOwnProperty(entityType))
                    filter[entityType] = [];
                filter[entityType].push(entityId)
            };
            let flatFilter = [];
            for(let entity of Object.keys(filter)) {
                flatFilter.push({id: entity, value: filter[entity]})
            }

            this.setState({filter, flatFilter, filterEmpty: false});
        })
        
    }
    render() {
        const { filter, flatFilter, filterEmpty } = this.state;
        const { widgetProps } = this.props;
        if(filterEmpty)
            return <p>No Spotify Discovery tracks selected.</p>
        
        if(!filter)
            return <Spinner enabled={true} />;
        return <div className="inner-page result-page"> 
            <div className="inner-page">
                <h2> Spotify Discovery</h2>
                <div className="widget-row widget-row--full-width">
                    <Widget widgetID="vendors" widgetProps={widgetProps}>
                        <VendorsWidget filter={filter} externalLinks={true} shadowChartProps={{id: 'home_vendors', shadowChartWidth: 800, shadowChartHeight: 300}} />
                    </Widget>
                </div>

                <div className="widget-row widget-row--full-width">
                    <Widget widgetID="sources" widgetProps={widgetProps}>
                        <Sources filtered={filter} externalLinks={true} shadowChartProps={{id: 'top_sources', shadowChartWidth: 800, shadowChartHeight: 300}} />
                    </Widget>
                </div>

                <div className="widget-row widget-row--full-width">
                    <Widget widgetID="territories" widgetProps={widgetProps}>                        
                        <TerritoryStats filtered={flatFilter} externalLinks={true} shadowChartProps={{id: 'top_territories', shadowChartWidth: 800, shadowChartHeight: 300}} />
                    </Widget>
                </div>
                <div className="widget-row widget-row--full-width">
                    <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                        <EntitiesList title="Top Playlists" entity="playlists" filter={filter} externalLinks={true} shadowChartProps={{id: 'top_products', shadowChartWidth: 800, shadowChartHeight: 300}} />
                    </Widget>
                </div>
                <div className="widget-row widget-row--full-width">
                    <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                        <EntitiesList title="Top Tracks" entity="tracks" filter={filter} externalLinks={true} shadowChartProps={{id: 'top_tracks', shadowChartWidth: 800, shadowChartHeight: 300}} />
                    </Widget>
                </div>
                <div className="widget-row widget-row--full-width">
                    <Widget widgetID="devices" widgetProps={widgetProps}>
                        <Devices filtered={filter} externalLinks={true} shadowChartProps={{id: 'top_devices', shadowChartWidth: 800, shadowChartHeight: 300}} />
                    </Widget>
                </div>
                
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones
    } 
}
export default connect(mapStateToProps)(withRouter(WithWidgetManager(SpotifyDiscoveryReport, 'spotify_report')));