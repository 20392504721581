import React from 'react';
import { capitalize } from 'lodash';
import ReactTooltip from 'react-tooltip';
import FilterSummaryLogoStatic from './FilterSummaryLogoStatic';

class FilterSummary extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        let { filter } = this.props;
        let summary = {};
		let entities = {};
        let firstEntity = null;
        for(let entity of filter.entities) {
            if(!firstEntity)
                firstEntity = entity;
        }
        if(!firstEntity)
            return null;

        return <div className="entity-card-item">
            <FilterSummaryLogoStatic entity={firstEntity} />
            <p>{filter.caption}</p>
        </div>;
    }
}

export default FilterSummary;