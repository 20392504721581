import store from './store';
import { find } from 'lodash';


export default function BuildFilterQS(params, filteredEntity) {
    if(params.filtered) {
        const globalFilter = store.get('globalFilter');
        const filteredEntities = globalFilter.filter(entity => (entity.fav_hide == params.filtered && entity.entity_type == params.filteredBy));
        const paramsPrefix = params.filtered == 'hidden' ? 'exclude_' : '';
        let newParams = {imprints: '', artists: '', products: '', projects: '', tracks: ''};
        
        // don't apply the filter if it's already filtered by any entity type
        // e.g. top artists by imprint ID
        let alreadyFiltered = false;
        for(let entity of Object.keys(newParams)) {
            if(params[entity])
                alreadyFiltered = true;
        }
        //
        if(!alreadyFiltered) {
            
            for (let entity of filteredEntities) {
                if(newParams[entity.entity_type])
                    newParams[entity.entity_type] += ',';
                newParams[entity.entity_type] += entity.entity_id;
            }
            
            for(let newParamKey of Object.keys(newParams)) {
//                if(newParamKey !== filteredEntity)
//                    continue;
                if(!newParams[newParamKey])
                    continue;
                
                const paramKey = paramsPrefix + ( newParamKey == 'artists' ? 'track_artists' : newParamKey);
                params[paramKey] = newParams[newParamKey];
            }
        }
        delete params.filteredBy;
    }
    return params;
}