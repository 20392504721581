import React from "react";
var arrowLeftSvg = require('!svg-inline-loader!../../../public/img/arrow-left.svg');
var arrowRightSvg = require('!svg-inline-loader!../../../public/img/arrow-right.svg');

const buttons = {
  left: "left",
  right: "right",
};

class ScrollButtons extends React.Component {
  constructor(props) {
    super(props);
  }

  scrollButtonClick(e, id) {
    const { el, scrollStepValue } = this.props;
    const scrollStep = scrollStepValue || 50;

    if (id === buttons.left) {
      el.scrollLeft -= scrollStep
    } else if (id === buttons.right) {
      el.scrollLeft += scrollStep
    }
  }
  
  render() {
    const { el, scrollPosition } = this.props;
    const clientWidth = el.clientWidth;
    
    const leftHidden = scrollPosition === 0 ? "hidden" : "";
    const rightHidden = scrollPosition + clientWidth >=  el.scrollWidth ? "hidden" : "";

    return (
      <div className="scroll-buttons-holder">
        <button
          type="button"
          id={buttons.left}
          onClick={(e) => this.scrollButtonClick(e, buttons.left)}
          dangerouslySetInnerHTML={{__html: arrowLeftSvg}}
          className={`angle-button angle-button--left ${leftHidden}`}
        >
        </button>
        <button
          type="button"
          id={buttons.right}
          onClick={(e) => this.scrollButtonClick(e, buttons.right)}
          dangerouslySetInnerHTML={{__html: arrowRightSvg}}
          className={`angle-button angle-button--right ${rightHidden}`}
        >
        </button>
      </div>
    );
  }
}

export default ScrollButtons;
