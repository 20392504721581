import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual, capitalize, cloneDeep } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { tracksActions } from '../../../data/actions/tracks';
import { productsActions } from '../../../data/actions/products';
import { FillLineChart, StackedBarChart } from '../../widgets/charts/types';


class SourcesStats extends React.Component {
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        let { entity, id } = this.props;
        
        let fields;
                
        switch(entity) {
            case 'projects':
                fields = ['identifier', 'project_title']
                break;
            case 'products':
                fields = ['upc', 'product_title'];
                break;
                
            case 'tracks':
                fields = ['isrc', 'track_title'];    
        }

        fields = fields.concat(["artist", "first_released", "weeks_since_release", "src_album", "perc_album", "src_artist", "perc_artist", "src_collection", "perc_collection", "src_other", "perc_other", "src_playlist", "perc_playlist", "src_radio", "perc_radio", "src_search", "perc_search"]);
    
        let data = this.props[entity].comparison.data.filter(item=>item[fields[0]] == id);
        let exportData = data.map(item => {
            let resultItem = {};
            for(let field of fields) {
                resultItem[field] = item[field];
            }
            return resultItem;
        });
        const title = ( entity == 'tracks' ? 'track' : 'product') + '-comparison-sources';
        const filename = CSV.CSVHeader(title);
        return CSV.CSVExport(exportData, {filename}, title);
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    render (){
        const { entity, id, metadata, chartID = '', shadowChart = false, weeks }  = this.props,
            data = cloneDeep(this.props[entity].comparison);
            
        const period = weeks <= 8 ? 'Week' : 'Month';
        

        let datasets = [];
        if(data.sourceDatasets && data.sourceDatasets[id]) {
            for(let datasetIndex in data.sourceLabels) {
                const source = data.sourceLabels[datasetIndex];
                let dataset = data.sourceDatasets[id][source];
                dataset.unshift(dataset[0])
                datasets.push ({
                    label: source,
                    data: dataset
                })
            }
        }
        let labels = data.labels;
        labels.push(labels.length+1);
        let chartData = {
            labels,
            datasets
        }
        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        
        const chartOptions = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: `${period}s Since Release`
                    },
                    ticks: {
                        callback: function(value, index, values) {
                            return value == 0 ? 'Release' : value;
                        }
                    }                    
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: function(context) {
                            if(Array.isArray(context) && context.length) {
                                const {label} = context[0];
                                return label == 0 ? `Release ${period}` : `Release ${period} + ${label}`
                            }
                        }
                    }
                }
            }
        }        

        return <Box title={title} exportToCsv={this.renderExportToCsv()}>
        
            {chartData && <div className="row">
                <div className={`col-xs-12`}>
                    {chartData.datasets.length > 0 && <div className="chart-block">
                        <FillLineChart data={chartData} options={chartOptions} id={chartID} shadowChart={shadowChart} />                          
                    </div>}
                    {!chartData.datasets.length && <p>No data available</p>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        products: state.products,
        projects: state.projects,
        filter: state.filter,
        userData: state.user
    } 
}

export default connect(mapStateToProps)(SourcesStats);