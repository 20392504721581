import React, { Component } from "react";
import { connect } from 'react-redux';

class WithPermission extends Component {

    constructor(props){
        super(props);
    }

    render() {
        const {user, permission} = this.props;
        let rules = permission;
        if(typeof permission === 'string')
            rules = [permission];
            
        
        let allowed = false;
        for(let rule of rules ) {
            if(user.user[rule])
                allowed = true;
        }
        
        if(!user.loggedIn || !allowed){
            return null;
        }
        else {
            return {...this.props.children}
        }
    }
}

function mapStateToProps(state) {
    return { 
        user: state.user
    };
}

    

export default connect(mapStateToProps)(WithPermission); 