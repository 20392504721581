import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
       {key: 'content_type', title: 'Type', width: 15, fontSize: 5},
       {key: 'vendor', title: 'Platform', width: 15, fontSize: 5},
       {key: 'curr_units', title: 'Streams', width: 15, fontSize: 5, formatFn: splitByThousands},
       {key: 'prev_units', title: 'Previous Streams', width: 15, fontSize: 5, formatFn: splitByThousands},
       {key: 'share', title: 'Platform Share', width: 15, fontSize: 5},
       {key: 'pre_add', title: 'Pre Add', width: 15, fontSize: 5},
       {key: 'curr_playlist_units', title: 'Playlist Streams', width: 15, fontSize: 5, formatFn: splitByThousands},
       {key: 'playlist_share', title: 'Playlist Share', width: 15, fontSize: 5},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}