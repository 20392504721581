import React from 'react';
import CopyToClipboard from '../../common/CopyToClipboard';
import config from '../../../config/config';

class SharedPageLink extends React.Component {
    render() {
        const { slug, justCopy = false } = this.props;
        const url = `${config.apis.shared}/share/${slug}`;     
        return (slug ? <span className="isrc-holder with-copy shared">{justCopy ? 'Click to copy' : url}<CopyToClipboard value={url} /></span> : null)
    }
}

export default SharedPageLink