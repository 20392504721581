import React from 'react';

import Multiselect from '../../common/Multiselect/Multiselect';
//import MultiselectWithSearch from '../../common/MultiselectWithSearch';
import vendors from '../../widgets/filter/enums/vendors.json';
import territories from '../../widgets/filter/enums/territories.js';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            filter: {
                territories: [],
                vendors: []
            }
        }
    }
    
    filterChange = (value, prop) => {
        const filter = {
          ...this.state.filter,
          [prop]: value
        };


        this.setState({
          ...this.state,
          filter
        });
    }
    
    filterApply = (e) => {
        e.preventDefault();
        this.toggleExpand();
        return this.props.onChange(this.state.filter);
    }
    
    toggleExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    
    render() {
        const { filter, expanded } = this.state,
            dropdownClass = expanded ? 'fa-caret-up' : 'fa-caret-down',
            vendorValues = vendors.map(vendor => ({label: vendor.label, value: vendor.vend_id})),
            selectedTerritories = filter.territories ? filter.territories.slice(0) : [],
            selectedVendors = filter.vendors ? filter.vendors.slice(0) : [],
            labelData = `${selectedTerritories.length > 0 ? selectedTerritories.length : 'All'} Territories, 
                ${selectedVendors.length > 0 ? selectedVendors.length : 'All'} Platforms`;
        
        return <div className="advanced-holder">
                <span className="advanced-title" onClick={this.toggleExpand}>Advanced <i className={`fas ${dropdownClass}`} /></span>
                <span style={{display: expanded ? 'none' : 'block'}}>{labelData}</span>
                <div className="advanced-list" style={{display: expanded ? 'block' : 'none'}}>
                    <div className="advanced-list-holder">
                        <div className="filter-container">
                            <span><b>Territories</b></span>
                            <Multiselect className="territories-container" elements={territories} selected={selectedTerritories} handleChange={(value)=>this.filterChange(value, 'territories')} options={{enableClickableOptGroups: true}} />
                        </div>
                        <div className="filter-container">
                            <span><b>Platforms</b></span>
                            <Multiselect elements={vendorValues} selected={selectedVendors} handleChange={(value)=>this.filterChange(value, 'vendors')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <a href="#" className="advanced-apply default-btn default-btn--small" onClick={(e)=>this.filterApply(e)}>Apply</a>
                        </div>
                    </div>
                </div>
            </div>
    }
}

export default Filter;