import { searchConstants } from '../constants/search';
import { searchService } from '../services/search';
import { searchFormatter } from '../formatters/search';

export const searchActions = {
    getSearchAutocomplete,
    getSearchPlaylistAutocomplete,
    getSearchImport,
    addRecentItem,
    clearRecentItems,
    getSearchResults,
    getSearchResult,
    updateSearchResult
};



function _getSearchAutocomplete(search, limit=10, entities = ['imprints', 'artists', 'products', 'projects', 'tracks']) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));

        return searchService.autocomplete(search, entities)
            .then(
                search => {
                    search = searchFormatter.formatAutocomplete(search.results);
                    dispatch(success(search.slice(0, limit)));
                    return search;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_REQUEST } }
    function success(search) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_SUCCESS, search} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_FAILURE, error } }
}

function getSearchAutocomplete(search, limit=10, entities = ['imprints', 'artists', 'products', 'projects', 'tracks'], batch = false, mapNames = false) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        
        const querySearch = batch ? searchFormatter.formatBatchSearch(search) : search;
        
        return searchService.autocomplete(querySearch, entities, batch)
            .then(
                ({results}) => {
                    results = searchFormatter.formatAutocomplete(results, entities);
                    if(batch)
                        results = searchFormatter.addExactMatches(results, search, mapNames);
                    dispatch(success(results)) //.slice(0, limit)));
                    return results;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_REQUEST } }
    function success(search) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_SUCCESS, search} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_AUTOCOMPLETE_FAILURE, error } }
}


function getSearchPlaylistAutocomplete(search, limit=10, entities = ['imprints', 'artists', 'products', 'projects', 'tracks', 'playlists'], batch = false) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        
        const querySearch = batch ? searchFormatter.formatBatchSearch(search) : search;
        
        return searchService.autocomplete(querySearch, entities, batch)
            .then(
                search => {
                    search = searchFormatter.formatAutocomplete(search.results, entities);
                    dispatch(success(search));
                    return search;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST } }
    function success(search) { return { type: searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS, search} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE, error } }
}

function getSearchImport(search, entities = ['imprints', 'artists', 'products', 'projects', 'tracks'], async=false) {
    return dispatch => {
        
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        
        const querySearch = searchFormatter.formatImportSearch(search);
        
        return searchService.autocomplete(querySearch, entities, true, async)
            .then(
                (searchResult)=> {
                    let results;
                    if(!async)
                        results = searchFormatter.formatImport(searchResult.results, entities, querySearch);
                    else
                        results = searchResult.import_id;
                    
                    dispatch(success(results));
                    return results;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_IMPORT_REQUEST } }
    function success(search) { return { type: searchConstants.GET_SEARCH_IMPORT_SUCCESS, search} }
    function failure(error) { return { type: searchConstants.GET_SEARCH_IMPORT_FAILURE, error } }
}

function getSearchResults() {
    return ( dispatch, getState ) => {
        dispatch(request());
        return searchService.getSearchResults()
            .then(
                results => {
                    const data = searchFormatter.formatSearchResults(results.imports);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: searchConstants.GET_SEARCH_RESULTS_REQUEST } }
    function success(results) { return { type: searchConstants.GET_SEARCH_RESULTS_SUCCESS, results } }
    function failure(error) { return { type: searchConstants.GET_SEARCH_RESULTS_FAILURE, error } }
}

function getSearchResult(id) {
    return ( dispatch, getState ) => {
        dispatch(request());        
        return searchService.getSearchResult(id)
            .then(
                (search)=> {
                    const {import: searchData} = search;
                    const result = searchFormatter.formatImport(searchData.result, searchData.search_types.split(','), searchData.search_input);
                    dispatch(success(id, result));
                    return result;
                },
                error => {
                    dispatch(failure())
                }
            );
 
    };
    function request() { return { type: searchConstants.GET_SEARCH_RESULT_REQUEST } }
    function success(id, result) { return { type: searchConstants.GET_SEARCH_RESULT_SUCCESS, id, result } }
    function failure(error) { return { type: searchConstants.GET_SEARCH_RESULT_FAILURE, error } }
}

function updateSearchResult(id, params) {
    return ( dispatch, getState ) => {
        dispatch(request());        
        return searchService.updateSearchResult(id, result)
            .then(
                result => {
                    dispatch(success());
                    dispatch(searchActions.getSearchResults())
                    //dispatch(errorActions.setSuccessMessage('grid saved'))
                    return result;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving grid'))
                }
            );
 
    };
    function request() { return { type: searchConstants.UPDATE_SEARCH_RESULT_REQUEST } }
    function success() { return { type: searchConstants.UPDATE_SEARCH_RESULT_SUCCESS} }
    function failure(error) { return { type: searchConstants.UPDATE_SEARCH_RESULT_FAILURE, error } }
}



function addRecentItem(item) {
    return dispatch => {
        dispatch(add(item));
        return true;
    };

    function add(item) { return { type: searchConstants.ADD_RECENT_ITEM, item } }
}


function clearRecentItems(item) {
    return dispatch => {
        dispatch(clear());
        return true;
    };

    function clear() { return { type: searchConstants.CLEAR_RECENT_ITEMS } }
}
