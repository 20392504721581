import React from 'react';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';
import ModeSelect from '../../common/ModeSelect';
import { isEqual, maxBy, minBy, map, capitalize, get } from 'lodash';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import Tooltip from "../../common/Tooltip";
import Entity from './Entity';
import EntityTable from './EntityTable';
import EntityVendors from './EntityVendors';
import EntityTimeline from './EntityTimeline';
import PercentageValue from '../../common/PercentageValue';
import TrendDirection from '../../common/TrendDirection';
import ExportModal from '../../common/ExportModal';
import LoadingMessage from '../../../helpers/LoadingMessage';
import PlaylistAnalysisLink from "../../common/Datagrid/PlaylistAnalysisLink";
import EntitySelector from '../result/EntitySelector';
import DateGroupSelector from '../result/DateGroupSelector';

import { imprintsActions } from '../../../data/actions/imprints';
import { artistsActions } from '../../../data/actions/artists';
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { playlistsActions } from '../../../data/actions/playlists';
import { tracksActions } from '../../../data/actions/tracks';

var cardSvg = require('!svg-inline-loader!../../../../public/img/card.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var vendorSvg = require('!svg-inline-loader!../../../../public/img/vendor-chart.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var xlsxSvg = require('!svg-inline-loader!../../../../public/img/xlsx.svg');

class EntitiesList extends React.Component{

    options = [
       {label: 'Most Units', field: 'curr_units', dir: 'DESC'},
       {label: 'Least Units', field: 'curr_units', dir: 'ASC'},
       {label: 'Units Spike', field: 'units_diff', dir: 'DESC'},
       /*
       {label: 'Most Listeners', field: 'curr_listeners', dir: 'DESC'},
       {label: 'Least Listeners', field: 'curr_listeners', dir: 'ASC'},
       {label: 'Listeners Spike', field: 'listeners_diff', dir: 'DESC'}
       */
    ];

    constructor(props) {
        super(props);
        
        const defaultView = props.defaultMode || this.props.defaultView || 'table';
        
        this.state = {
            view: defaultView,
            currentExpandedId: null,
            sort: this.options[0],
            filter: this.getInitialFilter(props),
            entityFilter: null,
            dateGroup: 'day'
        }
        this.setChartView = this.setChartView.bind(this);
        this.expand = this.expand.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSort = this.setSort.bind(this);
        this.getDateRange = this.getDateRange.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
        this.getTimeseries = this.getTimeseries.bind(this);
        this.getLoading = this.getLoading.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);

        if(props.entity !== 'playlists') {
            this.options.push({label: 'Most Playlists', field: 'curr_playlists', dir: 'DESC'});
            this.options.push({label: 'Playlists Spike', field: 'playlists_diff', dir: 'DESC'});
        }
    }

    getInitialFilter(props) {
        if(props.parentEntity && props.parentEntityIDs) {
            return [{id: props.parentEntity, value: props.parentEntityIDs}];
        }
        return [];
    }
    
    setEntityFilter(entityFilter){
        this.setState({
            ...this.state,
            entityFilter,
        }, ()=>this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter}, false, this.state.entityFilter));
    }

    componentDidMount(){
        this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter}, false, this.state.entityFilter);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            
            if(this.props.filter.global!==undefined && this.props.filter.global.hasOwnProperty('dateStart') && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter}, false, this.state.entityFilter);
            }
        }
    }
    
    getTimeseries(dateGroup){
        //this.props.getTimeseries({sorted: this.state.sort, filtered: this.state.filter});
        let data = this.getResults();
        if(data && data.length) {
            const IDField = (this.props.entity == 'tracks') ? 'isrc' : 'id';
            const ids = map(data, IDField).filter(id=>id);
            let filtered = Object.assign([], this.state.filter);
                filtered.push({id:'ids', value:ids});
            return this.props.getTimeseries({sorted: this.state.sort, filtered, dateGroup}, false, this.state.entityFilter);
        }
    }

    exportToCsv(){
        const filename = CSV.CSVHeader(`top_${this.props.entity}`, this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle);
        let data = this.getResults();
        return CSV.CSVExport(data, {filename}, `top_${this.props.entity}`);
    }

    exportToXls(){
        this.getTimeseries().then(timeseries=>{
            const filename = CSV.CSVHeader(`top_${this.props.entity}`, this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle);
            let data = this.getResults();
            data = CSV.expandTimelineFlat(data, timeseries, this.props.entity.replace(/s$/, '')+'_id');
            return CSV.XLSExport(data, {filename}, `top_${this.props.entity}`);
        })
    }


    expand(id) {
        const currentExpandedId = (id !== this.state.currentExpandedId) ? id : null;
        this.setState({
            currentExpandedId
        })
    }

    getResults(mode='top'){
        switch(this.props.entity){
            case 'imprints':
                return this.props.imprints[mode] || [];
            case 'artists':
                return this.props.artists[mode] || [];
            case 'products':
                return this.props.products[mode] || [];
            case 'projects':
                return this.props.projects[mode] || [];                
            case 'playlists':
                return this.props.playlists[mode] || [];
            case 'tracks':
            case 'tracks_in_playlist':
                return this.props.tracks[mode] || [];
        }
    }

    getLoading(mode='top'){
        switch(this.props.entity){
            case 'imprints':
                return this.props.imprints[`${mode}Loading`] || false;
            case 'artists':
                return this.props.artists[`${mode}Loading`] || false;
            case 'products':
                return this.props.products[`${mode}Loading`] || false;
            case 'projects':
                return this.props.projects[`${mode}Loading`] || false;                
            case 'playlists':
                return this.props.playlists[`${mode}Loading`] || false;
            case 'tracks':
            case 'tracks_in_playlist':
                return this.props.tracks[`${mode}Loading`] || false;
        }
    }


    setChartView(view){
        this.setState({view});
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(view);
        
    }

    setSort(sort){
        this.setState({sort}, ()=>{
            this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter}, false, this.state.entityFilter);
        })
    }

    getDetails(id){
        switch(this.props.entity){
            case 'imprints':
                return this.props.imprints.imprint[id] || {};
            case 'artists':
                return this.props.artists.artist[id] || {};
            case 'products':
                return this.props.products.product[id] || {};
            case 'projects':
                return this.props.projects.project[id] || {};                
            case 'playlists':
                return this.props.playlists.timeline[id] || {};
            case 'tracks':
            case 'tracks_in_playlist':
                return this.props.tracks.track[id] || {};
        }
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    // renderPlaylistAnalisysLink() {
    //     const title = ['track_artists', 'product_artists'].includes(this.props.parentEntity) ? 'Artists' : capitalize(this.props.parentEntity);
    //     return (
    //       <div className="playlist-details-link">
    //         <PlaylistAnalysisLink
    //           title={`${title} Playlist Analysis`}
    //           entity={this.props.parentEntity}
    //           id={this.props.parentEntityIDs}
    //         ></PlaylistAnalysisLink>
    //       </div>
    //     );
    // }

    renderToolbar(views){
        const { entity } = this.props;
        const defaultView = this.props.defaultView || 'cards';
        let toolbar = [];
        if(defaultView !== 'cards') {
            
            toolbar.push(<div key="wrapper" className="ibox-action-holder">
            <div className="ibox-icon-holder">
                <a key='table' title="Table" className={`chart-icon ${this.state.view == 'table' ? 'active' : ''}`}
                onClick={()=>this.setChartView('table')}
                dangerouslySetInnerHTML={{__html: tableSvg}}>
            </a>
            <a key='line' title="Line Chart" className={`chart-icon ${this.state.view == 'line' ? 'active' : ''}`}
                onClick={()=>this.setChartView('line')}
                dangerouslySetInnerHTML={{__html: lineSvg}}>
            </a>
            <a key='bar' title="Bar Chart" className={`chart-icon ${this.state.view == 'bar' ? 'active' : ''}`}
                onClick={()=>this.setChartView('bar')}
                dangerouslySetInnerHTML={{__html: barSvg}}>
            </a>
            
            </div>
            <div className="ibox-actions">
              <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select sort-select" isSearchable={ false }/>
              <ExportModal exportTotals={this.exportToCsv} exportBreakdown={this.exportToXls} loading={this.getLoading('timeseries')} />
            </div>
            </div>)
            
        }
        else {
            let options = [
                // {icon: cardSvg, value: 'cards', label: "Overview"},
                {icon: tableSvg, value: 'table', label: "Table"},
                {icon: lineSvg, value: 'line', label: "Line Chart"},
                {icon: barSvg, value: 'bar', label: "Bar Chart"}
            ];
            if(entity !== 'playlists')
                options.push({icon: vendorSvg, value: 'vendors', label: "Platforms"});
            
            toolbar.push(<div className="ibox-action-wrapper" key="wrapper">
                <div className="ibox-icon-holder mode-select">
                    <ModeSelect options={options} selected={this.state.view} onChange={this.setChartView}  isSearchable={ false } />
                </div>
                <div key="sort" className="select-holder sort-by-select">
                    <span className="select-label">Sort by</span>
                    <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select sort-select" isSearchable={ false }/>
                </div>
            </div>);
        }
        //for (let view in views) {
          //  toolbar.push(<a key={view}><i className={`fa fa-${views[view]} ${this.state.view == view ? 'active' : ''}`} onClick={()=>this.setChartView(view)}></i></a>);
        //}
        return toolbar;
    }

    getDateRange(){
        if(this.props.filter.global) {
            let { dateStart, dateEnd } = this.props.filter.global;
            return `${moment(dateStart).format('D MMM')} - ${moment(dateEnd).format('D MMM')}`;

        }
        else
            return '';

    }

    renderTrends(data){
        if(!data || !data.length)
            return null;

        const topEntity = maxBy(data, 'curr_units'),
            bestDay = maxBy(topEntity.stms_by_date, 'units'),
            worstDay = minBy(topEntity.stms_by_date, 'units');

        return <div>
            <h2 className="content-title capitalize">{topEntity.name}</h2>
            <h4 className="content-subtitle">has been you best performing entity with {convertToThousands(topEntity.curr_units)} streams</h4>
            <TrendDirection direction="up">
                <strong>{moment(bestDay.stream_date).format('Do MMMM')}</strong> was your best performing day with <span className="num">{convertToThousands(bestDay.units)} streams</span>
            </TrendDirection>
            <TrendDirection direction="down">
                <strong>{moment(worstDay.stream_date).format('Do MMMM')}</strong> was your worst performing day with <span className="num">{convertToThousands(worstDay.units)} streams</span>
            </TrendDirection>

        </div>;
    }

    renderTooltip() {
        let tooltip = [];
        tooltip.push(<React.Fragment key="tooltip">
            {this.props.entity === 'products' && (
                <div className="table-header-tooltip">
                    {/* <span data-tip data-for="top-product-tip" className="notificationReportInfoIcon"> 
                        <i className="fas fa-question-circle" /> 
                    </span> */}
                    <Tooltip 
                        position="bottom" 
                        message={`Streams on a UPC level start populating from the product release date, apart from Apple where there are Instant grat tracks available for streaming on Apple Music. Use the entity filters to group ISRCs to have the full project visibility.\n\n In addition, note that due to the nature of reporting from some DSPs, it is sometimes not possible for us to map an ISRC to a UPC. In these instances, these products will appear in your Admin panel for you to review.`} 
                        tooltipClass="toolbar-title-tooltip"
                    />
                </div>)
            }
            {this.props.entity === 'artists' && 
            <div className="table-header-tooltip">
                <Tooltip 
                position="bottom" 
                message={`Total Artist streams on the Overview and Catalogue pages are for products and tracks where the artist is Primary Position One only. On the Artist Details page, Total streams are higher because they also include products and tracks where the artist is not the first Primary artist listed.`} 
                tooltipClass="toolbar-title-tooltip"
            />
            </div>}
        </React.Fragment>
        )

        return tooltip;
    }
    
    renderTitle() {
        let { title, filter } = this.props;
        let results = this.getResults();
        if(results && results.length)
            title = `${title} (${results.length})`;
        else if(filter.global && filter.global.limit)
            title = `${title} (${filter.global.limit})`;
            
           
        return title;
    }

    renderExportToCsv() {
        const title = ['track_artists', 'product_artists'].includes(this.props.parentEntity) ? 'Artists' : capitalize(this.props.parentEntity);
        return (<div className="playlist-analysis-links-holder">
            {this.props.showPlaylistAnalisysLink && <div className="playlist-details-link">
                <PlaylistAnalysisLink
                title={`${title} Playlist Analysis`}
                entity={this.props.parentEntity}
                id={this.props.parentEntityIDs}
                ></PlaylistAnalysisLink>
            </div>}
            
            <ExportModal exportTotals={this.exportToCsv} exportBreakdown={this.exportToXls} loading={this.getLoading('timeseries')} />
        </div>
        )
    }

    renderTable(mode) {
        const { playlistID } = this.props,
        { data = [], loading } = this.props.tracks.topPlaylist[playlistID] || [];
    
        return <CellHeaderTable 
            resizable={false}
            loading={loading}
            pageSize={data.length} 
            showPagination={false} 
            data={data} 
            className={"new-releases-track top-tracks-in-playlist"} 
            columns={playlistTracksSettings.settingsForTable(mode)}
            noDataText='No results found. Please check your filters.'
        />
        
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>this.getTimeseries(dateGroup));
    }        

    
    renderChartActions(){
        const { view } = this.state;
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entity={this.props.entity} />        
            {(view == 'line' || view == 'bar') && <DateGroupSelector dateGroupChange={this.setDateGroup}/>}
        </React.Fragment>
    }

    render(){
        //hide the widget until we have some projects
        if(this.props.entity == 'projects') {
            if(this.getLoading())
                return null;
            const projectsData = this.getResults();
            if(Array.isArray(projectsData) && projectsData.length == 0)
                return null;
        }
        
        const { widgetMode, externalLinks = true, milestones, filter } = this.props;
        const views = {
            'cards': 'address-card',
            'table': 'table',
            // 'timeline': 'chart-line',
        },
        trends = (config.showTrends && this.props.trends) || false;
        const { showPlaylistAnalisysLink } = this.props;
        const showTooltip = this.props.entity === 'products' || this.props.entity === 'artists' ? true : false
        let topChartClass = '';
        const datasetResults = this.getResults('timeseries').datasets;

        if (datasetResults) {
            if (datasetResults.length > 20 && datasetResults.length <= 100) {
                topChartClass = 'top-chart-md'    
            } else if (datasetResults.length > 100 ) {
                topChartClass = 'top-chart-lg'    
            }
        }
        
        const resultsLoading = (this.getLoading('top') || this.getLoading('timeseries'));

        const { shadowChartProps } = this.props;
        let milestonesItems = get(milestones, 'entities.mixed.tableItems', [])
        return <Box 
                title={this.renderTitle()} 
                // mobileTitle={"Top Performers"}
                hasTooltip={showTooltip} 
                loadingTooltip={this.renderTooltip()} 
                loadingMessage={LoadingMessage('overview')} 
                toolbar={this.renderToolbar(views)}
                chartActions={this.renderChartActions()} 
                className={`${this.props.entity} home-box`} 
                spinnerEnabled={(resultsLoading && this.state.view != 'table')} 
                // showLink={showPlaylistAnalisysLink ? this.renderPlaylistAnalisysLink() : null}
                exportToCsv={this.renderExportToCsv()}>
                <div className="row">
                    
                    {trends && <div className={`col-xs-12 col-sm-3`}>
                        {this.renderTrends(this.getResults())}
                    </div>}
                    <div className={`col-xs-12 col-sm-${trends?9:12}`}>
                        {/* {this.state.view == 'cards' && <div className="row entities-list">
                            {this.getResults().map(item=><Entity key={item.id} entity={this.props.entity} getDetails={(id)=>this.props.getDetails(id)} item={item} details={this.getDetails(item.id)} expanded={false} expand={()=>this.expand(item.id)} dateRange={this.getDateRange()} />) }
                        </div>} */}
                        {this.state.view == 'table' && <EntityTable entity={this.props.entity} parentEntity={this.props.parentEntity} results={this.getResults()} resultsLoading={resultsLoading} widgetMode={widgetMode} externalLinks={externalLinks} />}
                        {this.state.view == 'vendors' && <EntityVendors entity={this.props.entity} parentEntity={this.props.parentEntity} results={this.getResults()} />}
                        {this.state.view == 'line' && <div className={`timeline-holder ${topChartClass}`}><EntityTimeline mode="line" entity={this.props.entity} results={this.getResults('timeseries')} getTimeseries={this.getTimeseries} timeseriesLoading={this.getLoading('timeseries')} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} milestones={milestonesItems} shadowChartProps={shadowChartProps} /></div>}
                        {this.state.view == 'bar' && <div className={`${topChartClass}`}><EntityTimeline mode="bar" entity={this.props.entity} results={this.getResults('timeseries')} getTimeseries={this.getTimeseries} timeseriesLoading={this.getLoading('timeseries')} milestones={milestonesItems}  shadowChartProps={shadowChartProps} /></div>}
                    </div>
                </div>
            </Box>
    }
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        playlists: state.playlists,
        tracks: state.tracks,
        filter: state.filter,
        milestones: state.milestones
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getEntities: (params, cache=true, entityFilter) => {
            const filter = entityFilter ? entityFilter : ownProps.filter;
            switch(ownProps.entity) {
                case 'imprints':
                    dispatch(imprintsActions.getTopImprints(params, cache, filter));
                break;
                case 'artists':
                    dispatch(artistsActions.getTopArtists(params, cache, filter));
                    break;
                case 'products':
                    dispatch(productsActions.getTopProducts(params, cache, filter))
                    break;
                case 'projects':
                    dispatch(projectsActions.getTopProjects(params, cache, filter))
                    break;
                    
                case 'playlists':
                    dispatch(playlistsActions.getTopPlaylists(params, null, filter))
                    break;
                case 'tracks':
                    dispatch(tracksActions.getTopTracks(params, cache, filter))
                    break;
                case 'tracks_in_playlist':
                    dispatch(tracksActions.getTopTracksInPlaylist(params, cache, filter))
                    break;                    
            }
        },
        getTimeseries: (params, cache=true, entityFilter) => {
            const filter = entityFilter ? entityFilter : ownProps.filter;
            switch(ownProps.entity) {
                case 'imprints':
                    return dispatch(imprintsActions.getTimeseriesImprints(params, cache, filter));
                break;
                case 'artists':
                    return dispatch(artistsActions.getTimeseriesArtists(params, cache, filter));
                    break;
                case 'products':
                    return dispatch(productsActions.getTimeseriesProducts(params, cache, filter))
                    break;
                case 'projects':
                    return dispatch(projectsActions.getTimeseriesProjects(params, cache, filter))
                    break;                    
                case 'playlists':
                    return dispatch(playlistsActions.getTimeseriesPlaylists(params, null, filter))
                    break;
                case 'tracks':
                    return dispatch(tracksActions.getTimeseriesTracks(params, cache, filter))
                    break;
                case 'tracks_in_playlist':
                    return dispatch(tracksActions.getTimeseriesTracksInPlaylist(params, cache, filter))
                    break;                    
            }
        },        
        getDetails: (id) => {
            switch(ownProps.entity) {
                case 'imprints':
                    dispatch(imprintsActions.getImprintDetails(id));
                    break;
                case 'artists':
                    dispatch(artistsActions.getArtistDetails(id));
                    break;
                case 'products':
                    dispatch(productsActions.getProductDetails(id))
                    break;
                case 'projects':
                    dispatch(projectsActions.getProjectDetails(id))
                    break;                    
                case 'playlists':
                    dispatch(playlistsActions.getPlaylistTimeline(id))
                    break;
                case 'tracks':
                    dispatch(tracksActions.getTrackDetails(id))
                    break;
            }
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
