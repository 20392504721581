import { capitalize } from 'lodash';
import whitelist from './PDFExport.json';

export default {
    resizeCanvas,
    resizeCanvasDimensions,
    formatTable
}

function resizeCanvas(canvas, toWidth) {
    const fromWidth = canvas.width,
        fromHeight = canvas.height,
        ratio = toWidth / fromWidth,
        toHeight = fromHeight * ratio;
    
    if(fromWidth <= toWidth)
        return canvas;
    
    let cloneCanvas = document.createElement("canvas"),
    cloneCanvasContext = cloneCanvas.getContext("2d");
    cloneCanvas.width = fromWidth;
    cloneCanvas.height = fromHeight;
    cloneCanvasContext.drawImage(canvas, 0, 0, fromWidth, fromHeight);
    
    let tempCanvas = document.createElement("canvas"),
        tempCanvasContext = tempCanvas.getContext("2d");
    
    tempCanvas.width = toWidth;
    tempCanvas.height = toHeight;
    tempCanvasContext.drawImage(cloneCanvas, 0, 0, fromWidth, fromHeight, 0, 0, toWidth, toHeight);
    return tempCanvas;
}

function resizeCanvasDimensions(canvas, toWidth) {
    const fromWidth = canvas.width,
        fromHeight = canvas.height,
        ratio = toWidth / fromWidth,
        toHeight = fromHeight * ratio;
    
    if(fromWidth <= toWidth)
        return {width: fromWidth, height: fromHeight};
    else
        return {width: toWidth, height: toHeight};
}


function formatTable(table, entity) {
    let head = null,
        body = [];
    const entityWhitelist = whitelist[entity];
    for (let record of table) {
        let th = [],
            td = [];
        for(let key of entityWhitelist) {
            //if(entityWhitelist.includes(key)) {
                th.push(capitalize(key.replace('_', ' ')));
                td.push(record[key]);
            //}
        }
        
        if(head === null)
            head = [th];
        body.push(td);
    }
    return {head, body};
}