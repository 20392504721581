import { map, uniq, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';

export const campaignsFormatter = {
    formatCampaigns,
    formatCampaign,
    formatNewChannel,
    formatNewSocialChannel
};

function formatCampaigns(data){
    for(let item of data) {
        item.label = `${item.title} (${item.socials}/${item.sales})`;
        item.target_date = moment(item.target_date).format('YYYY-MM-DD');
    }
    return data;
}

function formatCampaign(data){
    for(let item of data.campaign_sales) {
        const entityType = `${item.channel_type}s`,
            extEntityType = `${entityType}Ext`;
            
        if(data[entityType] === undefined) {
            data[entityType] = [];
        }
        if(data[extEntityType] === undefined) {
            data[extEntityType] = [];
        }
        
        if(item.internal_id) {
            data[entityType].push(item.internal_id);
        }
        data[extEntityType].push(item.item_id);
    }
    for(let item of data.campaign_socials) {
        item.remote_channel_id = item.channel_id; 
        item.caption = item.caption.replace(/\(([a-z\-]+)\)/, '');
    }
    
    return data;
}

function formatNewChannel(params, campaign, currentUser) {
    return {
        campaign_id: campaign.id,
        caption: params.label,
        channel_type: params.channel_type,
        item_id: params.value,
        user_id: currentUser.id,
        internal_id: params.internal_id
    }
}

function formatNewSocialChannel(params) {
    return {
        caption: params.label,
        channel_type: params.channel_type,
        channel_id: params.value
    }
}