import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';

import { convertToThousands, addPercent } from '../../../helpers/NumberFormatter';

const renderTable=(data)=>{
	const columns=[
	   {key: 'title', title: 'Track', width: 28},
	   {key: 'lastMonthStatus', title: 'Status', width: 8},
	   {key: 'release_type', title: 'Type', width: 8, formatFn: (text)=>text.replace('_', ' ')},
	   {key: 'lastMonthStreams', title: 'Streams', width: 8},
	   {key: 'lastMonthListeners', title: 'Listeners', width: 8},
	   {key: 'totalStreams', title: 'Total', width: 8},
	   {key: 'totalMonetized', title: 'Monetized', width: 8},
	   {key: 'totalUnmonetized', title: 'Unmonetized', width: 8},
	   {key: 'unmonetizedSince', title: 'Unmonetized Since', width: 8},
	   {key: 'monthsUnmonetized', title: 'Months Unmonetized', width: 8},

   ];
	   
	return <Table
		columns={columns}
		items={data}
	/>
}

export default (props) => {
	const { data: { tables, images, reportTitle } } = props;
	if(!Array.isArray(tables.data) || !tables.data.length)
		return null;
		
	return <Document>
		<Page size="A4" style={styles.page}>
			<View style={styles.reportTitle}>
				<Text className={styles.smallHeaderNotUnderline}>{reportTitle}</Text>
			</View>
			<View style={styles.columnSection}>
				<Text style={styles.smallHeader}>Streams</Text>
				<Image source={images.spotify_monetization_metrics} />
			</View>
			<View style={styles.columnSection}>
				<Text style={styles.smallHeader}>Tracks</Text>	
				{renderTable(tables.data)}
			</View>      
		</Page>
	</Document>
};