import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import CountryFlag from '../../../common/CountryFlag';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../../common/Tooltip';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../../common/Datagrid/Footers';

export const territorySettings = {
    settingsForTable
};


function settingsForTable(mode, setTerritory){
    const modes={
        'map': ['expander', 'logo', 'share', 'name', 'listeners'],
        'expanded': ['expander', 'rank', 'logo', 'name', 'listeners', 'total', 'share']
    };
    
    
    let columns = [
       {
           id: 'expander',
           expander: true,
           width: 35,
           Expander: ({ isExpanded, ...rest }) => (
             <div>
               {isExpanded ? (
                 <span>&#x25BC;</span>
               ) : (
                 <span>&#x25B6;</span>
               )}
             </div>
           ),
           style: {
             cursor: "pointer",
             fontSize: 10,
             padding: "0",
             textAlign: "center",
             userSelect: "none"
           },
       },                   
       {
           id: 'rank',
           className: 'rank',
           headerClassName: 'rank',
           Header: 'Rank',
           width: 40,
           accessor: 'rank',
           Footer: spaceFooter
       },
                   
        {
            id: 'logo',
            className: 'logo-sell',
            headerClassName: 'logo-sell no-sort',
            Header: '',
            accessor: 'name',
            width: 40,
            sortable: false,
            Cell: props => {
                return <CountryFlag className="flag-medium" country={props.original.code} />
            },
            Footer: spaceFooter
      },
      {
          id: 'name',
       //    className: 'imprint-name',
       //    headerClassName: 'imprint-name',
          Header: 'Country',
          accessor: 'name',
       //    width: 190
          Cell: props => {
              return <span className="territory-demographics-link" onClick={()=>{setTerritory(props.original.code)}}>{props.value}</span>
           },
          Footer: textFooter

      },
      {
          id: 'listeners',
          headerClassName: '',
          className: 'listeners',
          Header: 'Listeners',
          Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
          // width: 100,
          accessor: 'listeners',
          Footer: convertSumFooter
      },      
      {
              id: 'share',
              headerClassName: '',
              Header: 'Share',
              // width: 70,
              accessor: 'share',
              Cell: props => <span>{props.value}%</span>,
              Footer: spaceFooter
          },
       {
           id: 'total',
           headerClassName: ' total',
           className: 'total',
           Header: 'Units',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'total_streams',
           Footer: convertSumFooter
       },       


    ];
    return columns.map(column=>{
        column.show = modes[mode].includes(column.id);
        return column;
    });
}