import { map, uniq, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import defaultGrid from '../../components/common/Grid/homepage.json';

export const gridsFormatter = {
    formatGrids,
};

function formatGrids({data}){
    data.unshift({
        id: 0,
        grid_name: 'Default Layout',
        is_shared: false,
        components: [], //todo
        layouts: defaultGrid,
        page_type: 'home'
    })
    return data;
}