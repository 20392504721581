import React from 'react';
import Modal from './Modal';

class ConfirmClick extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showRevokeModal: false
        }
    }
    
    showModal(modal, show){
        this.setState({
            [modal]: show
        })
    }
    
    render() {
        const {className, cancelClass, confirmClass, cancelLabel=<i className="fas fa-times" />, confirmLabel, confirmAction, confirmPrompt='Are you sure?', title, submitTitle='Yes', confirmRef, extraClass, closeTitle = 'No'} = this.props;

        return <div className="confirm-click-holder" id="confirm-click-holder" >
            <Modal show={this.state.showRevokeModal}  
                size="sm"
                title=""
                submitTitle={submitTitle}
                closeTitle={closeTitle}
                // dialogClassName="dialog-modal"
                handleSubmit={(e)=>{confirmAction(e); this.showModal('showRevokeModal', false)}}
                handleClose={()=>this.showModal('showRevokeModal', false)}
                dialogClassName={`confirm-modal-holder ${extraClass}`}
            modalRef={confirmRef} 
                modalContainer={ document.getElementById("filter-wrapper-inner") }
            >
            <span className="popup-confirm-text">{confirmPrompt}</span>
            </Modal>
            <a className={`confirm ${confirmClass}`} onClick={()=>this.showModal('showRevokeModal', true)} title={title}>{confirmLabel}</a>
        </div>;
    }
}

export default ConfirmClick