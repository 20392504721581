import { artistsConstants } from '../constants/artists';

export function artists(state = {artist: {}, card: {}, profile: {}, autoCompleteLoading: false}, action) {
    switch (action.type) {
        case artistsConstants.GET_ARTISTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case artistsConstants.GET_ARTISTS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.artists,
                metadata: action.metadata,
                total: action.total
            };
        case artistsConstants.GET_ARTISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case artistsConstants.GET_TOP_ARTISTS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                metadata: null,
                timeseries: null
            };
        case artistsConstants.GET_TOP_ARTISTS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.artists,
                metadata: action.metadata
            };
        case artistsConstants.GET_TOP_ARTISTS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };

        case artistsConstants.GET_ARTISTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case artistsConstants.GET_ARTISTS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.artists
            };
        case artistsConstants.GET_ARTISTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };
        case artistsConstants.GET_ARTIST_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case artistsConstants.GET_ARTIST_DETAILS_SUCCESS:
            let artist = state.artist;
            artist[action.id] = action.artist;
            return {
                ...state,
                loading: false,
                artist: artist
            };
        case artistsConstants.GET_ARTIST_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case artistsConstants.GET_ARTISTS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case artistsConstants.GET_ARTISTS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card,
                cardChannels: action.channels
            };
        case artistsConstants.GET_ARTISTS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case artistsConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true
            };
        case artistsConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.artists
            };
        case artistsConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error
            };

        case artistsConstants.GET_ARTIST_PROFILE_REQUEST:
            return {
                ...state,
                profileLoading: true
            };
        case artistsConstants.GET_ARTIST_PROFILE_SUCCESS:
            let profile = state.profile;
            profile[action.id] = action.artist;
            return {
                ...state,
                profileLoading: false,
                profile
            };
        case artistsConstants.GET_ARTIST_PROFILE_FAILURE:
            return {
                ...state,
                profileLoading: false,
                error: action.error
            };

        case artistsConstants.GET_ARTIST_FOLLOWERS_REQUEST:
            const loadingMetricKey = (action.metric == 'metric') ? 'followers' : 'monthlyListeners';
            return {
                ...state,
                followersLoading: true,
                [loadingMetricKey]: undefined,
            };
        case artistsConstants.GET_ARTIST_FOLLOWERS_SUCCESS:
            const metricKey = (action.metric == 'metric') ? 'followers' : 'monthlyListeners';
            return {
                ...state,
                followersLoading: false,
                [metricKey]: action.followers
            };
        case artistsConstants.GET_ARTIST_FOLLOWERS_FAILURE:
            return {
                ...state,
                followersLoading: false,
                error: action.error
            };
        case artistsConstants.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                productsLoading: true,
                products: null
            };
        case artistsConstants.GET_PRODUCTS_SUCCESS:
            return {
                
                ...state,
                productsLoading: false,
                products: action.products,
            };
        case artistsConstants.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                productsLoading: false,
                error: action.error
            };

        case artistsConstants.GET_TIMESERIES_ARTISTS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case artistsConstants.GET_TIMESERIES_ARTISTS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.artists
            };
        case artistsConstants.GET_TIMESERIES_ARTISTS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };
        case artistsConstants.GET_ARTISTS_SOCIAL_REQUEST:
            return {
                ...state,
                socialLoading: true
            };
            
        case artistsConstants.GET_ARTISTS_SOCIAL_SUCCESS:
            return {

                ...state,
                socialLoading: false,
                socialItems: action.artists,
                socialMetadata: action.metadata,
                socialTotal: action.total
            };
        case artistsConstants.GET_ARTISTS_SOCIAL_FAILURE:
            return {
                ...state,
                socialLoading: false,
                error: action.error
            };
            
        default:
            return state
    }
}
