import React from 'react';
import { Link } from 'react-router-dom';

var audienceAnalysisSvg = require('!svg-inline-loader!../../../../public/img/audienceAnalysisSvg.svg');

class AudienceAnalysisLink extends React.Component {
    render() {
        const { id, entity } = this.props;
            
        return (id ? <Link to={`/audience/${entity}/${id}`} {...this.props} className="link"><span className="small-icon audience-analysis-link" dangerouslySetInnerHTML={{__html: audienceAnalysisSvg}}></span></Link> : null)
    }
}

export default AudienceAnalysisLink