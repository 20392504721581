import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';

import Box from '../../components/common/Box';
import WhiteLabelForm from '../../components/forms/whiteLabel';

import {whiteLabelActions} from '../../data/actions/white_label';
import {whiteLabelService} from '../../data/services/white_label';

const SILO_SEPARATOR = ',';

class WhiteLabel extends Component {
  state = {
    files: {
      header_logo: {},
      homepage_background: {},
      login_page_background: {},
      email_logo: {}
    },
    header_logo: { name: ''},
    homepage_background: { name: ''},
    login_page_background: { name: ''},
    email_logo: { name: ''},
    existFiles: false
  }

  onSubmit = (values) => {
    const isDefaults = !this.props.whiteLabel.items.properties;
    const requestType = isDefaults ? 'POST' : 'PUT';

    const { user } = this.props.user;
    const silo = this.handleSeveralSilo(user.data_silo_codes);

    const cb = () => this.props.getWhiteLabel(silo);
    const data = Object.assign(values, { files: this.state.files });
    this.props.submitWhiteLabel(silo, data, requestType, cb);
  }

  onAddFiles = (event, key) => {
    let reader = new FileReader();
    const currentFile = event.target.files[0];
    const existFiles = currentFile instanceof File;
    const newStateFiles = this.state.files;
    newStateFiles[key] = currentFile;

    reader.onloadend = () => {
      this.setState( state => {
        return {
          ...state,
          files: newStateFiles,
          existFiles,
          [`${key}_path`]: reader.result
        }
      });
    }

    reader.readAsDataURL(currentFile);
  }

  handleSeveralSilo = silo => {
    let result = silo;
    if(silo.includes(SILO_SEPARATOR)){
      result = silo.split(SILO_SEPARATOR)[0];
    }
    return result;
  }

  componentDidMount() {
    const {user} = this.props.user;
    const silo = this.handleSeveralSilo(user.data_silo_codes);

    this.props.getWhiteLabel(silo)
  }

  render() {
    return (<div className="homepage white-label">
      <Box title="White Label">
        <div className="white-label-row">
          <div className="white-label-item">
            <WhiteLabelForm
              existFiles={this.state.existFiles}
              files={this.state.files}
              onAddFiles={this.onAddFiles}
              onSubmit={this.onSubmit}
              whiteLabel={this.props.whiteLabel}
              headerLogoPath={this.state.header_logo_path}
              homepageBackgroundPath={this.state.homepage_background_path}
            loginPageBackgroundPath={this.state.login_page_background_path}
              invitationEmailLogoPath={this.state.email_logo_path}
            />
          </div>
          <div className="white-label-image">
            <div className="explanation-image"></div>
          </div>
        </div>
      </Box>
      <Helmet>
          <title>White Label - Admin</title>
      </Helmet>
      
    </div>)
  }
}

function mapStateToProps(state) {
  return {user: state.user, whiteLabel: state.whiteLabel}
}

function mapDispatchToProps(dispatch) {
  return {
    getWhiteLabel: (silo) => dispatch(whiteLabelActions.getWhiteLabelData(silo)),
    submitWhiteLabel: (silo, values, requestType, cb) => dispatch(whiteLabelActions.submitWhiteLabelData(silo, values, requestType, cb))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabel)
