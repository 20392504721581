import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import Box from '../../components/common/Box';
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import CSV from '../../helpers/CSVExport';
import cellHeaderHOC from '../../components/common/Datagrid/CellHeaderHOC';
import { playlistsActions } from '../../data/actions/playlists';
import { playlistsFormatter } from '../../data/formatters/playlists'
import { playlistDetailsSettings } from '../../components/pages/playlists/details/Settings'
import TagsList from '../../components/pages/playlists/TagsList';
import VendorLogo from '../../components/common/VendorLogo';
import Benchmarks from '../../components/pages/playlists/Benchmarks';
import PositionInPlaylist from '../../components/pages/playlists/PositionInPlaylist';
import { convertToThousands } from '../../helpers/NumberFormatter';
import { pull, isEqual } from 'lodash';

var downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

const CellHeaderTable = cellHeaderHOC(ReactTable);

class PlaylistOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            filter: {
                columns: this.getInitialFilter()
            },
            tab: 'active'
        };

        this.toggleTab = this.toggleTab.bind(this);
        this.getInitialFilter = this.getInitialFilter.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    getInitialFilter(tab) {
        return playlistDetailsSettings.settingsForTable()
            .filter(item=>(item.show===undefined || item.show===true))
            .map(item=>item.id);
    }

    toggleTab(tab) {

        let { columns } = this.state.filter;

        if(tab=='active')
            columns = pull(columns, 'removed_at');
        else
            columns.push('removed_at');

        this.setState({
            ...this.state,
            tab,
            filter: {
                ...this.state.filter,
                columns
            }
        });
    }

    exportToCsv(){
        const playlist = this.props.playlists.details[this.state.id] || {};
        const filename = CSV.CSVHeader(`playlist-details-${playlist.playlist_name}`, this.state.tab, this.props.filter.global);
        return CSV.CSVExport(playlist.tracks[this.state.tab], {filename}, 'playlist_overview');
    }


    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id});
        dispatch(playlistsActions.getPlaylistDetails(match.params.id));
    }

    componentWillReceiveProps(nextProps){
        const { dispatch } = this.props;
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    dispatch(playlistsActions.getPlaylistDetails(this.state.id));
                }
            }
        }
    }


    render() {
        const playlist = this.props.playlists.details[this.state.id] || {};
        let metaTitle = playlist ? `${playlist.short_name} - ` : '';
            metaTitle += 'Playlist Overview';

        const benchmarks = this.props.playlists.benchmarks;
            return <div className="homepage">
                <Box title="Playlist Overview" className="" spinnerEnabled={this.props.playlists.detailsLoading}>
                    {playlist.tracks && <div>
                        <div className="playlist-holder column ibox-content section-header">
                            <a href={`/playlists/${playlist.id}/details`}>
                                <div className="playlist-image" style={{backgroundImage: `url(${playlist.image})`}}></div>
                            </a>
                            <div className="playlist-holder-inner">
                                <div className="playlist-name">
                                        <VendorLogo name={playlist.vendor} />
                                    {playlist.short_name}
                                    <Benchmarks id={playlist.id} />
                                    <a key="download" onClick={this.exportToCsv} title="Export CSV" className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                                </div>
                                <div className="playlist-info">
                                    <div className="playlist-info-item">
                                        <span className="playlist-info-title">Number of Tracks: {playlist.tracks.active.length + playlist.tracks.removed.length }</span>
                                    </div>
                                    <div className="playlist-info-item">
                                        <span className="playlist-info-title">Followers: {convertToThousands(playlist.followers_on_last_check)}</span>
                                    </div>
                                </div>
                                <div className="playlist-info">
                                    <div className="playlist-info-item">
                                        <span className="playlist-info-title">Average track streams per day: {playlist.avg_track_streams_per_day}</span>
                                    </div>
                                </div>
                                { benchmarks && <div className="playlist-info">
                                    <div className="playlist-info-item">
                                        <span className="playlist-info-title">Average skip rate: {benchmarks.skipped_ratio}%</span>
                                    </div>
                                </div> }
                                <div className="playlist-info">
                                    <div className="playlist-info-item playlist-info-item--tags">
                                        {playlist.pl_tags && <span className="playlist-info-title">Tags:</span>}
                                        <TagsList tags={playlist.pl_tags} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="ibox-tools overview-tools">
                            <a className={"default-btn default-btn--small " + (this.state.tab == 'active' ? 'active' : '')} onClick={()=>this.toggleTab('active')}>
                                <i className="fa fa-check-circle"></i>
                                 Live
                            </a>
                            <a className={"default-btn default-btn--small " + (this.state.tab == 'removed' ? 'active' : '') } onClick={()=>this.toggleTab('removed')}>
                               <i className="fa fa-minus-circle"></i> Removed
                            </a>
                        </div>
                        <CellHeaderTable
                            className="playlist-table playlist-table--overview catalogue-table-holder simple-table"
                            data={playlist.tracks[this.state.tab]}
                            columns={playlistDetailsSettings.settingsForTable(this.state.filter.columns)}
                            getTrProps={playlistDetailsSettings.settingsForRow}
							collapseOnDataChange={false}
                            noDataText='No results found. Please check your filters.'
							SubComponent={row=><PositionInPlaylist positions={row.original.position_changes} />}
                        />
                        <Helmet>
                            <title>{metaTitle}</title>
                        </Helmet>

                    </div>}
                </Box>
            </div>
    }
}

function mapStateToProps(state) {
    return {
        playlists: state.playlists,
        filter: state.filter
    }
}

export default connect(mapStateToProps)(PlaylistOverview)
