import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, cloneDeep } from 'lodash';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';
import FillLineChart from '../../widgets/charts/types/FillLineChart';
import BarChart from '../../widgets/charts/types/BarChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import ModeSelect from '../../common/ModeSelect';
import EntitySelector from '../../pages/result/EntitySelector';

import { audienceActions } from '../../../data/actions/audience';
import { demographicsSettings } from './Demographics/Settings';

var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class Demographics extends React.Component {
    genders = [{
       label: 'All',
       value: null
    }, {
        label: 'Male',
        value: 'Male'
    }, {
        label: 'Female',
        value: 'Female'        
    }, {
        label: 'Unknown',
        value: 'Unknown'
    }];
    
    constructor(props) {
        super(props);
        this.state = {
            mode: props.defaultMode || 'bar',
            gender: null,
            entityFilter: null
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setGender = this.setGender.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
    }
        
    componentDidMount() {
        this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter);
    }
    
    setChartMode(mode){
        this.setState({mode});
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(mode);
        
    }
    
    setGender(gender){
        this.setState({gender : gender.value});
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter));
    }
    
    
    exportToCsv(){
        const filename = CSV.CSVHeader('audience_demographics', 'listeners', this.props.filter.global);
        
        let chartData = cloneDeep(this.props.audience.demographics.table);
        
        return CSV.CSVExport(chartData, {filename}, 'audience_demographics');
    }

    
    
    renderToolbar(modes){
        let toolbar = [];
        
        // let selectedGender = find(this.genders, gender => gender.value == this.state.gender);
        const options = [
                         {icon: barSvg, value: 'bar', label: "Bar Chart"},
            {icon: pieSvg, value: 'pie', label: "Donut Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
        ];

        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
                <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
            </div>
            {/*
            <div className="ibox-actions">
                <ReactSelect value={selectedGender} options={this.genders} onChange={this.setGender} className="single-select"/>
            </div>
            */}
        </div>)
        return toolbar;
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter);
            }                
        }
        if(nextProps.id && !isEqual(nextProps.id, this.props.id)) {
            this.props.getStreamsStats(nextProps.id, nextProps.entity, this.state.entityFilter);
        } 
    }
    
    renderChartActions(){
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entityFilterMode="array" />
        </React.Fragment>
    }
    
    render (){  
        const { entity, id } = this.props,
            { demographics, demographicsLoading } = this.props.audience,
            { mode, gender } = this.state,
            widgetTitle = `Age and Gender`;
            
        let chartData = cloneDeep(demographics);

//        if(gender) {
//            chartData.datasets = chartData.datasets.filter(dataset => dataset.gender == gender );
//        }
            
        return <Box title={widgetTitle} toolbar={this.renderToolbar()} chartActions={this.renderChartActions()} spinnerEnabled={demographicsLoading} exportToCsv={this.renderExportToCsv()}>
            
            {chartData && 
                <div className="">
                    {this.state.mode=='table' && <div className="demographics-table territory-table--single custom-scroll">
                        <ReactTable
                            className="playlist-table simple-table"
                            data={chartData.table}
                            columns={demographicsSettings.settingsForTable('details')}
                            defaultPageSize={10}
                            showPagination={true}
                            defaultSorted={this.state.defaultSorted}
                    /></div>}
                    {this.state.mode=='pie' && <div className="chart-block">
                        <DoughnutChart data={chartData.chart} shadowChart={true} shadowChartWidth={400} shadowChartHeight={300} id="audience_demographics_bar_chart" addPercentage={false} options={{
                            plugins: {
                                legend: {
                                    labels: {
                                        filter: (item, data) => {
                                            return !(item.text.includes('('));
                                        }
                                    },

                                    onClick: function(e, legendItem, legend) {
                                      var ci = this.chart;
                                      var labels = ci.data.labels;
                                      var legendText = legendItem.text.replace(/[\d+\%\s]/g, '');
                                      var legendIndices = [];

                                      for (let i = 0; i < labels.length; i++) {
                                        if (labels[i].startsWith(legendText)) {
                                          legendIndices.push(i);
                                        }
                                      }

                                      if (e.native.shiftKey) {
                                        let someElementsVisible = false;
                                        var dataset = ci.data.datasets[0];
                                        for (let index in dataset.data) {
                                          if (legendIndices.includes(Number(index))) {
                                            continue;
                                          }

                                          if (ci.getDataVisibility(index)) {
                                            someElementsVisible = true;
                                            break;
                                          }
                                        }

                                        let nextVisibility = !someElementsVisible; 

                                        ci.data.datasets.forEach(function(element, ind) {
                                          for (let ind in element.data) {
                                            if (legendIndices.includes(Number(ind))) {
                                              if (ci.getDataVisibility(ind) !== true) {
                                                legend.chart.toggleDataVisibility(ind);
                                              }
                                            } else {
                                              if(ci.getDataVisibility(ind) != nextVisibility) {
                                                legend.chart.toggleDataVisibility(ind);
                                              }
                                            }
                                          }
                                        })
                                      } else {
                                        labels.forEach(function(el, index) {
                                          if (el.startsWith(legendText)) {
                                            ci.toggleDataVisibility(index)
                                          }
                                        })
                                      }
      
                                      ci.update();
                                    }
                                }
                            }
                        }} /> 
                    </div>}
                    {this.state.mode=='bar' && <div className="chart-block">
                        <BarChart data={chartData.barChart} shadowChart={true} shadowChartWidth={400} shadowChartHeight={300} id="audience_demographics_bar_chart" options={{
                            plugins:{
                                legend:{
                                    position: 'bottom',
                                    labels: {
                                        filter: (item, data) => {
                                            return !(item.text.includes('('));
                                        }
                                    },

                                    onClick: function(e, legendItem, legend) {
                                        var ci = this.chart;
                                        var datasets = ci.data.datasets;
                                        var legendItemText = legendItem.text.replace(' ',''); 
                                        var currentHiddenVal = legendItem.hidden;
                                        var visibleCount = ci.getVisibleDatasetCount();

                                        datasets.forEach(function(el, index) {
                                            var elName = el.stack.replace(' ',''); 
                                            var visible = true;

                                            if (e.native.shiftKey) {
                                                if (visibleCount === 2) {
                                                    if (elName == legendItemText && (currentHiddenVal == null || currentHiddenVal == true)) {
                                                        visible = true;
                                                    } else if (elName !== legendItemText && (currentHiddenVal == null || currentHiddenVal == true)) {
                                                        visible = false;
                                                    } else {
                                                        visible = true;
                                                    }
                                                } else {
                                                    if (elName !== legendItemText) {
                                                        visible = false;
                                                    }
                                                }
    
                                                ci.setDatasetVisibility(index, visible)
                                            } else {
                                                if (elName == legendItemText) {
                                                    if (currentHiddenVal == null || currentHiddenVal == true) {
                                                        visible = true
                                                    } else {
                                                        visible = false;
                                                    }

                                                    ci.setDatasetVisibility(index, visible)
                                                } 
                                            }

                                        })
        
                                        ci.update();
                                    }
                                }
                            }
                        }} shadowChartOptions={{
                            plugins:{
                                legend:{
                                    labels: {
                                        filter: (item, data) => {
                                            return true;
                                        }
                                    }
                                }
                            }
                            
                        }} /> 
                    </div>}
                </div>
            }
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (id, entity, entityFilter) => {
            return dispatch(audienceActions.getDemographicsStats(id, entity, entityFilter ? entityFilter : ownProps.filter))
        } 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(Demographics);