//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';
import {searchService} from './search';

export const gridsService = {
    getGrids,
    addGrid,
    editGrid,
    deleteGrid,
    saveDefaultGrid
};

function getGrids(params, globalFilter) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/layouts`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getGridsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/grids.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function addGrid(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/layouts`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function editGrid(data, id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/layouts/${id}`;
    return fetch(baseUrl, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function deleteGrid(id) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/layouts/${id}`;
    return fetch(baseUrl, {
        method: 'DELETE',
        headers
    })
    .then(res=>{   
        return res.json()
    })
    
}

function saveDefaultGrid(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/update_default_layout`;
    return fetch(baseUrl, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}