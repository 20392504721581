import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '../../components/common/Box';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import {Helmet} from 'react-helmet';
import { pdf as PDFRenderer }from '@react-pdf/renderer';
import { cloneDeep } from 'lodash';

import EntityCard from '../../components/common/EntityCard';
import Streams from '../../components/pages/audience/Streams';
import Demographics from '../../components/pages/audience/Demographics';
import EntityMilestones from '../../components/pages/audience/EntityMilestones';
import Milestones from '../../components/pages/audience/Milestones';
import Sources from '../../components/pages/audience/Sources';
import Territory from '../../components/pages/audience/Territory';
import TopPlaylists from '../../components/pages/audience/TopPlaylists';
import RepeatListeners from '../../components/pages/audience/RepeatListeners';
import SharePage from '../../components/pages/sharedPages/SharePage';
import PDFDocument from '../../components/pages/audience/PDF';
import CSV from '../../helpers/CSVExport';
import PDF from '../../helpers/PDFExport';
import {Widget, WithWidgetManager} from '../../components/widgets/manager';
import RedirectToResults from '../../components/pages/result/RedirectToResults';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class AudienceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            entity: null,
        };
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
        this.setParentEntity = this.setParentEntity.bind(this);
        this.isDataReady = this.isDataReady.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({
            id: match.params.id,
            entity: match.params.entity
        });
    }
    
    setParentEntity(entity) {
        this.setState({
            title: entity.name_raw,
            titlePdf: entity.code ? `${entity.name_raw} (${entity.code}). Released on: ${entity.release_date}.` : entity.name_raw
        })
    }
    
    componentWillReceiveProps(nextProps){
        
        const { match } = nextProps;
        if(match.params.id && match.params.id !== this.state.id) {
            this.setState({
                id: null
            }, ()=> {
                this.setState({
                    id: match.params.id,
                    entity: match.params.entity
                });
            })
        } 
    }
    
    exportStreamsListeners({labels, datasets}) {
        let result = [];
        for(let index in labels) {
            result.push({
                date: labels[index],
                total_streams: datasets[0].data[index],
                unique_listeners: datasets[1].data[index]
            })
        }
        return result;
    }
    
    isDataReady(sharedPage) {
        const { audience, milestones, match } = this.props;
        const entity = match.params.entity;
        
        const dataLoadingFlags = [
            audience.loading, 
            audience.demographicsLoading, 
            audience.sourceStatsLoading, 
            audience.repeatListenersLoading, 
            audience.territoryLoading,
            (!sharedPage && milestones.entities[entity] && milestones.entities[entity].loading)
        ];
        let ready = true;
        for(let flag of dataLoadingFlags) {
            if(flag) {
                ready = false;
                break;
            }
        }
        return ready;
    }

    exportToCsv() {
        const {
          audience,
          milestones,
          match,
          sharedPage = false
        } = this.props;
        
        const entity = match.params.entity;

        let data = [
          { streams_and_listeners: this.exportStreamsListeners(audience.timeline) },
          { audience_demographics: audience.demographics.table },
          { audience_repeat_listeners: audience.repeatListeners.table },
          { audience_discovery: audience.sourceStats.table },
          { audience_territories: audience.territory.table },
          { audience_playlists: audience.playlist }
        ];
        
        if(!sharedPage)
        data.push({milestones: milestones.entities[entity].tableItems });

        const filename = CSV.CSVHeader('audience_analysis', `${this.state.entity}-${this.state.id}`, this.props.filter.global);

        CSV.CSVBulkExport(data, filename);
    }
    
    getPdfData() {
        const { entity } = this.props.match.params,
            reportTitle = 'Audience Analysis',
            { audience, milestones } = this.props;
            
        const imageIDs = [
            'audience_streams_line_chart',
            'audience_demographics_bar_chart',
            'audience_sources_pie_chart',
            'audience_repeat_listeners_line_chart'
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        const tables = {
            milestones: milestones.entities[entity].tableItems,
            territories: audience.territory.table.slice(0, 10),
            playlists: audience.playlist,
            sources: audience.sourceStats.table,
            demographics: cloneDeep(audience.demographics.table),
        }
        return { reportTitle, images, tables, globalFilter: this.props.filter.global, entity: this.state.titlePdf, repeatListenersDataset: audience.repeatListeners.dataset };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "audience_analysis.pdf";
            link.click();
        });
    }
        
    
    render() {
        if(!this.state.id)
            return null;
        
        return <RedirectToResults entity={this.state.entity} id={this.state.id} tab="audience" />
        
        let metaTitle = this.state.title ? `${this.state.title} - ` : '';
        metaTitle += 'Audience Analysis';
        const entityFilter = this.state.entity == 'artists' ? 'track_artists' : this.state.entity;
        const { sharedMode = false, widgetProps } = this.props;

        return <div className="audience-analysis">
            <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <EntityCard entity={this.state.entity} id={this.state.id} onResult={this.setParentEntity}  />
                </div>
            </div>
            {this.isDataReady(sharedMode) && <div className="row download-page">
                {!sharedMode && <div className="download-item">
                    <SharePage module="audience_analysis" entity={this.state.entity} id={this.state.id}  exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} />
                </div>}
                {sharedMode && <React.Fragment>
                    <div className="download-item">
                        <span>Export</span>
                        <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                    </div>
                    <div className="download-item">
                        <span>PDF</span>
                        <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                    </div>
                </React.Fragment>}
            </div>}
            <div className="row">
                <div className="col-xs-12 pd-0 default">            
                    <Widget widgetID="streams" widgetProps={widgetProps}>
                        <Streams entity={entityFilter} id={this.state.id}>
                            <EntityMilestones entity={this.state.entity} id={this.state.id} />
                        </Streams>
                    </Widget>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <Widget widgetID="demographics" widgetProps={widgetProps}>            
                        <Demographics entity={entityFilter} id={this.state.id} />
                    </Widget>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 pd-0 default"> 
                    <Widget widgetID="listeners" widgetProps={widgetProps}>
                        <RepeatListeners entity={entityFilter} id={this.state.id} />
                    </Widget>            
                </div>
            </div>
            
            <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <Widget widgetID="territories" widgetProps={widgetProps}>
                        <Territory entity={entityFilter} id={this.state.id} expanded={true} />
                    </Widget>
                </div>
            </div>            

            <div className="row">
                <div className="col-xs-12 col-sm-6 pd-0 default">
                    <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                        <TopPlaylists entity={entityFilter} id={this.state.id} />
                    </Widget>
                </div>
                <div className="col-xs-12 col-sm-6 pd-0 default">  
                    <Widget widgetID="sources" widgetProps={widgetProps}>
                        <Sources entity={entityFilter} id={this.state.id} />
                    </Widget>  
                </div>            
            </div>
                
            {!sharedMode && <div className="row">
                <div className="col-xs-12 pd-0 default">
                    <Widget widgetID="milestones" widgetProps={widgetProps}>
                        <Milestones entity={this.state.entity} id={this.state.id} />
                    </Widget>
                </div>
            </div>}            
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        audience: state.audience,
        playlists: state.playlists,
        milestones: state.milestones
    }
}

export default connect(mapStateToProps)(WithWidgetManager(AudienceDetails, 'audience_details'))