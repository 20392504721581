import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router';
import {releasesActions} from '../data/actions/releases';
import ReactTable from "react-table";
import { debounce, find, findIndex, isEqual, flatten } from 'lodash';
import Datepicker from '../components/common/Datepicker';
import { previousFriday } from '../data/helpers/heartbeats';
import SectionHeader from '../components/common/Datagrid/SectionHeader';
import PageFilter from '../components/common/Datagrid/PageFilter';
import Tooltip from '../components/common/Tooltip';
import DetailsLink from "../components/common/Datagrid/DetailsLink";
import moment from 'moment';
import CSV from '../helpers/CSVExport';
import PlaylistAnalysisLink from '../components/common/Datagrid/PlaylistAnalysisLink';
import { Link } from 'react-router-dom';
import { splitByThousands } from '../helpers/NumberFormatter';
import CopyToClipboard from '../components/common/CopyToClipboard';

import cellHeaderHOC from '../components/common/Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);

const dateFormat = 'YYYY-MM-DD';
//const disabledDaysOfWeek = [0, 1, 2, 3, 4, 6];
const disabledDaysOfWeek = [];

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const plusSvg = require('!svg-inline-loader!../../public/img/plus.svg');
const minusSvg = require('!svg-inline-loader!../../public/img/minus.svg');
var copySvg = require('!svg-inline-loader!../../public/img/copy-icon.svg');

export class NewReleases extends React.Component {
  constructor(props){
    super(props);
    this.setFilterSearchDeb = debounce(this.setFilterSearch, 1000);
    this.dateChange = this.dateChange.bind(this);
    this.bulkExportToCsv = this.bulkExportToCsv.bind(this);
    this.state = {
      expanded: {},
      filtered: '',
      pageSize: 10,
      filteredProductsData: [],
      filter: {
        filtered: [{id: 'track_title', value: ''}]
      },
      pageNumber: 0,
      filterDate: moment(previousFriday()).format(dateFormat),
      defaultSorted: [{id: 'units', desc: true}],
    }
  }

  setFilterSearch = (value, id) => {
    let filtered = this.state.filter.filtered.slice(0),
      valueIndex = findIndex(filtered, {id});
    const filteredProductsData = this.props.releases.new.filter( release => {
      return release.tracks.some( track => track.track_title.indexOf(value) > -1)
    });

    if (valueIndex !== -1) {
      filtered[valueIndex] = {
        id,
        value
      };
    } else {
      filtered.push({id, value})
    }

    this.setState({
      ...this.state,
      filteredProductsData,
      //pageSize: filteredProductsData.length,
      filter: {
        ...this.state.filter,
        filtered
      }
    });
  }

  dateChange(date, propDate){
    date = moment(date).format(dateFormat);
    this.setState({
      filterDate: date,
      expanded: {},
      filtered: '',
      pageSize: 10,
      pageNumber: 0,
      filteredProductsData: [],
      filter: {
        filtered: [{id: 'track_title', value: ''}]
      },
    }, ()=>{
        this.dispatchLoadData({page: this.state.pageNumber, pageSize: this.state.pageSize}, date);    
    });

    
  }

  dispatchLoadData = (state, instance={}, date = this.state.filterDate) => {
    const { dispatch } = this.props;
    const pagination = { page: state.page + 1, per_page: state.pageSize};
    const sorted = state.sorted ? state.sorted : this.state.defaultSorted; 
    dispatch(releasesActions.getNewReleases(date, pagination, sorted));
  }

  handleToggleSubComponent = (event, props) => {
    const rowNumber = props.viewIndex;
    const expanded = {};
    const value = this.state.expanded[props.viewIndex]
      ? false
      : true;
    expanded[rowNumber] = value;
    this.setState({expanded})
  }

  setSearch = (e) => {
    const value = e.target.value;
    this.setState({filtered: value});
    this.setFilterSearchDeb(value, 'track_title');
  }

  getFileName = (record, upc) => {
    const todayDate = moment(new Date()).format('YYYYMMDD');
    const fromDay = moment(new Date(this.state.filterDate)).format('YYYYMMDD');
    if(upc){
      return `new_releases_${upc}_${fromDay}-${todayDate}`;
    } else {
      return `new_releases_${fromDay}-${todayDate}`;
    }
  }

  exportToCsv = (record, upc) => {
    const filename = this.getFileName(record, upc);
    return CSV.CSVExport(record, {filename}, 'tracks');
  }

  prepareReleasesToTracks = releases => {
    let result;
    if(releases){
      const tracks = releases.map( release => {
        return this.prepareReleaseToTracks(release);
      });
      result = flatten(tracks);
    } else {
      result = []
    }
    return result;
  }

  handlePercentage = value => {
    return Math.floor(value) + '%';
  }

  buildCarcassTrackObject = release => {
    return {
      track_title: '',
      upc: `'${release.upc}`,
      isrc: '',
      units: release.units,
      playlists: release.playlists,
      playlist_units: release.playlist_units,
      playlist_percentage: this.handlePercentage(release.playlist_percentage)
    }
  }

  prepareReleaseToTracks = release => {
    const releaseData = {'product_title': release.product_title, 'upc': '' };
    let carcassTrackObject;
    const tracks = release.tracks.map( track => {
      const trackData = {...track};
      carcassTrackObject = {...track};
      trackData.playlist_percentage = this.handlePercentage(trackData.playlist_percentage);
      return {...trackData, ...releaseData };
    })

    tracks.unshift({ ...releaseData, ...this.buildCarcassTrackObject(release) });
    return tracks;
  }

  bulkExportToCsv(){
    const {
      releases,
    } = this.props;
    
    let newReleases = [];
    
    for(let product of releases.new) {
        for(let track of product.tracks) {
            newReleases.push({
                ...product,
                ...track,
                'playlist_ratio': this.handlePercentage(track.playlist_percentage)
            });
        }
    }
    
    const data = [
      { new_releases: newReleases}
    ];

    const filename = CSV.CSVHeader('new_releases', null, this.props.filter.global);
    CSV.CSVBulkExport(data, filename);

  }

  render() {
    const data = this.state.filteredProductsData.length !== 0 ? this.state.filteredProductsData : this.props.releases.new;

    const pagination = this.props.releases.pagination ? this.props.releases.pagination : {};
    const preparedReleasesToTracks = data !== undefined ? this.prepareReleasesToTracks(data) : [];


    const subComponent = (row) => {
      const filteredTracks = row.original.tracks.filter( track => track.track_title ? track.track_title.indexOf(this.state.filtered) > -1 : false);
      return (<CellHeaderTable 
        resizable={false} 
        pageSize={filteredTracks.length} 
        showPagination={false} 
        data={filteredTracks} 
        className={"new-releases-track new-releases-inner-table"} 
        noDataText='No results found. Please check your filters.'
        columns={[
          {
            id: 'arrow_1',
            className: 'imprint-name arrow frozen',
            headerClassName: 'imprint-name arrow frozen no-sort',
            Header: "Actions",
            sortable: false,
            Cell: props => {
              return (<div className="new-release-action-holder">
                <PlaylistAnalysisLink entity="tracks" id={props.original.track_id} />
              </div>)
            },
            width: 98,
          },
          {
            id: 'logo_empty',
            className: 'imprint-name release-logo',
            headerClassName: 'imprint-name release-logo',
            width: 77,
          },
          {
            id: 'track_title',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'Track',
            accessor: 'track_title',
            Cell: ({ value, ...props }) => {
              return (
                <div>
                  <div className="product-item-title">
                    <DetailsLink
                      title="Track Details"
                      entity="tracks"
                      id={props.original.track_id}
                      text={value}
                      noIcon
                    ></DetailsLink>
                  </div>
                  <span className="isrc-holder with-copy"><CopyToClipboard value={props.original.isrc}>{props.original.isrc}</CopyToClipboard></span>
                </div>
              );
            },
            width: 250,
          }, 
          // {
          //   id: 'isrc',
          //   className: 'imprint-name',
          //   headerClassName: 'imprint-name',
          //   Header: 'International Standard Recording Code',
          //   accessor: 'isrc',
          // }, 
          {
            id: 'release_date1',
            className: 'imprint-name',
            Header: 'Release date',
            headerClassName: 'imprint-name',
            accessor: 'release_date',
            // accessor: release => {
            //   const date = new Date(release.release_date);
            //   return date.toLocaleString('default', {
            //     day: '2-digit',
            //     month: 'short',
            //     year: 'numeric'
            //   });
            // },
            sortable: false,
          },
          {
            id: 'units',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'Streams',
            accessor: 'units',
            Cell: props => splitByThousands(props.value),
          }, {
            id: 'playlists',
            className: 'imprint-name',
            headerClassName: 'imprint-name',
            Header: 'Playlists',
            accessor: 'playlists',
          }, {
            id: 'pl-streams',
            className: 'imprint-name',
            Header: 'PL Streams',
            headerClassName: 'imprint-name',
            accessor: 'playlist_units',
            Cell: props => splitByThousands(props.value),
          }, {
            id: 'pl-ratio',
            className: 'imprint-name',
            Header: 'PL Ratio',
            headerClassName: 'imprint-name',
            accessor: 'playlist_percentage',
            Cell: ({value}) => {
              return this.handlePercentage(value);
            },
          }
        ]}/>)
    }

    return (<div className="homepage">
      {/* <SectionHeader label="New Releases" searchEntity="Track" data={data === undefined ? [] : data.tracks} filtered={this.state.filtered} onChange={this.setSearch} view="table" ></SectionHeader> */}
      <div className="column ibox-content section-header">
        <h2 className="ibox-title__title title-with-padding">
          <span className="title-text">
            New Releases
            <div className="title-tooltip release">
              <span data-tip data-for="new-release-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
                  <i className="fas fa-question-circle" /> 
              </span>
              <ReactTooltip effect="solid" multiline={true} place="bottom" className="top-sections-tooltip" id="new-release-tip">
                New Releases include products released in the past 6 weeks
              </ReactTooltip>
            </div>
          </span>
          <div className="title-action-buttons">
            <a key="download" title="Export CSV" onClick={() => this.exportToCsv(preparedReleasesToTracks)} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
          </div>
        </h2>
      
      </div>  
      <div className="row new-releases-header" id="new-releases-header">
        <div className="col-xs-12 col-sm-6">
          <div className='calendar-filter'>
              <span className="calendar-filter-label">From: </span>
              {/* <i className="fa fa-calendar-week calendar-button"></i> */}
              <div className="new-release-datepicker">
              {/* endDate={previousFriday()} */}
                <Datepicker date={this.state.filterDate} dateChange={(date)=>this.dateChange(date, 'dateEnd')} daysOfWeekDisabled={disabledDaysOfWeek}  container="#new-releases-header" />
              </div>
            </div>
          </div>
        {/* <div className="col-xs-12 col-sm-6">  
          <div className="download-page">
            <span>Export</span>
            <a key="download" title="Download entire page" onClick={this.bulkExportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
          </div>
        </div> */}
    </div>
      <CellHeaderTable
        defaultSorted={this.state.defaultSorted}
        defaultSortDesc={true}
        resizable={false}
        loading={this.props.releases.releasesInProgress}
        className="new-releases-table"
        defaultPageSize={this.state.pageSize}
        expanded={this.state.expanded}
        noDataText='No results found. Please check your filters.'
        columns={[
          {
            id: 'arrow',
            className: 'imprint-name arrow frozen',
            headerClassName: 'imprint-name arrow frozen no-sort',
            Header: "Actions",
            Cell: props => {
              return (<div className="new-release-action-holder">
                <button className="plus-button" onClick={(e) => this.handleToggleSubComponent(e, props)}>
                {
                  this.state.expanded[props.viewIndex] ? <span title="Track Breakdown" dangerouslySetInnerHTML={{__html: minusSvg}} /> : <span title="Track Breakdown" dangerouslySetInnerHTML={{__html: plusSvg}} />
                }
                </button>
                <PlaylistAnalysisLink entity="products" id={props.original.product_id} />
              </div>)
            },
            width: 98,
            sortable: false
          }, 
          {
              id: 'expander',
              expander: true, 
              headerClassName: 'default-expander',
              width: 0,
              sortable: false
          },                             
          {
            id: 'artist_image',
            className: 'imprint-name release-logo frozen no-sort',
            headerClassName: 'imprint-name release-logo frozen',
            accessor: 'artist_image',
            Header: "Logo",
            Cell: ({value, ...props}) => {
              return (<div className="image-cell">
                <Link to={`/artists/${props.original.artist_id}`}><img src={value}/></Link>
              </div>);
            },
            width: 77,
            sortable: false
          }, {
            id: 'product_title',
            className: 'imprint-name release-product frozen',
            headerClassName: 'imprint-name release-product rt-resizable-header',
            Header: 'Product',
            accessor: 'product_title',
            sortable: true,
            Cell: ( { value, ...props } ) => {
              return (
                <div className="">
                  <DetailsLink
                    title="Product Details"
                    entity="products"
                    id={props.original.product_id}
                    text={value}
                    noIcon
                  ></DetailsLink>
                  {/* <div>{props.original.artist_name}</div> */}
                  <div>{ props.original.artist_id ? <Link to={`/artists/${props.original.artist_id}`}>{props.original.artist_name}</Link> : props.artist_name }</div>
                  <span className="isrc-holder with-copy"><CopyToClipboard value={props.original.upc}>UPC{props.original.upc}</CopyToClipboard></span>
                </div>
              );
            },
            width: 250,
          }, 
          // {
          //   id: 'artist_name',
          //   className: 'imprint-name release-artist frozen',
          //   headerClassName: 'imprint-name release-artist frozen',
          //   Header: 'Artist',
          //   accessor: 'artist_name',
          // }, 
          // {
          //   id: 'actions',
          //   className: 'imprint-name',
          //   Cell: props => {
          //     return <a key="download" onClick={() => this.exportToCsv(this.prepareReleaseToTracks(props.original), props.original.upc)} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
          //   },
          // }, 
          // {
          //   id: 'imprint_name',
          //   className: 'imprint-name',
          //   Header: 'Imprint',
          //   headerClassName: 'imprint-name',
          //   accessor: 'imprint_name',
          // }, 
          // {
          //   id: 'upc',
          //   className: 'imprint-name',
          //   Header: 'UPC',
          //   headerClassName: 'imprint-name',
          //   accessor: 'upc',
          // }, 
          {
            id: 'release_date',
            className: 'imprint-name',
            Header: 'Release date',
            headerClassName: 'imprint-name rt-resizable-header',
            accessor: release => {
              const date = new Date(release.release_date);
              return moment(date).format('YYYY-MM-DD');
            },
            sortable: true,
          }, {
            id: 'units',
            className: 'imprint-name',
            Header: 'Streams',
            headerClassName: 'imprint-name rt-resizable-header',
            accessor: 'units',
            Cell: props => splitByThousands(props.value),
            sortable: true,
          }, {
            id: 'playlists',
            className: 'imprint-name',
            Header: 'Playlists',
            headerClassName: 'imprint-name rt-resizable-header',
            accessor: 'playlists',
            sortable: true,
          }, {
            id: 'playlist_units',
            className: 'imprint-name',
            Header: 'PL Streams',
            headerClassName: 'imprint-name rt-resizable-header',
            accessor: 'playlist_units',
            sortable: true,
            Cell: props => splitByThousands(props.value),
          }, {
            id: 'playlist_percentage',
            className: 'imprint-name',
            Header: 'PL Ratio',
            headerClassName: 'imprint-name rt-resizable-header',
            accessor: 'playlist_percentage',
            Cell: ({value}) => {
              return this.handlePercentage(value);
            },
            sortable: true,
          }
        ]} showPagination={true} showPaginationBottom={true} onFetchData={this.dispatchLoadData} manual pages={pagination.total_page} SubComponent={subComponent} data={data}/>
      <Helmet>
          <title>New Releases - Reports</title>
      </Helmet>

      </div>);
  }
}

function mapStateToProps(state) {
  return { releases: state.releases, filter: state.filter }
}
export default withRouter(connect(mapStateToProps)(NewReleases));
