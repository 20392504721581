import React, { Component } from 'react';
import Modal from './Modal';
import Spinner from './Spinner';

var downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class ExportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.showModal = this.showModal.bind(this);
    }
    
    showModal(show) {
        this.setState({
            showModal: show !== undefined ? show: !this.state.showModal
        })
    }

    render() {
        const { exportTotals, exportBreakdown, loading = false } = this.props;
        return <div className="export-modal-holder">
            <Modal show={this.state.showModal} 
                title="Export Data" 
                showActionButtons={false}
                // size="sm"
                handleClose={()=>this.showModal(false)}
            dialogClassName="entity-filter-modal" 
            >
                <p className="export-modal">
                    <a className="export-modal-link" onClick={()=>exportTotals()}>Totals</a>
                    <a className="export-modal-link" onClick={()=>exportBreakdown()}>Breakdown</a>
                    <Spinner enabled={loading} />
                </p>
            </Modal>
            <a key="download" title="Export" onClick={()=>this.showModal(true)} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
    }
}
export default ExportModal;