import React from 'react';
import ReactTooltip from 'react-tooltip';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import CountryFlag from '../../CountryFlag';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../Tooltip';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../Datagrid/Footers';

export const territorySettings = {
    settingsForTable
};


function settingsForTable(mode){
    const modes={
        'map': ['logo', 'share', 'name', 'curr_units'],
        'details': ['expander', 'rank', 'logo', 'name', 'share', 'curr_units', 'per_cap_units', 'curr_playlists', 'skipped_ratio', 'completed_ratio'],
        'expanded': ['expander', 'rank', 'logo', 'name', 'share', 'curr_units', 'per_cap_units', 'curr_playlists', 'skipped_ratio', 'completed_ratio',  'completed_diff'/*, 'curr_listeners'*/]
    };
    
    
    let columns = [
       {
           id: 'expander',
           expander: true,
           width: 35,
           Expander: ({ isExpanded, ...rest }) => (
             <div>
               {isExpanded ? (
                 <span>&#x25BC;</span>
               ) : (
                 <span>&#x25B6;</span>
               )}
             </div>
           ),
           style: {
             cursor: "pointer",
             fontSize: 10,
             padding: "0",
             textAlign: "center",
             userSelect: "none"
           },
       },            
       {
           id: 'rank',
           className: 'rank',
           headerClassName: 'rank',
           Header: 'Rank',
           width: 40,
           accessor: 'rank',
           Footer: spaceFooter,
       },
                   
        {
            id: 'logo',
            className: 'logo-sell',
            headerClassName: 'logo-sell no-sort',
            Header: '',
            accessor: 'name',
            width: 40,
            sortable: false,
            Cell: props => {
                return <CountryFlag className="flag-medium" country={props.original.code} />
         },
            Footer: spaceFooter 
      },
      {
          id: 'name',
          Header: 'Country',
          accessor: 'name',
          Footer: textFooter
      },      
      {
              id: 'share',
              headerClassName: '',
              Header: 'Share',
              accessor: 'share',
              Cell: props => <span>{props.value}%</span>,
              Footer: spaceFooter
          },
       {
           id: 'curr_units',
           headerClassName: 'total',
           className: 'total',
           Header: 'Units',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           accessor: 'curr_units',
           Footer: convertSumFooter
       },
       {
              id: 'per_cap_units',
              headerClassName: 'total',
              className: 'total',
              Header: 'Per Capita',
              Cell: props => <AbsoluteValue value={Math.round(props.value)}></AbsoluteValue>,
              accessor: 'per_cap_units',
              Footer: (props)=>avgFooter(props, null, null, '')
          },

       
       {
          id: 'curr_playlists',
          headerClassName: 'total',
          className: 'total',
          Header: 'Playlists',
          Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
          accessor: 'curr_playlists',
          Footer: convertSumFooter
      },
           
       {
           id: 'skipped_ratio',
           headerClassName: '',
           Header: props => <div className="top-table-header-inner">
               Skip Rate A/V
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google. A = Audio, V = Video`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,

           Cell: ({original}) => <span>{original.skipped_audio_ratio}% / {original.skipped_video_ratio}%</span>,
           width: 135,
           accessor: 'skipped_ratio',
           Footer: (props)=><React.Fragment>{avgFooter(props, null, 'skipped_audio_ratio')} / {avgFooter(props, null, 'skipped_video_ratio')}</React.Fragment>
       },       
       {
           id: 'completed_ratio',
           headerClassName: '',
           Header: props => <div className="top-table-header-inner">
               Completion Rate A/V
               <div className="table-header-tooltip">
                    <Tooltip position="right-bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google. A = Audio, V = Video`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,

           Cell: ({original}) => <span>{original.completed_audio_ratio}% / {original.completed_video_ratio}%</span>,
           width: 135,
           accessor: 'completed_ratio',
           Footer: (props)=><React.Fragment>{avgFooter(props, null, 'completed_audio_ratio')} / {avgFooter(props, null, 'completed_video_ratio')}</React.Fragment>
       },
       /*
       {
           id: 'curr_listeners',
           headerClassName: '',
           className: 'listeners',
           Header: 'Listeners',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 100,
           accessor: 'curr_listeners'
       },
       */       

    ];
    return columns.map(column=>{
        column.show = modes[mode].includes(column.id);
        return column;
    });
}