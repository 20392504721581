import React from 'react';
import { connect } from 'react-redux';
import { filter, isEqual, map, sumBy } from 'lodash';
import store from '../../../../helpers/store'

import BarChart from '../../../widgets/charts/types/BarChart';
import PieChart from '../../../widgets/charts/types/PieChart';
import Box from '../../../common/Box';
import { Link } from 'react-router-dom';
import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import ReactSelect from '../../../common/ReactSelect';
import { audienceActions } from '../../../../data/actions/audience'; 


class TerritoryDemographics extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            age: null,
            gender: null
        }
        
        this.setAge = this.setAge.bind(this);
        this.setGender = this.setGender.bind(this);
        this.reset = this.reset.bind(this);        
    };    
    
    setAge(age) {
        this.setState({
            age,
            gender: null
        })
    }
    
    setGender(gender) {
        this.setState({
            gender,
            age: null
        })
    }
    
    reset() {
        this.setState({
            age: null,
            gender: null
        })
    }

    
    componentDidMount() {
        const { filtered, territory } = this.props;
        this.props.dispatch(audienceActions.getDemographicsTerritoryStats(filtered, territory));
    }
    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(audienceActions.getDemographicsTerritoryStats(this.props.filtered, this.props.territory))
            }                
        };
        
        if(nextProps.territory && !isEqual(nextProps.territory, this.props.territory)){
            this.props.dispatch(audienceActions.getDemographicsTerritoryStats(this.props.filtered, nextProps.territory));
        }

    }
    
    renderStackedChart(data) {
        const options = {
            maintainAspectRatio: false,
            plugins:{
                legend:{
                    position: 'bottom',
                    labels: {
                        filter: (item, data) => {
                            return !(item.text.includes('('));
                        }
                    }
                }
            }
        };
        return <BarChart data={data} options={options} />
    }
    
    renderTerritories() {
        const { territory, setTerritory } = this.props;
        let selectedTerritory = {},
            territories = [];
        for (let country of countriesByAbbreviation) {
            if(country.abbreviation===null)
                continue;
            
            let item = {
                label: country.country,
                value: country.abbreviation
            };
            
            territories.push(item);
            
            if(country.abbreviation == territory)
                selectedTerritory = item;
        }
        
        return <div className="demography-select"><ReactSelect value={selectedTerritory} options={territories} onChange={(territory)=>setTerritory(territory.value)} className="single-select"/><div className="default-btn default-btn--small back-to-map-btn" onClick={this.props.setMap}>Back</div></div>
        
    }

    render (){
        const { audience, territory } = this.props;
        const demographics = audience.demographicsTerritory[territory];
        // const noRecords = demographics && demographics.data && demographics.data.length == 0;
        // const records = demographics && demographics.data && demographics.data.length > 0;
        return <div className="default"><Box title={''} spinnerEnabled={audience.demographicsTerritoryLoading} className="inner-ibox">
            {this.renderTerritories()}
            {/* {noRecords && <p>No demographics data available for this country</p>} */}
            {demographics && <div className="gender-chart-holder">
                <div className="gender-charts">
                    <div className="chart-block chart-block-gender">
                        {this.renderStackedChart(demographics)}
                    </div>
                </div>
            </div>}
        </Box></div>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(TerritoryDemographics);