import { some, map } from 'lodash'

export const catalogueExceptionsFormatter = {
    formatCatalogueExceptions,
    exceptionsCountFormat
};

function formatCatalogueExceptions(data){
    data.tracks = data.tracks.map(product=>{
        product.product_status = some(product.tracks, (track)=>track.track_status == 1) ? 1 : 4;
        return product;
    })
    return data;
}

function exceptionsCountFormat(data) {
  if(Array.isArray(data)) {
    if(data.length !== 0) {
      return true
    }
  } else {
    if(Object.values(data) != 0) {
      return true
    }
  }

  return false;

}
