import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Spinner from '../../common/Spinner';
import PercentageValue from '../../common/PercentageValue';
import Channels from './Channels';
import AudienceAnalysisLink from '../../common/Datagrid/AudienceAnalysisLink';
import EntityStatus from '../../common/Datagrid/EntityStatus';
import { convertToThousands } from  '../../../helpers/NumberFormatter';
import Tooltip from '../../common/Tooltip';
import ScrollButtons from '../../common/ScrollButtons';
import SharePage from '../sharedPages/SharePage';
import MatchMediaWrapper from '../../../helpers/MatchMediaWrapper';

class ArtistOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resizeObserver: undefined,
            tableContainer: undefined,
            hasHorizontalScroll: false
        }

        this.resizeObserverFunction = this.resizeObserverFunction.bind(this)
    }

    resizeObserverFunction() {
        const tableContainer = document.getElementById('overview-tiles-holder');
        if (!tableContainer)
            return;
        
        this.setState({
            tableContainer
        });

        if (window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                this.setState({
                    hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
                });
            });
            this.setState({
                resizeObserver
            });
        
            resizeObserver.observe(tableContainer);
        } else {
            // console.log("Resize observer not supported!");
            this.setState({
                hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
            });
        }
    }

    getTilesBoundary() {
        const tile = document.getElementsByClassName("overview-card");
        const tileWidth = tile && tile[0] && tile[0].offsetWidth;

        return tileWidth;
    }

    componentDidMount() {
        if (!this.props.loading)
            this.resizeObserverFunction()
    }

    componentDidUpdate(prevProps) {
        if (this.props.loading !== prevProps.loading && !this.props.loading) {
            this.resizeObserverFunction();
            this.getTilesBoundary();
        }
    }

    componentWillUnmount() {
        const tableContainer = document.getElementsByClassName('overview-tiles-holder')[0];

        this.state.resizeObserver && this.state.resizeObserver.unobserve(tableContainer)
    }

    render (){
        const { artist, loading, externalLinks, exportToCsv, exportToPdf } = this.props;
        
        let metaTitle = ( artist && artist.name) ? `${artist.name} (artist) - ` : '';
        metaTitle += 'Details';

        return <div className="default">
            {loading !== false ? <Spinner enabled={true} /> : <div className="artist-overview-inner">
                <div className="artist-profile-holder">
                    <div className="artist-profile-logo" style={{backgroundImage: `url(${artist.image})`}} >
                    </div>
                    <div className="artist-info-holder">
                        <div className="artist-main-info">
                            <div className="title-container portrait">
                                <h1 className="content-title">
                                    <span className="content-title-span">{artist.name}</span>
                                        {/* {externalLinks && <div className="artist-details-link">
                                            <AudienceAnalysisLink title="Artist Audience" entity="artists" id={artist.id} />
                                            <EntityStatus entity="artists" id={artist.id} />
                                        </div>} */}
                                </h1>
                            </div>
                            <div className="profile-navigation">
                                <div className="profile-navigation-link">Overview</div>
                                <div className="profile-navigation-link">Audience</div>
                                <div className="profile-navigation-link">Playlists</div>
                                <div className="profile-navigation-link">Profile</div>
                            </div>
                            <div className="profile-action-buttons-holder">
                                {externalLinks && <SharePage entity="artists" id={artist.id} exportToCsv={exportToCsv} exportToPdf={exportToPdf} />}
                            </div>
                        </div>
                        <div className="row catalog-overview home artist-overview">
                            {this.state && this.state.hasHorizontalScroll && <ScrollButtons el={this.state.tableContainer} scrollStepValue={this.getTilesBoundary()} />}
                            <div className="overview-tiles-holder artists" id="overview-tiles-holder">
                                <MatchMediaWrapper breakpoint={959} isMobile={true}>
                                    <h2 className="ibox-title__title tiles-mobile-title">
                                        Overview
                                    </h2>
                                </MatchMediaWrapper>
                                <div className="overview-tiles-holder-inner">
                                {artist.totals && artist.totals.map(entity=>{
                                    return <div key={entity.title} className={`overview-tiles-item ${entity.title}`}>
                                        <div className="widget overview-widget white-bg p-lg text-center">
                                            <div className="overview-card">
                                                {/* <span className={`tiles-rounded-icon tiles-rounded-icon--${entity.iconName}`} dangerouslySetInnerHTML={{__html: entity.icon}} /> */}
                                                <div className='tiles-main-info-holder'>
                                                    <div className="title-totals-container">
                                                        <h3 className="title-totals">{entity.title}</h3>
                                                        {entity.title === "streams" && 
                                                        <div className="top-sections-tooltip">
                                                            <span data-tip data-for="dpb-title-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
                                                                <i className="fas fa-question-circle" /> 
                                                            </span>
                                                            <ReactTooltip effect="solid" multiline={true} place="top" className="top-sections-tooltip" id="dpb-title-tip">
                                                                Total Artist streams on the Overview and Catalogue pages are for products and tracks where the artist is Primary Position One only. On the Artist Details page, Total streams are higher because they also include products and tracks where the artist is not the first Primary artist listed.
                                                            </ReactTooltip>
                                                        </div>}
                                                    </div>
                                                    <div className='title-totals-holder'>
                                                        <h2 className={`title-num`}>{convertToThousands(entity.absoluteValue)}</h2>
                                                        <PercentageValue onlyPercentage={true} item={entity} field={entity.key} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                            
                                    </div>
                                })} 
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                 
                {/* <MatchMediaWrapper breakpoint={641} isMobile={false}>
                    {(artist.related_artists && artist.related_artists.length) ? <div className="row">
                        <div className="related-artists-holder">
                            <h2 className="related-artists-title">Related Artists</h2>
                            <div className="similar-artists">
                                {artist.related_artists.map((artist)=><div key={artist.id} className="logo-container">
                                    <Link to={`/artists/${artist.id}`}>
                                        <div title={artist.name} className="logo" style={{backgroundImage: `url(${artist.image})`}} data-toggle="tooltip" data-placement="top" /> 
                                    </Link>
                                </div>)}
                            </div>
                        </div>
                    </div> : null}
                </MatchMediaWrapper> */}
            </div> }
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>
        </div>
    }
}


export default ArtistOverview