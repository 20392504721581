import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import PDFHeader from '../result/PDFHeader';

import { convertToThousands, addPercent } from '../../../helpers/NumberFormatter';

const renderHeartbeats=(data)=>{
    const columns=[
       {key: 'PDFHeader', title: 'Track', width: 25},
       {key: 'PDFGrowth', title: 'Growth', width: 25},
       {key: 'PDFReleaseDate', title: 'Release Date (new)', width: 15},
       {key: 'PDFBiggestMover', title: 'Biggest Mover', width: 15},
       {key: 'tiktok_2_views', title: 'Views', width: 10},
       {key: 'tiktok_2_creates', title: 'Creates', width: 10},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}

export default (props) => {
    const { data: { tables, reportTitle } } = props;
    if(!Array.isArray(tables.data) || !tables.data.length)
        return null;
    const record = tables.data[0];
    let releaseType;
    switch(tables.state.newRelease) {
        case 'true':
            releaseType = 'New Release';
            break;
        case 'false':
            releaseType = 'Catalog';     
            break;            
        default:
            releaseType = 'All';
            break;
    }

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.reportTitle}>
                <Text className={styles.smallHeaderNotUnderline}>{reportTitle}</Text>
                <View style={styles.section}>
                    <Text style={styles.dateRangeTitle}>Wk#{record.period_2_week}: {record.period_2_start} - {record.period_2_end}</Text>
                </View>
                <View style={styles.reportTitle}>
                    <Text style={styles.dateRangeTitle}>Week Units: {convertToThousands(tables.state.minStreams)}</Text>
                    {tables.state.minTiktokCreates && <Text style={styles.dateRangeTitle}>TikTok Creates: {convertToThousands(tables.state.minTiktokCreates)}</Text>}
                    <Text style={styles.dateRangeTitle}>Percent Change: {tables.state.percentage}%</Text>
                    <Text style={styles.dateRangeTitle}>Release Type: {releaseType}</Text>
                </View>
            </View>
            <View style={styles.columnSection}>
                {renderHeartbeats(tables.data)}
            </View>      
        </Page>
    </Document>
};