export const statsConstants = {
    GET_TOP_STATS_REQUEST: 'GET_TOP_STATS_REQUEST',
    GET_TOP_STATS_SUCCESS: 'GET_TOP_STATS_SUCCESS',
    GET_TOP_STATS_FAILURE: 'GET_TOP_STATS_FAILURE',
    GET_CATALOG_STATS_REQUEST: 'GET_CATALOG_STATS_REQUEST',
    GET_CATALOG_STATS_SUCCESS: 'GET_CATALOG_STATS_SUCCESS',
    GET_CATALOG_STATS_FAILURE: 'GET_CATALOG_STATS_FAILURE',    
    GET_VENDOR_STATS_REQUEST: 'GET_VENDOR_STATS_REQUEST',
    GET_VENDOR_STATS_SUCCESS: 'GET_VENDOR_STATS_SUCCESS',
    GET_VENDOR_STATS_FAILURE: 'GET_VENDOR_STATS_FAILURE',
    GET_VENDOR_TIMESERIES_REQUEST: 'GET_VENDOR_TIMESERIES_REQUEST',
    GET_VENDOR_TIMESERIES_SUCCESS: 'GET_VENDOR_TIMESERIES_SUCCESS',
    GET_VENDOR_TIMESERIES_FAILURE: 'GET_VENDOR_TIMESERIES_FAILURE',    
    GET_TERRITORY_STATS_REQUEST: 'GET_TERRITORY_STATS_REQUEST',
    GET_TERRITORY_STATS_SUCCESS: 'GET_TERRITORY_STATS_SUCCESS',
    GET_TERRITORY_STATS_FAILURE: 'GET_TERRITORY_STATS_FAILURE',
    GET_TERRITORY_TIMESERIES_REQUEST: 'GET_TERRITORY_TIMESERIES_REQUEST',
    GET_TERRITORY_TIMESERIES_SUCCESS: 'GET_TERRITORY_TIMESERIES_SUCCESS',
    GET_TERRITORY_TIMESERIES_FAILURE: 'GET_TERRITORY_TIMESERIES_FAILURE',    
    GET_TOP_TERRITORY_STATS_REQUEST: 'GET_TOP_TERRITORY_STATS_REQUEST',
    GET_TOP_TERRITORY_STATS_SUCCESS: 'GET_TOP_TERRITORY_STATS_SUCCESS',
    GET_TOP_TERRITORY_STATS_FAILURE: 'GET_TOP_TERRITORY_STATS_FAILURE',
    GET_TOP_TERRITORY_TIMESERIES_REQUEST: 'GET_TOP_TERRITORY_TIMESERIES_REQUEST',
    GET_TOP_TERRITORY_TIMESERIES_SUCCESS: 'GET_TOP_TERRITORY_TIMESERIES_SUCCESS',
    GET_TOP_TERRITORY_TIMESERIES_FAILURE: 'GET_TOP_TERRITORY_TIMESERIES_FAILURE',    
    GET_DEMOGRAPHICS_STATS_REQUEST: 'GET_DEMOGRAPHICS_STATS_REQUEST',
    GET_DEMOGRAPHICS_STATS_SUCCESS: 'GET_DEMOGRAPHICS_STATS_SUCCESS',
    GET_DEMOGRAPHICS_STATS_FAILURE: 'GET_DEMOGRAPHICS_STATS_FAILURE',
    GET_DEMOGRAPHICS_TIMESERIES_REQUEST: 'GET_DEMOGRAPHICS_TIMESERIES_REQUEST',
    GET_DEMOGRAPHICS_TIMESERIES_SUCCESS: 'GET_DEMOGRAPHICS_TIMESERIES_SUCCESS',
    GET_DEMOGRAPHICS_TIMESERIES_FAILURE: 'GET_DEMOGRAPHICS_TIMESERIES_FAILURE',    
    GET_DEVICES_STATS_REQUEST: 'GET_DEVICES_STATS_REQUEST',
    GET_DEVICES_STATS_SUCCESS: 'GET_DEVICES_STATS_SUCCESS',
    GET_DEVICES_STATS_FAILURE: 'GET_DEVICES_STATS_FAILURE',
    GET_SOURCES_STATS_REQUEST: 'GET_SOURCES_STATS_REQUEST',
    GET_SOURCES_STATS_SUCCESS: 'GET_SOURCES_STATS_SUCCESS',
    GET_SOURCES_STATS_FAILURE: 'GET_SOURCES_STATS_FAILURE',
    GET_SOURCES_DISCOVERY_STATS_REQUEST: 'GET_SOURCES_DISCOVERY_STATS_REQUEST',
    GET_SOURCES_DISCOVERY_STATS_SUCCESS: 'GET_SOURCES_DISCOVERY_STATS_SUCCESS',
    GET_SOURCES_DISCOVERY_STATS_FAILURE: 'GET_SOURCES_DISCOVERY_STATS_FAILURE',        
    GET_SOURCES_TIMESERIES_REQUEST: 'GET_SOURCES_TIMESERIES_REQUEST',
    GET_SOURCES_TIMESERIES_SUCCESS: 'GET_SOURCES_TIMESERIES_SUCCESS',
    GET_SOURCES_TIMESERIES_FAILURE: 'GET_SOURCES_TIMESERIES_FAILURE',    
    GET_DEVICES_TIMESERIES_REQUEST: 'GET_DEVICES_TIMESERIES_REQUEST',
    GET_DEVICES_TIMESERIES_SUCCESS: 'GET_DEVICES_TIMESERIES_SUCCESS',
    GET_DEVICES_TIMESERIES_FAILURE: 'GET_DEVICES_TIMESERIES_FAILURE',
    GET_SOURCES_DISCOVERY_TIMESERIES_REQUEST: 'GET_SOURCES_DISCOVERY_TIMESERIES_REQUEST',
    GET_SOURCES_DISCOVERY_TIMESERIES_SUCCESS: 'GET_SOURCES_DISCOVERY_TIMESERIES_SUCCESS',
    GET_SOURCES_DISCOVERY_TIMESERIES_FAILURE: 'GET_SOURCES_DISCOVERY_TIMESERIES_FAILURE',        
    GET_MULTI_ENTITIES_STREAMS_REQUEST: 'GET_MULTI_ENTITIES_STREAMS_REQUEST', 
    GET_MULTI_ENTITIES_STREAMS_SUCCESS: 'GET_MULTI_ENTITIES_STREAMS_SUCCESS',
    GET_MULTI_ENTITIES_STREAMS_FAILURE: 'GET_MULTI_ENTITIES_STREAMS_FAILURE',
    GET_MULTI_ENTITIES_METADATA_REQUEST: 'GET_MULTI_ENTITIES_METADATA_REQUEST', 
    GET_MULTI_ENTITIES_METADATA_SUCCESS: 'GET_MULTI_ENTITIES_METADATA_SUCCESS',
    GET_MULTI_ENTITIES_METADATA_FAILURE: 'GET_MULTI_ENTITIES_METADATA_FAILURE',
    RESET_RELEASE_MIN_DATE: 'RESET_RELEASE_MIN_DATE' ,
    GET_LOCATION_STATS_REQUEST: 'GET_LOCATION_STATS_REQUEST',
    GET_LOCATION_STATS_SUCCESS: 'GET_LOCATION_STATS_SUCCESS',
    GET_LOCATION_STATS_FAILURE: 'GET_LOCATION_STATS_FAILURE',
    GET_TOP_LOCATIONS_STATS_REQUEST: 'GET_TOP_LOCATIONS_STATS_REQUEST',
    GET_TOP_LOCATIONS_STATS_SUCCESS: 'GET_TOP_LOCATIONS_STATS_SUCCESS',
    GET_TOP_LOCATIONS_STATS_FAILURE: 'GET_TOP_LOCATIONS_STATS_FAILURE',

}