import React from 'react'
import WithUser from '../helpers/WithUser';
import Main from '../components/layouts/Main';
import Admin from '../components/layouts/Admin';
import Share from '../components/layouts/Share';
import Blank from '../components/layouts/Blank';

import { Route, Redirect, Switch } from 'react-router';

export default (
        
            <Switch>
                <Route path="/login" component={Blank} />
                <Route path="/share" component={Share} />
                <Route path="/admin" component={WithUser(Admin)} />
                <Route component={WithUser(Main)} />
            </Switch>
        
);