import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import { milestonesActions } from '../../../data/actions/milestones';
import { milestonesSettings } from './Milestones/Settings'
import MilestoneChart from './Milestones/Chart';

import Box from '../../common/Box';

class EntityMilestones extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        }
        
    }
        
    componentDidMount() {
        this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.ids))
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.ids))
            }                
        }
        
        if(nextProps.ids && !isEqual(nextProps.ids, this.props.ids)){
            this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, nextProps.ids))
        }
        
        if(nextProps.milestones && nextProps.milestones.tableItems){
            if(!isEqual(nextProps.milestones.tableItems, this.props.milestones.tableItems)) {
               this.props.dispatch(milestonesActions.getEntityMilestones(this.props.entity, this.props.ids))
            }                
        }
    }
    
    
    render (){

        const milestones = this.props.milestones.entities[this.props.entity];
        if(!milestones)
            return null;
        const { items, types, tableItems } = milestones;

        return <Box title="Linked Milestones">
            <MilestoneChart items={items} />
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(EntityMilestones);