import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, map } from 'lodash';
import Box from '../../common/Box';
import Spinner from '../../common/Spinner';
import ReactSelect from '../../common/ReactSelect';
import LineChart from '../../widgets/charts/types/LineChart';
import PinnedEntityFilters from '../../widgets/entityFilter/PinnedEntityFilters';
import RecentSearches from '../home/RecentSearches';
import ShortenedTitle from './ShortenedTitle'
import ClickOutsideHolder from '../../../helpers/ClickOutsideHolder';

import { statsActions } from '../../../data/actions/stats';
import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters';
import { userEntityFiltersFormatter } from '../../../data/formatters/user_entity_filters';

class EntityCard extends React.Component {
    
    
    constructor(props) {
        super(props);
        this.state = {title: ''};
    }

    
    componentDidMount() {
        this.props.getEntitiesMetadata().then(metadata=>{
            const title = this.buildTitle(metadata);
            this.setState({title});
        });
    }
    buildTitle(metadata){
        return userEntityFiltersFormatter.formatTitle(metadata, 'name');
    }
    
    render (){  
        const { multiMetadata = [], multiMetadataLoading } = this.props.stats;
        const { title } = this.state;

        return <React.Fragment>
            {multiMetadataLoading && <Spinner enabled={true} />}
            {!multiMetadataLoading && <span title={title}>{title}</span>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getEntitiesMetadata: () => {
            let { filtered = '{}' } = ownProps; 
            filtered = JSON.parse(filtered);
            return dispatch(statsActions.getMultiEntitiesMetadata(filtered));
        },
    } 
}



export default connect(mapStateToProps, mapDispatchToProps)(EntityCard);