import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {convertToThousands} from '../../helpers/NumberFormatter';

export default class SelectFieldFilter extends React.Component {
  render(){
    const { onChange, value, symbol, options, fieldName, accessor = 'percent_change', disabled} = this.props;
    const percentFilter = fieldName === accessor;
    const getLabel = (value, accessor) => ( accessor == 'period_2_units' || accessor == 'period_1_units' || accessor == 'tiktok_creations' )? convertToThousands(value) : value;
    return (
      <select
        value={value}
      disabled={disabled}
        onChange={event => {
          onChange(event.target.value, accessor);
        }}
      >
      {options.map(value => {
        return (
          <option key={value} value={value} >
            { percentFilter ? value + symbol : getLabel(value, accessor) }
          </option>
        );
      })}
      </select>
    )
  }
}
