import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';

const renderTerritories=(data)=>{
    const columns=[
       {key: 'rank', title: 'Rank', width: 15, fontSize: 5},
       {key: 'territory_name', title: 'Country', width: 30, fontSize: 5},
       {key: 'share', title: 'Share', width: 12, fontSize: 5, formatFn: addPercent},
       {key: 'curr_units', title: 'Total', width: 20, fontSize: 5, formatFn: splitByThousands},
       {key: 'skipped_ratio', title: 'Skipped Ratio', width: 15, fontSize: 5, formatFn: addPercent},
       {key: 'completed_ratio', title: 'Completed Ratio', width: 15, fontSize: 5, formatFn: addPercent}
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}

const renderTrackLegend = (data) => {
  let titles = {};
  titles = data.labels.map((item, index) => {
    return `${item} -  ${data.titles[index]}   `
  })

  return titles;
}


export default (props) => {
    const { data: { images, tables, metadata, reportTitle, entities, ids, productTracks } } = props;
    
    //const tableData = Object.values(tables);
    let tableData = [];
    for(let entity of entities) {
        tableData.push(tables[entity.code]);
    }
    const [ meta0, meta1 ] = metadata;
    
  return <Document>
    <Page size="A4" style={styles.page}>
    <View style={styles.reportTitle}>
        <Text style={styles.header}>{reportTitle}</Text>
    </View>
    <View style={styles.columnSection}>
    <View style={styles.column}>
      <Text style={styles.smallHeader}>{entities[0].name_result}</Text>
      <Text style={styles.smallHeader}>{entities[1].name_result}</Text>
    </View>
    </View>    
    <View style={styles.columnSection}>
    <View style={styles.column}>
      <Text style={styles.smallHeader}>Streams</Text>
      <Image source={images.track_comparison_streams} />
    </View>
    </View>
    <View style={styles.section}>
      <View style={styles.half}>
        <Text style={styles.smallHeader}>Listeners</Text>
        <Image source={images.track_comparison_listeners} />
      </View>
      <View style={styles.half}>
        <Text style={styles.smallHeader}>Stream/Listener Ratio</Text>
        <Image source={images.track_comparison_ratio} />
      </View>
    </View>
        <View style={styles.section}>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Active</Text>
          <Image source={images.track_comparison_active} />
        </View>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Skipped</Text>
          <Image source={images.track_comparison_skipped} />
        </View>
      </View>
      </Page>
      <Page size="A4" style={styles.page}>

      <View style={styles.section}>
      <View style={styles.half}>
        <Text style={styles.smallHeader}>Age and Gender</Text>
        <Image source={images.track_comparison_demographics_0} />
      </View>
      <View style={styles.half}>
        <Text style={styles.smallHeader}>Age and Gender</Text>
        <Image source={images.track_comparison_demographics_1} />
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.half}>
        <Text style={styles.smallHeader}>Sources</Text>
        <Image source={images.track_comparison_sources_0} />
      </View>
      <View style={styles.half}>
        <Text style={styles.smallHeader}>Sources</Text>
        <Image source={images.track_comparison_sources_1} />
      </View>
    </View>
    <View style={styles.section}>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Platforms</Text>
          <Image source={images.track_comparison_vendors_0} />
        </View>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Platforms</Text>
          <Image source={images.track_comparison_vendors_1} />
        </View>
      </View>
    
    </Page>
    <Page size="A4" style={styles.page}>
    <View style={styles.section}>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Territories</Text>
          {renderTerritories(tableData[0])}
        </View>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Territories</Text>
          {renderTerritories(tableData[1])}
        </View>
    </View>    
    <View style={styles.section}>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Artist Catalog</Text>
          <Image source={images.track_comparison_artist_0} />
        </View>
        <View style={styles.half}>
          <Text style={styles.smallHeader}>Artist Catalog</Text>
          <Image source={images.track_comparison_artist_1} />
        </View>
    </View>     
    <View style={styles.section}>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Total Streams</Text>
          <Image source={images.tracks_in_product_0_curr_units} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Skip Rate %</Text>
          <Image source={images.tracks_in_product_0_skipped_ratio} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Collection %</Text>
          <Image source={images.tracks_in_product_0_collection_ratio} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Engagement Rate %</Text>
          <Image source={images.tracks_in_product_0_engagement_ratio} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Playlist %</Text>
          <Image source={images.tracks_in_product_0_playlist_ratio} />
        </View>
    </View> 
    {productTracks[ids[0]] && <View style={styles.columnSection}>
      <Text style={styles.trackInProductLegend}>{renderTrackLegend(productTracks[ids[0]])}</Text>
    </View>}
    
    <View style={styles.section}>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Total Streams</Text>
          <Image source={images.tracks_in_product_1_curr_units} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Skip Rate %</Text>
          <Image source={images.tracks_in_product_1_skipped_ratio} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Collection %</Text>
          <Image source={images.tracks_in_product_1_collection_ratio} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Engagement Rate %</Text>
          <Image source={images.tracks_in_product_1_engagement_ratio} />
        </View>
        <View style={styles.trackInProductItem}>
          <Text style={styles.trackInProductSmallTitle}>Playlist %</Text>
          <Image source={images.tracks_in_product_1_playlist_ratio} />
        </View>
    </View>  
    {productTracks[ids[1]] && <View style={styles.columnSection}>
      <Text style={styles.trackInProductLegend}>{renderTrackLegend(productTracks[ids[1]])}</Text>
    </View>}
    
    
    </Page>
  </Document>
};