import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'

import { composeValidators, required, email } from './validators'

class AddUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let {onSubmit, values={}, owner={}, formRef, formId} = this.props;
        let { data_silo_codes } = this.props.owner
        
        return <Form onSubmit={onSubmit} ref={formRef} id="userCreateForm" initialValues={values} keepDirtyOnReinitialize={true}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                return <form className="m-t" id={formId} onSubmit={handleSubmit}>
                    <Field name="first_name"  
                        // validate={composeValidators(required)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">First Name</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>
                    
                    <Field name="last_name"  
                        // validate={composeValidators(required)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">Last Name</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>         
                    <Field name="invitee_email"  
                        validate={composeValidators(required, email)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">Email*</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>
                    <Field name="allow_api" type="checkbox"
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`${hasError ? ' has-error':''} anable-api-holder`}>
                                <div className='checkbox-holder checkbox-holder--right'>
                                    <input {...input} type="checkbox" id="allow_api" className="input" />
                                    <label className="checkbox-label" htmlFor="allow_api">Enable API access
                                    </label>
                                </div>
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>
                    {owner.show_statements && data_silo_codes == 'sec' && <Field name="show_statements" type="checkbox"
                        >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`${hasError ? ' has-error':''} anable-api-holder`}>
                                <div className='checkbox-holder checkbox-holder--right'>
                                    <input {...input} type="checkbox" className="input" id="show_statements" />
                                    <label className="checkbox-label" htmlFor="show_statements">Secretly Statements access
                                    </label>
                                </div>
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>}
                    {owner.allow_support && <Field name="allow_support" type="checkbox"
                        >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`${hasError ? ' has-error':''} anable-api-holder`}>
                                <div className='checkbox-holder checkbox-holder--right'>
                                    <input {...input} type="checkbox" id="allow_support" className="input" />
                                    <label className="checkbox-label" htmlFor="allow_support">Access Support
                                    </label>
                                </div>
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>}                    
                    {/* <div className="button-holder">
                        <button type="submit" className="default-btn default-btn--small">Save</button>
                    </div> */}
                </form>
            }}
        />
    }
}
export default AddUserForm