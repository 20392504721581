import { combineReducers } from 'redux';

import { userConstants } from '../constants/user';

import { imprints } from './imprints';
import { artists } from './artists';
import { products } from './products';
import { projects } from './projects';
import { filter } from './filter';
import { user } from './user';
import { error } from './error';
import { stats } from './stats';
import { playlists } from './playlists';
import { tracks } from './tracks';
import { notifications } from './notifications';
import { catalogueExceptions } from './catalogue_exceptions';
import { whiteLabel } from './white_label';
import { userLogs } from './user_logs';
import { heartbeats } from './heartbeats';
import { releases } from './releases';
import { channels } from './channels';
import { search } from './search';
import { search_playlist } from './search_playlist';
import { notification_reports } from './notification_reports';
import { common } from './common';
import { campaigns } from './campaigns';
import { milestones } from './milestones';
import { maps } from './maps';
import { users } from './users';
import { vendors } from './vendors';
import { entity_filters } from './entity_filters';
import { audience } from './audience';
import { grids } from './grids';
import { globalFilter} from './global_filter';
import { sharedPages } from './shared_pages';
import { userEntityFilters } from './user_entity_filters';
import { mailingLists } from './mailing_lists';
import { spotifyMonetization } from './spotify_monetization';

const appReducer = combineReducers({
    imprints,
    artists,
    products,
    projects,
    stats,
    filter,
    user,
    error,
    playlists,
    tracks,
    notifications,
    catalogueExceptions,
    whiteLabel,
    userLogs,
    heartbeats,
    releases,
    channels,
    search,
    search_playlist,    
    notification_reports,
    common,
    campaigns,
    milestones,
    maps,
    users,
    vendors,
    entity_filters,
    audience,
    grids,
    globalFilter,
    sharedPages,
    userEntityFilters,
    spotifyMonetization,
    mailingLists
});

const rootReducer = (state, action) => {
    if (action.type === userConstants.LOGOUT_SUCCESS) {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;
