import React from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ShortenedTitle from '../../pages/result/ShortenedTitle';
import ConfirmClick from '../../common/ConfirmClick';
import { percentageValue } from '../../../helpers/NumberFormatter';
import { userEntityFiltersFormatter } from '../../../data/formatters/user_entity_filters';

var deleteSvg = require('!svg-inline-loader!../../../../public/img/icons/delete_new.svg');
var editSvg = require('!svg-inline-loader!../../../../public/img/icons/edit.svg');
var profileSvg = require('!svg-inline-loader!../../../../public/img/open_playlist.svg');

export const searchSettings = {
    settingsForTable
};


const filterURL = (entities) => {
    let filter = {};
    for(let entity of entities){
        const { fav_hide, entity_type, entity_id } = entity;
        if(fav_hide == 'favorite') {
            if(!filter.hasOwnProperty(entity_type))
                filter[entity_type] = [];
            filter[entity_type].push(entity_id);
        }
    }
    return `/filter/${JSON.stringify(filter)}/overview`;
}   



function settingsForTable(deleteFilter, pinFilter, shareFilter){
    let columns = [
       {
           id: 'title',
           className: '',
           headerClassName: '',
           Header: 'Search Query',
           accessor: 'title',
           width: 220
       },

       {
           id: 'created_at',
           className: '',
           headerClassName: '',
           Header: 'Created',
           accessor: 'created_at',
           width: 120,
           Cell: props => {
               return <p>{moment(props.value).format('YYYY-MM-DD')}</p>
           }
       },
       {
           id: 'updated_at',
           className: '',
           headerClassName: '',
           Header: 'Updated',
           accessor: 'updated_',
           width: 120,
           Cell: props => {
               return <p>{moment(props.value).format('YYYY-MM-DD')}</p>
           }
       },
       
       {
           id: 'status',
           className: '',
           headerClassName: '',
           Header: 'Status',
           accessor: 'status',
           width: 100,
       },
       {
           id: 'total',
           className: '',
           headerClassName: '',
           Header: 'Total',
           accessor: 'total'
       },
       {
           id: 'mapped',
           className: '',
           headerClassName: '',
           Header: 'Mapped',
           accessor: 'mapped',
       },
       {
           id: 'skipped',
           className: '',
           headerClassName: '',
           Header: 'Skipped',
           accessor: 'skipped',
       },
       {
           id: 'actions',
           className: 'milestone-actions',
           headerClassName: 'no-sort milestone-actions',
           Header: 'Actions',
           width: 150,
           sortable: false,
           // <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
           Cell: props => {
               const { id, owner, entities } = props.original;
               return <div className="buttons-holder buttons-holder--milestones">
               
                   <Link to={`/search-results/${id}`} className="btn add-to-filter" title="Details"><span className="small-icon" dangerouslySetInnerHTML={{__html: profileSvg}}></span></Link>
               {/*                   
                   {owner && <React.Fragment>
                   <Link to={`/user/entities-filter/${id}`}className="btn add-to-filter" title="Edit"><span className="small-icon" dangerouslySetInnerHTML={{__html: editSvg}}></span></Link>
                   <ConfirmClick confirmAction={()=>deleteFilter([id])} confirmClass="btn add-to-filter" confirmLabel={<span className="small-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>} confirmPrompt="Delete Filter?" title="Delete"></ConfirmClick>
                   </React.Fragment>}
               */}
               </div>
           }
       },
       
       
    ];
    return columns;
}
