import React from 'react';
import { find, sumBy, filter } from 'lodash';
import PercentageValue from '../PercentageValue';

class VendorValue extends React.Component {
    
    render() {
        const { vendor, trends, source } = this.props; 
        let trend = {curr_units: 0, prev_units: 0};
        
        if(vendor == 'total') {
            let sourceTrends = filter(trends, {content_type: source});
            
            for(let vendorTrend of sourceTrends) {
                trend.curr_units += vendorTrend.curr_units;
                trend.prev_units += vendorTrend.prev_units;
            }
        }
        else {
            trend = find(trends, {content_type: source, vendor});
        }
        
        return trend ? <PercentageValue field="units" item={trend}></PercentageValue> : '-';
    }

}

export default VendorValue