import React from 'react';
import ReactTooltip from 'react-tooltip';
import { map, find } from 'lodash';
import { Link as LiveLink } from 'react-router-dom';
import ReadonlyLink from '../home/ReadonlyLink';
import PercentageValue from '../../common/PercentageValue';
import EntityStatus from '../../common/Datagrid/EntityStatus';
import AbsoluteValue from '../../common/AbsoluteValue';
import TrendsBox from '../../common/Datagrid/TrendsBox';
import GenderSplitBox from '../../common/Datagrid/GenderSplitBox';
import TagsList from './TagsList';
import VendorLogo from '../../common/VendorLogo';
import { convertToThousands, splitByThousands, percentageValue } from '../../../helpers/NumberFormatter';
import Tooltip from '../../common/Tooltip';
import { avgFooter, sumFooter, convertSumFooter, spaceFooter, textFooter } from '../../common/Datagrid/Footers';

var openPlaylistSvg = require('!svg-inline-loader!../../../../public/img/open_playlist.svg');
var overviewSvg = require('!svg-inline-loader!../../../../public/img/overview.svg');
var detailsSvg = require('!svg-inline-loader!../../../../public/img/details.svg');
const plusSvg = require('!svg-inline-loader!../../../../public/img/plus.svg');
const minusSvg = require('!svg-inline-loader!../../../../public/img/minus.svg');


export const playlistsSettings = {
    settingsForPercentageChart,
    settingsForTable
};

function settingsForPercentageChart(){
    return {};
}

function settingsForTable(enabledColumns, onTitleClick, externalLinks){
    const Link = externalLinks ? LiveLink : ReadonlyLink;
    let columns = [
        /*    
                   {
                       id: 'favhide',
                       className: 'logo-sell frozen favhide',
                       headerClassName: 'logo-sell frozen favhide no-sort',
                       headerAlt: 'Favorite/Hidden',
                       width: 80,
                       sortable: false,
                       Header: "",
                       Cell: props => <div className="action-icons">
                           <EntityStatus entity="playlists" id={props.original.id} />
                       </div>,
                       Footer: spaceFooter
                   },
         */          
        { 
            className: 'arrow',
            headerClassName: 'arrow',
            accessor: 'tracks', 
            expander: true, 
            Header: '',
            width: 57,
            sortable: false,
            Expander: props => {
                const { isExpanded } = props;
                return (isExpanded) ? <button type="button" className="plus-button" title="Track Breakdown" dangerouslySetInnerHTML={{__html: minusSvg}} /> : <button type="button" title="Track Breakdown" className="plus-button" dangerouslySetInnerHTML={{__html: plusSvg}} />
                
            },
            Footer: spaceFooter
        },                   
        {
            id: 'logo',
            className: 'logo-td',
            headerClassName: 'logo-td ',
            Header: 'Logo',
            accessor: 'logo',
            width: 60,
            Cell: props => {
                return <Link to={`/playlists/${props.original.id}/details`}><div className="logo-container" ><div className="logo" style={{backgroundImage: `url(${props.original.logo})`}} /></div></Link>;
            },
            sortable: false,
            Footer: spaceFooter
        },
       {
           id: 'name',
           className: 'playlist-name-td',
           headerClassName: 'playlist-name-td ',
           headerAlt: 'Playlist',
           Header: props => <div className="top-table-header-inner">
               Playlist 
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`Following the industry wide change in their reporting, Apple is no longer providing Playlist IDs under a certain number of conditions. Where possible we will display the corresponding playlist, but otherwise streams will be aggregated under an 'Editorial playlist' entry.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'name',
           width: 280,
           Cell: props => {
               //const onClickCall = (e)=>{return onTitleClick(props.original);};
               return <div className="logo-container">
                 
                  <div className="playlist-description">
                		<p className="playlist-name"><VendorLogo name={props.original.vendor} /><span className={`activity activity-${props.original.pl_active?'true':'false'}`}/><Link to={`/playlists/${props.original.id}/overview`}>{props.value}</Link></p>
                		{/*<div className="tags-holder"><TagsList tags={props.original.pl_tags} /></div>*/}
                		<div className="playlist-owner">{props.original.owner}</div>
                  </div>
                		
            		</div>;
           },
           sortable: false,
           Footer: textFooter
       },
       {
           id: 'curr_tracks',
           headerClassName: 'rt-resizable-header',
           Header: 'Tracks',
           accessor: 'curr_tracks',
           Cell: props => <span>{splitByThousands(props.value)}</span>,
        //    width: 110,
           show: true,
           Footer: convertSumFooter
       },
       {
           id: 'benchmark',
           className: 'small-td',
           headerClassName: ' small-td no-sort',
           headerAlt: "Playlist Benchmark",
           Header: props => <div className="top-table-header-inner">
               Playlist Benchmark 
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`Performance Benchmark: EI industry average calculated across all clients, for total daily streams generated by the number of your tracks on the playlist by the number of days the track is on the playlist.`} tooltipClass="toolbar-title-tooltip"/>
               </div>
           </div>,
           Cell: props => props.value == 'N/A' ? props.value : <AbsoluteValue value={props.value}></AbsoluteValue>,
        //    width: 110,
           accessor: 'benchmark',
           sortable: false,
           Footer: convertSumFooter
       },
	   {
			  id: 'avg_track_streams_per_day',
			  className: 'small-td',
			  headerClassName: ' small-td',
			  headerAlt: 'Track Benchmark',
			  Header: props => <div className="top-table-header-inner">
				  Track Benchmark 
				  <div className="table-header-tooltip">
					   <Tooltip position="bottom" message={`Track Performance benchmark: value calculated across all of Ei’s clients as an average of daily streams generated by all known tracks to have been included in this playlist.`} tooltipClass="toolbar-title-tooltip"/>
				   </div>
			   </div>,
			  accessor: 'avg_track_streams_per_day',
			  // width: 130,
			  Cell: props => <span>{ props.value ? convertToThousands(props.value) : 'N/A' }</span>
	    },	          
       {
           id: 'curr_units',
           className: 'small-td',
           headerClassName: ' small-td rt-resizable-header',
           Header: 'Units',
           Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
        //    width: 110,
           accessor: 'curr_units',
           Footer: convertSumFooter
       },       
       {
           id: 'curr_skipped',
           className: 'small-td',
           headerClassName: ' small-td rt-resizable-header',
           Header: <span>Skips <i className="color-red fa fa-step-forward" /></span>,
           Cell: props => <AbsoluteValue className="color-red" value={props.value}></AbsoluteValue>,
        //    width: 110,
           accessor: 'curr_skipped',
           Footer: convertSumFooter
       },
       {
           id: 'curr_completed',
           headerClassName: 'rt-resizable-header',
           Header: <span>Completed <i className="color-light-green fa fa-play" /></span>,
           Cell: props => <AbsoluteValue className="color-light-green" value={props.value}></AbsoluteValue>,
        //    width: 110,
           accessor: 'curr_completed',
           Footer: convertSumFooter
       },
       {
           id: 'skipped_ratio',
           accessor: 'skipped_ratio',
           className: 'lowercase grid-td',
           headerAlt: 'Skip Rate',
           headerClassName: 'rt-resizable-header small-td',
           Header: props => <div className="top-table-header-inner">
               Skip Rate
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           Cell: props => <span>{props.value}%</span>,
           Footer: avgFooter,
        //    width: 110
       },
       {
           id: 'completed_ratio',
           accessor: 'completed_ratio',
           className: 'lowercase grid-td',
           headerAlt: 'Completion Rate',
           headerClassName: 'rt-resizable-header small-td',
           Header: props => <div className="top-table-header-inner">
               Completion Rate
               <div className="table-header-tooltip">
                    <Tooltip position="right-bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           Cell: props => <span>{props.value}%</span>,
        //    width: 130,
           Footer: avgFooter
       },       
       {
           id: 'source',
           className: 'small-td',
           headerClassName: 'rt-resizable-header small-td',
           headerAlt: 'Source',
           Header: props => <div className="top-table-header-inner">
               Source 
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`Playlist / Radio. To filter the table please select your option in the drop down menu above.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'source',
           // width: 130,
           Cell: props => <span>{ props.value ? props.value.substr(0, 1) : 'P' }</span>,
           Footer: spaceFooter,
        //    width: 110,
           Cell: props => <span>{ props.value ? props.value.substr(0, 1) : 'P' }</span>
       },
       {
           id: 'followers',
           headerClassName: 'rt-resizable-header',
           Header: 'Followers',
           accessor: 'followers',
        //    width: 110,
           Cell: props => <span>{props.value? convertToThousands(props.value) : 'N/A'}</span>,
           Footer: convertSumFooter
       },       
       {
           id: 'playlist_type',
           className: 'small-td',
           headerClassName: 'rt-resizable-header small-td',
           Header: 'Playlist Type',
           accessor: 'playlist_type',
        //    width: 110,
           // Cell: props => <span>{ props.value ? props.value.substr(0, 1) : 'P' }</span>
           Cell: props => <span>{props.value}</span>,
           Footer: spaceFooter
       },       
       {
           id: 'playlist_share',
           headerClassName: 'rt-resizable-header',
           headerAlt: "Playlist Share",
           Header: props => <div className="top-table-header-inner">
                Playlist Share
                <div className="table-header-tooltip">
                    <Tooltip position="right-bottom" message={`The percentage of your tracks that make up this playlist. Only available from Spotify, Apple and Deezer. Over 100% share indicates tracks that were removed or added over the course of your date range..`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           sortable: false,
           accessor: 'playlist_share',
           Cell: props => <span>{props.value?`${props.value}%`:'N/A'}</span>,
        //    width: 120,
           show: true,
           Footer: avgFooter
       },       

       /*
       {
           id: 'curr_listeners',
           headerClassName: '',
           Header: 'Listeners',
           accessor: 'curr_listeners',
           // width: 130,
           Cell: props => <span>{convertToThousands(props.value)}</span>
       },
       */       
    //    {
    //        id: 'added_on',
    //        headerClassName: '',
    //        Header: 'Added On',
    //        Cell: props => '-tbd-',
    //        // width: 130,
    //        show: false
    //    },
    //    {
    //        id: 'last_active',
    //        headerClassName: '',
    //        Header: 'Last Active',
    //        Cell: props => '-tbd-',
    //        // width: 130,
    //        show: false
    //    },
       

       /*
       {
           id: 'completed_diff',
           headerClassName: ' no-sort',
           Header: props => <div className="top-table-header-inner">
               Ratio S/C 
               <Tooltip 
                position="bottom" 
                message={`S = Skips / C = Completion\n
                These metrics only apply to streams over 30 seconds. A track is marked as complete when the stream reaches 95% of the track length.\n
                They are provided by all DSPs except Google and YouTube`} 
                tooltipClass="toolbar-title-tooltip" />
            </div>,
           accessor: 'completed_diff',
           headerAlt: 'Ratio S/C',
           Cell: props => <div>
           <span title="Skipped">{props.original.skipped_ratio}%</span>
            /
            <span title="Completed">{props.original.completed_ratio}%</span>
           </div>,
            
           sortable: false,
           // width: 130
       },
       */

       
       /*
       {
           id: 'completed_diff',
           headerClassName: ' no-sort',
           Header: 'Completion Ratio',
           accessor: 'completed_diff',
           Cell: props => <span>{Math.round(props.original['curr_completed']/props.original['curr_units']*100)}%</span>,
           sortable: false,
           // width: 130
       },
       */

       {
           id: 'gender',
           className: 'gender-td',
           headerClassName: 'rt-resizable-header',
           Header: 'Gender Ratio M/F/U',
           Cell: props => <GenderSplitBox item={props.original} />,
           sortable: false,
           Footer: spaceFooter
           // width: 130

       },

	   /*
       {
          id: 'overlay',
          className: 'overlay-td',
          headerAlt: 'Overlay',
          headerClassName: 'overlay-header',
          Cell: props => {
            return <div className="overlay-links-holder">
							{props.original.external_url && <a href={props.original.external_url} target="_blank">
                Open Playlist
                <span className="chart-icon" dangerouslySetInnerHTML={{__html: openPlaylistSvg}}></span></a>}
              <Link to={`/playlists/${props.original.id}/overview`}>
                Overview
                <span className="chart-icon" dangerouslySetInnerHTML={{__html: overviewSvg}}></span></Link>
              <Link to={`/playlists/${props.original.id}/details`}>
                Details
                <span className="chart-icon" dangerouslySetInnerHTML={{__html: detailsSvg}}></span></Link>
              </div>
          },
          Footer: null
       }
	   */
       
    ];
    columns = map(columns, (column)=>{
        if(enabledColumns !== undefined)
            column.show = enabledColumns.includes(column.id);
        return column;
    });
    /*
    if(externalLinks === false) {
        let overlayColumn = find(columns, column=>column.id == 'overlay');
        overlayColumn.show = false;
    }
    */
    return columns;
}