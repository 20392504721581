import React from 'react';
import { find, sumBy, filter } from 'lodash';
import { convertToThousands} from '../../../helpers/NumberFormatter';

class VendorFooter extends React.Component {
    
    render() {
        const { vendor, data, source } = this.props;
        let trend = {curr_units: 0, prev_units: 0};
        for(let item of data.data) {
            let { trends } = item._original;
        
            if(vendor == 'total') {
                let sourceTrends = filter(trends, {content_type: source});
                
                for(let vendorTrend of sourceTrends) {
                    trend.curr_units += vendorTrend.curr_units;
                    trend.prev_units += vendorTrend.prev_units;
                }
            }
            else {
                let vendorTrend = find(trends, {content_type: source, vendor});
                if(vendorTrend) {
                    trend.curr_units += vendorTrend.curr_units;
                    trend.prev_units += vendorTrend.prev_units;
                }
            }
        }
        
        return <span>{convertToThousands(trend.curr_units)}</span>
    }

}

export default VendorFooter