import React from 'react';
import { map } from 'lodash';
import PercentageValue from '../../../common/PercentageValue';
import AbsoluteValue from '../../../common/AbsoluteValue';
import { percentageValue, splitByThousands, round } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../../common/Tooltip';
import { avgFooter, convertSumFooter, spaceFooter } from '../../../common/Datagrid/Footers';

export const vendorSettings = {
    settingsForTable
};


function settingsForTable(mode){
    let columns = [
       {
           id: 'content_type',
           headerClassName: '',
           Header: 'Type',
           accessor: 'content_type',
       },
                                
       {
           id: 'vendor',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'Platform',
           accessor: 'vendor',
           Footer: 'Total: '
       },
       {
           id: 'total',
           headerClassName: 'total',
           className: 'total',
           Header: 'Units',
           //Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
           Cell: props => <span>{splitByThousands(props.value)}</span>,
           accessor: 'curr_units',
           Footer: convertSumFooter
       },
       {
           id: 'prev_units',
           headerClassName: 'total',
           className: 'total',
           Header: 'Units Change',
           //Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           Cell: (props) => {
               const { units_diff } = props.original;
               const direction = units_diff > 0 ? "up" : "down";
               const offset = units_diff > 0 ? 'offset' : '';
               return <span className={`vendor-value vendor-value-inline ${offset}`}>{splitByThousands(props.value)} <span className={direction}>{round(props.original.units_diff)}%</span></span>
           },
           accessor: 'units_change',
           Footer: spaceFooter
       },
       {
           id: 'share',
           headerClassName: 'total',
           className: 'total',
           Header: 'Platform Share',
           Cell: props => <span>{props.value}%</span>,
           accessor: 'share',
           Footer: avgFooter
       },
       {
           id: 'pre_add',
           headerClassName: 'total',
           className: 'total',
           Header: 'Pre Add',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           accessor: 'pre_add',
           Footer: convertSumFooter
       },       
       {
           id: 'curr_playlist_units',
           headerClassName: 'total',
           className: 'total',
           Header: 'Playlist Units',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           accessor: 'curr_playlist_units',
           Footer: convertSumFooter
       },
       {
           id: 'playlist_share',
           headerClassName: 'total',
           className: 'total',
           Header: props => <div className="top-table-header-inner">
                Playlist Share
                <div className="table-header-tooltip">
                    <Tooltip position="right-bottom" message={`Only available from Spotify, Apple and Deezer. Over 100% share indicates tracks that were removed or added over the course of your date range..`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           Cell: props => <span>{props.value}%</span>,
           accessor: 'playlist_share',
           Footer: avgFooter,
           width: 120
       },       
    ];
    return columns;
}