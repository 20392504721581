import React, { Component } from 'react';
import { find, sumBy, map } from 'lodash';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import { LineChart } from '../../widgets/charts/types';
import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';
import { playlistsActions} from '../../../data/actions/playlists';
import { convertToThousands } from '../../../helpers/NumberFormatter';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class PositionInPlaylist extends Component {

	render(){
		const { positions } = this.props;
		
		if(!Array.isArray(positions) || positions.length < 2)
			return <p>There were no position changes for this track</p>
			
		let dates = [],
			changes = [];
		for(let position of positions) {
			dates.push(position.changed_at);
			changes.push(position.changed_from);
			
		}		
		return <Box title="Positions in Playlist">
			<div className="chart-block">
				<LineChart 
					data={{labels: dates, datasets:[{data: changes, label: 'Position'}]}}
					options={{scales: {y: {reverse: true,  ticks: {stepSize: 1}}}}}
				/>
			</div>
		</Box>;
	}
						
}

export default PositionInPlaylist;