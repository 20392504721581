import React from 'react';
import ReactDOM from 'react-dom';
import { convertToThousands } from '../../../helpers/NumberFormatter'

class CatalogOverview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	
	renderCard(entity){
		return <div className="tiles-main-info-holder">
				<h3 className="title-totals">{entity.title}</h3>
				<div className={`title-totals-holder ${entity.valueClass}`}>
					<h2 className={`title-num num`}>{convertToThousands(entity.value)}</h2>
					{entity.percentageValue !== undefined ? <div className={`overview-percentage-value`}>{entity.percentageValue}%</div> : null }
				</div>
			</div>
	}

	render (){
		const { stats } = this.props;
		return <div className="overview-tiles-holder">
			<div className="overview-tiles-holder-inner">
				{stats && stats.map(entity=>{
					return <div key={entity.entity} className="overview-tiles-item">
						{this.renderCard(entity)}
					</div>
				})} 
			</div>
		</div>
	}
}

export default CatalogOverview;