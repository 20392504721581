import React from 'react';
import moment from 'moment';
import { find, isEqual } from 'lodash';
import Datepicker from '../../common/Datepicker';
import ReactSelect from '../../common/ReactSelect';
import { ordinalSuffix } from '../../../helpers/NumberFormatter';

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                period: 'daily',
                date: null,
                dayOfWeek: null,
                dayOfMonth: null,
                weekOfMonth: null
        }
        
        this.setDate = this.setDate.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
    }
    
    makeDateFromMailingList(mailingList) {
        const { schedule_type: type, schedule_value: value } = mailingList;
        let date = moment();
        switch(type) {
            case 'weekly':
            case 'bi_weekly':
                date.day(value);
            break;
            case 'monthly_day_of_week':
                let [dayOfWeek, weekOfMonth] = value.split('_');

                const myMonth = moment().startOf('month');
                date = myMonth.clone().day(dayOfWeek);
                if( date.month() != myMonth.month() ){
                    weekOfMonth++;
                }
                date.add(weekOfMonth-1, 'weeks');
                                  
            
            case 'monthly_day':
                date.date(value);
            break;
        }
        return date.format('YYYY-MM-DD');
    }
    
    initialize(mailingList) {
        const date = ( mailingList && mailingList.start_date) ? mailingList.start_date : moment().format('YYYY-MM-DD');
        this.setDate(date);
        if(mailingList)
            this.setPeriod(mailingList.schedule_type);
    }
    
    componentDidMount() {
        const { mailingList } = this.props;
        this.initialize(this.props.mailingList);
    }
    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.mailingList, this.props.mailingList) && this.props.initializeDate) {
            conaole.log('calendar initialized');
            this.initialize(this.props.mailingList);    
        }
    }
    
    setDate(date) {
        const dateObject = moment(date);
        this.setState({
            date: dateObject.format('YYYY-MM-DD'),
            dayOfWeek: dateObject.format('dddd'),
            dayOfMonth: dateObject.format('D'),
            weekOfMonth: Math.ceil(dateObject.date() / 7)
        }, ()=>{
            this.props.onChange(this.state)
        });
    }
    
    setPeriod(period) {
        if(period) {
            this.setState({period}, ()=>{this.props.onChange(this.state)});
        }
    }
    
    
    
    renderOptions(readonly) {
        let { period } = this.state;
        let options = [ 
            {label: 'Daily', value: 'daily'},
            {label: `Weekly on ${this.state.dayOfWeek}`, value: 'weekly'},
            {label: `Every 2nd week on ${this.state.dayOfWeek}`, value: 'bi_weekly'},
            {label: `Monthly on every ${ordinalSuffix(this.state.weekOfMonth)} ${this.state.dayOfWeek}`, value: 'monthly_day_of_week'},
            {label: `Monthly on ${ordinalSuffix(this.state.dayOfMonth)}`, value: 'monthly_day'}
        ],
        value = find(options, option=>option.value == period);
        
        return <ReactSelect isDisabled={readonly} className="period repeat-on-period" id="mailing_list_calendar" options={options} value={value} onChange={(option)=>this.setPeriod(option.value)} />
    }
    
    render() {
        const { readonly = false} = this.props;
        return <div className="mailing-list-calendar-holder inline-datepicker">
            <Datepicker datepickerPosition="right" className="calendar" date={this.state.date} dateChange={(date)=>this.setDate(date)} readonly={readonly} />
            {this.renderOptions(readonly)}
        </div>
    }
}

export default Calendar;