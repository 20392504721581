import { errorActions } from './error';
import { gridsConstants } from '../constants/grids';
import { gridsService } from '../services/grids';
import { gridsFormatter } from '../formatters/grids';
import { find } from 'lodash';

export const gridsActions = {
    getGrids,
    addGrid,
    editGrid,
    deleteGrid,
    saveDefaultGrid
};

function getGrids(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return gridsService.getGrids(params, globalFilter)
            .then(
                grids => {
                    const data = gridsFormatter.formatGrids(grids);
                    dispatch(success(data, grids));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: gridsConstants.GET_GRIDS_REQUEST } }
    function success(grids, pages) { return { type: gridsConstants.GET_GRIDS_SUCCESS, grids, pages } }
    function failure(error) { return { type: gridsConstants.GET_GRIDS_FAILURE, error } }
}

function addGrid(params) {
    return ( dispatch, getState ) => {
        dispatch(request());        
        return gridsService.addGrid(params)
            .then(
                grid => {
                    dispatch(success());
                    dispatch(gridsActions.getGrids())
                    dispatch(errorActions.setSuccessMessage('grid saved'))
                    return grid.data;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving grid'))
                }
            );
 
    };
    function request() { return { type: gridsConstants.ADD_GRID_REQUEST } }
    function success() { return { type: gridsConstants.ADD_GRID_SUCCESS } }
    function failure() { return { type: gridsConstants.ADD_GRID_FAILURE } }
}

function editGrid(params, id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        params.user_id = currentUser.id;
        
        gridsService.editGrid(params, id)
            .then(
                grid => {
                    dispatch(success());
                    dispatch(gridsActions.getGrids())
                    dispatch(errorActions.setSuccessMessage('grid updated'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving grid'))
                }
            );
 
    };
    function request() { return { type: gridsConstants.ADD_GRID_REQUEST } }
    function success() { return { type: gridsConstants.ADD_GRID_SUCCESS } }
    function failure() { return { type: gridsConstants.ADD_GRID_FAILURE } }

}

function deleteGrid(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return gridsService.deleteGrid(id)
            .then(
                grid => {
                    dispatch(success());
                    return dispatch(gridsActions.getGrids())
                    dispatch(errorActions.setSuccessMessage('grid removed'))
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving grid'))
                }
            );
 
    };
    function request() { return { type: gridsConstants.ADD_GRID_REQUEST } }
    function success() { return { type: gridsConstants.ADD_GRID_SUCCESS } }
    function failure() { return { type: gridsConstants.ADD_GRID_FAILURE } }

}

function saveDefaultGrid(params) {
    return ( dispatch, getState ) => {
        dispatch(request());        
        return gridsService.saveDefaultGrid(params)
            .then(
                grid => {
                    dispatch(success());
                    return grid.data;
                },
                error => {
                    dispatch(failure())
                }
            );
 
    };
    function request() { return { type: gridsConstants.SAVE_DEFAULT_GRID_REQUEST } }
    function success() { return { type: gridsConstants.SAVE_DEFAULT_GRID_SUCCESS } }
    function failure() { return { type: gridsConstants.SAVE_DEFAULT_GRID_FAILURE } }
}