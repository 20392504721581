import React, { Component } from 'react'
import Select, { components } from 'react-select'

// const { Option } = components
// const CustomSelectOption = props => (
//     <Option {...props}>
//         {this.props.children}
//     </Option>
// )

// const CustomSelectValue = props => (
//     <div>
//         {this.props.children}
//     </div>
// )

class ReactSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
        this.setExpanded=this.setExpanded.bind(this);
    }
    
    setExpanded(expanded) {
        this.setState({expanded});
    }
    
    render() {
        const customStyles = {
            dropdownIndicator: (provided, state) => ({
                ...provided,
                //transition: 'all .2s ease',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            }),
            option: (provided, state) => ({
                ...provided,
                ':hover': { 
                    fontWeight: 'bold',
                    cursor: 'pointer'
                }
            })            
        }
        return <Select styles={customStyles} onMenuOpen={()=>this.setExpanded(true)} onMenuClose={()=>this.setExpanded(false)} isSearchable={ this.props.isSearchable || false } {...this.props} />
    }
}

export default ReactSelect