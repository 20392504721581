import React from 'react';
import { uniq, map } from 'lodash';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import styles from '../../common/PDF/LayoutStylesheet';

const renderVendor = (vendor, data) => {
    let dates = uniq(map(data.filter(entry=>entry.vendorTitle == vendor), 'report_date')).sort();
    return `${vendor} (${dates.join(', ')})`;
}


const getMissingDataString = (data = []) => {
    if(!data.length)
        return null;
    
    const vendors = uniq(map(data, 'vendorTitle')).sort();
    let vendorsString = '';
    if(vendors.length == 1)
        vendorsString = renderVendor(vendors[0], data);
    else
        vendorsString = map(vendors, vendor => renderVendor(vendor, data)).reduce((prev, curr, index, data) => { const separator = (index == data.length - 1) ? ' and ' : ', '; return [prev, separator, curr].join('')});
    return `Missing or partial data for this selection from ${vendorsString}`;

}

export default ({data}) => {
    const missingData = getMissingDataString(data);
    if(!missingData)
        return null;
  return <Text style={styles.dateMissingText}>{missingData}</Text>
};