import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { pdf as PDFRenderer }from '@react-pdf/renderer';
import Box from '../../components/common/Box';
import { artistsActions } from '../../data/actions/artists';
import ArtistOverview from '../../components/pages/artists/ArtistOverview';
//import EntitiesListSelector from '../../components/pages/artists/EntitiesListSelector';
import EntitiesList from '../../components/pages/home/EntitiesList';
import TerritoryStats from '../../components/common/Stats/Territory';
import DemographicsStats from '../../components/common/Stats/Demographics';
import DevicesStats from '../../components/common/Stats/Devices';
import SourcesStats from '../../components/common/Stats/Sources';
import FollowersStats from '../../components/pages/artists/Followers';
import VendorsWidget from '../../components/pages/home/VendorsWidget';
import SharePage from '../../components/pages/sharedPages/SharePage';
import CSV from '../../helpers/CSVExport';
import SharedEntityCard from '../../components/common/SharedEntityCard';
import { dspLogos } from "../../components/common/Stats/utils";
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import PDFDocument from '../../components/pages/artists/PDF';
import Milestones from '../../components/pages/audience/Milestones';
import {Widget, WithWidgetManager} from '../../components/widgets/manager';
import RedirectToResults from '../../components/pages/result/RedirectToResults';

const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class ArtistProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            currentTabIndex: {
                top: 0,
                sources: 3,
                territories: 5
            }
        };
        this.setTabIndex = this.setTabIndex.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
    }

    componentDidMount() {
        const { match, dispatch } = this.props;
        this.setState({id: match.params.id});
        dispatch(artistsActions.getArtistProfile(match.params.id));
    }

    componentWillReceiveProps(nextProps){
        const { dispatch } = this.props;
        if(nextProps.filter){
            if(nextProps.filter.global){
                if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                    dispatch(artistsActions.getArtistProfile(this.state.id));
                }
            }
        }
        if(nextProps.match){
            const {match} = nextProps;
            if(match.params.id!==this.state.id)
                this.setState({id: match.params.id}, ()=>{
                    dispatch(artistsActions.getArtistProfile(this.state.id));
                });
        }
    }

    exportToCsv = () => {
      const {
        playlists,
        products,
        tracks,
        stats
      } = this.props;

      const data = [
        { top_platforms: stats.vendor.data },
        { top_products: products.top },
        { top_tracks: tracks.top },
        { top_playlists: playlists.top},
        { sources: stats.sources.table },
        { demographics: stats.demographics.table },
        { territories: stats.territory.table },
        { devices: stats.devices.table }
      ];
      
      const filename = CSV.CSVHeader('artist_profile', this.state.id, this.props.filter.global);

      CSV.CSVBulkExport(data, filename);
    }

    getPdfData() {
        const { id } = this.props.match.params,
            artist = this.props.artists.profile[id] || {},        
            reportTitle = 'Artist Profile',
            { artists, products, tracks, playlists, stats } = this.props;
            
        const imageIDs = [
              'artist_details_vendors',
              'artist_details_demographics',
              'artist_details_sources',
              'artist_details_devices',
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        const tables = {
            products: products.top,
            tracks: tracks.top,
            playlists: playlists.top,
            territories: stats.territory.table
        };
        const overview = artists.profile[id].totals;
        return { reportTitle, images, tables, overview, globalFilter: this.props.filter.global, entity: artist.name };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "artist_details.pdf";
            link.click();
        });
    }

    
    setTabIndex(currentTabIndex, group) {
        this.setState({
            ...this.state, 
            currentTabIndex: {
                ...this.state.currentTabIndex,
                [group]: currentTabIndex
            }
        });
    }

    renderTabsHeader(tabsHeadingConfig, group) {
        const { currentTabIndex } = this.state; 
        
        let tabs = [];
        for(let tab of tabsHeadingConfig) {
            //const tab = tabsHeadingConfig[tabIndex]
            const tabIndex = tab.id;
            tabs.push(<span className={`tab${tabIndex == currentTabIndex[group] ? ' active' : ''}`} onClick={()=>this.setTabIndex(tabIndex, group)}>{tab.title}</span>);
        }
        return tabs;
    }

    render() {
        if(!this.state.id)
            return null;
        
        const { sharedMode } = this.props;
        const { currentTabIndex } = this.state

        const topTabs = [
            {
                id: 0,
                title: "Products"
            },
            {
                id: 1,
                title: "Tracks"
            },
            {
                id: 2,
                title: "Playlists"
            }
        ];

        const sourcesTabs = [
            {
                id: 3,
                title: "Sources"
            },
            {
                id: 4,
                title: "Age and Gender"
            }
        ]; 

        const territoryTabs = [
            {
                id: 5,
                title: "Territories"
            },
            {
                id: 6,
                title: "Devices"
            }
        ]

        const artist = this.props.artists.profile[this.state.id] || {};
            const { widgetProps } = this.props;
            return <RedirectToResults entity="artists" id={this.state.id} />
            return <div className="artist-profile homepage">
                <ArtistOverview artist={artist} loading={this.props.artists.profileLoading} externalLinks={!sharedMode} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf} />
                {false && <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <h2>Artist Profile</h2>
                        <SharedEntityCard entity="artists" id={this.state.id} />
                    </div>
                </div>}
                <div className="page-action-holder">
                    {sharedMode && <div className="download-data-holder">
                        <span>Export</span>
                        <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                        <span>PDF</span>
                        <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                    
                    </div>}
                    {/* {!sharedMode && <SharePage entity="artists" id={this.state.id} />} */}
                </div>
                {!this.props.artists.profileLoading && <div className="">
                    {/*
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            {artist.artists_channels && <FollowersStats artist={artist} />}
                        </div>
                    </div>

                    */}
                    <div className="row">
                        <div className="col-xs-12 pd-0 default">
                            <Widget widgetID="vendors" widgetProps={widgetProps}>
                                <VendorsWidget entity="track_artists" id={this.state.id} parentEntityTitle={artist.name}  shadowChartProps={{id: 'artist_details_vendors', shadowChartWidth: 800, shadowChartHeight: 400}} />
                            </Widget>
                        </div>
                    </div>

                    <MatchMediaWrapper breakpoint={640} isMobile={true}>
                        <div className="tabs-content">
                            <div className="tab-container">
                                {this.renderTabsHeader(topTabs, 'top')}
                            </div>
                            <div className="tab-content mobile-content-tables">
                                {currentTabIndex.top == 0 && <Widget widgetID="top_products" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Products" entity="products" parentEntity="product_artists" parentEntityIDs={this.state.id} parentEntityTitle={artist.name} externalLinks={!sharedMode} id={0} /></Widget>}
                                {currentTabIndex.top == 1 && <Widget widgetID="top_tracks" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Tracks" entity="tracks" parentEntity="track_artists" parentEntityIDs={this.state.id}  parentEntityTitle={artist.name} externalLinks={!sharedMode} id={1}/></Widget> }
                                {currentTabIndex.top == 2 && <Widget widgetID="top_playlists" widgetProps={widgetProps}><EntitiesList trends={true} title="Top Playlists" entity="playlists" parentEntity="track_artists" parentEntityIDs={this.state.id}  parentEntityTitle={artist.name} showPlaylistAnalisysLink={true} externalLinks={!sharedMode} id={2}/></Widget>}
                            </div>
                        </div>

                        <div className="tabs-content">
                            <div className="tab-container">
                                {this.renderTabsHeader(sourcesTabs, 'sources')}
                            </div>
                            <div className="tab-content">
                                {currentTabIndex.sources == 3 && <div className="pd-0 default" id={3}>
                                <Widget widgetID="sources" widgetProps={widgetProps}>
                                    <SourcesStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.sources.id}  shadowChartProps={{id: 'artist_details_sources', shadowChartWidth: 400, shadowChartHeight: 300}}/>
                                </Widget></div>}
                                {currentTabIndex.sources == 4 &&
                                    <div className="pd-0 default" id={4}><Widget widgetID="demographics" widgetProps={widgetProps}><DemographicsStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.age.id} shadowChartProps={{id: 'artist_details_demographics', shadowChartWidth: 800, shadowChartHeight: 400}} /></Widget></div>}
                            </div>
                        </div>
                    </MatchMediaWrapper>
                    <MatchMediaWrapper breakpoint={641} isMobile={false}>
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_products" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Products" entity="products" parentEntity="product_artists" parentEntityIDs={this.state.id} parentEntityTitle={artist.name} externalLinks={!sharedMode}/>
                                </Widget>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_tracks" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Tracks" entity="tracks" parentEntity="track_artists" parentEntityIDs={this.state.id}  parentEntityTitle={artist.name} externalLinks={!sharedMode} />
                                </Widget>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 pd-0 default">
                                <Widget widgetID="top_playlists" widgetProps={widgetProps}>
                                    <EntitiesList trends={true} title="Top Playlists" entity="playlists" parentEntity="track_artists" parentEntityIDs={this.state.id}  parentEntityTitle={artist.name} showPlaylistAnalisysLink={true} externalLinks={!sharedMode}/>
                                </Widget>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-xs-12 pd-0 default">
                                <Widget widgetID="sources" widgetProps={widgetProps}>
                                    <SourcesStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.sources.id}  shadowChartProps={{id: 'artist_details_sources', shadowChartWidth: 400, shadowChartHeight: 300}}/>
                        		</Widget>
                            </div>
                            <div className="col-lg-6 col-xs-12 pd-0 default">
                            
                                <Widget widgetID="demographics" widgetProps={widgetProps}>
                                    <DemographicsStats entity="artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.age.id} shadowChartProps={{id: 'artist_details_demographics', shadowChartWidth: 800, shadowChartHeight: 400}}/>
                                </Widget>
                            </div>
                        </div>
                    </MatchMediaWrapper>
                </div>}

                {!this.props.artists.profileLoading && 
                    <React.Fragment>
                        <MatchMediaWrapper breakpoint={640} isMobile={true}>
                            <div className="tabs-content">
                                <div className="tab-container">
                                    {this.renderTabsHeader(territoryTabs, 'territories')}
                                </div>
                                <div className="tab-content">
                                    <div className="pd-0 default territory-details" id={5}>
                                        {currentTabIndex.territories == 5 && <Widget widgetID="territories" widgetProps={widgetProps}><TerritoryStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.territories.id} /></Widget>}
                                    </div>
                                    <div className="pd-0 default" id={6}>
                                        {currentTabIndex.territories == 6 && <Widget widgetID="devices" widgetProps={widgetProps}>
                                            <DevicesStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.devices.id} shadowChartProps={{id: 'artist_details_devices', shadowChartWidth: 400, shadowChartHeight: 300}} />
                                        </Widget> }
                                    </div>
                                </div>
                            </div>
                        </MatchMediaWrapper>
                        <MatchMediaWrapper breakpoint={641} isMobile={false}>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 pd-0 default territory-details">
                                    <Widget widgetID="territories" widgetProps={widgetProps}>
                                        <TerritoryStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.territories.id} />
                                    </Widget>
                                </div>
                                <div className="col-lg-6 col-xs-12 pd-0 default">
                                    <Widget widgetID="devices" widgetProps={widgetProps}>
                                        <DevicesStats entity="track_artists" ids={this.state.id}  parentEntityTitle={artist.name} chartType={dspLogos.devices.id} shadowChartProps={{id: 'artist_details_devices', shadowChartWidth: 400, shadowChartHeight: 300}} />
                                    </Widget>
                                </div>

                            </div>
                        </MatchMediaWrapper>
                    </React.Fragment>
                }
                <div className="row">
                    <div className="col-xs-12 pd-0 default">
                        <Milestones entity="artists" id={this.state.id} sharedMode={sharedMode} />
                    </div>
                </div>
                                        
            </div>;
    }
}

function mapStateToProps(state) {

    return {
        artists: state.artists,
        filter: state.filter,
        stats: state.stats,
        products: state.products,
        tracks: state.tracks,
        playlists: state.playlists
    }
}

export default connect(mapStateToProps)(WithWidgetManager(ArtistProfile, 'artist_profile'))
