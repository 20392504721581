import React, { Component } from "react";
import ReactDOM from 'react-dom';

class Map extends Component {
  render() {
    return (
      <div className="territory-map-container">
        <div id="map" className="map"></div>
        <div id="popup" className="ol-popup"> </div>
      </div>
    );
  }
}

export default Map;
