import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { Route, withRouter } from 'react-router';
import SearchWidget from '../../components/pages/home/SearchWidget';
import AudienceDetailsView from './Details';
import Tooltip from '../../components/common/Tooltip';

class AudienceOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.searchResult = this.searchResult.bind(this);
    }
    
    componentDidMount() {
    }
    
    searchResult(item) {
        this.props.history.push(`/audience/${item.entity}/${item.id}`);
    }

    
    render() {
        const { sharedMode = false } = this.props;
        return <div className="inner-page">
            <h2 className="big-title audience">
                <span>Audience Analysis</span>
                <div className="toolbar-title-tooltip">
                    <Tooltip position="bottom" message={`As per the CCPA regulations, Spotify refreshes User IDs on a monthly basis. It is therefore difficult to accurately represent Unique listeners over longer periods of time, so please bear this in mind when doing your analysis.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </h2>
            {!sharedMode && <div className="ibox-content section-header">
                <div className="playlist-search-container">
                    <SearchWidget searchResult={this.searchResult} placeholder="Search artist, product or track" mode="playlist"  />
                </div>
            </div>}
            <Route path="/audience/:entity/:id" component={(AudienceDetailsView)}></Route>
            <Route path="/share/audience/:entity/:id"  render={(props)=><AudienceDetailsView sharedMode={true} {...props} />}></Route>
            <Helmet>
                <title>Audience Analysis</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
    } 
}

export default withRouter(connect(mapStateToProps)(AudienceOverview))