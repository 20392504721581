import config from '../../config/config';
import AuthHeader from '../../helpers/AuthHeader';

export const usersService = {
    getUsers,
    getUser,
    addUser,
    deleteUser,
    resendInvitation,
    resetPassword,
    getImprints,
    getArtists,
    getProducts
};

function getUsers(){
    return config.live ? getUsersLive() : getUsersStatic();
}

function getUsersLive() {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.orthus}/v1/users/by_silo`; 
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function getUsersStatic() {
    let baseUrl = `${config.apis.sales}/api/static/invitees.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getUser(id){
    return config.live ? getUserLive(id) : getUserStatic();
}

function getUserLive(id) {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.orthus}/v1/users/invitee/${id}`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function getUserStatic() {
    let baseUrl = `${config.apis.sales}/api/static/invitee.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function addUser(data){
    return config.live ? addUserLive(data) : addUserStatic(data);
}

function addUserStatic(data) {
    console.log(data);
    return Promise.resolve({});
}

function addUserLive(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/users/invite`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function deleteUser(data) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.orthus}/v1/users/revoke_acl`;
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
    
}

function getImprints(){
    return config.live ? getImprintsLive() : getImprintsStatic();
}

function getImprintsLive() {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.miniCat}/v2/imprints`;
    return fetch(baseUrl, {
        method: 'GET',
        headers
    })
    .then(res=>{   
        return res.json()
    })
}

function getImprintsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/acl_imprints.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getArtists(ids){
    return config.live ? getArtistsLive(ids) : getArtistsStatic(ids);
}

function getArtistsLive(ids) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/imprints/acl_artists`,
        data = {
            imprint_ids: ids.map(id=>Number(id))
        };
        
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })
}

function getArtistsStatic(ids) {
    console.log(ids);
    let baseUrl = `${config.apis.sales}/api/static/acl_artists.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getProducts(ids, imprintID){
    return config.live ? getProductsLive(ids, imprintID) : getProductsStatic(ids, imprintID);
}

function getProductsLive(ids, imprintID) {
    let headers = AuthHeader({'Content-Type': 'application/json'}),
        baseUrl = `${config.apis.miniCat}/v2/imprints/${imprintID}/acl_products`,
        data = {
            artist_ids: ids
        };
        
    return fetch(baseUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
    .then(res=>{   
        return res.json()
    })

}

function getProductsStatic(ids, imprintID) {
    console.log(ids);
    console.log(imprintID);
    let baseUrl = `${config.apis.sales}/api/static/acl_products.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function resendInvitation(id) {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.orthus}/v1/users/${id}/resend_invitation`;
    return fetch(baseUrl, {
        method: 'GET',
        headers,
    })
    .then(res=>{   
        return res.json()
    })
}


function resetPassword(id) {
    let headers = AuthHeader(),
        baseUrl = `${config.apis.orthus}/v1/users/${id}/forgot_password`;
    return fetch(baseUrl, {
        method: 'GET',
        headers,
    })
    .then(res=>{   
        return res.json()
    })
}
