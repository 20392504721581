import React from "react";

export default function VendorLogo(props) {
  const { name, modificator } = props;
  if (!name) return null;
  const vendorCss = name.toLowerCase().replace(/\s/g, "");
  const modificatorClass = modificator ? `vendor-widget--${modificator}` : '';
  return (
    <span
      title={name}
      className={`vendor-widget vendor-widget-${vendorCss} ${modificatorClass}`}
    />
  );
}