import 'polyfill-object.fromentries';
import 'array-flat-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { history } from './helpers/history';
import routes from './config/routes';
import store from './config/store';
import './components/common/Datagrid/ReactTableOptions';
import './components/common/GTM';
import Rollbar from './components/common/Rollbar';

// import jquery from 'jquery';
// import metismenu from 'metismenu';
// import bootstrap from 'bootstrap';

// import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/@fortawesome/fontawesome-free/css/all.css'
//import './../node_modules/animate.css/animate.min.css'
// import './../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'
import "react-datepicker/dist/react-datepicker.css";
//import './../node_modules/chosen-js/chosen.css'
// import './../node_modules/bootstrap-multiselect/dist/css/bootstrap-multiselect.css'
// import './../node_modules/jvectormap/jquery-jvectormap.css'
import './../node_modules/react-table/react-table.css'
import './../node_modules/toastr/build/toastr.css'
import './../node_modules/react-grid-layout/css/styles.css'
import './../node_modules/react-resizable/css/styles.css'
import 'rc-slider/assets/index.css';
// import './../public/styles/style.css'
import './styles/index.scss';
import './styles/patch.scss';

ReactDOM.render(
    <Rollbar>
        <Provider store={store}>    
                <Router history={history}>{routes}</Router>
        </Provider>
    </Rollbar>,
    document.getElementById('root')
);