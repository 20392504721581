import React from 'react';
import { Text, View, Font } from '@react-pdf/renderer';
import styles from '../../common/PDF/LayoutStylesheet';
import DateRange from '../../common/PDF/DateRange';
import PDFMainFilterSummary from './PDFMainFilterSummary';
import PDFMissingDataWarning from './PDFMissingDataWarning';

export default ({header}) => {
    const { globalFilter, reportTitle, entityTitle, missingData } = header;
    return <View style={styles.reportTitle}>
        <Text className={styles.smallHeaderNotUnderline}>{entityTitle} - {reportTitle}</Text>
        <DateRange filter={globalFilter} />
        <PDFMainFilterSummary data={globalFilter} />
        <PDFMissingDataWarning data={missingData} />
    </View>
};