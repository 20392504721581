import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize } from 'lodash';
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import FollowersStats from '../artists/Followers';

import { statsActions } from '../../../data/actions/stats';

class MultiArtistsFollowers extends React.Component {
    
    nullElement = {value: null, label: 'All'}
    
    constructor(props) {
        super(props);
        this.state = {
            entity: this.nullElement
        }
        
        this.setEntity = this.setEntity.bind(this);
    }

    setEntity(entity) {
        this.setState({entity});
    }
    
    renderToolbar(data){
        let entities = [this.nullElement];
        if(data) {
            for(let entity of data.datasets)
                entities.push({
                    value: entity.id,
                    label: entity.label
                });
        }
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <ReactSelect value={this.state.entity} options={entities} onChange={this.setEntity} className="single-select"/>
        </div>
        </div>)

        return toolbar;
    }
    
    
    componentDidMount() {
        const { filter } = this.props;
        
        
    }
    
    componentWillReceiveProps(nextProps){
        /*
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats();
            }                
        }
        */
        /*
        if(nextProps.ids && !isEqual(nextProps.ids, this.props.ids)) {
            this.props.getStreamsStats(nextProps.ids);
        } 
        */
    }
    
    render (){
        const { multiMetadata = [], multiMetadataLoading } = this.props.stats;
        if(!multiMetadata)
            return null;
        
        const artistsMetadata = multiMetadata.filter(item=>item.entity == 'artists');
        if(!artistsMetadata.length)
            return null;
        
        let totalChannels = artistsMetadata.reduce((total, current)=>total.concat(current.channels), []);
        const metric = (this.props.mode == 'followers') ? 'metric' : 'monthly_listeners';
        return <FollowersStats artist={{artists_channels: totalChannels}} metric={metric} defaultMode={this.props.defaultMode} modeChange={this.props.modeChange} shadowChartProps={this.props.shadowChartProps} />

//            
//        let chartData = Object.assign({}, multiStreams);
//        if(this.state.entity.value!==null) {
//            const dataset = find(multiStreams.datasets, {id: this.state.entity.value});
//            chartData.datasets = [dataset];
//        }
//
//        return <Box title={widgetTitle} toolbar={this.renderToolbar(multiStreams)} spinnerEnabled={multiStreamsLoading} >
//            {chartData.datasets && 
//                <div className="chart-block">
//                    {chartData.datasets && <LineChart data={chartData} />}
//                </div>
//            }
//        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getMetadata: (filter) => { 
            dispatch(artistsActions.getMultiEntitiesMetadata(filter));
        } 
    } 
}



export default connect(mapStateToProps, mapDispatchToProps)(MultiArtistsFollowers);