import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import Modal from '../common/Modal'
import Select from './elements/Select'
import DatePicker from './elements/DatePicker'
import CSVImportModal from '../common/CSVImportModal';
import CSVSampleDownload from '../common/CSVSampleDownload';

import { composeValidators, required, date, length } from './validators'

class AddMilestoneForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notesMaxLength: 255
        };
    }

    getCharactersLeft(maxLength, prop) {
        const charactersDiff = maxLength - (prop ? prop.length : 0);
        const charactersLeft = charactersDiff > 0 ? charactersDiff : 0;

        return `Characters left: ${charactersLeft}`;
    }

    render() {
        let submit,
            {show, onSubmit, onBulkAdd, onClose, milestoneTypes, title, mode="new", values={}, entities = [], fileUpload=true} = this.props;
        let entityTypes = [{label: 'Imprints', value: 'imprints'}, {label:'Artists', value: 'artists'}, {label:'Products', value: 'products'}, {label:'Tracks', value: 'tracks'}];
        
        let milestoneCSVFields = [ {key: 'milestone_type_id', required: true, label: 'Milestone Type', options: milestoneTypes},                                     
            {key: 'reference', required: true, label: 'Title', placeholder: 'Milestone title (Remove me)'},
            {key: 'notes', required: false, label: 'Notes', placeholder: 'Example notes on the project milestone'},
            {key: 'occurs_at', required: true, label: 'Occurs At', placeholder: '2022-01-01'},
            {key: 'ends_at', required: false, label: 'Ends At', placeholder: '2022-01-02(optional)'}];
        
        if(entities && entities.length) {
            milestoneCSVFields.unshift({key: 'linkedEntity', required: true, label: 'Entity', options: entities});
        }
        else {
            milestoneCSVFields.unshift({key: 'entity_type', required: true, label: 'Entity Type', options: entityTypes});
            milestoneCSVFields.unshift({key: 'entity_id', required: true, label: 'Entity ID'});
        }
            

        const buttons = ( mode == 'new' && fileUpload ) && <div className="milestone-modal-actions">
            <CSVSampleDownload fields={milestoneCSVFields} linkClass="button-no-style download-scv" linkTitle="Download Template CSV" />
            <CSVImportModal fields={milestoneCSVFields} buttonClass="default-btn default-btn--small milestone-import-btn default-btn--light" modalTitle="Import Milestones" buttonTitle="Choose File" onSubmit={onBulkAdd} />
            </div>
        return <Modal show={show} 
                title={title} 
                submitTitle="Save"
                handleSubmit={e=>submit(e)}
                handleClose={onClose}
                dialogClassName="milestone-modal"
                comment={buttons}
            >
                <Form onSubmit={onSubmit} initialValues={values}
                    validate={(values)=>{
                        let errors = {};
                        if(values.ends_at) {
                            if(new Date(values.ends_at) < new Date(values.occurs_at)) {
                                errors.ends_at = 'End date cannot be before start date.'
                            }
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit;
                        return <form className="m-t">
                                <Field name="milestone_type_id"  
                                    validate={composeValidators(required)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':'' } milestone-type`}>
                                            <div className='milestone-type-select'>
                                                <label className="font-normal control-label">Type*</label>
                                                <Select input={input} options={milestoneTypes} className="custom-select" />
                                            </div>
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                {(entities.length > 0) && <Field name="entity"  
                                    validate={composeValidators(required)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''} entity`}>
                                            <div className='milestone-type-select'>
                                                <label className="font-normal control-label">Entity* </label>
                                                <div className="modal-select-holder">
                                                    <Select input={input} options={entities} className="custom-select" />
                                                    {hasError && <span className="help-block">{meta.error}</span>}
                                                </div>
                                            </div>
                                        </div>            
                                    }}
                                </Field>}
                                <Field name="reference"  
                                    validate={composeValidators(required)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''} milestone-type`}>
                                            <div className='milestone-type-select'>
                                                <label className="font-normal control-label">Title*</label>
                                                <input {...input} type="text" className="form-control" />
                                            </div>
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                
                                <div className="notes-holder">
                                    <Field name="notes"
                                        maxLength={this.state.notesMaxLength}
                                        validate={composeValidators(length)}
                                    >
                                        {({ input, meta }) => {
                                            const hasError = meta.error && meta.touched;
                                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                                <label className="font-normal control-label">Notes</label>
                                                <textarea {...input} type="text" className="form-control" />
                                                {hasError && <span className="help-block">{meta.error}</span>}
                                            </div>            
                                        }}
                                    </Field>
                                </div>
                                <div className="characters-left-holder">
                                    <span>{this.getCharactersLeft(this.state.notesMaxLength, values.notes)}</span>
                                </div>
                                
                                <div className="row">

                                    <div className="col-xs-12 col-sm-6 inline-datepicker">
                                        <Field name="occurs_at"  
                                            validate={composeValidators(required, date)} 
                                        >
                                            {({ input, meta }) => {
                                                const hasError = meta.error && meta.touched;
                                                return <div className={`form-group${hasError ? ' has-error':''} form-control-date`}>
                                                    <div>
                                                        <label className="font-normal control-label">Target Date</label>
                                                        <DatePicker input={input} className="form-control" placeholder="YYYY-MM-DD" datepickerPosition="right" />
                                                        {hasError && <span className="help-block">{meta.error}</span>}
                                                    </div>
                                                </div>            
                                            }}
                                        </Field>
                                </div>
                                <div className="col-xs-12 col-sm-6 inline-datepicker">
                                    <Field name="ends_at"  
                                        validate={composeValidators(date)}
                                    >
                                        {({ input, meta }) => {
                                            const hasError = meta.error && meta.touched;
                                            return <div className={`form-group${hasError ? ' has-error':''} form-control-date`}>
                                                <div>
                                                    <label className="font-normal control-label">End Date</label>
                                                    <DatePicker input={input} className="form-control" placeholder="YYYY-MM-DD" datepickerPosition="right" />
                                                    {hasError && <span className="help-block">{meta.error}</span>}
                                                </div>
                                            </div>            
                                        }}
                                    </Field>
                                </div>
                            </div>
                        </form>
                    }}
                />
                
        </Modal>
    }
}
export default AddMilestoneForm