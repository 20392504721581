import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import config from '../../config/config';

export default function Rollbar(props) {
    const { rollbar: rollbarConfig } = config;
    if(rollbarConfig !== undefined) {
        return <Provider config={rollbarConfig}>
          <ErrorBoundary>
          {...props.children}
          </ErrorBoundary>
        </Provider>
    }
    else {
        return <React.Fragment>{...props.children}</React.Fragment>
    }
}