import { productsConstants } from '../constants/products';

export function products(state = {data: [], product: {}, card: {}, autoCompleteLoading: false, orderedTracks: {}, 
        demographicsComparison: {}, territoriesComparison: {}, artistComparison: {} , datetimeComparison: {}, vendorsComparison: {} }, action) {
    switch (action.type) {
        case productsConstants.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case productsConstants.GET_PRODUCTS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.products,
                metadata: action.metadata,
                total: action.total
            };
        case productsConstants.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case productsConstants.GET_TOP_PRODUCTS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                metadata: null,
                timeseries: null
            };
        case productsConstants.GET_TOP_PRODUCTS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.products,
                metadata: action.metadata
            };
        case productsConstants.GET_TOP_PRODUCTS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };

        case productsConstants.GET_PRODUCTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case productsConstants.GET_PRODUCTS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.products
            };
        case productsConstants.GET_PRODUCTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };
        case productsConstants.GET_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case productsConstants.GET_PRODUCT_DETAILS_SUCCESS:
            let product = state.product;
            product[action.id] = action.product;
            return {
                ...state,
                loading: false,
                product: product
            };
        case productsConstants.GET_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case productsConstants.GET_PRODUCTS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case productsConstants.GET_PRODUCTS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card
            };
        case productsConstants.GET_PRODUCTS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case productsConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true,
                streams: undefined
            };
        case productsConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.products
            };
        case productsConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error,
                streams: undefined
            };
        case productsConstants.GET_PRODUCTS_BY_BARCODES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case productsConstants.GET_PRODUCTS_BY_BARCODES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.products
            };
        case productsConstants.GET_PRODUCTS_BY_BARCODES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case productsConstants.GET_TRACKS_REQUEST:
            return {
                ...state,
                tracksLoading: true,
                tracks: null
            };
        case productsConstants.GET_TRACKS_SUCCESS:
            return {

                ...state,
                tracksLoading: false,
                tracks: action.tracks,
            };
        case productsConstants.GET_TRACKS_FAILURE:
            return {
                ...state,
                tracksLoading: false,
                error: action.error
            };


        case productsConstants.GET_TIMESERIES_PRODUCTS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case productsConstants.GET_TIMESERIES_PRODUCTS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.products
            };
        case productsConstants.GET_TIMESERIES_PRODUCTS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };
            
        case productsConstants.COMPARE_PRODUCTS_REQUEST:
            return {
                ...state,
                comparisonLoading: true
            };
        case productsConstants.COMPARE_PRODUCTS_SUCCESS:
            return {

                ...state,
                comparisonLoading: false,
                comparison: action.products,
            };
        case productsConstants.COMPARE_PRODUCTS_FAILURE:
            return {
                ...state,
                comparisonLoading: false,
                error: action.error
            };

        case productsConstants.COMPARE_PRODUCT_DEMOGRAPHICS_REQUEST:
            return {
                ...state,
                demographicsComparisonLoading: true
            };
        case productsConstants.COMPARE_PRODUCT_DEMOGRAPHICS_SUCCESS:
            return {

                ...state,
                demographicsComparisonLoading: false,
                demographicsComparison: {
                    ...state.demographicsComparison,
                    [action.id]: action.product
                }
                    
                    
            };
        case productsConstants.COMPARE_PRODUCT_DEMOGRAPHICS_FAILURE:
            return {
                ...state,
                demographicsComparisonLoading: false,
                error: action.error
            };

        case productsConstants.COMPARE_PRODUCT_ARTIST_REQUEST:
            return {
                ...state,
                artistComparisonLoading: true
            };
        case productsConstants.COMPARE_PRODUCT_ARTIST_SUCCESS:
            return {
        
                ...state,
                artistComparisonLoading: false,
                artistComparison: {
                    ...state.artistComparison,
                    [action.id]: action.product
                }
                    
                    
            };
        case productsConstants.COMPARE_PRODUCT_ARTIST_FAILURE:
            return {
                ...state,
                artistComparisonLoading: false,
                error: action.error
            };


        case productsConstants.COMPARE_PRODUCT_TERRITORIES_REQUEST:
            return {
                ...state,
                territoriesComparisonLoading: true
            };
        case productsConstants.COMPARE_PRODUCT_TERRITORIES_SUCCESS:
            return {

                ...state,
                territoriesComparisonLoading: false,
                territoriesComparison: {
                    ...state.territoriesComparison,
                    [action.id]: action.product
                }
                    
                    
            };
        case productsConstants.COMPARE_PRODUCT_TERRITORIES_FAILURE:
            return {
                ...state,
                territoriesComparisonLoading: false,
                error: action.error
            };

        case productsConstants.COMPARE_PRODUCT_DATETIME_REQUEST:
            return {
                ...state,
                datetimeComparisonLoading: true
            };
        case productsConstants.COMPARE_PRODUCT_DATETIME_SUCCESS:
            return {
        
                ...state,
                datetimeComparisonLoading: false,
                datetimeComparison: {
                    ...state.datetimeComparison,
                    [action.id]: action.product
                }
                    
                    
            };
        case productsConstants.COMPARE_PRODUCT_DATETIME_FAILURE:
            return {
                ...state,
                datetimeComparisonLoading: false,
                error: action.error
            };

            
        case productsConstants.COMPARE_PRODUCT_VENDORS_REQUEST:
            return {
                ...state,
                vendorsComparisonLoading: true
            };
        case productsConstants.COMPARE_PRODUCT_VENDORS_SUCCESS:
            return {

                ...state,
                vendorsComparisonLoading: false,
                vendorsComparison: {
                    ...state.vendorsComparison,
                    [action.id]: action.product
                }
                    
                    
            };
        case productsConstants.COMPARE_PRODUCT_VENDORS_FAILURE:
            return {
                ...state,
                vendorsComparisonLoading: false,
                error: action.error
            };

        case productsConstants.GET_ORDERED_TRACKS_REQUEST:
            return {
                ...state,
                orderedTracksLoading: true
            };
        case productsConstants.GET_ORDERED_TRACKS_SUCCESS:
            let orderedTracks = state.orderedTracks;
            orderedTracks[action.id] = action.tracks;
            return {
                ...state,
                orderedTracksLoading: false,
                orderedTracks: orderedTracks
            };
        case productsConstants.GET_ORDERED_TRACKS_FAILURE:
            return {
                ...state,
                orderedTracksLoading: false,
                error: action.error
            };

            
        default:
            return state
    }
}
