import React from 'react';
import { find } from 'lodash';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';
import { globalFilterFormatter } from '../../../data/formatters/global_filter';

export default ({data}) => {
  const extras = globalFilterFormatter.formatFilterSummary(data, 3);
  if(!extras || !extras.length)
      return null;
  
  return <View style={styles.reportTitle}>
      {Object.keys(extras).map(key=><Text key={key} style={styles.dateRangeTitle}>{extras[key]}</Text>)}
  </View>
};