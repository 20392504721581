import React, { Component } from "react";
import { connect } from 'react-redux';

export default function(ComposedComponent) {
    class WithSiloCode extends Component {

        /*
        shouldComponentUpdate(nextProps) {
            return ((this.props.user === undefined && nextProps.user !== undefined) || (this.props.user.user_silo_codes !== nextProps.user.user_silo_codes));
        }
        */

        render() {
            const { loggedIn, user } = this.props.user; 
            if(!loggedIn){
                return <ComposedComponent silo={false} {...this.props} />
            }
            else {
                let silo = user.primary_silo_code;
                //if(silo.includes(','))
                //    silo = silo.split(',')[0];
                return <ComposedComponent silo={silo} {...this.props} />
            }
        }
    }

    function mapStateToProps(state) {
        return { 
            user: state.user
        };
    }

    return connect(mapStateToProps)(WithSiloCode);
}