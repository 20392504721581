import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import PDFHeader from './PDFHeader';
import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';

const renderMilestones=(data)=>{
    const columns=[
       {key: 'linked', title: 'Item', width: 40},
       {key: 'milestone_type', title: 'Type', width: 20},
       {key: 'reference', title: 'Reference', width: 20},
       {key: 'occurs_at', title: 'Occurs At', width: 15},
       {key: 'ends_at', title: 'Ends At', width: 15},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}


export default (props) => {
    const { data: { images, tables, metadata, overview, reportTitle, globalFilter, entity, header } } = props;
  return <Document>
    <Page size="A4" style={styles.page}>
      <PDFHeader header={header} />
      <View style={styles.section}>
          <OverviewPDF data={header.overview} />
      </View>    

      <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Milestones</Text>
          {renderMilestones(tables.milestones)}
        </View>
      </View>
    </Page>

  </Document>
};