import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { userActions } from '../../data/actions/user';
import { smoothlyMenu } from '../layouts/Helpers';
import profile from '../../../public/img/default-user.png';
import NotificationCount from '../widgets/notifications/NotificationCount'
import NotificationMenu from '../widgets/notifications/NotificationMenu'
import WithPermission from '../../helpers/WithPermission';
import DropdownMenu from './DropdownMenu'
import { withRouter } from 'react-router';
import NavigationMain from './NavigationMain';
import NavigationUser from './NavigationUser';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper'

import moment from 'moment';
import { notificationsActions } from '../../data/actions/notifications';
const DATE_FORMAT = 'YYYY-MM-DD';
var arrowDownSvg = require('!svg-inline-loader!../../../public/img/arrow-down.svg');
var adminSvg = require('!svg-inline-loader!../../../public/img/icons/admin.svg');
var logoutSvg = require('!svg-inline-loader!../../../public/img/icons/logout.svg');
var toggleSvg = require('!svg-inline-loader!../../../public/img/toggle-menu.svg');
var toggleFilterSvg = require('!svg-inline-loader!../../../public/img/toggle-filter.svg');

class TopHeader extends React.Component {

    constructor(props){
        super(props);
        this.logout = this.logout.bind(this);
    }

    state = {
        todayDate: moment(new Date()).format(DATE_FORMAT)
    }

    componentDidMount() {
      this.props.getCountOfNotifications(this.state.todayDate);
    }

    componentDidUpdate(prevProps){
      if(this.props.notifications.count !== prevProps.notifications.count){
        this.props.getCountOfNotifications(this.state.todayDate);
      }
    }

    logout(e){
        e.preventDefault();
        return this.props.logout();
    }

    onUpdatePlaylistNotification = () => {
      const { getPlaylistNotifications, updatePlaylistNotifications } = this.props;
      const { playlist } = this.props.notifications;

      updatePlaylistNotifications(playlist.id, getPlaylistNotifications, this.state.todayDate);
    }

    onUpdateReleaseNotifications = () => {
      const { updateReleaseNotifications, history } = this.props;
      const { releases } = this.props.notifications;

      updateReleaseNotifications(releases.id, this.state.todayDate);

      history.push('/reporting/new-releases');
    }

    onUpdateHeartbeatsNotifications = () => {
      const { updateHeartbeatsNotifications, history } = this.props;
      const { heartbeats } = this.props.notifications;

      updateHeartbeatsNotifications(heartbeats.id, this.state.todayDate);

      history.push('/reporting/heartbeats');
    }
    
    render() {
      const { user } = this.props,
        userName = user.loggedIn ? `${user.user.first_name} ${user.user.last_name}` : 'Anonymous User',
        userAvatar = user.loggedIn ? (user.user.avatar_url ? user.user.avatar_url : profile) : '';

      const dropdownHeader = <div className="dropdown-toggle user-dropdown">
        <div className="user-image-holder">
          <div className="user-image-block">
            {this.props.notifications.count !== 0 && this.props.count !== undefined && <NotificationCount
              notifications={this.props.notifications}
              />}
              <img src={userAvatar} alt="profile" title={userName}/>
          </div>
        </div>
        <span className="caret" dangerouslySetInnerHTML={{__html: arrowDownSvg}}></span>
      </div>
            
      const mainMenuHeader = <span className="toggle-main-menu" dangerouslySetInnerHTML={{__html: toggleSvg}}></span>

      const mobileMenu = <DropdownMenu header={mainMenuHeader} className="mobile-navigation">
        <NavigationMain location={this.props.location} />
      </DropdownMenu>

      const pathname = window.location.pathname;

      const isLogoShown = pathname != '/main' ? true : false;
      const isMain = pathname == '/main' ? "is-main" : "";

      return (
        <div className="header-container">
          <div className={`header-logo-container ${isMain}`}>
            <Link to="/">
              <div className="main-logo"></div>
            </Link>
            {/* <MatchMediaWrapper breakpoint={639} isMobile={true}>
              <div className="toggle-filter-mobile" 
                onClick={(e)=>this.props.toggleFilterOpened()}>
                <span className="toggle-filter-mobile-btn" dangerouslySetInnerHTML={{__html: toggleFilterSvg}}></span>
                {this.props.filterCount && <span className="filter-count">{this.props.filterCount}</span>}
              </div>
            </MatchMediaWrapper> */}
          </div>
          {this.props.children}
          <nav className="header-navbar" role="navigation" style={{marginBottom: 0}}>
            <div className="user-nav">
              <DropdownMenu header={dropdownHeader}>
                <ul className="user-dropdown-list">
                  <li><Link to="/user/profile" className="main-nav-link">
                    <span className="nav-label">{userName}</span></Link></li>
                  {/*<li><a href="#">User Position</a></li>*/}
                  <div className="user-menu-list">
                    <NavigationUser location={this.props.location} />
                  </div>
                  {/* <WithPermission permission="allow_admin">
                    <li>
                      <Link to="/admin/statistics" className="main-nav-link">
                        <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: adminSvg}}></span>
                        <span className="nav-label">Admin</span>
                      </Link>
                    </li>
                  </WithPermission> */}
                  <li>
                    <a href="http://support.entertainment-intelligence.com" target="_blank" className="main-nav-link">
                      <span className="nav-label">Help Guide</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={this.logout} className="main-nav-link">
                      <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: logoutSvg}}></span>
                      <span className="nav-label">Logout</span>
                    </a>
                  </li>
                </ul>
                {this.props.notifications.count !== 0 && this.props.count !== undefined && 
                  <WithPermission permission="allow_admin">
                    <NotificationMenu
                      todayDate={this.state.todayDate}
                      notifications={this.props.notifications}
                      catalogueExceptions={this.props.catalogueExceptions}
                      onUpdatePlaylistNotification={this.onUpdatePlaylistNotification}
                      onUpdateReleaseNotifications={this.onUpdateReleaseNotifications}
                      onUpdateHeartbeatsNotifications={this.onUpdateHeartbeatsNotifications}
                      user={this.props.user.user}
                    />
                  </WithPermission>
                }
              </DropdownMenu>
            </div>
          </nav>

          <DropdownMenu header={mainMenuHeader} className="mobile-navigation">
            <NavigationMain location={this.props.location} />
          </DropdownMenu>
        </div>
      )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notifications: state.notifications,
        catalogueExceptions: state.catalogueExceptions,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(userActions.logout()),
        getCountOfNotifications: date => dispatch( notificationsActions.getCountOfNotifications(date)),
        updatePlaylistNotifications: (id, cb, date) => dispatch( notificationsActions.updateStorePlaylistNotifications(id, cb, date)),
        updateReleaseNotifications: (id, date) => dispatch( notificationsActions.updateReleaseNotifications(id, date)),
        updateHeartbeatsNotifications: (id, date) => dispatch( notificationsActions.updateHeartbeatsNotifications(id, date))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopHeader));
