import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '../helpers/store'
import { withRouter } from 'react-router';
import { gridsActions } from '../data/actions/grids';
import ConfirmClick from '../components/common/ConfirmClick';

const deleteSvg = require('!svg-inline-loader!../../public/img/icons/delete.svg');

class Grid extends Component {
    constructor(props) {
        super(props);
        this.applyGrid = this.applyGrid.bind(this);
        this.deleteGrid = this.deleteGrid.bind(this);
        this.shareGrid = this.shareGrid.bind(this);
    }
    
    componentDidMount() {
        this.props.dispatch(gridsActions.getGrids());
    }
    
    applyGrid(item) {
        store.set('store_homepage', item.layouts);
        store.set('store_homepage_id', item.id);
        this.props.dispatch(gridsActions.saveDefaultGrid({default_layout: item.layouts, default_layout_id: item.id}));
        this.props.history.push('/overview');
    }
    
    deleteGrid(id) {
        this.props.dispatch(gridsActions.deleteGrid(id));
    }
    
    shareGrid(id, shared) {
        const gridData = {is_shared: !shared} 
        this.props.dispatch(gridsActions.editGrid(gridData, id));
    }
    
    renderTemplates(items, currentTemplate, shared) {
        return items.length > 0 ? <div>
            <h3>{shared ? 'Shared Templates' : 'Own Templates'}</h3>
            <ul className="templates-list">
            {items.map(item=><li className="templates-item">
                <span>{item.grid_name}</span> 
                <div className="template-action-item">
                    {currentTemplate != item.id && <a className="default-btn default-btn--small" onClick={()=>this.applyGrid(item)}>Apply</a>}
                    {currentTemplate == item.id && <a className="template-current">Current</a>}
                    {(!shared && item.id !=0 ) && <React.Fragment>
                        <ConfirmClick confirmClass="filter-reset-btn button-no-style" confirmAction={()=>this.deleteGrid(item.id)} title="remove template" confirmLabel={<span><span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>Delete</span>} />
                        <a className="template-share-text" onClick={()=>this.shareGrid(item.id, item.is_shared)}>{item.is_shared ? 'Stop sharing' : 'Share'}
                        <span className={`btn share-all-milestones ${item.is_shared ? 'shared' : ''}`}></span></a>
                    </React.Fragment>}
                </div>
            </li>)}
            </ul> 
        </div> : null;
    }
    
    render() {
        const {user} = this.props.user;
        const {items = []} = this.props.grids;
        const ownTemplates = items.filter(item=>item.user_id == user.id),
            sharedTemplates = items.filter(item=>item.user_id != user.id);
        
        const currentTemplate = store.get('store_homepage_id');
        
        return <div className="inner-page homepage">
            <div className="ibox">
                <h2 className="">Templates</h2>
                {this.renderTemplates(ownTemplates, currentTemplate, false)}
                {this.renderTemplates(sharedTemplates, currentTemplate, true)}
            </div>
           
        </div>
    }
}

function mapStateToProps(state) {
    return {
        grids: state.grids,
        user: state.user
    } 
}

export default connect(mapStateToProps)(withRouter(Grid))