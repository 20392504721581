import React from 'react';

import {find} from 'lodash';
import countriesByAbbreviation from './CountriesByAbbreviation';
import ReactCountryFlag from 'react-country-flag';
import CountryFlagAlias from './CountryFlagAlias';

const DEFAULT_FLAG_CLASS_NAME = 'flag-default';

export default function CountryFlag(props) {
    let { country, className, ...rest } = props;
//    if(CountryFlagAlias.hasOwnProperty(country))
//        country = CountryFlagAlias[country];
    const styles = className || DEFAULT_FLAG_CLASS_NAME;
//    const countryStructure = countriesByAbbreviation.find(countryData => countryData.country === country ),
//        countryAbbreviation = countryStructure ? countryStructure.abbreviation : null;
    let countryCode = country == 'UK' ? 'GB' : country;
    return country && country !== 'ZZ' ? <ReactCountryFlag aria-label={countryCode} svg countryCode={countryCode} className={styles} cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/" {...rest} /> : null;
}

export function CountryFlagByAbbreviation(props) {
  const { country, className, ...rest } = props;
  const styles = className || DEFAULT_FLAG_CLASS_NAME;

  return <ReactCountryFlag svg countryCode={country} className={styles} {...rest}  />
}
