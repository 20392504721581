import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import useOutsideClick from "../../helpers/useOutsideClick"

function DropdownMenu(props) {
  const [showMenu, setShowMenu] = useState(false);
  const { children, header, className } = props;
  const ref = useRef();
  const isOpened = showMenu ? "opened" : ""

  useOutsideClick(ref, () => {
    if (showMenu) setShowMenu(false);
  });
  
  useEffect( () => {
      if (showMenu) setShowMenu(false);
  }, [props.location.pathname])
  
  return (
    <div className={`dropdown-holder ${isOpened} ${className}`}>
      <div className="dropdown-header" onClick= {() => setShowMenu(!showMenu)}>
        {header}
      </div>

      { showMenu &&  
        <div className="dropdown-menu" ref={ref}>
          {children}
        </div>
      }
    </div>
  );
} 

export default withRouter(DropdownMenu)