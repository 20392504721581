import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import PercentageValue from '../../PercentageValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';

export const sourcesSettings = {
    settingsForTable,
    settingsForRow
};


function settingsForTable(mode){
    
    
    let columns = [
      {
              id: 'icon',
              headerClassName: ' icon-header icon',
              className: 'icon', 
              Header: '',
              // width: 70,
              accessor: 'source',
              Cell: props => <div className="source-icon"></div>
          },
                                
       {
           id: 'source',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'source',
           accessor: 'source',
           // width: 190
       },
       {
           id: 'streams',
           headerClassName: ' streams-header',
           className: 'streams',
           Header: 'Units',
           Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
           // width: 70,
           accessor: 'curr_units'
       },
       {
           id: 'share',
           headerClassName: ' share',
           className: 'share',
           Header: 'Share',
           // width: 70,
           accessor: 'share',
           Cell: props => <span className="share-val">{props.value}%</span>,
       },
    ];
    return columns.map(column=>{
        //column.show = modes[mode].includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};

    return {
        className: `tr-source tr-source-${rowInfo.row.source.toLowerCase().replace(' ', '-')}`
    }
}