import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { find } from 'lodash';
import WithPermission from '../../helpers/WithPermission';
import WithSilo from '../../helpers/WithSilo';
import ClickOutsideHolder from '../../helpers/ClickOutsideHolder'

var arrowDownSvg = require('!svg-inline-loader!../../../public/img/arrow-down.svg');

class Navigation extends Component {
    constructor(props) {
      super(props);
      this.state = {
        menu: this.props.menuItems
      }
    

      this.ref = React.createRef();
      // this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      const { menu } = this.refs;
      document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside, true);
    };

    activeRoute(routeName) {
      return this.props.location.pathname.startsWith(routeName) ? "active" : "";
    }

    secondLevelActive(routeRegex, element) {
      if(typeof routeRegex == 'string')
        routeRegex = new RegExp(routeRegex);
      return this.props.location.pathname.match(routeRegex) ? (element == 'li' ? 'active' : "nav nav-second-level") : (element == 'li' ? '' : "nav nav-second-level")
    }

    getCounter(entity) {
        let value = null;
        if(this.props.stats.catalog) {
            entity = find(this.props.stats.catalog, {entity});
            if(entity)
                value = entity.absoluteValue;
        }

        return value !== null ? <span className="label label-info">({value})</span> : null;
    }

    toggleMenu(label, isCloseAll) {
      this.setState(function (state) {
        return {
          menu: [
            ...state.menu.map((m) => {
              if (isCloseAll) {
                return {
                  ...m,
                  isSubmenuOpened: false
                }
              } else if (m.label === label) {
                return {
                  ...m,
                  isSubmenuOpened: !m.isSubmenuOpened
                }
              } else {
                return {
                  ...m,
                  isSubmenuOpened: false
                }
              }
            })
          ]
        };
      });
    }

    render() {
      const { menu } = this.state;
      
      return (
        <nav className="header-nav" role="navigation">
          <ul className="header-nav-list" ref="menu">
            {menu.map(({ label, route, submenu, isSubmenuOpened, icon, permission }) => (
              (
                <li key={label} className={`header-nav-list-item ${!submenu ? this.activeRoute(route) : this.secondLevelActive(route, "li")}`}>
                  {permission ? <WithPermission permission={permission}>
                      {!submenu ? 
                      <Link to={route} className={`main-nav-link ${isSubmenuOpened ? 'opened' : ''}`} onClick={(e) => {
                        e.stopPropagation();
                        this.toggleMenu(label, true)
                      }}>
                        <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: icon}}></span>
                        <span className="nav-label">{label}</span>
                      </Link> : 
                      <div className={`main-nav-link ${isSubmenuOpened ? 'opened' : ''}`} onClick={(e) => {
                        e.stopPropagation();
                        this.toggleMenu(label)
                      }}>
                        <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: icon}}></span>
                        <span className="nav-label">{label}</span>
                        <span className="nav-label-icon" dangerouslySetInnerHTML={{__html: arrowDownSvg}}></span>
                      </div> 
                    }
                  </WithPermission> : (!submenu ? 
                    <Link to={route} className={`main-nav-link ${isSubmenuOpened ? 'opened' : ''}`} onClick={(e) => {
                      e.stopPropagation();
                      this.toggleMenu(label, true)
                    }}>
                      <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: icon}}></span>
                      <span className="nav-label">{label}</span>
                    </Link> : 
                    <div className={`main-nav-link ${isSubmenuOpened ? 'opened' : ''}`} onClick={(e) => {
                      e.stopPropagation();
                      this.toggleMenu(label)
                    }}>
                      <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: icon}}></span>
                      <span className="nav-label">{label}</span>
                      <span className="nav-label-icon" dangerouslySetInnerHTML={{__html: arrowDownSvg}}></span>
                    </div> 
                  )}
                    <ul className={`${this.secondLevelActive(route, "ul")} ${isSubmenuOpened ? '' : 'collapsed'}`} >
                      {submenu && submenu.length && isSubmenuOpened &&
                        submenu.map(({ label: subLabel, route: subRoute, permission: withPermission, counter: counter, icon: subIcon, siloCodes }) => (
                                withPermission ? 
                        <WithPermission key={subLabel}  permission={withPermission}>
                        <WithSilo siloCodes={siloCodes}> 
                          <li className={this.activeRoute(subRoute)}>
                            <Link to={subRoute} onClick={(e) => {
                                e.stopPropagation();
                                this.toggleMenu(label)
                              }}>
                              <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: subIcon}}></span>
                              <span className="nav-label">{subLabel}</span>
                              {counter && this.getCounter(subLabel.toLowerCase())}
                            </Link>
                          </li>
                          </WithSilo>
                          </WithPermission> 
                        
                          :
                          <WithSilo key={subLabel}  siloCodes={siloCodes}>
                              <li className={this.activeRoute(subRoute)}>
                          <Link to={subRoute} onClick={(e) => {
                              e.stopPropagation();
                              this.toggleMenu(label)
                            }}>
                            <span className="menu-nav-icon" dangerouslySetInnerHTML={{__html: subIcon}}></span>
                            <span className="nav-label">{subLabel}</span>
                            {counter && this.getCounter(subLabel.toLowerCase())}
                          </Link>
                          </li>                              
                          </WithSilo>
                        ))}
                    </ul>
                </li>
              )
            ))}
          </ul>
        </nav>
      );
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats
    }
}


export default connect(mapStateToProps)(withRouter(Navigation))
