import React from 'react';
import { connect } from 'react-redux';
import { sumBy } from 'lodash';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import settings from './SectionHeader.json';
import AbsoluteValue from '../AbsoluteValue';

var searchSvg = require('!svg-inline-loader!../../../../public/img/search.svg');
const defaultSearchEntity = "Artist or Imprint"

class SectionHeader extends React.Component {

    constructor(props){
        super(props);
        this.reset = this.reset.bind(this);
    }
    
    reset() {
        const searchField = document.getElementsByClassName('search-field')[0],
            nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set,
            ev = new Event('input', { bubbles: true});
        
        nativeInputValueSetter.call(searchField, '');
        searchField.dispatchEvent(ev);    
    }

    sumByKey(key){
        return sumBy(this.props.data.filter(item=>item.id), key)
    }

    render() {
        const searchEntity = this.props.searchEntity || defaultSearchEntity,
            showExtras = this.props.view && this.props.view == 'table';
        return <div className="column ibox-content section-header">
            <h2 className="ibox-title__title"> 
                <span className="title-text">{this.props.label}</span>
            </h2>
            {showExtras && this.props.children}
            {/*<div className="rates-holder">
                {this.props.entity && settings[this.props.entity].map(key=>{return key.key.endsWith('listeners') ? null : <div key={key.key} className="description up rate-item"><span className="icon fa"></span><span className="lowercase rate-num">{convertToThousands(this.sumByKey(key.key))}</span>{key.label}
                </div>})}
            </div>*/}
            {false && <div className="search-holder">
                <span className="search-icon" title="Search" dangerouslySetInnerHTML={{__html: searchSvg}}></span>
                <input className="pull-right search-field" type="text" name="search" value={this.props.filtered} onChange={this.props.onChange} placeholder={`Search by ${searchEntity }`} />
                <span className="pull-right clear-icon fa fa-backspace" title="Reset Search" onClick={this.reset}></span>
            </div>}
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader)
