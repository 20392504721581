import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import { splitByThousands } from '../../../helpers/NumberFormatter';

import config from '../../../config/config';
import Box from '../Box';
import TrendDirection from '../../common/TrendDirection';
import { statsActions } from '../../../data/actions/stats';
import { BarChart, LineChart} from '../../widgets/charts/types';
import { demographicsSettings } from './Demographics/Settings';
import VendorLogo from "../VendorLogo";
import { dspLogos } from "../../common/Stats/utils";
import ModeSelect from '../ModeSelect';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';

var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class DemographicsStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          mode: props.defaultMode || 'bar',
          logoDataFiltered: [],
          defaultSorted: [{ id: "curr_units", desc: false }],
          //            sort: this.options[0]
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setChartMode(mode){
        this.setState({mode});

        if (mode == "line") 
            this.getTimeseries();
    }
    
    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(statsActions.getDemographicsStats(this.props.entity, this.props.ids))
        
        if (this.props.filter.global) {
          if (this.props.filter.global !== undefined) {
            this.setState({
              logoDataFiltered: chartType
                ? dspLogos[chartType].data.filter((l) =>
                    this.props.filter.global.vendors.includes(l)
                  )
                : [],
            });
          }
        }
    }
    
    componentWillReceiveProps(nextProps) {
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(statsActions.getDemographicsStats(this.props.entity, this.props.ids))
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    logoDataFiltered: chartType
                      ? dspLogos[chartType].data.filter((l) =>
                          globalFilter.vendors.includes(l)
                        )
                      : [],
                  };
                });
            }                
        }
    }

    getTimeseries() {
        this.props.dispatch(statsActions.getDemographicsTimeseries(this.props.entity, this.props.ids))
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('details_demographics', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.stats.demographics.table, {filename});
    }
    
    renderToolbar(modes){
        let toolbar = [];

        let options = [
            {icon: barSvg, value: 'bar', label: "Bar Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: pieSvg, value: 'pie', label: "Donut Chart"},
            // {icon: lineSvg, value: 'line', label: "Line Chart"},
        ];

        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
            <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
        </div>
        </div>)
        return toolbar;
    }  
    
    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    getTrendForGender(data, gender) {
        return maxBy(data.filter(entry=>entry.gender==gender), 'curr_units');
    }
    
    renderTrends(data, single) {
        if(!data || !data.length)
            return null;
        
        const trends = [this.getTrendForGender(data, 'Male'), this.getTrendForGender(data, 'Female')],
            topTrend = maxBy(trends, 'share'); 
        return (topTrend && <div>
            <h2 className="content-title capitalize">{topTrend.gender}s, {topTrend.age}</h2>
            <h4 className="content-subtitle"> is your best performing demographic.</h4>
            {trends.map(trend=>{trend && <TrendDirection key={trend.gender} direction="up">
                <p className={trend.gender}>The most popular age demographic for <span className="gender">{trend.gender}s</span> is <span className="num">{trend.age} years</span>.</p>
            </TrendDirection>})}  

            
        </div>);                        
    }

    renderVendorLogos() {
        const { userData, chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];
        const getIsHidden = (el) => chartType === dspLogos.age.id && el === 'Apple Music' && userData.user.primary_silo_code === 'con';

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                !getIsHidden(l) && <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    
    formatGradient(data) {
        const items = data.datasets;
        if(items){
            for(let item of items) {
            }
        }
        return data;
    }
    
    render (){
        const stats = this.props.stats,
            trends = config.showTrends;

        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        return <Box title="Age and Gender" mobileTitle=" " mobileClassName="age-mobile" toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.demographicsLoading} exportToCsv={this.renderExportToCsv()}>
            {this.props.stats.demographics && <div className="row">
                {trends && <div className="col-sm-5 col-xs-12">
                    {this.renderTrends(stats.demographics.table)}
                </div>}
                <div className={`col-xs-12 col-sm-${trends?7:12}`}>
                    {this.state.mode=='bar' && <div className="chart-block">
                        <BarChart data={this.formatGradient(this.props.stats.demographics.chart)} {...shadowChartProps} options={{
                            legend: {display: false, labels: {usePointStyle: true}, position: "bottom"}, 
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    stacked: true
                                },
                                y: {
                                    stacked: true,
                                    "ticks": {
                                        callback: function formatAxesLabel(value, index, values){
                                            return splitByThousands(value);
                                        }
                                    }

                                }
                            },  
                            plugins: {
                                tooltip: {
                                    displayColors: false,
                                    callbacks: {
                                        title: function(context) {
                                            const { label, dataset } = context[0];
                                            return `${dataset.stack} ${label}`;
                                        },
                                        label: function(context) {
                                            const { dataset, dataIndex } = context;
                                            const active = dataset.active[dataIndex];
                                            const passive = dataset.passive[dataIndex];
                                            const total = active + passive;
                                            return [Math.round(active / total * 100) + '% Streams from active listeners',  
                                                Math.round(passive / total * 100) + '% Streams from passive listeners']; 
                                        }
                                    }
                                },
                                legend: {
                                    display: true, 
                                    labels: {usePointStyle: true}, 
                                    position: "bottom",
                                    labels: {
                                        filter: (item, data) => {
                                            return !(item.text.includes('('));
                                        }
                                    }
                                }
                            }
                        }} shadowChartOptions={{
                            plugins:{
                                legend:{
                                    labels: {
                                        filter: (item, data) => {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }} />                          
                    </div>}
                    {/* {this.state.mode=='line' && <div className="chart-block">
                        <LineChart data={this.props.stats.demographicsTimeseries} options={{legend: {position: "bottom"}}}/>                          
                    </div>}                     */}
                    {this.state.mode=='table' && <div className="demographics-table territory-table--single custom-scroll">
                        <ReactTable
                            className="playlist-table simple-table"
                            data={this.props.stats.demographics.table}
                            columns={demographicsSettings.settingsForTable('details')}
                            defaultPageSize={10}
                            showPagination={true}
                            defaultSorted={this.state.defaultSorted}
                            //getTrProps={demographicsSettings.settingsForRow}
                    /></div>}
                    {this.state.mode=='pie' && <div className="chart-block">
                        <DoughnutChart data={this.props.stats.demographics.pie} {...shadowChartProps}  addPercentage={false} options={{
                            plugins: {
                                legend: {
                                    labels: {
                                        filter: (item, data) => {
                                            return !(item.text.includes('('));
                                        }
                                    }
                                }
                            }
                        }} shadowChartOptions={{
                            plugins:{
                                legend:{
                                    labels: {
                                        filter: (item, data) => {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }}/>
                    </div>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter,
        userData: state.user
    } 
}

export default connect(mapStateToProps)(DemographicsStats);