import { campaignsConstants } from '../constants/campaigns';

export function campaigns(state = {campaign: {}}, action) {
    switch (action.type) {
        case campaignsConstants.GET_CAMPAIGNS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case campaignsConstants.GET_CAMPAIGNS_SUCCESS:
            return {
                
                ...state,
                loading: false,
                items: action.campaigns
            };
        case campaignsConstants.GET_CAMPAIGNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case campaignsConstants.GET_CAMPAIGN_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case campaignsConstants.GET_CAMPAIGN_DETAILS_SUCCESS:
            let campaign = state.campaign;
            campaign[action.id] = action.campaign;
            return {
                ...state,
                loading: false,
                campaign
            };
        case campaignsConstants.GET_CAMPAIGN_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case campaignsConstants.ADD_CAMPAIGN_REQUEST:
            return {
                ...state,
                campaignLoading: true
            };
        case campaignsConstants.ADD_CAMPAIGN_SUCCESS:
            return {
                
                ...state,
                campaignLoading: false,
            };
        case campaignsConstants.ADD_CAMPAIGN_FAILURE:
            return {
                ...state,
                campaignLoading: false,
            };

        case campaignsConstants.ADD_CHANNEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case campaignsConstants.ADD_CHANNEL_SUCCESS:
            return {
                
                ...state,
                loading: false,
            };
        case campaignsConstants.ADD_CHANNEL_FAILURE:
            return {
                ...state,
                loading: false,
            };
            
        default:
            return state
    }
} 