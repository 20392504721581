import moment from 'moment';
import { sharedPagesConstants } from '../constants/shared_pages';
import { sharedPagesFormatter } from '../formatters/shared_pages';
import { sharedPagesService } from '../services/shared_pages';
import { userEntityFiltersFormatter } from '../formatters/user_entity_filters'

export const sharedPagesActions = {
    share,
    shareFilter,
    getSharedPages,
    deleteSharedPage
};

function share(entity, id, module = 'details', params = '', title = '', expiresAt) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global,
            currentUser = getState().user.user;
        
        const userData = sharedPagesFormatter.formatSharedUser(Object.assign({}, currentUser));

        return sharedPagesService.share(entity, id, module, params, title, expiresAt, userData, globalFilter)
            .then(
                page => {
                    dispatch(success(page));
                    return page;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: sharedPagesConstants.SHARE_REQUEST } }
    function success(page) { return { type: sharedPagesConstants.SHARE_SUCCESS, page } }
    function failure(error) { return { type: sharedPagesConstants.SHARE_FAILURE, error } }
}

function getSharedPages() {
    return ( dispatch, getState ) => {
        dispatch(request());
        let currentUser = getState().user.user;

        sharedPagesService.getSharedPages()
            .then(
                pages => {
                    pages = sharedPagesFormatter.formatSharedPages(pages, currentUser);
                    dispatch(success(pages));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: sharedPagesConstants.GET_SHARED_PAGES_REQUEST } }
    function success(pages) { return { type: sharedPagesConstants.GET_SHARED_PAGES_SUCCESS, pages } }
    function failure(error) { return { type: sharedPagesConstants.GET_SHARED_PAGES_FAILURE, error } }
}

function deleteSharedPage(id, refresh = true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return sharedPagesService.deleteSharedPage(id)
            .then(
                page => {
                    dispatch(success());
                    if(refresh)
                        dispatch(getSharedPages());
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: sharedPagesConstants.DELETE_SHARED_PAGE_REQUEST } }
    function success() { return { type: sharedPagesConstants.DELETE_SHARED_PAGE_SUCCESS } }
    function failure(error) { return { type: sharedPagesConstants.DELETE_SHARED_PAGE_FAILURE, error } }
}

function shareFilter(filter, globalFilter, currentFilter) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let currentUser = getState().user.user;
        const userData = sharedPagesFormatter.formatSharedUser(Object.assign({}, currentUser));
        let globalFilterParams = {};
        if(globalFilter) {
            globalFilterParams = globalFilter.filter_body;
        }
        else {
            let fieldsFromCurrent = ['dateEnd', 'dateStart', 'prevDateEnd', 'prevDateStart', 'limit'];
            for(let key of fieldsFromCurrent) {
                globalFilterParams[key] = currentFilter[key];
            }
        }
        const filterParams = userEntityFiltersFormatter.stringifyFilter(filter); 
        const title = `Auto Shared Page for Filter ${filter.id}`;
        const expiresAt = moment().add(120, 'month').format('YYYY-MM-DD');

        return sharedPagesService.share('filter', filter.id, 'filter', '', title, expiresAt, userData, globalFilterParams)
            .then(
                page => {
                    dispatch(success(page));
                    return page;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: sharedPagesConstants.SHARE_REQUEST } }
    function success(page) { return { type: sharedPagesConstants.SHARE_SUCCESS, page } }
    function failure(error) { return { type: sharedPagesConstants.SHARE_FAILURE, error } }

}