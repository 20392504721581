import { errorConstants } from '../constants/error';

export function error(state = {}, action) {
    switch (action.type) {
        case errorConstants.SET_SUCCESS_MESSAGE:
            return {
                ...state,
                type: 'success',
                title: action.title,
                message: action.message
            };
    
        case errorConstants.SET_ERROR_MESSAGE:
            return {
                ...state,
                type: 'error',
                title: action.title,
                message: action.message
            };
        case errorConstants.CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                message: null,
                type: null, 
                title: null
            };            
        default:
            return state
    }
} 