import React from 'react';
import { map } from 'lodash';

class TableChart extends React.Component {
    renderTableElements() {
        return this.props.data.map((item, index)=>{
            const rowClass = index % 2 == 0 ? "gradeX" : "gradeC";
            return <tr className={rowClass}>
                <td>{item.title}</td>
                <td>{item.value}</td>
            </tr>;
        });
    }
    render() {
        return (
            <table className="table table-striped no-margins table-hover" >
                <thead>
                <tr>
                    <th>{this.props.labels.titleLabel}</th>
                    <th>{this.props.labels.valueLabel}</th>
                </tr>
                </thead>
                <tbody>
                    {this.renderTableElements()}
                </tbody>
            </table>    
        )
    }
}

export default TableChart