import React from 'react';
import { map } from 'lodash';
import moment from 'moment';
import Box from '../../../common/Box';
import { PieChart } from '../../../widgets/charts/types'

export default function EntityTimeline(props){
    let labels = [], 
        datasets = [], 
        { results, mode = 'line' } = props,
        legendThreshold = 10,
        legendPosition = 'right';
        

    if(results && results.length) {
        datasets = [{ data : results.map(entity=>entity.curr_units), label: 'streams'}];
        labels = results.map(entity=>entity.name);
        legendPosition = datasets.length > legendThreshold ? 'bottom' : 'right';
    }
    
    return datasets.length ? <PieChart data={{labels, datasets}} options={{legend: {display: true, position: legendPosition}}}/> : null  ;
}
