import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import PDFHeader from './PDFHeader';
import { convertToThousands, addPercent, splitByThousands } from '../../../helpers/NumberFormatter';

const renderMetrics=(data)=>{
  const columns=[
     {key: 'name', title: 'Title', width: 25, fontSize: 5},
     {key: 'artist', title: 'Artist', width: 25, fontSize: 5},
     {key: 'isrc', title: 'ISRC', width: 15, fontSize: 5},
     {key: 'views', title: 'Views', width: 7, formatFn: convertToThousands, fontSize: 5},
     {key: 'creates', title: 'Creates', width: 7, formatFn: convertToThousands, fontSize: 5},
     {key: 'likes', title: 'Likes', width: 7, formatFn: convertToThousands, fontSize: 5},
     {key: 'shares', title: 'Shares', width: 7, formatFn: convertToThousands, fontSize: 5},
     {key: 'saves', title: 'Saves', width: 7, formatFn: convertToThousands, fontSize: 5},
 ];
     
  return <Table
      columns={columns}
      items={data}
  />
}

const renderTerritories=(data)=>{
  const columns=[
     {key: 'country', title: 'Country', width: 40, fontSize: 5},
     {key: 'views', title: 'Views', width: 15, formatFn: convertToThousands, fontSize: 5},
     {key: 'prev_views', title: '(Change)', width: 15, formatFn: splitByThousands, fontSize: 5},
     {key: 'creates', title: 'Creates', width: 15, formatFn: convertToThousands, fontSize: 5},
     {key: 'prev_creates', title: '(Change)', width: 15, formatFn: splitByThousands, fontSize: 5},
 ];
     
  return <Table
      columns={columns}
      items={data}
  />
}

export default (props) => {
    const { data: { images, tables, metadata, overview, reportTitle, globalFilter, entity, header }, widgetProps: { widgetState } } = props;
  return <Document>
    <Page size="A4" style={styles.page}>
      <PDFHeader header={header} />
      <View style={styles.section}>
          <OverviewPDF data={header.overview} />
      </View>
      {images.audience_tiktok_line_chart && <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Social Creates and Views</Text>
            <Image source={images.audience_tiktok_line_chart} />
          </View>
      </View>}
      </Page>
      <Page size="A4" style={styles.page}>
        {images.audience_tiktok_pie_chart && <View style={styles.section}>
            <View style={styles.column}>
              <Text style={styles.smallHeader}>Platforms</Text>
              <Image source={images.audience_tiktok_pie_chart} />
            </View>
        </View>}
        
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Social Metrics</Text>
            {renderMetrics(tables.metrics)}
          </View>
        </View>      
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Social Territories</Text>
            {widgetState.tiktok_territories.mode == 'line' && <Image source={images.audience_tiktok_territories_line_chart} /> }
            {widgetState.tiktok_territories.mode != 'line' && renderTerritories(tables.territories)}
          </View>
        </View>           
      </Page>
  </Document>
};