import countriesByAbbreviation from 'country-json/src/country-by-abbreviation.json';
import { cloneDeep } from 'lodash';

let countriesByAbbreviationCopy = cloneDeep(countriesByAbbreviation)

countriesByAbbreviationCopy.push({
    "country": "Unknown",
    "abbreviation": "ZZ"     
}, {
    "country": "Taiwan",
    "abbreviation": "TW"         
});

countriesByAbbreviationCopy = countriesByAbbreviationCopy.map(obj => {
    if (obj.abbreviation === 'GB') {
        return {...obj, country: 'United Kingdom'};
    }

    return obj;
}).filter(obj=>obj.abbreviation != 'UK').sort((a, b) => {
    let countryA = a.country;
    let countryB = b.country;
    return (countryA < countryB) ? -1 : (countryA > countryB) ? 1 : 0;
});

export default countriesByAbbreviationCopy;