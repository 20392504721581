export const notificationReportsConstants = {
  GET_NOTIFICATION_REPORTS_REQUEST: 'GET_NOTIFICATION_REPORTS_REQUEST',
  GET_NOTIFICATION_REPORTS_SUCCESS: 'GET_NOTIFICATION_REPORTS_SUCCESS',
  GET_NOTIFICATION_REPORTS_FAILURE: 'GET_NOTIFICATION_REPORTS_FAILURE',
  GET_NOTIFICATION_SETTINGS_REQUEST: 'GET_NOTIFICATION_SETTINGS_REQUEST',
  GET_NOTIFICATION_SETTINGS_SUCCESS: 'GET_NOTIFICATION_SETTINGS_SUCCESS',
  GET_NOTIFICATION_SETTINGS_FAILURE: 'GET_NOTIFICATION_SETTINGS_FAILURE',
  GET_FREQUENCIES_REQUEST: 'GET_FREQUENCIES_REQUEST',
  GET_FREQUENCIES_SUCCESS: 'GET_FREQUENCIES_SUCCESS',
  GET_FREQUENCIES_FAILURE: 'GET_FREQUENCIES_FAILURE',
  UPDATE_USER_NOTIFICATION_REPORTS_REQUEST: 'UPDATE_USER_NOTIFICATION_REPORTS_REQUEST',
  UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS: 'UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS',
  UPDATE_USER_NOTIFICATION_REPORTS_FAILURE: 'UPDATE_USER_NOTIFICATION_REPORTS_FAILURE',
}
