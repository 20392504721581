import React, { Component } from 'react';

class TabContainer extends Component {
        
    constructor(props) {
        super(props);
        this.state = {
            currentTabIndex: 0
        }
        this.setTabIndex = this.setTabIndex.bind(this);
    }
    
    setTabIndex(currentTabIndex) {
        this.setState({currentTabIndex});
    }
    
    renderTabs() {
        const { tabs: tabsConfig } = this.props,
            { currentTabIndex } = this.state; 
        
        let tabs = [];
        for(let tabIndex in tabsConfig) {
            const tab = tabsConfig[tabIndex]
            tabs.push(<span className={`tab${tabIndex == currentTabIndex ? ' active' : ''}`} onClick={()=>this.setTabIndex(tabIndex)}>{tab.title}</span>);
        }
        return tabs;
    }
    
    render() {
        const { components, tabs, widgetMode } = this.props,
            { currentTabIndex } = this.state,
            currentTab = tabs[currentTabIndex];
            
        if(!currentTab)
            return null;
        
        let { component: componentName, props: componentProps } = currentTab;
        componentProps.widgetMode = widgetMode;
        const Component = components[componentName];
        
        return <div className="tabs-content">
            <div className="tab-container">
                {this.renderTabs()}
            </div>
            <div className="tab-content">
                <Component id={currentTabIndex} {...componentProps} />
            </div>
        </div>
    }
}

export default TabContainer;