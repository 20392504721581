import React from 'react';
import { Link } from 'react-router-dom';

class ArtistsList extends React.Component {
    render (){
        const { artists } = this.props;
        return artists.length ? <span>By: <ul className="">
        {artists.map(artist=><li key={artist.id}>{artist.name}</li>)}
        </ul></span> : null;
    }
}

export default ArtistsList;