import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import EntityTablePDF from './EntityTable/PDF';
import TerritoryTablePDF from './Territories/PDF';
import VendorTablePDF from './Vendor/PDF';

export default (props) => {
    const { data: { images, tables, reportTitle, overview, grid, globalFilter } } = props;
    const allViews = {
            "top_imprints": <View style={styles.columnSection}>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Imprints</Text>
                    <EntityTablePDF entity="imprints" data={tables.imprints} />
                </View>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Imprints</Text>
                    <Image source={images.top_imprints} />
                </View>
            </View>,
            "top_artists": <View style={styles.columnSection}>
                <View style={styles.column}>
                  <Text style={styles.smallHeader}>Top Artists</Text>
                  <EntityTablePDF entity="artists" data={tables.artists} />
                </View>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Artists</Text>
                    <Image source={images.top_artists} />
                </View>
            </View>,
            "top_products": <View style={styles.columnSection}>
                <View style={styles.column}>
                  <Text style={styles.smallHeader}>Top Products</Text>
                  <EntityTablePDF entity="products" data={tables.products} />
                </View>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Products</Text>
                    <Image source={images.top_products} />
                </View>
          </View>,
          "top_tracks": <View style={styles.columnSection}>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Tracks</Text>
                    <EntityTablePDF entity="tracks" data={tables.tracks} />
                </View>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Tracks</Text>
                    <Image source={images.top_tracks} />
                </View>
          </View>,
          "top_playlists": <View style={styles.columnSection}>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Playlists</Text>
                    <EntityTablePDF entity="playlists" data={tables.playlists} />
                </View>
                <View style={styles.column}>
                    <Text style={styles.smallHeader}>Top Playlists</Text>
                    <Image source={images.top_playlists} />
                </View>
          </View>,
          "top_territories": <View style={styles.columnSection}>
              <View style={styles.column}>
                  <Text style={styles.smallHeader}>Territories</Text>
                  <TerritoryTablePDF data={tables.territories} />
              </View>
              <View style={styles.column}>
                <Text style={styles.smallHeader}>Territories</Text>
                <Image source={images.top_territories} />
              </View>
          </View>,
          "top_vendors": <View style={styles.columnSection}>      
              <View style={styles.column}>
                <Text style={styles.smallHeader}>Platforms</Text>
                <Image source={images.home_vendors} />
              </View>
              <View style={styles.column}>
                <Text style={styles.smallHeader}>Platforms</Text>
                <VendorTablePDF data={tables.vendorsTable} />
              </View>
          </View>
    };
    let views = [];
    for(let item of grid) {
        
        views.push(allViews[item.i]);
    }
  return <Document>
    <Page size="A4" style={styles.page}>
        <View style={styles.reportTitle}>
            <Text style={styles.header}>{reportTitle}</Text>
        </View>
        <DateRange filter={globalFilter} />
        <View style={styles.section}>
            <OverviewPDF data={overview} />
        </View>    
        {views}
    </Page>
  </Document>
};