export const catalogueExceptionsConstants = {
    GET_CATALOGUE_EXCEPTIONS_REQUEST: 'GET_CATALOGUE_EXCEPTIONS_REQUEST',
    GET_CATALOGUE_EXCEPTIONS_SUCCESS: 'GET_CATALOGUE_EXCEPTIONS_SUCCESS',
    GET_CATALOGUE_EXCEPTIONS_FAILURE: 'GET_CATALOGUE_EXCEPTIONS_FAILURE',
    GET_CATALOGUE_EXCEPTIONS_COUNT_REQUEST: 'GET_CATALOGUE_EXCEPTIONS_COUNT_REQUEST',
    GET_CATALOGUE_EXCEPTIONS_COUNT_SUCCESS: 'GET_CATALOGUE_EXCEPTIONS_COUNT_SUCCESS',
    GET_CATALOGUE_EXCEPTIONS_COUNT_FAILURE: 'GET_CATALOGUE_EXCEPTIONS_COUNT_FAILURE',
    GET_CATALOGUE_EXCEPTIONS_UPDATE_REQUEST: 'GET_CATALOGUE_EXCEPTIONS_UPDATE_REQUEST',
    GET_CATALOGUE_EXCEPTIONS_UPDATE_SUCCESS: 'GET_CATALOGUE_EXCEPTIONS_UPDATE_SUCCESS',
    GET_CATALOGUE_EXCEPTIONS_UPDATE_FAILURE: 'GET_CATALOGUE_EXCEPTIONS_UPDATE_FAILURE',
}