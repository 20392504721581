import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import {Helmet} from 'react-helmet';
import parser from 'url-parse';

class Landing extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const { user, loggedIn } = this.props.user;
        let path;
        if(loggedIn) {
            if(user.landing == 'overview')
                path = 'overview';
            else
                path = 'main';
        }
        else
            path = 'login';
        
        return <Redirect to={`/${path}`} />
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    } 
}


export default connect(mapStateToProps)(withRouter(Landing));