import React from 'react';
export default function Spinner(props){
    const root = "spinner";
    const styles = props.className ? props.className : root;
    return props.enabled ? <div className={styles}>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div> : null;
}
