import React from 'react';
import { Link } from 'react-router-dom';

class TagsList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: false,
            limit: 3
        }
        
        this.toggleExpand = this.toggleExpand.bind(this);
    }
    
    toggleExpand(){
        this.setState({
            expanded: !this.state.expanded
        })
    }
    
    render (){
        if(!this.props.tags)
            return null;
        
        let tags = [],
            numberOfTags = this.state.expanded ? this.props.tags.length : Math.min(this.state.limit, this.props.tags.length); 
        for(let index = 0; index < numberOfTags; index++) {
            let tag = this.props.tags[index];
            tags.push(<li key={tag.tag_id} className="tag"><Link to={`/playlist/tags/${tag.tag_id}`}>{tag.tag}</Link></li>)
        }
        return tags ? <ul className="tag-list">
            {tags}
            {this.props.tags.length > this.state.limit && <li key="expand" className="more-tag">
                <a onClick={this.toggleExpand} className="more-tag-link">{this.state.expanded ? 'less' : 'more'}</a>
            </li> }
        </ul> : null;
    }
}

export default TagsList;