import React, { Component } from 'react';

class NotificationCount extends React.Component {
  render() {
    return this.props.notifications.count !== 0 && (
      <div className="notification-count">
        {this.props.notifications.count}
      </div>
    );
  }
}

export default NotificationCount;
