export const dspLogos = {
  sources: {
    id: "sources",
    data: ["Spotify", "Apple Music", "Amazon", "Deezer", "Pandora", "SoundCloud", "YouTube"],
  },
  age: {
    id: "age",
    data: ["Spotify", "Apple Music", "Deezer", "Pandora", "SoundCloud"],
  },
  territories: {
    id: "territories",
    data: ["Spotify", "Apple Music", "Amazon", "Deezer", "Pandora", "SoundCloud", "YouTube"],
  },
  devices: {
    id: "devices",
    data: ["Spotify", "Apple Music", "Amazon", "Deezer", "Pandora", "SoundCloud", "YouTube"],
  },
};