import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { capitalize, map } from 'lodash';
import PercentageValue from '../../common/PercentageValue';
import AbsoluteValue from '../../common/AbsoluteValue';
import VendorLogo from '../../common/VendorLogo';
import VendorHelper from '../../common/Datagrid/VendorHelper';
import FieldsSelectorHelper from '../../common/Datagrid/FieldsSelectorHelper';
import VendorValue from '../../common/Datagrid/VendorValue';
import PlaylistAnalysisLink from '../../common/Datagrid/PlaylistAnalysisLink';
import AudienceAnalysisLink from '../../common/Datagrid/AudienceAnalysisLink';
import EntityStatus from '../../common/Datagrid/EntityStatus';
import DetailsLink from '../../common/Datagrid/DetailsLink';
import AccountSplitBox from '../../common/Datagrid/AccountSplitBox';
import AudioFormatSplitBox from '../../common/Datagrid/AudioFormatSplitBox';
import Tooltip from '../../common/Tooltip';
import EntityCard from '../../common/EntityCard';
import MultiEntityCard from '../../pages/result/EntityCard';
import SharedPageLink from './SharedPageLink';
import ConfirmClick from '../../common/ConfirmClick';


var heartFullSvg = require('!svg-inline-loader!../../../../public/img/heart-full.svg');
var ghostFullSvg = require('!svg-inline-loader!../../../../public/img/ghost-full.svg');
var expandVendorSvg = require('!svg-inline-loader!../../../../public/img/expand-vendor.svg');

export const sharedPagesSettings = {
    settingsForTable
};

function renderLimitedString (items, limit) {
    const overLimit = items.length - limit;
    let title = items.slice(0, limit).join(', ');
    
    if(overLimit > 0)
        title += ` + ${overLimit} more`;

    return title;
}

function renderCaption(row){
    switch(row.module) {
        case 'home':
            return <span>Overview Page</span>;
        case 'reporting':
            return <span>{capitalize(row.entity)}</span>;            
        case 'filter':
            let caption = 'Details';
            if(row.entity == 'filter' && row.entity_id)
                caption = 'Saved Filter';
            return <span>{caption}</span>
        case 'track_comparison':
            return <span>{capitalize(row.entity)} Comparison</span>
        default:
            return <EntityCard entity={row.entity} id={row.entity_id} flat={true} />
    }
} 

function renderSummary(page) {
    
    if(page.module == 'track_comparison') {
        const key = page.entity == 'products' ? 'upcs' : 'isrcs';
        const filter = {[key]: page.entity_id.split(',')};
        return <MultiEntityCard filtered={JSON.stringify(filter)} /> 
    }
    else if(page.module == 'filter') {
        if(page.params && !page.entity_id) {
            return <MultiEntityCard filtered={page.params} />
        }
        else if(page.entity_id && page.entities) {
            const titles = map(page.entities, 'entity_title');
            return <span>{renderLimitedString(titles, 3)}</span>
        } 
        else {
            return <span>filter details unavailable</span>
        }
    }
    else {
        return <span>{page.summary || 'N/A'}</span>
    }

    
}
function settingsForTable(deleteSharedPage){

    let columns = [
        {
           id: 'title',
           Header: 'Title',
           accessor: 'title',
           width: 250
        },                   
        {
            id: 'entity',
            Header: 'Type',
            accessor: 'entity_id',
            sortable: false,
            Cell: props => renderCaption(props.original),
            width: 150
        },
        
        {
            id: 'created_at',
            Header: 'Created',
            accessor: 'created_at',
            Cell: props => moment(props.value).format('YYYY-MM-DD'),
            width: 100,
        },
        {
            id: 'summary',
            Header: 'Summary',
            sortable: false,
            accessor: 'summary',
            Cell: props => renderSummary(props.original)
        },
        {
            id: 'views',
            Header: 'Views',
            sortable: false,
            accessor: 'views',
            width: 30,
        },        
        {
            id: 'links',
            Header: 'Actions',
            headerClassName: 'no-sort',
            accessor: 'slug',
            sortable: false,
            Cell: props => <div className="action-icons">
                <SharedPageLink slug={props.value} justCopy={true} />
                <ConfirmClick confirmAction={()=>deleteSharedPage(props.original.id)} confirmClass="btn add-to-filter" confirmLabel={<i className="fas fa-trash-alt"></i>} extraClass="dialog-modal" confirmPrompt="Delete shared page?"></ConfirmClick>
            </div>,
            width: 130
            // width: 105
        }
    ];
                    
    return columns; 
}
