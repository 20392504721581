import React from 'react';
import { connect } from 'react-redux';
import Toastr from './Toastr';

import { errorActions } from '../../data/actions/error';

class ErrorBox extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            message: null,
            title: null,
            type: null
        }
    }
    
    componentWillReceiveProps(props){
        if(props.error.message!==undefined && props.error.message!=this.state.message){
            this.setState({
                message: props.error.message,
                title: props.error.title,
                type: props.error.type
            }, ()=>(props.error.message && this.props.clear()))
        }
    }
    
    render() {
        const timeout = this.state.type == 'success' ? 4000 : 0;
        const options = {timeOut : timeout, extendedTimeOut: timeout};
        return (this.state.message ? <Toastr method={this.state.type} title={this.state.title} message={this.state.message} options={options} />: null)
    }
}

function mapStateToProps(state) {
    return {
        error: state.error
    } 
}

function mapDispatchToProps(dispatch) {
    return {
        clear: ()=>dispatch(errorActions.clearErrorMessage())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ErrorBox) 