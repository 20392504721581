import React from 'react';
import { Pie } from 'react-chartjs-2';
import { map, merge, sum, cloneDeep } from 'lodash';
import { colors, pie } from '../ChartDefaults';

class PieChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            options: merge({}, pie, props.options)
        };
    }
    render() {
        let chartData = cloneDeep(this.props.data);
        
        const dataReady = chartData && chartData.datasets;
            
        if(dataReady) {
            const dataset = chartData.datasets[0].data;
            const total = sum(dataset);
            for(let index in dataset) {
                const percent = total ? Math.round(dataset[index]/total*100) : 0;
                chartData.labels[index] = `${chartData.labels[index]} (${percent}%)`; 
            }
        }
            
        const { data, options, id, shadowChart = false, shadowChartClass = 'chart-export-shadow', shadowChartWidth = 400, shadowChartHeight = 300, ...rest } = this.props;
        return (dataReady ? <React.Fragment> 
            <Pie data={chartData} options={this.state.options} {...rest} ></Pie>
            {shadowChart && <div className={shadowChartClass}><Pie width={shadowChartWidth} height={shadowChartHeight} id={id} data={chartData} options={this.state.options} {...rest}></Pie></div>}
        </React.Fragment> : null);
    }
}

export default PieChart