import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { isEqual } from 'lodash';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import Spinner from '../components/common/Spinner';
import DataProvider from '../components/pages/result/DataProvider';
import Header from '../components/pages/result/Header';
import exportToCsv from '../components/pages/result/CSVFunctions';
import exportToPdf from '../components/pages/result/PDFFunctions';
import SharePage from '../components/pages/sharedPages/SharePage';
import ResultTour from '../components/pages/result/Tour';

import ResultOverview from './Result/Overview';
import ResultAudience from './Result/Audience';
import ResultPlaylists from './Result/Playlists';
import ResultProfile from './Result/Profile';
import ResultSocial from './Result/Social';

const downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../public/img/pdf.svg');


import { filterActions } from '../data/actions/filter'

class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInitialized: false,
            exportInProgress: false,
            title: null,
            savedFilterID: null
        }
        this.exportProgress = this.exportProgress.bind(this);
        this.setTitle = this.setTitle.bind(this);
    }
    
    componentDidMount(){
        const { match, dispatch } = this.props;
        const filter = JSON.parse(match.params.filter);
        this.setState({
            filter, 
            filterString: match.params.filter,
            filterInitialized: true
        });
//        dispatch(filterActions.appendEntityFilter(filter))
//        .then((filter)=>{console.log(filter); this.setState({filterInitialized: true})});
    }
    
    componentWillReceiveProps(nextProps) {
        const { match } = this.props,
            { match: nextMatch } = nextProps;
        
        if(!isEqual(match.params.filter, nextMatch.params.filter)) {
            this.setState({
                filterInitialized: false
            }, ()=>{
                const filter = JSON.parse(nextMatch.params.filter);
                this.setState({
                    filterInitialized: true,
                    filter,
                    filterString: nextMatch.params.filter
                });
            });
        }
    }
    
    getCurrentTab(){
        const { location } = this.props;
        return location.pathname.substr(location.pathname.lastIndexOf('/')+1);
    }
    
    exportProgress(exportInProgress){
        this.setState({exportInProgress})
    }
    
    setTitle(title, savedFilterID){
        this.setState({title, savedFilterID});
    }
        
    renderTabs(filterString, sharedMode = false){
        const share = sharedMode ? '/share' : '';
        return <ul className="profile-navigation">
            <NavLink className="profile-navigation-link" to={`${share}/filter/${filterString}/overview`}>Overview</NavLink>
            <NavLink className="profile-navigation-link" to={`${share}/filter/${filterString}/audience`}>Audience</NavLink>
            <NavLink className="profile-navigation-link" to={`${share}/filter/${filterString}/social`}>Social Music</NavLink>            
            <NavLink className="profile-navigation-link" to={`${share}/filter/${filterString}/playlists`}>Playlists</NavLink>
            <NavLink className="profile-navigation-link" to={`${share}/filter/${filterString}/profile`}>Milestones</NavLink>

        </ul>
    }
    

    render() {
        const { filterInitialized, filter, filterString, exportInProgress, savedFilterID } = this.state;
        const { sharedMode = false } = this.props;
        if(!filterInitialized)
            return null;
        
        let flatFilter = [];
        for(let entity of Object.keys(filter)) {
            //for(let id of filter[entity]) {
                flatFilter.push({id: entity, value: filter[entity]})
            //}
        }
        const exportProps = {
            ...this.props,
            entityFilter: this.state.filter,
            entityTitle: this.state.title
        }
        const sharePage = !sharedMode ? 
                <SharePage entity="filter" id={savedFilterID} params={JSON.stringify(filter)} module="filter" 
                    exportToCsv={()=>{exportToCsv(this.getCurrentTab(), exportProps, this.exportProgress)}} 
                    exportToPdf={()=>exportToPdf(this.getCurrentTab(), exportProps, this.exportProgress)} 
                    exportInProgress={exportInProgress} /> 
            	: <div className="download-data-holder">
                    <span>Export</span>
                    <a key="download" title="Download entire page" onClick={()=>{exportToCsv(this.getCurrentTab(), exportProps, this.exportProgress)}} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                    <span>PDF</span>
                    <a key="pdf" title="Download PDF" onClick={()=>exportToPdf(this.getCurrentTab(), exportProps, this.exportProgress)} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                </div>;

        return <div className="inner-page result-page">
            <DataProvider filtered={filter}>
                <div>
                    <Header filtered={filter} tabs={this.renderTabs(filterString, sharedMode)} filtered={filter} sharedMode={sharedMode} sharePage={sharePage} onTitle={this.setTitle}>
                        <CatalogOverview view="home" filtered={flatFilter} newClass="artist-overview" />
                        <ResultTour />
                    </Header>
                </div>
                <Switch>
                    <Route path="/filter/:filter/overview" render={(props)=><ResultOverview filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/filter/:filter/audience" render={(props)=><ResultAudience filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/filter/:filter/playlists" render={(props)=><ResultPlaylists filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/filter/:filter/profile" render={(props)=><ResultProfile filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/filter/:filter/social" render={(props)=><ResultSocial filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/share/filter/:filter/overview" render={(props)=><ResultOverview sharedMode={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/share/filter/:filter/audience" render={(props)=><ResultAudience sharedMode={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/share/filter/:filter/playlists" render={(props)=><ResultPlaylists sharedMode={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/share/filter/:filter/profile" render={(props)=><ResultProfile sharedMode={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Route path="/share/filter/:filter/social" render={(props)=><ResultSocial sharedMode={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
                    <Redirect exact from={"/share/filter/:filter"} to={`/share/filter/${filterString}/overview`} />
                    <Redirect exact from={"/filter/:filter"} to={`/filter/${filterString}/overview`} />
                </Switch>
            </DataProvider>
        </div>
            
    }
}

function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters,
        stats: state.stats,
        filter: state.filter,
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        tracks: state.tracks,
        playlists: state.playlists,
        audience: state.audience,
        milestones: state.milestones
    } 
}

export default connect(mapStateToProps)(Result)