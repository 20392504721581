import React, { Component } from 'react';
import ReactTable from "react-table";
import {cloneDeep, find, isEqual, findIndex} from 'lodash';
import Modal from '../../common/Modal';
import ReactSelect from '../../common/ReactSelect';

class FieldMapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mappedResults: [],
            hideExactMatches: true,
            hideMultipleMatches: true
        };
        this.mapResult = this.mapResult.bind(this); 
        this.toggleMatch = this.toggleMatch.bind(this);
    }
    
    /*
    mapResult(e, index, results) {
        let mappedResults = cloneDeep(this.state.mappedResults);
        mappedResults[index] = find(results, result=>result.id == e.target.value);
        this.setState({
            mappedResults
        });
    }
    */
    
    mapResult({value}, index) {
        let mappedResults = cloneDeep(this.state.mappedResults);
        mappedResults[index] = value; 
        this.setState({
            mappedResults
        });
    }    
    
    componentWillReceiveProps(nextProps) {
        //if(this.state.mappedResults !== null)
            //return;
        
        if(Array.isArray(nextProps.results) && nextProps.results.length && !isEqual(this.props.results, nextProps.results)) {
            const mappedResults = nextProps.results.map(({result}) => result[0]);
            this.setState({mappedResults});
        }
    }
    
    toggleMatch(index) {
        this.setState({[index]: !this.state[index]});
    }
    
    renderSummary(results) {
        const { mappedResults = {}} = this.state;
        const mappedResultsArray = Object.values(mappedResults);
        const exactMatches = results.filter(result => result.exactMatch);
        return <ul className="import-result-list">
            <li><b>Total Results: </b>{results.length}</li>
            <li><b>Exact Matches: </b>{exactMatches.filter(result=>result.result.length == 1).length}</li>
            <li><b>Multiple Matches: </b>{exactMatches.filter(result=>result.result.length > 1).length}</li>
            <li><b>No Match: </b>{results.filter(result=>!result.exactMatch).length}</li>
            <li><b>Will be Added: </b>{mappedResultsArray.filter(result=>(result && result.id)).length}</li>
            <li><b>Will be Skipped: </b>{mappedResultsArray.filter(result=>!(result && result.id)).length}</li>
        </ul>
    }
    
    render() {
        const { results, show, mapResults } = this.props;
        const { hideExactMatches, hideMultipleMatches } = this.state;
        const columns = [{
            id: 'query',
            accessor: 'query',
            className: 'with-margin',
            Header: 'Query',
            width: 200
        },{
            id: 'exactMatch',
            accessor: 'exactMatch',
            className: 'with-margin',
            Header: 'Exact Match',
            width: 80,
            Cell: (props) => props.value ? 'Y' : 'N',
        },{
            id: 'result',
            accessor: 'result',
            Header: 'Results',
            Cell: (props)=>{
                const {original: {result, importIndex}} = props;
                const { mappedResults } = this.state;
                const options = props.value.map(result=>({
                    label: result.name,
                    value: result
                }));
                const selected = find(options, option=>option.value.id == mappedResults[importIndex].id);
                return <ReactSelect value={selected} options={options} className="single-select single-select-full-width" onChange={(value)=>this.mapResult(value, importIndex )} />
            }
            /*
            Cell: (props)=>{
                const {index, value} = props;
                const { mappedResults } = this.state;
                return <select onChange={(e)=>this.mapResult(e, index, value)} defaultValue={mappedResults[index].id} >
                    {value.map(result=><option key={result.id} value={result.id} >{result.name}</option>)}
                </select>
            }
            */
        }];
        
        if(!Array.isArray(results))
            return null;
            
        let data = results;
        if(hideExactMatches)
            data = results.filter(result => !(result['multipleMatches']==1));    
        if(hideMultipleMatches)
            data = results.filter(result => result['multipleMatches']==false);
            
        return <Modal show={show} 
                title="Map Import Results" 
                showActionButtons={true}
                handleClose={()=>mapResults([])}
                handleSubmit={()=>{mapResults(this.state.mappedResults)}}
                dialogClassName=""
                submitTitle="Add To Filter"
            >
                {this.renderSummary(results)}
                <div className="checkbox-holder">
                    <input id="hide_exact_matches" type="checkbox" className="left-input" checked={hideExactMatches} onChange={()=>this.toggleMatch('hideExactMatches')} />
                    <label htmlFor="hide_exact_matches">Hide Exact Matches</label>
                </div>
                <div className="checkbox-holder">
                    <input id="hide_multiple_matches" type="checkbox" className="left-input" checked={hideMultipleMatches} onChange={()=>this.toggleMatch('hideMultipleMatches')} />
                    <label htmlFor="hide_multiple_matches">Hide Multiple Matches</label>
                </div>
                {(data.length > 0) && <ReactTable data={data} 
                    columns={columns} 
                    className="map-import-results"
                    pageSize={10}
                />}
            </Modal>
    }
}


export default FieldMapper;