import React, { Component } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import moment from 'moment';
import {Helmet} from 'react-helmet';

import Box from '../../components/common/Box';
import Spinner from '../../components/common/Spinner';
import FieldSelector from '../../components/common/Datagrid/FieldsSelector';
import PageFilter from '../../components/common/Datagrid/PageFilter';
//import Accordion from '../../components/common/Accordion'
import AuthHeader from '../../helpers/AuthHeader';
import UserLogs from './UserLogs/UserLogs';

import { catalogueExceptionsActions } from '../../data/actions/catalogue_exceptions';
import { userLogsActions } from '../../data/actions/user_logs';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';

class Statistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 1,
            total_page: 100,
            pageSize: 20,
            filtered: [],
            selected: {},
            selectAll: 0,
        }
        this.toggleRow = this.toggleRow.bind(this);
        this.loadData = this.loadData.bind(this);
        this.dispatchLoadData = this.dispatchLoadData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.dispatchLoadData(this.state);
    }

    loadData(page) {
        this.setState({
            ...this.state,
            page: page + 1
        }, () => this.dispatchLoadData(this.state))
    }

    dispatchLoadData(state){
        const { dispatch } = this.props;

        dispatch(catalogueExceptionsActions.getCatalogueExceptions({
            page: state.page,
            per_page: state.pageSize
        }));
        dispatch(catalogueExceptionsActions.getCatalogueExceptionsCount({
            tracks: state.count
        }));
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.catalogueExceptions && nextProps.catalogueExceptions.exceptions){
            const { pagination, tracks } = nextProps.catalogueExceptions.exceptions

            if (pagination && tracks) {
                const { page, per_page, total_page } = pagination
                
                this.setState({
                    data: tracks,
                    page,
                    per_page,
                    total_page
                })
            }

        }

        if(nextProps.catalogueExceptions && nextProps.catalogueExceptions.count){
            this.setState({
                count: nextProps.catalogueExceptions.count.tracks,
            })
        }
    }

    toggleRow(upc) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[upc] = !this.state.selected[upc];
        this.setState({
            selected: newSelected,
            selectAll: 2
        });
    }

    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                newSelected[x.upc] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }


    onSubmit(value){
        let data = this.state.data;
        let selectedArr = this.state.selected;


        let resultArr = [];

        var selectedArrayKeys = Object.keys(selectedArr)
        
        if (selectedArrayKeys.length) {
            let selectedProducts = data.filter(i => selectedArrayKeys.includes(i.upc));
            
            for(let product of selectedProducts) {
                for(let track of product.tracks) {
                    resultArr.push({
                        upc: product.upc,
                        isrc: track.isrc
                    })      
                }
            }
            //.map(i => {return {upc: i.upc, isrc: i.isrc}});
                
        }

        let resValues = {
            values: resultArr
        }

        const cb = () => this.loadData(this.state.page-1)
        this.props.dispatch(catalogueExceptionsActions.updateCatalogueExceptions(resValues, cb));
    }

    renderToolbar() {
        let toolbar = [];
        toolbar.push(<div key="wrapper">--todo: accordion--</div>);
        return toolbar;
    }

    render() {
        
        const subComponent = (row) => {
            const data = row.original.tracks;
            return (<ReactTable resizable={false} 
                pageSize={data.length} 
                showPagination={false} 
                data={data} 
                className={""} 
                columns={[
                {
                  id: 'isrc',
                  Header: 'ISRC',
                  accessor: 'isrc',
                  className: '',
                  headerClassName: '',
                  width: 250,
                  Cell: (props) => {
                    return (
                      <span className={`status-field status-track-${props.original.track_status}`}> { props.value } </span>
                    )
                  }
                }, {
                  id: 'artist',
                  Header: 'Artist',
                  accessor: 'artist',
                  className: 'text-align-left',
                  headerClassName: '',                      
                }, {
                  id: 'title',
                  Header: 'Title',
                  accessor: 'title',
                  className: 'text-align-left',
                  headerClassName: '',                      
                }
              ]}/>)
          };
        
        const columns = [{
            Header: x => {
                return (
                    <div className="checkbox-all">
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                    <span>Select All</span>
                    </div>
                );
            },
            className: 'all-rt-td',
            headerClassName: 'all-rt-th',
            columns: [
                {
                    Header: "Archive",
                    id: "checkbox",
                    accessor: "",
                    Cell: ({ original }) => {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selected[original.upc] === true}
                                onChange={() => this.toggleRow(original.upc)}
                            />
                        );
                    },
                    sortable: false,
                    width: 100
            },
            {
                id: 'upc',
                className: '',
                headerClassName: '',
                Header: 'UPC',
                filterable: false,
                sortable: false,
                accessor: 'upc',
                width: 250,
                Cell: props => {
                    return <span  className={`status-field status-product-${props.original.product_status}`}>{props.value}</span>;
                }
            },
            {
                id: 'product_title',
                className: 'text-align-left',
                headerClassName: '',
                Header: 'Product Title',
                filterable: false,
                sortable: false,
                accessor: 'product_title',
                Cell: props => {
                    return <div className="">{props.value}</div>;
                }
            },
            {
                expander: true,
                width: 65,
                Expander: ({ isExpanded, ...rest }) => (
                  <div>
                    {isExpanded ? (
                      <span>&#x2299;</span>
                    ) : (
                      <span>&#x2295;</span>
                    )}
                  </div>
                ),
                style: {
                  cursor: "pointer",
                  fontSize: 25,
                  padding: "0",
                  textAlign: "center",
                  userSelect: "none"
                },
            }            
        ]}];

        const tableData = this.state.data;
        
        const exceptionsTitle = `Exceptions ${this.props.catalogueExceptions && this.props.catalogueExceptions.count ? `(${this.props.catalogueExceptions.count.products}/${this.props.catalogueExceptions.count.tracks || 0})` : '(0)'}`;
        
        return (
          <div className="homepage statistics-main-holder">
            <div className="title-container">
              <h2 className="big-title">Admin Statistics</h2>
            </div>
            <Accordion allowZeroExpanded className="statistics-accordion">
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <Box
                          title="Trends Data Status (0)"
                          className="collapsed"
                        ></Box>
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <Box
                          title={exceptionsTitle}
                          className="exceptions-holder collapsed"
                        ></Box>
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Spinner enabled={this.props.catalogueExceptions.loading} />
                    {this.props.catalogueExceptions &&
                      this.props.catalogueExceptions.exceptions ? (
                        <div className="exceptions-holder-inner">
                          <ReactTable
                            manual
                            className="exceptions-table"
                            data={tableData}
                            //page={this.state.page-1}
                            pages={this.state.total_page}
                            pageSize={this.state.per_page}
                            columns={columns}
                            onPageChange={(pageIndex) => this.loadData(pageIndex)}
                            SubComponent={subComponent}
                          />
                          <input
                            type="submit"
                            onClick={this.onSubmit}
                            value="Update View"
                            className="update-exceptions default-btn"
                          />
                        </div>
                      ) : null}
                  </AccordionItemPanel>
              </AccordionItem>
              <UserLogs />
            </Accordion>
            <Helmet>
              <title>Statistics - Admin</title>
            </Helmet>
          </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        catalogueExceptions: state.catalogueExceptions,
        users: state.users,
        user: state.user,
        logs: state.userLogs
    }
}

export default connect(mapStateToProps)(Statistics);
