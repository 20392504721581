import { notificationsConstants } from '../constants/notifications';

export function notifications(state = {}, action) {
    switch (action.type) {
        case notificationsConstants.GET_USER_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case notificationsConstants.GET_USER_NOTIFICATIONS_SUCCESS:
            return {

                ...state,
                loading: false,
                data: action.notifications.data,
                count: action.notifications.count,
            };
        case notificationsConstants.GET_USER_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case notificationsConstants.GET_STORE_PLAYLIST_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case notificationsConstants.GET_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS:
            return {

                ...state,
                loading: false,
                playlist: action.notifications,
            };
        case notificationsConstants.GET_STORE_PLAYLIST_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case notificationsConstants.GET_HEARTBEATS_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                heartbeats: { updated: false }
            };
        case notificationsConstants.GET_HEARTBEATS_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                heartbeats: action.payload,
            };
        case notificationsConstants.GET_HEARTBEATS_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case notificationsConstants.UPDATE_STORE_PLAYLIST_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case notificationsConstants.UPDATE_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS:
            return {

                ...state,
                loading: false,
                // playlistCount: action.notifications,
            };
        case notificationsConstants.UPDATE_STORE_PLAYLIST_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case notificationsConstants.GET_RELEASES_NOTIFICATIONS_REQUEST:
            return {
              ...state,
              loading: true,
              releases: { updated: false }
            };
        case notificationsConstants.GET_RELEASES_NOTIFICATIONS_SUCCESS:
            return {
              ...state,
              loading: false,
              releases: action.payload
            };
        case notificationsConstants.GET_RELEASES_NOTIFICATIONS_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload
            };
        case notificationsConstants.GET_COUNT_OF_NOTIFICATIONS_REQUEST:
            return {
              ...state,
              loading: true
            };
        case notificationsConstants.GET_COUNT_OF_NOTIFICATIONS_SUCCESS:
            return {
              ...state,
              loading: false,
              count: action.payload
            };
        case notificationsConstants.GET_COUNT_OF_NOTIFICATIONS_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case notificationsConstants.UPDATE_RELEASE_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case notificationsConstants.UPDATE_RELEASE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                releases: { updated: true }
            };
        case notificationsConstants.UPDATE_RELEASE_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                releases: { updated: false }
            };
        case notificationsConstants.UPDATE_HEARTBEATS_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case notificationsConstants.UPDATE_HEARTBEATS_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                heartbeats: { updated: true }
            };
        case notificationsConstants.UPDATE_HEARTBEATS_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                heartbeats: { updated: false }
            };

        default:
            return state
    }
}
