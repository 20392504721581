import moment from 'moment';
import { imprintsFormatter } from './imprints';
import { artistsFormatter } from './artists';
import { productsFormatter } from './products';
import { tracksFormatter } from './tracks';
import { capitalize, uniq, map, sortBy, find } from 'lodash';

export const filterFormatter = {
    formatEntityFilter,
    formatNewEntityFilter,
    formatDataLogs,
    formatDataLogsResults,
    formatFullDataLogs
};

function formatEntityFilter(data, entity) {
    return data.map(hit=>{
        switch(entity){
            case 'imprints':
            case 'exclude_imprints':
                return imprintsFormatter.formatAutocompleteItem(hit);
            case 'artists':
            case 'exclude_artists':
                return artistsFormatter.formatAutocompleteItem(hit);
            case 'products':
            case 'exclude_products':
                return productsFormatter.formatAutocompleteItem(hit);
            case 'projects':
            case 'exclude_projects':
                return projectsFormatter.formatAutocompleteItem(hit);                
            case 'tracks':
            case 'exclude_tracks':    
                return tracksFormatter.formatAutocompleteItem(hit);
        }
    });
}

function formatNewEntityFilter(filter){
    let hashFilter = {};
    //reset previous data
    for(let key of ['imprints', 'artists', 'products', 'projects', 'tracks']) {
        hashFilter[key] = [];
        hashFilter[`exclude_${key}`] = [];
    }
    //
    if(filter && filter.entities) {
        for(let entity of filter.entities) {
            const { entity_id, entity_type, fav_hide, entity_title } = entity;
            const key = `${fav_hide == 'hidden' ? 'exclude_' : ''}${entity_type}`;
            
            hashFilter[key].push({
                entity: entity_type,
                id: entity_id,
                name: entity_title
            });
            
        }
    }
    return hashFilter;
}

function formatDataLogs(globalFilter, user, all) {
    const dateStart = globalFilter.prevDateStart.split('-'),
        dateEnd = globalFilter.dateEnd.split('-');
    
    let params = {
        "silo_code[]": user.primary_silo_code,
        "dataset[]": 'ALL',
        "status_code[]": 0,
        "date_from[year]": dateStart[0],
        "date_from[month]": dateStart[1],
        "date_from[day]": dateStart[2],
        "date_to[year]": dateEnd[0],
        "date_to[month]": dateEnd[1],
        "date_to[day]": dateEnd[2]        
    };
    
    if(all == false) {
        params.complete = false
    }
    return params;
}

function normalizeVendor(vendor){
    let vendorTitle;
    switch (vendor) {
        case "amazon-prime":
        case "amazon-unlimited":
        case "amazon-freetier":
        case "amazon-downloads":            
            vendorTitle = 'Amazon';
            break;
            
        case "apple_music":
        case "apple_audience":
        case "apple_containers":
        case "apple_saves":
        case "apple_sub30":
        case "fuga_apple":
            vendorTitle = 'Apple Music';
            break;
            
        case "spotify":
        case "spotify_api_v2_4":
        case "spotify_ccpa":
        case "spotify_saves":
        case "spotify-sub30":
        case "spotify_sub30_ccpa":
            vendorTitle = 'Spotify';
            break;
        case "tiktok":
        case "tiktok_saves":
            vendorTitle = 'TikTok';
            break;
        case "youtube":
        case "youtube_playlists":
            vendorTitle = 'YouTube';
            break;
        case "itunes":
            vendorTitle = 'iTunes';
            break;
        case "facebook-consumption":
        case "facebook-production":
            vendorTitle = 'Facebook';
            break;            
        default:
            vendorTitle = capitalize(vendor);
    }
    return vendorTitle;

}

function formatDataLogsResults(data, user, globalFilter) {
    data = data.filter(item=>item.silo_code == user.primary_silo_code);
    let { vendors } = globalFilter;
    vendors = vendors.map(vendor => vendor.toLowerCase());
    for(let item of data){
        item.vendorTitle = normalizeVendor(item.vendor);
    }
    return data.filter(item=>vendors.includes(item.vendorTitle.toLowerCase()));
}

function _formatFullDataLogs(data, globalFilter) {
    let fromDate = moment(globalFilter.prevDateStart);
    let toDate = moment(globalFilter.dateEnd);
    let diff = toDate.diff(fromDate, 'days');
    let dates = [];
    for (let i = 0; i <= diff; i++) {
        dates.push(fromDate.format('YYYY-MM-DD'));
        fromDate.add(1, 'days');
    }
    let messages = {};
    for(let item of data) {
        messages[item.status_code] = item.status_msg;
    }
    
    const vendors = uniq(map(data, 'vendor')).sort();
    let results = [];
    for (let vendor of vendors) {
        let row = {};
        for(let date of dates) {
            let report = find(data, item=>(item.vendor == vendor && item.report_date == date));
            if(report) {
                row[date]=report.status_code;
            }
            else {
                row[date]=0;
            }
        }
        row.vendor = vendor;
        results.push(row);
    }
    let flatResults = {};
    for(let result of results) {
        let normalizedVendor = normalizeVendor(result.vendor.toLowerCase());
        for(let dateIndex in dates) {
            let date = dates[dateIndex];
            if(!flatResults[normalizedVendor])
                flatResults[normalizedVendor] = {};
            
            let vendorStatus = 0;
            if(flatResults[normalizedVendor][date]!==undefined) {
                if(result[date] < flatResults[normalizedVendor][date] || result[date] == 999)
                    vendorStatus = result[date];
                else
                    vendorStatus = flatResults[normalizedVendor][date];
            }
            else
                vendorStatus = result[date];
            
            flatResults[normalizedVendor][date] = vendorStatus;
        }
        flatResults[normalizedVendor].vendor = normalizedVendor;
    }
    return { data: Object.values(flatResults), dates, messages};
    
    
}

function formatFullDataLogs(data, globalFilter) {
    let fromDate = moment(globalFilter.prevDateStart);
    let toDate = moment(globalFilter.dateEnd);
    let diff = toDate.diff(fromDate, 'days');
    let dates = [];
    for (let i = 0; i <= diff; i++) {
        dates.push(fromDate.format('YYYY-MM-DD'));
        fromDate.add(1, 'days');
    }
    let messages = {};
    for(let item of data) {
        messages[item.status_code] = item.status_msg;
    }
    
    const vendors = uniq(map(data, 'vendor')).sort();
    let results = [];
    for (let vendor of vendors) {
        let row = {};
        for(let date of dates) {
            let report = find(data, item=>(item.vendor == vendor && item.report_date == date));
            if(report) {
                row[date]=report.status_code;
            }
            else {
                row[date]=0;
            }
        }
        row.vendor = vendor;
        row.vendorGroup = normalizeVendor(vendor.toLowerCase());
        results.push(row);
    }
    return { data: results, dates, messages};
    
    
}