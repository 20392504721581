import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, map, get } from 'lodash';
import store from '../../../helpers/store'

import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import cellHeaderHOC from '../Datagrid/CellHeaderHOC';

import Box from '../Box';
import ExportModal from '../ExportModal';

import TerritoryStatsMap from './Territory/Map';
import LineChart from '../../widgets/charts/types/LineChart';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import EntitySelector from '../../pages/result/EntitySelector';
import DateGroupSelector from '../../pages/result/DateGroupSelector';
import Locations from '../../pages/result/Locations';
import ModeSelect from '../ModeSelect';
import { statsActions } from '../../../data/actions/stats';
import { territorySettings } from './Territory/Settings'; 

var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import VendorLogo from "../VendorLogo";
import { dspLogos } from "../../common/Stats/utils";
const CellHeaderTable = cellHeaderHOC(ReactTable);


class TerritoryStats extends React.Component {
    
    options = [
           //{label: 'Most Per Capita', field: 'per_cap_units', dir: 'DESC'},
           {label: 'Most Units', field: 'curr_units', dir: 'DESC'},
           {label: 'Least Units', field: 'curr_units', dir: 'ASC'},
           {label: 'Units Spike', field: 'units_diff', dir: 'DESC'},
           /*
           {label: 'Most Listeners', field: 'curr_listeners', dir: 'DESC'},
           {label: 'Least Listeners', field: 'curr_listeners', dir: 'ASC'},
           {label: 'Listeners Spike', field: 'listeners_diff', dir: 'DESC'}
           */
       ];
    
    limits = [10, 25, 50, 100, 500];
    
    constructor(props) {
        super(props);
        
        const territoryLimit = store.get('territoryLimit') || 10;
        
        this.state = {
          mode: props.defaultMode || 'map',
          sort: this.options[0],
          limit: territoryLimit,
          logoDataFiltered: [],
          entityFilter: null,
          dateGroup: null
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setSort = this.setSort.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.getFiltered = this.getFiltered.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getData = this.getData.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);
        this.tableScrollTop = 0;
    };    
    
    setChartMode(mode){
        this.setState({mode}, ()=>{
            this.loadData();
        });
        
        const { modeChange } = this.props;
        if(typeof modeChange == 'function')
            modeChange(mode);
        
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>this.loadData());
    }        

    renderToolbar(modes){
        let toolbar = [];

        // for (let mode in modes) {
        //     toolbar.push(<a key={mode}><i alt={mode} className={`fa fa-${modes[mode]} ${this.state.mode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode({mode})}></i></a>);                
        // }
        
        const limitOptions = this.limits.map(limit => ({
            label: limit == 500 ? 'All' : limit,
            value: limit        
        })),
        limitValue = find(limitOptions, {value: this.state.limit});   
        const options = [
            {icon: worldSvg, value: 'map', label: "World Map"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
        ];     
        
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
                <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
            </div>
            <div className="limit-holder">
                <span className="select-label">Limit</span>
                <ReactSelect value={limitValue} options={limitOptions} onChange={this.setLimit} className="single-select select-limit" isSearchable={ false }/>
            </div>
            <div className="select-holder">
                <span className="select-label">Sort by</span>
                <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select sort-select" isSearchable={ false }/>
            </div>
            {/* <div className="ibox-actions">
            </div> */}
        </div>)
        return toolbar;
    }
    
    getFiltered(){
        const { filtered:extFiltered = [], entity, ids } = this.props;
        let filtered = {};
        for(let filter of extFiltered) {
            filtered[filter.id] = filter.value;
        }
        filtered[entity] = ids;
        return filtered;
    }
    
    exportToCsv(){
        let { entityFilter } = this.state;
        if(!entityFilter)
            entityFilter = this.getFiltered();
        this.props.getTerritoryStats(entityFilter, this.state.sort, 100000).then(data => {
            const filename = CSV.CSVHeader('details_territories', this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle); 
            return CSV.CSVExport(data.table, {filename}, 'territories');            
        });
    }
    
    exportToXls(){
        const { mode } = this.state,
            { filtered } = this.props,
            data = this.getData(mode);
            
        if(!data.table)
            return;
        const territories = map(data.table, 'territory');
        this.props.getTopLocationsStats(territories.join(','), filtered, null, territories.length * 10).then(countries => {
            countries.unshift({'Overall': data.table})
            const filename = CSV.CSVHeader('top_locations', '', this.props.filter.global, this.props.parentEntityTitle);
            return CSV.CSVBulkExport(countries, filename);            
        })
    }
    

    setSort(sort){
        this.setState({sort}, ()=>{
            this.loadData();
            //this.props.getTerritoryStats(this.getFiltered(), this.state.sort, this.state.limit)
        })
    }

    setLimit(limit){
        this.setState({limit: limit.value}, ()=>{
            //this.props.getTerritoryStats(this.getFiltered(), this.state.sort, this.state.limit)
            this.loadData();
            store.set('territoryLimit', limit.value);
        })
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.loadData());
    }


    loadData() {
        const { mode, dateGroup } = this.state;
        const dataCall = mode == 'line' ? this.props.getTerritoryTimeseries : this.props.getTerritoryStats;
        let { entityFilter } = this.state;
        if(!entityFilter)
            entityFilter = this.getFiltered();
        return dataCall(entityFilter, this.state.sort, this.state.limit, dateGroup);
    }

    componentDidMount() {
        const { chartType } = this.props;
        //this.props.getTerritoryStats(this.getFiltered(), this.state.sort, this.state.limit)
        this.loadData();

        if (this.props.filter.global) {
          if (this.props.filter.global !== undefined) {
            this.setState({
              logoDataFiltered: chartType
                ? dspLogos[chartType].data.filter((l) =>
                    this.props.filter.global.vendors.includes(l)
                  )
                : [],
            });
          }
        }
    }
    
    getTimeseries() {
        // const ids = this.props.stats.territory ? map(this.props.stats.territory.table, 'code') : [];
        //this.props.getTerritoryTimeseries(this.getFiltered(), this.state.sort, this.state.limit)
        this.loadData();
    }
     
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                //this.props.getTerritoryStats(this.getFiltered(), this.state.sort, this.state.limit)
                this.loadData();
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    logoDataFiltered: chartType
                      ? dspLogos[chartType].data.filter((l) =>
                          globalFilter.vendors.includes(l)
                        )
                      : [],
                  };
                });
            }                
        }
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }

    renderExportToCsv() {
        return <ExportModal exportTotals={this.exportToCsv} exportBreakdown={this.exportToXls} loading={(this.props.stats.territoryLoading || this.props.stats.topLocationsLoading)} />
        //return (
            //<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        //)
    }

    getData(mode) {    
        const { stats } = this.props;
        return mode == 'line' ? stats.territoryTimeseries : stats.territory;
    }
    
    renderChartActions(){
        const { mode } = this.state;
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} />        
            {mode == 'line' && <DateGroupSelector dateGroupChange={this.setDateGroup}/>}
        </React.Fragment>
    }

    render (){
        const { stats, expanded = false, milestones, filtered } = this.props;
        const { mode } = this.state;
        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }        
        const data = this.getData(mode);
        let milestonesItems = get(milestones, 'entities.mixed.tableItems', [])

        return <Box title="Territories" toolbar={this.renderToolbar()} chartActions={this.renderChartActions()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.territoryLoading} exportToCsv={this.renderExportToCsv()}>
            {data && <div>
                {mode=='map' && <TerritoryStatsMap expanded={true} data={data} {...shadowChartProps} />}
                {mode=='line' &&  <div className="territory-chart">
                    <LineChartWithAnnotations milestones={milestonesItems} data={data} period={this.state.period} label="streams" dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} shadowChartProps={shadowChartProps} />
                    {/* <LineChart data={data} label="streams" {...shadowChartProps} /> */}
				</div> }
                {mode=='table' && <div className="territory-table territory-table--single custom-scroll"><CellHeaderTable
                    className="simple-table"
                        data={data.table}
                        columns={territorySettings.settingsForTable(expanded?'expanded':'details')}
                        defaultPageSize={10}
                        showPagination={data.table.length > 10}
                        collapseOnDataChange={false}
                        noDataText='No results found. Please check your filters.'
                        SubComponent={row=><Locations territory={row.original.territory} filtered={filtered} mode="basic" />}
                            
                />
                </div>}
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter,
        milestones: state.milestones
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getTerritoryStats: (filtered, sorted, limit) => {
            switch(ownProps.entity) {
                case 'playlists':
                    return dispatch(statsActions.getPlaylistTerritoryStats(filtered, sorted, limit));
                    break;
                default:
                    return dispatch(statsActions.getTerritoryStats(filtered, sorted, limit));
                    break;
            }
        },
        getTerritoryTimeseries: (filtered, sorted, limit, dateGroup) => {
            return dispatch(statsActions.getTerritoryTimeseries(ownProps.entity, filtered, sorted, limit, dateGroup))
        },
        getTopLocationsStats: (territories, filtered, sorted, limit) => {
            return dispatch(statsActions.getTopLocationsStats(territories, filtered, sorted, limit))
        } 
    } 
}


export default connect(mapStateToProps, mapDispatchToProps)(TerritoryStats);