import { map, orderBy, filter } from 'lodash';

export const entityFiltersFormatter = {
    formatAllEntityFilters
};

function formatAllEntityFilters(data){
    data = orderBy(data, ['entity'], ['asc']);
    data = map(data, (item)=>{
        let ids = map(item.body.value, 'id');
        item.name = `${item.name}`;
        item.id = ids.join(','); 
        return item;
    }) 
    return filter(data, (item) => { return (item.id.length && !item.entity.startsWith('exclude_'))});
}
