import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, cloneDeep, get, sumBy } from 'lodash';
import ReactTable from "react-table";
import Box from '../../common/Box';
import ModeSelect from '../../common/ModeSelect';
import ReactSelect from '../../common/ReactSelect';
import WorldMap from '../../common/WorldMap';
import LineChart from '../../widgets/charts/types/LineChart';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import DateGroupSelector from './DateGroupSelector';
import CSV from '../../../helpers/CSVExport';
import TiktokTerritoriesMap from './TiktokTerritories/Map';
import { territorySettings } from './TiktokTerritories/Settings'; 
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';

const CellHeaderTable = cellHeaderHOC(ReactTable);

import { tracksActions } from '../../../data/actions/tracks';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');

class TiktokTerritories extends React.Component {
	
	nullElement = {vend_id: 0, value: 'all', label: 'All'}
	
	constructor(props) {
		super(props);
		this.state = {
			vendor: this.nullElement,
			mode: props.defaultMode || 'map',
			view: 'views',
			dateGroup: null
		}
		
		this.setVendor = this.setVendor.bind(this);
		this.setView = this.setView.bind(this);
		this.setDateGroup = this.setDateGroup.bind(this);
		this.exportToCsv = this.exportToCsv.bind(this);
		this.setChartMode = this.setChartMode.bind(this);
		this.getTableData = this.getTableData.bind(this);
	}

	setVendor(vendor) {
		this.setState({vendor});
	}

	setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>{
            this.props.getTiktokTerritoriesTimeseries(this.props.filtered, this.state.dateGroup);
        });
    }

	setView({value}) {
		this.setState({view: value});
	}
	
	setChartMode(mode){
		this.setState({mode});
		
		const { modeChange } = this.props;
		if(typeof modeChange == 'function')
			modeChange(mode);
			
		if(mode == 'line') {
			this.props.getTiktokTerritoriesTimeseries(this.props.filtered, this.state.dateGroup);
		}
	}
	
	renderToolbar(data){
		let toolbar = [];
		const viewOptions = [
			{label: 'Views', value: 'views'}, 
			{label: 'Creates', value: 'creates'}
		];
		const viewValue = find(viewOptions, option=>option.value == this.state.view);
		const options = [
			{icon: worldSvg, value: 'map', label: "World Map"},
			{icon: lineSvg, value: 'line', label: "Line Chart"},
			{icon: tableSvg, value: 'table', label: "Table"},
		];
		toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
		<div className="ibox-icon-holder mode-select">
			<ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
		</div>
		<div className="ibox-actions">
			<ReactSelect value={viewValue} options={viewOptions} onChange={this.setView} className="single-select"/>
			<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />	
		</div>
		</div>)
		return toolbar;
	}
	
    exportToCsv(){
		const {tiktokTerritories} = this.props.tracks;
        const filename = CSV.CSVHeader('social_territories', '', this.props.filter.global, this.props.parentEntityTitle); 
		const { tableData } = this.getTableData(tiktokTerritories); 
        return CSV.CSVExport(tableData, {filename}, 'social_territories');
    }

	
	
	componentDidMount() {
		this.props.getTiktokTerritories(this.props.filtered, this.state.dateGroup);
		const { mode } = this.state;
		if(mode == 'line') {
			this.props.getTiktokTerritoriesTimeseries(this.props.filtered, this.state.dateGroup);
		}
	}
	
	componentWillReceiveProps(nextProps){
		if(nextProps.filter && nextProps.filter.global){
			if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
				this.props.getTiktokTerritories(this.props.filtered, this.state.dateGroup);
				const { mode } = this.state;
				if(mode == 'line')
					this.props.getTiktokTerritoriesTimeseries(this.props.filtered, this.state.dateGroup);

			}                
		}
		if(nextProps.filtered && !isEqual(nextProps.filtered, this.props.filtered)) {
			this.props.getTiktokTerritories(nextProps.filtered, this.state.dateGroup);
		} 
	}
	
    renderChartActions(data){
        const { mode } = this.state;
        let vendors = [this.nullElement];
        if(data) {
            for(let vendor of data) {
                vendors.push(vendor);
            }
        }
          
        return <React.Fragment>
			<div className="entity-selector-select">
			    <ReactSelect value={this.state.vendor} options={vendors} onChange={this.setVendor} className="single-select"/>    
			</div>
            {mode == 'line' && <DateGroupSelector dateGroupChange={this.setDateGroup}/>}
        </React.Fragment>
    }
	
	getTableData(territories) {
		const { view, vendor, mode } = this.state;
		let tableData = cloneDeep(territories);
		if(tableData && tableData.result) {
			tableData = tableData.result.filter(row => (row.vendor == vendor.vend_id && row.contentType == view));
			const total = sumBy(tableData, 'curr_units');
			for(let rank in tableData) {
				let row = tableData[rank];
				row.rank = ++rank;
				row.share = Math.round(row.curr_units / total * 10000 ) / 100
			}
		}
		else {
			tableData = [];
		}
		const vendorsList = territories ? territories.vendors : [];
		return {tableData, vendorsList};
	}
	
	render (){  
		const {tiktokTerritories, tiktokTerritoriesLoading, tiktokTerritoriesTimeseries, tiktokTerritoriesTimeseriesLoading} = this.props.tracks,
			widgetTitle = `Social Territories`;
		const { view, vendor, mode } = this.state;

		let chartData = cloneDeep(tiktokTerritoriesTimeseries);
		if(chartData) {
			chartData.datasets = chartData.datasets[view].filter(dataset => dataset.vendor == vendor.vend_id);
		}
		
		let { shadowChartProps } = this.props;
		if(shadowChartProps){
			shadowChartProps.shadowChart = true;
		}    
		
		let { tableData, vendorsList } = this.getTableData(tiktokTerritories); 
		
		let milestonesItems = get(this.props.milestones, 'entities.mixed.tableItems', []);
		const mapHolderClass = mode === 'map' ? 'world-map-container' : 'map-table-container';
		
		return <Box title={widgetTitle} chartActions={this.renderChartActions(vendorsList)}  toolbar={this.renderToolbar()} spinnerEnabled={(tiktokTerritoriesLoading || tiktokTerritoriesTimeseriesLoading)} className="territories-widget" >
		{(tableData || (chartData && chartData.datasets)) && <div className="territory-map-row">
			<div className={`territory-details-map`}>
				<div className={`chart-block chart-block--full custom-scroll ${mapHolderClass}`}>
					{mode == 'map' && <TiktokTerritoriesMap data={tableData} label="units" view={view} />}
					{mode == 'line' && <LineChartWithAnnotations milestones={milestonesItems} data={chartData} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} period={this.state.period} shadowChartProps={shadowChartProps} />}
					{mode=='table' && <div className="territory-table territory-table--single custom-scroll"><CellHeaderTable
						className="simple-table"
							data={tableData}
							columns={territorySettings.settingsForTable(view)}
							defaultPageSize={10}
							showPagination={tableData.length > 10}
							noDataText='No results found. Please check your filters.'
					/>
					</div>}
				</div>
			</div>
		</div>}
		</Box>;
	}
}

function mapStateToProps(state) {
	return {
		stats: state.stats,
	} 
}

function mapStateToProps(state) {
	return {
		tracks: state.tracks,
		filter: state.filter,
		milestones: state.milestones
	} 
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		getTiktokTerritories: (filtered) => {
			return dispatch(tracksActions.getTiktokTerritories(filtered));
		} ,
		
		getTiktokTerritoriesTimeseries: (filtered, dateGroup) => {
			return dispatch(tracksActions.getTiktokTerritoriesTimeseries(filtered, dateGroup));
		} 
	} 
}



export default connect(mapStateToProps, mapDispatchToProps)(TiktokTerritories);