import React from 'react';
import { find, map } from 'lodash';
import CSVToArray from '../../helpers/CSVToArray';
import ReactSelect from './ReactSelect';

class CSVImport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            keys: [],
            data: [],
            keyMap: {},
            showForm: false
        }
    }
    
    readCSV = (event, key) => {
        const { fields } = this.props;
        const fieldKeys = map(fields, 'key');
        let reader = new FileReader();
        const currentFile = event.target.files[0];
        reader.onload = (file) => {
            const data = CSVToArray(file.target.result);
            
            if(data && data.length) {
                
                const keys = Object.keys(data[0]);
                let keyMap = {};
                for(let key of keys) {
                    if(fieldKeys.includes(key)) {
                        keyMap[key] = key;
                    }
                }
                this.setState({data, keys, keyMap, showForm: true});
            }
        }
        reader.readAsText(currentFile);
    }
    
    setField = (field, option) => {
        let { keys, keyMap } = this.state;
        const existing = keyMap[field];
        if(existing) {
            keys.push(existing);
        }
        keyMap[field] = option.value;
        this.setState({
            keyMap,
            keys: keys.filter(key=>key!=option.value)
        })
    }
    
    renderSubmitButton = () => {
        const { fields } = this.props;
        const { keyMap } = this.state;
        for(let field of fields) {
            if(field.required && !keyMap[field.key])
                return null;
        }
        return <button className="default-btn default-btn--small" onClick={this.submit}>Import</button>;
    }
    
    submit = () => {
        const { data, keyMap } = this.state, 
            { onSubmit, fields } = this.props;
        
        const result = data.map(row => {
            let formattedRow = {}
            
            //for(let key of Object.keys(keyMap)) {
            //    formattedRow[key] = row[keyMap[key]];
            //}
            
            for( let field of fields ) {
                const { key, options } = field;
                if(options !== undefined) {
                    formattedRow[key] = keyMap[key];
                }
                else {
                    formattedRow[key] = row[keyMap[key]];
                }
            }
            return formattedRow;
        });
        return onSubmit(result);
    }
        
    render() {
        const { fields } = this.props;
        const { keys, keyMap, showForm } = this.state;
        let options = keys.map(key=>({value: key, label: key}));
        options.unshift({label: '-not set-', value: null});
        return (
            <div>
                <input type="file" id="csvFile" accept="text/csv" onChange={this.readCSV} />
                {showForm && <div className="entity-filter-form">
                {fields.map(field=>{
                    const value = keyMap[field.key] || null,
                        fieldOptions = field.options ? [{label: '-not set-', value: null}, ...field.options] : options, 
                        selectedValue = find(fieldOptions, option=>option.value == value);
                    return <div className="csv-item">
                    <span>{field.label} {field.required ? '*' : ''}</span>
                    <ReactSelect options={fieldOptions} value={selectedValue} onChange={(option)=>this.setField(field.key, option)} />
                    </div>
                })}
                </div>}
                {this.renderSubmitButton()} 
            </div>            
        )
    }
}

export default CSVImport