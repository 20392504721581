import React from 'react';
import { find, sum } from 'lodash';
import WorldMap from '../../../common/WorldMap';
import AbsoluteValue from '../../../common/AbsoluteValue';
import PercentageValue from '../../../common/PercentageValue';
import ReactTable from "react-table";
import cellHeaderHOC from '../../../common/Datagrid/CellHeaderHOC';
import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import {convertToThousands} from '../../../../helpers/NumberFormatter';
const CellHeaderTable = cellHeaderHOC(ReactTable);

class TiktokTerritoriesMap extends React.Component {

	constructor(props) {
		super(props);
	};    
	


		

	render () {
		const { id, data, topCountryThreshold, averageCountryThreshold, view } = this.props;
		let topQuantity = 10,
			threshold = 100,
			topCount = 0,
			topSum = 0,
			countriesOverThreshold = 0,
			countries = [],
			mapData = {},
			tableData = [];
		if(!data)
			return null;       
			
			
		for(let row of data) {
			const { code } = row;
			mapData[code] = row[`curr_units`];
			
			tableData.push({
				code: code,
				name: row.country,
				units: row[`curr_units`]
			});
			
		}

			
		for(let country of tableData){
			let units = Number(country.units);
			if(topCount<topQuantity) {
				topCount++;
				topSum += units;
			} 
			if(units<threshold)
				continue;
			countriesOverThreshold++;
		}

		return <div className="territory-map-container-row">
			<div className="territory-map-item" id={id}>
				<div className="tooltip-holder">
				<h3 className="content-title lowercase">{countriesOverThreshold} countries</h3>
				{/*<i className="fa fa-question entity-question-sign entity-question-sign--tooltip"></i>*/}
			  </div>
			  <h4 className="content-subtitle lowercase">With over {convertToThousands(threshold)} {view}</h4>
			  {/*
			  <div className="description up">
				  <span className="icon fa"></span>    
				  <p>You have <span className="num">+11%</span> in comparison with the previous month.</p>
			  </div>
			  */}
			  <div className="data-title">
				  <div>
					  Listeners by Top {topQuantity} Countries
				  </div>
				  <div className="lowercase">
					  {/*actual count*/}
					  {convertToThousands(topSum)}                                
				  </div>                                
			  </div>
			  <div className="data-block"> 
				  {tableData.slice(0, topQuantity).map(country=>{return <div key={country.code} className="data-block__item">
					  <div className="name">
						  {country.name}
					  </div>
					  <div className="value">
						  {convertToThousands(country.units)}                                
					  </div>                                
				  </div>})}
			  </div>

			</div>
			<div className={'territory-map-item territory-map-holder'}>
				<WorldMap data={mapData} label="Units" /> 
			</div>
		</div>
	}
}
export default TiktokTerritoriesMap;