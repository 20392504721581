import React from 'react';

export default function Accordion(props) {
    const opened = props.accordion[props.id],
        filter = props.filter[props.id],
        { totalValuesCount = 0 } = props;
    let itemsCount = null;
    if(filter && Array.isArray(filter)) {
        if(filter.length && filter.length != totalValuesCount)
            itemsCount = filter.length; 
        else
            itemsCount = 'All';
    }
    return <div className={`form-group ${props.className} ${opened ? 'opened' : ''}`}>          
        <label className="font-normal filter-label" onClick={()=>props.toggleAccordion(props.id)}>{props.label}{(itemsCount ? ` (${itemsCount})` : '')}<span className={`caret ${opened && 'opened'}`}></span></label>
        {opened && <div className="form-group-inner">
            {props.children}
        </div>}
    </div>
    
}
