import { heartbeatsConstants } from '../constants/heartbeats';

const initialState = {
  data: [],
  heartAttacksData: [],
  heartbeatsSuccess: false,
  heartbeatsFailure: false,
  heartbeatsInProgress: false,
  heartbeatsWeeks: [],
  heartbeatsWeeksLoading: false,
  heartAttacksSuccess: false,
  heartAttacksFailure: false,
  heartAttacksInProgress: false,
  heartAttacksWeeks: [],
  heartAttacksWeeksLoading: false  
  
}

export function heartbeats(state = initialState, action = {}){
  switch (action.type) {
    case heartbeatsConstants.GET_HEARTBEATS_REQUEST:
      return { ...state, heartbeatsInProgress: true };
    case heartbeatsConstants.GET_HEARTBEATS_SUCCESS:
      return { ...state, data: action.payload, heartbeatsSuccess: true, heartbeatsFailure: false, heartbeatsInProgress: false };
    case heartbeatsConstants.GET_HEARTBEATS_ERROR:
      return { ...state, heartbeatsSuccess: false, heartbeatsFailure: true, heartbeatsInProgress: false };
      
    case heartbeatsConstants.GET_HEARTBEATS_WEEKS_REQUEST:
        return { ...state, heartbeatsWeeks: [], heartbeatsWeeksLoading: true };
      case heartbeatsConstants.GET_HEARTBEATS_WEEKS_SUCCESS:
        return { ...state, heartbeatsWeeks: action.payload, heartbeatsWeeksLoading: false };
      case heartbeatsConstants.GET_HEARTBEATS_WEEKS_ERROR:
        return { ...state, heartbeatsWeeks: [], heartbeatsWeeksLoading: false };
      
    case heartbeatsConstants.GET_PAGED_HEARTBEATS_REQUEST:
        return { ...state, loading: true };
      case heartbeatsConstants.GET_PAGED_HEARTBEATS_SUCCESS:
        const {data, pagination} = action.payload;
        return { ...state, data, pagination, heartbeatsSuccess: true, heartbeatsFailure: false, loading: false };
      case heartbeatsConstants.GET_PAGED_HEARTBEATS_ERROR:
        return { ...state, heartbeatsSuccess: false, heartbeatsFailure: true, loading: false };
        
      case heartbeatsConstants.GET_HEARTATTACKS_WEEKS_REQUEST:
          return { ...state, heartAttacksWeeks: [], heartAttacksWeeksLoading: true };
        case heartbeatsConstants.GET_HEARTATTACKS_WEEKS_SUCCESS:
          return { ...state, heartAttacksWeeks: action.payload, heartAttacksWeeksLoading: false };
        case heartbeatsConstants.GET_HEARTATTACKS_WEEKS_ERROR:
          return { ...state, heartAttacksWeeks: [], heartAttacksWeeksLoading: false };
        
      case heartbeatsConstants.GET_PAGED_HEARTATTACKS_REQUEST:
          return { ...state, heartAttacksLoading: true };
        case heartbeatsConstants.GET_PAGED_HEARTATTACKS_SUCCESS:
          const {data: heartAttacksData, pagination: heartAttacksPagination} = action.payload;
          return { ...state, heartAttacksData, heartAttacksPagination, heartAttacksSuccess: true, heartAttacksFailure: false, heartAttacksLoading: false };
        case heartbeatsConstants.GET_PAGED_HEARTATTACKS_ERROR:
          return { ...state, heartAttacksSuccess: false, heartAttacksFailure: true, heartAttacksLoading: false };
        
      default:
          return state;
  }
}
