import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import ReactSelect from '../../common/ReactSelect';
import { statsActions } from '../../../data/actions/stats';
import dateGroups from '../../widgets/filter/enums/ranges.json'

class DateGroupSelector extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedDateGroup: null
        }
        this.setDateGroup = this.setDateGroup.bind(this);
    }
    

    setDateGroup({value}) {
        this.setState({selectedDateGroup: value});
        const { dateGroupChange } = this.props;
        if(typeof dateGroupChange == 'function') { 
            dateGroupChange(value);
        }
    }
    
    render (){
        let { selectedDateGroup } = this.state;
        const filterSelectedDateGroup = this.props.filter && this.props.filter.global && this.props.filter.global.range; 
        if(!selectedDateGroup)
            selectedDateGroup = filterSelectedDateGroup;
        
        const filteredDateGroups = dateGroups.filter(group=>{
            switch(filterSelectedDateGroup) {
            case 'day':
                return true;
            case 'chwk':
            case 'clwk':
                return group.value != 'day';
            case 'mth':
                return group.value == 'mth';
            }
        })            
        const selected = find(filteredDateGroups, group=>group.value == selectedDateGroup);
        
        return <div className="date-group-selector-holder"><ReactSelect value={selected} options={filteredDateGroups} onChange={this.setDateGroup} className="single-select " /></div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(DateGroupSelector);