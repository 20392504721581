import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import PDFHeader from './PDFHeader';
import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';

const renderSources=(data)=>{
  const columns=[
     {key: 'source', title: 'Source', width: 40},
     {key: 'curr_units', title: 'Units', width: 40, formatFn: splitByThousands},
     {key: 'share', title: 'Share', width: 20, formatFn: addPercent},
 ];
     
  return <Table
      columns={columns}
      items={data}
  />
}

const renderDevices=(data)=>{
    const columns=[
       {key: 'device', title: 'Device', width: 40},
       {key: 'curr_units', title: 'Units', width: 40, formatFn: splitByThousands},
       {key: 'share', title: 'Share', width: 20, formatFn: addPercent},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
  }


const renderDemographics=(data)=>{
  const columns=[
    {key: 'age', title: 'Age Group', width: 25, fontSize: 5},
    {key: 'male', title: 'Male', width: 25, fontSize: 5, formatFn: splitByThousands},
    {key: 'female', title: 'Female', width: 25, fontSize: 5, formatFn: splitByThousands},
    {key: 'unknown', title: 'Unknown', width: 25, fontSize: 5, formatFn: splitByThousands},
  ];
     
  return <Table
      columns={columns}
      items={data}
  />
}

const renderTerritories=(data)=>{
    const columns=[
       {key: 'rank', title: 'Rank', width: 20},
       {key: 'name', title: 'Country', width: 60},
       {key: 'share', title: 'Share', width: 20, formatFn: addPercent},
       {key: 'listeners', title: 'Listeners', width: 20, formatFn: splitByThousands},
       {key: 'total_streams', title: 'Streams', width: 20, formatFn: splitByThousands},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}

export default (props) => {
    const { data: { images, tables, metadata, overview, reportTitle, globalFilter, entity, header }, widgetProps: { widgetState } } = props;
  return <Document>
    <Page size="A4" style={styles.page}>
      <PDFHeader header={header} />
      <View style={styles.section}>
          <OverviewPDF data={header.overview} />
      </View>
      {/*images.audience_followers_line_chart && <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Followers</Text>
          <Image source={images.audience_followers_line_chart} />
        </View>
      </View>}
      {images.audience_monthly_listeners_line_chart && <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Monthly Listeners</Text>
            <Image source={images.audience_monthly_listeners_line_chart} />
          </View>
      </View>*/}
      {images.audience_streams_listeners && <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Listeners</Text>
            <Image source={images.audience_streams_listeners} />
          </View>
      </View>}
      <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Territories</Text>
            {renderTerritories(tables.territories)}
          </View>
        </View>      
      </Page>
      <Page size="A4"  wrap={false} style={styles.page}>          
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.smallHeader}>Repeat Listeners</Text>
            <Image source={images.audience_repeat_listeners_line_chart} />
          </View>
        </View>      
  
      <View style={styles.section}>
      
          <View style={styles.half}>
            <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Sources of Stream</Text>
                {widgetState.sources.mode != 'table' && <View style={styles.columnWithMargin}>
                  <Image source={images.audience_sources_pie_chart} />
                </View>}
                {widgetState.sources.mode == 'table' && <View style={styles.column}>
                   {renderSources(tables.sources)}
               </View>}
            </View>
         </View>
          
        
        <View style={styles.half}>
            <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Age and Gender</Text>
                {widgetState.demographics.mode != 'table' && <View style={styles.columnWithMargin}>
                  <Image source={images.audience_demographics_bar_chart} />
                </View>}
                {widgetState.demographics.mode == 'table' && <View style={styles.column}>
                  {renderDemographics(tables.demographics)}
                </View>}
            </View>
        </View>
        
      </View>
      </Page>
      <Page size="A4"  wrap={false} style={styles.page}>      
      <View style={styles.section}>
      
        <View style={styles.half}>
          <View style={styles.columnSection}>
              <Text style={styles.smallHeader}>Sources of Discovery</Text>
              {widgetState.sources_discovery.mode != 'table' && <View style={styles.columnWithMargin}>
                <Image source={images.audience_sources_discovery_pie_chart} />
              </View>}
              {widgetState.sources_discovery.mode == 'table' && <View style={styles.column}>
                 {renderSources(tables.sourcesDiscovery)}
             </View>}
          </View>
        </View>
        
        
        <View style={styles.half}>
            <View style={styles.columnSection}>
                <Text style={styles.smallHeader}>Devices</Text>
                {widgetState.devices.mode != 'table' && <View style={styles.columnWithMargin}>
                  <Image source={images.audience_devices_pie_chart} />
                </View>}
                {widgetState.devices.mode == 'table' && <View style={styles.column}>
                  {renderDevices(tables.devices)}
                </View>}
            </View>
        </View>
        
      </View>
    </Page>

    {/*

    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.column}>
          <Text style={styles.smallHeader}>Milestones</Text>
          {renderMilestones(tables.milestones)}
        </View>
      </View>
    </Page>
    
    */}
  </Document>
};