import React from 'react';
import { connect } from 'react-redux';
import AddChannelForm from '../../forms/addChannel'
import { channelsActions } from '../../../data/actions/channels'

class AddChannelLink extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showOpen: false, 
            channel: undefined
        }
        this.addChannel = this.addChannel.bind(this);
        this.deleteChannelCall = this.deleteChannelCall.bind(this);
        this.deleteChannel = this.deleteChannel.bind(this);
        this.showModal = this.showModal.bind(this);
    }
    
    showModal(show){
        this.setState({
            showModal: show
        })
    }
    
    deleteChannelCall(artist, channel) {
        return (channel && channel.id) ? this.props.dispatch(channelsActions.deleteChannel(artist, {channel_id: channel.id, channel_url: channel.url})) : Promise.resolve();
    }
    
    deleteChannel(artist, channel) {
        this.deleteChannelCall(artist, channel).then(this.props.refresh)
    }
    
    toggleEnabled(channel) {
        this.props.dispatch(channelsActions.toggleEnabled(channel.id)).then(this.props.refresh)
    }

    
    addChannel(formData) {
        const { url } = formData,
            { artist, channel, dispatch } = this.props;            
            
        if(url) {
            this.deleteChannelCall(artist, channel).then(data=>this.props.dispatch(channelsActions.addChannel(artist, url))).then(this.props.refresh);
            this.showModal(false);
        }
    }
    
    
    render (){
        let { artist, channel = {} } = this.props;
        const editMode = channel.hasOwnProperty('url');
        const formTitle = editMode ? 'Edit Channel' : 'Add Channel';
        const buttonStyle = editMode ? '' : 'fa-plus';
        const url = channel.url ? new URL(channel.url) : {};
        const channelIcon = channel.channel_type_name == 'youtube-channel' ? 'youtube' : channel.channel_type_name;
        const channelEnabledTitle = channel.enabled ? 'Disable Channel':'Enable Channel';
        const channelEnabledClass = channel.enabled ? 'fa-check-square':'fa-square';
        return <div className="socials-list">
            <a key="add" title={formTitle} onClick={()=>this.showModal(true)}>{channel.url ? <span className="channel-path" title={channel.url}> <i className={`fab fa-${channelIcon}`}  /> {url.pathname} </span>  : ''}<i className={`fa ${buttonStyle}`} /></a>
            {channel.url && <a className="delete-channel" key="delete" title={'Delete Channel'} onClick={()=>this.deleteChannel(artist, channel)}><i className="fa fa-times" /></a>}
            {channel.url && <a className="delete-channel" key="toggle" title={channelEnabledTitle} onClick={()=>this.toggleEnabled(channel)}><i className={`fas ${channelEnabledClass}`} /></a>}
            <AddChannelForm title={formTitle} show={this.state.showModal} onSubmit={this.addChannel} values={channel} onClose={(e)=>this.showModal(false)} />            
        </div>
    }
}

function mapStateToProps(state) {
    return {
        channels: state.channels
    } 
}

export default connect(mapStateToProps)(AddChannelLink);