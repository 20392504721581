import React from 'react';
import { connect } from 'react-redux';
import { map, find, findIndex, isEqual, cloneDeep, get } from 'lodash';
import { Link } from "react-router-dom";
import { playlistsActions } from '../../../data/actions/playlists';
import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters';
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';

class PlaylistFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			playlists: [],
			searchResults: []
        }
	}
    
    componentDidMount(){
		this.props.dispatch(userEntityFiltersActions.getPlaylistEntityFilters());
    }
    
    render (){
		const { playlistLoading, playlistFilters = []} = this.props.userEntityFilters;
        const { filtered = {} } = this.props;
        let selectedPlaylistIDs = [];
        if(filtered.entity_type == 'playlists') {
            selectedPlaylistIDs = filtered.entity_id.split(',').sort();
        }
		let options = playlistFilters.map(filter=>({
            filterID: filter.id,
			label: filter.caption,
			value: filter.entities,
            ids: map(filter.entities, 'entity_id').sort()
		}));
        
        options.unshift({
            filterID: null,
            label: '-view all-', 
            value: null,
            ids: null
        });
        
        const value = find(options, option=>isEqual(option.ids, selectedPlaylistIDs));
		
        return <div className='saved-filter-select'>
			<ReactSelect placeholder="Playlist Saved Filters" options={options} value={value} onChange={this.props.onChange} />
			{/*<Link to="/playlists/filter">Manage Saved Filters</Link>*/}
		</div>
    }
    
    
}

function mapStateToProps(state) {
    return {
        playlists: state.playlists,
		userEntityFilters: state.userEntityFilters
    }
}


export default connect(mapStateToProps)(PlaylistFilter);