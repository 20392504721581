import { playlistsConstants } from '../constants/playlists';

function getTopPlaylistKey(action) {
    const entity = action.entity || ''; 
    return `top${entity}`;    
}

export function playlists(state = {timeline: {}, details: {}, stats:{}, share:{}, benchmarks:{}}, action) {
    const topKey = getTopPlaylistKey(action);
    
    switch (action.type) {
    
        case playlistsConstants.GET_PLAYLISTS_REQUEST:
            return {
                ...state,
                loading: true,
                items: [],
                total: []
            };
        case playlistsConstants.GET_PLAYLISTS_SUCCESS:
            return {
                
                ...state,
                loading: false,
                items: action.playlists,
                total: action.total
            };
        case playlistsConstants.GET_PLAYLISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
            
        case playlistsConstants.GET_TOP_PLAYLISTS_REQUEST:
            
            return {
                ...state,
                [`${topKey}Loading`]: true,
                [topKey]: null,
                metadata: null,
                timeseries: null
            };
        case playlistsConstants.GET_TOP_PLAYLISTS_SUCCESS:
            
            return {
                ...state,
                [`${topKey}Loading`]: false,
                metadata: action.metadata,
                [topKey]: action.playlists
            };
        case playlistsConstants.GET_TOP_PLAYLISTS_FAILURE:
            return {
                ...state,
                [`${topKey}Loading`]: false,
                error: action.error
            };
            
        case playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autocomplete_loading: true
            };
        case playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS:
            return {
                
                ...state,
                autocomplete_loading: false,
                autocomplete_items: action.playlists
            };
        case playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autocomplete_loading: false,
                error: action.error
            };
        case playlistsConstants.GET_PLAYLIST_TIMELINE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case playlistsConstants.GET_PLAYLIST_TIMELINE_SUCCESS:
            let timeline = state.timeline;
            timeline[action.id] = action.playlist;
            return {
                ...state,
                loading: false,
                timeline: timeline
            };
        case playlistsConstants.GET_PLAYLIST_TIMELINE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case playlistsConstants.GET_PLAYLIST_DETAILS_REQUEST:
            return {
                ...state,
                detailsLoading: true
            };
        case playlistsConstants.GET_PLAYLIST_DETAILS_SUCCESS:
            let details = state.details;
            details[action.id] = action.playlist;
            return {
                ...state,
                detailsLoading: false,
                details: details,
                benchmarks: action.benchmarks
            };
        case playlistsConstants.GET_PLAYLIST_DETAILS_FAILURE:
            return {
                ...state,
                detailsLoading: false,
                error: action.error
            };
     
        case playlistsConstants.GET_PLAYLIST_STATS_REQUEST:
            return {
                ...state,
                statsLoading: true
            };
        case playlistsConstants.GET_PLAYLIST_STATS_SUCCESS:
            let stats = state.stats;
            stats[action.id] = action.playlist;
            return {
                ...state,
                statsLoading: false,
                stats: stats
            };
        case playlistsConstants.GET_PLAYLIST_STATS_FAILURE:
            return {
                ...state,
                statsLoading: false,
                error: action.error
            };

        case playlistsConstants.GET_TIMESERIES_PLAYLISTS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case playlistsConstants.GET_TIMESERIES_PLAYLISTS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.playlists
            };
        case playlistsConstants.GET_TIMESERIES_PLAYLISTS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };

        case playlistsConstants.GET_PLAYLIST_SHARE_REQUEST:
            return {
                ...state,
                shareLoading: true
            };
        case playlistsConstants.GET_PLAYLIST_SHARE_SUCCESS:
            let share = state.share;
            share[action.id] = action.playlist;
            return {
                ...state,
                shareLoading: false,
                share: share
            };
        case playlistsConstants.GET_PLAYLIST_SHARE_FAILURE:
            return {
                ...state,
                shareLoading: false,
                error: action.error
            };

        case playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_REQUEST:
            return {
                ...state,
                typesSourcesLoading: true
            };
        case playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_SUCCESS:
            return {
                ...state,
                typesSourcesLoading: false,
                typesSources: action.stats
            };
        case playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_FAILURE:
            return {
                ...state,
                typesSourcesLoading: false,
                error: action.error
            };
            
        case playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_REQUEST:
            return {
                ...state,
                typesSourcesDiscoveryLoading: true
            };
        case playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_SUCCESS:
            return {
                ...state,
                typesSourcesDiscoveryLoading: false,
                typesSourcesDiscovery: action.stats
            };
        case playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_FAILURE:
            return {
                ...state,
                typesSourcesDiscoveryLoading: false,
                error: action.error
            };            

        case playlistsConstants.GET_PLAYLIST_BENCHMARKS_REQUEST:
            return {
                ...state,
                benchmarksLoading: true
            };
        case playlistsConstants.GET_PLAYLIST_BENCHMARKS_SUCCESS:
            let benchmarks = state.benchmarks;
            benchmarks[action.id] = action.playlist;
            return {
                ...state,
                benchmarksLoading: false,
                benchmarks: benchmarks
            };
        case playlistsConstants.GET_PLAYLIST_BENCHMARKS_FAILURE:
            return {
                ...state,
                benchmarksLoading: false,
                error: action.error
            };
            
        case playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.playlists
            };
        case playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };

            
        default:
            return state
    }
} 