import { notificationReportsConstants } from '../constants/notification_reports';

const initialState = {
  settings: [],
  data: [],
  frequencies: {},
  loading: false,
  error: null,
}

export function notification_reports( state= initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case notificationReportsConstants.GET_NOTIFICATION_REPORTS_REQUEST:
      return { ...state, loading: true };
    case notificationReportsConstants.GET_NOTIFICATION_REPORTS_SUCCESS:
      return { ...state, loading: false, error: false, data: payload };
    case notificationReportsConstants.GET_NOTIFICATION_REPORTS_FAILURE:
      return { ...state, loading: false, error: payload, data: [] };
    case notificationReportsConstants.GET_NOTIFICATION_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case notificationReportsConstants.GET_NOTIFICATION_SETTINGS_SUCCESS:
      return { ...state, loading: false, error: false, settings: payload };
    case notificationReportsConstants.GET_NOTIFICATION_SETTINGS_FAILURE:
      return { ...state, loading: false, error: payload, settings: [] };
    case notificationReportsConstants.GET_FREQUENCIES_REQUEST:
      return { ...state, loading: true };
    case notificationReportsConstants.GET_FREQUENCIES_SUCCESS:
      return { ...state, loading: false, error: false, frequencies: payload };
    case notificationReportsConstants.GET_FREQUENCIES_FAILURE:
      return { ...state, loading: false, error: payload, frequencies: {} };
    case notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_REQUEST:
      return { ...state, loading: true };
    case notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_SUCCESS:
      return { ...state, loading: false, error: false, result: payload };
    case notificationReportsConstants.UPDATE_USER_NOTIFICATION_REPORTS_FAILURE:
      return { ...state, loading: false, error: payload, result: {} };
    default:
      return state
  }
}
