import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    render() {
        const onSubmit = this.props.onSubmit;
        return (
            <Form onSubmit={onSubmit} initialValues={{}}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form className="m-t" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="font-normal">Email</label>
                            <Field name="email" component="input" type="text" className="form-control" placeholder="" />
                        </div>
                        <div className="form-group">
                            <label className="font-normal">Password</label>
                            <Field name="password" component="input" type="password" className="form-control" placeholder="" />
                        </div>
                        <div className="checkbox-holder">
                            <input type="checkbox" value="" className="input" id="remember" />
                            <label htmlFor="remember" className="checkbox-label">Remember me</label>
                        </div>

                        <button type="submit" disabled={submitting || pristine} className="btn default-btn block full-width m-b">Log in</button>
                    </form>
                )}
            />
        );
    }
}

export default LoginForm
