import { map } from 'lodash'

export const notificationsFormatter = {
    formatNotifications,
    notificationsCountFormat
};

function formatNotifications(data){
    return {data, count: data.length}
}

function notificationsCountFormat(data) {
  return data && data.count && data.count !== 0;
}
