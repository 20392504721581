import { releasesConstants } from '../constants/releases';

const initialState = {
  new: [],
  releasesSuccess: false,
  releasesFailure: false,
  releasesInProgress: false,
  pagination: {}
}

export function releases(state = initialState, action = {}){
  switch (action.type) {
    case releasesConstants.GET_NEW_RELEASES_REQUEST:
      return { ...state, releasesInProgress: true };
    case releasesConstants.GET_NEW_RELEASES_SUCCESS:
      return { ...state, new: action.payload.releases, pagination: action.payload.pagination, releasesSuccess: true, releasesFailure: false, releasesInProgress: false };
    case releasesConstants.GET_NEW_RELEASES_FAILURE:
      return { ...state, releasesSuccess: false, releasesFailure: true, releasesInProgress: false };
    default:
      return state;
  }
}
