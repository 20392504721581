import { channelsConstants } from '../constants/channels';
import { channelsService } from '../services/channels';
import { channelsFormatter } from '../formatters/channels';
import { errorActions } from './error';
import { artistsActions } from './artists';

export const channelsActions = {
    getChannelTypes,
    addChannel,
    deleteChannel,
    toggleEnabled
};


function getChannelTypes(useCache=true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        const types = useCache ? getState().channels.types : undefined;
        if(types!==undefined) {
            dispatch(success(types));
            return types;
        }
        return channelsService.getChannelTypes()
            .then(
                channels => {
                    const data = channelsFormatter.formatChannelTypes(channels);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: channelsConstants.GET_CHANNEL_TYPES_REQUEST } }
    function success(channels) { return { type: channelsConstants.GET_CHANNEL_TYPES_SUCCESS, channels } }
    function failure(error) { return { type: channelsConstants.GET_CHANNEL_TYPES_FAILURE, error } }
}


function addChannel(artist, url, bulk = false) {
    return dispatch => {
        dispatch(request());
        return channelsService.addChannel(artist, url)
            .then(
                result => {
                    dispatch(success(result.channel));
                    if(!bulk) {
                        if(result.channel) {
                            dispatch(errorActions.setSuccessMessage(result.message));    
                        }
                        else {
                            dispatch(errorActions.setErrorMessage(result.message));
                        }
                        //const message = `${result.message}. The system needs some time to add the social. It will be added soon.`
                        // dispatch(errorActions.setSuccessMessage(message));
                    }
                    return result.channel;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error adding channel'))
                }
            );
    };

    function request() { return { type: channelsConstants.ADD_CHANNEL_REQUEST } }
    function success(channel) { return { type: channelsConstants.ADD_CHANNEL_SUCCESS, channel } }
    function failure() { return { type: channelsConstants.ADD_CHANNEL_FAILURE} }
}


function deleteChannel(artist, channel) {
    return dispatch => {
        dispatch(request());
        return channelsService.deleteChannel(artist, channel)
            .then(
                result => {
                    dispatch(success(channel));
                    return true;
                    //const message = `Channel successfully deleted.`
                    //dispatch(errorActions.setSuccessMessage(message))
                    //dispatch(artistsActions.getArtistProfile(artist));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error deleting channel.'))
                }
            );
    };

    function request() { return { type: channelsConstants.DELETE_CHANNEL_REQUEST } }
    function success(channel) { return { type: channelsConstants.DELETE_CHANNEL_SUCCESS, channel } }
    function failure() { return { type: channelsConstants.DELETE_CHANNEL_FAILURE} }
}

function toggleEnabled(channel) {
    return dispatch => {
        dispatch(request());
        return channelsService.toggleEnabled(channel)
            .then(
                result => {
                    dispatch(success(channel));
                    const message = `Channel successfully ${result.enabled?'enabled':'disabled'}`;
                    dispatch(errorActions.setSuccessMessage(message));
                    return true;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('Error enabling channel.'))
                }
            );
    };

    function request() { return { type: channelsConstants.TOGGLE_CHANNEL_REQUEST } }
    function success(channel) { return { type: channelsConstants.TOGGLE_CHANNEL_SUCCESS, channel } }
    function failure() { return { type: channelsConstants.TOGGLE_CHANNEL_FAILURE} }
}
