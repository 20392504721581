import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import { isEqual, maxBy, minBy } from 'lodash';

import CSV from '../../../helpers/CSVExport';
import { convertToThousands, splitByThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import PercentageValue from '../../common/PercentageValue';

import BarChart from '../../widgets/charts/types/BarChart';
import ChartLegend from '../../widgets/charts/ChartLegend';
import EntitySelector from '../../pages/result/EntitySelector';

import { audienceActions } from '../../../data/actions/audience';
import { playlistSettings } from './Playlists/Settings';

const downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class RepeatListeners extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            view: props.defaultMode || 'table',
            entityFilter: null
        }
        this.setChartView = this.setChartView.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
    }

    componentDidMount(){
        this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter);
            }
        }
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.props.getRepeatListeners(this.props.id, this.props.entity, this.state.entityFilter));
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader(`audience_repeat_listeners`, 'listeners', this.props.filter.global, this.props.parentEntityTitle);
        return CSV.CSVExport(this.props.audience.repeatListeners.table, {filename}, 'audience_repeat_listeners');
    }

    // renderToolbar(modes){
    //     let toolbar = [];
    //     toolbar.push(<div key="wrapper" className="ibox-action-holder">
    //     <div className="ibox-actions">
    //       <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
    //     </div>
    //     </div>)
    //     return toolbar;
    // }

    renderLegend(dataset){
        let labels = [],
            colors = [];
        if(!dataset || !dataset.length)
            return null;
        
        for(let index = 3; index>=0; index--) {
            for(let datasetIndex = 0; datasetIndex<4; datasetIndex++) {
                const row = dataset[datasetIndex];
                if(row.data[index]) {
                    labels.push(row.labels[index]);
                    colors.push(row.backgroundColor[index]);
                }
            }
        }
        return <ChartLegend labels={labels} colors={colors} />
    }

    setChartView(view){
        this.setState({view});
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    renderChartActions(){
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entityFilterMode="array" />        
        </React.Fragment>
    }    
    
    render(){
        const { audience } = this.props;
        const chartAxesLabels = ['11+', '6-10', '2-5', '1'];
        const options = {
            maintainAspectRatio: false,
            indexAxis: 'y',
            layout: {
                padding: {
                    right: 40
                }
            },
            scales: {
                x: {
                    //barThickness: 20,
                    stacked: true,
                    "ticks": {
                        callback: function formatAxesLabel(value, index, values){
                            return splitByThousands(value)
                        }
                    },
                    title: {
                        display: true,
                        text: '# of Listeners'
                    }
                },
                y: {
                    //barThickness: 20,
                    stacked: true,
                    "ticks": {
                        callback: function formatAxesLabel(value, index, values){
                            return chartAxesLabels[index]
                        }
                    },
                    title: {
                        display: true,
                        text: '# of Listens'
                    }
                }
            },    
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        label: function(context) {
                            const { dataset, dataIndex: index } = context;
                            if(!dataset.labels)
                                return null;
                            
                            return (dataset.labels[index] && dataset.data[index]) ? dataset.labels[index] + ': ' + splitByThousands(dataset.data[index]) : null;
                        }
                    }
                }
            }
        };
        
        let dataset = [];
        if(audience.repeatListeners && audience.repeatListeners.dataset) {
            dataset = audience.repeatListeners.dataset;
            dataset.barThickness = 20;
        }
            
        
        return <Box title="Repeat Listeners" chartActions={this.renderChartActions()} className="" spinnerEnabled={audience.repeatListenersLoading} exportToCsv={this.renderExportToCsv()} >
                <div className="row">
                    <div className={`col-xs-12`}>
                        {audience.repeatListeners && <div className="repeat-listeners-chart">
                            <BarChart shadowChart={true} shadowChartWidth={800} shadowChartHeight={300} id="audience_repeat_listeners_line_chart" data={{labels: ['1', '2 - 5', '6 - 10', '11+'].reverse(), datasets: dataset}} options={options} />
                        </div>}
                        {/*this.renderLegend(dataset)*/}
                    </div>
                </div>
            </Box>
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getRepeatListeners: (id, entity, entityFilter) => {
            return dispatch(audienceActions.getRepeatListeners(id, entity, entityFilter ? entityFilter : ownProps.filtered))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RepeatListeners)
