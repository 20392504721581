import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AbsoluteValue from '../../../common/AbsoluteValue';
import PercentageValue from '../../../common/PercentageValue';
import Tooltip from '../../../common/Tooltip';
import GenderSplitBox from '../../../common/Datagrid/GenderSplitBox';
import { avgFooter, convertSumFooter, spaceFooter, textFooter } from '../../../common/Datagrid/Footers';
import CopyToClipboard from '../../../common/CopyToClipboard';

var copySvg = require('!svg-inline-loader!../../../../../public/img/copy-icon.svg');

function renderName(item) {
    let name;
    if(item.name)
        name = `${item.name} (${item.isrc})`;
    else
        name = item.isrc;
    
    return <Link to={`/tracks/${item.id}`}>{name}</Link>
}


export const playlistTracksSettings = {
    settingsForTable
};

const modes={
    'expanded': ['artist_name', 'track', 'isrc', 'position', 'curr_units'],
    'details': ['artist_name', 'track_and_isrc', 'position', 'prev_units', 'curr_units', 'growth', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'days_in_list']
};

function settingsForTable(mode, showPosition = true){
    let columns = [
		/*
        {
            id: 'artist_name',
            className: 'playlist-subtable-td details-td',
            headerClassName: 'playlist-subtable-td details-td ',
            Header: 'Artist',
            accessor: 'artist_name',
            // width: 450,
        },                                 
        {
           id: 'track',
           className: 'playlist-subtable-td details-td',
           headerClassName: 'playlist-subtable-td details-td ',
           Header: 'Track',
           //accessor: 'name',
        //    width: 280,
           Cell: props => <Link to={`/tracks/${props.original.id}`}>{props.original.name}</Link>,
           Footer: 'Total: '
       },
       {
           id: 'isrc',
           className: 'playlist-subtable-td',
           headerClassName: 'playlist-subtable-td ',
           Header: 'ISRC',
           accessor: 'isrc',
           width: 250,
       },
	   {
			  id: 'track_and_isrc',
			  headerClassName: 'playlist-subtable-td details-td ',
			  className: 'playlist-subtable-td details-td',
			  Header: 'Name',
			  Cell: props => renderName(props.original),
		  },  
	   */
	   
	   {
		   id: 'logo',
		   className: 'logo-sell ',
		   headerClassName: 'logo-sell no-sort',
		   Header: 'Logo',
		   accessor: 'image',
		   width: 80,
		   sortable: false,
		   Cell: props => {
			   // const onClickCall = (e)=>{return onTitleClick(props.original);};
			   return <div className="logo-container">
				   <Link to={`/tracks/${props.original.id}`}>
					   <div className="logo" style={{backgroundImage: `url(${props.original.image})`}} />
				   </Link>
			   {props.original.id ? <div className="logo-counter" title={`${props.original.curr_products} products reported for this date range`}>{props.original.curr_products}</div> : null}</div>;
		   },
		   Footer: spaceFooter
	   },
	   {
		   id: "artist_name",
		   className: "filter-subtable-track-info",
		   headerClassName: " no-sort",
		   Header: "Track",
		   accessor: "artist_name",
		   sortable: false,
		   Cell: props => {
			   const { id, artist_id, artist_name, name, isrc } = props.original;
			   return <React.Fragment>
				   <span>{props.original.id ? <Link to={`/tracks/${props.original.id}`}>{name} {props.original.track_version && `(${props.original.track_version})`}</Link> : name}</span>                
				   <span className="artist-name-trimmed"><Link to={`/artists/${artist_id}`}>{artist_name}</Link></span>
				   <span className="isrc-holder with-copy">
				   {id ? <CopyToClipboard value={isrc}>{isrc}</CopyToClipboard> : <span>{isrc}</span>}</span>
			   </React.Fragment>
		   },
		//    width: 250,
		   Footer: textFooter
		 }, 
	   
	    {
            id: 'position',
            className: 'playlist-subtable-td',
            headerClassName: 'playlist-subtable-td rt-resizable-header',
            Header: 'Position',
            accessor: 'position',
            width: 90
		},	   
                     
		/*			    
       {
           id: 'prev_units',
           headerClassName: '',
           Header: 'Prev Streams',
           accessor: 'prev_units',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
            Footer: convertSumFooter
       },
	   */
       
       {
           id: 'curr_units',
           className: 'playlist-subtable-td',
           headerClassName: 'playlist-subtable-td rt-resizable-header',
           Header: 'Units',
           accessor: 'curr_units',
           Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
           width: 150,
            Footer: convertSumFooter
       },
	   /*
       {
           id: 'growth',
           headerClassName: '',
           Header: 'Growth',
           Cell: props => <PercentageValue field="units" item={props.original} onlyPercentage={true}></PercentageValue>,
           Footer: spaceFooter
           // width: 96,
       },
       {
           id: 'passive',
           headerClassName: '',
           Header: props => <div className="top-table-header-inner">
               Passive Streams
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`Passive Streams: editorial, third party and algorithmic playlists, radio, and other (such as the Spotify autoplay feature). This may also include streams from a playlist with the artist name (This is, Essentials…) where the artist is not the main primary artist on the track, like featuring or remixes for instance.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'passive',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
                   Footer: convertSumFooter
           // width: 138
       },
	   
       
       {
           id: 'active',
           headerClassName: '',
           Header: props => <div className="top-table-header-inner">
               Active Streams
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`Active Streams: streams coming from search, artist page, album page, collections, and playlists with the artist name in it (This is, Essentials…)`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'active',
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
                   Footer: convertSumFooter
           // width: 138
       },
       {
           id: 'engaged',
           headerClassName: '',
           Header: 'Engaged',
           accessor: 'engaged',
           Cell: props => <span>{props.value}%</span>,
           Footer: avgFooter
           // width: 138
       },
       */
	   {
			  id: 'curr_skipped',
			  className: 'small-td',
			  headerClassName: ' small-td rt-resizable-header',
			  Header: <span>Skips </span>,
			  Cell: props => <AbsoluteValue className="color-red" value={props.value}></AbsoluteValue>,
			  width: 110,
			  accessor: 'curr_skipped',
			  Footer: convertSumFooter
		  },
		  {
			  id: 'curr_completed',
			  headerClassName: 'rt-resizable-header',
			  Header: <span>Completed</span>,
			  Cell: props => <AbsoluteValue className="color-light-green" value={props.value}></AbsoluteValue>,
			  width: 110,
			  accessor: 'curr_completed',
			  Footer: convertSumFooter
		  },

       {
           id: 'skipped_ratio',
           headerClassName: 'rt-resizable-header',
           Header: props => <div className="top-table-header-inner">
               Skip Rate
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'skipped_ratio',
           Cell: props => <span>{props.value}%</span>,
           Footer: avgFooter,
		   width: 120,
           // width: 138
       },       
       {
           id: 'completed_ratio',
           headerClassName: 'rt-resizable-header',
           Header: props => <div className="top-table-header-inner">
               Completion Rate
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'completed_ratio',
           Cell: props => <span>{props.value}%</span>,
           Footer: avgFooter,
		   width: 130,
           // width: 138
       },            
       {
           id: 'days_in_list',
           Header: 'Days in list',
           headerClassName: 'rt-resizable-header',
           accessor: 'days_in_list',
           Cell: props => <span>{props.value}</span>,
           // width: 134
           sortable: false,
           Footer: (props)=>spaceFooter(props, null, null, ''),
		   width: 100,
       },
	   
	   {
			  id: 'gender',
			  className: 'gender-td',
			  headerClassName: 'rt-resizable-header no-sort',
			  Header: 'Gender Ratio M/F/U',
			  Cell: props => <GenderSplitBox item={props.original} />,
			  Footer: spaceFooter,
			  width: 110,
              sortable: false,
			  // width: 130
	   
		  },
	   {
				id: 'spacer',
				className: '',
				headerClassName: 'rt-resizable-header no-sort',
				Header: '',
				Cell: props => <span></span>,
                sortable: false,
				Footer: spaceFooter,
				// width: 130
		  
			},

       
    ];
    return columns.map(column=>{
        //column.show = modes[mode].includes(column.id);
		if(column.id == 'position')
			column.show = showPosition;
			
        return column;
    });    
}
