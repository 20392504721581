import React from 'react';
import { connect } from 'react-redux';
import store from '../../../helpers/store'
import icons from '../../../helpers/Icons';
import { searchActions } from '../../../data/actions/search';
import { withRouter } from 'react-router';

const filterSvg = require('!svg-inline-loader!../../../../public/img/saved-filter-icon.svg');

class RecentSearches extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.searchResult = this.searchResult.bind(this);
    this.clearRecent = this.clearRecent.bind(this);
  }

  clearRecent() {
      store.set('recentSearches', []);
      this.props.clearRecent();
  }
  
  searchResult(item) {
      this.props.history.push(`/${item.entity}/${item.id}`);
  }
  
  render() {
      const { search: { recentSearches }, renderMode } = this.props;
      if(!recentSearches || !recentSearches.length)
          return null;
      for(let entity of recentSearches) {
          entity.logo = icons.entities[entity.entity];
      }
      return <div className={`search-filter-item search-filter-${renderMode}`}>
            <h2 className="search-title">
                <span className="search-title-text">Recent</span>
                <a onClick={this.clearRecent} className="edit-saved">Clear recent</a>
            </h2>
            <ul className="pinned-filter-list">
                {recentSearches.map(item=><li className={`filter pinned-filter-list-item`} key={item.id} onClick={()=>this.searchResult(item)}>
                    <div className="saved-filter-item">
                        { item.logo && <span className="saved-filter-icon" dangerouslySetInnerHTML={{__html: item.logo}}></span> }
                        { item.image ? <span className="image"><img src={item.image} /></span> : <span className="saved-filter-item-icon" dangerouslySetInnerHTML={{__html: filterSvg}}></span>}
                    </div>
                    <div className="saved-filter-item-text">{item.name}</div>
                </li>)}
            </ul>
        </div>
  }
}

function mapStateToProps(state, ownProps) {
    return {
        search: state.search,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        clearRecent: (item) => { 
           return dispatch(searchActions.clearRecentItems());
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecentSearches))