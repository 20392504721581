import React, { Component } from 'react';
import { find } from 'lodash';
import { components } from 'react-select'
import ReactSelect from './ReactSelect';
const { Option } = components;

const CustomSelectOption = (props) => {
  return <Option {...props} className={`${props.isSelected ? 'selected' : ''}`}>
    <div className={`search-entity-logo`} dangerouslySetInnerHTML={{ __html: props.data.icon }}></div>
    {props.data.label}
  </Option>
};

const CustomSelectValue = (props) => (
  <div className="search-select-option">
    <div className={`search-entity-logo`} dangerouslySetInnerHTML={{ __html: props.data.icon }}></div>
    {props.data.label}
  </div>
);

class ModeSelect extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        let { options, selected, onChange, ...rest } = this.props,
            value = find(options, (option)=>option.value == selected);
        return <ReactSelect options={options} value={value} onChange={({value})=>onChange(value)} components={{
            Option: CustomSelectOption,
            SingleValue: CustomSelectValue,
          }} {...rest} className="select-wrapper" />
    }
}

export default ModeSelect;