import { statsConstants } from '../constants/stats';

export function stats(state = {locations: {}}, action) {
    switch (action.type) {
        case statsConstants.GET_TOP_STATS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: undefined
            };
        case statsConstants.GET_TOP_STATS_SUCCESS:
            return {
                
                ...state,
                topLoading: false,
                top: action.stats
            };
        case statsConstants.GET_TOP_STATS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_CATALOG_STATS_REQUEST:
            return {
                ...state,
                catalogLoading: true
            };
        case statsConstants.GET_CATALOG_STATS_SUCCESS:
            return {
                
                ...state,
                catalogLoading: false,
                catalog: action.stats
            };
        case statsConstants.GET_CATALOG_STATS_FAILURE:
            return {
                ...state,
                catalogLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_VENDOR_STATS_REQUEST:
            return {
                ...state,
                vendorLoading: true,
                vendor: undefined
            };
        case statsConstants.GET_VENDOR_STATS_SUCCESS:
            return {
                
                ...state,
                vendorLoading: false,
                vendor: action.stats,
                vendorStatsCache: action.cache
            };
        case statsConstants.GET_VENDOR_STATS_FAILURE:
            return {
                ...state,
                vendorLoading: false,
                error: action.error
            };

        case statsConstants.GET_VENDOR_TIMESERIES_REQUEST:
            return {
                ...state,
                vendorTimeseriesLoading: true,
                vendorTimeseries: undefined
            };
        case statsConstants.GET_VENDOR_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                vendorTimeseriesLoading: false,
                vendorTimeseries: action.stats,
                vendorTimeseriesCache: action.cache
            };
        case statsConstants.GET_VENDOR_TIMESERIES_FAILURE:
            return {
                ...state,
                vendorTimeseriesLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                territoryLoading: true,
                territory: undefined,
                territoryTimeseries: undefined
            };
        case statsConstants.GET_TERRITORY_STATS_SUCCESS:
            return {
                
                ...state,
                territoryLoading: false,
                territory: action.stats
            };
        case statsConstants.GET_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                territoryLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_TERRITORY_TIMESERIES_REQUEST:
            return {
                ...state,
                territoryLoading: true,
                territoryTimeseries: undefined                
            };
        case statsConstants.GET_TERRITORY_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                territoryLoading: false,
                territoryTimeseries: action.stats
            };
        case statsConstants.GET_TERRITORY_TIMESERIES_FAILURE:
            return {
                ...state,
                territoryLoading: false,
                error: action.error
            };
            

        case statsConstants.GET_TOP_TERRITORY_STATS_REQUEST:
            return {
                ...state,
                topTerritoryLoading: true,
                topTerritory: undefined,
                timeseries: undefined
                
            };
        case statsConstants.GET_TOP_TERRITORY_STATS_SUCCESS:
            return {
                
                ...state,
                topTerritoryLoading: false,
                topTerritory: action.stats,
                topTerritoryCache: action.cache
            };
        case statsConstants.GET_TOP_TERRITORY_STATS_FAILURE:
            return {
                ...state,
                topTerritoryLoading: false,
                error: action.error
            };

        case statsConstants.GET_TOP_TERRITORY_TIMESERIES_REQUEST:
            return {
                ...state,
                topTimeseriesLoading: true,
                topTerritoryTimeseries: undefined
                
            };
        case statsConstants.GET_TOP_TERRITORY_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                topTimeseriesLoading: false,
                topTerritoryTimeseries: action.timeseries
            };
        case statsConstants.GET_TOP_TERRITORY_TIMESERIES_FAILURE:
            return {
                ...state,
                topTimeseriesLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_DEMOGRAPHICS_STATS_REQUEST:
            return {
                ...state,
                demographicsLoading: true,
                demographics: undefined,
                demographicsTimeseries: undefined,
            };
        case statsConstants.GET_DEMOGRAPHICS_STATS_SUCCESS:
            return {
                
                ...state,
                demographicsLoading: false,
                demographics: action.stats
            };
        case statsConstants.GET_DEMOGRAPHICS_STATS_FAILURE:
            return {
                ...state,
                demographicsLoading: false,
                error: action.error
            };  
            
        case statsConstants.GET_DEMOGRAPHICS_TIMESERIES_REQUEST:
            return {
                ...state,
                demographicsLoading: true,
                demographicsTimeseries: undefined                
            };
        case statsConstants.GET_DEMOGRAPHICS_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                demographicsLoading: false,
                demographicsTimeseries: action.stats
            };
        case statsConstants.GET_DEMOGRAPHICS_TIMESERIES_FAILURE:
            return {
                ...state,
                demographicsLoading: false,
                error: action.error
            };  

        case statsConstants.GET_DEVICES_STATS_REQUEST:
            return {
                ...state,
                devicesLoading: true,
                devices: undefined                
            };
        case statsConstants.GET_DEVICES_STATS_SUCCESS:
            return {
                
                ...state,
                devicesLoading: false,
                devices: action.stats
            };
        case statsConstants.GET_DEVICES_STATS_FAILURE:
            return {
                ...state,
                devicesLoading: false,
                error: action.error
            };  

        case statsConstants.GET_SOURCES_STATS_REQUEST:
            return {
                ...state,
                sourcesStatsLoading: true,
                sourcesStats: undefined,
                sourcesTimeseries: undefined
            };
        case statsConstants.GET_SOURCES_STATS_SUCCESS:
            return {
                
                ...state,
                sourcesStatsLoading: false,
                sourcesStats: action.stats
            };
        case statsConstants.GET_SOURCES_STATS_FAILURE:
            return {
                ...state,
                sourcesStatsLoading: false,
                error: action.error
            };  

        case statsConstants.GET_SOURCES_DISCOVERY_STATS_REQUEST:
            return {
                ...state,
                sourcesDiscoveryStatsLoading: true,
                sourcesDiscoveryStats: undefined,
                sourcesDiscoveryStatsTimeseries: undefined
            };
        case statsConstants.GET_SOURCES_DISCOVERY_STATS_SUCCESS:
            return {
                
                ...state,
                sourcesDiscoveryStatsLoading: false,
                sourcesDiscoveryStats: action.stats
            };
        case statsConstants.GET_SOURCES_DISCOVERY_STATS_FAILURE:
            return {
                ...state,
                sourcesDiscoveryStatsLoading: false,
                error: action.error
            };  

            
        case statsConstants.GET_SOURCES_TIMESERIES_REQUEST:
            return {
                ...state,
                sourcesTimeseriesLoading: true,
                sourcesTimeseries: undefined                
            };
        case statsConstants.GET_SOURCES_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                sourcesTimeseriesLoading: false,
                sourcesTimeseries: action.stats,
                sourcesTimeseriesCache: action.cache
            };
        case statsConstants.GET_SOURCES_TIMESERIES_FAILURE:
            return {
                ...state,
                sourcesTimeseriesLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_SOURCES_DISCOVERY_TIMESERIES_REQUEST:
            return {
                ...state,
                sourcesDiscoveryTimeseriesLoading: true,
                sourcesDiscoveryTimeseries: undefined                
            };
        case statsConstants.GET_SOURCES_DISCOVERY_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                sourcesDiscoveryTimeseriesLoading: false,
                sourcesDiscoveryTimeseries: action.stats,
                sourcesDiscoveryTimeseriesCache: action.cache
            };
        case statsConstants.GET_SOURCES_DISCOVERY_TIMESERIES_FAILURE:
            return {
                ...state,
                sourcesDiscoveryTimeseriesLoading: false,
                error: action.error
            };  
            

        case statsConstants.GET_DEVICES_TIMESERIES_REQUEST:
            return {
                ...state,
                devicesLoading: true,
                devicesTimeseries: undefined,
            };
        case statsConstants.GET_DEVICES_TIMESERIES_SUCCESS:
            return {
                
                ...state,
                devicesLoading: false,
                devicesTimeseries: action.stats,
                devicesTimeseriesCache: action.cache,
            };
        case statsConstants.GET_DEVICES_TIMESERIES_FAILURE:
            return {
                ...state,
                devicesTimeseriesLoading: false,
                error: action.error
            };  
            
        case statsConstants.GET_MULTI_ENTITIES_STREAMS_REQUEST:
            return {
                ...state,
                multiStreamsLoading: true,
                multiStreams: null
            };
        case statsConstants.GET_MULTI_ENTITIES_STREAMS_SUCCESS:
            return {              
                ...state,
                multiStreamsLoading: false,
                multiStreams: action.entities
            };
        case statsConstants.GET_MULTI_ENTITIES_STREAMS_FAILURE:
            return {
                ...state,
                multiStreamsLoading: false,
                error: action.error
            };
            

        case statsConstants.GET_MULTI_ENTITIES_METADATA_REQUEST:
            return {
                ...state,
                multiMetadataLoading: true,
                multiMetadata: []
            };
        case statsConstants.GET_MULTI_ENTITIES_METADATA_SUCCESS:
            return {              
                ...state,
                multiMetadataLoading: false,
                multiMetadata: action.entities,
                metadataMinDate: action.minDate
            };
        case statsConstants.GET_MULTI_ENTITIES_METADATA_FAILURE:
            return {
                ...state,
                multiMetadataLoading: false,
                error: action.error
            };
        
        case statsConstants.RESET_RELEASE_MIN_DATE:
            return {
                ...state,
                metadataMinDate: action.firstDate
            }
            
        case statsConstants.GET_LOCATION_STATS_REQUEST:
            return {
                ...state,
                locationLoading: true,
            };
        case statsConstants.GET_LOCATION_STATS_SUCCESS:
            const { territory, stats } = action;
            return {
                
                ...state,
                locationLoading: false,
                locations: {
                    ...state.locations,
                    [territory]: stats
                }
            };
        case statsConstants.GET_LOCATION_STATS_FAILURE:
            return {
                ...state,
                locationLoading: false,
                error: action.error
            };
            
        case statsConstants.GET_TOP_LOCATIONS_STATS_REQUEST:
            return {
                ...state,
                topLocationsLoading: true,
                topLocations: undefined
                
            };
        case statsConstants.GET_TOP_LOCATIONS_STATS_SUCCESS:
            return {
                
                ...state,
                topLocationsLoading: false,
                topLocations: action.stats
            };
        case statsConstants.GET_TOP_LOCATIONS_STATS_FAILURE:
            return {
                ...state,
                topLocationsLoading: false,
                error: action.error
            };
            
        default:
            return state
    }
} 