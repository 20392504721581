import React from 'react'
import { SketchPicker } from 'react-color';

class ColorPicker extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            expanded: false
        }
        
        this.onChangeComplete = this.onChangeComplete.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getRGB = this.getRGB.bind(this);
    }   
    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    onChangeComplete(color, event){
        const { rgb } = color;
//        this.setState({
//            color: hex
//        }, ()=>{
         
        var rgbStr = "rgba("+ rgb.r + ","+ rgb.g + "," + rgb.b + "," + rgb.a + ")"
        return this.props.input.onChange(rgbStr);
//        });
    }

    getRGB(str){
      var match = str.match(/rgba?\((\d{1,3}),?(\d{1,3}),?(\d{1,3})\)?(?:,?(\d(?:\.\d+?))\))?/);
      return match ? {
        r: match[1],
        g: match[2],
        b: match[3],
        a: match[4] || 1
      } : {};
    }

    handleClose = () => {
        this.setState({ expanded: false })
    };
    
    render() {
        const {input: { name, value='#fff', ...restInput }, meta, className, ...rest} = this.props;
        const val = this.getRGB(value)
        const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        }
        return <div className={`color-picker-wrapper ${className}`}>
            <span className="color-sample" style={{backgroundColor:value}} />
            <input type="button" className="default-btn default-btn--small" value="pick" onClick={this.toggleExpanded} />
            {this.state.expanded && <div className="color-picker"><div style={ cover } onClick={ this.handleClose }/><SketchPicker
                name={name}
                onChangeComplete={this.onChangeComplete}
                color={val}
            /></div>}             
        </div>
    }
}

export default ColorPicker;
