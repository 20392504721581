import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '../helpers/store'
import PinnedEntityFilters from '../components/widgets/entityFilter/PinnedEntityFilters';
//import SearchWidget from '../components/pages/home/SearchWidget';
import RecentSearches from '../components/pages/home/RecentSearches';
import MultiSearch from '../components/pages/home/MultiSearch';
import HomepageTour from '../components/pages/home/Tour';

class Filter extends Component {
    constructor(props) {
        super(props);
//        this.searchResult = this.searchResult.bind(this);        
    }
    
    
    render() {
        return <div className="inner-page">
            <div className="search-page-holder">
                <div className="search-page-item">
                    <h2 className="search-title">
                        <span className="search-title-text">Search</span>
                    </h2>
                    {/*<SearchWidget searchResult={this.searchResult}/>*/}
                    <MultiSearch />
                </div>
                <div className="search-page-item">
                    {/*<RecentSearches renderMode="row"/>*/}
                    <PinnedEntityFilters source="recent" renderMode="row" />
                    <PinnedEntityFilters source="pinned" renderMode="row" />
                </div>
                <HomepageTour />
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters
    } 
}

export default connect(mapStateToProps)(Filter)