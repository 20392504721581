import React from 'react';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';
import { statsActions } from '../../../data/actions/stats';

class EntitySelector extends React.Component {
    
    nullElement = {id: null, label: 'All'}
    
    constructor(props) {
        super(props);
        this.state = {
            entity: this.nullElement
        }
        this.getFilterObject = this.getFilterObject.bind(this);
        this.setEntity = this.setEntity.bind(this);
    }
    
    getFilterObject(entity, id) {
        const { entityFilterMode = 'object' } = this.props;
        if(!(id && entity))
            return null;
        
        if(entityFilterMode == 'array') 
            return [{id: entity, value: [id]}]
        else
            return {[entity]: id};
    }

    setEntity(entity) {
        this.setState({entity});
        const { entityChange } = this.props;
        if(typeof entityChange == 'function') { 
            entityChange(this.getFilterObject(entity.entity, entity.id));
        }
    }
    
    render (){
        let { multiMetadata = [], multiMetadataLoading } = this.props.stats;
        const { entity } = this.props;
        
        if(!multiMetadata.length)
            return null;
        
        multiMetadata = multiMetadata.map(item=>{
            item.label = `${item.name}${item.code?` ${item.code}`:''} (${item.entity.slice(0, -1)})`;
            return item;
        }); 
        multiMetadata.unshift(this.nullElement);
        if(entity) {
            multiMetadata = multiMetadata.filter(item => (item.entity == entity || !item.entity ));
        }
        return <div className="entity-selector-select"><ReactSelect value={this.state.entity} options={multiMetadata} onChange={this.setEntity} className="single-select" /></div>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
    } 
}

export default connect(mapStateToProps)(EntitySelector);