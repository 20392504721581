import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';

import AuthHeader from '../../../helpers/AuthHeader';

class NotificationMenu extends React.Component {
  render() {
      const { notifications, catalogueExceptions, user } = this.props;
      return notifications.count !== 0 && (
        <div className="notification-menu">
          <span className="notification-menu-title">Notifications</span>
          <ul className="user-dropdown-list">
              {catalogueExceptions && catalogueExceptions.count && catalogueExceptions.count.tracks !== 0 && user.client_admin && (
                <li>
                  <a href="/admin/statistics" className="notification-link">
                      <div className="notification-title">Admin - Exceptions</div>
                      <div className="notification-message">{catalogueExceptions.count.tracks || 0} tracks need delivery or exclusion</div>
                      <div className="notification-message">{catalogueExceptions.count.products || 0} products need delivery</div>
                  </a>
                </li>
              )}
              {notifications && notifications.playlist && notifications.playlist.count > 0 && (
                <li>
                  <a onClick={this.props.onUpdatePlaylistNotification} className="notification-link">
                    <div className="notification-title">Store Playlist Additions Notifications</div>
                    <div className="notification-message">You got {notifications.playlist.count} additions for {this.props.todayDate} for playlists that on average generate 100 streams per track per day (DPB).</div>
                  </a>
                </li>
              )}
              {notifications && notifications.heartbeats && notifications.heartbeats.count > 0 && (
                <li onClick={this.props.onUpdateHeartbeatsNotifications} >
                  <a className="notification-link">
                    <div className="notification-title">Heartbeats</div>
                    <div className="notification-message">You got {notifications.heartbeats.count} additions for {this.props.todayDate} for playlists that on average generate 100 streams per track per day (DPB).</div>
                  </a>
                </li>
              )}
              {notifications && notifications.releases && notifications.releases.count > 0 && (
                <li onClick={this.props.onUpdateReleaseNotifications} >
                  <a className="notification-link">
                    <div className="notification-title">Releases</div>
                    <div className="notification-message">Streaming or sales activity increase for {notifications.releases.count} records was detected by data management services.</div>
                  </a>
                </li>
              )}
          </ul>
      </div>);
  }
}

export default NotificationMenu;
