import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import OverviewBox from './OverviewBox';

const styles = StyleSheet.create({
    overviewContainer: {
        flexDirection: 'row',
        // flexWrap: 'wrap',
        justifyContent: 'space-between',
        // marginTop: 24,
        borderWidth: 1,
        borderColor: '#E8E8EA',
        fontSize: 8,
        flexGrow: 1,
    },
});

const Overview = ({data}) => (
    <View style={styles.overviewContainer}>
    {data.map(item=><OverviewBox key={item.title} item={item} />)}
    </View>
);
  
export default Overview