import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import Image from '../../common/PDF/Image';
import styles from '../../common/PDF/LayoutStylesheet';
import Table from '../../common/PDF/Table';
import OverviewPDF from '../../common/PDF/Overview';
import DateRange from '../../common/PDF/DateRange';
import EntityTablePDF from '../home/EntityTable/PDF';
import TerritoryTablePDF from '../home/Territories/PDF';
import PDFHeader from './PDFHeader';

import { splitByThousands, addPercent } from '../../../helpers/NumberFormatter';


export default (props) => {
    const { data: { images, tables, metadata, overview, reportTitle, globalFilter, entity, header }, widgetProps: { widgetState } } = props;

  return <Document>
    <Page size="A4" style={styles.page}>
      <PDFHeader header={header} />
      <View style={styles.section}>
          <OverviewPDF data={header.overview} />
      </View>    
      
      <View style={styles.columnSection}>
          <Text style={styles.smallHeader}>Playlist Overview</Text>
          {widgetState.top_playlists.mode != 'chart' && <View style={styles.column}>
              <EntityTablePDF entity="playlists" data={tables.playlists} />
          </View>}
          {widgetState.top_playlists.mode == 'chart' && <View style={styles.column}>
              <Image source={images.top_playlists} />
          </View>}
      </View>      
      </Page>
  </Document>
};