import React from 'react';
import { filter, isEqual, map, sumBy, find } from 'lodash';

import BarChart from '../../../widgets/charts/types/BarChart';
import PieChart from '../../../widgets/charts/types/PieChart';
import Box from '../../../common/Box';
import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import ReactSelect from '../../../common/ReactSelect';
import { audienceActions } from '../../../../data/actions/audience';
import contentTypes from '../../../widgets/filter/enums/content_types';


class VendorBarChart extends React.Component {
    
    
    renderStackedChart(data) {
        const options = {
            maintainAspectRatio: false,
            indexAxis: 'y',
            scales: {
                x: {
//                    barThickness: 20,
                    stacked: true,
                    display: false
                },
                y: {
//                    barThickness: 20,
                    stacked: true,
                    display: false
                    
                }
            },     
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'nearest',
                    yAlign: 'center',
                    callbacks: {
                        label: function(context) {
                            const vendor = context.dataset.label;
                            return vendor + ': ' + context.raw + '%';
                        }
                    }
    
                }
            }
        };
        return <BarChart data={data} options={options} />
    }
    
    render (){
        const { vendors: allVendors, hiddenVendors = []} = this.props;
        const vendors = allVendors.filter(vendor=>!(hiddenVendors.includes(vendor.vendor_value)));
        const total = sumBy(vendors, 'curr_units');

        let barDataset = [],
            maxPercent = 0,
            maxIndex = 0,
            totalPercent = 0;
    
        for(let vendorIndex in vendors) {
            let vendor = vendors[vendorIndex]
            const percent = Math.round(vendor.curr_units / total * 100);
            totalPercent += percent;
            
            if(percent > maxPercent) {
                maxPercent = percent;
                maxIndex = vendorIndex;
            }
            const contentType = find(contentTypes, type=>type.value == vendor.cont_typ);
            const contentTypeLabel = contentType ? contentType.label : 'N/A';
    
            barDataset[vendorIndex] = {
                label: `${vendor.vendor_value} (${contentTypeLabel})` ,
                data: [percent],
                backgroundColor: vendor.color,
                borderRadius: 5,
                borderColor: '#fff',
                borderWidth: 1,
                // borderSkipped: 'end',
                barThickness: 20
            };              
        }
        if(barDataset[maxIndex])
            barDataset[maxIndex].data[0] = barDataset[maxIndex].data[0] - totalPercent + 100;
                
        const chart = {labels: ['Streams by Vendor'], datasets: barDataset};
        return <div className="chart-block top-vendor-chart-block">{this.renderStackedChart(chart)}</div>
    }
}


export default VendorBarChart;