export const entityFiltersConstants = {
  GET_ENTITY_FILTERS_REQUEST: 'GET_ENTITY_FILTERS_REQUEST',
  GET_ENTITY_FILTERS_SUCCESS: 'GET_ENTITY_FILTERS_SUCCESS',
  GET_ENTITY_FILTERS_FAILURE: 'GET_ENTITY_FILTERS_FAILURE',
  GET_ALL_ENTITY_FILTERS_REQUEST: 'GET_ALL_ENTITY_FILTERS_REQUEST',
  GET_ALL_ENTITY_FILTERS_SUCCESS: 'GET_ALL_ENTITY_FILTERS_SUCCESS',
  GET_ALL_ENTITY_FILTERS_FAILURE: 'GET_ALL_ENTITY_FILTERS_FAILURE',  
  UPDATE_ENTITY_FILTER_REQUEST: 'UPDATE_ENTITY_FILTER_REQUEST',
  UPDATE_ENTITY_FILTER_SUCCESS: 'UPDATE_ENTITY_FILTER_SUCCESS',
  UPDATE_ENTITY_FILTER_FAILURE: 'UPDATE_ENTITY_FILTER_FAILURE',
  CREATE_ENTITY_FILTER_REQUEST: 'CREATE_ENTITY_FILTER_REQUEST',
  CREATE_ENTITY_FILTER_SUCCESS: 'CREATE_ENTITY_FILTER_SUCCESS',
  CREATE_ENTITY_FILTER_FAILURE: 'CREATE_ENTITY_FILTER_FAILURE',
  DESTROY_ENTITY_FILTER_REQUEST: 'DESTROY_ENTITY_FILTER_REQUEST',
  DESTROY_ENTITY_FILTER_SUCCESS: 'DESTROY_ENTITY_FILTER_SUCCESS',
  DESTROY_ENTITY_FILTER_FAILURE: 'DESTROY_ENTITY_FILTER_FAILURE'
}
