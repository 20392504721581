import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { filterActions } from '../../data/actions/filter'
import SocialChannelsList from '../SocialManagement/List';
import Milestones from '../../components/pages/audience/Milestones';
import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';
import ProfileTour from '../../components/pages/result/ProfileTour';

class ResultProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInitialized: false
        }
    }
    
    
    render() {
        const { filter, widgetProps, sharedMode } = this.props;
        
        return <div className="inner-page">
            {!sharedMode && <div className="widget-row">
                <HiddenElements widgetProps={widgetProps} />
            </div>}
            {/*!sharedMode*/ false && <div className="widget-row widget-row--full-width social">
                <Widget widgetID="socials" widgetProps={widgetProps}>
                    <div className="ibox">
                        <SocialChannelsList mode="inline" filtered={filter} />
                    </div>
                </Widget>
            </div>}
            <div className="widget-row widget-row--full-width milestones">
                <Widget widgetID="milestones" widgetProps={widgetProps}>
                    <Milestones mode="mixed" entity="mixed" filtered={filter} sharedMode={sharedMode} />
                </Widget>
            </div>
            <ProfileTour />
        </div>
    }
}

export default WithWidgetManager(ResultProfile, 'result_profile')