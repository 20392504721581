import React from 'react';

class Footer extends React.Component {
    render() {
        const year = new Date().getFullYear();
        return (
            <div className="footer">
                <div className="footer-inner">
                    <div>
                        <a href="http://support.entertainment-intelligence.com" target="_blank" className="footer-link help-guide-link">
                        <span className="nav-label">Help Guide</span>
                        </a>
                    </div>
                    <div>
                        <strong>Copyright</strong> Entertainment Intelligence &copy; {year}
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer