import { map, flatten } from 'lodash';

import { releasesService } from '../services/releases';
import { tracksService } from '../services/tracks';
import { releasesConstants } from '../constants/releases';
import { releasesFormatter } from '../formatters/releases';

export const releasesActions = {
  getNewReleases
}

function getNewReleases(date, pagination, sorted){
  return ( dispatch, getState ) => {
    dispatch(request());
    releasesService.getNewReleases(date, pagination, sorted)
    .then( response => {
        const ids = flatten(map(response.releases, product=>map(product.tracks, 'track_id')));
        return tracksService.getMetadata(ids)
        .then(metadata => {
            response = releasesFormatter.formatReleases(response, metadata.data);
            return dispatch(success(response));      
        });
      
    })
    .catch( error => {
      return dispatch(failure(error));
    });
  };

  function request(){ return { type: releasesConstants.GET_NEW_RELEASES_REQUEST }}
  function success( releases ){ return { type: releasesConstants.GET_NEW_RELEASES_SUCCESS, payload: releases }}
  function failure( error ){ return { type: releasesConstants.GET_NEW_RELEASES_FAILURE, error }}
}
