export const heartbeatsConstants = {
  GET_HEARTBEATS_REQUEST: 'GET_HEARTBEATS_REQUEST',
  GET_HEARTBEATS_SUCCESS: 'GET_HEARTBEATS_SUCCESS',
  GET_HEARTBEATS_FAILURE: 'GET_HEARTBEATS_FAILURE',
  GET_PAGED_HEARTBEATS_REQUEST: 'GET_PAGED_HEARTBEATS_REQUEST',
  GET_PAGED_HEARTBEATS_SUCCESS: 'GET_PAGED_HEARTBEATS_SUCCESS',
  GET_PAGED_HEARTBEATS_FAILURE: 'GET_PAGED_HEARTBEATS_FAILURE',
  GET_HEARTBEATS_WEEKS_REQUEST: 'GET_HEARTBEATS_WEEKS_REQUEST',
  GET_HEARTBEATS_WEEKS_SUCCESS: 'GET_HEARTBEATS_WEEKS_SUCCESS',
  GET_HEARTBEATS_WEEKS_FAILURE: 'GET_HEARTBEATS_WEEKS_FAILURE',
  GET_PAGED_HEARTATTACKS_REQUEST: 'GET_PAGED_HEARTATTACKS_REQUEST',
  GET_PAGED_HEARTATTACKS_SUCCESS: 'GET_PAGED_HEARTATTACKS_SUCCESS',
  GET_PAGED_HEARTATTACKS_FAILURE: 'GET_PAGED_HEARTATTACKS_FAILURE',
  GET_HEARTATTACKS_WEEKS_REQUEST: 'GET_HEARTATTACKS_WEEKS_REQUEST',
  GET_HEARTATTACKS_WEEKS_SUCCESS: 'GET_HEARTATTACKS_WEEKS_SUCCESS',
  GET_HEARTATTACKS_WEEKS_FAILURE: 'GET_HEARTATTACKS_WEEKS_FAILURE',  
  
  PERCENT_SYMBOL: '%',
  CHECK_MARK_SYMBOL: '✓',
  CROSS_SYMBOL: '✗',
  START_DATE: "2017-12-29"
};
