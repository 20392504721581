import React from 'react';
import { map, merge } from 'lodash';
import SaveLoad from '../../../helpers/SaveLoad';




class FilterSaveLoad extends React.Component {

    constructor(props) {
        super(props);

        this.saveLoad = SaveLoad(this.props.entity);

        this.state = ({
            savedFilters: this.saveLoad.loadAll()
        });
        this.filterSave = this.filterSave.bind(this);
        this.filterLoad = this.filterLoad.bind(this);
    }

    filterSave(e){
        e.preventDefault();
        let key = prompt('filter name');
        this.saveLoad.save(key, this.props.filter);
        this.setState({
            savedFilters: this.saveLoad.loadAll()
        });
    }

    filterLoad(e, key){
        e.preventDefault();
        let { value } = this.saveLoad.load(key);
        this.props.onLoad(value);
    }

    renderSavedFilters () {
        let filters = [
            <li key="save"><a href="#" onClick={(e)=>this.filterSave(e)}>Save Filter As:</a></li>
        ]
        for(let filter of this.state.savedFilters)
            filters.push(<li key={filter.key}><a href="#" onClick={(e)=>{this.filterLoad(e, filter.key)}}>Load {filter.key}</a></li>);

        return filters;
    }

    render() {
        return <div className="form-group button-container btn-group clearfix">
            <button type="button" className="btn btn-warn dropdown-toggle" alt="save" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-cogs button-icon"></i>{this.props.label}</button>
            <ul className="dropdown-menu">
                {this.renderSavedFilters()}
            </ul>
        </div>

    }
}

export default FilterSaveLoad
