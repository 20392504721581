import React, { Component } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '../../../components/common/Box';
import FieldSelector from '../../../components/common/Datagrid/FieldsSelector';
import PageFilter from '../../../components/common/Datagrid/PageFilter';
//import Accordion from '../../../components/common/Accordion'
import AuthHeader from '../../../helpers/AuthHeader';

import { userLogsActions } from '../../../data/actions/user_logs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

class UserLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }   
        this.dispatchLoadData = this.dispatchLoadData.bind(this);
    }
    
    componentDidMount() {
        this.dispatchLoadData(this.state);
    }
  
    dispatchLoadData(state){
        const { dispatch } = this.props;

        dispatch(userLogsActions.getUsers());
    }

    renderToolbar() {
        let toolbar = [];
        toolbar.push(<div key="wrapper">--todo: accordion--</div>);
        return toolbar;
    }

    render() {

        const { logs } = this.props;
        
        return (
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                    <Box title={`User Logs ${logs && logs.users ? `(${logs.users.length})` : '(0)' }`} className="collapsed" />
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {logs && logs.users ? <div className="user-logs-list">
                  {logs.users.map((user)=>{return(
                      <Link to={`/admin/user-logs/${user.invitee_id}/details`} key={user.invitee_id} className="user-logs-item">{user.first_name} {user.last_name}</Link>)})}
                  </div> : null}
              </AccordionItemPanel>
          </AccordionItem>
        )
    }
}


function mapStateToProps(state) {
    return {
        users: state.users,
        user: state.user,
        logs: state.userLogs
    } 
}

export default connect(mapStateToProps)(UserLogs);
