import countriesByAbbreviation from '../../../common/CountriesByAbbreviation';
import countriesByContinent from 'country-json/src/country-by-continent.json';
import { groupBy, orderBy, find, filter } from 'lodash';

const UKTerritories = ['GB', 'GG', 'IM', 'JE'];
const UKKey = 'UK and Territories';

const groupedCountries = filter(countriesByAbbreviation, (c)=>{return c.abbreviation && c.abbreviation !== 'ZZ'}).map((country)=>{
    if(UKTerritories.includes(country.abbreviation)) {
        country.continent = UKKey;
    }
    else if(country.abbreviation == 'TL' || country.abbreviation == 'TW') {
        country.continent = 'Asia';
    }
    else {       
        const continent = find(countriesByContinent, {country: country.country});
        
        if(continent)
          country.continent = continent.continent;
        else
          country.continent = 'Unknown';
    }
    return country;
})

let groups = groupBy(orderBy(groupedCountries.map((country)=>({value: country.abbreviation, label: country.country, region: country.continent})), ['region', 'name'], ['asc', 'asc']), 'region');
if(groups[UKKey]) {
    groups[UKKey] = orderBy(groups[UKKey], (country) => UKTerritories.indexOf(country.value));
}
export default groups;
// export default orderBy(countries.map((country)=>({value: country.cca2, label: country.name.common})), 'label', 'asc');