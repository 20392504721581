import React from 'react';
import moment from 'moment';
import ConfirmClick from '../../../common/ConfirmClick';

export const milestonesSettings = {
    settingsForTable
};


function settingsForTable(setCurrentMilestone, deleteMilestone, shareMilestone, copyMilestone, sharedMode){
    
    
    let columns = [
        {
            id: 'actions',
            className: 'milestone-actions',
            headerClassName: 'no-sort milestone-actions',
            Header: 'Actions',
            sortable: false,
            width: 120,
            // <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
            Cell: props => props.original.owner ? <div className="buttons-holder buttons-holder--milestones">
             
             <a onClick={()=>setCurrentMilestone(props.original, 'showEditMilestoneModal')} className="btn add-to-filter" title="Edit"><i className="far fa-edit"></i></a>
             <a onClick={()=>copyMilestone(props.original)} className="btn add-to-filter" title="Copy"><i className="far fa-copy"></i></a>
             <ConfirmClick confirmAction={()=>deleteMilestone(props.original.id)} confirmClass="btn add-to-filter" confirmLabel={<i className="fas fa-trash-alt"></i>} confirmPrompt="Delete milestone?" title="Delete"></ConfirmClick>
            </div> : null,
            show: !sharedMode
            
        },
       {
           id: 'logo',
           className: 'logo-sell',
           headerClassName: 'logo-sell no-sort',
           Header: 'Logo',
           accessor: 'logo',
           sortable: false,
           Cell: props => {
               return <div className="logo-container" ><div id={`milestone_${props.original.id}`} className="milestone-anchor-container" /><div className="logo" style={{backgroundImage: `url(${props.value})`}} /></div>;
           },
           

       },
       {
           id: 'linked',
           className: 'milestone-reference item-milestone',
           headerClassName: 'milestone-reference',
           Header: 'Item',
           accessor: 'linked',
           // width: 220
       },
       /*
       {
           id: 'milestone_type',
           className: 'milestone-type',
           headerClassName: 'milestone-type',
           Header: 'Type',
           accessor: 'milestone_type',
           // width: 190
       },
       */
       /*
       {
           id: 'imprint',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Imprint',
           accessor: 'imprint',
           // width: 220
       },
       {
           id: 'artist',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Artist',
           accessor: 'artist',
           // width: 220
       },
       {
           id: 'product',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Product',
           accessor: 'product',
           // width: 220
       },
       {
           id: 'track',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Track',
           accessor: 'track',
           // width: 220
       },
       */
       {
           id: 'reference',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Title',
           accessor: 'reference',
           // width: 220
       },       
       {
           id: 'notes',
           className: 'milestone-reference',
           headerClassName: 'milestone-reference',
           Header: 'Notes',
           accessor: 'notes',
           // width: 220
       },
       
       {
           id: 'occurs_at',
           className: '',
           headerClassName: '',
           Header: 'Occurs At',
           accessor: 'occurs_at',
           // width: 150,
           Cell: props => {
               return <div>{moment(props.value).format('YYYY-MM-DD')}</div>
           }
           
       },
       
       {
           id: 'ends_at',
           className: '',
           headerClassName: '',
           Header: 'Ends At',
           accessor: 'ends_at',
           // width: 150,
           Cell: props => {
               const date = moment(props.value);
               return <div>{date.isValid() ? date.format('YYYY-MM-DD') : '-'}</div>
           }
           
       },
       {
             id: 'owner',
             className: '',
             headerClassName: '',
             Header: 'Author',
             accessor: 'owner',
             width: 50,
             Cell: props => {
                 return <span>{props.value ? 'You' : 'Shared'}</span>
             }
             
      },       
       
       {
           id: 'public',
           className: 'logo-sell',
           headerClassName: 'logo-sell no-sort',
           Header: 'Shared',
           accessor: 'public',
           sortable: false,
           Cell: props => {
               return props.original.owner ? <span onClick={()=>shareMilestone(props.value, props.original.id)}><i className={`far fa-${props.value ? 'check-square' : 'square'}`} /></span> : null
           },
           show: !sharedMode

       },       
       
       //{ accessor: 'exception', expander: true }      
       
    ];
    return columns;
}