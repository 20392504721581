import { milestonesConstants } from '../constants/milestones';
import { find, findIndex } from 'lodash';

export function milestones(state = {entities: {products: null, tracks: null, socials: null, mixed: {}}, list: {}, comparisonMilestones: {}}, action) {
    switch (action.type) {
        case milestonesConstants.GET_MILESTONES_REQUEST:
            return {
                ...state,
                loading: true,
                items: null
            };
        case milestonesConstants.GET_MILESTONES_SUCCESS:
            return {
                
                ...state,
                loading: false,
                items: action.datasets,
                tableItems: action.table 
            };
        case milestonesConstants.GET_MILESTONES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case milestonesConstants.GET_MILESTONES_BY_TYPE_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case milestonesConstants.GET_MILESTONES_BY_TYPE_SUCCESS:
            return {
                
                ...state,
                listLoading: false,
                list: {
                    ...state.list,
                    [action.id]:action.milestones
                } 
            };
        case milestonesConstants.GET_MILESTONES_BY_TYPE_FAILURE:
            return {
                ...state,
                listLoading: false,
                error: action.error
            };
            
        case milestonesConstants.GET_ENTITY_MILESTONES_REQUEST:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.entity]: {
                        loading: true,
                        items: null
                    }
                }
            };
        case milestonesConstants.GET_ENTITY_MILESTONES_SUCCESS:
            return {
                
                ...state,
                entities: {
                    ...state.entities,
                    [action.entity]: {
                        loading: false,
                        items: action.datasets,
                        tableItems: action.table,
                    }
                }                
 
            };
        case milestonesConstants.GET_ENTITY_MILESTONES_FAILURE:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.entity]: {
                        loading: false,
                        error: action.error
                    }
                }
            };

        

        case milestonesConstants.GET_COMPARISON_MILESTONES_REQUEST:
            return {
                ...state,
                comparisonMilestonesLoading: true
            };
        case milestonesConstants.GET_COMPARISON_MILESTONES_SUCCESS:
            return {
                
                ...state,              
                comparisonMilestonesLoading: false,
                comparisonMilestones: {
                    ...state.comparisonMilestones, 
                    [action.id]: action.milestones
                } 
 
            };
        case milestonesConstants.GET_COMPARISON_MILESTONES_FAILURE:
            return {
                ...state,
                comparisonMilestonesLoading: false,
                error: action.error
            };
            
        case milestonesConstants.GET_MILESTONE_TYPES_REQUEST:
            return {
                ...state,
                typesLoading: true
            };
        case milestonesConstants.GET_MILESTONE_TYPES_SUCCESS:
            return {
                ...state,
                typesLoading: false,
                types: action.types
            };
        case milestonesConstants.GET_MILESTONE_TYPES_FAILURE:
            return {
                ...state,
                typesLoading: false,
                error: action.error
            };
            
        case milestonesConstants.ADD_MILESTONE_REQUEST:
        case milestonesConstants.LINK_MILESTONE_REQUEST:
            return {
                ...state,
                milestoneLoading: true
            };
        case milestonesConstants.ADD_MILESTONE_SUCCESS:
        case milestonesConstants.LINK_MILESTONE_SUCCESS:
            return {
                
                ...state,
                milestoneLoading: false,
            };
        case milestonesConstants.ADD_MILESTONE_FAILURE:
        case milestonesConstants.LINK_MILESTONE_FAILURE:
            return {
                ...state,
                milestoneLoading: false,
            };

        case milestonesConstants.UNLINK_MILESTONE_REQUEST:
            return {
                ...state,
                milestoneLoading: true
            };
        case milestonesConstants.UNLINK_MILESTONE_SUCCESS:
            let tableItems = Object.assign([], state.tableItems), 
                milestone = find(tableItems, {id: action.milestoneID}),
                removedEntity = findIndex(milestone.linked_entities, {id: action.id});
            
            milestone.linked_entities.splice(removedEntity, 1);
            
            return {
                
                ...state,
                milestoneLoading: false,
                tableItems
            };
        case milestonesConstants.UNLINK_MILESTONE_FAILURE:
            return {
                ...state,
                milestoneLoading: false,
            };
            
        default:
            return state
    }
} 