import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
       {key: 'source', title: 'Source', width: 40, fontSize: 5},
       {key: 'curr_units', title: 'Streams', width: 35, fontSize: 5, formatFn: splitByThousands},
       {key: 'share', title: 'Share', width: 25, fontSize: 5, formatFn: addPercent},
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}