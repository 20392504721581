import React from 'react';
import { Line } from 'react-chartjs-2';
import { map, concat, merge, get, cloneDeep } from 'lodash';
import { colors, line } from '../ChartDefaults';
import moment from 'moment';

class LineChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            //data: this.buildChartData(props.data),
            options: merge({}, line, props.options)
        };
        this.getData = this.getData.bind(this);
    }
    
    componentWillMount() {
        //Chart.elements.Rectangle.prototype.draw = RectangularDraw;     
    }

    componentWillReceiveProps(props){
        /*
        this.setState({
            data: this.buildChartData(props.data) 
        })
        */
    }
    
    getData(canvas) {
        return this.props.data;
        let {labels, dataset} = this.props.data;
        if(!dataset)
            return false;
        
        const ctx = canvas.getContext("2d");
        
        const gradientColors = colors;
        
        dataset = {
            label: '',
            backgroundColor: gradientColors[0],
            data: Object.values(dataset)
        };
        
        labels = labels.map(label=>moment(label).format('DD MMM'));
        return {
            labels,
            datasets: [dataset]
        }
        
    }
	
	getShadowChartData(data) {
		let shadowData = cloneDeep(data);
		let datasets = get(shadowData, 'datasets', []);
		if(datasets) {
			datasets = datasets.filter(dataset=>dataset.label!='MissedData');
			shadowData.datasets = datasets;
		}
		return shadowData;
	}
    
    render() {
        const { data, options, id, shadowChart = false, shadowChartClass = 'chart-export-shadow', shadowChartWidth = 400, shadowChartHeight = 300, ...rest } = this.props;
        return (this.props.data ?
            <React.Fragment>
                <Line data={this.props.data} options={this.state.options} {...rest} ></Line> 
                {shadowChart && <div className={shadowChartClass}><Line width={shadowChartWidth} height={shadowChartHeight} id={id} data={this.getShadowChartData(this.props.data)} options={this.state.options} {...rest}></Line></div>}
            </React.Fragment> : null);
    }
}

export default LineChart