const encryptedKeys = ['user'];
import { encrypt, decrypt } from './crypto';

module.exports = CryptoStorePlugin;

function CryptoStorePlugin() {
    return {
        get: get,
        set: set,
    }

    function get(super_fn, key) {
        var val = super_fn(key)
        if (!val) { 
            return val; 
        }
        let decrypted = val;
        if(encryptedKeys.includes(key)) {
            const decryptedValue = decrypt(val);
            if(decryptedValue)
                decrypted = JSON.parse(decryptedValue);
            else
                decrypted = null;
        }
        // fallback to existing values that are not compressed
        return (decrypted == null) ? val : decrypted //this._deserialize(decrypted);
    }

    function set(super_fn, key, val) {
     //   val = this._serialize(val);
        if(encryptedKeys.includes(key))
            val = encrypt(JSON.stringify(val));
        super_fn(key, val);
    }
}