import React from 'react';
import ReactTooltip from 'react-tooltip';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AbsoluteValue from '../../../common/AbsoluteValue';
import TrendsBox from '../../../common/Datagrid/TrendsBox';
import TrackPosition from '../TrackPosition';
import GenderSplitBox from '../../../common/Datagrid/GenderSplitBox';
import ArtistsList from '../ArtistsList';
import Tooltip from '../../../common/Tooltip';
import { avgFooter, convertSumFooter } from '../../../common/Datagrid/Footers';

export const playlistDetailsSettings = {
    settingsForRow,
    settingsForTable
};

function dateDiff(item) {
    const addedAt = moment(item.added_at),
        removedAt = moment(item.removed_at || undefined);
    return Math.round(moment.duration(removedAt.diff(addedAt)).as('days'));
}

function settingsForTable(enabledColumns){
    let columns = [
	   {
		   id: 'expander',
		   expander: true,
		   width: 35,
		   className: "arrow",
		   Expander: ({ isExpanded, ...rest }) => (
			 <div>
			   {isExpanded ? (
				 <span>&#x25BC;</span>
			   ) : (
				 <span>&#x25B6;</span>
			   )}
			 </div>
		   ),
		   style: {
			 cursor: "pointer",
			 fontSize: 10,
			 padding: "0",
			 textAlign: "center",
			 userSelect: "none"
		   },
	   },
        {
            id: 'logo',
            className: 'logo-td',
            headerClassName: 'logo-td  no-sort',
            Header: '',
            accessor: 'image',
            width: 60,
            Cell: props => {
                return <div className="logo" style={{backgroundImage: `url(${props.original.image})`}} />;
            }
        },
        {
           id: 'track',
           className: 'playlist-name-td',
           headerClassName: 'playlist-name-td ',
           Header: 'Track',
           accessor: 'track_title',
           width: 280,
           Cell: props => {
               const onClickCall = (e)=>{return onTitleClick(props.original);};
               return <div className="playlist-description">
                	  <div><a onClick={onClickCall}>{props.value}</a>
                      <div className="playlist-details-artist"><ArtistsList artists={props.original.artists} /></div>
                    </div>
            	</div>;
           },
           Footer: 'Total: '
       },
       {
           id: 'position',
           headerClassName: '',
           Header: 'Pos',
           accessor: 'position',
           // width: 96,
           Cell: props => <TrackPosition track={props.original} />
       },                          
       {
           id: 'added_at',
           Header: 'Added At',
           accessor: 'added_at',
           // width: 138
       },
       {
           id: 'removed_at',
           className: 'removed-td',
           Header: 'Removed At',
           accessor: 'removed_at',
           Cell: props => moment(props.value).format('YYYY-MM-DD'),
           // width: 134,
           show: false
       },       
       {
           id: 'days_in_list',
           Header: 'Days in list',
           headerClassName: 'no-sort',
           Cell: props => <span>{dateDiff(props.original)}</span>,
           // width: 134
           sortable: false,
           //Footer: (props)=>avgFooter(props, null, null, '')
       },
       {
           id: 'total',
           Header: 'Total',
           accessor: 'total',
           Footer: convertSumFooter,
           Cell: props => props.value ? <AbsoluteValue value={props.value}></AbsoluteValue> : '-',
           // width: 138
       },              
       {
              id: 'skipped_ratio',
              accessor: 'skipped_ratio',
              className: 'lowercase grid-td',
              headerAlt: 'Skip Rate A/V',
              Header: props => <div className="top-table-header-inner">
                  Skip Rate
                  <div className="table-header-tooltip">
                       <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google. A = Audio, V = Video`} tooltipClass="toolbar-title-tooltip"/>
                   </div>
               </div>,
              Cell: ({original}) => (original.skipped_audio_ratio || original.skipped_video_ratio) ? <span>{original.skipped_audio_ratio}% / {original.skipped_video_ratio}%</span> : '-',
              width: 135,
              Footer: (props)=><React.Fragment>{avgFooter(props, null, 'skipped_audio_ratio')} / {avgFooter(props, null, 'skipped_video_ratio')}</React.Fragment>
          },
          {
              id: 'completed_ratio',
              accessor: 'completed_ratio',
              className: 'lowercase grid-td',
              headerAlt: 'Completion Rate A/V',
              Header: props => <div className="top-table-header-inner">
                  Completion Rate A/V
                  <div className="table-header-tooltip">
                       <Tooltip position="right-bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google. A = Audio, V = Video`} tooltipClass="toolbar-title-tooltip"/>
                   </div>
               </div>,
              Cell: ({original}) => (original.completed_audio_ratio || original.completed_video_ratio) ? <span>{original.completed_audio_ratio}% / {original.completed_video_ratio}%</span> : '-',
              width: 135,
              Footer: (props)=><React.Fragment>{avgFooter(props, null, 'completed_audio_ratio')} / {avgFooter(props, null, 'completed_video_ratio')}</React.Fragment>
          },
/*
       {
           id: 'completed_diff',
           headerClassName: ' no-sort',
           Header: props => <div className="top-table-header-inner">
               Ratio S/C 
               <div className="table-header-tooltip">
                    <span data-tip data-for="ratio-rc-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
                        <i className="fas fa-question-circle" /> 
                    </span>
                    <ReactTooltip effect="solid" multiline={true} place="bottom" className="toolbar-title-tooltip" id="ratio-rc-tip">
                        S = Skips / C = Completion 
                        <br/>
                        These metrics only apply to streams over 30 seconds. A track is marked as complete when the stream reaches 95% of the track length.
                        <br/>
                        They are provided by all DSPs except Google and YouTube
                    </ReactTooltip>
                </div>
            </div>,
           accessor: 'completed_diff',
           headerAlt: 'Ratio S/C',
           Cell: props => {
            const {skipped_ratio, completed_ratio} = props.original,
                showRatio = skipped_ratio && completed_ratio;
            
            return showRatio ? <div>
           <span title="Skipped">{skipped_ratio}%</span>
            /
            <span title="Completed">{completed_ratio}%</span>
           </div> : <div>-</div>},
            
           sortable: false,
           Footer: (props)=><React.Fragment>{avgFooter(props, null, 'skipped_ratio')} / {avgFooter(props, null, 'completed_ratio')}</React.Fragment>
           // width: 130
       },
       */
       {
           id: 'gender',
           className: 'gender-td',
           Header: 'Gender M/F/U',
           Cell: props => <GenderSplitBox item={props.original} />,
           // width: 138
       }
    ];
    return map(columns, (column)=>{
        if(enabledColumns !== undefined)
            column.show = enabledColumns.includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};
    return {
        className: rowInfo.original.completed === null ? 'disabled' : ''
    }
}