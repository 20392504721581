import moment from 'moment';

export const required = value => (value ? undefined : 'Required')
          
export const URL = value => (value.startsWith('http') ? undefined : 'Must be a valid URL')

export const email = value => (!value || /(.+)@(.+){2,}\.(.+){2,}/.test(value)  ? undefined : 'Email is invalid');

export const date = value => (!value || moment(value, 'YYYY-MM-DD', true).isValid() ? undefined : 'Date is invalid'); 

// TODO switch back to 255
export const length = value => (value && value.length <= 255 || value === undefined || value === null ? undefined : 'Must be less than 255')

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)