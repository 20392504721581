import { Chart, Legend, Tooltip } from 'chart.js';
import { merge, some } from 'lodash';
import { defaults } from 'react-chartjs-2';
import './chartjs-plugin-colorschemes/plugin.colorschemes.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import { formatAxesLabel, formatTooltipLabel, formatTooltipLabelPie } from './Formatter';
import datalabelsPlugin from 'chartjs-plugin-datalabels'
import { splitByThousands, convertToThousands} from '../../../helpers/NumberFormatter';

Chart.register([Tooltip, Legend, datalabelsPlugin, annotationPlugin]);

// export const colors = ['#A5F6E2', '#F87E7F', '#FC66B4', '#99B1FF', '#FD80C1', '#F0C5FC', '#FFA867', '#FF7D1A', '#3363FF', '#5BA8FF', '#FFE165', '#80C7CE', '#C364DF', '#E2B600', '#1BD2A4', '#4D77FF', '#FF6E01', '#18BA92', '#FB339B', '#29B7C5'];

export const colors = ['#D400D4','#FF4500','#893FFC','#1E90FF','#00A566','#002D9D','#FB339B','#3363FF','#FF0054','#25A18E','#C364DF','#531BC2','#FA0082','#4D77FF','#FB5607','#094839','#48639C','#C80068','#1D1929','#4C4C9D'];

// chart.js globals
// defaults.global.animation = false;

defaults.plugins = {
    ...defaults.plugins, 
    colorschemes: {
        scheme: colors,
        fillAlpha: 0.8,
        reverse: false,
        override: false         
    },
    datalabels: {
        display: false
    }
};

// chart type settings

const tooltip = {
    callbacks: {
        label: formatTooltipLabel
    }    
}

// const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;
const pieDoughnutLegendClickHandler = Chart.overrides.doughnut.plugins.legend.onClick;

function newLegendHandler(e, legendItem, legend) {
    var index = legendItem.datasetIndex;
    var ci = this.chart;
    var datasets = ci.data.datasets;
    var visibleCount = ci.getVisibleDatasetCount();
    const type = legend.chart.config.type;
    
    if (e.native.shiftKey) {
      if (type === "pie" || type === "doughnut") {
        var legendItemIndex = legendItem.index;
        

        ci.data.datasets.forEach(function(el, i) {
            let someElementsVisible = false;
            for(let index in el.data) {
                if(index == legendItemIndex)
                    continue;
                if(ci.getDataVisibility(index)) {
                    someElementsVisible = true;
                    break;
                }
            }
            
          let nextVisibility = !someElementsVisible;  
          el.data.forEach(function(cur, ind) {
            if (ind === legendItemIndex) {
              if (ci.getDataVisibility(legendItemIndex) !== true) {
                legend.chart.toggleDataVisibility(legendItemIndex);
              }
            } else {
                if(ci.getDataVisibility(ind) != nextVisibility)
                    legend.chart.toggleDataVisibility(ind);
                
            }
          })
        })
      } else {
        var currentHiddenVal = ci.getDatasetMeta(index).hidden;
        
        datasets.forEach(function(el, i) {
            var visible = true;
            
            if (visibleCount === 1) {
                if (i === index && (currentHiddenVal === null || currentHiddenVal === true)) {
                    visible = true
                } else if (i !== index && (currentHiddenVal === null || currentHiddenVal === true)) {
                    visible = false;
                } else {
                    visible = true;
                }
            } else {
                if (i !== index) {
                    visible = false;
                }
            }
            
            ci.setDatasetVisibility(i, visible)
        }); 
      }
    } else {
      if (type === "pie" || type === "doughnut") {
        pieDoughnutLegendClickHandler(e, legendItem, legend)
        } else {
        var meta = ci.getDatasetMeta(index);
        
        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
      }
    }

    ci.update();
}

function shiftClickHandler(e, item, chart) {
    var ci = this;
    var datasets = ci.data.datasets;
    var visibleCount = ci.getVisibleDatasetCount();
    
    var type = ci.config.type;

    if (e.native.shiftKey) {
      if (type === "pie" || type === "doughnut") {
        var sectionIndex = ci.getElementsAtEventForMode(e, 'index', { intersect: true }, true)[0].index;
        ci.data.datasets.forEach(function(el, i) {
          el.data.forEach(function(cur, ind) {
            if (ind !== sectionIndex) {
              ci.legend.chart.toggleDataVisibility(ind);
            }
          })
        })
      } else {
        var currentHiddenVal = ci.getDatasetMeta(index).hidden;
        var index = ci.getElementsAtEventForMode(e, 'dataset', { intersect: false }, true)[0].datasetIndex;
        
        datasets.forEach(function(el, i) {
            var visible = true;
            
            if (visibleCount === 1) {
                if (i === index && (currentHiddenVal === null || currentHiddenVal === true)) {
                    visible = true
                } else if (i !== index && (currentHiddenVal === null || currentHiddenVal === true)) {
                    visible = false;
                } else {
                    visible = true;
                }
            } else {
                if (i !== index) {
                    visible = false;
                }
            }
            
            ci.setDatasetVisibility(i, visible)
        }); 
      }
    }

    ci.update();
}

export const pie = {
    "maintainAspectRatio": false,
    // "responsive": true,
    plugins: {
        tooltip: {
            callbacks: {
                label: formatTooltipLabelPie
            }    
        },
        legend: {
            position: "right",
            labels: {
                usePointStyle: true,
                pointStyle: 'rect',
                font: {
                    size: window.innerWidth > 600 ? 14 : 10
                }
            },
            onHover: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'default';
            },
        },
        
    }
};

export const doughnut = {
    "maintainAspectRatio": false,
    onClick: shiftClickHandler,
    plugins: {
        tooltip: {
            callbacks: {
                label: formatTooltipLabelPie
            }    
        },
        legend: {
            position: "right",
            labels: {
                usePointStyle: true,
                pointStyle: 'rect',
                font: {
                    size: window.innerWidth > 600 ? 16 : 10
                }
            },
            onClick: newLegendHandler,
            onHover: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'default';
            },
        },
        
    }
};

export const bar = {
    // "responsive": true,
    "maintainAspectRatio": false,
    cornerRadius: 20,
    onClick: shiftClickHandler,
    maxBarThickness: 40,
    plugins: {
        legend: {
            //"display": false,
            position: "top",
            labels: {
                usePointStyle: true,
                pointStyle: 'rect',
                font: {
                    size: window.innerWidth > 600 ? 16 : 10
                }
            },
            onClick: newLegendHandler,
            onHover: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'default';
            },
        },
        tooltip,
    },
    scales: {
        x: {
        },
        y: {
            ticks: {
                callback: formatAxesLabel
            }
        }            
    },
};

export const stackedBar = merge({}, bar, {
    "maintainAspectRatio": false,
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 5,
    layout: {
        padding: {
            left: 10,
            right: 10,
            top: 0,
            bottom: 0
        }
    },
    onClick: shiftClickHandler,
    plugins: {  
        legend: {
            display: true,
            position: "bottom",
            align: "left",
            labels: {
                usePointStyle: true,
                pointStyle: 'rect',
                padding: 10,
                font: {
                    size: window.innerWidth > 600 ? 16 : 10
                }
            },
            onClick: newLegendHandler,
            onHover: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'default';
            }
        },  
        tooltips: {
            mode: 'dataset',
         },  
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false,
                color: '#8E8C94'
            },
            ticks: {
                color: '#8E8C94'
            }
        },
        y: {
            stacked: true,
            grid: {
                color: '#f2f2f2'
            },
            ticks: {
                color: '#8E8C94'
            }
        }            
    },
});

export const line =  {
    // "responsive": true,
    "maintainAspectRatio": false,
    layout: {
        padding: {
            left: 10,
            right: 10,
            top: 0,
            bottom: 0
        }
    },
    //tooltip,
    scales: {
        x: {
            grid: {
                display: false,
                color: '#8E8C94'
            },
            ticks: {
                color: '#8E8C94'
            }
        },
        y: {
            ticks: {
                callback: formatAxesLabel,
                color: '#8E8C94'
            },
            grid: {
                color: '#f2f2f2'
            }
        }           
    },
    onClick: shiftClickHandler,
    plugins: {
        legend: {
            position: "bottom",
            labels: {
                usePointStyle: true,
                pointStyle: 'rect',
                padding: 10,
                font: {
                    size: window.innerWidth > 600 ? 16 : 10
                }

            },
            onClick: newLegendHandler,
            onHover: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave: function(e, legendItem, legend) {
                e.native.target.style.cursor = 'default';
            },
        }
    }
}; 
