import React from 'react';

const imprintsSvg = require('!svg-inline-loader!../../../../public/img/icons/imprints.svg');
const artistsSvg = require('!svg-inline-loader!../../../../public/img/icons/artists.svg');
const productsSvg = require('!svg-inline-loader!../../../../public/img/icons/products.svg');
const projectsSvg = require('!svg-inline-loader!../../../../public/img/icons/projects.svg');
const tracksSvg = require('!svg-inline-loader!../../../../public/img/icons/tracks.svg');
const playlistsSvg = require('!svg-inline-loader!../../../../public/img/icons/playlist_library.svg');

const pageFilterObjects = [
    { type: "imprints", icon: imprintsSvg },
    { type: "products", icon: productsSvg },
    { type: "projects", icon: projectsSvg },    
    { type: "artists", icon: artistsSvg },
    { type: "tracks", icon: tracksSvg },
	{ type: "playlists", icon: playlistsSvg }
];

export default function EntitiesList(props){
    const { entities = [], type, fav_hide, onDelete } = props;
    const entitiesByType = entities.filter(entity => (entity.entity_type == type && entity.fav_hide == fav_hide));
    if(!entitiesByType.length)
        return null;
    
    const renderFilterIcon = (type) => {
        const searchCriteria = (f) =>
          f.type === type || `exclude_${f.type}` === type;
        const currentType = pageFilterObjects.find(searchCriteria);
        const icon = currentType ? currentType.icon : "";
        
        return <span className="page-filter-imprint-type">
            <span className={`page-filter-icon ${currentType.type}`} dangerouslySetInnerHTML={{__html: icon}} />
            <span className="page-filter-imprint-name">{currentType.type}</span>
            </span>
    }

    return <div key={`${type}_${fav_hide}`} className={`page-filter ${type}`}>
        <div className="tag-list">
            <div className="page-filter-item">
                {renderFilterIcon(type)}
                <div className="page-filter-list">
                    {entitiesByType.map(entity=><div key={entity.entity_id} className="tag">
                        <div className="tag-name-holder">
                            <div className="tag-name">
                                <span>{entity.entity_title}</span>
                            </div>
                            <a
                                className="close-link"
                                onClick={()=>onDelete(entity.entity_id, entity.entity_type)}
                            >
                                &times;
                            </a>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}
