export const notificationsConstants = {
    GET_USER_NOTIFICATIONS_REQUEST: 'GET_USER_NOTIFICATIONS_REQUEST',
    GET_USER_NOTIFICATIONS_SUCCESS: 'GET_USER_NOTIFICATIONS_SUCCESS',
    GET_USER_NOTIFICATIONS_FAILURE: 'GET_USER_NOTIFICATIONS_FAILURE',
    GET_STORE_PLAYLIST_NOTIFICATIONS_REQUEST: 'GET_STORE_PLAYLIST_NOTIFICATIONS_REQUEST',
    GET_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS: 'GET_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS',
    GET_STORE_PLAYLIST_NOTIFICATIONS_FAILURE: 'GET_STORE_PLAYLIST_NOTIFICATIONS_FAILURE',
    GET_HEARTBEATS_NOTIFICATIONS_REQUEST: 'GET_HEARTBEATS_NOTIFICATIONS_REQUEST',
    GET_HEARTBEATS_NOTIFICATIONS_SUCCESS: 'GET_HEARTBEATS_NOTIFICATIONS_SUCCESS',
    GET_HEARTBEATS_NOTIFICATIONS_FAILURE: 'GET_HEARTBEATS_NOTIFICATIONS_FAILURE',
    GET_RELEASES_NOTIFICATIONS_REQUEST: 'GET_RELEASES_NOTIFICATIONS_REQUEST',
    GET_RELEASES_NOTIFICATIONS_SUCCESS: 'GET_RELEASES_NOTIFICATIONS_SUCCESS',
    GET_RELEASES_NOTIFICATIONS_FAILURE: 'GET_RELEASES_NOTIFICATIONS_FAILURE',
    GET_COUNT_OF_NOTIFICATIONS_REQUEST: 'GET_COUNT_OF_NOTIFICATIONS_REQUEST',
    GET_COUNT_OF_NOTIFICATIONS_SUCCESS: 'GET_COUNT_OF_NOTIFICATIONS_SUCCESS',
    GET_COUNT_OF_NOTIFICATIONS_FAILURE: 'GET_COUNT_OF_NOTIFICATIONS_FAILURE',
    UPDATE_STORE_PLAYLIST_NOTIFICATIONS_REQUEST: 'UPDATE_STORE_PLAYLIST_NOTIFICATIONS_REQUEST',
    UPDATE_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS: 'UPDATE_STORE_PLAYLIST_NOTIFICATIONS_SUCCESS',
    UPDATE_STORE_PLAYLIST_NOTIFICATIONS_FAILURE: 'UPDATE_STORE_PLAYLIST_NOTIFICATIONS_FAILURE',
    UPDATE_RELEASE_NOTIFICATIONS_REQUEST: 'UPDATE_RELEASE_NOTIFICATIONS_REQUEST',
    UPDATE_RELEASE_NOTIFICATIONS_SUCCESS: 'UPDATE_RELEASE_NOTIFICATIONS_SUCCESS',
    UPDATE_RELEASE_NOTIFICATIONS_FAILURE: 'UPDATE_RELEASE_NOTIFICATIONS_FAILURE',
    UPDATE_HEARTBEATS_NOTIFICATIONS_REQUEST: 'UPDATE_HEARTBEATS_NOTIFICATIONS_REQUEST',
    UPDATE_HEARTBEATS_NOTIFICATIONS_SUCCESS: 'UPDATE_HEARTBEATS_NOTIFICATIONS_SUCCESS',
    UPDATE_HEARTBEATS_NOTIFICATIONS_FAILURE: 'UPDATE_HEARTBEATS_NOTIFICATIONS_FAILURE'
}
