import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ClickOutsideHolder from '../../../helpers/ClickOutsideHolder';
import {orderBy} from 'lodash';

var crossSvg = require('!svg-inline-loader!../../../../public/img/cross.svg');

class MilestoneLegendItem extends React.Component {
    
    constructor(props) {
        super(props);
        
    }
        
    componentDidMount() {
    }
    
    render (){
        let { milestone, period, clickOutside, xCoordinate, direction, show } = this.props;
        let { pathname } = window.location;
        pathname = pathname.replace('overview', 'profile');
        if(Array.isArray(milestone)) {
            milestone = orderBy(milestone, 'linked');
        }

        let linked = '';
        return milestone ? <div className="milestone-legend-block" style={{
            [direction]: xCoordinate + "px"
            }} >
        {/*<span className="title">{period}</span>*/}
        
        <ul>
        {milestone.map(milestoneForDate=>{
            let showLinked = false;
            if(milestoneForDate.linked != linked) {
                linked = milestoneForDate.linked;
                showLinked = true;
            }
            
            if(!milestoneForDate.ends_at) {
                return <li>
                    {showLinked && <p>{linked}</p>}
                    <Link to={`${pathname}#milestone_${milestoneForDate.id}`}>{`${milestoneForDate.reference}`}</Link>
                    </li>
                }
                else {
                    const startEndLabel = milestoneForDate.ends_at == period ? 'End' : 'Start';
                    return <li>
                    {showLinked && <p>{linked}</p>}
                    <Link to={`${pathname}#milestone_${milestoneForDate.id}`}>{`${milestoneForDate.reference}: ${startEndLabel}`}</Link>
                    </li>
                }
            }
        )}
        </ul>
        <span className="milestone-tooltip-close-btn" onClick={clickOutside} dangerouslySetInnerHTML={{__html: crossSvg}} show={show} ></span>
        </div> : null;
    }
}

export default (MilestoneLegendItem);