import React from 'react';
import Frequencies from './Frequencies';
import NewReleasesReport from './reports/NewReleases';
import HeartbeatsReport from './reports/Heartbeats';
import NosedivesReport from './reports/Nosedives';
import PlaylistAdditionsReport from './reports/PlaylistAdditions';
import UnsupportedReport from './reports/Unsupported';

class NotificationReport extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    getInitialSettings(reportId) {
        switch(reportId) {
        case 1:
            return {release_weeks: 5}
        case 2:
        case 5:
            return { threshold: 50, minimum_streams: 1000 }
        case 3:
            return { products: '', avg_streams: 1000 }

        default: 
            return {};
        }
    }
	
	getReport(reportId) {
		switch(reportId) {
		case 1:
			return NewReleasesReport;
		case 2:
            return HeartbeatsReport;
		case 5:
			return NosedivesReport;
		case 3:
			return PlaylistAdditionsReport;
		default: 
			return UnsupportedReport;
		}		
	}
    
    render (){
        const { report, settings, onSettingsChanged, onFrequencyChanged } = this.props;
        let reportSettings, frequency;
		let initializeDefault;
        if(settings && settings.hasOwnProperty('report_id')) {
            reportSettings = settings.settings;
            frequency = settings.frequency;
			initializeDefault = false;
        }
        else {
            reportSettings = this.getInitialSettings(report.id);
            frequency = 3;
			initializeDefault = true;
        }
		
		if(initializeDefault) {
			onFrequencyChanged(frequency);
			onSettingsChanged(reportSettings);
		}
		
        const ReportComponent = this.getReport(report.id);
        return <div className="notificationReportRoot">
            <div className="notificationReportInfo">
                <h4>{report.title}</h4>
                <div className="report-description">{report.description}</div>
            </div>
            <div className="notification-report-count">
                <div className="notification-report-count-inner">
                    <ReportComponent settings={reportSettings} onValueChanged={onSettingsChanged} />
                </div>
            </div>                    
            <div className="notification-report-set">            
                <Frequencies report={report.id} frequency={frequency} onValueChanged={onFrequencyChanged} />
            </div>
        </div>
    }
}
export default NotificationReport;