export {
    convertToThousands,
    splitByThousands,
    round,
    percentageValue,
    addPercent,
    ordinalSuffix
}

function convertToThousands(num, digits=2) {
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "m" },
        { value: 1E9, symbol: "Bn" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/, i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

function splitByThousands(num){
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0; 
}

function round(num){
    return Math.round(num);
}

function percentageValue(curr, prev) {
    if(!curr)
        return 0;
    if(!prev)
        return curr;
    
    return Math.round((curr-prev)/prev*100);    
}

function addPercent(value) {
	return value + '%';
}

function ordinalSuffix(i) {
    i = Number(i);
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}