export const filterConstants = {
    SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER',
    BUILD_TABLE_REQUEST: 'BUILD_TABLE_REQUEST',
    BUILD_TABLE_SUCCESS: 'BUILD_TABLE_SUCCESS',
    BUILD_TABLE_FAILURE: 'BUILD_TABLE_FAILURE',
    SET_ENTITY_FILTER: 'SET_ENTITY_FILTER',
    SET_NEW_ENTITY_FILTER: 'SET_NEW_ENTITY_FILTER',
    TOGGLE_GLOBAL_FILTER: 'TOGGLE_GLOBAL_FILTER',
	SET_SHARED_MODE: 'SET_SHARED_MODE',
    GET_FILTERS_REQUEST: 'GET_FILTERS_REQUEST',
    GET_FILTERS_SUCCESS: 'GET_FILTERS_SUCCESS',
    GET_FILTERS_FAILURE: 'GET_FILTERS_FAILURE',
    CREATE_FILTER_REQUEST: 'CREATE_FILTER_REQUEST',
    CREATE_FILTER_SUCCESS: 'CREATE_FILTER_SUCCESS',
    CREATE_FILTER_FAILURE: 'CREATE_FILTER_FAILURE',
    REMOVE_FILTER_REQUEST: 'REMOVE_FILTER_REQUEST',
    REMOVE_FILTER_SUCCESS: 'REMOVE_FILTER_SUCCESS',
    REMOVE_FILTER_ERROR: 'REMOVE_FILTER_ERROR',
    UPDATE_FILTER_REQUEST: 'UPDATE_FILTER_REQUEST',
    UPDATE_FILTER_SUCCESS: 'UPDATE_FILTER_SUCCESS',
    UPDATE_FILTER_ERROR: 'UPDATE_FILTER_ERROR',
    GET_DATA_LOGS_REQUEST: 'GET_DATA_LOGS_REQUEST',
    GET_DATA_LOGS_SUCCESS: 'GET_DATA_LOGS_SUCCESS',
    GET_DATA_LOGS_FAILURE: 'GET_DATA_LOGS_FAILURE',
}
