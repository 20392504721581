import React, { Component } from 'react';
import ReactTable from "react-table";
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import Box from '../../common/Box'
import CSV from '../../../helpers/CSVExport';

import { heartbeatsSettings } from './vendors/Settings';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

const CellHeaderTable = cellHeaderHOC(ReactTable);
class  HeartbeatsVendors extends Component {

	renderExportToCsv() {
		return (
			<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		)
	}
  	exportToCsv = () => {
		const { isrc, vendors } = this.props;  
		const filename = `heartbeats-vendors-${isrc}`;
		return CSV.CSVExport(vendors, {filename}, 'heartbeats_vendors');
  	}
	render() {
		const {vendors} = this.props;
		return <Box title="Platforms and Territories" exportToCsv={this.renderExportToCsv()} >
			<ReactTable
				data={vendors}
				columns={heartbeatsSettings.settingsForTable()}
				pivotBy={['vend_id']}
			/>
		</Box>
	}
}
export default HeartbeatsVendors;