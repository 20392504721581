import React, { Component } from 'react';

import CSVImportModal from '../components/common/CSVImportModal'

class CSV extends Component {
    render() {
        const fields = [
            {key: 'milestone_type_id', required: true, label: 'Milestone Type', options: [{label:'one', value: 1}, {label:'two', value: 2}]},
            {key: 'reference', required: true, label: 'Reference'},
            {key: 'notes', required: false, label: 'Notes'},
            {key: 'occurs_at', required: true, label: 'Occurs At'},
            {key: 'ends_at', required: false, label: 'Ends At'}];
        return <CSVImportModal fields={fields} modalTitle="import milestones" buttonTitle="import" onSubmit={console.log} />
    }
}


export default CSV;