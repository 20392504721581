import React from 'react';
import store from '../../helpers/store';
import config from '../../config/config';

export default class Reports extends React.Component {

  state = {
    email: null,
    token: null
  }

  componentDidMount = () => {
    const user = store.get('user');
    if(user){
      this.setState({ token: user.token, email: user.email });
    }
  }

  render(){
    return(
      <div>
        {
          (this.state.email && this.state.token) && (
            <iframe id="reportsIframe"
              title="Iframe with reports"
              className="iframe-reports"
              src={`${config.apis.orthus_web}/bq_reports?email=${this.state.email}&token=${this.state.token}`}>
            </iframe>
          )
        }
      </div>
    )
  }
}
