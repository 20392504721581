import React from 'react';
import { connect } from 'react-redux';
import { find, capitalize } from 'lodash';
import ReactSelect from '../../common/ReactSelect';
import blockedFrequenciesSettings from './Frequencies.json';

class NotificationFrequencies extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        }
        this.setFrequency = this.setFrequency.bind(this);
    }
    

    setFrequency({value}) {
        this.props.onValueChanged(value);
    }
    
    render (){
        const { report, notifications: { frequencies }, frequency} = this.props;
        const blockedFrequencies = blockedFrequenciesSettings[report];
        let options = [];
        for(let key of Object.keys(frequencies)) {
            let value = frequencies[key];
            if(!blockedFrequencies.includes(value)) {
                options.push({label: capitalize(key), value });
            }
        }
        let value = find(options, option=>option.value == frequency)
        return <label htmlFor="">
			Frequency
			<ReactSelect className="frequency-select" options={options} value={value} onChange={this.setFrequency} />
		</label>
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notification_reports
    } 
}

export default connect(mapStateToProps)(NotificationFrequencies);