import { spotifyMonetizationConstants } from '../constants/spotify_monetization';

export function spotifyMonetization(state = {}, action) {
	switch (action.type) {
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_REQUEST:
			return {
				...state,
				loading: true,
				data: []
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data,
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
	
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST:
			return {
				...state,
				loadingTotals: true
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS:
			return {
				...state,
				loadingTotals: false,
				totals: action.data
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE:
			return {
				...state,
				loadingTotals: false,
				error: action.error
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_REQUEST:
			return {
				...state,
				loadingTimeseries: true
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_SUCCESS:
			return {
				...state,
				loadingTimeseries: false,
				timeseries: action.data
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_FAILURE:
			return {
				...state,
				loadingTimeseries: false,
				error: action.error
			};
			
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_REQUEST:
			return {
				...state,
				loadingDates: true,
				dates: []
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_SUCCESS:
			return {
				...state,
				loadingDates: false,
				dates: action.dates,
			};
		case spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_FAILURE:
			return {
				...state,
				loadingDates: false,
				error: action.error
			};

		default:
			return state
	}
} 