import React from 'react';
import Reactour from 'reactour';
import Cookies from 'universal-cookie';

class Tour extends React.Component {
    constructor(props) {
        super(props);
        
        const cookies = new Cookies();
        const tourClosed = cookies.get(props.tourID);
        this.state = {
            tourClosed: tourClosed || false,
            cookies
        };
        this.setTourClosed = this.setTourClosed.bind(this);
    }
    
    setTourClosed(tourClosed){
        if(tourClosed) {
            let expires = new Date();
            expires.setFullYear(expires.getFullYear() + 10);
            this.state.cookies.set(this.props.tourID, true, {path: '/', expires });
        }
        this.setState({tourClosed});
    };
    
    render(){
        const { steps = [] } = this.props;
        const hideByDefault = process.env.NODE_ENV == 'development';
        if(this.state.tourClosed || hideByDefault)
            return null;
        return <Reactour steps={steps} isOpen={!this.state.tourClosed} onRequestClose={()=>this.setTourClosed(true)} />
    }
}

export default Tour;