import React, { Component } from 'react';
import { find, sumBy, map } from 'lodash';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import { LineChart } from '../../widgets/charts/types';
import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';
import { playlistsActions} from '../../../data/actions/playlists';
import { convertToThousands } from '../../../helpers/NumberFormatter';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');

class PlaylistShare extends Component {

    nullElement = {value: null, label: 'All'}
    constructor(props) {
        super(props);
        this.state = {
            playlist: this.nullElement
        }
        
        this.exportToCsv = this.exportToCsv.bind(this);
    }
    
    componentDidMount() {
        const { id, dispatch } = this.props;
        dispatch(playlistsActions.getPlaylistShare(id));
    }
    
    exportToCsv (){
        const { id } = this.props;
        const filename = CSV.CSVHeader('playlist_share', '', this.props.filter.global); 
        return CSV.CSVExport(this.props.playlists.share[id].data, {filename});        
    }
    
    renderToolbar(){
        
        
        //onChange={this.setPlaylist} 
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" onClick={this.exportToCsv} title="Export CSV" className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)

        return toolbar;
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }

    render(){
        const { id } = this.props,
            { share, shareLoading } = this.props.playlists, 
            data = share[id];
            
        const dataAvailable = (!shareLoading && data && data.labels.length > 0);    
        const noDataAvailable = (!shareLoading && data && data.labels.length == 0);
            
        return <Box title="Playlist Share" spinnerEnabled={shareLoading} exportToCsv={this.renderExportToCsv()}>
            <div className="row">
                <div className={`col-xs-12`}>
                    {dataAvailable && <LineChart 
                        data={data}
                        options={{
                            scales: {
                                y: [{
                                    ticks: {
                                        callback: function(value, index, values) {
                                            return value + '%';
                                        }
                                    }
                                }]
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            return context.raw + '%';
                                        }
                                    }
                                },
                            }
                        }}
                    />}
                    {noDataAvailable && <p>Data is not available for this playlist</p>}
                </div>
            </div>
        </Box>;
    }
                        
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        playlists: state.playlists
    } 
}

export default connect(mapStateToProps)(PlaylistShare);