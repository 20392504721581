import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import ReactTable from "react-table";
import CSV from '../../../helpers/CSVExport';
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { tracksActions } from '../../../data/actions/tracks';
import { BarChart, LineChart} from '../../widgets/charts/types';
import { splitByThousands } from '../../../helpers/NumberFormatter';

class DemographicsStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        const { entity, id }  = this.props,
            exportData = this.props[entity].demographicsComparison[id].data;
        const title = ( entity == 'tracks' ? 'track' : 'product') + '-comparison-demographics';
        const filename = CSV.CSVHeader(title, id);
        return CSV.CSVExport(exportData, {filename}, title);
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }


    componentDidMount() {
        const { id, weeks, territories, vendors, metadata } = this.props,
            releaseDate = metadata.release_date;
        this.props.compareDemographics(id, weeks, releaseDate, territories, vendors)
        
    }
    
    componentWillReceiveProps(nextProps) {
    }
    
    render (){
        const { entity, id, metadata, chartID = '', shadowChart = false }  = this.props,
            chartData = this.props[entity].demographicsComparison[id];

        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        return <Box title={title} spinnerEnabled={this.props[entity].demographicsComparisonLoading} exportToCsv={this.renderExportToCsv()}>
        
            {chartData && <div className="row">
                <div className={`col-xs-12`}>
                    {chartData.datasets.length > 0 && <div className="chart-block">
                        <BarChart data={chartData} id={chartID} shadowChart={shadowChart} options={{
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    stacked: true,
                                    title: {
                                        display: true,
                                        text: 'Age Group'
                                    },
                                    /*
                                    ticks: {
                                        callback: function(value, index, values) {
                                        }
                                    } 
                                    */                   
                                },
                                y: {
                                    stacked: true,
                                    ticks: {
                                        callback: function(value, index, values) {
                                            return splitByThousands(value);
                                        }
                                    }                                    
                                }
                            },
                            plugins: {
                                legend: {
                                    display: true, 
                                    labels: {
                                        usePointStyle: true
                                    }
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            return splitByThousands(context.raw);
                                        }
                                    }
                                }
                            }
                        }} />                          
                    </div>}
                    {!chartData.datasets.length && <p>No data available</p>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        products: state.products,
        projects: state.projects,
        filter: state.filter,
        userData: state.user
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        compareDemographics: (id, weeks, releaseDate, territories, vendors) => {
            switch(ownProps.entity) {
                case 'products':
                    dispatch(productsActions.compareProductDemographics(id, weeks, releaseDate, territories, vendors));
                    break;
                case 'projects':
                    dispatch(projectsActions.compareProjectDemographics(id, weeks, releaseDate, territories, vendors));
                    break;
                    
                default:
                    dispatch(tracksActions.compareTrackDemographics(id, weeks, releaseDate, territories, vendors));
                    break;

            
            }
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DemographicsStats);
