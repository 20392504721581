import { entityFiltersConstants } from '../constants/entity_filters';

const initialState = {
  loading: false,
  errors: null,
  entityFilters: {},
  filter: {}
}

export function entity_filters(state = initialState, action){
  const { payload, type, entity } = action;

  switch (type) {
    case entityFiltersConstants.GET_ENTITY_FILTERS_REQUEST:
      state.entityFilters[entity] = [];
      return { ...state, loading: true, errors: null };
    case entityFiltersConstants.GET_ENTITY_FILTERS_SUCCESS:
      state.entityFilters[entity] = payload;
      return { ...state, loading: false, errors: null };
    case entityFiltersConstants.GET_ENTITY_FILTERS_FAILURE:
      state.entityFilters[entity] = [];
      return { ...state, loading: false, errors: payload };
    case entityFiltersConstants.GET_ALL_ENTITY_FILTERS_REQUEST:
        state.allEntityFilters = [];
        return { ...state, loading: true, errors: null };
      case entityFiltersConstants.GET_ALL_ENTITY_FILTERS_SUCCESS:
        state.allEntityFilters = payload;
        return { ...state, loading: false, errors: null };
      case entityFiltersConstants.GET_ALL_ENTITY_FILTERS_FAILURE:
        state.allEntityFilters = [];
        return { ...state, loading: false, errors: payload };      
    case entityFiltersConstants.CREATE_ENTITY_FILTER_REQUEST:
      return { ...state, loading: true, errors: null, filter: {}}
    case entityFiltersConstants.CREATE_ENTITY_FILTER_SUCCESS:
      const updatedFilters = state.entityFilters[entity].slice();
      updatedFilters.push(payload);
      state.entityFilters[entity] = updatedFilters;
      return { ...state, loading: false, errors: null, filter: payload }
    case entityFiltersConstants.CREATE_ENTITY_FILTER_FAILURE:
      return { ...state, loading: false, errors: payload, filter: {}}

    case entityFiltersConstants.DESTROY_ENTITY_FILTER_REQUEST:
      return { ...state, loading: false, errors: payload, filter: {}}

    case entityFiltersConstants.DESTROY_ENTITY_FILTER_SUCCESS:
      let filters = state.entityFilters[entity].slice();
      filters = filters.filter( filter => filter.name !== payload.name);
      state.entityFilters[entity] = filters;
      return { ...state, loading: false, errors: payload, filter: payload};

    case entityFiltersConstants.DESTROY_ENTITY_FILTER_FAILURE:
      return { ...state, loading: false, errors: payload, filter: {}};

    case entityFiltersConstants.UPDATE_ENTITY_FILTER_REQUEST:
      return { ...state, loading: true };
    case entityFiltersConstants.UPDATE_ENTITY_FILTER_SUCCESS:
      const currentFilters = state.entityFilters[entity].slice();
      currentFilters.push(payload);
      return { ...state, loading: false };
    case entityFiltersConstants.UPDATE_ENTITY_FILTER_FAILURE:
      return { ...state, loading: false, errors: payload };



    default:
      return state
  }
}
