import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#E8E8EA'
const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    column: {
      textAlign: 'left',
      borderColor: "#E8E8EA",
      borderBottomWidth: 1,
      borderRightWidth: 1,
      display: "block",
      height: 20,
      paddingTop: 5,
      paddingLeft: 5,
    }   
});

  const TableHeader = ({columns}) => (
    <View style={styles.container}>
        {columns.map(column=>(<Text key={column.title} style={{...styles.column, width: column.width?`${column.width}%`:'auto', fontSize: column.fontSize ? column.fontSize : 10}}>{column.title}</Text>))}
    </View>
  );
  
export default TableHeader