import {max} from 'lodash';

export const notificationReportsFormatter = {
        formatFrequencies,
        formatSettings,
        formatNewSettings
};

function formatFrequencies(frequencies){
    let maxId = max(Object.values(frequencies));
    frequencies['off'] = ++maxId;
    return frequencies;
}

function formatSettings(settingsArray) {
    let settings = {};
    for(let item of settingsArray) {
        settings[item.report_id] = item;
    }
    return settings;
}

function formatNewSettings(settings) {
    settings.status = settings.frequency != 3;
    return settings;
}