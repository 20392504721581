import React, { Component } from "react";
import { connect } from 'react-redux';

class WithSilo extends Component {

	constructor(props){
		super(props);
	}

	render() {
		let {user, siloCodes} = this.props;
		let allowed = true;
		
		if(siloCodes) {
			if(typeof siloCodes === 'string')
				siloCodes = [siloCodes];	
			allowed = siloCodes.includes(user.user.primary_silo_code)
		}
		
		if(!allowed){
			return null;
		}
		else {
			return {...this.props.children}
		}
	}
}

function mapStateToProps(state) {
	return { 
		user: state.user
	};
}

	

export default connect(mapStateToProps)(WithSilo); 