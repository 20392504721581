import React from "react";
import { useEffect, useState } from "react";

const MatchMediaWrapper = (props) => {
  const [mQuery, setMQuery] = useState({
    matches: window.innerWidth > props.breakpoint ? true : false,
  });

  const [deviceType, setDeviceType] = useState("");
  
  useEffect(() => {
    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone)\b/i.test(UA);
      }
    }
    
    if (hasTouchScreen && window.matchMedia("(max-width: 861px)").matches) {
      setDeviceType("mobile");
    } else if (window.matchMedia("(max-width: 861px)").matches) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }

    let mediaQuery = window.matchMedia('(min-width: ' + parseInt(props.breakpoint + 1) + 'px)');
    mediaQuery.addListener(setMQuery);
    
    return () => mediaQuery.removeListener(setMQuery);

  }, []);

  return (
    <React.Fragment>
      {mQuery && !mQuery.matches && props.isMobile && (props.device === "mobile" || deviceType === "mobile") ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : (mQuery && mQuery.matches && !props.isMobile && <React.Fragment>{props.children}
          </React.Fragment>)}
    </React.Fragment>
  );
};

export default MatchMediaWrapper;