import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { find, cloneDeep, get, capitalize, map } from 'lodash';
import store from '../../helpers/store'
import ReactSelect from '../../components/common/ReactSelect';
import SearchWidget from '../../components/pages/playlists/Filter/Search';
import FilterList from '../../components/widgets/entityFilter/FilterList';
import EntitiesList from '../../components/widgets/entityFilter/EntitiesList';
import SampleCSV from '../../components/widgets/entityFilter/SampleCSV';
import CSVImportModal from '../../components/common/CSVImportModal';
import CSVSampleDownload from '../../components/common/CSVSampleDownload';
import Spinner from '../../components/common/Spinner';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';

import { userEntityFiltersActions } from '../../data/actions/user_entity_filters';
import { searchActions } from '../../data/actions/search';
import { playlistsActions } from '../../data/actions/playlists';
const allEntityTypes = ['playlists'];
const deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const editSvg = require('!svg-inline-loader!../../../public/img/icons/edit.svg');

class PlaylistsFilterDetails extends React.Component {

	constructor(props){
		super(props);
		// const currentFilter = store.get('userEntityFilter') || null;
		this.state = {
			currentFilter: null,
			existingFilter: null,
			searchActive: false,
			entityType: null,
			entitySearchResults: null,
			importTarget: null
		}
		this.setFilterState = this.setFilterState.bind(this);
		this.addNewFilter = this.addNewFilter.bind(this);
		this.addToFilter = this.addToFilter.bind(this);
		this.addAllToFilter = this.addAllToFilter.bind(this);
		this.bulkAddToFilter = this.bulkAddToFilter.bind(this);
		this.dummyBulkAddToFilter = this.dummyBulkAddToFilter.bind(this);
		this.addMappedResultsToFilter = this.addMappedResultsToFilter.bind(this);
		this.selectFilter = this.selectFilter.bind(this);
		this.selectFilterByID = this.selectFilterByID.bind(this);
		this.updateFilterField = this.updateFilterField.bind(this);
		this.removeFromFilter = this.removeFromFilter.bind(this);
		this.saveFilter = this.saveFilter.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.addRecentFilter = this.addRecentFilter.bind(this);
		this.setSearchActive = this.setSearchActive.bind(this);
		this.updateTitle = this.updateTitle.bind(this);
	}
	
	componentDidMount(){
		const { match, dispatch } = this.props; 
		const { id } = match.params;
		let getFilterData, mode;
		if(id == 'new') {
			getFilterData = Promise.resolve(this.getNewFilter());
			mode = 'new';
		}
		else {
			getFilterData = dispatch(userEntityFiltersActions.getUserEntityFilter(id));
			mode = 'edit';
		}
		getFilterData.then(currentFilter=>{
			this.setState({currentFilter, mode, existingFilter: cloneDeep(currentFilter)});
		})
		
	}
	
	getNewFilter() {
		let entities = []
		if(this.props) {
			entities = get(this.props, 'location.state.defaultEntities', [])
		}
		entities = entities.map(item=>({
			entity_id: item.id,
			entity_type: item.type,
			entity_title: item.title,
			entity_image: null,
			fav_hide: 'favorite'
		}))
		return {caption: 'New Filter', entities};
	}
	
	addNewFilter() {
		const caption = prompt('New Filter Name')
		if(!caption)
			return;
		
		this.props.dispatch(userEntityFiltersActions.createFilter({caption}))
		.then(newFilter => {
			this.setState({
				...this.state,
				currentFilter: {
					...newFilter,
					entities: [
					   ...this.state.currentFilter.entities
					]
				}
			}, ()=>this.saveFilter())
		});
	}
	
	renderRecentFilters() {
		const { currentFilter, recentFilters } = this.state;
		return <FilterList currentFilter={currentFilter} filters={recentFilters} onSelect={this.selectFilterByID} />
	}
	
	renderPinnedFilters() {
		const { currentFilter } = this.state,
			{ filters = [] } = this.props.userEntityFilters;
		const pinnedFilters = filters.filter(filter=>filter.is_pinned);
		return <FilterList currentFilter={currentFilter} filters={pinnedFilters} onSelect={this.selectFilterByID} />        
	}
	
	addRecentFilter(filter) {
		let recentFilters = store.get('recentEntityFilters') || [];
		const newFilter = {id: filter.id, caption: filter.caption};
		if(!find(recentFilters, filter=>filter.id == newFilter.id)) {
			recentFilters.unshift(newFilter);
			store.set('recentEntityFilters', recentFilters);
			return new Promise((resolve) => this.setState({recentFilters}, resolve));
		}
		else 
			return Promise.resolve();
	}
	
	setFilterState(currentFilter, callback) {
		//store.set('userEntityFilter', currentFilter);
		this.setState({
			...this.state,
			currentFilter,
			searchActive: false
		}, callback);
	}
	
	selectFilter(currentFilter) {
		this.addRecentFilter(currentFilter)
		.then(()=>{
			this.setFilterState(currentFilter);
		});
	}
	
	selectFilterByID(id) {
		const { filters = [] } = this.props.userEntityFilters;
		const currentFilter = find(filters, filter=>filter.id == id);
		if(currentFilter)
			this.selectFilter(currentFilter);
	}
	
	addToFilter(items, include) {
		const { id: filterID, entities } = this.state.currentFilter;
		if(!Array.isArray(items))
			items = [items];
		let newEntities = cloneDeep(entities);
		for(let item of items) {
			const newFilterEntity = {
				user_filter_id: filterID,
				entity_id: item.id,
				entity_type: item.entity,
				entity_title: item.name_raw,
				entity_image: item.image,
				fav_hide: 'favorite'
			};
			newEntities = newEntities.filter(entity=>(!(entity.entity_id==newFilterEntity.entity_id && entity.entity_type==newFilterEntity.entity_type)));
			newEntities.push(newFilterEntity);
		}
		this.setFilterState({
			...this.state.currentFilter,
			entities: newEntities 
		});
	}
	
	addAllToFilter(items, include) {
		let newItems = [];
		for(let key of Object.keys(items)) {
			if(Array.isArray(items[key])) {
				newItems = newItems.concat(items[key])
			}
		}
		this.addToFilter(newItems, include);
	}
	
	bulkAddToFilter(fields) {
		if(!fields.length)
			return;
		
		let { target } = fields[0];
		let	source = 'playlists';
		
		const searchString = map(fields, 'field').join('\n');
		this.props.dispatch(playlistsActions.getPlaylistsAutocompleteBatch(searchString))
		.then(results=>{
			this.addToFilter(results, (target == 'include'));
		});
	}
	
	dummyBulkAddToFilter() {
		const fields = [{
			'target': 'include',
			'source': 'artists',
			'field': 'Mitski'
		}, {
			'target': 'include',
			'source': 'artists',
			'field': 'Marco'            
		}];
		let { target, source } = fields[0];
		const searchString = map(fields, 'field').join('\n');
		this.props.dispatch(searchActions.getSearchImport(searchString, [source]))
		.then(results=>{
			this.setState({
				entitySearchResults: results,
				importTarget: target
			})
		});
	}
	
	addMappedResultsToFilter(results) {
		const { importTarget } = this.state;
		const filteredResults = results.filter(result=>(result.id !== null));
		this.addToFilter(filteredResults, (importTarget == 'include'));
		this.setState({
			entitySearchResults: null,
			showFieldMapper: false,
			importTarget: null
		})
	}
	
	removeFromFilter(id, type) {
		const { entities } = this.state.currentFilter;
		let newEntities = entities.filter(entity=>(!(entity.entity_id==id && entity.entity_type==type)));
		this.setFilterState({
			...this.state.currentFilter,
			entities: newEntities 
		});
	}
	
	updateFilterField(field, value) {
		const { id } = this.state.currentFilter;
		this.setFilterState({
			...this.state.currentFilter,
			[field]:value 
		}, ()=>{
			this.props.dispatch(userEntityFiltersActions.updateUserEntityFilter(id, {[field]:value}))    
		})
		
	}
	
	updateTitle() {
		const caption = prompt('Change Filter Name')
		if (!caption)
			return;

		this.updateFilterField('caption', caption)
	}
	
	saveFilter() {
		const { currentFilter, existingFilter } = this.state;
		const entitiesToAdd = currentFilter.entities.filter(entity=>!entity.id); 
		const entitiesToDelete = existingFilter.entities.filter(existingEntity => !find(currentFilter.entities, entity => entity.id == existingEntity.id));
		this.props.dispatch(userEntityFiltersActions.updateFilterEntities(currentFilter.id, entitiesToAdd, entitiesToDelete, false))
		//.then(currentFilter=>this.setFilterState(currentFilter));
		.then(this.props.history.push('/playlists/filter'));
	}
	
	deleteFilter() {
		const { id } = this.state.currentFilter;
		this.props.dispatch(userEntityFiltersActions.destroyFilter(id))
		.then(this.setFilterState(null));
	}
	
	setSearchActive(searchActive) {
		this.setState({searchActive});
	}
	
	resetFilter(){
		this.setFilterState(null, ()=>{
			this.props.applyFilter();
		})
	}
	
	renderFilterSelect() {
		const { currentFilter } = this.state,
			{ loading, filters = [] } = this.props.userEntityFilters,
			filterOptions = filters.map(filter=>({label: filter.caption, value: filter})),
			selectedFilter = currentFilter ? find(filterOptions, option=>option.value.id == currentFilter.id) : null;
			
		return <ReactSelect
			loading
			value={selectedFilter}
			options={filterOptions}
			onChange={({value})=>this.selectFilter(value)}
			isLoading={loading}
			isSearchable={true}
			onFocus={()=>this.setSearchActive(true)}
		/>
	}
	
	renderFilterEntities(entities, entityType) {
		if(!entities)
			return null;
		
		const entitiesInFilter = entities.length;
		const entityTypes = entityType ? [entityType] : allEntityTypes;
		return <div className="pinned-filters-holder filter-page">
			{entitiesInFilter == 0 && <p className="select-entity-text">Search a playlist from the search by on the left, or upload a file of multiple playlist IDs to create your filter.</p>}
			{entitiesInFilter > 0 && <div className="pinned-filters-item">
				<div className="pinned-entity-list">
					{entityTypes.map(type=><EntitiesList entities={entities} type={type} fav_hide={'favorite'} onDelete={this.removeFromFilter} />)}
				</div>
			</div>}
		</div>
	}  
			
	setEntityType(entityType) {
		this.setState({entityType});
	}
	
	render() {
		const { currentFilter: filter , searchActive, mode, entityType, showFieldMapper, entitySearchResults }  = this.state;
		const { applyFilter, search: { importLoading } } = this.props;
		const onClickAction = (mode == 'new') ? this.addNewFilter : this.saveFilter;
		const filterCSVFields = [ 
			 {key: 'field', required: true, label: 'Playlist ID', placeholder: '5KHQzdL4544ZJfSmiB5MXc'},
			 {key: 'target', required: true, label: 'Add To', options: [{label:'Inclusions', value:'include'}, {label:'Exclusions', value:'exclude'},],  placeholder: 'inclusions'}
			 
		 ];
		const widgetTitle = mode == 'edit' ? 'Edit Playlists Filter' : 'Add Playlists Filter';
		return (
			 <div className="user-entity-filter-holder create-filter">
				 <h3 className="user-filter-title saved-filter-title ">{widgetTitle}</h3>
				 <h3 className="back-link"><Link to="/playlists" className="saved-filter-title-actions">back</Link></h3>
				 {filter && <div className="user-entity-filter">
					 <div className="search-holder">
						 <p className="text">Search entities to include to filter</p>
						 <p className="sub">To search for multiple at once, use the 'Download Sample' button to create a list of Playlists IDs to bulk upload.</p>
						 <div className="add-filter-search-holder">
							 <SearchWidget searchResult={this.addToFilter} placeholder="Search..." mode="playlist" entities={['playlists']} currentlySelectedItems={filter.entities} batch={false} />
							 <Spinner enabled={importLoading} className="spinner spinner-inline"  />
							 <MatchMediaWrapper isMobile={false} breakpoint={639}>
								 <div className="search-holder-btns">
									 <CSVImportModal fields={filterCSVFields} buttonClass="default-btn default-btn--small milestone-import-btn default-btn--light" modalTitle="Import To Filter" buttonTitle="Choose File" onSubmit={this.bulkAddToFilter} />                         
									 <CSVSampleDownload fields={filterCSVFields} linkClass="button-no-style download-scv" linkTitle="Download Sample" />
									 {/* <button onClick={this.dummyBulkAddToFilter}>Demo Search</button> */}
								 </div>
							  </MatchMediaWrapper>

						 </div>
					 </div>
					<div className="current-filter-holder"> 
					  <div className="current-filter-title-holder">
						<h3 className="current-filter-title">{filter.caption}</h3>
						{mode == 'edit' && <button className="update-filter-title" onClick={()=>{this.updateTitle()}}>
						  <span dangerouslySetInnerHTML={{__html: editSvg}}/>
						</button>}
					  </div>
					  <div className="results-holder">
						 {this.renderFilterEntities(filter.entities, entityType)}
						 <div className="entity-filter-dropdown-list">
							{mode == 'edit' && <button onClick={this.saveFilter} className="default-btn default-btn--small update-filter">Update Filter</button>}
							<button onClick={this.addNewFilter} className="default-btn default-btn--small default-btn--light">Save As New Filter</button>
							{mode == 'edit' && <div className="entity-filter-action"> 
								<div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
									<input type="checkbox" id="filter_is_shared" checked={filter.is_shared} onChange={()=>this.updateFilterField('is_shared', !filter.is_shared)} className="input" />
									<label htmlFor="filter_is_shared" className="checkbox-label checkbox-switcher">Share</label>
								</div>
								<div className="checkbox-holder checkbox-holder--block checkbox-switcher-holder">
									<input type="checkbox" id="filter_is_pinned" checked={filter.is_pinned} onChange={()=>this.updateFilterField('is_pinned', !filter.is_pinned)} className="input" />
									<label htmlFor="filter_is_pinned" className="checkbox-label checkbox-switcher">Pin</label>
								</div>
							</div>}
						 </div>
						 <div>
							<button onClick={this.deleteFilter} className="remove-filter-link filter-reset-btn button-no-style">
							<span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>Delete Filter</button>
						</div>
					 </div>                    
				 </div>
				 </div>}          
			</div>   
		)
	}
}

function mapStateToProps(state) {
	return {
		userEntityFilters: state.userEntityFilters,
		search: state.search
	} 
}
export default connect(mapStateToProps)(withRouter(PlaylistsFilterDetails));