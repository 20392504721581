import { catalogueExceptionsConstants } from '../constants/catalogue_exceptions';
import { catalogueExceptionsService } from '../services/catalogue_exceptions';
import { catalogueExceptionsFormatter } from '../formatters/catalogue_exceptions';

export const catalogueExceptionsActions = {
    getCatalogueExceptions,
    getCatalogueExceptionsCount,
    updateCatalogueExceptions
};

function getCatalogueExceptions(payload) {
    return (dispatch, getState) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        catalogueExceptionsService.getCatalogueExceptions(payload)
            .then(
                catalogueExceptions => {
                    const data = catalogueExceptionsFormatter.formatCatalogueExceptions(catalogueExceptions);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_REQUEST } }
    function success(exceptions, pages) { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_SUCCESS, exceptions, pages } }
    function failure(error) { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_FAILURE, error } }
}

function getCatalogueExceptionsCount() {
    return dispatch => {
        dispatch(request());

      return catalogueExceptionsService.getCatalogueExceptionsCount()
        .then( response => {
           const data = catalogueExceptionsFormatter.exceptionsCountFormat(response);
           data && dispatch(success(response));
           return data && response;
        })
        .catch( error => {
          dispatch(failure(error));
        });
    };

    function request() { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_COUNT_REQUEST } }
    function success(exceptions) { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_COUNT_SUCCESS, exceptions } }
    function failure(error) { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_COUNT_FAILURE, error } }
}

function updateCatalogueExceptions(params, cb) {
    return dispatch => {
        dispatch(request());

        catalogueExceptionsService.updateCatalogueExceptions(params)
            .then(
                catalogueExceptions => {
                    dispatch(success('Exceptions have been updated'));
                    if (cb && typeof cb === 'function') {
                        cb()
                    }
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_UPDATE_REQUEST } }
    function success(message) { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_UPDATE_SUCCESS, title: '' } }
    function failure(message) { return { type: catalogueExceptionsConstants.GET_CATALOGUE_EXCEPTIONS_UPDATE_FAILURE, title: 'update exceptions error' } }
}
