import React from 'react';
import ReactTooltip from 'react-tooltip'
import { concat } from 'lodash';
import {round, splitByThousands } from '../../../../helpers/NumberFormatter';
import Tooltip from "../../../common/Tooltip";

function getName(item) {
    return <span>{item.name} {item.code && <span className="newline">{item.code}</span>} </span>  
}


export default function EntityTable(props){
    let headers = [
        {text: 'Name'}, 
        {text: 'Prev Streams'}, 
        {text: 'Streams'}, 
        {text: 'Streams growth'}, 
        {text: 'Passive Streams', tooltipId: "passive-steams-tip", tooltipText: 'Passive Streams: editorial, third party and algorithmic playlists, radio, and other (such as the Spotify autoplay feature). This may also include streams from a playlist with the artist name (This is, Essentials…) where the artist is not the main primary artist on the track, like featuring or remixes for instance.'}, 
        {text: 'Active Streams', tooltipId: "active-streams-tip", tooltipText: 'Active Streams: streams coming from search, artist page, album page, collections, and playlists with the artist name in it (This is, Essentials…)'}, 
        {text: 'Engaged'},
        /*
        'Prev Listeners', 
        'Listeners', 
        'Listeners growth'
        */                                     
    ],
    cells = (item)=>[getName(item),
        splitByThousands(item.prev_units),
        splitByThousands(item.curr_units),
        round(item.units_diff)+'%',
        splitByThousands(item.passive),
        splitByThousands(item.active),
        round(item.engaged)+'%',
        /*
        splitByThousands(item.prev_listeners),
        splitByThousands(item.curr_listeners),
        round(item.listeners_diff)+'%'
        */
    ],
    extraHeaders = [], extraCells = [];
    
    if(props.entity !== 'playlists') {
        extraHeaders = [
            {text: 'Prev Playlists'}, 
            {text: 'Playlists'}, 
            {text: 'Playlists growth'}
        ];
        
        extraCells = (item)=> [
            splitByThousands(item.prev_playlists),
            splitByThousands(item.curr_playlists),
            round(item.playlists_diff)+'%'
        ];
    }
    else {
        extraHeaders = [
            {text: 'Prev Tracks'}, 
            {text: 'Tracks'}, 
            {text: 'Tracks growth'}
        ];
        
        extraCells = (item)=> [
            splitByThousands(item.prev_tracks),
            splitByThousands(item.curr_tracks),
            round(item.tracks_diff)+'%'
        ];        
    }
    let allHeaders = concat(headers, extraHeaders);
        
    return <div className="row">
        <div className="top-table-holder">
            <table className="table table-striped no-margins table-hover ">
                <thead>
                    <tr>
                        {allHeaders.map(header=>
                            <th className="top-table-header">
                                <div className="top-table-header-inner">
                                    {header.text}
                                    {header.tooltipText ? (
                                        <Tooltip position="bottom" message={`${header.tooltipText}`} tooltipClass="toolbar-title-tooltip"/>
                                    ) : null}
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.results.map((item, index)=>{
                        let allCells = concat(cells(item), extraCells(item));
                        return <tr key={item.id} className={index % 2 == 0 ? "gradeX" : "gradeC"}>
                            {allCells.map(item=><td>{item}
                        </td>)}
                    </tr>})}
                </tbody>
            </table>
        </div>
    </div>    
}
