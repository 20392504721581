import React from 'react';
import { map } from 'lodash';
import moment from 'moment';
import Box from '../../../common/Box';
import { LineChart } from '../../../widgets/charts/types'
import { formatChartLabel } from '../../../../helpers/DateFormatter';

export default function EntityTimeline(props){
    let labels = [], 
        datasets = [], 
        { results, mode = 'line' } = props,
        legendThreshold = 10,
        legendPosition = 'right';
        
    if(results && results.length) {
        datasets = results.map(entity=>({
            label: entity.name,
            data: map(entity.stms_by_date, (item)=>item.units),
            fill: false
        }));
        labels = map(results[0].stms_by_date, (item)=>formatChartLabel(item));
        legendPosition = datasets.length > legendThreshold ? 'bottom' : 'right';
    }
    return datasets.length ? <LineChart data={{labels, datasets}} options={{legend: {display: true, position: legendPosition}}}/> : null  ;
}
