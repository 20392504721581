import React, { Component } from 'react';
import { map, find, sum } from 'lodash';
import PercentageValue from '../../../common/PercentageValue';
import CountryFlag from '../../../common/CountryFlag'

export const heartbeatsSettings = {
  settingsForTable
};

function settingsForTable(){
	let columns = [ 
		{
		   id: 'vend_id',
		   Header: '',
		   accessor: 'vend_id',
		   width: 20,
		   Pivot: ({ isExpanded, ...rest }) => (
			<div>
			  {isExpanded ? (
				<span>&#x25BC;</span>
			  ) : (
				<span>&#x25B6;</span>
			  )}
			</div>
		  ),

		},
	   {
		   id: 'vendor_display',
		   Header: 'Platform',
		   accessor: 'vendor_display',
		   Cell: props => {
			   return <span>{props.original.vendor}</span>
		   }
		   /*
		   Aggregated: row => {
		   	   if(Array.isArray(row) && row.length > 0) {
					  const vendor = row[0];
					return <span>{vendor.vendor} ({vendor.share}%)</span>   
			   }
			   else {
				   return <span>Unknown Vendor</span>;
			   }
		   }
		   */

		   
	   },
	   {
		   id: 'content',
		   className: 'text-align-left',
		   Header: 'Content Type',
		   accessor: 'content',
		   Aggregated: row => {
		   	return <span></span>
		   }

	   },       
	   {
		  id: 'country',
		  className: 'text-align-left',
		  Header: 'Territory',
		  accessor: 'country',
		  Cell: props => {
			  const { original } = props;
			  return <span><CountryFlag country={original.territory} /> {original.territory_name} ({original.territory_share})% </span>
		  },
		  Aggregated: row => {
			 return <span></span>
		 }

	  },       
	   
	   {
		   id: 'period_1_units',
		   className: 'text-align-left',
		   Header: 'Previous Week',
		   accessor: 'period_1_units',
		   aggregate: (values, rows) => sum(values),
	   },       
	   {
		  id: 'period_2_units',
		  className: 'text-align-left',
		  Header: 'Current Week',
 		  accessor: 'period_2_units',
		  aggregate: (values, rows) => sum(values),	
	   }
 	   
   ];
   return columns
};
