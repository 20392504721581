import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import SharedPageView from '../../views/SharedPage';
import ImprintDetailsView from '../../views/Imprint/Details';
import ArtistProfileView from '../../views/Artist/Profile';
import ProductDetailsView from '../../views/Product/Details';
import TrackDetailsView from '../../views/Track/Details';
import OverviewSharedView from '../../views/OverviewShared';
import AudienceOverviewView from '../../views/Audience/Overview';
import TrackComparisonView from '../../views/TrackComparison/Search';
import FilterResultView from '../../views/Result';
import FilterResultDownloadView from '../../views/ResultDownload';
import HeartBeatsView from '../../views/PagedHeartbeats';
import HeartAttacksView from '../../views/HeartAttacks';
import SpotifyMonetizationReportView from '../../views/SpotifyMonetization/Report';

import WithUser from '../../helpers/WithSharedUser';

const WithUserImprintDetailsView = WithUser(ImprintDetailsView);
const WithUserArtistProfileView = WithUser(ArtistProfileView);
const WithUserProductDetailsView = WithUser(ProductDetailsView);
const WithUserTrackDetailsView = WithUser(TrackDetailsView);
const WithUserOverviewSharedView = WithUser(OverviewSharedView);
const WithUserAudienceOverviewView = WithUser(AudienceOverviewView);
const WithUserTrackComparisonView = WithUser(TrackComparisonView);
const WithUserFilterResultView = WithUser(FilterResultView);
const WithUserFilterResultDownloadView = WithUser(FilterResultDownloadView);
const WithUserHeartBeatsView = WithUser(HeartBeatsView);
const WithUserHeartAttacksView = WithUser(HeartAttacksView);
const WithUserSpotifyMonetizationReportView = WithUser(SpotifyMonetizationReportView);

const unauthorized=(props)=>{
    return <Switch>
        <Route path="/share/imprints/:id" render={(props)=><WithUserImprintDetailsView sharedMode={true} {...props} />}></Route>
        <Route path="/share/artists/:id" render={(props)=><WithUserArtistProfileView sharedMode={true} {...props} />}></Route>
        <Route path="/share/products/:id" render={(props)=><WithUserProductDetailsView sharedMode={true} {...props} />}></Route>
        <Route path="/share/tracks/:id" render={(props)=><WithUserTrackDetailsView sharedMode={true} {...props} />}></Route>
        <Route path="/share/overview/" render={(props)=><OverviewSharedView sharedMode={true} {...props} />}></Route>
        <Route path="/share/audience/:entity/:id" render={(props)=><AudienceOverviewView sharedMode={true} {...props} />}></Route>
        <Route path="/share/comparison/:entity/:ids/:weeks" render={(props)=><TrackComparisonView sharedMode={true} {...props} />}></Route>
        <Route path="/share/download/filter/:filter" render={(props)=><WithUserFilterResultDownloadView sharedMode={true} {...props} />}></Route>
        <Route path="/share/filter/:filter" render={(props)=><WithUserFilterResultView sharedMode={true} {...props} />}></Route>
        <Route path="/share/reporting/heartbeats/:week?/:minStreams?/:percentage?/:minTiktokCreates?/:newRelease/:vendor?" render={(props)=><WithUserHeartBeatsView sharedMode={true} {...props} />}></Route>
        <Route path="/share/reporting/nosedives/:week?/:minStreams?/:percentage?/:minTiktokCreates?/:newRelease/:vendor?" render={(props)=><WithUserHeartAttacksView sharedMode={true} {...props} />}></Route>
        <Route path="/share/reporting/spotify-monetization/:dates?" render={(props)=><WithUserSpotifyMonetizationReportView sharedMode={true} {...props} />}></Route>
        
        <Route path="/share/:slug" component={SharedPageView}></Route>
    </Switch>
    
    }

export default unauthorized; 