import React from 'react'
import ReactDOM from 'react-dom';
import ScrollButtons from '../ScrollButtons';

export default Component => {
  const wrapper = class RTCellHeader extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        resizeObserver: undefined,
        tableContainer: undefined,
        hasHorizontalScroll: false,
      }
      this.getWrappedInstance.bind(this)
    }

    componentDidMount() {
      const cellHeaderTable = ReactDOM.findDOMNode(this.wrappedInstance);
      const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];
      this.setState({
        tableContainer
      });

      if (this.props.hasResizeAdjustment && window.ResizeObserver) {
          const resizeObserver = new ResizeObserver((entries) => {
              this.setState({
                  hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
              });
          });
          this.setState({
            resizeObserver
          });
      
          resizeObserver.observe(tableContainer);
      } else {
        // console.log("Resize observer not supported!");
        this.setState({
          hasHorizontalScroll: tableContainer.scrollWidth > tableContainer.clientWidth
        });
      }
    }

    componentWillUnmount() {
      const cellHeaderTable = ReactDOM.findDOMNode(this.wrappedInstance);
      const tableContainer = cellHeaderTable.getElementsByClassName('rt-table')[0];

      this.state.resizeObserver && this.state.resizeObserver.unobserve(tableContainer)
    }

    // this is so we can expose the underlying ReactTable to get at the sortedData for selectAll
    getWrappedInstance = () => {
      // if (!this.wrappedInstance) console.warn('RTCellHeader - No wrapped instance')
      if (this.wrappedInstance.getWrappedInstance) return this.wrappedInstance.getWrappedInstance()
      else return this.wrappedInstance
    }
    
    replaceCell = (column) => {
      if(!column.cellReplaced) {
        let _cell = column.Cell;
        if(!_cell || typeof _cell !== "function" )
            _cell = (props)=>props.value;
            
        let _header = column.Header;
        if(!_header || typeof _header !== "function" ) {
            _header = ()=>column.Header;
        }

        column.Cell = (props)=>{return <div><div className="td-header">{_header(props)}</div>{_cell(props)}</div>}
        column.cellReplaced = true;
      }
        return column
    }

    render() {
      const { columns, showScrollBtn, HOCTableClass, ...rest } = this.props
      const { tableContainer, hasHorizontalScroll } = this.state
      const extra = {
        columns: columns.map(column => {
            if(column.columns!==undefined)
                column.columns.map(this.replaceCell)
            else
                column = this.replaceCell(column);
            return column;
        }),
      }

      return (
        <div className={HOCTableClass}>
          {showScrollBtn && hasHorizontalScroll && <ScrollButtons el={tableContainer} />}
          <Component {...rest} {...extra} ref={r => (this.wrappedInstance = r)} />
        </div>
      )
    }
  }
  wrapper.displayName = 'RTCellHeader'
  wrapper.defaultProps = {
    treeTableIndent: 10,
    hasResizeAdjustment: true,
    showScrollBtn: true,
    HOCTableClass: '',
  }

  return wrapper
} 