import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { map, merge, sum, cloneDeep } from 'lodash';
import { colors, doughnut } from '../ChartDefaults';

class DoughnutChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            options: merge({}, doughnut, props.options)
        };
    }
    render() {
        let chartData = cloneDeep(this.props.data);
         
        
            
        let { data, options, id, shadowChart = false, shadowChartClass = 'chart-export-shadow', shadowChartWidth = 400, shadowChartHeight = 300, addPercentage = true , shadowChartOptions, ...rest } = this.props;
        shadowChartOptions = merge({}, this.state.options, shadowChartOptions);
        const dataReady = chartData && chartData.datasets;
        
        if(dataReady && addPercentage) {
            const dataset = chartData.datasets[0].data;
            const total = sum(dataset);
            for(let index in dataset) {
                const percent = total ? Math.round(dataset[index]/total*100) : 0;
                chartData.labels[index] = `${chartData.labels[index]} (${percent}%)`; 
            }
        }
        
        return (dataReady ? <React.Fragment>
            <Doughnut data={chartData} options={this.state.options} {...rest} ></Doughnut>
            {shadowChart && <div className={shadowChartClass}><Doughnut width={shadowChartWidth} height={shadowChartHeight} id={id} data={chartData} options={shadowChartOptions} {...rest}></Doughnut></div>}
        </React.Fragment>: null);
    }
}

export default DoughnutChart