import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { isEqual } from 'lodash';
import CatalogOverview from '../components/widgets/catalog/CatalogOverview';
import Spinner from '../components/common/Spinner';
import DataProvider from '../components/pages/result/DataProvider';
import Header from '../components/pages/result/Header';
import AutoDownload from '../components/pages/result/AutoDownload';
import exportToCsv from '../components/pages/result/CSVFunctions';
import ResultTour from '../components/pages/result/Tour';
import store from '../helpers/store'

import ResultOverview from './Result/Overview';
import ResultAudience from './Result/Audience';
import ResultPlaylists from './Result/Playlists';
import ResultProfile from './Result/Profile';
import ResultSocial from './Result/Social';

var downloadSvg = require('!svg-inline-loader!../../public/img/download.svg');

class ResultDownload extends Component {
	constructor(props) {
		super(props);
		const sharedTabs = store.get('sharedTabs');
		this.state = {
			filterInitialized: false,
			exportInProgress: false,
			title: null,
			savedFilterID: null,
			sharedTabs: sharedTabs || []
		}
		this.exportProgress = this.exportProgress.bind(this);
		this.setTitle = this.setTitle.bind(this);
	}
	
	componentDidMount(){
		const { match, dispatch } = this.props;
		const filter = JSON.parse(match.params.filter);
		this.setState({
			filter, 
			filterString: match.params.filter,
			filterInitialized: true
		});
//        dispatch(filterActions.appendEntityFilter(filter))
//        .then((filter)=>{console.log(filter); this.setState({filterInitialized: true})});
	}
	
	componentWillReceiveProps(nextProps) {
		const { match } = this.props,
			{ match: nextMatch } = nextProps;
		
		if(!isEqual(match.params.filter, nextMatch.params.filter)) {
			this.setState({
				filterInitialized: false
			}, ()=>{
				const filter = JSON.parse(nextMatch.params.filter);
				this.setState({
					filterInitialized: true,
					filter,
					filterString: nextMatch.params.filter
				});
			});
		}
	}
	
	getCurrentTab(){
		const { location } = this.props;
		return location.pathname.substr(location.pathname.lastIndexOf('/')+1);
	}
	
	exportProgress(exportInProgress){
		this.setState({exportInProgress})
	}
	
	setTitle(title, savedFilterID){
		this.setState({title, savedFilterID});
	}
		
	renderTabs(filterString, sharedMode = false, sharedTabs = [], tab, exportProps, exportProgress){
		const share = sharedMode ? '/share' : '';
		const tabAllowed = (tab) => (!sharedTabs.length || sharedTabs.includes(tab));
		
		return <div>
			<h2 className="download-links-title">Click on links below to download</h2>
			<ul className="profile-navigation">
				{tabAllowed('overview') && <NavLink className="profile-navigation-link" to={`${share}/download/filter/${filterString}/overview`}>Overview</NavLink>}
				{tabAllowed('audience') && <NavLink className="profile-navigation-link" to={`${share}/download/filter/${filterString}/audience`}>Audience</NavLink>}
				{tabAllowed('social') && <NavLink className="profile-navigation-link" to={`${share}/download/filter/${filterString}/social`}>Social Music</NavLink>}
				{tabAllowed('playlists') && <NavLink className="profile-navigation-link" to={`${share}/download/filter/${filterString}/playlists`}>Playlists</NavLink>}
				{tabAllowed('profile') && <NavLink className="profile-navigation-link" to={`${share}/download/filter/${filterString}/profile`}>Milestones</NavLink>}
			</ul>
			{tab && <AutoDownload tab={tab} exportToCsv={()=>{exportToCsv(this.getCurrentTab(), exportProps, exportProgress)}} />}
			</div>
	}
	

	render() {
		const { filterInitialized, filter, filterString, exportInProgress, savedFilterID, sharedTabs = [] } = this.state;
		const { sharedMode = false } = this.props;
		if(!filterInitialized)
			return null;
			
		const firstTab = sharedTabs.length > 0 ? sharedTabs[0] : 'overview';
		
		const tab= this.getCurrentTab();
		
		let flatFilter = [];
		for(let entity of Object.keys(filter)) {
			//for(let id of filter[entity]) {
				flatFilter.push({id: entity, value: filter[entity]})
			//}
		}
		const exportProps = {
			...this.props,
			entityFilter: this.state.filter,
			entityTitle: this.state.title
		}

		return <div className="inner-page result-page download">
			<DataProvider filtered={filter}>
				<div>
					<Header filtered={filter} tabs={this.renderTabs(filterString, sharedMode, sharedTabs, tab, exportProps, this.exportProgress)} sharedMode={sharedMode} onTitle={this.setTitle}>
						<CatalogOverview view="home" filtered={flatFilter} newClass="artist-overview" />
					</Header>
				</div>
				<Switch>
					<Route path="/share/download/filter/:filter/overview" render={(props)=><ResultOverview sharedMode={true} download={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
					<Route path="/share/download/filter/:filter/audience" render={(props)=><ResultAudience sharedMode={true} download={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
					<Route path="/share/download/filter/:filter/playlists" render={(props)=><ResultPlaylists sharedMode={true} download={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
					<Route path="/share/download/filter/:filter/profile" render={(props)=><ResultProfile sharedMode={true} download={true} filter={filter} flatFilter={flatFilter} {...props} />}></Route>
					<Route path="/share/download/filter/:filter/social" render={(props)=><ResultSocial sharedMode={true} filter={filter} download={true} flatFilter={flatFilter} {...props} />}></Route>
					<Redirect exact from={"/share/download/filter/:filter"} to={`/share/download/filter/${filterString}/${firstTab}`} />
				</Switch>
			</DataProvider>
		</div>
			
	}
}

function mapStateToProps(state) {
	return {
		userEntityFilters: state.userEntityFilters,
		stats: state.stats,
		filter: state.filter,
		imprints: state.imprints,
		artists: state.artists,
		products: state.products,
		projects: state.projects,
		tracks: state.tracks,
		playlists: state.playlists,
		audience: state.audience,
		milestones: state.milestones
	} 
}

export default connect(mapStateToProps)(ResultDownload)