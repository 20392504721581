import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, cloneDeep, get, map } from 'lodash';
import Box from '../../common/Box';
import vendorsArray from '../../widgets/filter/enums/vendors';
import ReactTable from 'react-table';
import ModeSelect from '../../common/ModeSelect';
import CSV from '../../../helpers/CSVExport';
import ReactSelect from '../../common/ReactSelect';
import LineChart from '../../widgets/charts/types/LineChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';

import { tracksActions } from '../../../data/actions/tracks';
import { tiktokMetricsSettings } from './TiktokMetrics/Settings';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');


class TiktokMetrics extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			mode: 'pie',
	        vendor: 0,
	        pieMetrics: 'views'
		}
		this.exportToCsv = this.exportToCsv.bind(this);
		this.setVendor = this.setVendor.bind(this);
		this.setChartMode = this.setChartMode.bind(this);
		this.setPieMetrics = this.setPieMetrics.bind(this);
	}
	
	extractTableData = (table) => {
	    let tableData = [], pie = {};
	    const { vendor, pieMetrics } = this.state;
	    
        for(let track of table) {
            let { vendors, ...rest } = track;
            let vendorRow = find(vendors, row=>row.vend_id == vendor);
            let tableRow = {...rest, ...vendorRow};
            tableData.push(tableRow);
            
            for(let vendor of vendors) {
                let { vend_id: vendID } = vendor;
                if(!vendID)//exclude sum
                    continue;
                
                if(!pie.hasOwnProperty(vendID))
                    pie[vendID] = 0;
                pie[vendID] += vendor[pieMetrics];
            }
        }
	    return { tableData, pie };
	}

    exportToCsv(){
        const {tiktokMetrics} = this.props.tracks,
            filename = CSV.CSVHeader('social_metrics', '', this.props.filter.global);
        const { tableData } = this.extractTableData(tiktokMetrics.table);
        
        return CSV.CSVExport(tableData, {filename}, 'social_metrics');
    }
    
    setVendor({vend_id}) {
        this.setState({
            vendor: vend_id
        });
    }
    
    setPieMetrics({value}) {
        this.setState({
            pieMetrics: value
        });
    }
	
	setChartMode(mode){
		this.setState({mode});
		
		const { modeChange } = this.props;
		if(typeof modeChange == 'function')
			modeChange(mode);
			
		if(mode == 'line') {
			this.props.getTiktokMetricsTimeseries(this.props.filtered, this.state.dateGroup);
		}
	}

	
	renderToolbar(data = []){
        if(!data)
            return;
        
		let toolbar = [];
		let vendors = [...data, {label: 'All', value: 'All', vend_id: 0}];
		
		const vendorValue = vendors.find(vendor=>vendor.vend_id == this.state.vendor);
		toolbar.push(<div key="wrapper" className="ibox-action-holder">
		<div className="ibox-actions">
		    <ReactSelect value={vendorValue} options={vendors} onChange={this.setVendor} className="single-select"/>
		    <a key="download" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		</div>
		</div>)

		return toolbar;
	}
	
   renderToolbarPie(data = []){
        let toolbar = [];
        let vendors = [{label:'Views', value: 'views'}, {label:'Creates', value: 'creates'}];

        const vendorValue = vendors.find(vendor=>vendor.value == this.state.pieMetrics);
		
		
		const options = [
			{icon: pieSvg, value: 'pie', label: "Donut Chart"},
			{icon: lineSvg, value: 'line', label: "Line Chart"},
			
		];
		toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
			<div className="ibox-icon-holder mode-select">
				<ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
			</div>
        	<div className="ibox-actions">
            	<ReactSelect value={vendorValue} options={vendors} onChange={this.setPieMetrics} className="single-select"/>
        	</div>
        </div>)

        return toolbar;
    }

	
	
	componentDidMount() {
		const { mode } = this.state;
		if(mode == 'line')
			this.props.getTiktokMetricsTimeseries(this.props.filtered);
		else
			this.props.getTiktokMetrics(this.props.filtered);
	}
	
	componentWillReceiveProps(nextProps){
		const { mode } = this.state;
		if(nextProps.filter && nextProps.filter.global){
			if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
				if(mode == 'line')
					this.props.getTiktokMetricsTimeseries(nextProps.filtered);
				else
					this.props.getTiktokMetrics(nextProps.filtered);
			}                
		}
		if(nextProps.filtered && !isEqual(nextProps.filtered, this.props.filtered)) {
			if(mode == 'line')
				this.props.getTiktokMetricsTimeseries(nextProps.filtered);
			else
				this.props.getTiktokMetrics(nextProps.filtered);
		} 
	}
	
	render (){  
		const {tiktokMetrics, tiktokMetricsLoading, tiktokMetricsTimeseries, tiktokMetricsTimeseriesLoading} = this.props.tracks,
			widgetTitle = `Social Metrics`;
		const { vendor, pieMetrics, mode } = this.state;
		
		let tableData = [], pieLabels = [], pieDataset = [], vendors = [], pie = {};
		
		if(tiktokMetrics) {
		    //tableData = tiktokMetrics.filter(row=>row.vend_id == vendor);
//		    const uniqueMetrics = tiktokMetrics.filter(row=>row.vend_id);
		    const data = this.extractTableData(tiktokMetrics.table);
		    tableData = data.tableData;
		    pie = data.pie;
		    
		    vendors = tiktokMetrics.vendors;
		    for(let vendID of Object.keys(pie)) {
		        pieDataset.push(pie[vendID]);
		        pieLabels.push(find(vendors, vendor=>vendor.vend_id == vendID).label);
		    }
		}
		
	      let { shadowChartProps } = this.props;
	        if(shadowChartProps){
	            shadowChartProps.shadowChart = true;
	        }     


		return <React.Fragment>
            <Box title="Platforms" toolbar={this.renderToolbarPie()} spinnerEnabled={tiktokMetricsLoading || tiktokMetricsTimeseriesLoading} >
                <div className="chart-block">
                    {(mode == 'pie' && tiktokMetrics) && <DoughnutChart data={{labels:pieLabels, datasets:[{data: pieDataset, label: pieMetrics}]}} {...shadowChartProps} />}
					
					{(mode == 'line' && tiktokMetricsTimeseries) && <LineChart data={{labels:tiktokMetricsTimeseries.labels, datasets: tiktokMetricsTimeseries.datasets[pieMetrics]}} {...shadowChartProps} />}

                </div>
            </Box>		
    		<Box title={widgetTitle} toolbar={this.renderToolbar(vendors)} spinnerEnabled={tiktokMetricsLoading} >
                {tiktokMetrics && <ReactTable
                    className="simple-table"
                    data={tableData}
                    columns={tiktokMetricsSettings.settingsForTable()}
                    showPagination={false}
                    defaultSortDesc={true}
                    defaultSorted={[{ id: "views", desc: true }]}
                    pageSize={(tiktokMetrics && tiktokMetrics.length ) ? tiktokMetrics.length : 10}                
                />}
    		</Box>
		</React.Fragment>;
	}
}

function mapStateToProps(state) {
	return {
		tracks: state.tracks,
		filter: state.filter,
	} 
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		getTiktokMetrics: (filtered, dateGroup) => {
			return dispatch(tracksActions.getTiktokMetrics(filtered, dateGroup));
		} ,
		getTiktokMetricsTimeseries: (filtered, dateGroup) => {
			return dispatch(tracksActions.getTiktokMetricsTimeseries(filtered, dateGroup));
		} 
	} 
}



export default connect(mapStateToProps, mapDispatchToProps)(TiktokMetrics);