import React, { Component } from 'react';
import { connect } from 'react-redux';
//import Datetime from '../components/pages/trackComparison/Datetime';
import TracksInProduct from '../components/pages/trackComparison/TracksInProduct'
class Test extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const props = {
			id: "656605165066",
			entity: "products",
			weeks: 1,
			territories: [],
			vendors: [],
			metadata: {
				release_date: "2023-09-15",
				name_compare: "Mitski - My Love Mine All Mine"
			}
		}
		return <div className="inner-page">
			<TracksInProduct {...props} />
		</div>
	}
}


export default Test