import React from 'react';
import { connect } from 'react-redux';
import { maxBy, isEqual } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';

import config from '../../../config/config';
import Box from '../../common/Box';
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { tracksActions } from '../../../data/actions/tracks';
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { territorySettings } from './Territory/Settings';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
const CellHeaderTable = cellHeaderHOC(ReactTable);

class TerritoriesStats extends React.Component {
    
    constructor(props) {
        super(props);
        this.exportToCsv = this.exportToCsv.bind(this);        
    }
    
    exportToCsv(){
        const { entity, id }  = this.props,
            tableData = this.props[entity].territoriesComparison[id];
        const title = ( entity == 'tracks' ? 'track' : 'product') + '-comparison-territories';
        const filename = CSV.CSVHeader(title, id);
        return CSV.CSVExport(tableData, {filename}, title);
    }

    renderToolbar(){
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
          <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)
        return toolbar;
    }



    componentDidMount() {
        const { id, weeks, metadata , territories, vendors} = this.props,
            releaseDate = metadata.release_date;
        this.props.compareTerritories(id, weeks, releaseDate, territories, vendors);
        
    }
    
    componentWillReceiveProps(nextProps) {
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    render (){
        const { entity, id, metadata }  = this.props,
            tableData = this.props[entity].territoriesComparison[id];
        
        const title = ( metadata && metadata.name_compare ) ? metadata.name_compare : id;
        return <Box title={title} spinnerEnabled={this.props[entity].territoriesComparisonLoading} exportToCsv={this.renderExportToCsv()}>
        
            {tableData && <div className="row">
                <div className={`col-xs-12`}>
                    {tableData.length > 0 && <div className="territory-table territory-table--single custom-scroll">
                        <CellHeaderTable
                            className="territory-table-holder playlist-table track-territory-table simple-table"
                            data={tableData}
                            columns={territorySettings.settingsForTable()}
                            defaultPageSize={10}
                            showPagination={tableData.length > 10}
                            noDataText='No results found. Please check your filters.'
                    /></div>}                    
                    {!tableData.length && <p>No data available</p>}
                </div>    
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        products: state.products,
        projects: state.projects,
        filter: state.filter,
        userData: state.user
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        compareTerritories: (id, weeks, releaseDate, territories, vendors) => {
            switch(ownProps.entity) {
                case 'products':
                    dispatch(productsActions.compareProductTerritories(id, weeks, releaseDate, territories, vendors));
                    break;
                case 'projects':
                    dispatch(projectsActions.compareProjectTerritories(id, weeks, releaseDate, territories, vendors));
                    break;                    
                default:
                    dispatch(tracksActions.compareTrackTerritories(id, weeks, releaseDate, territories, vendors));
                    break;

            
            }
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TerritoriesStats);