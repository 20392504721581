import React from 'react';
import ReactTooltip from 'react-tooltip';
import { map, capitalize } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import CountryFlag from '../../../common/CountryFlag';
import { percentageValue, splitByThousands } from '../../../../helpers/NumberFormatter';
import Tooltip from '../../../common/Tooltip';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../../common/Datagrid/Footers';

export const territorySettings = {
	settingsForTable
};


function settingsForTable(view){
	let columns = [           
	   {
		   id: 'rank',
		   className: 'rank',
		   headerClassName: 'rank',
		   Header: 'Rank',
		   width: 40,
		   accessor: 'rank',
		   Footer: spaceFooter,
	   },
				   
		{
			id: 'logo',
			className: 'logo-sell',
			headerClassName: 'logo-sell no-sort',
			Header: '',
			accessor: 'code',
			width: 40,
			sortable: false,
			Cell: props => {
				return <CountryFlag className="flag-medium" country={props.value} />
		 },
			Footer: spaceFooter 
	  },
	  {
		  id: 'name',
		  Header: 'Country',
		  accessor: 'country',
		  Footer: textFooter
	  },      
	  {
			  id: 'share',
			  headerClassName: '',
			  Header: 'Share',
			  accessor: 'share',
			  Cell: props => <span>{props.value}%</span>,
			  Footer: spaceFooter
		  },
	   {
		   id: 'curr_units',
		   headerClassName: 'total',
		   className: 'total',
		   Header: capitalize(view),
		   Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
		   accessor: 'curr_units',
		   Footer: convertSumFooter
	   },

	   {
		  id: 'prev_units',
		  headerClassName: 'total',
		  className: 'total',
		  Header: `${capitalize(view)} Change`,
		  Cell: props => <span>{splitByThousands(props.value)} ({props.original.units_diff}%)</span>,
		  accessor: 'units_change',
		  Footer: spaceFooter
	  }	   

	];
	return columns;
}