import { whiteLabelConstants } from '../constants/white_label';
import { whiteLabelService } from '../services/white_label';
import { whiteLabelFormatter } from '../formatters/white_label';
// import defaultValues from '../../components/pages/whitelabel/defaultValues';
const defaultValues = require('../../components/pages/whitelabel/defaultValues.json');

export const whiteLabelActions = {
    loadDomainConfig,    
    getWhiteLabelData,
    submitWhiteLabelData
};

function getWhiteLabelData(silo) {
    return dispatch => {
        dispatch(request());

        return whiteLabelService.getWhiteLabelData(silo)
            .then(
                whiteLabel => {
                    whiteLabel = whiteLabelFormatter.formatForForm(whiteLabel, defaultValues);
                    dispatch(success(whiteLabel));
                }
            )
            .catch(error => {
                dispatch(success(defaultValues));
            });
    };

    function request() { return { type: whiteLabelConstants.GET_WHITE_LABEL_REQUEST } }
    function success(whiteLabel) { return { type: whiteLabelConstants.GET_WHITE_LABEL_SUCCESS, whiteLabel } }
    function failure(error) { return { type: whiteLabelConstants.GET_WHITE_LABEL_FAILURE, error } }
}

function submitWhiteLabelData(silo, params, requestType, cb) {
    return dispatch => {
        whiteLabelService.submitWhiteLabelData(silo, params, requestType)
        .then(res => {
              if (cb && typeof cb === 'function') {
                  cb()
              }
              dispatch(success('ticket was successfully submitted'));
          }
        )
        .catch(error => {
            dispatch(failure('there was an error'));
        });
    };

    function request() { return { type: whiteLabelConstants.SUBMIT_WHITE_LABEL_REQUEST } }
    function success(message) { return { type: whiteLabelConstants.SUBMIT_WHITE_LABEL_SUCCESS, message, title: '' } }
    function failure(message) { return { type: whiteLabelConstants.SUBMIT_WHITE_LABEL_FAILURE, message, title: 'send ticket error' } }
}

function loadDomainConfig() {
    return dispatch => {
        dispatch(request());

        return whiteLabelService.loadDomainConfig()
            .then(
                config => {
                    dispatch(success(config));
                }
            )
            .catch(error => {
                dispatch(success({}));
            });
    };

    function request() { return { type: whiteLabelConstants.LOAD_DOMAIN_CONFIG_REQUEST } }
    function success(config) { return { type: whiteLabelConstants.LOAD_DOMAIN_CONFIG_SUCCESS, config } }
}
