import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { filterActions } from '../../data/actions/filter'
import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';
import TiktokTimeseries from '../../components/pages/result/TiktokTimeseries';
import TiktokMetrics from '../../components/pages/result/TiktokMetrics';
import TiktokTerritories from '../../components/pages/result/TiktokTerritories';


class ResultSocial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInitialized: false
        }
    }
    
    
    render() {
        const { filter, widgetProps, sharedMode } = this.props;
        
        return <div className="inner-page">
            {!sharedMode && <div className="widget-row">
                <HiddenElements widgetProps={widgetProps} />
            </div>}
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="tiktok_views_creates" widgetProps={widgetProps}>
                    <TiktokTimeseries filtered={filter} shadowChartProps={{id: 'audience_tiktok_line_chart', shadowChartWidth: 800, shadowChartHeight: 300}}/>
                </Widget>
            </div>
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="tiktok_territories" widgetProps={widgetProps}>
                    <TiktokTerritories filtered={filter} shadowChartProps={{id: 'audience_tiktok_territories_line_chart', shadowChartWidth: 800, shadowChartHeight: 300}}/>
                </Widget>
            </div>
            
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="tiktok_metrics" widgetProps={widgetProps}>
                    <TiktokMetrics filtered={filter} shadowChartProps={{id: 'audience_tiktok_pie_chart', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
        </div>
    }
}

export default WithWidgetManager(ResultSocial, 'result_social')