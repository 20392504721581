export const spotifyMonetizationConstants = {
	GET_SPOTIFY_MONETIZATION_REQUEST: 'GET_SPOTIFY_MONETIZATION_REQUEST',
	GET_SPOTIFY_MONETIZATION_SUCCESS: 'GET_SPOTIFY_MONETIZATION_SUCCESS',
	GET_SPOTIFY_MONETIZATION_FAILURE: 'GET_SPOTIFY_MONETIZATION_FAILURE',
	GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST: 'GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST',
	GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS: 'GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS',
	GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE: 'GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE',
	GET_SPOTIFY_MONETIZATION_TIMESERIES_REQUEST: 'GET_SPOTIFY_MONETIZATION_TIMESERIES_REQUEST',
	GET_SPOTIFY_MONETIZATION_TIMESERIES_SUCCESS: 'GET_SPOTIFY_MONETIZATION_TIMESERIES_SUCCESS',
	GET_SPOTIFY_MONETIZATION_TIMESERIES_FAILURE: 'GET_SPOTIFY_MONETIZATION_TIMESERIES_FAILURE',
	GET_SPOTIFY_MONETIZATION_DATES_REQUEST: 'GET_SPOTIFY_MONETIZATION_DATES_REQUEST',
	GET_SPOTIFY_MONETIZATION_DATES_SUCCESS: 'GET_SPOTIFY_MONETIZATION_DATES_SUCCESS',
	GET_SPOTIFY_MONETIZATION_DATES_FAILURE: 'GET_SPOTIFY_MONETIZATION_DATES_FAILURE',
}