import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { isEqual } from 'lodash';

import CSV from '../../../helpers/CSVExport';


import Box from '../Box';
import { statsActions } from '../../../data/actions/stats';
import PieChart from '../../widgets/charts/types/PieChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import FillLineChart from '../../widgets/charts/types/FillLineChart';

import { devicesSettings } from './Devices/Settings';

var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');

import VendorLogo from "../VendorLogo";
import { dspLogos } from "../../common/Stats/utils";
import ModeSelect from '../ModeSelect';


class DevicesStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          mode: props.defaultMode || 'table',
          defaultSorted: [{ id: "curr_units", desc: false }],
          logoDataFiltered: [],
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }

    setChartMode(mode){
        this.setState({mode});
        const { modeChange } = this.props;
        if (mode == "line" || mode == "bar")
            this.getTimeseries();
        
        if(typeof modeChange == 'function')
            modeChange(mode);
        /*
        if (mode == "line" || mode == "bar")
            this.getTimeseries();
        */
    }

    exportToCsv(){
        const filename = CSV.CSVHeader('details_devices', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.stats.devices.table, {filename});
    }
    
    componentDidMount() {
        const { chartType } = this.props;
        const { mode } = this.state;
        this.props.dispatch(statsActions.getDevicesStats(this.props.entity, this.props.ids, this.props.filtered))
        if (mode == "line" || mode == "bar")
            this.getTimeseries(false);

        if (this.props.filter.global) {
          if (this.props.filter.global !== undefined) {
            this.setState({
              logoDataFiltered: chartType
                ? dspLogos[chartType].data.filter((l) =>
                    this.props.filter.global.vendors.includes(l)
                  )
                : [],
            });
          }
        }
    }
    
    
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(statsActions.getDevicesStats(this.props.entity, this.props.ids, this.props.filtered));
                const { mode } = this.state;
                if (mode == "line" || mode == "bar")
                    this.getTimeseries(false);
                
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    mode: 'table',
                    logoDataFiltered: chartType
                      ? dspLogos[chartType].data.filter((l) =>
                          globalFilter.vendors.includes(l)
                        )
                      : [],
                  };
                });
            }                
        }
    }
    
    getTimeseries(cache = true) {
        this.props.dispatch(statsActions.getDevicesTimeseries(this.props.entity, this.props.ids, cache, this.props.filtered));
    }

    
    renderToolbar(modes){
        let toolbar = [];

        let options = [
            {icon: pieSvg, value: 'pie', label: "Donut Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: barSvg, value: 'bar', label: "Bar Chart"}
        ];

        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
            <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
        </div>
        </div>)
        return toolbar;
    }  

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
    
    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }
    
    
    
    render (){
        const stats = this.props.stats;
        const rows = (stats.devices && stats.devices.table) ? stats.devices.table.length : 0;
        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        return <Box title="Devices" toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.devicesLoading} exportToCsv={this.renderExportToCsv()}>
            {this.props.stats.devices && <div>
                {this.state.mode=='pie' && <div className="chart-block">
                    <DoughnutChart data={this.props.stats.devices.chart} {...shadowChartProps} />                          
                </div>}
                {this.state.mode=='table' && <div className="devices-table territory-table--single custom-scroll">
                    <ReactTable
                        className="device-table simple-table"
                        data={stats.devices.table}
                        columns={devicesSettings.settingsForTable('details')}
                        defaultPageSize={(stats.devices.table && stats.devices.table.length ) ? Math.min(stats.devices.table.length, 10) : 10}
                        showPagination={rows > 10}
                        defaultSorted={this.state.defaultSorted}
                        getTrProps={devicesSettings.settingsForRow}
                    />
                </div>}
                {this.state.mode=='line' && <div className="chart-block">
                    <FillLineChart data={this.props.stats.devicesTimeseries} {...shadowChartProps}  />                          
                </div>}
                {this.state.mode=='bar' && <div className="chart-block">
                    <StackedBarChart data={this.props.stats.devicesTimeseries} {...shadowChartProps}  />                          
                </div>}
                
            </div>}
        </Box>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(DevicesStats);