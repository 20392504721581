import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { map } from 'lodash';
import ReactTable from 'react-table';
import { Route } from 'react-router';
import Spinner from '../../components/common/Spinner';
import Details from './Details';

import selectTableHOC from "react-table/lib/hoc/selectTable";
import { searchActions } from '../../data/actions/search';
import { searchSettings } from '../../components/pages/search/Settings';

var deleteSvg = require('!svg-inline-loader!../../../public/img/icons/delete.svg');
const SelectTable = selectTableHOC(ReactTable);

class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'all',
            selectedRows: []
        }
        this.deleteFilter = this.deleteFilter.bind(this);
        this.toggleSelection = this.toggleSelection.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.allSelected = this.allSelected.bind(this);
    }
    
    
    
    componentDidMount() {
        this.props.dispatch(searchActions.getSearchResults());
    }
        
    toggleSelection(id){
        let selectedRows = Object.assign([], this.state.selectedRows);
        if(selectedRows.includes(id))
            selectedRows = selectedRows.filter(item=>item!=id);
        else
            selectedRows.push(id);
        this.setState({selectedRows});
    }
    
    toggleAll(rows){
        let selectedRows = map(rows, row=>row.id);
        if(this.allSelected(rows))
            selectedRows = [];
        this.setState({selectedRows});
    }
    
    allSelected(rows){
        const currentlySelectedRows = this.state.selectedRows;
        return (currentlySelectedRows.length > 0 && currentlySelectedRows.length == rows.length);
    }
    
    updateFilterField(id, field, value) {
        this.props.dispatch(userEntityFiltersActions.updateUserEntityFilter(id, {[field]:value}))
//        .then(this.props.dispatch(userEntityFiltersActions.getUserEntityFilters()));
    }
    
    deleteFilter(ids) {
        Promise.all(ids.map(id=>this.props.dispatch(userEntityFiltersActions.destroyFilter(id))))
        .then(this.props.dispatch(userEntityFiltersActions.getUserEntityFilters()));
    }

    shareAllFilters(allShared) {
        const filters = this.props.userEntityFilters.filters;
        if (!filters) 
            return
        
        const ids = map(filters, 'id');
        const sharePromises = map(ids, (id)=>this.props.dispatch(userEntityFiltersActions.updateUserEntityFilter(id, {'is_shared': allShared})));
        Promise.all(sharePromises)
        .then(() => this.props.dispatch(userEntityFiltersActions.getUserEntityFilters()));
    }

    renderShareAll() {
        return null;
        
        const filters = this.props.userEntityFilters.filters;
        if (!filters) 
            return null;
        
        
        let allShared = true;
        for(let filter of filters) {
            if(!filter.is_shared) {
                allShared = false;
                break;
            }        
        }
        
        let buttonClass;
        
        if (allShared) {
            buttonClass = 'shared';
        } else {
            buttonClass = '';
        }
            
        
        return <div className="small-title-holder small-title-holder--right">
            <h3 className="title share-label">Share All</h3>
            <button title="Share All" onClick={()=>this.shareAllFilters(!allShared)} className={`btn share-all-milestones ${buttonClass}`}></button>
        </div>
    }
    
    render() {
        const { searchesLoading, searches = [] } = this.props.search,
            { selectedRows } = this.state,
            rows = searches.length;
        
        return <div className="inner-page shared-pages">
            <div className="ibox">
                <h2 className="saved-filter-title">Search Results</h2>
                
                <div className="milestone-action-btns filters-actions-btn">
                    <div className="buttons-holder">
                        <Link to="/user/entities-filter/new" className="default-btn default-btn--small create-new-filter">Add New</Link>
                        {selectedRows.length > 0 && <button className="default-btn default-btn--small default-btn--light delete-selected" onClick={()=>this.deleteFilter(selectedRows)}>Delete Selected</button>}
                    </div>
                    {this.renderShareAll()}
                </div>
                <SelectTable
                    data={searches}
                    loading={searchesLoading}
                    columns={searchSettings.settingsForTable(this.deleteFilter, (id, value)=>this.updateFilterField(id, 'is_pinned', value), (id, value)=>this.updateFilterField(id, 'is_shared', value))}
                    pageSize={rows ? Math.min(rows, 10): 10}
                    showPagination={rows > 10}       
                    selectType="checkbox"
                    keyField="id"
                    toggleSelection={(key, shift, row)=>{this.toggleSelection(row.id)}}
                    toggleAll={()=>this.toggleAll(searches)}
                    isSelected={(key)=>{return selectedRows.includes(key)}}
                    className="simple-table filter-table"
                    selectAll={this.allSelected(searches)}
                defaultSorted={[{ id: "created_at", desc: true }]}
                />
                
                <Route path="/search-results/:id" render={(props)=><Details {...props} />}></Route>

            </div>
            <Helmet>
                <title>Search Results</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        search: state.search
    } 
}

export default connect(mapStateToProps)(SearchResults)