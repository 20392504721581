import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import ReactTable from "react-table";
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import { tracksActions } from '../../../data/actions/tracks';
import { playlistTracksSettings } from './PlaylistTracks/Settings'
const CellHeaderTable = cellHeaderHOC(ReactTable);
import Box from '../../common/Box';
import ReactSelect from '../../common/ReactSelect';
import EntityTimeline from '../../pages/home/EntityTimeline';
import CSV from '../../../helpers/CSVExport';
import ModeSelect from '../../common/ModeSelect';
import { Link } from 'react-router-dom';

var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var openPlaylistSvg = require('!svg-inline-loader!../../../../public/img/open_playlist.svg');
var overviewSvg = require('!svg-inline-loader!../../../../public/img/overview.svg');
var detailsSvg = require('!svg-inline-loader!../../../../public/img/details.svg');



class PlaylistTracks extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            view: 'table'
        }
        this.renderTable = this.renderTable.bind(this);
		this.renderExpanded = this.renderExpanded.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.getTimeseries = this.getTimeseries.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setChartView = this.setChartView.bind(this);
    }
    
    componentDidMount(){
        const { playlist, parentEntity, parentEntityID, filtered } = this.props;
        this.props.dispatch(tracksActions.getTopTracksInPlaylist(playlist.id, parentEntity, parentEntityID, false, filtered));
    }
    
    getTimeseries(){
        const { playlist: {id: playlistID} } = this.props,
            { data = [] } = this.props.tracks.topPlaylist[playlistID] || [];
            
        if(data && data.length) {
            const ids = map(data, 'track_id').filter(id=>id);
            let filtered = [{id:'playlists', value: playlistID}, {id:'ids', value:ids}];
            this.props.dispatch(tracksActions.getTimeseriesTracksInPlaylist({sorted: 'curr_units', filtered}), playlistID);
        }
    }
    
    setChartView(view){
        this.setState({view});
    }
    
    exportToCsv(){
        const { playlist: {id: playlistID } } = this.props,
        { data = []} = this.props.tracks.topPlaylist[playlistID] || [];
        const filename = CSV.CSVHeader('top_tracks_in_playlist', 'curr_units', this.props.filter.global); 
        return CSV.CSVExport(data, {filename}, 'top_tracks_in_playlist');
    }

    renderToolbar() {
        let options = [
            {icon: tableSvg, value: 'table', label: "Table"},
            {icon: lineSvg, value: 'line', label: "Line Chart"},
            {icon: barSvg, value: 'bar', label: "Bar Chart"},
        ];
        return <div key="wrapper" className="ibox-action-wrapper">
            <div className="ibox-icon-holder mode-select">
                <ModeSelect options={options} selected={this.state.view} onChange={this.setChartView} isSearchable={ false } />
            </div>
        </div>
    }
    
    renderTable (mode) {
        const { playlist } = this.props,
		tableShowPosition = playlist.playlist_type != 'Algorithmic', 
        { data = [], loading } = this.props.tracks.topPlaylist[playlist.id] || [];
    
        return <CellHeaderTable 
            HOCTableClass={"expanded-table-container"}
            resizable={false}
            loading={loading}
            pageSize={data.length} 
            showPagination={false} 
            data={data} 
            className={"top-tracks-in-playlist simple-table catalogue-table-holder"} 
            columns={playlistTracksSettings.settingsForTable(mode, tableShowPosition)}
            noDataText='No results found. Please check your filters.'
        />
        
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }
	
	renderLinks(playlist) {
		const {id, playlist_type, external_url} = playlist;
		return <div className="overlay-links-holder overlay-links-holder-inner">
			{external_url && <a href={external_url} target="_blank">
			Open Playlist
			<span className="chart-icon" dangerouslySetInnerHTML={{__html: openPlaylistSvg}}></span></a>}
		  {playlist_type != 'Algorithmic' && <Link to={`/playlists/${id}/overview`}>
			Overview
			<span className="chart-icon" dangerouslySetInnerHTML={{__html: overviewSvg}}></span></Link>}
		  <Link to={`/playlists/${id}/details`}>
			Details
			<span className="chart-icon" dangerouslySetInnerHTML={{__html: detailsSvg}}></span></Link>
		  <a onClick={this.exportToCsv}>
			Export CSV
			<span className="chart-icon" dangerouslySetInnerHTML={{__html: downloadSvg}}></span></a>
			
		  </div>
	}
    
    renderDetails (tableShowPosition) {
        const { playlist: {id: playlistID}, filter } = this.props,
            { data = [], loading } = this.props.tracks.topPlaylist[playlistID] || [],
            { topPlaylistTimeseries: timeseries, topPlaylistTimeseriesLoading} = this.props.tracks;

        let topChartClass = ''
        if (data) {
            if (data.length > 20 && data.length <= 100) {
                topChartClass = 'top-chart-md'    
            } else if (data.length > 100 ) {
                topChartClass = 'top-chart-lg'    
            }
        }            
        return <Box title="Top Tracks in Playlist" toolbar={this.renderToolbar()} spinnerEnabled={topPlaylistTimeseriesLoading} exportToCsv={this.renderExportToCsv()}>
            {this.state.view == 'table' && this.renderTable('details')}
            {this.state.view == 'line' && <div className={`timeline-holder ${topChartClass}`}><EntityTimeline mode="line" entity="tracks_in_playlist" results={timeseries} forceLegendPosition="bottom" filter={filter} getTimeseries={this.getTimeseries} /></div>}
            {this.state.view == 'bar' && <div className={`timeline-holder ${topChartClass}`}><EntityTimeline mode="bar"  entity="tracks_in_playlist" results={timeseries} forceLegendPosition="bottom" getTimeseries={this.getTimeseries} /></div>}            
        </Box>
    }
	
	renderExpanded (tableShowPosition) {
		const { playlist, filter } = this.props,
			{ data = [], loading } = this.props.tracks.topPlaylist[playlist.id] || [];
           
		return <Box mobileClassName="tracks-in-playlist-title-holder" title="Top Tracks in Playlist" spinnerEnabled={false} toolbar={this.renderLinks(playlist)}>
			{this.renderTable('details')}
		</Box>
	}
    
    render (){
        const { mode } = this.props;
        return mode == 'expanded' ? this.renderExpanded() : this.renderDetails();
    }
    
    
}

function mapStateToProps(state) {
    return {
        tracks: state.tracks,
        filter: state.filter,
    }
}


export default connect(mapStateToProps)(PlaylistTracks);