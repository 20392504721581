import React from 'react';
import ReactDOM from 'react-dom';
import toastr from 'toastr';
import {merge} from 'lodash';

const defaultOptions = {
    closeButton: true,
    progressBar: true,
    showMethod: 'slideDown',
    timeOut: 4000    
};

class Toastr extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        const { method, title, message, options = {} } = this.props;
        toastr.options = merge(defaultOptions, options);
        toastr[method](message, title);
    }

    componentWillUnmount(){
    }

    componentDidUpdate(prevProps) {
    }    
    render() {
      return null;
    }
  }

  export default Toastr;