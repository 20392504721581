import React from 'react';
import { Link } from 'react-router-dom';
import OverviewCard from './OverviewCard';

class OverviewWidget extends React.Component {
    render (){
        const { entity } = this.props,
            route = window.location.pathname; // todo: refactor to react-router if possible 
        
        return <Link to={entity.route} className="entity-link">
          <div className={`widget overview-widget white-bg p-lg text-center ${route === entity.route ? 'active' : ''}`}>
            <OverviewCard active={route === entity.route} entity={entity} /> 
          </div>
        </Link>
    }
}

export default OverviewWidget;