import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, map, get, every } from 'lodash';
import Box from '../../common/Box';
import Spinner from '../../common/Spinner';

class AutoDownload extends React.Component {
	readyFlags = {
		'overview': [
			'stats.topLoading',
			'stats.multiStreamsLoading',
			'stats.territoryLoading',
			'stats.vendorLoading',
			'artists.topLoading',
			'products.topLoading',
			'tracks.topLoading'
		],
		'audience': [
			'stats.topLoading',
			'audience.loading',
			'audience.repeatListenersLoading',
			'audience.territoryLoading',
			'audience.demographicsLoading',
			'stats.sourcesStatsLoading',
			'stats.sourcesDiscoveryStatsLoading',
			'stats.devicesLoading'
		],
		'social': [
			'stats.topLoading',
			'tracks.tiktokMetricsLoading',
			'tracks.tiktokTerritoriesLoading',
			'tracks.tiktokTimeseriesLoading'
		],
		'playlists': [
			'stats.topLoading',
			'playlists.loading'
		],
		'profile': [
			'stats.topLoading',
			'milestones.entities.mixed.loading'
		],
		

	}
	
	constructor(props) {
		super(props);
		this.state = {
			ready: false
		}
	}
	
	tabExists(tab) {
		return this.readyFlags.hasOwnProperty(tab);
	}

	componentDidUpdate(prevProps){
		if(!this.tabExists(this.props.tab))
			return;
		
		if(this.props.tab !== prevProps.tab) {
			this.setState({ready: false})
		}
		if(!this.state.ready) {
			const flags = this.readyFlags[this.props.tab].map(flag=>get(this.props, flag));
			if(every(flags, flag=>flag === false)) {
				this.setState({ready: true})
			}
		}
	}
	
	render (){  
		const { tab } = this.props;
		const { ready } = this.state;
		if(!this.tabExists(tab))
			return null;

		
		return ready ? <div className="download-report-holder"><button type="button"  class="default-btn default-btn--small" onClick={this.props.exportToCsv}>Download Report</button></div> : <div className="download-loading">
			<Spinner enabled={true} />
			<div>Please wait a few moments to download the report</div>
		</div>
	}
}

function mapStateToProps(state) {
	return {
		stats: state.stats,
		artists: state.artists,
		products: state.products,
		tracks: state.tracks,
		audience: state.audience,
		playlists: state.playlists,
		milestones: state.milestones
	} 
}


export default connect(mapStateToProps)(AutoDownload);