import React from 'react';

const lockedSvg = require('!svg-inline-loader!../../../../public/img/locked.svg');
const unlockedSvg = require('!svg-inline-loader!../../../../public/img/unlocked.svg');
const resetSvg = require('!svg-inline-loader!../../../../public/img/reset.svg');

class HiddenElements extends React.Component {

    renderHiddenElements(elements, showChange){
        if(!elements || !elements.showChangeEnabled)
            return null;
        let hiddenElements = [];
        for(let key of Object.keys(elements)) {
            const element = elements[key];
            
            if(element.hasOwnProperty('show') && !element.show) {
                hiddenElements.push({key, title: element.title});
            }
        }
        if(!hiddenElements.length)
            return <p>No hidden elements</p>;
        return <div className="manage-elements-list">
            <div className="toolbox-holder"><span>Removed elements (click to add back):</span></div>
            <div>
                {hiddenElements.map(element=><div className="toolbox-element"><a onClick={()=>showChange(element.key, true)}>{element.title}</a></div>)}
            </div>
        </div> 
    }
    
    render (){
        const { widgetState, showChange, toggleShowChange, resetSettings } = this.props.widgetProps;
        const lockSvg = widgetState.showChangeEnabled ? unlockedSvg : lockedSvg;

        return <div className="manage-elements">
            <div className="manage-elements-actions">
                <span className="grid-management-holder">
                    <a className="locked-holder" onClick={toggleShowChange}>
                        <span>Manage Elements</span>
                        <span className="locked-link" dangerouslySetInnerHTML={{__html: lockSvg}} />
                    </a>
                </span>
                {widgetState.showChangeEnabled && <span className="grid-management-holder">
                    <a className="locked-holder" onClick={resetSettings}>Reset<span dangerouslySetInnerHTML={{__html: resetSvg}} className="locked-link" /></a>
                </span>}
            </div>
            {this.renderHiddenElements(widgetState, showChange)}
        </div>
        

    }
}

export default HiddenElements;