import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, get } from 'lodash';
import CSV from '../../../helpers/CSVExport';
import Box from '../../common/Box';
import LineChart from '../../widgets/charts/types/LineChart';
import VendorLogo from "../../common/VendorLogo";
import Tooltip from '../../common/Tooltip';
import EntitySelector from '../../pages/result/EntitySelector';
import DateGroupSelector from '../../pages/result/DateGroupSelector';
import ContentTypeSelector from '../../pages/result/ContentTypeSelector';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import { audienceActions } from '../../../data/actions/audience';


class Streams extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            entityFilter: null,
            dateGroup: null,
            contentType: 0
        }
        this.setEntityFilter = this.setEntityFilter.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);
        this.setContentType = this.setContentType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
    }
        
    componentDidMount() {
        this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter, this.state.dateGroup);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter, this.state.dateGroup);
            }                
        }
        if(nextProps.id && !isEqual(nextProps.id, this.props.id)) {
            this.props.getStreamsStats(nextProps.id, nextProps.entity, this.state.entityFilter, this.state.dateGroup);
        } 
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter, this.state.dateGroup));
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>this.props.getStreamsStats(this.props.id, this.props.entity, this.state.entityFilter, this.state.dateGroup));
    }
    
    setContentType(contentType) {
        this.setState({contentType});
    }
    
    exportToCsv(){
        const filename = CSV.CSVHeader('audience_listeners', 'listeners', this.props.filter.global); 
        return CSV.CSVExport(this.props.audience.streams_listeners, {filename}, 'audience_listeners');
    }

    
    // renderToolbar(modes){
    //     let toolbar = [];
    //     toolbar.push(<div key="wrapper" className="ibox-action-holder">
        
    //     </div>)
    //     return toolbar;
    // }


    renderIcon() {
        return <div className="title-vendors-list title-vendors-list--single">
            <VendorLogo name={'spotify'} />
        </div>
    }

    renderExportToCsv() {
        return (
            <a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        )
    }

    renderTooltip() {
        let tooltip = [];
        tooltip.push(<React.Fragment key="tooltip">
            {
                <div className="table-header-tooltip">
                    <Tooltip 
                        position="bottom" 
                        message={`Listener data provided by Amazon, Deezer, Pandora, Soundcloud, Spotify, and iTunes only`} 
                        tooltipClass="toolbar-title-tooltip"
                    />
                </div>
            }
        </React.Fragment>
        )

        return tooltip;
    }
    
    renderChartActions(contentTypes){
        const { mode } = this.state;
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} entityFilterMode="array" />
            <DateGroupSelector dateGroupChange={this.setDateGroup}/>
            <ContentTypeSelector contentTypes={contentTypes} contentTypeChange={this.setContentType}/>
        </React.Fragment>
    }
    
    render (){  
        const { entity, id, shadowChartProps = {shadowChartWidth: 800, shadowChartHeight: 400, id: 'audience_streams_listeners' }, milestones } = this.props,
            {timeline, loading} = this.props.audience,
            widgetTitle = `Listeners`;
        let milestonesItems = get(milestones, 'entities.mixed.tableItems', []);
        
        let { contentType } = this.state;
        let filteredTimeline = {};
        if(timeline && timeline.hasOwnProperty('datasets')) {
            filteredTimeline.labels = timeline.labels;
            filteredTimeline.datasets =  timeline.datasets.filter(timelineItem => timelineItem.contentType == contentType )
        }
        if (shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        return <Box title={widgetTitle} chartActions={this.renderChartActions(timeline?timeline.contentTypes:[])} spinnerEnabled={loading} vendorLogoList={this.renderIcon()} exportToCsv={this.renderExportToCsv()} loadingTooltip={this.renderTooltip()} hasTooltip={true} >
            {timeline && 
                <div className="streams-audience-chart">
                    <div className="chart-block">
                        {/* <LineChart data={timeline} shadowChart={true} {...shadowChartProps} /> */}
                        <LineChartWithAnnotations milestones={milestonesItems} data={filteredTimeline} period={this.state.date} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} label="streams" shadowChartProps={shadowChartProps} />
                    </div>
                    {/*...this.props.children*/}
                </div>
            }
        </Box>;
    }
}

function mapStateToProps(state) {
    return {
        audience: state.audience,
        filter: state.filter,
        milestones: state.milestones
    } 
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getStreamsStats: (id, entity, entityFilter, dateGroup) => {
            return dispatch(audienceActions.getStreamsStats(id, entity, entityFilter ? entityFilter : ownProps.filter, dateGroup))
        } 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(Streams);