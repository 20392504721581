import { sharedPagesConstants } from '../constants/shared_pages';

export function sharedPages(state = {}, action) {
    switch (action.type) {
        case sharedPagesConstants.SHARE_REQUEST:
            return {
                ...state,
                loading: true,
                page: undefined
            };
        case sharedPagesConstants.SHARE_SUCCESS:
            return {
                ...state,
                loading: false,
                page: action.page
            };
        case sharedPagesConstants.SHARE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
    
        case sharedPagesConstants.GET_SHARED_PAGES_REQUEST:
            return {
                ...state,
                loading: true,
                pages: undefined
            };
        case sharedPagesConstants.GET_SHARED_PAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                pages: action.pages
            };
        case sharedPagesConstants.GET_SHARED_PAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case sharedPagesConstants.DELETE_SHARED_PAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case sharedPagesConstants.DELETE_SHARED_PAGES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case sharedPagesConstants.DELETE_SHARED_PAGES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state
    }
} 