import { playlistsConstants } from '../constants/playlists';
import { playlistsService } from '../services/playlists';
import { tracksService } from '../services/tracks';
import { playlistsFormatter } from '../formatters/playlists';
import { searchFormatter } from '../formatters/search';
import { find, map, concat, chunk, flatten } from 'lodash';

export const playlistsActions = {
    getPlaylists,
    getTopPlaylists,
    getPlaylistTimeline,
    getPlaylistDetails,
    getPlaylistStats,
    getMetadata,
    getTimeseriesPlaylists,
    getPlaylistShare,
    getPlaylistTypesSources,
	getPlaylistTypesSourcesDiscovery,
    getPlaylistBenchmarks,
    getPlaylistsAutocomplete,
    getPlaylistsAutocompleteBatch
};

function getPlaylists(params) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        const fields = ['playlist_id', 'vendor', 'owner', 'playlist_name', 'image', 'external_url', 'followers', 'avg_track_streams_per_day', 'source', 'playlist_type', 'total_tracks',
            'curr_tracks', 'prev_units', 'curr_units', 'curr_skipped', 'curr_completed', 'skipped_ratio', 'completed_ratio', 'curr_male', 'curr_female', 'curr_unknown', 'total_entities' 
        ];
        Promise.all([
            playlistsService.getPlaylists(params, globalFilter, 'main', fields),
            // playlistsService.getPlaylists(params, globalFilter, 'total')
        ])
            .then(
                res => {
                    let playlists = res[0];
                        // total = res[1];
                    
                    const { data, total } = playlistsFormatter.formatForTable(playlists, globalFilter);
                    dispatch(success(data, total));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLISTS_REQUEST } }
    function success(playlists, total) { return { type: playlistsConstants.GET_PLAYLISTS_SUCCESS, playlists, total } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLISTS_FAILURE, error } }
}


function getTopPlaylists(params, entity=null, filter) {
    return ( dispatch, getState ) => {
        dispatch(request(entity));
        const globalFilter = getState().filter.global;
        const fields = ['playlist_id', 'playlist_name', 'vendor', 'owner', 'curr_tracks', 'total_tracks', 'curr_units', 'prev_units', 'units_diff', 'passive', 'active', 'skipped_audio_ratio', 'completed_audio_ratio', 'skipped_video_ratio', 'completed_video_ratio'];
        if(filter) {
            let filtered = [];
            for(let entity of Object.keys(filter)) {
                filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
            params.filtered = filtered;
        }
        playlistsService.getTopPlaylists(params, globalFilter, fields)
            .then(
                playlists => {
                    const data = playlistsFormatter.formatTop(playlists);
                    //dispatch(success(data, entity));
                    return dispatch(getMetadata(data));
                },
                error => {
                    dispatch(failure('error', entity))
                }
            );
    };

    function request(entity) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_REQUEST, entity } }
    function success(playlists, entity) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_SUCCESS, playlists, entity } }
    function failure(error, entity) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_FAILURE, error, entity } }
}


function getPlaylistTimeline(id) {
    return ( dispatch, getState ) => {
        dispatch(request());

        const playlists = getState().playlists.top,
            playlist = find(playlists, {playlist_id: id});
        return Promise.resolve(playlist)

            .then(
                playlist => {
                    const data = playlistsFormatter.formatTimeline(playlist.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_TIMELINE_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_TIMELINE_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_TIMELINE_FAILURE, error } }
}

function getTracks(ids, playlistID, filter) {
    return tracksService.getTopTracks({filtered:[{
        id: 'isrcs',
        value: ids
    }, {
        id: 'playlists',
        value: String(playlistID)
    }]}, filter);
}

function getPlaylistDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistStats(id, globalFilter)
            .then(
                playlist => {
                    const chunkSize = 50,
                        ids = chunk(concat(map(playlist.current_tracks, 'isrc'), map(playlist.removed_tracks, 'isrc')), chunkSize),
                        filter = {...globalFilter, limit: playlist.current_tracks.length};
                    
                    Promise.all(ids.map(chunk=>getTracks(chunk, id, filter)))
                    .then(allTracks => {
                        const { data, benchmarks } = playlistsFormatter.formatDetails(playlist, flatten(allTracks));                        
                        dispatch(success(id, data, benchmarks));    
                    })
                    
                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_DETAILS_REQUEST } }
    function success(id, playlist, benchmarks) { return { type: playlistsConstants.GET_PLAYLIST_DETAILS_SUCCESS, id, playlist, benchmarks } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_DETAILS_FAILURE, error } }
}


function getPlaylistStats(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistStats(id, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatStats(playlist);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_STATS_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_STATS_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_STATS_FAILURE, error } }
}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        playlistsService.getMetadata(ids)
            .then(
                metadata => {
                    entities = playlistsFormatter.formatMetadata(entities, metadata.playlists);
                    dispatch(success(entities, metadata.playlists));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_TOP_PLAYLISTS_REQUEST } }
    function success(playlists, metadata) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_SUCCESS, playlists, metadata } }
    function failure(error) { return { type: playlistsConstants.GET_TOP_PLAYLISTS_FAILURE, error } }

}

function getTimeseriesPlaylists(params, useCache, filter) {
    return ( dispatch, getState ) => {
        if(getState().playlists.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        let ids = [];
        for(let param of params.filtered) {
            if(param.id=='ids') {
                ids = param.value;
            }    
        }
        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }
        
        return Promise.all([
                playlistsService.getTimeseriesPlaylists(params, globalFilter),
                playlistsService.getMetadata(ids)
            ])
            .then(
                results => {
                    const playlists = results[0]
                    const metadata = results[1].playlists;
                    
                    const data = playlistsFormatter.formatStreamsStats(playlists, metadata);
                    dispatch(success(data));
                    return playlists;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_TIMESERIES_PLAYLISTS_REQUEST } }
    function success(playlists) { return { type: playlistsConstants.GET_TIMESERIES_PLAYLISTS_SUCCESS, playlists } }
    function failure(error) { return { type: playlistsConstants.GET_TIMESERIES_PLAYLISTS_FAILURE, error } }
}

function getPlaylistShare(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistShare(id, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatShare(playlist);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_SHARE_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_SHARE_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_SHARE_FAILURE, error } }
}


function getPlaylistTypesSources(entity, ids, useCache, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        if(!filtered)
            filtered = {[entity]: ids};

        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistTypesSources({filtered}, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatTypesSources(playlist);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_REQUEST } }
    function success(stats) { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_SUCCESS, stats } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_FAILURE, error } }
}

function getPlaylistTypesSourcesDiscovery(entity, ids, useCache, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        if(!filtered)
            filtered = {[entity]: ids};
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistTypesSourcesDiscovery({filtered}, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatTypesSources(playlist);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_REQUEST } }
    function success(stats) { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_SUCCESS, stats } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_FAILURE, error } }
}

function getPlaylistBenchmarks(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        playlistsService.getPlaylistBenchmarks(id, globalFilter)
            .then(
                playlist => {
                    const data = playlistsFormatter.formatBenchmarks(playlist);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLIST_BENCHMARKS_REQUEST } }
    function success(id, playlist) { return { type: playlistsConstants.GET_PLAYLIST_BENCHMARKS_SUCCESS, id, playlist } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLIST_BENCHMARKS_FAILURE, error } }
}

function getPlaylistsAutocomplete(query) {
    return ( dispatch, getState ) => {
        dispatch(request());
		const currentUser = getState().user.user;
        return playlistsService.getPlaylistsAutocomplete(query, currentUser)
            .then(
                playlists => {
                    const data = playlistsFormatter.formatAutocomplete(playlists);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_REQUEST } }
    function success(playlists) { return { type: playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS, playlists } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_FAILURE, error } }
}

function getPlaylistsAutocompleteBatch(query) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        const queryArray = searchFormatter.formatPlaylistBatchSearch(query);
        const chunkSize = 50;
        
        let promises = [];
        for(let i = 0; i < queryArray.length; i+= chunkSize) {
            const chunk = queryArray.slice(i, i + chunkSize);
            promises.push(()=>playlistsService.getPlaylistsAutocompleteBatch(chunk.join('\n'), currentUser));
        }
        
        const serial = promises.reduce((promise, func) =>
            promise.then(result => func().then(Array.prototype.concat.bind(result))), Promise.resolve([]))
        
        return serial
            .then(
                playlists => {
                    playlists = flatten(playlists);
                    const data = playlistsFormatter.formatAutocompleteBatch(playlists);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_REQUEST } }
    function success(playlists) { return { type: playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS, playlists } }
    function failure(error) { return { type: playlistsConstants.GET_PLAYLISTS_AUTOCOMPLETE_FAILURE, error } }
}