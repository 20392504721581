import React from 'react';
import { connect } from 'react-redux';
import AddChannelForm from '../../forms/addChannel'
import { channelsActions } from '../../../data/actions/channels'

class Channels extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showOpen: false
        }
        this.addChannel = this.addChannel.bind(this);
        this.showModal = this.showModal.bind(this);
    }
    
    showModal(show){
        this.setState({
            showModal: show
        })
    }
    
    addChannel(formData) {
        const { url } = formData,
            artistID = this.props.artist.id;
        if(url) {
            this.props.dispatch(channelsActions.addChannel(artistID, url));
            this.showModal(false);
        }
    }
    
    
    render (){
        const { artist, channels: {channel} } = this.props;
        
        return <div className="socials-list">
            {artist.artists_channels && artist.artists_channels.filter(item=>item.show_in_profile).map(channel=><span key={channel.remote_channel_id} title={channel.channel_type} className="logo"><i className={`social-icon ${channel.channel_type.toLowerCase()}`} /></span>)}
            {channel && <span key={channel.channel_id} title={channel.channel_type_name} className="logo"><i className={`social-icon ${channel.channel_type_name.toLowerCase()}`} /></span>}
            <a key="add" title="Add Channel" onClick={()=>this.showModal(true)} className="logo"><i className={``} />+</a>)
            <AddChannelForm show={this.state.showModal} onSubmit={this.addChannel} onClose={(e)=>this.showModal(false)} />            
        </div>
    }
}

function mapStateToProps(state) {
    return {
        channels: state.channels
    } 
}

export default connect(mapStateToProps)(Channels);

