import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Modal from '../../common/Modal';
import SharePageForm from '../../forms/sharePage';
import { sharedPagesActions } from '../../../data/actions/shared_pages';
import SharedPageLink from './SharedPageLink';

const shareSvg = require('!svg-inline-loader!../../../../public/img/icons/share.svg');

class SharePage extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            showShareResultModal: false,
            showNewShareModal: false,
            slug: ''
        }
        this.share = this.share.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }
    
    share(formData) {
        const { title, expires } = formData,
            { entity, id, module, params } = this.props;
        
        const expiresAt = moment().add(expires ? 1 : 120, 'month').format('YYYY-MM-DD');
        this.props.dispatch(sharedPagesActions.share(entity, id, module, params, title, expiresAt))
        .then(result => this.setState({
            showNewShareModal: false,
            showShareResultModal: true,
            slug: result.slug
        }));
    }
    
    toggleModal(modal, opened){
        this.setState({[modal]: opened});
    }
    
    render() {
        const { sharedPages:{ page, loading} , exportToCsv, exportToPdf, exportInProgress = false } = this.props;
        return <div className="share-main-holder">
            <Modal
                show={this.state.showShareResultModal}
                title="Share Page"
                handleClose={()=>this.toggleModal('showShareResultModal', false)}
                showActionButtons={false}
            >
                <p>Link to share: <SharedPageLink slug={this.state.slug} /></p>
            </Modal>
            
            <SharePageForm
                show={this.state.showNewShareModal} 
                title="Share Page" 
                onSubmit={this.share} 
                values={{title:'', expires: true}}
                exportToPdf={exportToPdf}
                exportToCsv={exportToCsv}
                exportInProgress={exportInProgress}
                onClose={(e)=>this.toggleModal('showNewShareModal', false)} 
            />
            <button type="button" onClick={()=>this.toggleModal('showNewShareModal', true)} className="default-btn default-btn--small button-icon default-btn--light">
                share
                <span className="chart-icon chart-icon--small" dangerouslySetInnerHTML={{__html: shareSvg}}></span>
            </button>
        </div>

    }
}


function mapStateToProps(state) {
    return {
        sharedPages: state.sharedPages,
    }
}


export default connect(mapStateToProps)(SharePage);