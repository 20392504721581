import store from '../../helpers/store'
import { orderBy, isEqual, cloneDeep } from 'lodash';
import { filterConstants } from '../constants/filter';
import { filterService } from '../services/filter';
import { filterFormatter } from '../formatters/filter';

export const filterActions = {
    setGlobalFilter,
    setEntityFilter,
    setNewEntityFilter,
	setSharedMode,
    toggleGlobalFilter,
    getFilters,
    createFilter,
    removeFilter,
    updateFilter,
    getDataLogs,
    appendEntityFilter,
    undoEntityFilter
};

function updateFilter(id, filter){
  return dispatch => {
    dispatch(request());

    filterService.updateFilter(id, filter)
    .then(response => {
      dispatch(getFilters());
      dispatch(success(response.status))
    })
    .catch( error => dispatch(failure(error.message)));
  }

  function request(){ return { type: filterConstants.UPDATE_FILTER_REQUEST }};
  function success(payload){ return { type: filterConstants.UPDATE_FILTER_SUCCESS, payload }};
  function failure(payload){ return { type: filterConstants.UPDATE_FILTER_ERROR, payload }};
}

function getFilters(cache=false){
  return dispatch => {
    dispatch(request());

    return filterService.getFilters()
    .then( response => {
      const data = orderBy(response.data, ['filter_name', 'asc']);
      dispatch(success(data));
      return data;
    })
    .catch( error => {
      dispatch(failure(error.message));
    });
  }
  function request(){ return { type: filterConstants.GET_FILTERS_REQUEST }};
  function success(payload){ return { type: filterConstants.GET_FILTERS_SUCCESS, payload }};
  function failure(payload){ return { type: filterConstants.GET_FILTERS_FAILURE, payload }};
}

function createFilter(filter, filterName){
  return dispatch => {
    dispatch(request());

    return filterService.createFilter(filter, filterName)
    .then( response => {
      const { id } = response.data;  
      dispatch(setGlobalFilter(filter, id));
      dispatch(getFilters());
      dispatch(success(response.data));
      return id;
    })
    .catch( error => {
      dispatch(failure(error.message));
    });
  }

  function request(){ return { type: filterConstants.CREATE_FILTER_REQUEST }};
  function success(payload){ return { type: filterConstants.CREATE_FILTER_SUCCESS, payload }};
  function failure(error){ return { type: filterConstants.CREATE_FILTER_FAILURE, error }};
}

function removeFilter(id){
  return dispatch => {
    dispatch(request());

    filterService.removeFilter(id)
    .then( response => {
      dispatch(getFilters());
      dispatch(success(response.status));
      store.set('currentFilterID', null);
    })
    .catch( error => {
      dispatch(failure(error.message));
    });

    function request(){ return { type: filterConstants.REMOVE_FILTER_REQUEST} };
    function success(payload){ return { type: filterConstants.REMOVE_FILTER_SUCCESS, payload } };
    function failure(payload){ return { type: filterConstants.REMOVE_FILTER_ERROR, payload } };
  }
}


function shouldRebuildTable(newFilter, oldFilter){
    return false;
    if(oldFilter==[])
        return true;

    if(isEqual(newFilter, oldFilter))
        return false;

    return (oldFilter.dateStart!=newFilter.dateStart || oldFilter.dateEnd!=newFilter.dateEnd || oldFilter.prevDateStart!=newFilter.prevDateStart || oldFilter.prevDateEnd!=newFilter.prevDateEnd);
}

function setGlobalFilter(filter, id) {

    return ( dispatch, getState ) => {
        const globalFilter = getState().filter.global;
        dispatch(set(filter));
        store.set('currentFilter', filter);
        if(id)
            store.set('currentFilterID', id);
        
        const refreshTable = shouldRebuildTable(filter, globalFilter),
            filterThenable =  refreshTable ? filterService.buildTable(filter) : Promise.resolve();
        dispatch(request(refreshTable));
        
        return filterThenable.then(
            data => {
                
                dispatch(success(refreshTable));
                return filter;
            },
            error => {
                dispatch(failure('error'))
            }
        );
    };

    function request(refresh) { return { type: filterConstants.BUILD_TABLE_REQUEST, refresh } }
    function success(refresh) { return { type: filterConstants.BUILD_TABLE_SUCCESS, refresh } }
    function set(filter) { return { type: filterConstants.SET_ENTITY_FILTER, entity: 'global', filter } }
    function failure(error) { return { type: filterConstants.BUILD_TABLE_FAILURE, error } }

}

function setEntityFilter(entity, filter) {
    return dispatch => {
        filter = filterFormatter.formatEntityFilter(filter, entity);
        dispatch(set(entity, filter));
        return filter;
    };

    function set(entity, filter) { return { type: filterConstants.SET_ENTITY_FILTER, entity, filter } }
}

function setNewEntityFilter(filter, initialize = true) {
    return dispatch => {
      console.log(filter);
        filter = filterFormatter.formatNewEntityFilter(filter);
        console.log(filter);
        dispatch(set(filter, initialize));
        return true;
    };

    function set(filter, initialize) { return { type: filterConstants.SET_NEW_ENTITY_FILTER, filter, initialize } }    
}

function toggleGlobalFilter() {
    return dispatch => {
        dispatch(set());
    };

    function set() { return { type: filterConstants.TOGGLE_GLOBAL_FILTER} }
}

function setSharedMode() {
	return dispatch => {
		dispatch(set());
	};

	function set() { return { type: filterConstants.SET_SHARED_MODE} }
}

function getDataLogs(all = false, forceDateStart, forceDateEnd){
    return ( dispatch, getState ) => {
      dispatch(request());
      let globalFilter = cloneDeep(getState().filter.global),
          currentUser = getState().user.user;          
      if(forceDateStart)
          globalFilter.prevDateStart = forceDateStart;
      if(forceDateEnd)
          globalFilter.dateEnd = forceDateEnd;
      
      const params = filterFormatter.formatDataLogs(globalFilter, currentUser, all);
      filterService.getDataLogs(params)
      .then( response => {
          let data, dates = [], messages = {};
          if(all) {
             let result = filterFormatter.formatFullDataLogs(response, globalFilter);
             data = result.data;
             dates = result.dates;
             messages = result.messages;
          }
          else {
             data = filterFormatter.formatDataLogsResults(response, currentUser, globalFilter);
          }
          dispatch(success(data, dates, all, messages));
      })
//      .catch( error => {
//        dispatch(failure(error.message));
//      });
    }
    function request(){ return { type: filterConstants.GET_DATA_LOGS_REQUEST }};
    function success(payload, dates, all, messages){ return { type: filterConstants.GET_DATA_LOGS_SUCCESS, payload, dates, all, messages }};
    function failure(payload, dates, all){ return { type: filterConstants.GET_DATA_LOGS_FAILURE, payload, dates, all }};
  }

function appendEntityFilter(entityFilter){

    return ( dispatch, getState ) => {
        let globalFilter = getState().filter.global;
        for(let entity of ['imprints', 'artists', 'products', 'projects', 'tracks']) {
            globalFilter[entity] = entityFilter[entity] || null;
        }
        dispatch(set(globalFilter));
        store.set('currentFilter', globalFilter);
        dispatch(request(false));
        
        return Promise.resolve().then(
            data => {
                dispatch(success(false));
                return globalFilter;
            },
            error => {
                dispatch(failure('error'))
            }
        );
    };

    function request(refresh) { return { type: filterConstants.BUILD_TABLE_REQUEST, refresh } }
    function success(refresh) { return { type: filterConstants.BUILD_TABLE_SUCCESS, refresh } }
    function set(filter) { return { type: filterConstants.SET_ENTITY_FILTER, entity: 'global', filter } }
    function failure(error) { return { type: filterConstants.BUILD_TABLE_FAILURE, error } }

}


function undoEntityFilter(){
    const globalFilter = getState().filter.global;
    for(let entity of ['imprints', 'artists', 'products', 'projects', 'tracks']) {
            globalFilter[entity] = null;
    }
    return setGlobalFilter(globalFilter);
}
