//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import config from '../../config/config';

export const sharedPagesService = {
    share,
    getSharedPages,
    deleteSharedPage
};

const source = 'enterlytics';

function share(entity, entity_id, module, params, title, expires_at, user_data, filter_data) {
  const requestOptions = {
      method: 'POST',
      headers: AuthHeader({'Content-Type': 'application/json'}),
      body: JSON.stringify({entity, entity_id, module, params, title, expires_at, user_data, filter_data, source})
  };
  let baseUrl = `${config.apis.orthus}/v1/shared_pages`;
  return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSharedPages() {
  const requestOptions = {
      method: 'GET',
      headers: AuthHeader({'Content-Type': 'application/json'})
  };
    
  let baseUrl = `${config.apis.orthus}/v1/shared_pages/my?source=${source}`;
  return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function deleteSharedPage(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };
      
    let baseUrl = `${config.apis.orthus}/v1/shared_pages/${id}`;
    return fetch(baseUrl, requestOptions).then(res=>res.json());
}