export const playlistsConstants = {
    GET_PLAYLISTS_REQUEST: 'GET_PLAYLISTS_REQUEST',
    GET_PLAYLISTS_SUCCESS: 'GET_PLAYLISTS_SUCCESS',
    GET_PLAYLISTS_FAILURE: 'GET_PLAYLISTS_FAILURE',
    GET_PLAYLISTS_AUTOCOMPLETE_REQUEST: 'GET_PLAYLISTS_AUTOCOMPLETE_REQUEST',
    GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS: 'GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS',
    GET_PLAYLISTS_AUTOCOMPLETE_FAILURE: 'GET_PLAYLISTS_AUTOCOMPLETE_FAILURE',
    GET_TOP_PLAYLISTS_REQUEST: 'GET_TOP_PLAYLISTS_REQUEST',
    GET_TOP_PLAYLISTS_SUCCESS: 'GET_TOP_PLAYLISTS_SUCCESS',
    GET_TOP_PLAYLISTS_FAILURE: 'GET_TOP_PLAYLISTS_FAILURE',
    GET_PLAYLIST_TIMELINE_REQUEST: 'GET_PLAYLIST_TIMELINE_REQUEST',
    GET_PLAYLIST_TIMELINE_SUCCESS: 'GET_PLAYLIST_TIMELINE_SUCCESS',
    GET_PLAYLIST_TIMELINE_FAILURE: 'GET_PLAYLIST_TIMELINE_FAILURE',    
    GET_PLAYLIST_DETAILS_REQUEST: 'GET_PLAYLIST_DETAILS_REQUEST',
    GET_PLAYLIST_DETAILS_SUCCESS: 'GET_PLAYLIST_DETAILS_SUCCESS',
    GET_PLAYLIST_DETAILS_FAILURE: 'GET_PLAYLIST_DETAILS_FAILURE',    
    GET_PLAYLIST_STATS_REQUEST: 'GET_PLAYLIST_STATS_REQUEST',
    GET_PLAYLIST_STATS_SUCCESS: 'GET_PLAYLIST_STATS_SUCCESS',
    GET_PLAYLIST_STATS_FAILURE: 'GET_PLAYLIST_STATS_FAILURE',
    GET_TIMESERIES_PLAYLISTS_REQUEST: 'GET_TIMESERIES_PLAYLISTS_REQUEST',
    GET_TIMESERIES_PLAYLISTS_SUCCESS: 'GET_TIMESERIES_PLAYLISTS_SUCCESS',
    GET_TIMESERIES_PLAYLISTS_FAILURE: 'GET_TIMESERIES_PLAYLISTS_FAILURE',   
    GET_PLAYLIST_SHARE_REQUEST: 'GET_PLAYLIST_SHARE_REQUEST',
    GET_PLAYLIST_SHARE_SUCCESS: 'GET_PLAYLIST_SHARE_SUCCESS',
    GET_PLAYLIST_SHARE_FAILURE: 'GET_PLAYLIST_SHARE_FAILURE',
    GET_PLAYLIST_TYPES_SOURCES_REQUEST: 'GET_PLAYLIST_TYPES_SOURCES_REQUEST',
    GET_PLAYLIST_TYPES_SOURCES_SUCCESS: 'GET_PLAYLIST_TYPES_SOURCES_SUCCESS',
    GET_PLAYLIST_TYPES_SOURCES_FAILURE: 'GET_PLAYLIST_TYPES_SOURCES_FAILURE',
    GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_REQUEST: 'GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_REQUEST',
    GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_SUCCESS: 'GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_SUCCESS',
    GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_FAILURE: 'GET_PLAYLIST_TYPES_SOURCES_DISCOVERY_FAILURE',
    GET_PLAYLIST_BENCHMARKS_REQUEST: 'GET_PLAYLIST_BENCHMARKS_REQUEST',
    GET_PLAYLIST_BENCHMARKS_SUCCESS: 'GET_PLAYLIST_BENCHMARKS_SUCCESS',
    GET_PLAYLIST_BENCHMARKS_FAILURE: 'GET_PLAYLIST_BENCHMARKS_FAILURE',
    GET_PLAYLISTS_AUTOCOMPLETE_REQUEST: 'GET_PLAYLISTS_AUTOCOMPLETE_REQUEST',
    GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS: 'GET_PLAYLISTS_AUTOCOMPLETE_SUCCESS',
    GET_PLAYLISTS_AUTOCOMPLETE_FAILURE: 'GET_PLAYLISTS_AUTOCOMPLETE_FAILURE',
}