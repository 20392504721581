import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});

export default StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: "Roboto",
  },
  section: {
    marginBottom: 20,
    flexDirection: 'row'
  },
  
  reportTitle: {
      marginBottom: 5,
      flexDirection: 'row'
  },
  
  columnSection: {
    marginBottom: 20,
    width: '100%',
  },
  columnReportTitle: {
    marginBottom: 5,
  },      
  
  column: {
    flexDirection: 'column',
    width: '100%'
  },   
  columnWithMargin: {
    flexDirection: 'column',
    width: '100%',
    marginBottom: "10px"
  },
  half: {
    padding: 5,
    width: '49%',
  },
  header: {
    fontSize: 16,
    marginBottom: 16,
    fontFamily: "Roboto",
  },
  smallHeader: {
    fontSize: 12,
    marginBottom: 12,
    paddingBottom: 6,
    borderBottom: '1px dashed #D2D1D4',
    fontFamily: "Roboto",
  },
  smallHeaderNotUnderline: {
    fontSize: 12,
    marginBottom: 12,
    paddingBottom: 6,
    fontFamily: "Roboto",
  },
  image: {
    marginBottom: "10px"
  },
  complexTitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'row',
    alignItems: "flex-start",
    paddingTop: "20px",
  },
  reportTitle: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "flex-start",
  },  
  dateRangeTitle: {
    fontSize: 10,
    marginBottom: 10,
  },
  dateMissingText: {
    fontSize: 10,
    marginBottom: "10px"
  },
  trackInProductItem: {
    width: "20%"
  },
  trackInProductSmallTitle: {
    fontSize: 8,
    marginBottom: 5,
    padding: 10
  },
  trackInProductLegend: {
    fontSize: 7,
    marginBottom: 10,
    padding: 10,
    textAlign: 'center',
  }
});