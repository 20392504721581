import React from 'react';
import { connect } from 'react-redux';

import Spinner from '../../common/Spinner';

import { filterActions } from '../../../data/actions/filter';
import LoadingMessage from '../../../helpers/LoadingMessage';

class UserTablesLoader extends React.Component {
    render (){
        const {filter: {tableBuilt, tableLoading}, children, route} = this.props;
        const ready = true; // (tableBuilt || route == '/' || route == '/main');
        if(ready) {
            return children;
        }   
        else {
            return <p>
                {tableLoading ? <span className="user-tables-loader big-title">
                    {LoadingMessage('temp_table')} <br />
                        <Spinner enabled={true} />
            		</span> 
                : LoadingMessage('temp_table_error')}
            </p>;
        }
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserTablesLoader)