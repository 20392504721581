import React, { Component } from 'react';
const heatmap = require('!svg-inline-loader!../../../public/img/heatmap.svg');

const Switcher = props => {
  const { onShowHeatMap, onShowDefaultMap, showHandler, show, showDefaultMap, showHeatMap } = props;
  return (
    <div className="switcher-container">
      <div onClick={showHandler} className="switcher-layout"><i className="fas fa-map-marked-alt fa-3x"></i> </div>
      {
        show && (
          <div className="switcher-items-holder">
            <div onClick={onShowDefaultMap} className={showDefaultMap ? "pin-switcher active" : "pin-switcher" }><i className="fas fa-map-marker-alt fa-2x"></i> </div>
            <div onClick={onShowHeatMap} className={showHeatMap ? "heatmap-switcher active" : "heatmap-switcher"} dangerouslySetInnerHTML={{__html: heatmap}} ></div>
          </div>
        )
      }
    </div>
  );
}

export default Switcher;
