export const userConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    AVATAR_UPLOAD_REQUEST: 'AVATAR_UPLOAD_REQUEST',
    AVATAR_UPLOAD_SUCCESS: 'AVATAR_UPLOAD_SUCCESS',
    AVATAR_UPLOAD_FAILURE: 'AVATAR_UPLOAD_FAILURE',
    SET_HOME_PAGE_REQUEST: 'SET_HOME_PAGE_REQUEST',
    SET_HOME_PAGE_SUCCESS: 'SET_HOME_PAGE_SUCCESS',
}