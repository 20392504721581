import { whiteLabelConstants } from '../constants/white_label';

export function whiteLabel(state = {config: undefined, configLoading: false}, action) {
    switch (action.type) {
        case whiteLabelConstants.GET_WHITE_LABEL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case whiteLabelConstants.GET_WHITE_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.whiteLabel,
            };
        case whiteLabelConstants.GET_WHITE_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case whiteLabelConstants.SUBMIT_WHITE_LABEL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case whiteLabelConstants.SUBMIT_WHITE_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.whiteLabel,
            };
        case whiteLabelConstants.SUBMIT_WHITE_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case whiteLabelConstants.LOAD_DOMAIN_CONFIG_REQUEST:
            return {
                ...state,
                configLoading: true
            };
        case whiteLabelConstants.LOAD_DOMAIN_CONFIG_SUCCESS:
            return {
                ...state,
                configLoading: false,
                config: action.config,
            };
            
        default:
            return state
    }
} 