import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent, round } from '../../../../helpers/NumberFormatter';

export default ({data})=>{
    const columns=[
       {key: 'rank', title: 'Rank', width: 10, fontSize: 5},
       {key: 'name', title: 'Country', width: 40, fontSize: 5},
       {key: 'share', title: 'Share', width: 10, fontSize: 5, formatFn: addPercent},
       {key: 'curr_units', title: 'Units', width: 12, fontSize: 5, formatFn: splitByThousands},
       {key: 'per_cap_units', title: 'Per Capita', width: 12, fontSize: 5, formatFn: round},
       {key: 'curr_playlists', title: 'Playlists', width: 10, fontSize: 5, formatFn: splitByThousands},
       {key: 'skipped_ratio', title: 'Skip Rate', width: 10, fontSize: 5, formatFn: addPercent},
       {key: 'completed_ratio', title: 'Completion Rate', width: 10, fontSize: 5, formatFn: addPercent}
   ];
       
    return <Table
        columns={columns}
        items={data}
    />
}