import React from 'react';
import { find, map, cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SearchWidget from '../../pages/home/SearchWidget';
import EntitiesList from '../../widgets/entityFilter/EntitiesList';
import { sessionStore } from '../../../helpers/SessionStore'

class MultiSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			entities: {
				imprints:[],
				artists:[],
				projects:[],
				products:[],
				tracks:[]
			}
		}
		this.searchResult = this.searchResult.bind(this);
		this.addAllResults = this.addAllResults.bind(this);
		this.goToDetails = this.goToDetails.bind(this);
		this.removeFromFilter = this.removeFromFilter.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
	}
	
	componentDidMount() {
		const currentFilter = sessionStore.get('userEntityFilter') || null;
		if(currentFilter && currentFilter.id === null) {
			console.log(currentFilter);
			let entities = [];
			for(let entity of currentFilter.entities) {
				if(!entities.hasOwnProperty(entity.entity_type))
					entities[entity.entity_type] = [];
				entities[entity.entity_type].push(entity);
			}
			this.setState({entities});
		}
	}
	
	searchResult(item, include) {
		const { entity, id } = item;
		/*
		if(include === undefined) {
			const filter = {[entity]: [id]}
			this.props.history.push(`/filter/${JSON.stringify(filter)}`);
			return;
		}
		*/
		
		let entities = this.state.entities[entity];
		if(include === true) {
			const newFilterEntity = {
				entity_id: id,
				entity_type: entity,
				entity_title: item.name_raw,
				entity_image: item.image,
				fav_hide: 'favorite'
			};
			
			if(!find(entities, existingEntity=>(existingEntity.entity_id == newFilterEntity.entity_id && existingEntity.entity_type == newFilterEntity.entity_type)))
				entities.push(newFilterEntity);
		}
		else {
			entities = entities.filter(existingEntity=>(!(existingEntity.entity_id == id && existingEntity.entity_type == entity)));
		}
		
		this.setState({
			...this.state,
			entities:{
				...this.state.entities,
				[entity]: [...entities]
			}
		})
	}
	
	removeFromFilter(type, id) {
		let entities = this.state.entities[type];
		entities = entities.filter(entity => entity.entity_id != id);
		this.setState({
			...this.state,
			entities:{
				...this.state.entities,
				[type]: [...entities]
			}
		})        
	}
	
	goToDetails(entities) {
		//const { entities } = this.state;
		let filter = [];
		for(let entityType of Object.keys(entities)){
			filter = filter.concat(...entities[entityType]);
		}
		this.props.applyFilter(filter);
	}
	
	addAllResults(items) {
		let entities = cloneDeep(this.state.entities);
		for(let entityType of Object.keys(this.state.entities)) {
			// entities[entityType] = [];
			for(let item of items[entityType]) {
				
				if(find(entities[entityType], entity=>(entity.entity_id == item.id)))
					continue;
				
				const newFilterEntity = {
					entity_id: item.id,
					entity_type: item.entity,
					entity_title: item.name_raw,
					entity_image: item.image,
					fav_hide: 'favorite'
				};
				entities[entityType].push(newFilterEntity);
			}
		}
		this.setState({entities});
	}
	
	resetFilter(){
		let entities = cloneDeep(this.state.entities);
		for(let entity of Object.keys(entities))
			entities[entity] = [];
		this.setState({
			entities
		}, ()=>{
			this.props.resetFilter();	
		})
	}

	
	renderDetailsButton(entities) {
		const total = Object.keys(entities).reduce((sum, key)=>(sum+=entities[key].length), 0);
		return total ? <div className="search-filter-btn-holder"><button className="default-btn default-btn--small" onClick={()=>this.goToDetails(entities)}>Apply Filter</button></div>:null;
	}
	
	renderFilterEntities(entities) {
		return <div className="pinned-filters-holder filter-page">
			<div className="pinned-filters-item">
				<div className="pinned-entity-list">
					{Object.keys(entities).map(type=><EntitiesList entities={entities[type]} type={type} fav_hide="favorite" onDelete={(id)=>this.removeFromFilter(type, id)} />)}
				</div>
			</div>
		</div>
	}
	render() {
		const { entities } = this.state;
		const { className } = this.props;
		let currentlySelectedItems = [];
		for(let entity of Object.keys(entities)) {
			currentlySelectedItems = currentlySelectedItems.concat(entities[entity]);
		}
		
		return <div className={`multisearch-container ${className}`}>
			<div className="filter-search-with-buttons">
				<SearchWidget currentlySelectedItems={currentlySelectedItems} searchResult={this.searchResult} addAllResults={this.addAllResults} addExactMatches={true} batch={true} searchDropdownMode="single" searchItemMode="include_exclude" placeholder="Search all..." />  
				<div className='buttons-holder buttons-holder--flex filter-action-buttons-holder'>
					{this.renderDetailsButton(this.state.entities)}
					{currentlySelectedItems.length > 0 && 
					<React.Fragment>
						<Link className="default-btn default-btn--small default-btn--light button" to={{pathname:"/user/entities-filter/new", state:{defaultEntities: currentlySelectedItems}}}>Save Filter</Link>
						<button onClick={()=>this.resetFilter()} className="button-no-style">Clear</button></React.Fragment>}
				</div>      
			</div>
			{this.renderFilterEntities(this.state.entities)}	
		</div>
	}
}

export default withRouter(MultiSearch)
