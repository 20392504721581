import React, { useState, useEffect } from 'react';
import { convertToThousands, round } from '../../../helpers/NumberFormatter';
import PercentageValue from '../../common/PercentageValue';

var tileToggleSvg = require('!svg-inline-loader!../../../../public/img/icons/tile-toggle.svg');

export default function OverviewCard(props) {
    const { active, entity, overviewPage } = props;
    // const percentageEl = entity.percentageValue && <PercentageValue onlyPercentage={true} item={entity} field="units" /> 
    const percentageEl = entity.percentageValue + "%" 
    const numberEl = convertToThousands(entity.absoluteValue);
    
    const [number, setNumber] = useState(numberEl)
    const [percentage, setPercentage] = useState(percentageEl)

    const swapValues = (e) => {
        e.stopPropagation();

        setNumber(percentage);
        setPercentage(number);
    }

    useEffect(() => {
        setNumber(numberEl);
        setPercentage(percentageEl);
    }, [numberEl, percentageEl])

    const percentageClass =  entity.percentageValue ? (entity.percentageValue > 0 ? 'up' : 'down') : '';
    const renderPercentage = (value) => (value > 1000 ? '> 1000' : String(value)) + '%'; 
    
    return <React.Fragment>
        <div className={`overview-card ${active ? 'active' : ''} ${overviewPage ? 'overview-card-item-holder' : ''}`}>
            { overviewPage ? 
                <div className="overview-card-item-inner">
                    {/*entity.percentageValue && number ? <div className="tiles-head-holder">
                        <span className="toggle-icon" dangerouslySetInnerHTML={{__html: tileToggleSvg}} onClick = {swapValues}></span>
                    </div> : <span className="toggle-icon"></span>*/}
                    {/* <span className={`tiles-rounded-icon tiles-rounded-icon--${entity.entity.toLowerCase().replace(' ', '_')}`} dangerouslySetInnerHTML={{__html: entity.icon}}></span> */}
                    <div className="tiles-main-info-holder">
                        <div className="title-totals-holder">
                            <h2 className={`title-num ${percentageClass}`}>{number}</h2>
                            {entity.percentageValue ? <div className={`overview-percentage-value ${percentageClass}`}>{renderPercentage(entity.percentageValue)}</div> : <div className={`overview-percentage-value`}></div> }
                        </div>
                        <h3 className="title-totals">{entity.title}</h3>
                    </div>
                </div> : 
                <div className="tiles-main-info-holder">
                    <h3 className="title-totals">{entity.title}</h3>
                    <div className="title-totals-holder">
                        <h2 className={`title-num ${percentageClass}`}>{number}</h2>
                        {entity.percentageValue ? <div className={`overview-percentage-value ${percentageClass}`}>{renderPercentage(entity.percentageValue)}</div> : null }
                    </div>
                </div>
            }
        </div>
    </React.Fragment>
}