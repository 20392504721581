import React from 'react';
import Tour from '../../common/Tour';

class ProfileTour extends React.Component {
    render(){
        const steps = [
           {
               selector: '.milestone-holder',
               content: "The Profile page allows you to manage project milestones."
           }
        ];
        
        return <Tour tourRef={this.props.tourRef} steps={steps} tourID="profile_tour" />
    }
}

export default ProfileTour;