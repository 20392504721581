import React from 'react';
import copyToClipboard from '../../helpers/copyToClipboard';

var copySvg = require('!svg-inline-loader!../../../public/img/copy-icon.svg');


class CopyToClipboard extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            justCopied: false
        }
        
        this.setCopied = this.setCopied.bind(this);
    }

    setCopied(justCopied) {
        this.setState({justCopied});
        if(justCopied)
            setTimeout(()=>this.setCopied(false), 2000);
    }
    
    render() {
        const { children } = this.props;

        return <span className="copy-to-clipboard-holder">
            {children}
            <div className="copy-holder" onClick={()=>{copyToClipboard(this.props.value); this.setCopied(true); }}>
                {!this.state.justCopied && <div className="" dangerouslySetInnerHTML={{__html: copySvg}} />}
                {this.state.justCopied && <span className="copied-text">Copied!</span>}
            </div>
        </span>
    }
}

export default CopyToClipboard;