import React from 'react';
import ReactTable from "react-table";
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import { monthlyStatsSettings } from './MonthlyStats/Settings'
const CellHeaderTable = cellHeaderHOC(ReactTable);

import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');


class MonthlyStats extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			view: 'table'
		};
		this.exportToCsv = this.exportToCsv.bind(this);
	}
	
	componentDidMount(){
	}
	
	exportToCsv(){
		const { data, isrc } = this.props;
		const filename = CSV.CSVHeader('MonthlyDetails', isrc);
		return CSV.CSVExport(data, {filename});
	}

	renderToolbar(){
		let toolbar = [];
		toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
			<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		</div>)
		return toolbar;
	}

	render () {
		const { data } = this.props;
		return <Box title="Monthly Details" toolbar={this.renderToolbar()} >
			<CellHeaderTable 
				resizable={false}
				defaultPageSize={data ? data.length:10}
				data={data} 
				className={"new-releases-track top-tracks-in-playlist simple-table"} 
				columns={monthlyStatsSettings.settingsForTable()}
				noDataText='No results found. Please check your filters.'
				showPagination={false}
			/>
		</Box>
	}
	
}

export default MonthlyStats;