import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import ReactSelect from '../../common/ReactSelect';
import { statsActions } from '../../../data/actions/stats';
import contentTypes from '../../widgets/filter/enums/content_types.json'

class ContentTypeSelector extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedContentType: 0
        }
        this.setContentType = this.setContentType.bind(this);
    }
    

    setContentType({value}) {
        this.setState({selectedContentType: value});
        const { contentTypeChange } = this.props;
        if(typeof contentTypeChange == 'function') { 
            contentTypeChange(value);
        }
    }
    
    render (){
        let { selectedContentType } = this.state;
        const { contentTypes: availableContentTypes } = this.props;
        
        let filteredContentTypes = contentTypes.filter(type=>availableContentTypes.includes(type.value));
        filteredContentTypes.unshift({value: 0, label: 'All'})
        const selected = find(filteredContentTypes, type=>type.value == selectedContentType);
        
        
        
        return <div className="date-group-selector-holder"><ReactSelect value={selected} options={filteredContentTypes} onChange={this.setContentType} className="single-select " /></div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(ContentTypeSelector);