import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    legend: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px'
    },
    legendItem: {
        fontSize: 8,
        marginRight: '10px'
    },
    legendBox: {
        display: 'flex',
        width: '10px',
        height: '10px',
        marginRight: '5px'
    },
    legendInner: {
        display: 'flex',
        flexDirection: 'row'
    }
});

export default function ChartLegend(props) {
    const { labels, colors } = props;
    
    
    return <View style={styles.legend}>
        {labels.map((label, index)=><View style={styles.legendInner}><View style={{backgroundColor: colors[index], ...styles.legendBox}} /><Text style={styles.legendItem} key={label}>{label}</Text></View>)}
    </View>
}