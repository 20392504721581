import React from 'react';
import { pull, uniq, map } from 'lodash';
import FieldsSelectorHelper from './FieldsSelectorHelper';

class FieldSelector extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            settings: this.props.settings,
            current: this.props.current, 
            initial: Object.assign({}, this.props.current)
        };
        this.changeFields = this.changeFields.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    changeFields(e) {
      let {name, value} = e.target,
        currentFields = this.state.current.columns.slice(0),
        allFields = this.state.settings,
        settingFields = this.props.settings;

      const isCatalog = e.target.closest(".catalog-field-selector");

      const totalField = settingFields.find(field => field.id == "overview");
      let fieldsToChange = [value];

      for(let field of allFields){
          if(field.sourceGroup && field.sourceGroup == value) {
              fieldsToChange.push(field.id);
          } else if (field.sourceGroup == undefined && value == "overview" && !totalField.isTotalExpanded && !field.required && isCatalog) {
            fieldsToChange.push(field.id);
          }
      } 
          
      if(currentFields.includes(value)) {
        for(let fieldToRemove of fieldsToChange)
          pull(currentFields, fieldToRemove);
      } 
          
      else {
        for(let fieldToAdd of fieldsToChange)
          currentFields.push(fieldToAdd);
          
      }

      this.setState({
          current: {
              ...this.state.current,
              [name]: currentFields
          }
      });
    }
    
    resetFilter(e){
        const allColumns = map(this.state.settings, 'id');
        

        this.setState({
            current: {
                ...this.state.initial,
               columns: allColumns
            }
        })
    }
    
    applyFilter(e){
        const filter = this.state.current;
        if(!filter.columns || !filter.columns.length)
            alert('Please select at least one field');
        else
            this.props.onUpdate(filter);
    }
    
    render() {
        const fields = FieldsSelectorHelper.pickFields(this.state.settings);
        let groups = uniq(map(this.state.settings, (field)=>field.sourceGroup));
        const extraClassName = this.props.extraClassName || ""
        return (
        <div  className={extraClassName}>
            <div className={`manage-fields-header`}>
              <div className="links-holder">
                <a className="reset" onClick={this.resetFilter}>
                  Select All
                </a>
                <a className="apply" onClick={this.applyFilter}>
                  Apply
                </a>
              </div>
            </div>
            <div className="manage-fields-selection">
            {groups.map((group) => {
              const groupClassName = group === undefined ? 'main-group' : 'vendor-group'
            return <div className={`group-container ${groupClassName}`}>
              {fields.map((item) => {
                if (item.expander == true) return null;
                if (item.required == true) return null;
                if (item.sourceGroup != group) return null;

                return (
                  <div key={item.id} className="manage-fields-item">
                    <div className="input-holder">
                      <input
                        type="checkbox"
                        name="columns"
                        value={item.id}
                        onChange={this.changeFields}
                        checked={this.state.current.columns.includes(item.id)}
                        id={item.id}
                        className="input-checkbox visually-hidden"
                      />
                      <label className="default-checkbox" htmlFor={item.id}>
                        {item.headerAlt || item.Header}
                      </label>
                    </div>
                  </div>
                );
              })}
            
              </div>
            })}
            </div>
          </div>
        );
    }
}

export default FieldSelector