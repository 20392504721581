//import $ from 'jquery';
import AuthHeader from '../../helpers/AuthHeader';
import Param from '../../helpers/Param';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';
import {searchService} from './search';

export const artistsService = {
    getArtists,
    getTopArtists,
    getArtistDetails,
    getArtistsOld,
    getArtistsAutocomplete,
    getCard,
    getStreamsStats,
    getArtistProfile,
    getFollowers,
    getProducts,
    getMetadata,
    getTimeseriesArtists,
    getArtistsSocial
};

function getArtists(params, globalFilter, basic){
    return config.live ? getArtistsNew(params, globalFilter, basic) : getArtistsStatic(basic);
}


function getArtistsOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };
    let baseUrl = `${config.apis.sales}/api/v1/Artist`, 
        qs = {};
    
    if(sorted !== undefined && sorted.length)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": ["${value.join('","')}"]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}", "$options": "i"}`);
            else
                filters.push(`"${filter.id}": ${value}`);    
            
        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`; 

    }

    //
    //baseUrl += '?' + Param(qs);

    let pages;
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');   
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}

function getTopArtists(params, globalFilter, fields){
    return config.live ? getTopArtistsLive(params, globalFilter, fields) : getTopArtistsStatic();
}

function getTopArtistsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    qs.query_name = 'top_artists';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }  
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    
    qs = BuildFilterQS(qs, 'artists');

    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopArtistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_artists_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getArtistDetails(mode) {
    let baseUrl = `${config.apis.sales}/api/static/artist_products.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getArtistsNew(params, globalFilter, basic = true) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };
    
    qs.query_name = `cat_nav_artists${basic?'_basic':''}`;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search 
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    qs = BuildFilterQS(qs, 'artists');
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getArtistsStatic(params, basic) {
    let baseUrl = `${config.apis.sales}/api/static/artists${basic?'_basic':''}.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    });
}

function getArtistsAutocomplete(input) {
    return searchService.autocomplete(input, ['artists']);
}

function getCard(id, socials, recommended, related = false) {
    let fields = `id,name,catalogue_name,image&include_socials=${socials}&recommended_channels=${recommended}&related_artists=${related}`, 
        baseUrl = `${config.apis.miniCat}/v2/artists/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getStreamsStats(ids, filtered, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, filtered, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, extFiltered, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [...extFiltered, {id:'ids', value:ids}]
    }
    //
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'top_artists';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    //baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_artists_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getArtistProfile(id, globalFilter) {
    return config.live ? getArtistProfileLive(id, globalFilter) : getArtistProfileStatic();
}

function getArtistProfileLive(id, globalFilter) {

    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'artist_profile_header';
    qs.track_artists = id;

    //baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getArtistProfileStatic() {
    let baseUrl = `${config.apis.sales}/api/static/artist_profile_header.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getFollowers(channels, globalFilter) {
    return config.live ? getFollowersLive(channels, globalFilter) : getFollowersStatic();
}

function getFollowersLive(channel_ids, globalFilter) {
    let params = {
        channel_ids: channel_ids.join(','),
        start_date: globalFilter.dateStart,
        end_date: globalFilter.dateEnd
    },
    headers = AuthHeader({'Content-Type': 'application/json'}),
    baseUrl = `${config.apis.socialCollector}/data/time_series_bq`,
    requestOptions = {
        method: 'GET',
        headers,
    };
    
    //baseUrl += '?' + Param(params).replace(/%2C/g,',');
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>res.json());    
} 

function getFollowersStatic() {
    let baseUrl = `${config.apis.sales}/api/static/social_metric_new.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getProducts(id) {
    let baseUrl = `${config.apis.miniCat}/v1/artists/${id}/products`,
        requestOptions = {
            headers: AuthHeader()
        };
        
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
    
}

function getMetadata(ids, includeSocials = false){
    return config.live ? getMetadataLive(ids, includeSocials) : getMetadataStatic(includeSocials);
}

function getMetadataLive(ids, includeSocials) {
    ids = encodeURIComponent(ids.join(','));

    let baseUrl = `${config.apis.miniCat}/v2/artists/by_id${includeSocials ? '?include_socials=true':''}`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'artist_ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic(includeSocials) {
    
    let baseUrl = `${config.apis.sales}/api/static/artists_metadata${includeSocials?'_socials':''}.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


function getTimeseriesArtists(params, globalFilter){
    return config.live ? getTimeseriesArtistsLive(params, globalFilter) : getTimeseriesArtistsStatic();
}

function getTimeseriesArtistsLive(params, globalFilter) {
    
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */

    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})

        };
    
    qs.query_name = 'top_artists';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }


    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesArtistsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_artists_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getArtistsSocial(params, globalFilter){
    return config.live ? getArtistsSocialLive(params, globalFilter) : getArtistsSocialStatic();
}

function getArtistsSocialLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})

        };
    
    qs.query_name = 'top_artists_social';
    qs.offset = params.page ? params.page*globalFilter.limit : 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    qs = BuildFilterQS(qs, 'artists');
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getArtistsSocialStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/top_artists_social.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    });
}
