import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, capitalize, map } from 'lodash';
import { Link } from 'react-router-dom';
import Box from '../../common/Box';
import Spinner from '../../common/Spinner';
import ReactSelect from '../../common/ReactSelect';
import LineChart from '../../widgets/charts/types/LineChart';
import PinnedEntityFilters from '../../widgets/entityFilter/PinnedEntityFilters';
import RecentSearches from '../home/RecentSearches';
import ShortenedTitle from './ShortenedTitle'
import ClickOutsideHolder from '../../../helpers/ClickOutsideHolder';
import Tooltip from '../../common/Tooltip';
import MatchMediaWrapper from '../../../helpers/MatchMediaWrapper';

import { statsActions } from '../../../data/actions/stats';
import { userEntityFiltersActions } from '../../../data/actions/user_entity_filters';
import { userEntityFiltersFormatter } from '../../../data/formatters/user_entity_filters';

class Header extends React.Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            recentEntities: false
        }
        this.toggleRecent = this.toggleRecent.bind(this);
    }

    
    componentDidMount() {
        const { multiMetadata = [], multiMetadataLoading } = this.props.stats;
        this.props.getUserEntityFilters(true)
        .then(filters => {
            //this.props.getEntitiesMetadata().then(metadata=>{
                const { title, saved } = this.buildTitle(multiMetadata, filters);
                this.setState({title, saved: Boolean(saved)});
                this.saveRecent(multiMetadata, title);
                this.props.onTitle(title, saved);
            //})    
        })
        
    }
    
    toggleRecent(){
        this.setState({recentEntities: !this.state.recentEntities});
    }
    
    buildTitle(metadata, filters){
        
        for(let filter of filters) {
            const { entities, id } = filter;
            if(entities.length != metadata.length)
                continue;
            
            let filterMatched = true;
            for(let entity of entities) {
                if(!find(metadata, item => (item.id == entity.entity_id && item.entity == entity.entity_type))) {
                    filterMatched = false;
                    break;
                }
            }
            if(filterMatched)
                return { saved: filter.id, title: filter.caption };
        }

            
        return { saved: null, title: userEntityFiltersFormatter.formatTitle(metadata, 'name') };
    }
    
    saveRecent(metadata, title) {
        this.props.addRecentFilter(metadata, title)
    }
    
    componentWillReceiveProps(nextProps){
        /*
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getStreamsStats();
            }                
        }
        */
        /*
        if(nextProps.ids && !isEqual(nextProps.ids, this.props.ids)) {
            this.props.getStreamsStats(nextProps.ids);
        } 
        */
    }
    
    renderFilterSummary(metadata){
        let entities = {};
        for(let entity of metadata) {
            const entityType = entity.favHide == 'hidden' ? `exclude_${entity.entity}` : entity.entity;
            
            if(!entities.hasOwnProperty(entityType))
                entities[entityType] = [];
            entities[entityType].push(entity.name)
        }
        let message = Object.keys(entities).map(entityType=><p key={entityType} >
            <strong>{map(entityType.split('_'), capitalize).join(' ')}:</strong> <br />
            {entities[entityType].join(', ')}
        </p>);
        return <Tooltip noIcon tooltipClass="noIcon" message={message}>
            <ShortenedTitle title={this.state.title} />
        </Tooltip>

    }
    
    render (){  
        const { multiMetadata = [], multiMetadataLoading } = this.props.stats;
        const { recentEntities, saved } = this.state;
        const { children, tabs, sharePage, sharedMode, match, location, filtered } = this.props;
        const entitiesLength = multiMetadata.length;
        const entitiesImageClass = entitiesLength > 4 ? "image-4" : `image-${entitiesLength}`;
        let filterEntities = [];
        for(let meta of multiMetadata) {
            let favHide = null;
            if(filtered) {
                const excludeArray = filtered[`exclude_${meta.entity}`];
                if(Array.isArray(excludeArray) && excludeArray.includes(String(meta.id)))
                    favHide = 'hidden';
                else
                    favHide = 'favorite';
            }
            meta.favHide = favHide;
            const newFilterEntity = {
                entity_id: meta.id,
                entity_type: meta.entity,
                entity_title: meta.name,
                entity_image: meta.image,
                fav_hide: favHide
            };
            filterEntities.push(newFilterEntity);
        }
        
        return <div >
            {multiMetadataLoading && <Spinner enabled={true} className="spinner spinner-inline" />}
            <div className="artist-profile-holder">
                <div className="details-image-main-holder">
                    <div className={`details-header-holder-image ${entitiesImageClass}`}>
                        <div className="saved-filter-item-images">
                            { multiMetadata.map(entity =><div key={entity.id} className={`image`}><span className="image-inner"><img src={entity.image} /></span></div>)}
                        </div>
                    </div>
                    <MatchMediaWrapper breakpoint={599} isMobile={true}>
                        {sharePage}
                    </MatchMediaWrapper>
                </div>
                <div className="artist-info-holder">
                    <div className="artist-main-info">
                        <div className="profile-recent-search-holder">
                            <div className="details-header-list">
                                <div className="details-header-item">
                                    {/*
                                    <Tooltip noIcon tooltipClass="noIcon" message={`${multiMetadata.map(entity=>entity.name).join(', ')}`}>
                                        <ShortenedTitle title={this.state.title} />
                                    </Tooltip>
                                    */}
                                    {this.renderFilterSummary(multiMetadata)}
                                    {/*(saved === false) && <Link to={{pathname:"/user/entities-filter/new", state:{defaultEntities: filterEntities}}}>Save Filter</Link>*/}
                                </div>
                            </div>
                            {!sharedMode && <span onClick = {(e) => {this.toggleRecent()}} className={`${recentEntities ? 'opened' : 'closed'} profile-recent-search-toggle `}></span>}
                            {recentEntities && <ClickOutsideHolder show={this.state.recentEntities} onClickOutside={(e) => {
                                this.toggleRecent();
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                <div className="search-page-item column">
                                    {/*<RecentSearches renderMode="column" searchResult={this.searchResult} />*/}
                                    {saved === false && <Link className="quick-search-save" to={{pathname:"/user/entities-filter/new", state:{defaultEntities: filterEntities}}}>Save This Search</Link>}
                                    <PinnedEntityFilters source="recent" renderMode="column" />
                                    <PinnedEntityFilters source="pinned" renderMode="column" />
                                </div>
                            </ClickOutsideHolder>
                            }
                        </div>
                        { tabs && <div className="profile-navigation-holder">{tabs}</div> }
                        <MatchMediaWrapper breakpoint={600} isMobile={false}>
                            {sharePage}
                        </MatchMediaWrapper>
                    </div> 
                    { children }
                </div>
            </div> 
        </div>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
    } 
}


function mapDispatchToProps(dispatch, ownProps) {
    return {
        getUserEntityFilters: () => {
            return dispatch(userEntityFiltersActions.getUserEntityFilters());
        },
        getEntitiesMetadata: () => {
            const { filtered = [] } = ownProps; 
            return dispatch(statsActions.getMultiEntitiesMetadata(filtered));
        },
        addRecentFilter: (metadata, title) => {
            dispatch(userEntityFiltersActions.addRecentFilter(metadata, title));    
        }
        
    } 
}



export default connect(mapStateToProps, mapDispatchToProps)(Header);