import { userLogsConstants } from '../constants/user_logs';

export function userLogs(state = {}, action) {
    switch (action.type) {
        case userLogsConstants.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userLogsConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users,
                // pages: action.pages
            };
        case userLogsConstants.GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
    
        case userLogsConstants.GET_USER_LOGS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userLogsConstants.GET_USER_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user,
                pages: action.pages
            };
        case userLogsConstants.GET_USER_LOGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state
    }
} 