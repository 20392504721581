import { errorActions } from './error';
import { statsActions } from './stats';
import { milestonesConstants } from '../constants/milestones';
import { milestonesService } from '../services/milestones';
import { searchService } from '../services/search';
import { milestonesFormatter } from '../formatters/milestones';
import { searchFormatter } from '../formatters/search';
import { find, flatten, uniqBy } from 'lodash';

export const milestonesActions = {
    getMilestones,
    getEntityMilestones,
    getMilestoneTypes,
    getMilestonesByType,
    getComparisonMilestones,
    addMilestone,
    editMilestone,
    deleteMilestone,
    linkMilestone,
    linkAudienceMilestone,
    unlinkMilestone,
    bulkAddMilestones,
    bulkDeleteMilestones,
    searchMilestones
};

function getMilestones(campaign) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return milestonesService.getMilestones(campaign)
            .then(
                milestones => {
                    const {table, datasets} = milestonesFormatter.formatMilestones(milestones, globalFilter, false);
                    dispatch(success(table, datasets));
                    return table;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: milestonesConstants.GET_MILESTONES_REQUEST } }
    function success(table, datasets) { return { type: milestonesConstants.GET_MILESTONES_SUCCESS, table, datasets} }
    function failure(error) { return { type: milestonesConstants.GET_MILESTONES_FAILURE, error } }
}

function getEntityMilestones(entity, ids, filtered, dateRangeOnly = false) {
    return ( dispatch, getState ) => {
        dispatch(request(entity));
        const globalFilter = getState().filter.global,
            currentUser = getState().user.user;
        return milestonesService.getMilestoneTypes()
            .then(types => {
                if(!filtered) {
                    filtered = {[entity]: ids};
                }
                let milestoneApiCalls = [];
                for(let entity of Object.keys(filtered)) {
                    milestoneApiCalls.push(milestonesService.getEntityMilestones(entity, filtered[entity], currentUser))
                }
                //return milestonesService.getEntityMilestones(entity, ids, currentUser)
                return Promise.all(milestoneApiCalls)
                .then(
                    milestonesArray => {
                        let milestones = [];
                        for(let milestoneSet of milestonesArray)
                            milestones = milestones.concat(milestoneSet.milestones);
                        
                        let milestonesMetadata = [];
                        milestones = uniqBy(milestones, 'id'); 
                        for(let milestone of milestones) {
                            const { milestone_entity_links: milestoneEntityLinks } = milestone;
                            for(let link of milestoneEntityLinks) {
                                const entity = {entity_type: `${link.entity_type}s`, entity_id: link.entity_id };
                                if(!find(milestonesMetadata, metadata=>(metadata.entity_type == entity.entity_type && metadata.entity_id == entity.entity_id)))
                                    milestonesMetadata.push(entity)
                            }
                        };
                        return statsActions.getMultiMetadata(milestonesMetadata)
                        .then(metadata => {
                            const {table, datasets} = milestonesFormatter.formatMilestones({milestones}, metadata, globalFilter, true, types, dateRangeOnly, currentUser);
                            dispatch(success(table, datasets, entity));
                            return table;                            
                        });
                        //const {table, datasets} = milestonesFormatter.linkEntitiesToMilestones(milestonesFormatter.formatMilestones(milestones, globalFilter, true, types), entity, ids);
                    },
                    error => {
                        dispatch(failure('error', entity))
                    }
                );
        });
    };

    function request(entity = 'mixed') { return { type: milestonesConstants.GET_ENTITY_MILESTONES_REQUEST, entity } }
    function success(table, datasets, entity = 'mixed') { return { type: milestonesConstants.GET_ENTITY_MILESTONES_SUCCESS, table, datasets, entity} }
    function failure(error, entity) { return { type: milestonesConstants.GET_ENTITY_MILESTONES_FAILURE, error, entity } }
}


function getMilestoneTypes(useCache=true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        const types = useCache ? getState().milestones.types : undefined;
        if(types!==undefined) {
            dispatch(success(types));
            return Promise.resolve(types);
        }

        return milestonesService.getMilestoneTypes()
            .then(
                types => {
                    const data = milestonesFormatter.formatMilestoneTypes(types);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: milestonesConstants.GET_MILESTONE_TYPES_REQUEST } }
    function success(types) { return { type: milestonesConstants.GET_MILESTONE_TYPES_SUCCESS, types } }
    function failure(error) { return { type: milestonesConstants.GET_MILESTONE_TYPES_FAILURE, error } }
}

function getMilestonesByType(type, own = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        return milestonesService.getMilestonesByType(type, currentUser)
            .then(
                milestones => {
                    milestones = milestonesFormatter.formatMilestonesByType(milestones.milestones, currentUser, own);
                    return statsActions.getMultiMetadata(milestones)
                    .then(metadata => {
                        milestones = milestonesFormatter.formatMilestonesByTypeMetadata(milestones, metadata);    
                        dispatch(success(type, milestones));
                        return milestones;                        
                    })
                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: milestonesConstants.GET_MILESTONES_BY_TYPE_REQUEST } }
    function success(id, milestones) { return { type: milestonesConstants.GET_MILESTONES_BY_TYPE_SUCCESS, id, milestones} }
    function failure(error) { return { type: milestonesConstants.GET_MILESTONES_BY_TYPE_FAILURE, error } }
}

function getComparisonMilestones(entity, id) {
    return ( dispatch, getState ) => {
        dispatch(request(entity));
        const globalFilter = getState().filter.global,
            currentUser = getState().user.user;
        return milestonesService.getEntityMilestones(entity, id, currentUser)
            .then(
                milestones => {
                    const data = milestones;
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: milestonesConstants.GET_COMPARISON_MILESTONES_REQUEST } }
    function success(id, milestones) { return { type: milestonesConstants.GET_COMPARISON_MILESTONES_SUCCESS, id, milestones} }
    function failure(error) { return { type: milestonesConstants.GET_COMPARISON_MILESTONES_FAILURE, error } }
}


function addMilestone(campaign, params, bulk = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        params = milestonesFormatter.formatNewMilestone(campaign, params);
        
        return milestonesService.addMilestone(params)
            .then(
                milestone => {
                    dispatch(success());
                    if(!bulk)
                        dispatch(errorActions.setSuccessMessage('milestone saved'));
                    //dispatch(getMilestones(campaign));
                    return milestone;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}

function editMilestone(campaign, params, milestoneID, showSuccessMessage = true) {
    return ( dispatch, getState ) => {
        dispatch(request());
        params = milestonesFormatter.formatNewMilestone(campaign, params);
        
        return milestonesService.editMilestone(params, milestoneID)
            .then(
                milestone => {
                    dispatch(success());
                    if(showSuccessMessage)
                        dispatch(errorActions.setSuccessMessage('milestone updated'));
                    return true;
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}

function deleteMilestone(id, bulk = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return milestonesService.deleteMilestone(id)
            .then(
                milestone => {
                    dispatch(success());
                    if(!bulk)
                        dispatch(errorActions.setSuccessMessage('milestone deleted'));
                    return true;
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}

function linkMilestone(params, milestone, mode) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        params = milestonesFormatter.formatLinkMilestone(params, milestone, currentUser, mode);
        
        return milestonesService.linkMilestone(params)
            .then(
                milestone => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('milestone linked to an entity'));
                    return milestone;
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.LINK_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.LINK_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.LINK_MILESTONE_FAILURE } }

}

function linkAudienceMilestone(entity, entityID, milestoneID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        const params = milestonesFormatter.formatLinkAudienceMilestone(entity, entityID, milestoneID, currentUser);
        return milestonesService.linkMilestone(params)
            .then(
                milestone => {
                    dispatch(success());
                    return milestone;
                    //dispatch(errorActions.setSuccessMessage('milestone linked to an entity'));
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.LINK_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.LINK_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.LINK_MILESTONE_FAILURE } }

}


function unlinkMilestone(id, milestoneID) {
    return ( dispatch, getState ) => {
        dispatch(request());

        milestonesService.unlinkMilestone(id)
            .then(
                milestone => {
                    dispatch(success(id, milestoneID));
                    dispatch(errorActions.setSuccessMessage('milestone link deleted'));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.UNLINK_MILESTONE_REQUEST } }
    function success(id, milestoneID) { return { type: milestonesConstants.UNLINK_MILESTONE_SUCCESS, id, milestoneID } }
    function failure() { return { type: milestonesConstants.UNLINK_MILESTONE_FAILURE } }

}

function bulkAddMilestones(milestones) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        const milestonesData = milestonesFormatter.formatBulkMilestones(milestones, currentUser);
        return milestonesService.bulkAddMilestones(milestonesData)
            .then(
                milestone => {
                    dispatch(errorActions.setSuccessMessage('Milestones Imported'));
                    dispatch(success());
                    return milestone;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }
}


function searchMilestones(milestones) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        const { search, entities } = milestonesFormatter.formatSearch(milestones);
        const querySearch = searchFormatter.formatImportSearch(search);
        
        return searchService.autocomplete(querySearch, entities, true, false)
            .then(
                (searchResult) => {
                    let results = searchFormatter.formatImport(searchResult.results, entities, querySearch);
                    milestones = milestonesFormatter.formatSearchResults(milestones, results);
                    dispatch(success());
                    return results;
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }
}



function bulkDeleteMilestones(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return milestonesService.bulkDeleteMilestones(ids)
            .then(
                milestone => {
                    dispatch(success());
                    dispatch(errorActions.setSuccessMessage('Milestones Deleted'));
                    return true;
                    //dispatch(getMilestones(campaign));
                },
                error => {
                    dispatch(failure())
                    dispatch(errorActions.setErrorMessage('error saving milestone'))
                }
            );
 
    };
    function request() { return { type: milestonesConstants.ADD_MILESTONE_REQUEST } }
    function success() { return { type: milestonesConstants.ADD_MILESTONE_SUCCESS } }
    function failure() { return { type: milestonesConstants.ADD_MILESTONE_FAILURE } }

}