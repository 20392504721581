import React from 'react';
import OverviewCard from './OverviewCard';
import VendorLogo from  '../../common/VendorLogo';
import PercentageValue from '../../common/PercentageValue';
import { convertToThousands, round } from '../../../helpers/NumberFormatter';

class TrendsWidget extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            vendorsView: false
        }
        
        this.toggleVendorsView = this.toggleVendorsView.bind(this);
    }
    
    toggleVendorsView(){
        this.setState({
            vendorsView: !this.state.vendorsView
        })
    }
    
    render (){
        const { entity } = this.props,
            vendors = entity.stms_by_vendor; 
        const route = window.location.pathname;     
        const isOverviewPage = route == '/overview' ? true : false;
        
        return <div className="overview-widget">
            <OverviewCard entity={entity} overviewPage={isOverviewPage} />
            {this.state.vendorsView === true && entity.vendors !== undefined && <div className="tiles-details">
                <div className="tiles-vendor-list">
                    {entity.vendors.map(vendor=><div className="tiles-vendor-item">
                        <VendorLogo modificator="tiles" name={vendor.vendor} />
                        <span className="engaged-count"><PercentageValue onlyPercentage item={vendor} field="units" /></span>
                    </div>)}
                </div>
            </div>}
        </div>
    }
}

export default TrendsWidget;

