import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import Modal from '../common/Modal'
import { composeValidators, required, date } from './validators'
import DatePicker from './elements/DatePicker'

class AddCampaignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let submit,
            {show, onClose, title, values={}} = this.props;
        
        return <Modal show={show} 
                title={title} 
                submitTitle="Save"
                handleSubmit={e=>submit(e)}
                handleClose={onClose}
            >
                <Form onSubmit={this.props.onSubmit} initialValues={values}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        submit = handleSubmit;
                        return <form className="m-t">
                                <Field name="title"  
                                    validate={composeValidators(required)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''}`}>
                                            <label className="font-normal control-label">Title</label>
                                            <input {...input} type="text" className="form-control" />
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                <Field name="desc"  
                                    validate={composeValidators()}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''}`}>
                                            <label className="font-normal control-label">Notes</label>
                                            <textarea {...input} type="text" className="form-control" />
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                                <Field name="target_date"  
                                    validate={composeValidators(required, date)}
                                >
                                    {({ input, meta }) => {
                                        const hasError = meta.error && meta.touched;
                                        return <div className={`form-group${hasError ? ' has-error':''}`}>
                                            <label className="font-normal control-label">Target Date</label>
                                            <DatePicker input={input} className="form-control" />
                                            {hasError && <span className="help-block">{meta.error}</span>}
                                        </div>            
                                    }}
                                </Field>
                            
                        </form>
                    }}
                />
                
        </Modal>
    }
}
export default AddCampaignForm