import { find } from 'lodash';
import { imprintsConstants } from '../constants/imprints';
import { imprintsService } from '../services/imprints';
import { imprintsFormatter } from '../formatters/imprints';

export const imprintsActions = {
    getImprints,
    getImprintsAutocomplete,
    getTopImprints,
    getImprintDetails,
    getCard,
    getStreamsStats,
    getMetadata,
    getTimeseriesImprints
};

function getImprints(params, basic) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        imprintsService.getImprints(params, globalFilter, basic)
            .then(
                imprints => {
                    imprints = imprintsFormatter.formatForTable(imprints);
                    //dispatch(success(imprints.data, imprints.pages));
                    return dispatch(getMetadata(imprints));
                },
                error => {
                    console.log(error);
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_REQUEST } }
    function success(imprints, pages) { return { type: imprintsConstants.GET_IMPRINTS_SUCCESS, imprints, pages } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_FAILURE, error } }
}


function getImprintsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        imprintsService.getImprintsAutocomplete(search)
            .then(
                imprints => {
                    imprints = imprintsFormatter.formatAutocomplete(imprints.results, ['imprints']);
                    dispatch(success(imprints.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_SUCCESS, imprints} }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopImprints(params, useCache, filter) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filter) {
            let filtered = [];
//            if(filter.imprints){
//                filtered.push({
//                    id: 'imprints',
//                    value: filter.imprints                    
//                })
//            }
//            else {
                for(let entity of Object.keys(filter)) {
                    filtered.push({
                        id: entity,
                        value: filter[entity]
                    })
                };
            //}
            params.filtered = filtered;
        }
        const fields = ['imprint_id', 'curr_units', 'prev_units', 'units_diff', 'passive', 'active', 'skipped_audio_ratio', 'skipped_video_ratio', 'completed_audio_ratio', 'completed_video_ratio', 'curr_playlists', 'prev_playlists', 'playlists_diff', 'vendors'];

        imprintsService.getTopImprints(params, globalFilter, fields)
            .then(
                imprints => {
                    const data = imprintsFormatter.formatTop(imprints);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_TOP_IMPRINTS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_TOP_IMPRINTS_SUCCESS, imprints } }
    function failure(error) { return { type: imprintsConstants.GET_TOP_IMPRINTS_FAILURE, error } }
}

                
function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return imprintsService.getCard(id)
            .then(
                card => {
                    card= imprintsFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_CARD_REQUEST } }
    function success(id, card) { return { type: imprintsConstants.GET_IMPRINTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_CARD_FAILURE, error } }
}


function getImprintDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const imprints = getState().imprints.top,
            imprint = find(imprints, {imprint_id: id});
        return Promise.resolve(imprint)
            .then(
                imprint => {
                    const data = imprintsFormatter.formatDetails(imprint.stms_by_date);
                    dispatch(success(id, data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINT_DETAILS_REQUEST } }
    function success(id, imprint) { return { type: imprintsConstants.GET_IMPRINT_DETAILS_SUCCESS, id, imprint } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINT_DETAILS_FAILURE, error } }
}                    

function getStreamsStats(ids, filtered = []) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        imprintsService.getStreamsStats(ids, filtered, globalFilter)
            .then(
                imprints => {
                    const metadata = getState().imprints.metadata;
                    imprints = imprintsFormatter.formatStreamsStats(imprints, metadata);
                    dispatch(success(imprints));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_STREAMS_STATS_SUCCESS, imprints } }
    function failure(error) { return { type: imprintsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        imprintsService.getMetadata(ids)
            .then(
                metadata => {
                    entities = imprintsFormatter.formatMetadata(entities, metadata.data);
                    dispatch(success(entities, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_TOP_IMPRINTS_REQUEST } }
    function success(imprints, metadata) { return { type: imprintsConstants.GET_TOP_IMPRINTS_SUCCESS, imprints, metadata } }
    function failure(error) { return { type: imprintsConstants.GET_TOP_IMPRINTS_FAILURE, error } }

}


function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        imprintsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = imprintsFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_IMPRINTS_REQUEST } }
    function success(imprints, total, metadata) { return { type: imprintsConstants.GET_IMPRINTS_SUCCESS, imprints, total, metadata } }
    function failure(error) { return { type: imprintsConstants.GET_IMPRINTS_FAILURE, error } }

}

function getTimeseriesImprints(params, useCache, filter) {
    
    return ( dispatch, getState ) => {
        if(getState().imprints.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;

        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }
                
        return imprintsService.getTimeseriesImprints(params, globalFilter)
            .then(
                imprints => {
                    const metadata = getState().imprints.metadata;
                    const data = imprintsFormatter.formatStreamsStats(imprints, metadata);
                    dispatch(success(data));
                    return imprints;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: imprintsConstants.GET_TIMESERIES_IMPRINTS_REQUEST } }
    function success(imprints) { return { type: imprintsConstants.GET_TIMESERIES_IMPRINTS_SUCCESS, imprints } }
    function failure(error) { return { type: imprintsConstants.GET_TIMESERIES_IMPRINTS_FAILURE, error } }
}