import store from './store';

export default function AuthHeader(extraHeaders = {}) {
    let headers = new Headers(),
        user = store.get('user'),
        authHeaders = ['email', 'token', /*'id', 'data_silo_codes', */ 'client_admin'];

    if(user) {
        for(let header of authHeaders)
            headers.append(header, user[header]);
        
        let primarySilo = user.data_silo_codes;
        if(primarySilo.includes(','))
            primarySilo = primarySilo.split(',')[0];        
        
        headers.append('user_id', user.id);
        headers.append('silo_code', primarySilo);
    
        for(let header in extraHeaders)
            headers.append(header, extraHeaders[header]);
    }
    return headers;
}
