export default {
    entities: {
        imprints: require('!svg-inline-loader!../../public/img/icons/imprints.svg'),
        artists: require('!svg-inline-loader!../../public/img/icons/artists.svg'),
        products: require('!svg-inline-loader!../../public/img/icons/products.svg'),
        projects: require('!svg-inline-loader!../../public/img/icons/projects.svg'),
        tracks: require('!svg-inline-loader!../../public/img/icons/tracks.svg'),
        playlists: require('!svg-inline-loader!../../public/img/playlist.svg'),
    }
}
