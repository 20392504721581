import { find } from 'lodash';
import vendors from './VendorSources.json';
import vendorsEnum from '../../widgets/filter/enums/vendors.json';

export default {
    VendorDistribution,
    VendorSources,
    VendorColors
}

function VendorDistribution (source) {
    const sourceID = source.toLowerCase();
    let settings = []
    for(let vendor of vendors[source]){
        const vendorID = `${sourceID}.${vendor.toLowerCase()}`;
        const vendorData = find(vendorsEnum, {value: vendor});
        const tooltip = vendorData ? vendorData.tooltip : null;
        settings.push({
            id: vendorID,
            accessor: vendor,
            sourceGroup: sourceID,
            headerAlt: vendor,
            tooltip
        })
    }
    if(vendors[source].length) {
        const totalID = sourceID;
        settings.unshift({
            id: totalID,
            accessor: 'total',
            sourceGroup: sourceID,
            headerAlt: `${source} Platforms`
        })
    }
    return settings;
}

function VendorSources () {
    return Object.keys(vendors)
}

function VendorColors(vendors) {
    if(vendors) {
        for(let vendor of vendors) {
            const vendorData = find(vendorsEnum, {value: vendor.vendor_value});
            if(vendorData) {
                vendor.color = vendorData.hex[Object.keys(vendorData.hex)[0]];
            }
        }    
    }
    return vendors;
}