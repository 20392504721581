import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../../common/Datagrid/Footers';

export const sourcesSettings = {
    settingsForTable,
    settingsForRow
};


function settingsForTable(mode){
    
    
    let columns = [
      {
              id: 'icon',
              headerClassName: ' icon-header icon',
              className: 'icon', 
              Header: '',
              width: 45,
              accessor: 'source',
              Cell: props => <div className="source-icon"></div>,
              Footer: spaceFooter
          },
                                
       {
           id: 'source',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'source',
           accessor: 'source',
           Footer: textFooter
           // width: 190
       },
       /*
       {
           id: 'total_streams',
           headerClassName: ' streams-header',
           className: 'streams',
           Header: 'Streams',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'total_streams'
       },
       */
       {
           id: 'listeners',
           headerClassName: 'text-align-right source-listeners-header',
           className: 'streams with-sort',
           Header: 'Listeners',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'listeners',
           Footer: convertSumFooter,
           width: 120
       },       
       {
           id: 'share',
           headerClassName: ' share',
           className: 'share',
           Header: 'Share',
           width: 80,
           accessor: 'share',
           Cell: props => <span className="share-val">{props.value}%</span>,
           Footer: spaceFooter
       },
    ];
    return columns.map(column=>{
        //column.show = modes[mode].includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};

    return {
        className: `tr-source tr-source-${rowInfo.row.source?(rowInfo.row.source.toLowerCase().replace(' ', '-')):''}`
    }
}