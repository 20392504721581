import React from 'react';
import { isEqual, sumBy, merge } from 'lodash';
import { Line } from 'react-chartjs-2';
import { colors, line } from '../ChartDefaults';

class FillLineChart extends React.Component {
    convertDatasets(datasets) {
        if(datasets.length && datasets[0].data.length) {
            const days = datasets[0].data.length;
            for(let day = 0; day < days; day++) {
                let largestDatasetIndex = 0,
                    largestShare = 0;
                
                const total = sumBy(datasets, dataset=>dataset.data[day]);
                
                for(let datasetIndex in datasets) {
                    const dataset = datasets[datasetIndex],
                        share = dataset.data[day] ? Math.round((dataset.data[day] / total) * 10000) : 0;
                    if(share > largestShare) {
                        largestShare = share;
                        largestDatasetIndex = datasetIndex;
                    }
                        
                    dataset.data[day] =  share;  
                }   
                const fixOffset = sumBy(datasets, dataset=>dataset.data[day])-10000;
                datasets[largestDatasetIndex].data[day]-=fixOffset;
            }
            for(let dataset in datasets) {
                datasets[dataset].fill = true;
            }   
        }
        return datasets;
    }
    
    constructor(props){
        super(props);
        if(props.data) {
            this.state = {
                labels: props.data.labels,
                datasets: this.convertDatasets(props.data.datasets)
            }
        }
        else {
            this.state = {}
        }
    }
    
    componentWillReceiveProps({data}){
        if(data && data.datasets) {
            if(!isEqual(this.props.datasets, data.datasets)) {
                this.setState({
                    labels: data.labels,
                    datasets: this.convertDatasets(data.datasets)
                })
            }
        }
    }    
    
    render() {

        const { data, options, id, shadowChart = false, shadowChartClass = 'chart-export-shadow', shadowChartWidth = 400, shadowChartHeight = 300, ...rest } = this.props;
        const stackedOptions = merge({}, line, {
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: function(value, index, values) {
                            return value / 100 + '%';
                        }
                    },                
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.raw / 100 + '% ' + context.dataset.label;
                        },
                        title: function(context) {
                            if(Array.isArray(context) && context.length) {
                                const {label} = context[0];
                                return label == 0 ? 'Release Week' : `Release Week + ${label}`
                            }
                        }
                    }
                }
            }
        }, options);
                
        return (this.state.datasets) ?
            <React.Fragment>
                <Line data={this.state} options={stackedOptions} {...rest}/ > 
                {shadowChart && <div className={shadowChartClass}><Line width={shadowChartWidth} height={shadowChartHeight} id={id} data={this.state} options={stackedOptions} {...rest}></Line></div>}
            </React.Fragment> :  null;
    }
}

export default FillLineChart