import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';
import StreamsStats from '../../common/Stats/Streams';
import TopPlaylists from './TopPlaylists';
import EntityMilestones from './EntityMilestones';
import Box from '../../common/Box'
import icons from '../../../helpers/Icons';

class CampaignTypeBox extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            view: 'streams',
        }
        this.setChartView = this.setChartView.bind(this);
    }

    setChartView(view){
        this.setState({view});
    }


    renderToolbar(entity){

        let toolbar = [];
        //for (let view in views) {
          //  toolbar.push(<a key={view}><i className={`fa fa-${views[view]} ${this.state.view == view ? 'active' : ''}`} onClick={()=>this.setChartView(view)}></i></a>);
        //}
        toolbar.push(<div><div className="ibox-icon-holder">
            <a key='streams' title="Streams" className={`chart-icon ${this.state.view == 'streams' ? 'active' : ''}`}
                onClick={()=>this.setChartView('streams')}
                dangerouslySetInnerHTML={{__html: icons.entities[entity]}}>
            </a>
            <a key='playlists' title="Playlists" className={`chart-icon ${this.state.view == 'playlists' ? 'active' : ''}`}
                onClick={()=>this.setChartView('playlists')}
                dangerouslySetInnerHTML={{__html: icons.entities.playlists}}>
            </a>
        </div>
        </div>);
        return toolbar;
    }


    render(){
        const {entity, campaign} = this.props,
            extKey = `${entity}Ext`,
            campaignEntities = (campaign[entity] && campaign[entity].length) ? campaign[entity] : null,
            extCampaignEntities = (campaign[extKey] && campaign[extKey].length) ? campaign[extKey] : null;
        return <Box title={`Campaign ${entity}`} toolbar={this.renderToolbar(entity)} className="home-box">
                {campaignEntities && <div className="campaign-entity-holder">
                    {this.state.view == 'streams' && <StreamsStats entity={entity} ids={campaignEntities} /> }
                    {this.state.view == 'playlists' && <TopPlaylists title="Top Playlists" entity="playlists" parentEntity={entity} parentEntityIDs={campaignEntities} parentEntityTitle="Campaign Products" />}
                    <EntityMilestones entity={entity} ids={extCampaignEntities} />
                </div>}
                {!campaignEntities && <p>No {entity} added</p>}
            </Box>
    }
}


export default CampaignTypeBox