export const usersConstants = {
  GET_USERS_REQUEST: 'GET_ACL_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_ACL_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_ACL_USERS_FAILURE',
  GET_USER_REQUEST: 'GET_ACL_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_ACL_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_ACL_USER_FAILURE',
  ADD_USER_REQUEST: 'ADD_ACL_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_ACL_USER_SUCCESS', 
  ADD_USER_FAILURE: 'ADD_ACL_USER_FAILURE',
  GET_IMPRINTS_REQUEST: 'GET_ACL_IMPRINTS_REQUEST',
  GET_IMPRINTS_SUCCESS: 'GET_ACL_IMPRINTS_SUCCESS',
  GET_IMPRINTS_FAILURE: 'GET_ACL_IMPRINTS_FAILURE',
  GET_ARTISTS_REQUEST: 'GET_ACL_ARTISTS_REQUEST',
  GET_ARTISTS_SUCCESS: 'GET_ACL_ARTISTS_SUCCESS',
  GET_ARTISTS_FAILURE: 'GET_ACL_ARTISTS_FAILURE',
  GET_PRODUCTS_REQUEST: 'GET_ACL_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_ACL_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_ACL_PRODUCTS_FAILURE',
  GET_CAMPAIGNS_REQUEST: 'GET_ACL_CAMPAIGNS_REQUEST',
  GET_CAMPAIGNS_SUCCESS: 'GET_ACL_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAILURE: 'GET_ACL_CAMPAIGNS_FAILURE',  
      
};
