import React from 'react';
import GraphicChart from './GraphicChart';
import TableChart from './TableChart';
import { orderBy, sumBy } from 'lodash';
import Box from '../../common/Box';

class Chart extends React.Component {
    constructor(props) {
        super(props);
        let { data, limit } = this.props;
        data = orderBy(data, ['value'], ['desc']);

        if(limit && data.length > limit) {
            let other = data.slice(limit, data.length);
            data = data.slice(0, limit);
            data.push({
                value: sumBy(other, 'value'),
                title: 'Other'
            });
        }
                
        this.state = {
            data,
            view: 'chart'
        }
    }

    setView(view){
        this.setState({
            view
        })
    }

    renderToolbar(){
        return (
            <div>
                <a>
                    <i className={"fa fa-table " + (this.state.view == 'table' ? 'active' : '')} onClick={()=>this.setView('table')}></i>
                </a>
                <a>
                   <i className={"fa fa-chart-line " + (this.state.view == 'chart' ? 'active' : '') } onClick={()=>this.setView('chart')}></i>
                </a>
            </div>
        );
    }

    render() {
        return (
            <Box title={this.props.labels.header} toolbar={this.renderToolbar()}>
                {this.state.view === 'chart' && <GraphicChart data={this.state.data} mode={this.props.mode} labels={this.props.labels} >
                </GraphicChart> }
                {this.state.view === 'table' && <TableChart data={this.state.data} mode={this.props.mode} labels={this.props.labels} >
                </TableChart> }
            </Box>    
        );
    }
}

export default Chart