import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../AbsoluteValue';
import PercentageValue from '../../PercentageValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../Datagrid/Footers';

export const devicesSettings = {
    settingsForTable,
    settingsForRow
};


function settingsForTable(mode){
    
    
    let columns = [
      {
              id: 'icon',
              headerClassName: ' icon-header icon no-sort',
              className: 'icon', 
              Header: '',
              // width: 70,
              accessor: 'device',
              Cell: props => <div className="device-icon"></div>,
              Footer: spaceFooter
          },
                                
       {
           id: 'device',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'device',
           accessor: 'device',
           Footer: textFooter
           // width: 190
       },
       {
           id: 'streams',
           headerClassName: ' streams-header',
           className: 'streams',
           Header: 'Units',
           Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
           // width: 70,
           accessor: 'curr_units',
           Footer: convertSumFooter
       },
       {
           id: 'share',
           headerClassName: ' share',
           className: 'share',
           Header: 'Share',
           // width: 70,
           accessor: 'share',
           Cell: props => <span className="share-val">{props.value}%</span>,
           Footer: spaceFooter
       },
       
    ];
    return columns.map(column=>{
        //column.show = modes[mode].includes(column.id);
        return column;
    });
}

function settingsForRow(state, rowInfo, column) {
    if(rowInfo===undefined)
        return {};

    return {
        className: `tr-device tr-device-${rowInfo.row.device.toLowerCase()}`
    }
}