import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import store from '../../../helpers/store'
import {
  uniq,
  map,
  merge,
  find,
  flatten,
  findKey,
  isEqual,
  get,
  cloneDeep
} from 'lodash';
import {
  Dropdown
} from 'react-bootstrap';
import {
  connect
} from 'react-redux';

// import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import ReactTooltip from 'react-tooltip';
import Datepicker from '../../common/Datepicker';
import Multiselect from '../../common/Multiselect/Multiselect';
//import MultiselectWithSearch from '../../common/MultiselectWithSearch';
import Accordion from './Accordion';
import ReactSelect from '../../common/ReactSelect';
import FilterButton from './FilterButton';
import SelectFieldFilter from '../../common/SelectFieldFilter';
import { heartbeatsConstants } from '../../../data/constants/heartbeats';

import SaveLoad from '../../../helpers/SaveLoad';

import Spinner from '../../common/Spinner';
import ClickOutsideHolder from '../../../helpers/ClickOutsideHolder'
import MatchMediaWrapper from '../../../helpers/MatchMediaWrapper';
import Tooltip from '../../common/Tooltip';
import ConfirmClick from '../../common/ConfirmClick';
import Modal from '../../common/Modal';

import SearchWidget from '../../pages/home/SearchWidget';
import PlaylistSearchWidget from '../../pages/playlists/Filter/Search';
import EntityFilter from '../../widgets/entityFilter/StandaloneFilter';
import PlaylistFilter from '../../pages/playlists/Filter';

import vendorsArray from '../filter/enums/vendors';
import territoriesArray from '../filter/enums/territories.js';

import sourcesValues from './enums/sources';
import unitsValues from './enums/units';
import typesValues from './enums/types';
import limits from './enums/limits';

var tagSvg = require('!svg-inline-loader!../../../../public/img/tag.svg');
var searchSvg = require('!svg-inline-loader!../../../../public/img/search.svg');

const accordionSettings = [
  {
    id: "units", title: "Week Units", description: "Minimum consumption units"
  },
  {
    id: "tiktok", title: "TikTok Creates (#)", description: "Minimum number of TikTok creations"
  },
  {
    id: "percentage", title: "Percentage Change (%)", description: "Minimum change in consumption by percentage"
  },
  {
    id: "release_type", title: "Release Type", description: "Choose to include Cataog, New Releases, or all"
  },
  {
    id: "vendor", title: "Platform", description: "Filter for Biggest Mover by Platform"
  },
  {
    id: "territories", title: "Territory", description: "Filter for Biggest Mover by Territory"
  }
];

const defaultFilterState = {
  filtered: {
  },
  filter: {
    new_release: "false",
    period_2_units: 1000,
    tiktok_creations: 0,
    percent_change: 50,
    vendors: [],
    territories: [],
  }
}

class Filter extends React.Component {
  constructor(props) {
    super(props);
   
    let filterState = cloneDeep(defaultFilterState);
    if(Array.isArray(props.filterState)) {
      for(let filter of props.filterState) {
        filterState['filter'][filter.id] = filter.value;
      }
    } 

    this.state = {
      ...filterState,
      accordion: {
        units: false,
        tiktok: false,
        percentage: false,
        release_type: false,
        vendor: false,
        territories: false
      }, 
      search: "",
      matchingIds: ["units", "tiktok", "percentage", "release_type", "vendor", "territories"],
    }

    this.setSearch = this.setSearch.bind(this);
    this.onApply = this.onApply.bind(this);
  }
      

  componentWillMount(){
      
  }
    

  toggleAccordion = (selectedKey) => {
    let { accordion } = this.state;
    for (let key in accordion) {
        accordion[key] = (key == selectedKey) ? !accordion[key] : false;
    }
    this.setState({
        accordion
      })
  }

  filterOptionChange = (value, key) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [key]: value
      }
    }));
  }
  
  removeOption = (option, apply = true) => {
    let newState = Object.assign({}, this.state.filter);
    
    switch (option) {          
        case 'new_release':
          newState.new_release = "false";
        break;
        case 'percent_change':
          newState.percent_change = defaultFilterState.filter.percent_change;
        break;
        case 'period_2_units':
          newState.period_2_units = defaultFilterState.filter.period_2_units;
        break;  
        case 'tiktok_creations':
          newState.tiktok_creations = defaultFilterState.filter.tiktok_creations;
        break;  
        case 'vendors':
          newState.vendors = [];
        break;  
        case 'territories':
          newState.territories = defaultFilterState.filter.territories;
        break;  
    }
  
    this.setState({
        ...this.state,
        filter: {
            ...newState
        }
    }, () => {
      this.onApply();
    });
    
  }
  
  
  filterReset = () => {
    this.setState({
      ...this.state,
      ...defaultFilterState,
      filtered: {},
      display: []
    })
  }

    componentDidMount() {
    }
    
    getAccordionHeader(entity) {
      const accordionItem = accordionSettings.find(item => item.id === entity);

      if (!accordionItem) {
        return null;
      }

      return <div className="playlist-filter-accordion-label">
        <span className="playlist-filter-result-icon" dangerouslySetInnerHTML={{__html: tagSvg}}></span>
        <div className="playlist-filter-accordion-label-text">
          <span className="playlist-filter-accordion-label-title">{accordionItem.title}</span>
          <span className="playlist-filter-accordion-label-placeholder">{accordionItem.description}</span>
        </div>
      </div>
    }

    setSearch(e) {
      const value = e.target.value;
      
      this.setState({
        search: value,
      });
      
      this.getMatchingIds(value);
    }
    
    getMatchingIds(value) {
      const matchingIds = [];
      
      accordionSettings.forEach(setting => {
        if (setting.title.match(new RegExp(value, 'i')) || setting.description.match(new RegExp(value, 'i'))) {
          matchingIds.push(setting.id);
        }
      });

      if (value.length > 0 && value.length < 3) {
        return;
      }

      this.setState({
        matchingIds
      })
    }

    onApply() {
      const { filter } = this.state;

//       for(let stateKey of Object.keys(filter)) {
//         this.props.onFilteredChangeCustom(filter[stateKey], stateKey)
//       }
// 
//       this.props.onApply();

      this.props.onFilteredChangeCustom(filter);
    }

    render() {
      const { expanded, disableReason, filterState } = this.props;
      if(!expanded)
        return null;
        
      const { 
        matchingIds,
      } = this.state;

      const { 
        new_release,
        period_2_units,
        tiktok_creations,
        percent_change,
        vendors,
        territories,
       } = this.state.filter;

      const vendorsArrayValues = vendorsArray.map(vendor=>({label: vendor.value, value: String(vendor.vend_id)}));

        return <div className={`filter-wrapper filter-opened playlist-filter`}>
          <div className="filter-summary-holder playlist-filter-summary-holder">            
            <div className="filterSpinner">
              <Spinner enabled={this.props.loading} />
            </div>
            <form role="form" className="form-inline">
                <div className="filter-wrapper-inner">
                    <div className='search-widget'>
                    <form role="form" className="form-inline">
                      <div className="form-group search">
                        <input
                          className={`form-control main-search-input`}
                          placeholder={
                            "Search Filters"
                          }
                          type="text"
                          value={this.state.search}
                          onChange={this.setSearch}
                          />
                      </div>
                    </form>
                    </div>
                    <div className="advanced-filter">
                      <div className="advanced-filter-inner">
                        <Accordion className="chosen-group filter-option-group" id="units" label={this.getAccordionHeader("units")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <Multiselect elements={unitsValues} selected={period_2_units} handleChange={(value)=>{
                            this.filterOptionChange(value, 'period_2_units')}} />
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="tiktok" label={this.getAccordionHeader("tiktok")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <input type="number" disabled={(disableReason.sharedMode || disableReason.tiktokMetricsDisabled)} className="form-control percent-change-input filter-input" value={tiktok_creations} min={0} step={10} onChange={(e)=>{this.filterOptionChange(e.target.value, 'tiktok_creations')}} placeholder="e.g. 1000" />
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="percentage" label={this.getAccordionHeader("percentage")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <div className="filter-slider-container filter-no-marks">
                            <Slider marks={limits} value={percent_change} onChange={(value)=>{this.filterOptionChange(value, 'percent_change')}} min={20} step={1} max={100} className="filter-slider" />
                          </div>
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="release_type" label={this.getAccordionHeader("release_type")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <Multiselect elements={typesValues} selected={new_release} handleChange={(value)=>this.filterOptionChange(value, 'new_release')} />
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="vendor" label={this.getAccordionHeader("vendor")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <Multiselect elements={vendorsArrayValues} selected={vendors} handleChange={(value)=>this.filterOptionChange(value, 'vendors')} options={{enableClickableOptGroups: true}}   />
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group territories" id="territories" label={this.getAccordionHeader("territories")} accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion} matchingIds={matchingIds}>
                          <Multiselect className="territories-container" elements={territoriesArray} selected={territories} handleChange={(value)=>this.filterOptionChange(value, 'territories')} options={{enableClickableOptGroups: true}}  isTerritory />
                        </Accordion>
                      </div>
                    </div>
                    <ul className="saved-filters">
                      <div className="form-group button-container">
                        <button className="apply-btn" alt="apply" type="button" onClick={this.onApply}>Apply Filter</button>
                      </div>
                      <div className="form-group reset-link-container">
                          <a className="reset-filter-link" onClick={(e)=>this.filterReset(e)}>Reset to default</a>
                      </div>
                    </ul>
                </div>
              </form>
          </div>
            
        </div>
    }
}

function mapDispatchToProps(dispatch){
  return {
    getEntityFilters: () => dispatch(userEntityFiltersActions.getUserEntityFilters()),
  }
      
}

function mapStateToProps(state) {
  return {
      filter: state.filter,
      userEntityFilters: state.userEntityFilters
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Filter)