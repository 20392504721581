import React from 'react';

export default function ChartLegend(props) {
    const { labels, colors } = props;
    
    return <ul className="sources-legend">
        {labels.map((label, index)=><li className="sources-legend-item" key={label}><i className="fas fa-square sources-color" style={{color: colors[index]}} />{label}</li>)}
    </ul>
}



