import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import Modal from '../common/Modal'

import { composeValidators, required, email } from './validators'

class AddRecipientForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let submit,
            {show, onClose, title, values={}, onSubmit, formRef, formId } = this.props;
        return <Modal show={show} 
                title={title} 
                submitTitle="Add"
                handleSubmit={e=>submit(e)}
                handleClose={onClose}
                showActionButtons={true}
                dialogClassName="dialog-modal"
            >        
            <Form onSubmit={onSubmit} ref={formRef} id="userCreateForm" initialValues={values} keepDirtyOnReinitialize={true}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                submit = handleSubmit;
                return <form className="m-t" id={formId} onSubmit={handleSubmit}>
                    <Field name="email"  
                        validate={composeValidators(required, email)}
                    >
                        {({ input, meta }) => {
                            const hasError = meta.error && meta.touched;
                            return <div className={`form-group${hasError ? ' has-error':''}`}>
                                <label className="font-normal control-label">Email*</label>
                                <input {...input} type="text" className="form-control" />
                                {hasError && <span className="help-block">{meta.error}</span>}
                            </div>            
                        }}
                    </Field>                  
                    {/* <div className="button-holder">
                        <button type="submit" className="default-btn default-btn--small">Save</button>
                    </div> */}
                </form>
            }}
        />
        </Modal>
    }
}
export default AddRecipientForm