import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { Select } from './elements';

const selectOptions = [
    { value: "data_discrepancy", label: "Data Discrepancy" },
    { value: "platform_issue", label: "Platform Issue" },
    { value: "platform_improvement", label: "Platform Improvement" },
    { value: "other", label: "Other" }
];

class TicketForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: ''
        };
    }

    render() {
        const required = value => (value ? undefined : 'Required');

        return (
            <Form
                onSubmit={this.props.onSubmit}
                onFileChange={this.props.onFileChange}
                initialValues={{}}
                validate={values => {
                    const errors = {};
                    if (!values.category) {
                        errors.category = 'Required';
                    }
                    if (!values.body) {
                        errors.body = 'Required';
                    }
                    return errors;
                }}
                render={({ handleSubmit, form, submitting, pristine, values, invalid, onFileChange }) => (
                    <form
                        className="m-t chat-modal-form"
                        onSubmit={event => {
                            setTimeout(() => {
                                form.reset();
                            }, 2000);
                            return handleSubmit(event);
                        }}
                    >
                        <div className="form-group">
                            <Field name="category">
                                {({ input, meta }) => (
                                    <div>
                                        <Select
                                            input={input}
                                            options={selectOptions}
                                            placeholder={"Select category"}
                                            className="custom-select"
                                        />
                                        {meta.error && meta.touched && <span className="error-field">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className="form-group">
                            <Field name="body">
                                {({ input, meta }) => (
                                    <div>
                                        <textarea
                                            {...input}
                                            type="text"
                                            placeholder="Description of the issue"
                                            className="form-control form-field"
                                        />
                                        {meta.error && meta.touched && <span className="error-field">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className="form-group">
                            <Field name="share" type="checkbox">
                                {({ input, meta }) => {
                                    return (
                                        <div className={`input-holder`}>
                                            <input
                                                {...input}
                                                type="checkbox"
                                                className="input-checkbox visually-hidden"
                                                id="shareLink"
                                            />
                                            <label className="font-normal checkbox-label" htmlFor="shareLink">
                                                Share Current Screen
                                            </label>
                                        </div>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="form-group">
                            <Field name="file">
                                {({ input, meta }) => (
                                    <div>
                                        <input
                                            {...input}
                                            type="file"
                                            className="input-file"
                                            id="chatWidgetFile"
                                            onChange={onFileChange}
                                        />
                                        <label htmlFor="chatWidgetFile" className="default-btn default-btn--small">
                                            Attach Document
                                        </label>
                                    </div>
                                )}
                            </Field>
                        </div>
                        <button type="submit" disabled={submitting || pristine || invalid} className="chat-submit">
                            Submit
                        </button>
                    </form>
                )}
            />
        );
    }
}

export default TicketForm;
