import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../../common/Modal';
import SearchWidget from '../../pages/home/SearchWidget';
import MultiSearch from './MultiSearch';
import EntityFilter from './EntityFilter';
import { sessionStore } from '../../../helpers/SessionStore'
import { filterActions } from '../../../data/actions/filter';

var tagSvg = require('!svg-inline-loader!../../../../public/img/tag.svg');

class EntityFilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showNewFilter: false,
            
        };
        this.showModal = this.showModal.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.applyFilterSearch = this.applyFilterSearch.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.resetEntityFilter = this.resetEntityFilter.bind(this);
    }
    
    showModal(show) {
        this.setState({
            showModal: show !== undefined ? show: !this.state.showModal
        })
    }
    
    applyFilter(filter) {
        this.showModal(false);
        this.props.dispatch(filterActions.setNewEntityFilter(filter));
    }
    
    applyFilterSearch(filter) {
        this.toggleNewFilter(undefined, false);
        sessionStore.set('userEntityFilter', {id: null, caption: `Unsaved Filter - ${filter.length} entities`, entities: filter});
        this.props.dispatch(filterActions.setNewEntityFilter({entities: filter}));
    }
    
    resetEntityFilter(){
        this.showModal(false);
        sessionStore.set('userEntityFilter', null);
        this.resetFilter(false)
    }
    
    resetFilter(initialize=false){
        sessionStore.remove('userEntityFilter');
        this.props.dispatch(filterActions.setNewEntityFilter(undefined, initialize))
    }
    
    toggleNewFilter = (event, show) => {
        this.setState({
            showNewFilter: show !== undefined ? show: !this.state.showNewFilter
        })
    }

    
    renderCurrentFilter() {
        const currentFilter = sessionStore.get('userEntityFilter') || null;
        if(!currentFilter)
            return null;
        return <span className="playlist-filter-text-item"><span className="playlist-filter-result-icon" dangerouslySetInnerHTML={{__html: tagSvg}}></span>{currentFilter.caption} <a  className="close-link" title="Reset Filter" onClick={()=>this.resetEntityFilter()}>&times;</a></span>;
    }

    render() {
        const { showNewFilter } = this.state;
        return <div className="entity-filter-button-holder">
            <Modal show={this.state.showModal} 
                title="Saved Filters" 
                showActionButtons={false}
                handleClose={()=>this.showModal(false)}
                dialogClassName="entity-filter-modal"
            >
                <EntityFilter applyFilter={this.applyFilter} resetFilter={this.resetEntityFilter} />
            </Modal>
            <div className="filter-buttons-holder">
                <button onClick={this.showModal} className="default-btn default-btn--small">Saved Filters</button>
                <button onClick={this.toggleNewFilter} className="default-btn default-btn--small">+ Add Filter</button>
                <button onClick={()=>this.resetFilter(true)} className="default-btn default-btn--light default-btn--small">Load All Data</button>
                {this.renderCurrentFilter()}
            </div>
            <MultiSearch applyFilter={this.applyFilterSearch} resetFilter={this.resetFilter} className={showNewFilter ? '' : 'visually-hidden'} />
            
        
        </div>
    }
}


function mapStateToProps(state) {
    return {
        userEntityFilters: state.userEntityFilters
    } 
}
export default connect(mapStateToProps)(EntityFilterModal);