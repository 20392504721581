import moment from 'moment';
import { filter, cloneDeep } from 'lodash';

export {
  addMissedDataDataset
}

function labelDateMilestonesFormatter(period, label) {
    const dateLabel = moment(label);
    const year = dateLabel.format('YYYY');
    var week; 
    switch(period) {
        case 'Chart Week':
            week = dateLabel.day(5).week();
            return `Ch. Wk. #${week}, ${year}`;
        case 'Calendar Week':
            week = dateLabel.day(0).week();
            return `Cal. Wk. #${week}, ${year}`;
        case 'Month':
            return dateLabel.format('MMM YYYY');
        default:
            return dateLabel.format('DD MMM');
    }
}

function addMissedDataDataset(logs, labels, period) {
    let missedDataLogs = cloneDeep(logs);
    let missedDataArray = {};
    let backgroundArray = []; 
    let borderColorArray = []; 
    let dataArray = []; 

    if(Array.isArray(missedDataLogs) && missedDataLogs.length) {
        for(let missedData of missedDataLogs) {
            missedData.comparison_date = labelDateMilestonesFormatter(period, missedData.report_date)
        }

        for(let labelID in labels) {
            const label = labels[labelID];

            const missedDataForDate = filter(missedDataLogs, (missedData)=>(missedData.comparison_date == label));
            
            let backgrounds = []; 
            let borders = []; 
            let data = {};
            let tooltipText = ""
            let background = "#fff"
            let borderColor = "#fff"

            if (missedDataForDate && missedDataForDate.length) {
                missedDataForDate.forEach((item) => {
                    if (item.status_code === 0) {
                        background = 'rgba(216, 41, 41, 0.2)';
                        borderColor = 'rgba(216, 41, 41, 0.4)';
                    } else if (item.status_code === 750) {
                        background = 'rgba(151, 221, 151, 0.2)';
                        borderColor = 'rgba(151, 221, 151, 0.4)';
                    } else if (item.status_code > 0 && item.status_code !== 750) {
                        background = 'rgba(216, 156, 41, 0.2)';
                        borderColor = 'rgba(216, 156, 41, 0.4)';
                    }
                });

                const vendorGroups = _.groupBy(missedDataForDate, 'vendorTitle');
                tooltipText = Object.keys(vendorGroups).map((vendor, index) => {
                    const dates = vendorGroups[vendor].map(item => moment(item.report_date).format('YYYY-MM-DD'));
                    const uniqueDates = _.uniq(dates);
                    const formattedDates = uniqueDates.map((date, idx) => idx === uniqueDates.length - 1 ? date : `${date},`);
                    const formattedVendorData = `${vendor} ${formattedDates.join(' ')}`;
                    return index === Object.keys(vendorGroups).length - 1 ? formattedVendorData : `${formattedVendorData}\n`;
                }).join('');

                backgrounds.push(background)
                borders.push(borderColor)

                data = {
                    x: labels.indexOf(label),
                    y: 1,
                    tooltipText: tooltipText
                };
            } else {
                data = {
                    x: labels.indexOf(label),
                    y: 0,
                    tooltipText: ""
                };
                backgrounds.push("#fff"); 
                borders.push("#fff"); 
            }

            backgroundArray = backgroundArray.concat(backgrounds);
            borderColorArray = borderColorArray.concat(borders);
            dataArray.push(data); 
        }
    }
    
    missedDataArray = { dataArray, borderColorArray, backgroundArray }

    return missedDataArray;
}