import { projectsConstants } from '../constants/projects';
import { projectsService } from '../services/projects';
import { projectsFormatter } from '../formatters/projects';
import { find } from 'lodash';

export const projectsActions = {
    getProjects,
    getTopProjects,
    getProjectsAutocomplete,
    getProjectDetails,
    getCard,
    getStreamsStats,
    getProjectsByBarcode,
    getTracks,
    getMetadata,
    getTimeseriesProjects,
    compareProjects,
    compareProjectDemographics,
    compareProjectTerritories,
    compareProjectVendors,
    getProjectsReleaseDates,
    compareProjectArtist 
};

function getProjects(params, basic) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        projectsService.getProjects(params, globalFilter, basic)
            .then(
                projects => {
                    const data = projectsFormatter.formatForTable(projects);
                    //dispatch(success(data, projects.pages));
                    return dispatch(getMetadata(data));                    
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_REQUEST } }
    function success(projects, pages) { return { type: projectsConstants.GET_PROJECTS_SUCCESS, projects, pages } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_FAILURE, error } }
}

function getProjectsByBarcode(barcodes){
  return dispatch => {
    dispatch(request());
    if(!barcodes)
        return dispatch(success([]));

    projectsService.getProjectsByBarcode(barcodes)
    .then(response => {
      const dataIsEmpty = response.data && response.data.length === 0;
      let result = dataIsEmpty ? [] : response.projects;
        dispatch(success(result));
    })
    .catch( error => {
      dispatch(failure(error.message));
    })
  }

  function request() { return { type: projectsConstants.GET_PROJECTS_BY_BARCODES_REQUEST } }
  function success(projects) { return { type: projectsConstants.GET_PROJECTS_BY_BARCODES_SUCCESS, projects } }
  function failure(error) { return { type: projectsConstants.GET_PROJECTS_BY_BARCODES_FAILURE, error } }
}


function getProjectsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        projectsService.getProjectsAutocomplete(search)
            .then(
                projects => {
                    const data = projectsFormatter.formatAutocomplete(projects.results, ['projects']);
                    dispatch(success(data.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_AUTOCOMPLETE_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_PROJECTS_AUTOCOMPLETE_SUCCESS, projects} }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopProjects(params, useCache, filter) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        const fields = ['project_id', 'curr_units', 'prev_units', 'units_diff', 'passive', 'active', 'skipped_audio_ratio', 'skipped_video_ratio', 'completed_audio_ratio', 'completed_video_ratio', 'curr_playlists', 'prev_playlists', 'playlists_diff', 'vendors'];
        if(filter) {
            let filtered = [];
//            if(filter.projects){
//                filtered.push({
//                    id: 'projects',
//                    value: filter.projects                    
//                })
//            }
//            else {
                for(let entity of Object.keys(filter)) {
                    filtered.push({
                        id: entity,
                        value: filter[entity]
                    })
                };
//            }

            params.filtered = filtered;
        }
        
        projectsService.getTopProjects(params, globalFilter, fields)
            .then(
                projects => {
                    const data = projectsFormatter.formatTop(projects);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TOP_PROJECTS_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_TOP_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.GET_TOP_PROJECTS_FAILURE, error } }
}


function getProjectDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const projects = getState().projects.top,
            project = find(projects, {project_id: id});
        return Promise.resolve(project)
            .then(
                project => {
                    const data = projectsFormatter.formatDetails(project.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECT_DETAILS_REQUEST } }
    function success(id, project) { return { type: projectsConstants.GET_PROJECT_DETAILS_SUCCESS, id, project } }
    function failure(error) { return { type: projectsConstants.GET_ARTIST_PROJECT_FAILURE, error } }
}

function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return projectsService.getCard(id)
            .then(
                card => {
                    card= projectsFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_CARD_REQUEST } }
    function success(id, card) { return { type: projectsConstants.GET_PROJECTS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_CARD_FAILURE, error } }
}

function getStreamsStats(ids, filtered = []) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        Promise.all([projectsService.getStreamsStats(ids, filtered, globalFilter),
                     projectsService.getMetadata(ids)])
            .then(
                ([projects, metadata]) => {
                    //const metadata = getState().projects.metadata;
                    projects = projectsFormatter.formatStreamsStatsByIDs(projects, metadata.data);
                    dispatch(success(projects));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_STREAMS_STATS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTracks(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        ids = ids.map(id=>Number(id));
        projectsService.getTracks(ids)
            .then(
                tracks => {
                    const data = projectsFormatter.formatTracks(tracks.data);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TRACKS_REQUEST } }
    function success(tracks) { return { type: projectsConstants.GET_TRACKS_SUCCESS, tracks} }
    function failure(error) { return { type: projectsConstants.GET_TRACKS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        projectsService.getMetadata(ids)
            .then(
                metadata => {
                    entities = projectsFormatter.formatMetadata(entities, metadata.data);
                    dispatch(success(entities, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TOP_PROJECTS_REQUEST } }
    function success(projects, metadata) { return { type: projectsConstants.GET_TOP_PROJECTS_SUCCESS, projects, metadata } }
    function failure(error) { return { type: projectsConstants.GET_TOP_PROJECTS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        projectsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = projectsFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.projects));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_REQUEST } }
    function success(projects, total, metadata) { return { type: projectsConstants.GET_PROJECTS_SUCCESS, projects, total, metadata } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_FAILURE, error } }

}

function getMetadataByIDs(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.upc);
        projectsService.getMetadataByIDs(ids)
            .then(
                metadata => {
                    const data = projectsFormatter.formatMetadataByIDs(entities.data, metadata.projects);
                    dispatch(success(data, entities.total, metadata.projects));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_REQUEST } }
    function success(projects, total, metadata) { return { type: projectsConstants.GET_PROJECTS_SUCCESS, projects, total, metadata } }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_FAILURE, error } }

}



function getTimeseriesProjects(params, useCache, filter) {
    return ( dispatch, getState ) => {
        if(getState().projects.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;

        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }

        return projectsService.getTimeseriesProjects(params, globalFilter)
            .then(
                projects => {
                    const metadata = getState().projects.metadata;
                    const data = projectsFormatter.formatStreamsStats(projects, metadata);
                    dispatch(success(data));
                    return projects;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.GET_TIMESERIES_PROJECTS_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_TIMESERIES_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.GET_TIMESERIES_PROJECTS_FAILURE, error } }
}

function compareProjects(ids, weeks, releaseDates, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        projectsService.compareProjects(ids, weeks, releaseDates, territories, vendors, currentUser)
            .then(
                projects => {
                    const data = projectsFormatter.formatCompareProjects(ids, projects);
                    return dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.COMPARE_PROJECTS_REQUEST } }
    function success(projects) { return { type: projectsConstants.COMPARE_PROJECTS_SUCCESS, projects} }
    function failure(error) { return { type: projectsConstants.COMPARE_PROJECTS_FAILURE, error } }
}

function compareProjectDemographics(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        projectsService.compareProjectDemographics(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                project => {
                    const data = projectsFormatter.formatCompareProjectDemographics(project);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.COMPARE_PROJECT_DEMOGRAPHICS_REQUEST } }
    function success(id, project) { return { type: projectsConstants.COMPARE_PROJECT_DEMOGRAPHICS_SUCCESS, id, project} }
    function failure(error) { return { type: projectsConstants.COMPARE_PROJECT_DEMOGRAPHICS_FAILURE, error } }
}

function compareProjectTerritories(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        projectsService.compareProjectTerritories(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                project => {
                    const data = projectsFormatter.formatCompareProjectTerritories(project);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.COMPARE_PROJECT_TERRITORIES_REQUEST } }
    function success(id, project) { return { type: projectsConstants.COMPARE_PROJECT_TERRITORIES_SUCCESS, id, project} }
    function failure(error) { return { type: projectsConstants.COMPARE_PROJECT_TERRITORIES_FAILURE, error } }
}

function compareProjectVendors(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        projectsService.compareProjectVendors(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                project => {
                    const data = projectsFormatter.formatCompareProjectVendors(project);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.COMPARE_PROJECT_VENDORS_REQUEST } }
    function success(id, project) { return { type: projectsConstants.COMPARE_PROJECT_VENDORS_SUCCESS, id, project} }
    function failure(error) { return { type: projectsConstants.COMPARE_PROJECT_VENDORS_FAILURE, error } }
}

function getProjectsReleaseDates(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const idsArray = ids.split(','); 
        return Promise.all(idsArray.map(id=>projectsService.getCard(id)))
            .then(
                projects => {
                    projects = projectsFormatter.formatProjectsReleaseDates(projects, idsArray);
                    dispatch(success(projects));
                    return projects;
                },
                error => {
                    dispatch(failure('error'))
                }
            )
    };

    function request() { return { type: projectsConstants.GET_PROJECTS_RELEASE_DATES_REQUEST } }
    function success(projects) { return { type: projectsConstants.GET_PROJECTS_RELEASE_DATES_SUCCESS, projects} }
    function failure(error) { return { type: projectsConstants.GET_PROJECTS_RELEASE_DATES_FAILURE, error } }
}

function compareProjectArtist(id, artistID, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        projectsService.compareProjectArtist(id, artistID, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                streams => {
                    const data = projectsFormatter.formatCompareProjectArtist(streams);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: projectsConstants.COMPARE_PROJECT_ARTIST_REQUEST } }
    function success(id, product) { return { type: projectsConstants.COMPARE_PROJECT_ARTIST_SUCCESS, id, product} }
    function failure(error) { return { type: projectsConstants.COMPARE_PROJECT_ARTIST_FAILURE, error } }
}
