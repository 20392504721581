import React from 'react';
import Tour from '../../common/Tour';

class HomepageTour extends React.Component {
    render(){
    const steps = [
   {
       selector: '.search-widget',
       content: 'Welcome to the latest version of Enterlytics. Search for any of your catalog with the improved search bar. Search by Name, Title, UPC, or ISRC',
       mutationObservables: ['.missing-data-warning']
   },
   {
       selector: '.new-filter-link',
       content: 'Click Save to give your filter a name.'
   },
   {
       selector: '.toggle-filter-btn',
       content: 'Adjust your date range and more advanced filters here. You’ll see the latest 7 days of complete data by default.'
   },                       
    {
        selector: '.user-nav',
        content: 'You can manage and create new filters from the Saved Filters menu here.'
    },
    ];
        
        return <Tour steps={steps} tourID="homepage_tour" />
    }
}

export default HomepageTour;