import React from 'react';
import PercentageValue from '../../common/PercentageValue';
import { convertToThousands, round } from '../../../helpers/NumberFormatter';
import ImageURL from '../../../helpers/ImageURL';

class EntityWidget extends React.Component {
    constructor(props){
        super(props);
    }
    
    
    render (){
        
        let { item, entity } = this.props,
            image = item.image; //  ImageURL(item.image, item.id, entity);
        return  <div className="widget-head-color-box entity-widget">
            <div className="title-block">
                <h2 className="font-bold no-margins entity-widget__title">
                    {item.name}
                </h2>
                {/*
                <small>subtitle</small>
                */}
            </div>
            <img src={image} className="rounded-circle circle-border m-b-md" alt="" onClick={this.props.toggleExpand}/>
            <div className="clearfix">
                {item.code && <h2 className="font-bold no-margins entity-widget__title">
                    {item.code}
                </h2>}
                <small className="pull-left entity-num-info">{convertToThousands(item.absoluteValue)} plays</small>
                <PercentageValue onlyPercentage={true} className="pull-right" field="units" item={item} />
            </div>
        </div>;
    }
}


export default EntityWidget;