import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { Route, withRouter } from 'react-router';
import { find } from 'lodash';
import ReactSelect from '../../components/common/ReactSelect';
import Spinner from '../../components/common/Spinner';
import MilestonesList from './List';
import { milestonesActions } from '../../data/actions/milestones';

class MilestoneTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null,
            types: []
        };
        this.setType = this.setType.bind(this);
    }
    
    componentDidMount() {
        const { match, dispatch } = this.props;
        const { type } = match.params;
        
        this.props.getMilestoneTypes()
        .then(types=>{
            this.setState({types: types}, ()=>{
                let typeValue = types[0];
                if(type) 
                    typeValue = find(types, option=>option.slug == type);
                
                this.setType(typeValue);
            })
        })
    }
    
    setType(type){
        const { type: prevType } = this.state;
        this.setState({type}, ()=>{
            if(type && (prevType && type.value != prevType.value) || !prevType)
                this.props.history.push(`/milestones/${type.slug}`);
        });
    }
    
    render() {
        let { type, types } = this.state;
        return <div className="inner-page track-comparison">
            <h2 className="audience">
                <span>Milestones Management</span>
                <Spinner enabled={!type} /> 
            </h2>
            <ReactSelect options={types} value={type} onChange={this.setType} />
            <Route path="/milestones/:type" render={(props)=><MilestonesList {...props} />}></Route>
            <Helmet>
                <title>{`Milestones Management`}</title>
            </Helmet>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        milestones: state.milestones
    } 
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        getMilestoneTypes: () => {
            return dispatch(milestonesActions.getMilestoneTypes())
        }
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilestoneTypes))