import moment from 'moment';

export {
    formatChartLabel,
    formatChartLabelItem,
    sortDateRange,
    getWeekDay,
    getWeekDayWarning,
    getTime
}

function formatChartLabelItem(label, period) {
    const formattedMonthLabel = moment(label, "YYYY-M").format("YYYY-MM")
    let dateLabel = null;
    if (period == "Month") {
        dateLabel = moment(formattedMonthLabel);
    } else {
        dateLabel = moment(label);
    }

    switch(period) {
        case 'Chart Week':
        case 'Calendar Week':
            const [year, week] = label.split('-'),
                prefix = `${period == 'Chart Week' ? 'Ch.' : 'Cal.'} Wk.`; 
            
            return `${prefix} #${week}, ${year}`;
        case 'Month':
            return dateLabel.format('MMM YYYY');
        default:
            return dateLabel.format('DD MMM');
            
    }

}

function formatChartLabel(item, key='stream_date', periodKey='period') {
    const period = item[periodKey],
        label = item[key];
    return formatChartLabelItem(label, period);
}

function compareValues(a, b) {
    return (a > b) 
        ? 1 
        : (a < b ? -1 : 0);
};

function sortDateRange(a, b) {
    const [yearA, weekA] = a.stream_date.split('-'),
        [yearB, weekB] = b.stream_date.split('-'),
        year = compareValues(Number(yearA), Number(yearB));

    return year === 0 ? compareValues(Number(weekA), Number(weekB)) : year;    
}

function getWeekDay(date) {
    return moment(date).format('dddd');
}

function getWeekDayWarning(date) {
    return moment(date).format('dd') == 'Fr' ? '' : 'This Release date is not a Friday so please note it will not take into consideration potential DSP features which only refresh on a Friday.';
}

function getTime(seconds) {
    const format = seconds >= 3600 ? 'HH:mm:ss' : 'mm:ss';
    return moment.utc(seconds*1000).format(format);
}