import React from 'react';
import CSVImport from './CSVImport';
import Modal from './Modal';

class CSVImportModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    
    toggleModal = (showModal) =>{
        this.setState({showModal});
    }
    
    onSubmit = (data) => {
        const { onSubmit } = this.props;
        this.toggleModal(false);
        return onSubmit(data);
    }
        
    render() {
        const { buttonTitle, buttonClass, modalTitle, onSubmit, fields } = this.props;
        const { showModal } = this.state;
        
        return (
            <div>
                <Modal show={showModal} title={modalTitle} showActionButtons={false} dialogClassName="entity-filter-modal" handleClose={()=>this.toggleModal(false)}>
                    <CSVImport fields={fields} onSubmit={this.onSubmit} />
                </Modal>
                <button className={buttonClass} onClick={(e)=>{e.preventDefault(); this.toggleModal(true);}}>{buttonTitle}</button>
            </div>
        )
    }
}

export default CSVImportModal