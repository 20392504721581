import React from 'react';
import { map } from 'lodash';
import AbsoluteValue from '../../../common/AbsoluteValue';
import { percentageValue } from '../../../../helpers/NumberFormatter';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../../common/Datagrid/Footers';

export const demographicsSettings = {
    settingsForTable
};


function settingsForTable(mode){
    
    
    let columns = [
       /*
      {
              id: 'gender',
              headerClassName: '',
              Header: 'Gender',
              // width: 70,
              accessor: 'gender',
          },
       */                         
       {
           id: 'age',
           className: 'imprint-name',
           headerClassName: 'imprint-name',
           Header: 'Age Group',
           accessor: 'age',
           Footer: textFooter
           // width: 190
       },
       
       {
           id: 'male',
           headerClassName: '',
           Header: 'Male',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'male',
           Footer: convertSumFooter
       },
       {
           id: 'female',
           headerClassName: '',
           Header: 'Female',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'female',
           Footer: convertSumFooter
       },              
       {
           id: 'unknown',
           headerClassName: '',
           Header: 'Unknown',
           Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
           // width: 70,
           accessor: 'unknown',
           Footer: convertSumFooter
       }              

    ];
    return columns;
}
