import React from 'react';
import { Link } from 'react-router-dom';
import icons from '../../../helpers/Icons';

class DetailsLink extends React.Component {
    render() {
        const {entity, id, text, noIcon, global, ...rest} = this.props;
        const filter = {[entity]: [id]};
        //(entity == 'imprints') ? null : 
        return (id ? <Link to={`/filter/${JSON.stringify(filter)}/overview/${global?JSON.stringify(global):''}`} {...rest}>
            {text}
            {!noIcon ? <span className="small-icon entities-icon" dangerouslySetInnerHTML={{__html: icons.entities[entity]}}></span> : null }
            </Link> : null);
    }
}

export default DetailsLink