import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ConfirmClick from '../../common/ConfirmClick';
import Spinner from '../../common/Spinner';
import Tooltip from '../../common/Tooltip';
import AddChannelLink from './AddChannelLink';

export const socialManagementSettings = {
    settingsForTable
};

function settingsForTable(channelTypes, refresh){
    let columns = [ 
       {
           id: 'channel',
           className: '',
           headerClassName: 'no-sort',
           sortable: false,           
           Header: 'Add New',
           accessor: '',
           filterable: false,
           width: 80,
           Cell: props => <AddChannelLink artist={props.original.artist_id} refresh={refresh}/>
       },
       {
           id: 'logo',
           className: 'logo-sell ',
           headerClassName: 'logo-sell no-sort',
           Header: 'Logo',
           accessor: 'image',
           width: 60,
           sortable: false,
           Cell: props => {
               return <div className="logo-container"><div className="logo" style={{backgroundImage: `url(${props.original.image})`}} /></div>
           }
       },
       {
           id: 'artist_name',
           className: '',
           headerClassName: 'no-sort',
           sortable: false,
           Header: 'Artist',
           accessor: 'artist_name',
           width: 200,
       },
       ];       
       for(let channel of Object.values(channelTypes)) {
           const header = (channel == 'youtube-channel') ? 'YouTube Channel' : channel;
           columns.push({
               id: `channel_${channel}`,
               className: '',
               headerClassName: 'no-sort',
               sortable: false,               
               Header: header,
               accessor: `channel_${channel}`,
               Cell: props => ((props.value && props.value.length > 0) ? props.value.map(channel=><AddChannelLink key={props.original.artist_id} artist={props.original.artist_id} channel={channel} refresh={refresh} />): 'N/A'),
               width: 200
               // { const url = props.value ? props.value.url : ''; return  <input type="text" value={url} /> }
           });
       };
       

    
    return columns;
}