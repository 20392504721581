import React, { Component } from 'react';

class ClickOutsideHolder extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    const { extraRefs = [], externalModal } = props; 
    this.state = {
        refs: [...extraRefs, this.ref],
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    for (let ref of this.state.refs) {
      if (!this.ref.current)
        return;
      
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }  
    }   
    
    if (this.props.externalModal) {
      return;
    }

    this.props.onClickOutside && this.props.onClickOutside(event);
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  render() {
    if (!this.props.show)
      return null;
    if (this.props.disabled)
        return <React.Fragment>{this.props.children}</React.Fragment>
        
    return (
      <div className="block-container" ref={this.ref}>
        {this.props.children}
      </div> );
  }
}

export default ClickOutsideHolder;