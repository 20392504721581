import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AbsoluteValue from '../../../common/AbsoluteValue';
import PercentageValue from '../../../common/PercentageValue';
import Tooltip from '../../../common/Tooltip';
import { avgFooter, convertSumFooter } from '../../../common/Datagrid/Footers';
import CountryFlag from '../../../common/CountryFlag';
import ConfirmClick from '../../../common/ConfirmClick';

export const mailingListsSettings = {
    settingsForTable
};


function settingsForTable(resendCall, deleteCall){
    let columns = [
         {
             id: 'email',
             Header: 'Recipient',
             accessor: 'value',
         },      
        {
           id: 'user_id',
           className: '',
           headerClassName: '',
           Header: 'User Type',
           accessor: 'user_id',
           Cell: props => props.value ? 'Existing User' : 'Non-User',
       },
       {
           id: 'actions',
           className: 'milestone-actions',
           headerClassName: 'no-sort milestone-actions',
           Header: 'Actions',
           sortable: false,
           // <a onClick={()=>setCurrentMilestone(props.original, 'showAddChannelModal')} className="btn add-to-filter"><i class="fas fa-plus"></i></a>
           Cell: props => <div className="">
            {!props.original.user_id && <a onClick={()=>resendCall(props.original.id)} className="btn" title="Resend Invitation">Resend Invitation</a>}
            <ConfirmClick confirmAction={()=>deleteCall(props.original.id)} confirmClass="btn add-to-filter" confirmLabel={<i className="fas fa-trash-alt"></i>} confirmPrompt="Delete recipient?" title="Delete" extraClass="dialog-modal"></ConfirmClick>
           </div>
       }
       
    ];
    return columns;
}
