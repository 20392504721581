import React from 'react';
import Table from '../../../common/PDF/Table';
import { splitByThousands, addPercent } from '../../../../helpers/NumberFormatter';
import { find, capitalize } from 'lodash';

const keys = {
    'imprints': ['imprint_name', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff' ],
    'artists': ['artist_name', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff'],
    'tracks': ['artist_name', 'track_title', 'isrc', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff', 'duration_time' ],
    'products': ['artist_name', 'product_title', 'upc', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff' ],
    'playlists': ['playlist_name', 'vendor', 'avg_track_streams_per_day', 'playlist_type', 'followers', 'curr_units', 'skipped_ratio', 'completed_ratio', 'total_tracks', 'playlist_share'],
}

const titles = [
    { key: 'imprint_name', title: 'Imprint', width: 20, fontSize: 5 },
    { key: 'artist_name', title: 'Artist', width: 20, fontSize: 5 },
    { key: 'product_title', title: 'Product', width: 20, fontSize: 5 },
    { key: 'track_title', title: 'Track', width: 20, fontSize: 5 },
    { key: 'playlist_name', title: 'Playlist', width: 60, fontSize: 5 },
    { key: 'vendor', title: 'Platform', width: 16, fontSize: 5, formatFn: capitalize },
    { key: 'total_tracks', title: 'Tracks',  width: 10, fontSize: 5, formatFn: splitByThousands },
    { key: 'isrc', title: 'ISRC', width: 16, fontSize: 5 },
    { key: 'upc', title: 'UPC', width: 16, fontSize: 5 },
    { key: 'avg_track_streams_per_day', title: 'DBP',  width: 10, fontSize: 5, formatFn: splitByThousands },
    { key: 'playlist_type', title: 'Type',  width: 15, fontSize: 5 },
    { key: 'followers', title: 'Followers',  width: 15, fontSize: 5, formatFn: splitByThousands },
    { key: 'curr_units', title: 'Units',  width: 12, fontSize: 5, formatFn: splitByThousands },
    { key: 'units_diff', title: 'Unit Change',  width: 12, fontSize: 5, formatFn: addPercent },
    { key: 'passive', title: 'Passive Units',  width: 15, fontSize: 5, formatFn: splitByThousands },
    { key: 'active', title: 'Active Units',  width: 15, fontSize: 5, formatFn: splitByThousands },
    { key: 'engaged', title: 'Engaged',  width: 10, fontSize: 5, formatFn: addPercent },
    { key: 'skipped_ratio', title: 'Skip Rate',  width: 10, fontSize: 5, formatFn: addPercent },
    { key: 'completed_ratio', title: 'Completion Rate' ,  width: 12, fontSize: 5, formatFn: addPercent },
    { key: 'curr_playlists', title: 'Playlists',  width: 10, fontSize: 5, formatFn: splitByThousands },
    { key: 'playlists_diff', title: 'Playlists Growth', width: 10, fontSize: 5, formatFn: addPercent },
    { key: 'playlist_share', title: 'Playlist Share', width: 10, fontSize: 5 },
    { key: 'duration_time', title: 'Duration', width: 10, fontSize: 5 },
]


export default ({data, entity}) => {
    // const columns = titles.filter(row=>keys[entity].includes(row.key));
    const columns = keys[entity].map(key=>find(titles, title=>title.key == key));
    return <Table items={data} columns={columns} /> 
}