import React, { Component } from 'react';
import Spinner from './Spinner';

export default class SelectHeartBeatsDates extends React.Component {
  render(){
    const { week, getDataByWeek, dates, loading, disabled } = this.props;
    return (
      <React.Fragment>
      <Spinner className="spinner spinner-inline" enabled={loading} />
      {!loading && <select value={week} onChange={getDataByWeek} disabled={disabled} >
        {dates.map(value => {
          return (
            <option key={value.startDate} value={value.startDate} >
              {"Wk#" + value.weekNumber + ' ' + value.startDate + ' to ' + value.endDate }
            </option>
          );
        })}
      </select>}
      </React.Fragment>
    )
  }
}
