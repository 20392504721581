import imprints from '../../public/img/imprints.png';
import artists from '../../public/img/artist.png';
import products from '../../public/img/product.png';
import projects from '../../public/img/project.png';
import tracks from '../../public/img/music-note.png';
import playlists from '../../public/img/playlist.png';

const entities = {
    imprints: {
        image: imprints,
        path: 'imprints/logos'
    },
    artists: {
        image: artists,
        path: 'artists/profile_images'
    },    
    products: {
        image: products,
        path: 'products/packshots'
    },
    projects: {
        image: projects,
        path: 'products/packshots'
    },    
    tracks: {
        image: tracks,
        path: 'products/packshots'
    },    
    playlists: {
        image: imprints,
        path: 'imprints/logos'
    }
}

const CDNBase = 'https://s3-eu-west-1.amazonaws.com/mini-catalogue';

export default function ImageURL(src, id, entity) {
    if(!src) {
        src = entities[entity].image;
    }
    else {
        if(!src.startsWith('http') && !src.startsWith('data')) {
            let ids = String(id).padStart(9, '0').match(/.{1,3}/g);
            src = `${CDNBase}/${entities[entity].path}/${ids.join('/')}/medium/${src}`;
        }
        // all images from EI CDN, whether full URL is supplied by API or built above, need to have .jpg extension
        if(src.startsWith(CDNBase))
            src = src.substr(0, src.lastIndexOf(".")) + ".jpg";
    }
    
    // TODO: remove
    src = src.replace('-staging\/', '/');
    return encodeURI(src);
}