import { searchConstants } from '../constants/search';

export function search_playlist(state = {}, action) {
    switch (action.type) {
        case searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                loading: true
            };
        case searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_SUCCESS:
            return {
                
                ...state,
                loading: false,
                autocomplete_items: action.search
            };
        case searchConstants.GET_SEARCH_PLAYLIST_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
     
        default:
            return state
    }
} 