import React from "react";

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayTooltip: false };
  }

  hideTooltip = () => {
    this.setState({ displayTooltip: false });
  };

  showTooltip = () => {
    this.setState({ displayTooltip: true });
  };

  render() {
    const { position, message, tooltipClass, customIcon, noIcon } = this.props;

    return (
            <div className="tooltip-container">
            {noIcon && <div className="noicon-tooltip" onMouseLeave={this.hideTooltip} onMouseOver={this.showTooltip}>{...this.props.children}</div>}
            {!noIcon && (
              customIcon ? <div className="custom-icon-tooltip" onMouseLeave={this.hideTooltip} onMouseOver={this.showTooltip}>{...this.props.children}</div> : (
                <span className="notificationReportInfoIcon toolbar-title-tooltip-icon" onMouseLeave={this.hideTooltip} onMouseOver={this.showTooltip}> 
                  <i className="fas fa-question-circle" /> 
                </span>
              )
            )
            }

            { this.state.displayTooltip &&
              <div className={`tooltip-bubble tooltip-${position} ${tooltipClass}`}>
                <div className='tooltip-message'>
                  {message}
                </div>
              </div>
            }
          </div>
          )
  }
}

export default Tooltip;