export const userEntityFiltersConstants = {
  GET_USER_ENTITY_FILTER_REQUEST: 'GET_USER_ENTITY_FILTER_REQUEST',
  GET_USER_ENTITY_FILTER_SUCCESS: 'GET_USER_ENTITY_FILTER_SUCCESS',
  GET_USER_ENTITY_FILTER_FAILURE: 'GET_USER_ENTITY_FILTER_FAILURE',
  GET_USER_ENTITY_FILTERS_REQUEST: 'GET_USER_ENTITY_FILTERS_REQUEST',
  GET_USER_ENTITY_FILTERS_SUCCESS: 'GET_USER_ENTITY_FILTERS_SUCCESS',
  GET_USER_ENTITY_FILTERS_FAILURE: 'GET_USER_ENTITY_FILTERS_FAILURE',
  GET_PLAYLIST_ENTITY_FILTERS_REQUEST: 'GET_PLAYLIST_ENTITY_FILTERS_REQUEST',
  GET_PLAYLIST_ENTITY_FILTERS_SUCCESS: 'GET_PLAYLIST_ENTITY_FILTERS_SUCCESS',
  GET_PLAYLIST_ENTITY_FILTERS_FAILURE: 'GET_PLAYLIST_ENTITY_FILTERS_FAILURE',  
  UPDATE_USER_ENTITY_FILTER_REQUEST: 'UPDATE_USER_ENTITY_FILTER_REQUEST',
  UPDATE_USER_ENTITY_FILTER_SUCCESS: 'UPDATE_USER_ENTITY_FILTER_SUCCESS',
  UPDATE_USER_ENTITY_FILTER_FAILURE: 'UPDATE_USER_ENTITY_FILTER_FAILURE',
  CREATE_USER_ENTITY_FILTER_REQUEST: 'CREATE_USER_ENTITY_FILTER_REQUEST',
  CREATE_USER_ENTITY_FILTER_SUCCESS: 'CREATE_USER_ENTITY_FILTER_SUCCESS',
  CREATE_USER_ENTITY_FILTER_FAILURE: 'CREATE_USER_ENTITY_FILTER_FAILURE',
  DESTROY_USER_ENTITY_FILTER_REQUEST: 'DESTROY_USER_ENTITY_FILTER_REQUEST',
  DESTROY_USER_ENTITY_FILTER_SUCCESS: 'DESTROY_USER_ENTITY_FILTER_SUCCESS',
  DESTROY_USER_ENTITY_FILTER_FAILURE: 'DESTROY_USER_ENTITY_FILTER_FAILURE',
  ADD_RECENT_FILTER: 'ADD_RECENT_FILTER',
  GET_RECENT_ENTITY_FILTERS_REQUEST: 'GET_RECENT_ENTITY_FILTERS_REQUEST',
  GET_RECENT_ENTITY_FILTERS_SUCCESS: 'GET_RECENT_ENTITY_FILTERS_SUCCESS',
  GET_RECENT_ENTITY_FILTERS_FAILURE: 'GET_RECENT_ENTITY_FILTERS_FAILURE',  
  CLEAR_RECENT_ENTITY_FILTERS_REQUEST: 'CLEAR_RECENT_ENTITY_FILTERS_REQUEST',
  CLEAR_RECENT_ENTITY_FILTERS_SUCCESS: 'CLEAR_RECENT_ENTITY_FILTERS_SUCCESS',
  CLEAR_RECENT_ENTITY_FILTERS_FAILURE: 'CLEAR_RECENT_ENTITY_FILTERS_FAILURE',  
}
