import React, { Component } from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import store from '../../../helpers/store'
import { connect } from 'react-redux';

import GridItem from './GridItem';
import { find, filter } from 'lodash'
const GridLayout = WidthProvider(Responsive);
import { gridsActions } from '../../../data/actions/grids';

const lockedSvg = require('!svg-inline-loader!../../../../public/img/locked.svg');
const unlockedSvg = require('!svg-inline-loader!../../../../public/img/unlocked.svg');
const resetSvg = require('!svg-inline-loader!../../../../public/img/reset.svg');

class ResponsiveGrid extends Component {
    static defaultProps = {
        className: "layout",
        // rowHeight: 100,
        // onLayoutChange: function() {},
        cols: { lg: 8, md: 4, sm: 2, xs: 1 },
        breakpoints: { lg: 1200, md: 996, sm: 767, xs: 0 },
        margin: [10, 20],
        layoutName: 'homepage',
        // compactType: "vertical",
        defaultBreakpoint: "lg",
        defaultRowHeights: { lg: 100, md: 80, sm: 100, xs: 100 }
    };
        
    constructor(props) {
        super(props);
        const currentGridId = store.get(this.getStoreKey()+'_id');
        const { layouts: defaultLayouts, locked: defaultLocked = true, defaultBreakpoint } = props;
        const layouts = this.loadLayout(defaultLayouts); //TODO!!
        this.state = { layouts, locked: defaultLocked, currentGridId, currentBreakpoint: defaultBreakpoint };
        
        this.getStoreKey = this.getStoreKey.bind(this); 
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.resetLayout = this.resetLayout.bind(this);
        this.toggleLock = this.toggleLock.bind(this);
        this.newGrid = this.newGrid.bind(this);
        this.editGrid = this.editGrid.bind(this);
        this.closeItem = this.closeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.renderEdit = this.renderEdit.bind(this);
        this.renderToolbox = this.renderToolbox.bind(this); 
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.getRowHeight = this.getRowHeight.bind(this);
    }
    
    componentDidMount() {
        this.props.dispatch(gridsActions.getGrids());
    }
    
    getStoreKey() {
        return `store_${this.props.layoutName}`;
    }
    
    saveLayout(layouts) {
        store.set(this.getStoreKey(), layouts);
    }
    
    loadLayout(defaultLayouts) {
        const savedLayouts = store.get(this.getStoreKey());
        if(savedLayouts) {
            if(Array.isArray(savedLayouts)) { // legacy single-dimensional array
                const multiLayout = {
                    ...defaultLayouts,
                    lg: savedLayouts
                };
                // this.saveLayout(multiLayout);
                return multiLayout;
            }
            else {
                return savedLayouts;
            }
        }
        else {
            return defaultLayouts;    
        } 
    }
    
    resetLayout() {
        const currentGridId = store.get(`${this.getStoreKey()}_id`) || 0;
        const grids = this.props.grids.items;
        const currentGrid = find(grids, grid=>grid.id == currentGridId);
        let layouts = Object.assign({}, this.props.layouts)
        if(currentGrid && currentGrid.layouts){
            if(Array.isArray(currentGrid.layouts)) {
                layouts = {
                    ...this.props.layouts,
                    lg: currentGrid.layouts
                };
            }
            else {
                layouts = {
                    ...currentGrid.layouts
                }
            }
        }
        this.onLayoutChange(null, layouts);
    }
    
    toggleLock() {
        const { locked } = this.state;
        this.setState({locked: !locked});
        if(!locked) {
            const layouts = this.loadLayout();
            this.props.dispatch(gridsActions.saveDefaultGrid({default_layout: layouts}));
        }
    }
    
    onLayoutChange(layout, layouts) {
        //this.props.onLayoutChange(layout);
        this.saveLayout(layouts);
        this.setState({layouts});
    }
    
    onBreakpointChange(currentBreakpoint) {
        let { layouts } = this.state;
        let defaultLayout = Object.assign([], layouts[currentBreakpoint]);
        for(let item of defaultLayout) {
            item.moved = false;
            item.static = false;
            
        }
        this.setState({currentBreakpoint}, 
        ()=>{this.onLayoutChange(null, {
            ...this.state.layouts,
            [currentBreakpoint]: defaultLayout
        })});
    }
        
    closeItem(id) {
        let { layouts, currentBreakpoint } = this.state;
        let layout = filter(layouts[currentBreakpoint], item=>item.i != id);
        this.onLayoutChange(layout, {
            ...layouts,
            [currentBreakpoint]: layout
        });
    }
    
    addItem(id) {
        const { layouts: defaultLayouts } = this.props;
        const { layouts, currentBreakpoint } = this.state;
        let layout = Object.assign([], layouts[currentBreakpoint]);
        let item = find(defaultLayouts[currentBreakpoint], (item)=>item.i == id);
        item.x = 0;
        item.y = 0;
        const h = item.h;
        for(let element of layout) {
            element.y += h;
        }
        
        layout.push(item);
        this.onLayoutChange(layout, {
            ...layouts,
            [currentBreakpoint]: layout
        });
    }
    
    newGrid() {
        const { layouts } = this.state;
        const { children: components } = this.props;
        const grid_name = prompt('New Grid Name');
        const gridData = {
            page_type: 'home', 
            grid_name,
            layouts,
            components,
            is_shared: false
        }
        this.props.dispatch(gridsActions.addGrid(gridData))
        .then(grid => {
            store.set(this.getStoreKey()+'_id', grid.id);    
        });
    }

    editGrid() {
        const { layouts, currentGridId: id } = this.state;
        const gridData = {
            layouts
        }
        this.props.dispatch(gridsActions.editGrid(gridData, id));
    }

        
    renderToolbox(elements) {
        const { layouts, currentBreakpoint } = this.state;
        const layout = layouts[currentBreakpoint];
        const hiddenElements = filter(elements, (element) => (!find(layout, layoutItem => ( layoutItem.i == element.i ) || !element.breakpoints.includes(currentBreakpoint))));
        return (hiddenElements.length ? <div className="toolbox-holder"><span>Removed elements (click to add back):</span>
        <div>
        {hiddenElements.map(element=><div className="toolbox-element" key={element.i} i={element.i} onClick={()=>this.addItem(element.i)} unselectable="on">{element.i.replace('_', ' ')}</div>)} 
        </div>
        </div> : null);
        
    }
    
    getRowHeight() {
        const { defaultRowHeights } = this.props,
            {currentBreakpoint } = this.state;
            
        return defaultRowHeights[currentBreakpoint];
    }
    
    renderEdit(currentGridId) {
        if(!currentGridId)
            return null;
        const {user} = this.props.user;
        const grids = this.props.grids.items;
        const currentGrid = find(grids, grid=>grid.id == currentGridId);
        if(!currentGrid)
            return null;
        
        const canEdit = currentGrid.user_id == user.id;
        return canEdit ? <a className="locked-holder" onClick={this.editGrid}>Update</a> : null;
    }
     
    render() {
        const {layouts: defaultLayouts, ...rest} = this.props;
        const {locked, currentGridId, layouts } = this.state;
        const lockIcon = locked ? lockedSvg : unlockedSvg;
        
        return <div className="">
            <div className="grid-control">
                <a className="locked-holder" onClick={this.toggleLock}>
                    <span>{locked ? "Locked" : "Unlocked"}</span>
                    <span className="locked-link" dangerouslySetInnerHTML={{__html: lockIcon}} />
                </a>
                {!locked && <span className="grid-management-holder">
                    <a className="locked-holder" onClick={this.resetLayout}>Reset<span dangerouslySetInnerHTML={{__html: resetSvg}} className="locked-link" /></a>
                    <a className="locked-holder" onClick={this.newGrid}>New</a>
                    {this.renderEdit(currentGridId)}
                </span>}
            </div>
            {!locked && this.renderToolbox(this.props.children)}
            <GridLayout
                layouts = {this.state.layouts}
                onLayoutChange={this.onLayoutChange}
            // compactType="vertical"
                isDraggable={!locked}
                isResizable={!locked}
                isDroppable={true}
                preventCollision={false}
                onBreakpointChange={this.onBreakpointChange}
                rowHeight={this.getRowHeight()}
                {...rest}
            >
                {this.generateDOM(this.props.children)}
            </GridLayout>
        </div>
    }
    
    generateDOM(elements) {
        const { locked, currentBreakpoint, layouts } = this.state;
        const { components } = this.props;
        
        let items = [];
        const layout = layouts[currentBreakpoint];
        for(let i = 0; i < layout.length; i++) {
            const layoutItem = layout[i];

            const { component: componentName, props } = find(elements, (element)=>element.i == layoutItem.i);
            const Component = components[componentName];
            items.push(<div key={layoutItem.i}>
                <GridItem isLocked={locked} w={layoutItem.w} h={layoutItem.h} closeItem={()=>this.closeItem(layoutItem.i)}>
                    <Component {...props} />
                </GridItem></div>);
            //items.push(<div key={layoutItem.i}>ololo</div>);
        };
        return items;
    }
}

function mapStateToProps(state) {
    return {
        grids: state.grids,
        user: state.user
    }
}

export default connect(mapStateToProps)(ResponsiveGrid)